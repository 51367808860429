import { useMutation, useQueryClient } from '@tanstack/react-query';
import { fetchDeleteAdmin } from 'api/AdminManagement/fetchDeleteAdmin';
import { QueryKey } from 'enum/api';

export const useDeleteAdmin = () => {
  const queryClient = useQueryClient();

  return useMutation(fetchDeleteAdmin, {
    onSuccess() {
      queryClient.invalidateQueries([QueryKey.Admins]);
    }
  });
};
