import { useSideNav } from 'containers/Layout/components/SideNav/useSideNav';
import { Navigate, useLocation } from 'react-router-dom';

export const AvailableRoute = (): JSX.Element | null => {
  const { pathname } = useLocation();

  const sideNav = useSideNav();
  const firstAvailablePage = sideNav.find(({ isDisabled }) => !isDisabled);

  if (pathname === '/' && firstAvailablePage) {
    const { to } = firstAvailablePage;

    return to !== pathname ? <Navigate to={to} /> : null;
  }

  return null;
};
