import { defineStyle } from '@chakra-ui/react';
import { ComponentStyleConfig } from '@chakra-ui/theme';

import { Input } from './input';

const baseStyle = defineStyle({
  ...Input.baseStyle?.field
});

const variants = {
  outline: defineStyle((props) => Input.variants?.outline(props).field ?? {})
};

const sizes = {
  md: Input.sizes?.md.field ?? {}
};

export const Textarea: ComponentStyleConfig = {
  baseStyle,
  variants,
  sizes
};
