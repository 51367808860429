/*
  This file can't be moved into own folder `constants` by several reasons:
   - By default 'constants' module already exist and it creates import issue
   - By using alias like `@constants` create webpack issue because CRA doesn't have opportunity modify webpack config (using craco can help with that). 
   https://devtails.xyz/how-to-use-path-aliases-with-create-react-app-webpack-and-typescript 
*/

export const NO_RESULTS_FOUND = 'no_results_found';

export const TOKEN = 'token';

export const MIDDLE_DOT = ' \u00B7 ';

export const ALL_COUNTRIES = 'allCountries';

export const GLOBAL_COUNTRY_FILTER = 'globalCountryFilter';

export const NO_ASSIGNEE = 'noAssignee';

export const YES = 'yes';
export const NO = 'no';

export const MAX_NUMBER_VALUE_WITH_10_CHARACTERS = 9999999999;

export const STATISTIC_TOTAL = 'Total';

export const DEFAULT_PAGINATION_LIMIT = 25;

export const IS_PROD = process.env.REACT_APP_IS_PRODUCTION === 'true';

export const DEFAULT_DOMAIN_CONFIGS_NAME = 'default';
