import { yupResolver } from '@hookform/resolvers/yup';
import { FormConstructor, ModalContainer } from 'containers';
import { FormField, ModalType } from 'enum';
import { ErrorCode } from 'enum/api';
import {
  useChakraToast,
  useCreateCustomer,
  useFormValidation,
  useModalActions
} from 'hooks';
import snakeCase from 'lodash/snakeCase';
import { useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { ParamType, RequiredNotNullable } from 'types';
import { serializeAxiosError } from 'utils';

import { CreateCustomerFormData } from './CreateAccount.types';
import {
  createCustomerAccountFormSchema,
  createCustomerBasicValidationSchema,
  createCustomerValidationSchema
} from './CreateAccount.util';

export const CreateAccount = (): JSX.Element => {
  const [t] = useTranslation();
  const toast = useChakraToast();

  const form = useForm<CreateCustomerFormData>({
    mode: 'onSubmit',
    resolver: yupResolver(createCustomerValidationSchema),
    defaultValues: {
      [FormField.UserName]: '',
      [FormField.Email]: '',
      [FormField.BirthDate]: '',
      [FormField.IsAdminUser]: false
    }
  });

  const { isOpen, onClose } = useModalActions(ModalType.CreateCustomerAccount, {
    onClose: form.reset
  });

  const { mutate, isLoading } = useCreateCustomer();

  const { handleSubmit, watch, resetField } = form;

  const isFormFilled = useFormValidation<CreateCustomerFormData>(
    form,
    createCustomerBasicValidationSchema
  );

  const country = watch(FormField.Country);

  useEffect(() => {
    resetField(FormField.State);
    resetField(FormField.City);
  }, [resetField, country]);

  const onSubmit = handleSubmit((data) => {
    const { lookingForGender, userName, customerType, ...rest } =
      data as RequiredNotNullable<CreateCustomerFormData, FormField.State>;

    mutate(
      {
        ...rest,
        lookFor: lookingForGender,
        nickname: userName,
        type: customerType
      },
      {
        onSuccess: onClose,
        onError: (error) => {
          const { errorCode } = serializeAxiosError(error);

          if (errorCode === ErrorCode.UserCreateEmailExists) {
            toast({
              title: t(`errors.codes.${snakeCase(errorCode)}`),
              status: 'error'
            });
          } else {
            toast({
              title: t('errors.error_create_new_user'),
              status: 'error'
            });
          }
        }
      }
    );
  });

  const actions: ParamType<typeof ModalContainer, 'footerButtons'> = [
    {
      text: t('actions.create_customer_account'),
      colorScheme: 'primary',
      isPreventClose: true,
      isLoading,
      isDisabled: !isFormFilled,
      onClick: () => {
        onSubmit();
      }
    }
  ];

  return (
    <ModalContainer
      header={t('attribute.title.create_customer_account')}
      footerButtons={actions}
      isOpen={isOpen}
      onClose={onClose}
      size="xl"
    >
      <FormProvider {...form}>
        <FormConstructor formSchema={createCustomerAccountFormSchema} />
      </FormProvider>
    </ModalContainer>
  );
};
