import kebabCase from 'lodash/kebabCase';
import {
  CUSTOMER_PAGE,
  CUSTOMER_PAGE_LIST,
  SECTION_CONTENT
} from 'pages/Customers/CustomerInfo/CustomerInfo.utils';
import { Navigate, RouteObject } from 'react-router-dom';

export const CUSTOMER_ROUTES: RouteObject[] =
  CUSTOMER_PAGE_LIST.map<RouteObject>((page) => {
    const { sections, content: Content } = CUSTOMER_PAGE[page];

    const childrenRoutes = sections
      ? [
          ...sections.map((section) => {
            const Section = SECTION_CONTENT[section];

            return {
              path: kebabCase(section),
              element: <Section />
            };
          }),
          {
            index: true,
            element: <Navigate to={kebabCase(sections[0])} replace />
          }
        ]
      : undefined;

    return {
      path: kebabCase(page),
      element: <Content sections={sections} />,
      children: childrenRoutes
    };
  });
