import { SystemStyleObject } from '@chakra-ui/react';
import { ComponentStyleConfig } from '@chakra-ui/theme';

const variantRound: SystemStyleObject = {
  display: 'inline-flex',
  justifyContent: 'center',
  alignItems: 'center',
  height: 6,
  minWidth: 6,
  padding: 0.5,
  borderRadius: 'full',
  color: 'black',
  background: 'white'
};

const variants = {
  round: variantRound
};

export const Badge: ComponentStyleConfig = {
  sizes: {
    md: {
      fontSize: 'md',
      fontWeight: 'normal'
    }
  },
  variants,
  defaultProps: {
    size: 'md',
    variant: 'round'
  }
};
