import { useQuery } from '@tanstack/react-query';
import { fetchAllCustomerBadWordsList } from 'api/Customers/CustomerBadWords/fetchAllCustomerBadWordsList';
import { QueryKey } from 'enum/api';

export const useAllCustomerBadWordsList = (customerId: string) =>
  useQuery(
    [QueryKey.CustomerBadWords, customerId],
    () => fetchAllCustomerBadWordsList({ customerId }),
    {
      retry: false,
      refetchOnWindowFocus: false
    }
  );
