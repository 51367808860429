import { VStack, HStack, Text } from '@chakra-ui/react';
import { ModalContainer } from 'containers';
import { ModalType } from 'enum';
import { useModalActions } from 'hooks';
import snakeCase from 'lodash/snakeCase';
import { useTranslation } from 'react-i18next';

export const ViewPopupMetrics = (): JSX.Element => {
  const [t] = useTranslation();

  const { isOpen, onClose, meta } = useModalActions(ModalType.ViewPopupMetrics);

  return (
    <ModalContainer
      header={t('attribute.title.popup_metrics')}
      isOpen={isOpen}
      onClose={onClose}
      size="md"
    >
      {meta?.metrics && (
        <VStack alignItems="stretch">
          {Object.entries(meta.metrics).map(([key, value]) => (
            <HStack key={key} justifyContent="space-between">
              <Text textStyle="mds">
                {t(`keywords.popup_metrics.${snakeCase(key)}`)}:
              </Text>
              <Text>{value || t('keywords.empty_sign')}</Text>
            </HStack>
          ))}
        </VStack>
      )}
    </ModalContainer>
  );
};
