import { ChakraTheme, useTheme } from '@chakra-ui/react';
import { Theme as BarChartTheme } from '@nivo/core';

export const useBarChartTheme = (): BarChartTheme => {
  const theme = useTheme<ChakraTheme>();

  return {
    textColor: 'inherit',
    fontFamily: 'inherit',
    axis: {
      ticks: {
        text: {
          fontSize: theme.__cssMap['fontSizes.sm'].varRef,
          fontWeight: theme.__cssMap['fontWeights.normal'].varRef,
          fill: theme.__cssMap['colors.gray.600'].varRef
        }
      }
    },
    grid: {
      line: {
        stroke: theme.__cssMap['colors.gray.200'].varRef,
        strokeWidth: 1
      }
    },
    labels: {
      text: {
        fontSize: theme.__cssMap['fontSizes.sm'].varRef,
        fontWeight: theme.__cssMap['fontWeights.normal'].varRef,
        fill: 'black'
      }
    }
  };
};
