import { FormField, InputElement } from 'enum';

export const viewConversationsFormSchema = {
  fields: {
    [FormField.Password]: {
      type: InputElement.Password,
      translationKey: 'password',
      getPlaceholder: () => 'Password'
    }
  },
  gridLayout: {
    templateAreas: `
      "${FormField.Password}"
    `,
    gridTemplateColumns: '1fr'
  }
} as const;
