import { CloseButton } from '@chakra-ui/react';
import { MouseEventHandler } from 'react';

type Props = {
  onReset: () => void;
};

export const ResetSelection = ({ onReset }: Props): JSX.Element => {
  const handleReset: MouseEventHandler<HTMLButtonElement> = (e) => {
    e.preventDefault();
    onReset();
  };

  return (
    <CloseButton
      as="span"
      variant="round"
      colorScheme="white"
      size="sm"
      display="inline-flex"
      ml="0.375rem"
      onClick={handleReset}
    />
  );
};
