import { ModerationTotal } from 'api';
import { ModerationPage, ModerationPostStatus, ModerationSection } from 'enum';
import { getAllEnumValues } from 'enum-for';
import snakeCase from 'lodash/snakeCase';
import { TFunction } from 'react-i18next';

import { AboutMe, Comments, Declined, GalleryPhotos, Motto } from './Pages';

export enum ModerationActionType {
  Decline = 'decline',
  AutoDecline = 'autoDecline',
  Approve = 'approve',
  Edit = 'edit'
}

export const MODERATION_PAGE_LIST = getAllEnumValues(ModerationPage);

export const MODERATION_PAGE: Record<
  ModerationPage,
  {
    sections: ModerationSection[] | null;
    content: ({
      sections,
      status
    }: {
      sections: ModerationSection[] | null;
      status?: ModerationPostStatus;
    }) => JSX.Element;
  }
> = {
  [ModerationPage.GalleryPhoto]: { sections: null, content: GalleryPhotos },
  [ModerationPage.Comment]: { sections: null, content: Comments },
  [ModerationPage.Motto]: { sections: null, content: Motto },
  [ModerationPage.SomethingAboutMe]: {
    sections: null,
    content: AboutMe
  },
  [ModerationPage.Declined]: {
    sections: getAllEnumValues(ModerationSection),
    content: Declined
  }
};

export const SECTION_CONTENT: Record<ModerationSection, () => JSX.Element> = {
  [ModerationSection.GalleryPhoto]: () => (
    <GalleryPhotos status={ModerationPostStatus.Declined} />
  ),
  [ModerationSection.Comment]: () => (
    <Comments status={ModerationPostStatus.Declined} />
  ),
  [ModerationSection.Motto]: () => (
    <Motto status={ModerationPostStatus.Declined} />
  ),
  [ModerationSection.SomethingAboutMe]: () => (
    <AboutMe status={ModerationPostStatus.Declined} />
  )
};

export const getInitColumnVisibility = (status: ModerationPostStatus) => ({
  actions: status === ModerationPostStatus.NotAccepted,
  declineReason: status === ModerationPostStatus.Declined
});

export const getModerationPageLabel = (
  page: ModerationPage,
  data: ModerationTotal,
  t: TFunction
): string => {
  if (page === ModerationPage.Declined) return t(`keywords.declined`);

  return `${t(`keywords.${snakeCase(page)}`, {
    count: data[page]
  })} (${data[page]})`;
};
