import { useMutation, useQueryClient } from '@tanstack/react-query';
import { fetchDeleteQuickReply, UserSupportQuickReply } from 'api';
import { QueryKey } from 'enum/api';

export const useDeleteQuickReply = () => {
  const queryClient = useQueryClient();

  return useMutation(fetchDeleteQuickReply, {
    async onMutate({ id }) {
      await queryClient.cancelQueries({
        queryKey: [QueryKey.QuickReplies]
      });

      queryClient.setQueryData<UserSupportQuickReply[]>(
        [QueryKey.QuickReplies],
        (data) => {
          if (!data) {
            return data;
          }

          return data.filter((item) => item.id !== id);
        }
      );
    },
    onSettled() {
      queryClient.invalidateQueries([QueryKey.QuickReplies]);
    }
  });
};
