export enum Label {
  NewUser = '2a89752e-9604-44c6-8274-2e6e24d42c66',
  EmailVerified = '24165534-f8ec-46d4-883b-bcc909950172',
  EmailNotVerified = '479466d1-9b07-4f80-9714-c4c085e92c74',
  NewUserNotFullyRegistered = 'c115a29a-c5e7-48de-aca8-28b2e3370ccd',
  SuspendedIn = 'e85a22dc-31c1-479d-ad7d-0632c70f0e3d',
  NewUserWithNoMessage = 'bbd1b2bf-0b81-4d7e-a9c9-73c1e6f08361',
  SecondWarning = '0fbce53b-0c72-4ff2-8564-a5419f6f62a6',
  PhoneVerified = 'e5a9c05b-886b-4daf-a234-ae851a647675',
  PhotoVerified = '982eb7b5-8ad4-40f9-a86d-701b20846e7d',
  IdVerified = '591bcb63-4632-4f35-b1fe-3a9dedbc6d8c',
  Blocked = '4c110bd5-ba64-44f1-8b33-22940bbaae6e',
  Suspended = 'e49e216d-12e8-420f-a28f-1905fdfeeb40',
  NoFollowUpCorrespondence = '8bf638b8-e5aa-4731-99b3-1fb5c4323908',
  FirstWarning = '1e6aa8d8-354a-4d5f-8510-3c7b9e45bb21',
  NoPhoneLeft = '3a8b6c57-6ea5-4e0d-a011-973cab4fdb5c',
  TransactionDenial = '13aded7f-0aea-456b-bf43-e745fefce921',
  NoPhoneEntered = 'c014c14a-40d4-4659-a421-1ab0d0797603',
  ForFollowUp = 'b7fcb5b0-b178-4600-ab1d-82980ca302ce',
  PhoneVerification = 'c5dbc906-36d8-42e9-b3f6-5c83b7696590',
  PhoneNotVerified = '40fdd2d5-1afe-4492-abef-47147d3dd6ad',
  ImagesInTrace = '760e0f75-cca2-409c-815e-32aad8d92a01',
  TestingProfile = 'f2265ffc-b2ba-4ec8-8afb-c095ccf41783',
  ImagesBlock = '5b3fff6f-b290-4897-9aa0-5eaec2fc6efd',
  InstagramNotVerified = '6a783409-f214-49ea-89f7-8c2b68208083',
  InstagramVerificationInProcess = 'ac54690b-3e2e-4701-9c37-67f5122acc20',
  InstagramVerified = '3a44c262-c5cc-441f-8113-3e24217c9f49',
  InstagramVerificationFailed = 'b458a1ec-dcb2-4834-b07d-0f2cfbef354d'
}

export enum CustomerType {
  Supported = 'b2b72a12-7455-46a2-a7e5-801b78c5da2e',
  Supporter = 'b403cfd1-7c73-49ab-a2c3-694e14c1ce70'
}

export enum GenderTruncated {
  Male = 'e45f7259-5920-4778-a2a2-5fe67d22268a',
  Female = 'b46a46a7-297a-4172-8a76-73f4f8ff83da',
  Other = 'aed3e706-13d6-4445-9d1b-18c6ac0b6802'
}
