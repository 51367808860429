import { Box, Image } from '@chakra-ui/react';
import { createColumnHelper } from '@tanstack/react-table';
import { TruncateText } from 'components';
import { TFunction } from 'react-i18next';
import {
  ICustomerBlackList,
  ICustomerComment,
  ICustomerConversationMessage,
  ICustomerFavourites,
  ICustomerLikes,
  ICustomerNotes
} from 'types/customer';
import { getBaseCustomerLinkNavigation, getDateFormattedString } from 'utils';

import { NicknameCell } from './Cells';
import { DeleteComment } from './components';
import { CustomerInfoBlock } from './components/CustomerInfoBlock';

const commentsColumnHelper = createColumnHelper<ICustomerComment>();

export const commentsColumns = [
  commentsColumnHelper.accessor('photoUrl', {
    cell: (info) => (
      <Box>
        <Image boxSize={10} src={info.getValue()} borderRadius="md" />
      </Box>
    ),
    header: ({ table }) => table.options.meta?.t('keywords.gallery_photo'),
    enableSorting: false
  }),
  commentsColumnHelper.accessor('displayedNickname', {
    cell: ({ getValue, row }) => (
      <NicknameCell
        navigateTo={getBaseCustomerLinkNavigation(row.original.commentedId)}
      >
        {getValue()}
      </NicknameCell>
    ),
    header: ({ table }) => table.options.meta?.t('keywords.customer_nickname'),
    enableSorting: true
  }),
  commentsColumnHelper.accessor('commentedAt', {
    cell: ({ getValue, table }) =>
      getDateFormattedString({ t: table.options.meta?.t, date: getValue() }),
    header: ({ table }) =>
      table.options.meta?.t('keywords.comment_date_and_time'),
    enableSorting: true
  }),
  commentsColumnHelper.accessor('comment', {
    cell: (info) => (
      <TruncateText maxWidth="md">{info.getValue()}</TruncateText>
    ),
    header: ({ table }) => table.options.meta?.t('keywords.comment_text'),
    enableSorting: false
  }),
  commentsColumnHelper.display({
    id: 'more',
    header: () => null,
    cell: ({ row }) => <DeleteComment commentId={row.original.id} />
  })
];

const notesColumnHelper = createColumnHelper<ICustomerNotes>();

export const notesColumns = [
  notesColumnHelper.accessor('displayedNickname', {
    cell: ({ getValue, row }) => (
      <NicknameCell
        navigateTo={getBaseCustomerLinkNavigation(row.original.userId)}
      >
        {getValue()}
      </NicknameCell>
    ),
    header: ({ table }) => table.options.meta?.t('keywords.customer_nickname'),
    enableSorting: false
  }),
  notesColumnHelper.accessor('email', {
    cell: (info) => info.getValue(),
    header: ({ table }) => table.options.meta?.t('keywords.customer_email'),
    enableSorting: false
  }),
  notesColumnHelper.accessor('createdAt', {
    cell: ({ getValue, table }) =>
      getDateFormattedString({ t: table.options.meta?.t, date: getValue() }),
    header: ({ table }) =>
      table.options.meta?.t('keywords.comment_date_and_time'),
    enableSorting: false
  }),
  notesColumnHelper.accessor('note', {
    cell: (info) => (
      <TruncateText maxWidth="md">{info.getValue()}</TruncateText>
    ),
    header: ({ table }) => table.options.meta?.t('keywords.note_text'),
    enableSorting: false
  })
];

const favouritesColumnHelper = createColumnHelper<ICustomerFavourites>();

export const favouritesColumns = [
  favouritesColumnHelper.accessor('displayedNickname', {
    cell: ({ getValue, row }) => (
      <NicknameCell
        navigateTo={getBaseCustomerLinkNavigation(row.original.userId)}
      >
        {getValue()}
      </NicknameCell>
    ),
    header: ({ table }) => table.options.meta?.t('keywords.customer_nickname'),
    enableSorting: false
  }),
  favouritesColumnHelper.accessor('email', {
    cell: (info) => info.getValue(),
    header: ({ table }) => table.options.meta?.t('keywords.customer_email'),
    enableSorting: false
  }),
  favouritesColumnHelper.accessor('addedAt', {
    cell: ({ getValue, table }) =>
      getDateFormattedString({ t: table.options.meta?.t, date: getValue() }),
    header: ({ table }) =>
      table.options.meta?.t('keywords.date_and_time_addition'),
    enableSorting: true
  })
];

const likesColumnHelper = createColumnHelper<ICustomerLikes>();

export const likesColumns = [
  likesColumnHelper.accessor('displayedNickname', {
    cell: ({ getValue, row }) => (
      <NicknameCell
        navigateTo={getBaseCustomerLinkNavigation(row.original.userId)}
      >
        {getValue()}
      </NicknameCell>
    ),
    header: ({ table }) => table.options.meta?.t('keywords.customer_nickname'),
    enableSorting: false
  }),
  likesColumnHelper.accessor('email', {
    cell: (info) => info.getValue(),
    header: ({ table }) => table.options.meta?.t('keywords.customer_email'),
    enableSorting: false
  }),
  likesColumnHelper.accessor('likedAt', {
    cell: ({ getValue, table }) =>
      getDateFormattedString({ t: table.options.meta?.t, date: getValue() }),
    header: ({ table }) =>
      table.options.meta?.t('keywords.date_and_time_liking'),
    enableSorting: true
  })
];

const blackListColumnHelper = createColumnHelper<ICustomerBlackList>();

export const blackListColumns = [
  blackListColumnHelper.accessor('displayedNickname', {
    cell: ({ getValue, row }) => (
      <NicknameCell
        navigateTo={getBaseCustomerLinkNavigation(row.original.userId)}
      >
        {getValue()}
      </NicknameCell>
    ),
    header: ({ table }) => table.options.meta?.t('keywords.customer_nickname'),
    enableSorting: false
  }),
  blackListColumnHelper.accessor('email', {
    cell: (info) => info.getValue(),
    header: ({ table }) => table.options.meta?.t('keywords.customer_email'),
    enableSorting: false
  }),
  blackListColumnHelper.accessor('addedAt', {
    cell: ({ getValue, table }) =>
      getDateFormattedString({ t: table.options.meta?.t, date: getValue() }),
    header: ({ table }) =>
      table.options.meta?.t('keywords.addition_date_and_time'),
    enableSorting: true
  })
];

const conversationsColumnHelper =
  createColumnHelper<ICustomerConversationMessage>();

export const conversationsColumns = [
  conversationsColumnHelper.accessor('userId', {
    cell: ({ getValue, table }) => {
      const userId = getValue();
      const { conversation, customerId } = table.options.meta || {};

      return (
        <CustomerInfoBlock
          conversation={conversation}
          userId={userId}
          customerId={customerId}
        />
      );
    },
    header: ({ table }) => table.options.meta?.t('keywords.user_name'),
    enableSorting: false,
    meta: {
      width: '17rem'
    }
  }),
  conversationsColumnHelper.accessor('created', {
    cell: ({ getValue, table }) =>
      getDateFormattedString({ t: table.options.meta?.t, date: getValue() }),
    header: ({ table }) =>
      table.options.meta?.t('keywords.message_date_and_time'),
    enableSorting: true,
    meta: {
      width: '12rem'
    }
  }),
  conversationsColumnHelper.accessor('message', {
    cell: (info) => (
      <TruncateText whiteSpace="pre-line">
        {info.getValue()?.replace(/<br\s*\/?>/gi, '\n')}
      </TruncateText>
    ),
    header: ({ table }) => table.options.meta?.t('keywords.message_text'),
    enableSorting: false
  }),
  conversationsColumnHelper.accessor('imageFiles', {
    cell: ({ getValue }) => {
      const [image] = getValue();

      return (
        !!image && (
          <Image
            boxSize={15}
            minWidth={15}
            src={image.imageUrl}
            borderRadius="md"
            objectFit="cover"
          />
        )
      );
    },
    header: () => null,
    enableSorting: false
  })
];

export const getSearchOptions = (t: TFunction) => [
  // {
  //   label: t('keywords.nickname'),
  //   value: 'nickname'
  // },
  {
    label: t('keywords.message'),
    value: 'message'
  }
];
