import { InputElement } from 'enum';

import { GeneralSettingsFormField } from '../../FormContent.types';

export const filesStorageParamsFormFields = [
  {
    type: InputElement.Input,
    name: GeneralSettingsFormField.StorageFolderName,
    translationKey: 'storage_folder_name',
    getPlaceholder: () => ''
  },
  {
    type: InputElement.NumberInput,
    name: GeneralSettingsFormField.StorageMaxEmailsImagesMale,
    translationKey: 'storage_max_emails_images_male',
    getPlaceholder: () => '',
    min: 0
  },
  {
    type: InputElement.NumberInput,
    name: GeneralSettingsFormField.StorageMaxEmailsImagesFemale,
    translationKey: 'storage_max_emails_images_female',
    getPlaceholder: () => '',
    min: 0
  },
  {
    type: InputElement.NumberInput,
    name: GeneralSettingsFormField.StorageMaxNotificationsImagesMale,
    translationKey: 'storage_max_notifications_images_male',
    getPlaceholder: () => '',
    min: 0
  },
  {
    type: InputElement.NumberInput,
    name: GeneralSettingsFormField.StorageMaxNotificationsImagesFemale,
    translationKey: 'storage_max_notifications_images_female',
    getPlaceholder: () => '',
    min: 0
  }
] as const;
