import { VStack, Text } from '@chakra-ui/react';
import { yupResolver } from '@hookform/resolvers/yup';
import { RightSideButtons } from 'components';
import { ContentContainer, FormElement, RightSide } from 'containers';
import { InputElement } from 'enum';
import { useChakraToast, useUpdateCustomer } from 'hooks';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate, useOutletContext } from 'react-router-dom';

import { NotificationSettingsFormData } from './NotificationSettings.types';
import {
  changeNotificationsSettingsValidationSchema,
  getDefaultFormValues,
  settingsListConfig
} from './NotificationSettings.utils';

export const NotificationSettings = (): JSX.Element => {
  const [t] = useTranslation();
  const navigate = useNavigate();
  const toast = useChakraToast();

  const { customer } = useOutletContext();

  const form = useForm<NotificationSettingsFormData>({
    mode: 'onSubmit',
    resolver: yupResolver(changeNotificationsSettingsValidationSchema),
    defaultValues: getDefaultFormValues(customer)
  });

  const {
    formState: { isDirty },
    handleSubmit,
    getValues,
    reset
  } = form;

  const { mutate: updateCustomer, isLoading } = useUpdateCustomer();

  const onSubmit = handleSubmit((data) => {
    updateCustomer(
      {
        id: customer.id,
        notificationPreferences: data
      },
      {
        onSuccess: () => {
          reset({
            ...getValues()
          });

          toast({
            title: t('messages.successfully_saved'),
            status: 'success'
          });
        }
      }
    );
  });

  const handleCancel = () => {
    navigate(-1);
  };

  return (
    <ContentContainer>
      <FormProvider {...form}>
        <VStack spacing={4} alignItems="stretch">
          {settingsListConfig.map(({ categoryTitle, settings }) => (
            <VStack key={categoryTitle} spacing={3} alignItems="stretch">
              <Text textStyle="md-semibold">
                {t(`email_notifications_settings.${categoryTitle}`)}
              </Text>
              {settings.map(({ apiFieldName, title }) => (
                <FormElement
                  key={apiFieldName}
                  type={InputElement.Checkbox}
                  name={apiFieldName}
                  getLabel={(t) => t(`email_notifications_settings.${title}`)}
                />
              ))}
            </VStack>
          ))}
        </VStack>
      </FormProvider>
      <RightSide>
        <RightSideButtons
          onSubmit={onSubmit}
          isDisabledSubmit={!isDirty}
          onCancel={handleCancel}
          isLoading={isLoading}
        />
      </RightSide>
    </ContentContainer>
  );
};
