import { useMutation, useQueryClient } from '@tanstack/react-query';
import { CrmFiltersRequestData, fetchCrmDeleteUsers } from 'api';
import { QueryKey } from 'enum/api';
import { useAppSelector } from 'hooks';
import { selectAppFilters } from 'store/app';

export const useCrmDeleteUsers = () => {
  const queryClient = useQueryClient();
  const globalFilters = useAppSelector(selectAppFilters);

  return useMutation(
    (body: CrmFiltersRequestData) => fetchCrmDeleteUsers(body, globalFilters),
    {
      onSuccess() {
        queryClient.invalidateQueries([QueryKey.CrmDeleteUsers]);
      }
    }
  );
};
