import { GridItem } from '@chakra-ui/react';
import { FormElement } from 'containers';
import { InputElement } from 'enum';
import { Fragment } from 'react';
import { useTranslation } from 'react-i18next';

import { GeneralSettingsFormField } from '../FormContent.types';

export const PercentageDisplayAnotherGender = (): JSX.Element => {
  const [t] = useTranslation();

  return (
    <Fragment>
      <GridItem>
        {t('attribute.description.percentage_for_display_another_gender')}
      </GridItem>
      <GridItem width="50%">
        <FormElement
          type={InputElement.NumberInput}
          name={GeneralSettingsFormField.PercentageForDisplayAnotherGender}
          translationKey="percentage"
          getPlaceholder={() => ''}
          min={0}
          max={100}
        />
      </GridItem>
    </Fragment>
  );
};
