import { Portal } from '@chakra-ui/react';
import { ReactNode } from 'react';
import { useOutletContext } from 'react-router-dom';

export const RightSide = ({
  children
}: {
  children: ReactNode;
}): JSX.Element => {
  const { rightSideRef } = useOutletContext();

  return <Portal containerRef={rightSideRef}>{children}</Portal>;
};
