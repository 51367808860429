import { yupResolver } from '@hookform/resolvers/yup';
import { JsonType } from 'api';
import { FormConstructor, ModalContainer } from 'containers';
import { FormField, ModalType } from 'enum';
import { useModalActions, useChakraToast } from 'hooks';
import { useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { ParamType } from 'types';

import { EditJSONFormData } from './EditJSON.types';
import { EditJSONFormSchema, EditJSONValidationSchema } from './EditJSON.utils';

export const EditJSON = (): JSX.Element => {
  const [t] = useTranslation();
  const toast = useChakraToast();
  const [isLoading, setIsLoading] = useState(false);

  const form = useForm<EditJSONFormData>({
    mode: 'onSubmit',
    resolver: yupResolver(EditJSONValidationSchema)
  });

  const {
    reset,
    handleSubmit,
    formState: { isDirty }
  } = form;

  const { isOpen, onClose, meta } = useModalActions(ModalType.EditJSON);

  useEffect(() => {
    if (isOpen && meta?.initialValue) {
      try {
        reset({
          [FormField.Json]: JSON.stringify(meta.initialValue, undefined, 4)
        });
      } catch (error) {}
    }

    if (!isOpen) {
      reset({
        [FormField.Json]: ''
      });
    }
  }, [isOpen]);

  const onCancel = () => {
    onClose();
    meta?.onCancel?.();
  };

  const onSubmit = handleSubmit(async (data) => {
    if (meta) {
      try {
        setIsLoading(true);
        const jsonString = data.json;

        if (!jsonString) {
          await meta.onSubmit(null);
          return;
        }

        try {
          const jsonData = JSON.parse(jsonString) as JsonType;

          await meta.onSubmit(jsonData);
        } catch (error) {
          toast({
            status: 'error',
            description:
              error instanceof Error
                ? error.message || t('errors.invalid_json')
                : t('errors.invalid_json')
          });
        }
      } catch (error) {}

      setIsLoading(false);
      onClose();
    }
  });

  const actions: ParamType<typeof ModalContainer, 'footerButtons'> = [
    {
      text: t('actions.cancel'),
      variant: 'ghostGray'
    },
    {
      text: t('actions.save_changes'),
      isLoading,
      isDisabled: !isDirty,
      isPreventClose: true,
      onClick: () => onSubmit()
    }
  ];

  return (
    <ModalContainer
      header={meta?.header}
      footerButtons={actions}
      isOpen={isOpen}
      onClose={onCancel}
      size="xl"
      modalBodyProps={{
        overflow: 'unset',
        overflowX: 'unset'
      }}
    >
      <FormProvider {...form}>
        <FormConstructor formSchema={EditJSONFormSchema} />
      </FormProvider>
    </ModalContainer>
  );
};
