import { defineStyle, defineStyleConfig } from '@chakra-ui/styled-system';
import { cssVar } from '@chakra-ui/theme-tools';

const $bg = cssVar('tooltip-bg');
const $fg = cssVar('tooltip-fg');
const $arrowBg = cssVar('popper-arrow-bg');

const baseStyle = defineStyle({
  bg: $bg.reference,
  color: $fg.reference,
  [$bg.variable]: 'colors.gray.900',
  [$fg.variable]: 'colors.white',
  [$arrowBg.variable]: $bg.reference,
  px: '2.5',
  py: '3.5',
  borderRadius: 'md',
  fontWeight: 'normal',
  fontSize: 'md',
  boxShadow: 'none'
});

export const Tooltip = defineStyleConfig({
  baseStyle
});
