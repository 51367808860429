import { SearchIcon } from '@chakra-ui/icons';
import {
  CloseButton,
  Divider,
  HStack,
  IconButton,
  Input,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  useControllableState
} from '@chakra-ui/react';
import { Dropdown } from 'components/Dropdown';
import { DropdownType } from 'enum';
import { debounce } from 'lodash';
import { useState } from 'react';
import { Option, QueryFilters } from 'types';

type Props = {
  options: Option[];
  onChange: (value: Pick<QueryFilters, 'search' | 'searchBy'>) => void;
};

export const Search = ({ options, onChange }: Props): JSX.Element => {
  const [debouncedOnChange] = useState(() => debounce(onChange, 600));

  const [{ search, searchBy }, setSearchData] = useControllableState({
    defaultValue: {
      search: '',
      searchBy: options[0]?.value || null
    },
    onChange: ({ search, searchBy }) => {
      if (!searchBy || (!!search && search.length < 2)) {
        return;
      }

      debouncedOnChange({
        search,
        searchBy
      });
    }
  });

  return (
    <InputGroup>
      <InputLeftElement boxSize={10}>
        <IconButton
          icon={<SearchIcon />}
          aria-label="Search table"
          variant="ghost"
          size="md"
          color={search ? 'primary.500' : 'gray.500'}
          _hover={{ bgColor: 'transparent' }}
          _active={{ bgColor: 'transparent' }}
        />
      </InputLeftElement>
      <Input
        placeholder="Search"
        size="md"
        paddingStart={10}
        paddingEnd="11.25rem"
        value={search}
        onChange={(e) =>
          setSearchData((prev) => ({
            ...prev,
            search: e.target.value
          }))
        }
      />
      <InputRightElement w="auto">
        <HStack spacing="none">
          {!!search && (
            <CloseButton
              onClick={() =>
                setSearchData((prev) => ({
                  ...prev,
                  search: ''
                }))
              }
              size="sm"
              mr={3}
              _hover={{ bgColor: 'transparent' }}
              _active={{ bgColor: 'transparent' }}
            />
          )}
          <Divider orientation="vertical" size="md" h={7} w="1px" />
          <Dropdown
            type={DropdownType.Select}
            variant="search"
            placeholder="All"
            options={options}
            shouldFitContent
            isControllable
            values={searchBy ? [searchBy] : []}
            onChange={([value]) => {
              setSearchData((prev) => ({
                ...prev,
                searchBy: value
              }));
            }}
          />
        </HStack>
      </InputRightElement>
    </InputGroup>
  );
};
