import { Button, MenuItem, MenuList } from '@chakra-ui/react';
import { MouseEventHandler, memo } from 'react';
import { FixedSizeList } from 'react-window';
import { OptionsMap } from 'types';
import { matchTwoString, NO_RESULTS_FOUND } from 'utils';

import { MenuOptionItem } from './MenuOptionItem';
import { SelectItem } from './SelectItem';
import { VirtualItemRow } from './VirtualItemRow';

type Props = {
  handleAddItem: (value: string) => void;
  optionsMap: OptionsMap;
  search: string;
  isSearchable: boolean;
  canAdd: boolean;
  createNewOption: (id: string, label: string) => void;
  selectedValues: string[];
};

export const Options = ({
  handleAddItem,
  optionsMap,
  search,
  isSearchable,
  canAdd,
  createNewOption,
  selectedValues
}: Props): JSX.Element => {
  const { order, getFormattedOrder, optionMap } = optionsMap;

  const formattedOrder =
    isSearchable && search
      ? getFormattedOrder((order) =>
          order.filter((key) => matchTwoString(optionMap[key].label, search))
        )
      : order;

  const withOptimization = formattedOrder.length > 100;

  const onClickItem: MouseEventHandler<HTMLDivElement> = (e) => {
    const { value } = e.target as HTMLButtonElement;

    if (value) handleAddItem(value);
  };

  if (withOptimization) {
    return (
      <MenuList onClick={onClickItem} overflowY="unset">
        <FixedSizeList
          itemData={formattedOrder}
          height={Math.min(formattedOrder.length * 44, 200)}
          itemCount={formattedOrder.length}
          itemSize={44}
          width="100%"
        >
          {(props) => (
            <VirtualItemRow
              {...props}
              optionMap={optionMap}
              search={search}
              isSearchable={isSearchable}
              selectedValues={selectedValues}
            />
          )}
        </FixedSizeList>
      </MenuList>
    );
  }

  return (
    <MenuList onClick={onClickItem}>
      {formattedOrder.length > 0 ? (
        formattedOrder.map((key) => (
          <MenuItem padding={0} margin={0} key={key}>
            <SelectItem
              option={optionMap[key]}
              search={search}
              isSearchable={isSearchable}
              isSelected={selectedValues.includes(optionMap[key].value)}
            />
          </MenuItem>
        ))
      ) : (
        <MenuItem padding={0} margin={0}>
          <MenuOptionItem data-readonly>
            {optionMap[NO_RESULTS_FOUND].label}
          </MenuOptionItem>
        </MenuItem>
      )}
      {canAdd && search && (
        <MenuItem padding={0} margin={0}>
          <MenuOptionItem
            onClick={(e) => {
              e.stopPropagation();
              createNewOption(search, search);
              handleAddItem(search);
            }}
          >
            <Button variant="link">Add &apos;{search}&apos;</Button>
          </MenuOptionItem>
        </MenuItem>
      )}
    </MenuList>
  );
};
