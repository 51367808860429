import { Portal } from '@chakra-ui/react';
import { ReactNode } from 'react';
import { useOutletContext } from 'react-router-dom';

export const SubHeader = ({
  children
}: {
  children: ReactNode;
}): JSX.Element => {
  const { subHeaderRef } = useOutletContext();

  return <Portal containerRef={subHeaderRef}>{children}</Portal>;
};
