import { BarDatum, ComputedBarDatum } from '@nivo/bar';
import { Theme as BarChartTheme } from '@nivo/core';
import { useRef, useState } from 'react';
import { useMount } from 'react-use';

type Props = ComputedBarDatum<BarDatum> & {
  theme: BarChartTheme;
  maxBarWidth: number;
};

export const BarChartLabel = ({
  data,
  width,
  height,
  y,
  theme,
  maxBarWidth
}: Props): JSX.Element => {
  const ref = useRef<SVGTextElement>(null);
  const [initWidth, setInitWidth] = useState(0);

  const { fontSize, fontWeight, fill } = theme.labels?.text || {};
  const labelBarOffsetPx = 10;

  useMount(() => {
    if (ref.current) {
      setInitWidth(ref.current.getBBox().width);
    }
  });

  const shouldDisplayInside =
    width > maxBarWidth / 8 &&
    (ref.current?.getBBox().width || initWidth) <= width;

  const transform = shouldDisplayInside
    ? `translate(${width / 2}, ${y + height / 2})`
    : `translate(${width + labelBarOffsetPx}, ${y + height / 2})`;

  return (
    <text
      ref={ref}
      transform={transform}
      textAnchor={shouldDisplayInside ? 'middle' : 'left'}
      dominantBaseline="central"
      fontSize={fontSize}
      fontWeight={fontWeight}
      fill={fill}
    >
      {data.formattedValue}
    </text>
  );
};
