import { ENDPOINTS } from 'api';
import { serializeBodyFilters } from 'api/api.utils';
import axios from 'axios';
import { GlobalFilters } from 'types';

import { ContactUsTicketTotalActive } from './ContactUs.types';

export const fetchTotalTicketsActive = async ({
  globalFilters
}: {
  globalFilters: GlobalFilters;
}): Promise<number> => {
  const { data } = await axios.post<ContactUsTicketTotalActive>(
    ENDPOINTS.CONTACT_US_TOTAL,
    serializeBodyFilters({
      globalFilters
    })
  );

  return data.totalItems;
};
