import { FormField, InputElement } from 'enum';
import * as yup from 'yup';

import { EditTextFormData } from './EditText.types';

export const EditTextFormSchema = {
  fields: {
    [FormField.Text]: {
      type: InputElement.Input,
      translationKey: 'edit_value',
      getPlaceholder: () => ''
    }
  },
  gridLayout: {
    templateAreas: `
    "${FormField.Text}"
  `,
    gridTemplateColumns: '1fr'
  }
} as const;

export const EditTextValidationSchema: yup.SchemaOf<EditTextFormData> =
  yup.object({
    [FormField.Text]: yup.string().nullable()
  });
