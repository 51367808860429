import { Box, Heading, VStack } from '@chakra-ui/react';
import { FileUpload } from 'components';
import { useState } from 'react';

export const File = (): JSX.Element => {
  const [files, setFiles] = useState<{ file: File; url: string }[]>([]);
  return (
    <VStack
      w="400px"
      ml="20px"
      spacing={3}
      align="flex-start"
      alignItems="stretch"
    >
      <Box>
        <Heading as="h3" size="md" mt={3} mb={2}>
          File loader
        </Heading>
        <FileUpload onSelectFile={setFiles} files={files} />
      </Box>
    </VStack>
  );
};
