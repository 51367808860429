import {
  Box,
  Center,
  Heading,
  HStack,
  Icon,
  Text,
  VStack
} from '@chakra-ui/react';

type Props = {
  color: string;
  title: number;
  subtitle: string;
  icon: typeof Icon;
};

export const StatisticCard = ({
  color,
  title,
  subtitle,
  icon
}: Props): JSX.Element => (
  <Box
    flex="1"
    border="1px solid"
    borderColor="gray.300"
    borderRadius="1.25rem"
    padding={4}
  >
    <HStack>
      <Center boxSize="3rem" bgColor={color} borderRadius="md">
        <Icon as={icon} color="white" fontSize="1.5rem" />
      </Center>
      <VStack align="flex-start" marginInlineStart={4}>
        <Heading size="l">{title}</Heading>
        <Text textStyle="srg" color="subtext" marginTop={1}>
          {subtitle}
        </Text>
      </VStack>
    </HStack>
  </Box>
);
