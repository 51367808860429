import { useQuery } from '@tanstack/react-query';
import { fetchTotalTicketsActive } from 'api';
import { QueryKey } from 'enum/api';
import { useAppSelector } from 'hooks';
import { selectAppFilters } from 'store';

export const useTicketsActiveTotal = ({
  enabled
}: {
  enabled?: boolean;
} = {}) => {
  const globalFilters = useAppSelector(selectAppFilters);

  return useQuery<number>(
    [QueryKey.TicketTotal, globalFilters],
    () => fetchTotalTicketsActive({ globalFilters }),
    {
      retry: false,
      refetchOnWindowFocus: false,
      initialData: 0,
      enabled
    }
  );
};
