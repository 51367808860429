import { Icon, IconProps } from '@chakra-ui/react';

export const DangerIcon = (props: IconProps): JSX.Element => (
  <Icon viewBox="0 0 24 24" fill="none" {...props}>
    <path
      d="M2 17.9261C2 17.3187 2.15479 16.7214 2.44975 16.1904L8.63566 5.0558C9.18399 4.06881 10.1381 3.37239 11.2452 3.15096V3.15096C11.7435 3.05131 12.2565 3.05131 12.7548 3.15096V3.15096C13.8619 3.37239 14.816 4.06881 15.3643 5.05581L21.5502 16.1904C21.8452 16.7214 22 17.3187 22 17.9261V17.9261C22 19.8999 20.3999 21.5 18.4261 21.5H5.57391C3.60009 21.5 2 19.8999 2 17.9261V17.9261Z"
      stroke="currentColor"
      strokeWidth="1.5"
    />
    <path
      d="M12.75 9C12.75 8.58579 12.4142 8.25 12 8.25C11.5858 8.25 11.25 8.58579 11.25 9L12.75 9ZM11.25 13C11.25 13.4142 11.5858 13.75 12 13.75C12.4142 13.75 12.75 13.4142 12.75 13L11.25 13ZM12.75 13L12.75 9L11.25 9L11.25 13L12.75 13Z"
      fill="currentColor"
    />
    <path
      d="M11.25 16C11.25 15.5858 11.5858 15.25 12 15.25C12.4142 15.25 12.75 15.5858 12.75 16L11.25 16ZM12.75 16.5C12.75 16.9142 12.4142 17.25 12 17.25C11.5858 17.25 11.25 16.9142 11.25 16.5L12.75 16.5ZM11.25 16.5L11.25 16L12.75 16L12.75 16.5L11.25 16.5Z"
      fill="currentColor"
    />
  </Icon>
);
