import { Icon, IconProps } from '@chakra-ui/react';

export const ImageIcon = (props: IconProps): JSX.Element => (
  <Icon viewBox="0 0 25 24" fill="none" {...props}>
    <rect
      x="2.5"
      y="2"
      width="20"
      height="20"
      rx="5"
      stroke="currentColor"
      strokeWidth="1.5"
    />
    <path
      d="M2.56407 16.8897C2.22701 17.1305 2.14894 17.5989 2.3897 17.9359C2.63046 18.273 3.09887 18.3511 3.43593 18.1103L2.56407 16.8897ZM22.0199 16.5762C22.3381 16.8413 22.811 16.7983 23.0762 16.4801C23.3413 16.1619 23.2983 15.689 22.9801 15.4238L22.0199 16.5762ZM7.57822 16.0782L7.04789 16.6085L7.57822 16.0782ZM3.43593 18.1103L5.69512 16.4966L4.82327 15.276L2.56407 16.8897L3.43593 18.1103ZM7.04789 16.6085L8.12114 17.6818L9.1818 16.6211L8.10855 15.5479L7.04789 16.6085ZM10.8789 17.6818L15.868 12.6927L14.8073 11.632L9.8182 16.6211L10.8789 17.6818ZM17.2827 12.6285L22.0199 16.5762L22.9801 15.4238L18.2429 11.4762L17.2827 12.6285ZM15.868 12.6927C16.252 12.3087 16.8655 12.2808 17.2827 12.6285L18.2429 11.4762C17.2297 10.6318 15.7399 10.6994 14.8073 11.632L15.868 12.6927ZM8.12114 17.6818C8.88266 18.4433 10.1173 18.4433 10.8789 17.6818L9.8182 16.6211C9.64246 16.7969 9.35754 16.7969 9.1818 16.6211L8.12114 17.6818ZM5.69512 16.4966C6.1128 16.1983 6.68494 16.2456 7.04789 16.6085L8.10855 15.5479C7.22711 14.6664 5.83762 14.5515 4.82327 15.276L5.69512 16.4966Z"
      fill="currentColor"
    />
    <circle
      r="2"
      transform="matrix(-1 0 0 1 8.5 8)"
      stroke="currentColor"
      strokeWidth="1.5"
    />
  </Icon>
);
