import { Icon, IconProps } from '@chakra-ui/react';

export const MailIcon = (props: IconProps): JSX.Element => (
  <Icon viewBox="0 0 24 24" fill="none" {...props}>
    <rect
      x="2"
      y="4"
      width="20"
      height="16"
      rx="5"
      stroke="currentColor"
      strokeWidth="1.5"
    />
    <path
      d="M6 9L10.8 12.6C11.5111 13.1333 12.4889 13.1333 13.2 12.6L18 9"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </Icon>
);
