import { FormField, InputElement } from 'enum';
import { ParameterType } from 'enum/api';
import { GetOptionsArg } from 'types/form';
import { generateOptionsFromSettings } from 'utils';

export const freeMessagesFormFields = [
  {
    type: InputElement.Select,
    name: FormField.CustomerType,
    translationKey: 'customer_type',
    getPlaceholder: () => 'Customer type',
    getOptions: ({ t, settings }: GetOptionsArg) =>
      generateOptionsFromSettings(ParameterType.CustomerType, t, settings)
  },
  {
    type: InputElement.NumberInput,
    name: FormField.MessageAmount,
    translationKey: 'num_of_free_messages',
    getPlaceholder: () => '',
    min: 0
  },
  {
    type: InputElement.NumberInput,
    name: FormField.ConversationAmount,
    translationKey: 'num_of_free_conversations',
    getPlaceholder: () => '',
    min: 0
  }
] as const;

export const getInitialFieldsState = () => ({
  [FormField.CustomerType]: undefined,
  [FormField.MessageAmount]: undefined,
  [FormField.ConversationAmount]: undefined
});
