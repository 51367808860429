import { useQuery } from '@tanstack/react-query';
import { fetchModerationItemsTotal, ModerationTotal } from 'api';
import { ModerationPage } from 'enum';
import { QueryKey } from 'enum/api';
import { useAppSelector } from 'hooks';
import { selectAppFilters } from 'store/app';

const getInitialData = () => ({
  [ModerationPage.GalleryPhoto]: 0,
  [ModerationPage.Comment]: 0,
  [ModerationPage.Motto]: 0,
  [ModerationPage.SomethingAboutMe]: 0
});

export const useModerationItemsTotal = ({
  enabled
}: {
  enabled?: boolean;
} = {}) => {
  const globalFilters = useAppSelector(selectAppFilters);

  return useQuery<ModerationTotal>(
    [QueryKey.ModerationTotal, globalFilters],
    () => fetchModerationItemsTotal({ globalFilters }),
    {
      refetchOnWindowFocus: false,
      initialData: getInitialData,
      enabled
    }
  );
};
