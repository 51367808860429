import { chakra } from '@chakra-ui/react';
import { GroupBase, MultiValueGenericProps } from 'react-select';

import { useStyles } from '../LocationDropdown.utils';

export const MultiValueContainer = <
  Option,
  IsMulti extends boolean,
  Group extends GroupBase<Option>
>(
  props: MultiValueGenericProps<Option, IsMulti, Group>
): JSX.Element => {
  const { children, innerProps } = props;

  const styles = useStyles();

  return (
    <chakra.div __css={styles.multiValueContainer} {...innerProps}>
      {children}
    </chakra.div>
  );
};
