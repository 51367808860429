import { useMutation, useQueryClient } from '@tanstack/react-query';
import { fetchDeleteDomainConfig } from 'api';
import { QueryKey } from 'enum/api';

export const useDeleteDomainConfig = () => {
  const queryClient = useQueryClient();

  return useMutation(fetchDeleteDomainConfig, {
    onSuccess() {
      queryClient.invalidateQueries([QueryKey.DomainsConfigList]);
    }
  });
};
