import { BaseChartDataTemplate, StatisticsDataResponse } from 'api';
import {
  CustomStackedBarChartData,
  CustomStackedBarChartSettings
} from 'containers';
import { GenderTruncated, ParameterType } from 'enum';
import { useAppSelector } from 'hooks';
import sumBy from 'lodash/sumBy';
import { useMemo } from 'react';
import { selectSettings } from 'store';

type Props<T extends BaseChartDataTemplate> = {
  apiData?: StatisticsDataResponse<T>[];
  chartSettings: CustomStackedBarChartSettings<T>;
};

export const useCustomStackedBarChartData = <T extends BaseChartDataTemplate>({
  apiData,
  chartSettings
}: Props<T>): CustomStackedBarChartData[] => {
  const settings = useAppSelector(selectSettings);

  return useMemo(() => {
    if (!apiData?.length) return [];

    const { colors, transformChartData } = chartSettings;
    const totalAmount = apiData.reduce(
      (sum, item) =>
        (sum += sumBy<number>(item.data.map(({ value }) => +value))),
      0
    );

    const settingsOptions = settings[ParameterType.Gender];
    const mainGendersData: StatisticsDataResponse<T>[] = [];
    const otherGendersData: StatisticsDataResponse<T>[] = [];
    let processedData;

    apiData.map((item) =>
      [GenderTruncated.Female, GenderTruncated.Male].includes(
        settingsOptions.find(({ optionId }) => optionId === item.id)
          ?.systemId as GenderTruncated
      )
        ? mainGendersData.push(item)
        : otherGendersData.push(item)
    );

    if (otherGendersData.length) {
      processedData = [
        ...mainGendersData,
        otherGendersData.reduce(
          (acc, item) => ({
            ...acc,
            data: [
              {
                ...acc.data[0],
                value: acc.data[0].value + +item.data[0].value
              }
            ]
          }),
          {
            id: GenderTruncated.Other,
            data: [
              {
                type: otherGendersData[0].data[0].type,
                value: 0
              }
            ]
          }
        )
      ];
    } else {
      processedData = mainGendersData;
    }

    return (apiData || []).map<CustomStackedBarChartData>(
      ({ id, data }, index) => {
        const totalAmount = sumBy(
          data,
          (series) => transformChartData(series).value
        );

        return {
          id,
          data: data.map<CustomStackedBarChartData['data'][number]>(
            (series) => {
              const { id, value } = transformChartData(series);

              return {
                id,
                value,
                color: colors[index],
                percentage:
                  totalAmount === 0
                    ? 100 / data.length
                    : (value * 100) / totalAmount
              };
            }
          )
        };
      }
    );
  }, [apiData, chartSettings]);
};
