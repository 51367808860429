import { createColumnHelper } from '@tanstack/react-table';
import { AdminLoginSessionResponse } from 'api';
import { getDateFormattedString } from 'utils';

import { AdminNicknameCell } from './Cells';

const columnHelper = createColumnHelper<AdminLoginSessionResponse>();

export const loginSessionColumns = [
  columnHelper.display({
    id: 'nickname',
    cell: AdminNicknameCell,
    header: ({ table }) => table.options.meta?.t('keywords.user_name')
  }),
  columnHelper.accessor('loginAt', {
    cell: ({ getValue, table }) =>
      getDateFormattedString({
        date: getValue(),
        t: table.options.meta?.t
      }),
    header: ({ table }) =>
      table.options.meta?.t('keywords.login_date_and_time'),
    enableSorting: false
  }),
  columnHelper.accessor('lastActiveAt', {
    cell: ({ getValue, table }) => {
      const value = getValue();

      return (
        value &&
        getDateFormattedString({
          date: getValue(),
          t: table.options.meta?.t
        })
      );
    },
    header: ({ table }) =>
      table.options.meta?.t('keywords.last_active_date_and_time'),
    enableSorting: false
  })
];
