import { Link } from '@chakra-ui/react';
import { PropsWithChildren } from 'react';
import { LinkProps, Link as RouterLink } from 'react-router-dom';

type Props = PropsWithChildren<
  LinkProps & {
    shouldUnstyled?: boolean;
    isDisabled?: boolean;
  }
>;

export const NavigationLink = ({
  children,
  shouldUnstyled,
  isDisabled,
  ...rest
}: Props): JSX.Element => {
  if (shouldUnstyled) {
    return (
      <RouterLink
        style={{
          cursor: 'pointer'
        }}
        {...rest}
      >
        {children}
      </RouterLink>
    );
  }

  return (
    <Link as={RouterLink} aria-disabled={isDisabled} {...rest}>
      {children}
    </Link>
  );
};
