import { useParams } from 'react-router-dom';

export const useCustomerIdFromUrl = (): string => {
  const { customerId } = useParams();

  if (!customerId)
    throw new Error(
      'useCustomerIdFromUrl hook should be used only for customers page'
    );

  return customerId;
};
