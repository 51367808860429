import { FormControl, FormLabel } from '@chakra-ui/react';
import { FormError } from 'components';
import { isDate } from 'date-fns';
import { InputElement } from 'enum';
import { useMemo } from 'react';
import DatePicker from 'react-date-picker';
import { useController } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { FormComponentProps } from '../FormConstructor.types';

type Props = FormComponentProps<{ type: InputElement.DatePicker }>;

export const FormDatePicker = ({
  type,
  translationKey,
  name,
  isDisabled,
  ...rest
}: Props): JSX.Element => {
  const [t] = useTranslation();

  const {
    field: { onChange, onBlur, value },
    fieldState: { error }
  } = useController({
    name
  });

  const handleChange = (pickerValue: Date | null | [Date | null, unknown]) => {
    const value = Array.isArray(pickerValue) ? pickerValue[0] : pickerValue;

    if (value && isDate(value)) {
      onChange(value.toISOString());
    } else {
      onChange(undefined);
    }
  };

  return (
    <FormControl isInvalid={!!error}>
      <FormLabel>{t(`keywords.${translationKey}`)}</FormLabel>
      <DatePicker
        {...rest}
        disabled={isDisabled}
        onChange={handleChange}
        onBlur={onBlur}
        value={value ?? null}
        format="dd/MM/yyyy"
      />

      <FormError errorKey={error?.message} />
    </FormControl>
  );
};
