import {
  AspectRatio,
  Box,
  HStack,
  Image,
  Tag,
  useDisclosure,
  VStack
} from '@chakra-ui/react';
import { ModerationGalleryPhotoResponse } from 'api';
import { UserFeedCard, UserProfile } from 'components';
import {
  ContentContainer,
  ModalContainer,
  ScrollableContainer
} from 'containers';
import { format } from 'date-fns';
import {
  DeclineItemType,
  ModalType,
  ModerationPostStatus,
  ScrollDirection
} from 'enum';
import { useAppDispatch, useUpdateGalleryPhoto } from 'hooks';
import { ModerationActionType } from 'pages/Moderation/Moderation.utils';
import { ModerationActions } from 'pages/Moderation/components';
import { memo, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { openModal } from 'store/modal';

export const UserCard = memo(
  ({ data }: { data: ModerationGalleryPhotoResponse }): JSX.Element => {
    const dispatch = useAppDispatch();
    const [t] = useTranslation();
    const { isOpen, onOpen, onClose } = useDisclosure();
    const [feedImageUrl, setFeedImageUrl] = useState<string | null>(null);
    const [croppedImage, setCroppedImage] = useState<File | undefined>();

    const { user, photos } = data;

    const [activePhotoIndex, setActivePhotoIndex] = useState(10);

    const { mutate: updateGalleryPhoto } = useUpdateGalleryPhoto();

    const photoLength = photos.length;

    useEffect(() => {
      if (activePhotoIndex > photoLength - 1) {
        setActivePhotoIndex(0);
        setFeedImageUrl(null);
      }
    }, [photoLength, activePhotoIndex]);

    const activePhoto = photos[activePhotoIndex] || photos[0];

    const handleClick = (type: ModerationActionType) => {
      switch (type) {
        case ModerationActionType.Decline:
          setFeedImageUrl(null);

          return dispatch(
            openModal({
              id: ModalType.DeclineItem,
              meta: {
                photoId: activePhoto.id,
                type: DeclineItemType.GalleryPhoto
              }
            })
          );
        case ModerationActionType.AutoDecline:
          setFeedImageUrl(null);

          return updateGalleryPhoto({
            photoId: activePhoto.id,
            status: ModerationPostStatus.AutoDeclined
          });
        case ModerationActionType.Approve:
          setFeedImageUrl(null);

          return updateGalleryPhoto(
            {
              photoId: activePhoto.id,
              status: ModerationPostStatus.Approved,
              croppedImage
            },
            {
              onSuccess: () => {
                setCroppedImage(undefined);
              },
              onError: () => {
                setCroppedImage(undefined);
              }
            }
          );
        case ModerationActionType.Edit:
          return dispatch(
            openModal({
              id: ModalType.EditModerationPhoto,
              meta: {
                photo: activePhoto,
                setFeedImageUrl,
                setCroppedImage
              }
            })
          );
      }
    };

    return (
      <ContentContainer>
        <ModalContainer isOpen={isOpen} onClose={onClose} size="2xl">
          <Image src={photos[activePhotoIndex]?.imageUrl} loading="lazy" />
        </ModalContainer>
        <VStack spacing={6} alignItems="stretch">
          <HStack justifyContent="space-between" alignItems="flex-start">
            <UserProfile user={user} />
            <Box minWidth="45%">
              {photos.length > 0 && (
                <ScrollableContainer
                  scrollDirection={ScrollDirection.Horizontal}
                >
                  <HStack spacing={2}>
                    {photos.map((photo, index) => (
                      <AspectRatio
                        key={photo.id}
                        ratio={6 / 7}
                        flex="0 0 25%"
                        onClick={() => {
                          setActivePhotoIndex(index);
                          setFeedImageUrl(null);
                          onOpen();
                        }}
                      >
                        <Image
                          src={photo.imageUrl}
                          borderRadius="xl"
                          loading="lazy"
                        />
                      </AspectRatio>
                    ))}
                  </HStack>
                </ScrollableContainer>
              )}
              <HStack spacing={5} alignItems="flex-start" marginTop="1rem">
                <Box position="relative" width="80%">
                  <AspectRatio ratio={4 / 3} minWidth="100%">
                    <Image
                      src={activePhoto.imageUrl}
                      borderRadius="xl"
                      loading="lazy"
                    />
                  </AspectRatio>
                  <Tag
                    size="sm"
                    variant="solid"
                    colorScheme="status.blue"
                    position="absolute"
                    top="3"
                    left="3"
                  >
                    {format(new Date(activePhoto.postedAt), 'dd.MM.yyyy')}
                  </Tag>
                  <Tag
                    size="sm"
                    variant="solid"
                    colorScheme="status.blue"
                    position="absolute"
                    top="3"
                    right="3"
                  >
                    {activePhoto.main
                      ? t('keywords.profile_photo')
                      : t('keywords.gallery_photo')}
                  </Tag>
                </Box>
                <Box>
                  <UserFeedCard
                    user={{
                      displayedNickname: user.displayedNickname,
                      country: user.country,
                      city: user.city,
                      state: user.state,
                      birthDate: user.birthDate,
                      online: user.online,
                      lastOnlineAt: user.lastOnlineAt,
                      motto: user.motto
                    }}
                    imageUrl={feedImageUrl || activePhoto.imageUrl}
                  />
                </Box>
              </HStack>
              {activePhoto.status === ModerationPostStatus.NotAccepted && (
                <ModerationActions
                  handleClick={handleClick}
                  gridProps={{
                    marginTop: 10
                  }}
                />
              )}
            </Box>
          </HStack>
        </VStack>
      </ContentContainer>
    );
  }
);
