import { SystemStyleFunction } from '@chakra-ui/react';
import { ComponentStyleConfig } from '@chakra-ui/theme';

const baseStyle: SystemStyleFunction = () => ({
  button: {
    width: '100%',
    minWidth: 0,
    position: 'relative',
    appearance: 'none',
    "&[data-empty='true']": {
      color: 'gray.500'
    },
    '> span': {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center'
    }
  }
});

const variantSelect: SystemStyleFunction = (props) => {
  const { colorScheme: c } = props;
  return {
    button: {
      border: '1px solid',
      borderColor: 'inherit',
      bg: 'white',
      pe: 0,
      py: 1,
      _hover: {
        borderColor: `${c}.500`
      },
      _readOnly: {
        boxShadow: 'none',
        userSelect: 'all'
      },
      _invalid: {
        borderColor: 'error'
      },
      _focusVisible: {
        zIndex: 1,
        borderColor: `${c}.500`
      },
      '> span': {
        pointerEvents: 'unset'
      },
      _disabled: {
        bgColor: 'gray.100',
        borderColor: 'inherit',
        color: 'gray.600',
        pointerEvents: 'none'
      }
    },
    list: {
      maxBlockSize: 'xs',
      overflowY: 'auto',
      zIndex: 'dropdown'
    },
    item: {
      cursor: 'pointer',
      px: 3,
      py: 3,
      _hover: {
        backgroundColor: 'primary.100'
      },
      _focus: {
        backgroundColor: 'primary.100'
      },
      _disabled: {
        backgroundColor: 'disabled',
        cursor: 'default',
        pointerEvents: 'none'
      },
      _selected: {
        backgroundColor: 'primary.100'
      },
      _readOnly: {
        pointerEvents: 'none'
      }
    }
  };
};
const variantUnstyled: SystemStyleFunction = (props) => ({
  button: {
    mx: 0
  }
});

const variantRange: SystemStyleFunction = (props) => ({
  ...variantSelect(props)
});

const variantSearch: SystemStyleFunction = (props) => ({
  ...variantSelect(props),
  button: {}
});

const variants = {
  select: variantSelect,
  range: variantRange,
  unstyled: variantUnstyled,
  search: variantSearch
};

export const Menu: ComponentStyleConfig = {
  baseStyle,
  sizes: {
    md: {
      button: {
        fontSize: 'md',
        px: 3,
        minH: 10,
        borderRadius: 'md'
      }
    }
  },
  variants,
  defaultProps: {
    size: 'md',
    variant: 'select'
  }
};
