import { FormControl, FormLabel } from '@chakra-ui/react';
import { FormError, LocationDropdown } from 'components';
import { InputElement } from 'enum';
import { useLocationAutocomplete } from 'hooks';
import { useController } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { GoogleService } from 'services/google';
import { getLocationDetails } from 'utils';

import { FormComponentProps, Option } from '../FormConstructor.types';

export type LocationFormData = {
  country?: string;
  city?: string;
};

export type UserLocationDetails = {
  country: string;
  city: string;
  state?: string;
  longitude: number;
  latitude: number;
  placeId?: string;
};

type Props = FormComponentProps<{ type: InputElement.LocationSelect }>;

const getInitialValue = (location: LocationFormData) =>
  `${location?.city}, ${location?.country}`;

export const Location = ({ name, translationKey }: Props): JSX.Element => {
  const [t, { language }] = useTranslation();

  const {
    field: { onChange, onBlur, value },
    fieldState: { error }
  } = useController<{ [key: string]: LocationFormData | undefined }>({ name });

  const handleChange = async (option: Option | null) => {
    const locationDetails = await GoogleService.getFormattedPlaceDetails(
      language,
      {
        language,
        placeId: option?.value || ''
      },
      getLocationDetails
    );

    delete locationDetails?.state;

    onChange({
      ...locationDetails
    });
  };

  const { loadOptions } = useLocationAutocomplete({});

  const initialValue = {
    value: 'initial',
    label: value ? getInitialValue(value) : ''
  };

  return (
    <FormControl isInvalid={!!error}>
      <FormLabel>{t(`keywords.${translationKey}`)}</FormLabel>
      <LocationDropdown
        placeholder={t('keywords.location')}
        useAsync
        isSearchable
        isClearable
        hideSelectedOptions={false}
        onBlur={onBlur}
        loadOptions={loadOptions}
        onChange={handleChange}
        defaultOptions={[]}
        defaultValue={initialValue}
      />
      <FormError errorKey={error?.message} />
    </FormControl>
  );
};
