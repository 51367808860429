import { Grid, GridItem } from '@chakra-ui/react';
import { ContentContainer } from 'containers';
import { CustomerSection } from 'enum';
import { DropdownTitle } from 'pages/Customers/components';
import { Outlet, useLocation } from 'react-router-dom';
import { getCustomerSectionByUrl } from 'routes/routes.utils';

export const AdminInteraction = ({
  sections
}: {
  sections: CustomerSection[] | null;
}): JSX.Element => {
  const { pathname } = useLocation();

  const selectedValue = getCustomerSectionByUrl(pathname) || undefined;

  const templateAreas =
    selectedValue === CustomerSection.LinkedAccounts
      ? `"title actions"
         "filters filters"
         "table table"
         `
      : `"title actions"
         "table table"
         `;

  const templateRows =
    selectedValue === CustomerSection.LinkedAccounts
      ? 'auto min-content 1fr'
      : 'auto 1fr';

  return (
    <ContentContainer height="100%">
      <Grid
        templateAreas={templateAreas}
        templateColumns="auto 1fr"
        templateRows={templateRows}
        rowGap={6}
        height="100%"
      >
        <GridItem area="title">
          <DropdownTitle sections={sections} />
        </GridItem>
        <Outlet />
      </Grid>
    </ContentContainer>
  );
};
