import { ModalContainer } from 'containers';
import { ModalType, PageName } from 'enum';
import { useModalActions } from 'hooks';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { SIDE_NAV_LINK_BY_PAGE } from 'routes/routes.utils';
import { ParamType } from 'types';

export const Inactivity = (): JSX.Element => {
  const [t] = useTranslation();
  const navigate = useNavigate();

  const { isOpen, onClose } = useModalActions(ModalType.Inactivity);

  const actions: ParamType<typeof ModalContainer, 'footerButtons'> = [
    {
      text: t('actions.login'),
      colorScheme: 'primary',
      onClick: () => {
        navigate(SIDE_NAV_LINK_BY_PAGE[PageName.Login]);
      }
    }
  ];

  return (
    <ModalContainer
      header={t('attribute.title.expired_session')}
      footerButtons={actions}
      isOpen={isOpen}
      onClose={onClose}
      closeOnEsc={false}
      closeOnOverlayClick={false}
      shouldHideCloseButton
    />
  );
};
