import { ComponentStyleConfig } from '@chakra-ui/theme';

export const Heading: ComponentStyleConfig = {
  sizes: {
    xxl: {
      fontSize: 'xxl',
      fontWeight: 'bold'
    },
    xl: {
      fontSize: 'xl',
      fontWeight: 'bold'
    },
    l: {
      fontSize: 'l',
      fontWeight: 'semibold'
    },
    m: {
      fontSize: 'l',
      fontWeight: 'medium'
    }
  }
};
