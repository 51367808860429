import { Grid, GridItem } from '@chakra-ui/react';
import { FormElement } from 'containers';
import { InputElement, LengthUnits } from 'enum';
import { Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import { Option } from 'types';
import { GetOptionsArg } from 'types/form';

import { GeneralSettingsFormField } from '../FormContent.types';

export const LengthUnit = (): JSX.Element => {
  const [t] = useTranslation();

  return (
    <Fragment>
      <GridItem>{t('attribute.description.length_unit')}</GridItem>
      <GridItem>
        <Grid
          templateAreas={`
            "${GeneralSettingsFormField.LengthUnit} . . . . ."
          `}
        >
          <GridItem>
            <FormElement
              type={InputElement.Select}
              name={GeneralSettingsFormField.LengthUnit}
              translationKey="length_unit"
              getPlaceholder={() => 'Select'}
              getOptions={({ t, settings }: GetOptionsArg) =>
                Object.values(LengthUnits).map((val) => ({
                  label: val,
                  value: val
                })) as Option[]
              }
              autoComplete="off"
            />
          </GridItem>
        </Grid>
      </GridItem>
    </Fragment>
  );
};
