import { SubHeader } from 'containers';
import { useCustomerInfo } from 'hooks';
import { Fragment } from 'react';
import { Outlet, useOutletContext, useParams } from 'react-router-dom';

import { CustomerTabs } from './CustomerTabs';

export const CustomerInfo = (): JSX.Element => {
  const params = useParams();

  const context = useOutletContext();

  const { data } = useCustomerInfo(params.customerId || '');

  return (
    <Fragment>
      <SubHeader>
        <CustomerTabs />
      </SubHeader>
      {!!data && (
        <Outlet
          context={{
            ...context,
            customer: data
          }}
        />
      )}
    </Fragment>
  );
};
