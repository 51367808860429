import { ENDPOINTS } from 'api';
import axios from 'axios';

import { UpdateArticleStatusRequestData } from './BlogManagement.types';

export const fetchUpdateArticleStatus = ({
  articleId,
  ...body
}: { articleId: string } & UpdateArticleStatusRequestData) =>
  axios.patch(`${ENDPOINTS.ARTICLE}/${articleId}/status`, body);
