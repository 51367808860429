import kebabCase from 'lodash/kebabCase';
import {
  BLOG_SECTION,
  BLOG_SECTION_LIST
} from 'pages/BlogManagement/BlogManagement.utils';
import { Navigate, RouteObject } from 'react-router-dom';

export const BLOG_ROUTES: RouteObject[] = BLOG_SECTION_LIST.map<RouteObject>(
  (section) => {
    const Section = BLOG_SECTION[section];

    return {
      path: kebabCase(section),
      element: <Section />
    };
  }
).concat([
  {
    index: true,
    element: <Navigate to={kebabCase(BLOG_SECTION_LIST[0])} replace />
  }
]);
