import { DefaultValues } from 'react-hook-form';
import { TranslationType } from 'types';
import { ICustomer } from 'types/customer';
import * as yup from 'yup';

import { NotificationSettingsFormData } from './NotificationSettings.types';

type FormTitles = keyof TranslationType['email_notifications_settings'];

type NotificationsSettingsConfigSchema = ReadonlyArray<{
  categoryTitle: FormTitles;
  settings: ReadonlyArray<{ apiFieldName: string; title: FormTitles }>;
}>;

export const settingsListConfig: NotificationsSettingsConfigSchema = [
  {
    categoryTitle: 'chat_notifications',
    settings: [
      {
        apiFieldName: 'NewMessage',
        title: 'new_message'
      }
    ]
  },
  {
    categoryTitle: 'profile_notifications',
    settings: [
      {
        apiFieldName: 'SomebodyLikedMyProfile',
        title: 'somebody_liked_my_profile'
      },
      {
        apiFieldName: 'SomebodyWatchedMyProfile',
        title: 'somebody_watched_my_profile'
      },
      {
        apiFieldName: 'SomebodyAddedMeToFavorites',
        title: 'somebody_added_me_to_favorites'
      }
    ]
  },
  {
    categoryTitle: 'content_notifications',
    settings: [
      {
        apiFieldName: 'SomebodyCommentedMyPhoto',
        title: 'somebody_commented_on_my_photo'
      },
      {
        apiFieldName: 'SomebodyLikedMyPhoto',
        title: 'somebody_liked_my_photo'
      },
      {
        apiFieldName: 'SomebodyLikedMyComment',
        title: 'somebody_liked_my_comment'
      }
    ]
  },
  {
    categoryTitle: 'admin_notifications',
    settings: [
      {
        apiFieldName: 'MessageFromAdmin',
        title: 'message_from_admin'
      }
    ]
  }
];

export const fieldNames = settingsListConfig
  .map(({ settings }) => settings)
  .flat()
  .map(({ apiFieldName }) => apiFieldName);

export const getDefaultFormValues = (
  customer: ICustomer
): DefaultValues<NotificationSettingsFormData> =>
  customer.config.notificationPreferences || {};

export const changeNotificationsSettingsValidationSchema = yup
  .object()
  .shape(Object.fromEntries(fieldNames.map((name) => [name, yup.boolean()])));
