import { GridItem } from '@chakra-ui/react';
import { yupResolver } from '@hookform/resolvers/yup';
import { FormConstructor, ModalContainer } from 'containers';
import { FormField, ModalType } from 'enum';
import { useFormValidation, useModalActions } from 'hooks';
import { useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { ParamType } from 'types';

import { RestrictionViewCustomersFormData } from './RestrictionViewCustomers.types';
import {
  restrictionViewCustomersFormSchema,
  restrictionViewCustomersValidationSchema
} from './RestrictionViewCustomers.utils';

export const RestrictionViewCustomers = (): JSX.Element => {
  const [t] = useTranslation();

  const form = useForm<RestrictionViewCustomersFormData>({
    mode: 'onSubmit',
    resolver: yupResolver(restrictionViewCustomersValidationSchema),
    defaultValues: {
      [FormField.EverybodyCanSeeEverybody]: true
    }
  });

  const { handleSubmit, reset, watch } = form;

  const { isOpen, onClose, meta } = useModalActions(
    ModalType.RestrictionViewCustomers,
    {
      onClose: reset
    }
  );

  const isEverybodyCanSeeEverybody = watch(FormField.EverybodyCanSeeEverybody);

  useEffect(() => {
    reset({
      [FormField.CustomerType]: null,
      [FormField.OtherCustomerType]: null,
      [FormField.Countries]: [],
      [FormField.EverybodyCanSeeEverybody]: isEverybodyCanSeeEverybody,
      [FormField.AppliedForFreeProgramType]: undefined
    });
  }, [reset, isEverybodyCanSeeEverybody]);

  useEffect(() => {
    if (meta) {
      reset(meta.initialData);
    }
  }, [reset, meta]);

  const onSubmit = handleSubmit((data) => {
    if (meta) {
      meta.onUpdateData(data);
      onClose();
    }
  });

  const isFormFilled = useFormValidation<RestrictionViewCustomersFormData>(
    form,
    restrictionViewCustomersValidationSchema
  );

  const actions: ParamType<typeof ModalContainer, 'footerButtons'> = [
    {
      text: t('actions.discard'),
      variant: 'ghostGray'
    },
    {
      text: t('actions.save_changes'),
      colorScheme: 'primary',
      isDisabled: !isFormFilled,
      isPreventClose: true,
      onClick: () => {
        onSubmit();
      }
    }
  ];

  return (
    <ModalContainer
      header={t(
        'attribute.title.restriction_on_interaction_customer_from_another_country'
      )}
      footerButtons={actions}
      isOpen={isOpen}
      onClose={onClose}
      size="3xl"
      modalBodyProps={{
        overflow: 'unset',
        overflowX: 'unset'
      }}
    >
      <FormProvider {...form}>
        <FormConstructor formSchema={restrictionViewCustomersFormSchema}>
          <GridItem area="cant-see" alignSelf="flex-end" color="subtext">
            {t('keywords.cant_see')}
          </GridItem>
        </FormConstructor>
      </FormProvider>
    </ModalContainer>
  );
};
