import { createMultiStyleConfigHelpers } from '@chakra-ui/styled-system';

import { baseStyle } from './baseStyles';
import { parts } from './parts';
import { variantButton, variantButton3Col, variantOutline } from './variants';

const { defineMultiStyleConfig, definePartsStyle } =
  createMultiStyleConfigHelpers(parts);

const variants = {
  outline: variantOutline,
  button: variantButton,
  button3Col: variantButton3Col
};

const sizes = {
  md: definePartsStyle((props) => {
    const variant = props.variant as keyof typeof variants;

    switch (variant) {
      case 'button':
      case 'button3Col':
        return {
          control: {
            fontSize: 'md'
          }
        };
      default:
        return {
          control: {
            fontSize: 'md'
          }
        };
    }
  })
};

export const dropdownTheme = defineMultiStyleConfig({
  baseStyle,
  sizes,
  variants,
  defaultProps: {
    size: 'md',
    variant: 'outline'
  }
});
