import { createMultiStyleConfigHelpers } from '@chakra-ui/styled-system';

import { parts } from '../parts';

const { definePartsStyle } = createMultiStyleConfigHelpers(parts);

export const variantButton = definePartsStyle(() => ({
  rootContainer: {
    width: 'fit-content'
  },
  control: {
    fontWeight: 'medium',
    height: 12,
    paddingX: 8,
    alignItems: 'center',
    borderRadius: '10px',
    border: 'none',
    outline: 'none',
    boxShadow: '0 0.125rem 0.875rem 0 rgba(0, 0, 0, 0.1)',
    svg: {
      width: '1.5em',
      height: '1.5em'
    },
    background: 'white',
    color: 'black',
    _hover: {
      color: 'gray.800',
      _disabled: {
        color: 'disabled'
      }
    },
    _disabled: {
      color: 'disabled'
    }
  },
  valueContainer: {
    paddingX: 0,
    marginEnd: 2
  },
  dropdownIndicator: {
    px: 0,
    color: 'black',
    _disabled: {
      color: 'disabled'
    }
  }
}));
