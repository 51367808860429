import { Icon, IconProps } from '@chakra-ui/react';

export const DocumentIcon = (props: IconProps): JSX.Element => (
  <Icon viewBox="0 0 24 24" fill="none" {...props}>
    <rect
      x="4.00281"
      y="2"
      width="16"
      height="20"
      rx="4"
      stroke="currentColor"
      strokeWidth="1.5"
    />
    <path
      d="M8.00293 7H16.0029"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
    />
    <path
      d="M8.00293 12H16.0029"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
    />
    <path
      d="M8.00293 17H12.0029"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
    />
  </Icon>
);
