import { CustomerConversationResponse, ENDPOINTS, WithPagination } from 'api';
import { serializeQueryFilters } from 'api/api.utils';
import axios from 'axios';
import { QueryFilters } from 'types';
import { ICustomerConversation } from 'types/customer';

import { serializeCustomerConversationData } from './Conversations.utils';

export const fetchCustomerConversations = async ({
  customerId,
  queryFilters
}: {
  customerId: string;
  queryFilters: QueryFilters;
}): Promise<WithPagination<ICustomerConversation[]>> => {
  const { data } = await axios.post<
    WithPagination<CustomerConversationResponse[]>
  >(
    `${ENDPOINTS.CONVERSATIONS}/by-user/${customerId}/list`,
    serializeQueryFilters(queryFilters)
  );

  return serializeCustomerConversationData(data);
};
