import { useInfiniteQuery } from '@tanstack/react-query';
import {
  fetchModerationGalleryPhoto,
  ModerationGalleryPhotoResponse,
  WithPagination
} from 'api';
import { ModerationPostStatus } from 'enum';
import { QueryKey } from 'enum/api';
import { useAppSelector } from 'hooks';
import { selectAppFilters } from 'store/app';

export const useModerationGalleryPhoto = (status: ModerationPostStatus) => {
  const globalFilters = useAppSelector(selectAppFilters);

  return useInfiniteQuery(
    [QueryKey.ModerationGalleryPhoto, status, globalFilters],
    ({ pageParam }) =>
      fetchModerationGalleryPhoto({
        status,
        globalFilters,
        pageParam
      }),
    {
      getNextPageParam: <
        T extends WithPagination<ModerationGalleryPhotoResponse[]>
      >(
        lastPage: T,
        pages: T[]
      ): number | void => {
        const { page, totalItems } = lastPage;

        const dataLoadedLength = pages.reduce((acc, { data }) => {
          acc += data.map((item) => item.photos).flat().length;

          return acc;
        }, 0);

        if (totalItems > dataLoadedLength) {
          return page + 1;
        }
      },
      keepPreviousData: true
    }
  );
};
