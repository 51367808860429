import { Providers } from 'Providers';
import { DataWatcher } from 'containers';
import { useRoutes } from 'react-router-dom';
import { ROUTES } from 'routes/routesMap';
import { initAxios } from 'services';

initAxios();

export const App = (): JSX.Element => {
  const routes = useRoutes(ROUTES);

  return (
    <Providers>
      {routes}
      <DataWatcher />
    </Providers>
  );
};
