import { Checkbox } from '@chakra-ui/react';
import { FormComponentProps } from 'containers';
import { InputElement } from 'enum';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

type Props = FormComponentProps<{ type: InputElement.Checkbox }>;

export const FormCheckbox = (props: Props): JSX.Element => {
  const { name, getLabel, ...rest } = props;
  const [t] = useTranslation();

  const { control } = useFormContext();

  const label = getLabel?.(t);

  return (
    <Controller
      control={control}
      name={name}
      render={({ field: { onChange, value } }) => (
        <Checkbox
          onChange={onChange}
          isChecked={!!value}
          alignItems="flex-start"
          {...rest}
        >
          {label}
        </Checkbox>
      )}
    />
  );
};
