import { InputElement } from 'enum';

import { ListContainerProps } from '../ButtonDropdown.types';

import { CheckboxGroupList } from './CheckboxGroupList';
import { CheckboxList } from './CheckboxList';
import { RangeList } from './RangeList';
import { SelectList } from './SelectList';

export const ListContainer = (props: ListContainerProps): JSX.Element => {
  switch (props.type) {
    case InputElement.CheckboxGroup:
      return <CheckboxGroupList {...props} />;
    case InputElement.Range:
      return <RangeList {...props} />;
    case InputElement.Select:
      return <SelectList {...props} />;
    case InputElement.Checkbox:
    default:
      return <CheckboxList {...props} />;
  }
};
