import {
  Box,
  HStack,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  useControllableState
} from '@chakra-ui/react';
import { Range } from 'enum';
import { Fragment, MouseEventHandler } from 'react';
import { Option } from 'types';

import { RightIndicator } from './SelectDropdown';

type RangeDropdownProps = {
  minValue?: number;
  maxValue?: number;
  value?: {
    [Range.Start]: string;
    [Range.End]: string;
  };
  defaultValue?: RangeDropdownProps['value'];
  onChange?: (value: RangeDropdownProps['value']) => void;
  isInvalid?: boolean;
  isDisabled?: boolean;
};

const getOptions = (minValue = 0, maxValue = 50): Option[] => {
  const arr = [];
  for (let i = minValue; i <= maxValue; i++) {
    arr.push({
      label: String(i),
      value: String(i)
    });
  }
  return arr;
};

const defaultRangeValue = {
  [Range.Start]: '0',
  [Range.End]: '0'
};

const RangeSelector = <T extends Option>({
  label,
  options,
  selectedItem,
  onSelectItem,
  isInvalid,
  isDisabled
}: {
  label: string;
  options: T[];
  selectedItem: string;
  onSelectItem: MouseEventHandler<HTMLDivElement>;
  isInvalid: boolean;
  isDisabled: boolean;
}) => (
  <Menu variant="range">
    {({ isOpen }) => (
      <Fragment>
        <MenuButton aria-invalid={isInvalid} aria-disabled={isDisabled}>
          <Box
            flexGrow="1"
            justifyContent="space-between"
            display="flex"
            mr={4}
          >
            {label}
            <span>{selectedItem}</span>
          </Box>
          <RightIndicator isOpen={isOpen} />
        </MenuButton>
        <MenuList onClick={onSelectItem}>
          {options.map(({ label: optionLabel, value }) => (
            <MenuItem key={value} value={value}>
              {optionLabel}
            </MenuItem>
          ))}
        </MenuList>
      </Fragment>
    )}
  </Menu>
);

export const RangeDropdown = ({
  isInvalid = false,
  isDisabled = false,
  minValue,
  maxValue,
  value,
  defaultValue = defaultRangeValue,
  onChange
}: RangeDropdownProps): JSX.Element => {
  const [state, setState] = useControllableState({
    value,
    defaultValue,
    onChange
  });

  const onSelectItem =
    (range: Range): MouseEventHandler<HTMLDivElement> =>
    (event) => {
      const { value } = event.target as HTMLButtonElement;
      if (value) {
        setState((prev) => {
          const start = prev[Range.Start];
          const end = prev[Range.End];
          let newValue = value;

          if (range === Range.Start && value > end) {
            newValue = end;
          }

          if (range === Range.End && value < start) {
            newValue = start;
          }

          return {
            ...prev,
            [range]: newValue
          };
        });
      }
    };

  const options = getOptions(minValue, maxValue);

  return (
    <HStack spacing={3}>
      <RangeSelector
        label="From"
        isInvalid={isInvalid}
        isDisabled={isDisabled}
        options={options}
        selectedItem={state[Range.Start]}
        onSelectItem={(event) => onSelectItem(Range.Start)(event)}
      />
      <RangeSelector
        label="To"
        isInvalid={isInvalid}
        isDisabled={isDisabled}
        options={options}
        selectedItem={state[Range.End]}
        onSelectItem={(event) => onSelectItem(Range.End)(event)}
      />
    </HStack>
  );
};
