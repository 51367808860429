import { FormField, InputElement } from 'enum';
import * as yup from 'yup';

import { AddLinkedAccountFormData } from './AddLinkedAccount.types';

export const addLinkedAccountFormSchema = {
  fields: {
    [FormField.Email]: {
      type: InputElement.Input,
      translationKey: 'account_email',
      getPlaceholder: () => 'Email'
    },
    [FormField.Comment]: {
      type: InputElement.TextArea,
      translationKey: 'comment',
      getPlaceholder: () => '',
      minHeight: 44,
      maxLength: 2500
    }
  },
  gridLayout: {
    templateAreas: `
    "${FormField.Email}"
    "${FormField.Comment}"
    `,
    gridTemplateColumns: '1fr',
    rowGap: 6
  }
} as const;

export const addLinkedAccountBaseValidationSchema: yup.SchemaOf<AddLinkedAccountFormData> =
  yup.object({
    [FormField.Email]: yup.string().required(),
    [FormField.Comment]: yup.string().required()
  });

export const addLinkedAccountValidationSchema: yup.SchemaOf<AddLinkedAccountFormData> =
  addLinkedAccountBaseValidationSchema.concat(
    yup.object({
      [FormField.Email]: yup.string().email()
    })
  );
