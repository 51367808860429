import { getCSSVar } from '@chakra-ui/react';
import { Fragment } from 'react';
import { convertRemToPixels } from 'utils';

import { GaugeChartElementProps } from '../GaugeChart.types';

export const GaugeArc = ({
  theme,
  gauge,
  settings,
  segments,
  fontSize
}: GaugeChartElementProps): JSX.Element => {
  const { getArcProps, valueToAngle } = gauge;
  const { arcWidth, tickWidth } = settings;

  const paths = [
    {
      ...segments[0],
      end: segments[0].start
    },
    ...segments,
    {
      ...segments[segments.length - 1],
      start: segments[segments.length - 1].end
    }
  ];

  const offset =
    convertRemToPixels(arcWidth, fontSize).pxValue +
    convertRemToPixels(tickWidth, fontSize).pxValue;

  return (
    <Fragment>
      {paths.map(({ start, end, color }, index) => (
        <path
          key={`${start}-${end}-${color}`}
          {...getArcProps({
            offset,
            startAngle: valueToAngle(start),
            endAngle: valueToAngle(end)
          })}
          fill="none"
          stroke={getCSSVar(theme, 'colors', color)}
          strokeWidth={arcWidth}
          strokeLinecap={
            [0, paths.length - 1].includes(index) ? 'round' : 'butt'
          }
        />
      ))}
    </Fragment>
  );
};
