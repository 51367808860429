import { ENDPOINTS } from 'api';
import axios from 'axios';

import { ArticleResponse } from './BlogManagement.types';

export const fetchArticle = async (
  articleId: string
): Promise<ArticleResponse> => {
  const { data } = await axios.get<ArticleResponse>(
    `${ENDPOINTS.ARTICLE}/${articleId}`
  );

  return data;
};
