import { useNavigate } from 'react-router-dom';
import { getBaseCustomerLinkNavigation } from 'utils';

export const useNavigateToCustomer = (): ((customerId: string) => void) => {
  const navigate = useNavigate();

  return (id) => {
    navigate(getBaseCustomerLinkNavigation(id));
  };
};
