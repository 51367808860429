import { Text } from '@chakra-ui/react';
import { ModerationPostStatus } from 'enum';
import { useTranslation } from 'react-i18next';

type Props = {
  status: ModerationPostStatus;
  declineReason?: string;
};

export const DeclineReasonCell = ({
  status,
  declineReason
}: Props): JSX.Element => {
  const [t] = useTranslation();

  let reason = '';

  switch (status) {
    case ModerationPostStatus.AutoDeclined:
      reason = t('keywords.automatic_decline');
      break;
    case ModerationPostStatus.Declined:
      reason = declineReason || '';
      break;
  }

  return <Text>{reason}</Text>;
};
