import { useToast } from '@chakra-ui/react';
import { Option } from 'containers';
import debounce from 'debounce-promise';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { GoogleService } from 'services/google';

const REQUEST_DELAY = 400;

export const useLocationAutocomplete = ({
  withoutState = true
}: {
  withoutState?: boolean;
}) => {
  const toast = useToast();
  const { i18n } = useTranslation();

  const debouncedLoadOptions = useCallback(
    // required to use debounce which return always promise:
    // https://github.com/JedWatson/react-select/issues/3075#issuecomment-450194917
    debounce(async (input: string) => {
      try {
        const results = await GoogleService.getPlacePredictions(i18n.language, {
          input,
          types: ['locality']
        });

        if (!results) return [];

        return results.map<Option>(({ place_id, description, terms }) => ({
          value: place_id,
          label: withoutState
            ? `${terms[0].value}, ${terms[terms.length - 1].value}`
            : description
        }));
      } catch (error) {
        if (error instanceof Error) {
          const translatedMessage = error.message;

          const message =
            typeof translatedMessage === 'string'
              ? translatedMessage
              : error.message;

          toast({
            status: 'error',
            description: message
          });
        }

        return [];
      }
    }, REQUEST_DELAY),
    []
  );

  return {
    loadOptions: debouncedLoadOptions
  };
};
