import { FormControl, FormHelperText, FormLabel } from '@chakra-ui/react';
import { Dropdown, FormError } from 'components';
import { DropdownType, InputElement } from 'enum';
import { PermissionAction } from 'enum/api';
import { usePermissions } from 'hooks';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { FormComponentProps } from '../FormConstructor.types';

type Props = FormComponentProps<{ type: InputElement.Range }>;

export const FormRange = (props: Props): JSX.Element => {
  const [t] = useTranslation();

  const { control, formState, getFieldState } = useFormContext();
  const { checkPermissions } = usePermissions();

  const {
    type,
    translationKey,
    getPlaceholder,
    name,
    hintTranslationKey,
    resource,
    isDisabled,
    ...rest
  } = props;

  const { error } = getFieldState(name, formState);
  const hasUpdateAccess = resource
    ? checkPermissions({
        resource,
        actions: PermissionAction.Update
      })
    : true;

  return (
    <FormControl isInvalid={!!error}>
      <FormLabel>{t(`keywords.${translationKey}`)}</FormLabel>
      <Controller
        control={control}
        name={name}
        render={({ field: { onChange, value } }) => (
          <Dropdown
            type={DropdownType.Range}
            isDisabled={isDisabled || !hasUpdateAccess}
            {...rest}
            value={value}
            onChange={onChange}
            isInvalid={!!error}
          />
        )}
      />
      {!!hintTranslationKey && !error && (
        <FormHelperText>
          {t(`attribute.hint.${hintTranslationKey}`)}
        </FormHelperText>
      )}
      <FormError errorKey={error?.message} />
    </FormControl>
  );
};
