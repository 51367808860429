import { DeleteIcon } from '@chakra-ui/icons';
import {
  Box,
  HStack,
  IconButton,
  Radio,
  RadioGroup,
  Text,
  VStack
} from '@chakra-ui/react';
import { FC, useState } from 'react';

type Option = {
  id: string | null;
  value: string;
};

type Props = {
  mode?: 'use' | 'edit';
  options?: Option[];
  selected?: number;
  onDelete: (idx: number) => Promise<void>;
  onSelect: (idx: number) => void;
  isDisabled?: boolean;
};

export const TextRadioGroup: FC<Props> = ({
  selected,
  onSelect,
  mode,
  options: value = [],
  onDelete,
  isDisabled
}) => {
  const [isDeleting, setIsDeleting] = useState(false);

  const slots = value.map((value, idx) => ({
    idx,
    ...value
  }));

  const handleDelete = async (idx: number) => {
    if (mode === 'use' && !isDisabled) {
      return;
    }

    setIsDeleting(true);
    try {
      await onDelete(idx);
    } catch {}
    setIsDeleting(false);
  };

  const handleSelect = (idx: string | null) => {
    if (!isDisabled && idx) {
      onSelect(Number(idx));
    }
  };

  return (
    <RadioGroup
      onChange={handleSelect}
      value={selected}
      isDisabled={isDisabled}
    >
      <VStack
        alignItems="stretch"
        sx={{
          '& span+span': {
            display: 'block',
            width: 'full',
            height: 'full'
          }
        }}
      >
        {slots.map(({ id, idx, value }) => (
          <Radio value={idx} key={`${id}::${idx}`} border="1px">
            <HStack width="full" alignItems="stretch">
              <Box
                border="1px"
                borderRadius="md"
                padding={2}
                borderColor={
                  !isDisabled && idx === selected ? 'primary.500' : 'gray.300'
                }
                flex={1}
              >
                <Text
                  noOfLines={2}
                  textAlign="start"
                  lineHeight="1.25em"
                  height="2.5em"
                >
                  {value}
                </Text>
              </Box>
              {mode === 'edit' && (
                <IconButton
                  aria-label="delete"
                  icon={<DeleteIcon />}
                  onClick={() => handleDelete(idx)}
                  size="md"
                  isLoading={isDeleting}
                  isDisabled={idx !== selected || isDisabled}
                  alignSelf="center"
                />
              )}
            </HStack>
          </Radio>
        ))}
      </VStack>
    </RadioGroup>
  );
};
