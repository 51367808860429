import { ENDPOINTS } from 'api';
import axios from 'axios';
import { SupportSourceType } from 'enum/api';

export const fetchTicketReply = ({
  ticketId,
  ...body
}: {
  ticketId: string;
  text: string;
  source: SupportSourceType;
  isReplyFromCustomer?: boolean;
}) => axios.post(`${ENDPOINTS.CONTACT_US_TICKET_REPLY}/${ticketId}`, body);
