import { Box, HStack, Image, UseToastOptions } from '@chakra-ui/react';
import { yupResolver } from '@hookform/resolvers/yup';
import img from 'assets/board_example.png';
import { AxiosError } from 'axios';
import { Logo } from 'components';
import { ErrorCode } from 'enum/api';
import { useAuthorization, useChakraToast } from 'hooks';
import snakeCase from 'lodash/snakeCase';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { AxiosErrorData } from 'types';
import { serializeAxiosError } from 'utils';

import { AuthorizationFormField } from './Authorization.types';
import { LoginForm } from './LoginForm';
import { validationSchema } from './validation';

export const Authorization = (): JSX.Element => {
  const [t] = useTranslation();

  const form = useForm<AuthorizationFormField>({
    mode: 'onSubmit',
    resolver: yupResolver(validationSchema),
    defaultValues: {
      email: '',
      password: ''
    }
  });
  const toast = useChakraToast({
    status: 'warning'
  });

  const { login, isFetching } = useAuthorization();

  const handleError = (error?: AxiosError<AxiosErrorData>) => {
    let toastOptions: UseToastOptions = {
      description: t('errors.invalid_credentials')
    };

    if (error) {
      const { errorCode } = serializeAxiosError(error);

      if (
        [
          ErrorCode.AdminDeleted,
          ErrorCode.AdminNotActivated,
          ErrorCode.ValidationError
        ].includes(errorCode)
      ) {
        toastOptions = {
          description: t(`errors.codes.${snakeCase(errorCode)}`),
          duration: null
        };
      }
    }

    toast(toastOptions);
  };

  const onSubmit = form.handleSubmit(
    (data) => {
      login(data, {
        onError: handleError
      });
    },
    () => handleError()
  );

  return (
    <HStack height="100%">
      <Box
        sx={{
          margin: '0 auto',
          position: 'relative',
          display: 'flex',
          alignSelf: 'stretch',
          alignItems: 'center'
        }}
      >
        <FormProvider {...form}>
          <Box as="form" onSubmit={onSubmit}>
            <LoginForm
              title={t('attribute.title.authorization')}
              buttonLabel={t('actions.login')}
              isLoading={isFetching}
            />
          </Box>
        </FormProvider>
        <Logo
          sx={{
            position: 'absolute',
            top: 10,
            left: 0
          }}
        />
      </Box>
      <Image src={img} alt="Administrators management" height="100vh" />
    </HStack>
  );
};
