import { Spinner, Text } from '@chakra-ui/react';
import { ModalType } from 'enum';
import { ImageStatus } from 'enum/api';
import {
  useAppDispatch,
  useCustomerIdFromUrl,
  useCustomerMessageImages
} from 'hooks';
import { useTranslation } from 'react-i18next';
import { ModalItem, openModal } from 'store/modal';

import { AssetsContainer } from '../components';

export const MessageImages = (): JSX.Element => {
  const [t] = useTranslation();
  const dispatch = useAppDispatch();

  const customerId = useCustomerIdFromUrl();

  const { data, isLoading } = useCustomerMessageImages(customerId);

  if (isLoading || !data) return <Spinner />;

  if (data.length === 0) return <Text>{t('messages.no_result_found')}</Text>;

  const onHandleAsset =
    (
      status: ModalItem<ModalType.UpdateCustomerMessageImageStatus>['meta']['status']
    ) =>
    (id: string) => {
      dispatch(
        openModal({
          id: ModalType.UpdateCustomerMessageImageStatus,
          meta: {
            imageId: id,
            status
          }
        })
      );
    };

  return (
    <AssetsContainer
      data={data}
      onDeleteAsset={onHandleAsset(ImageStatus.DeletedByAdmin)}
      onRestoreAsset={onHandleAsset(ImageStatus.Active)}
    />
  );
};
