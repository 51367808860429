import { Grid, GridItem } from '@chakra-ui/react';
import { ContentContainer, ScrollableContainer } from 'containers';
import { useAppSelector } from 'hooks';
import { selectAppFilters } from 'store';

import {
  ActiveUsers,
  CustomerReports,
  IncomeMoneyBalance,
  PurchasedSubscriptions,
  RegisteredUsers,
  ReportsByStatus,
  ReportsStatistic,
  UsersWithEachProgramType,
  SentMessages,
  UsersLogins
} from './Statistics';

export const AdminPanel = (): JSX.Element => {
  const globalFilters = useAppSelector(selectAppFilters);

  return (
    <ScrollableContainer>
      <Grid templateColumns="repeat(6, 1fr)" rowGap={8} columnGap={8}>
        <GridItem colSpan={6}>
          <ContentContainer>
            <RegisteredUsers />
          </ContentContainer>
        </GridItem>
        <GridItem colSpan={6}>
          <ContentContainer>
            <UsersLogins />
          </ContentContainer>
        </GridItem>
        <GridItem colSpan={2}>
          <ActiveUsers />
        </GridItem>
        <GridItem colSpan={4}>
          <ContentContainer>
            <UsersWithEachProgramType />
          </ContentContainer>
        </GridItem>
        <GridItem colSpan={3}>
          <ContentContainer>
            <PurchasedSubscriptions />
          </ContentContainer>
        </GridItem>
        <GridItem colSpan={3}>
          <ContentContainer>
            <ReportsStatistic />
          </ContentContainer>
        </GridItem>
        <GridItem colSpan={3}>
          <ContentContainer>
            <ReportsByStatus />
          </ContentContainer>
        </GridItem>
        <GridItem colSpan={3}>
          <ContentContainer>
            <CustomerReports />
          </ContentContainer>
        </GridItem>
        <GridItem colSpan={6}>
          <ContentContainer>
            <IncomeMoneyBalance />
          </ContentContainer>
        </GridItem>
        {!globalFilters?.country && (
          <GridItem colSpan={3}>
            <ContentContainer>
              <SentMessages />
            </ContentContainer>
          </GridItem>
        )}
      </Grid>
    </ScrollableContainer>
  );
};
