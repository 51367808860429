import { createAction } from '@reduxjs/toolkit';
import { Settings } from 'api';

import { SettingsAction } from './settings.types';

export const setSettings = createAction(
  SettingsAction.SetSettings,
  (options: Settings) => ({
    payload: options
  })
);
