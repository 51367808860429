import { ENDPOINTS, WithPagination } from 'api';
import { serializeQueryFilters } from 'api/api.utils';
import axios from 'axios';
import { QueryFilters } from 'types';
import { ICustomerBlackList } from 'types/customer';

import { CustomerBlackListResponse } from '../Customers.types';

import { serializeCustomerBlackListData } from './CustomerBlackList.utils';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const fakeData: ICustomerBlackList[] = [
  {
    id: 'id_1',
    nickname: 'nick 1',
    email: 'email@email.com',
    addedAt: new Date().toLocaleString(),
    userId: 'user_1'
  }
];

export const fetchCustomerBlackListByUser = async ({
  customerId,
  queryFilters
}: {
  customerId: string;
  queryFilters: QueryFilters;
}): Promise<WithPagination<ICustomerBlackList[]>> => {
  const { data } = await axios.post<
    WithPagination<CustomerBlackListResponse[]>
  >(
    `${ENDPOINTS.BLACK_LIST}/by-user/${customerId}/list`,
    serializeQueryFilters(queryFilters)
  );

  return {
    ...data,
    data: serializeCustomerBlackListData(data.data)
  };
};
