import { Button, ButtonProps, CloseButton } from '@chakra-ui/react';
import { ReactNode } from 'react';

type Props = ButtonProps & {
  children: ReactNode;
  onRemove: () => void;
};
export const MultiValue = ({
  children,
  onRemove,
  ...rest
}: Props): JSX.Element => (
  <Button
    as="span"
    size="sm"
    variant="ghostGray"
    height="100%"
    {...rest}
    rightIcon={
      <CloseButton
        as="span"
        variant="round"
        colorScheme="dark"
        size="sm"
        onClick={(e) => {
          e.stopPropagation();
          onRemove();
        }}
      />
    }
  >
    {children}
  </Button>
);
