import { CrmFiltersRequestData, ENDPOINTS } from 'api';
import { serializeBodyFilters } from 'api/api.utils';
import axios from 'axios';
import { GlobalFilters } from 'types';

export const fetchCrmSendSMS = (
  body: CrmFiltersRequestData & { smsText: string },
  globalFilters: GlobalFilters
): Promise<void> =>
  axios.post(ENDPOINTS.CRM_SEND_SMS, {
    ...Object.fromEntries(
      Object.entries(body).filter(([, value]) => value !== undefined)
    ),
    ...serializeBodyFilters({ globalFilters })
  });
