export const toFormData = (data: Record<string, unknown>) => {
  const formData = new FormData();

  Object.keys(data).forEach((key) => {
    const value = data[key];
    if (typeof value === 'string') {
      formData.append(key, value);
    } else if (typeof value === 'boolean' || typeof value === 'number') {
      formData.append(key, String(value));
    } else if (value instanceof File) {
      formData.append(key, value, value.name);
    } else if (value instanceof Blob) {
      formData.append(key, value);
    } else if (typeof value === 'object' && value) {
      formData.append(key, JSON.stringify(value));
    }
  });

  return formData;
};
