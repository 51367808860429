import { chakra } from '@chakra-ui/react';
import { GroupBase, MenuListProps } from 'react-select';

import { useStyles } from '../LocationDropdown.utils';

export const MenuList = <
  Option,
  IsMulti extends boolean,
  Group extends GroupBase<Option>
>(
  props: MenuListProps<Option, IsMulti, Group>
): JSX.Element => {
  const { children, innerProps, innerRef } = props;

  const styles = useStyles();

  return (
    <chakra.div ref={innerRef} __css={styles.menuList} {...innerProps}>
      {children}
    </chakra.div>
  );
};
