import { Grid, GridItem } from '@chakra-ui/react';
import { AddButton, Search, Table } from 'components';
import { ContentContainer, TableFilters } from 'containers';
import { ModalType } from 'enum';
import {
  useAppDispatch,
  useStaticPagesList,
  useTableQueryController
} from 'hooks';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { openModal } from 'store/modal';

import { columns, getSearchOptions, tableFilters } from './StaticPages.utils';

export const StaticPages = (): JSX.Element => {
  const [t] = useTranslation();
  const dispatch = useAppDispatch();

  const {
    queryFilters,
    action: {
      onChangeSort,
      onChangeFilters,
      onChangeSearch,
      onChangePagination
    }
  } = useTableQueryController();
  const searchOptions = useMemo(() => getSearchOptions(t), [t]);

  const { data } = useStaticPagesList({ queryFilters });

  const handleCreateStaticPage = () => {
    dispatch(
      openModal({
        id: ModalType.CreateStaticPage
      })
    );
  };

  return (
    <ContentContainer height="100%">
      <Grid
        templateAreas={`"search actions"
                       "filters filters"
                       "table table"
       `}
        templateColumns="26.25rem 1fr"
        templateRows="min-content min-content 1fr"
        rowGap={6}
        height="100%"
      >
        <GridItem area="search">
          <Search options={searchOptions} onChange={onChangeSearch} />
        </GridItem>
        <GridItem area="filters">
          <TableFilters filters={tableFilters} onChange={onChangeFilters} />
        </GridItem>
        <GridItem area="actions" justifySelf="flex-end">
          <AddButton onClick={handleCreateStaticPage}>
            {t('actions.create_static_page')}
          </AddButton>
        </GridItem>
        {data && (
          <GridItem area="table" overflow="hidden">
            <Table
              //@ts-ignore
              data={data.data}
              columns={columns}
              onChangeSort={onChangeSort}
              onChangePagination={onChangePagination}
              pagination={queryFilters.pagination}
              totalItems={data.totalItems}
            />
          </GridItem>
        )}
      </Grid>
    </ContentContainer>
  );
};
