import { HStack, Text, Tooltip, VStack } from '@chakra-ui/react';
import { PointTooltipProps } from '@nivo/line';
import { BaseChartDataTemplate } from 'api';
import { LineChartDataMap } from 'containers';
import { i18n } from 'i18n';
import { Fragment } from 'react';
import { STATISTIC_TOTAL } from 'utils';

type Props<T extends BaseChartDataTemplate> = PointTooltipProps & {
  dataMap: LineChartDataMap<T>;
};

export const LineChartTooltip = <T extends BaseChartDataTemplate>({
  point,
  dataMap
}: Props<T>): JSX.Element => {
  const {
    id: pointId,
    serieId: id,
    data: { xFormatted }
  } = point;

  const {
    dataById,
    chartSettings: { formatYAxis }
  } = dataMap;

  // get an index of tooltip data to get other's series data
  const dataIndex = Number(pointId.split(`${id}.`)[1]);

  const displayIds =
    dataById[id].serieId === STATISTIC_TOTAL ? Object.keys(dataById) : [id];

  const label = (
    <VStack spacing={2.5} alignItems="flex-start">
      <Text color="subtext">{xFormatted}</Text>
      <Fragment>
        {displayIds.map((id) => {
          const { getLabel, data } = dataById[id];
          const value = data[dataIndex].y;

          return (
            <HStack key={id} spacing={1}>
              <Text>{`${getLabel(i18n.t)}:`}</Text>
              <Text fontWeight="bold">
                {formatYAxis ? formatYAxis(value) : value}
              </Text>
            </HStack>
          );
        })}
      </Fragment>
    </VStack>
  );

  return (
    <Tooltip
      isOpen
      shouldWrapChildren
      hasArrow
      label={label}
      placement="top"
      modifiers={[
        {
          name: 'flip',
          options: {
            fallbackPlacements: ['bottom', 'right', 'left'],
            altBoundary: true
          }
        }
      ]}
    >
      {null}
    </Tooltip>
  );
};
