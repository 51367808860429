import { ENDPOINTS } from 'api';
import axios from 'axios';

export const fetchUpdatePaymentTransaction = ({
  transactionId,
  comment
}: {
  transactionId: string;
  comment: string;
}) =>
  axios.patch(`${ENDPOINTS.PAYMENT_TRANSACTION}/${transactionId}`, {
    comment
  });
