import { Text } from '@chakra-ui/react';
import { NavigationLink } from 'components';
import { useTranslation } from 'react-i18next';
import { getBaseCustomerLinkNavigation } from 'utils';

type Props = {
  user: {
    nickname: string;
    displayedNickname: string;
    email: string;
    id: string;
  } | null;
};

export const UserCell = ({ user }: Props): JSX.Element => {
  const [t] = useTranslation();

  if (!user) {
    return <Text>{t('keywords.unknown_user')}</Text>;
  }

  return (
    <NavigationLink to={getBaseCustomerLinkNavigation(user.id)}>
      <Text>{user.displayedNickname}</Text>
      <Text color="subtext">{user.email}</Text>
    </NavigationLink>
  );
};
