import { Icon, IconProps } from '@chakra-ui/react';

export const ChatCircleIcon = (props: IconProps): JSX.Element => (
  <Icon viewBox="0 0 24 24" fill="none" {...props}>
    <path
      d="M2.94681 12.6094C2.10621 11.1931 1.81157 9.51875 2.11823 7.90065C2.4249 6.28255 3.31176 4.83207 4.61226 3.82164C5.91277 2.8112 7.53743 2.31033 9.18112 2.41308C10.8248 2.51583 12.3745 3.21515 13.539 4.37969C14.7035 5.54422 15.4028 7.09386 15.5056 8.73755C15.6084 10.3812 15.1075 12.0059 14.097 13.3064C13.0866 14.6069 11.6361 15.4938 10.018 15.8004C8.39992 16.1071 6.72553 15.8125 5.30931 14.9719L2.97494 15.6375C2.87875 15.6645 2.77711 15.6655 2.68044 15.6402C2.58378 15.6149 2.49559 15.5644 2.42494 15.4937C2.3543 15.4231 2.30374 15.3349 2.27847 15.2382C2.25321 15.1416 2.25414 15.0399 2.28119 14.9437L2.94681 12.6094Z"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M15.0316 6.43332C16.7982 6.31931 18.5444 6.87507 19.9422 7.99621C21.34 9.11736 22.2933 10.7268 22.6229 12.5222C22.9525 14.3175 22.6358 16.1754 21.7323 17.7468L22.4477 20.3369C22.4768 20.4437 22.4778 20.5564 22.4506 20.6637C22.4235 20.771 22.3691 20.8688 22.2932 20.9472C22.2173 21.0256 22.1225 21.0817 22.0186 21.1097C21.9147 21.1378 21.8054 21.1367 21.7021 21.1067L19.193 20.3682C17.6709 21.3009 15.8712 21.6278 14.132 21.2875C12.3929 20.9473 10.8339 19.9632 9.74787 18.5202C9.33371 17.9699 8.99919 17.3673 8.74994 16.7312"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </Icon>
);
