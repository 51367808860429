import {
  cssVar,
  SystemStyleFunction,
  SystemStyleObject
} from '@chakra-ui/react';
import { ComponentStyleConfig } from '@chakra-ui/theme';

const $fg = cssVar('alert-fg');
const $bg = cssVar('alert-bg');

const baseStyle: SystemStyleObject = {
  title: {
    fontSize: 'rg',
    fontWeight: 'medium',
    lineHeight: 'base'
  },
  description: {
    fontSize: 'md',
    fontWeight: 'normal',
    lineHeight: 'base'
  }
};

const variantLeftAccent: SystemStyleFunction = (props) => {
  const { status } = props;

  let fg;
  switch (status) {
    case 'success':
    case 'warning':
    case 'error':
      fg = status;
      break;
    default:
      fg = 'primary.500';
  }

  return {
    container: {
      [$bg.variable]: 'colors.white',
      [$fg.variable]: `colors.${fg}`
    },
    description: {
      color: 'gray.500'
    }
  };
};

const variants = {
  'left-accent': variantLeftAccent
};

export const Alert: ComponentStyleConfig = {
  baseStyle,
  variants
};
