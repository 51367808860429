import { FormErrorMessage } from '@chakra-ui/form-control';
import { Box } from '@chakra-ui/react';
import { Trans, useTranslation } from 'react-i18next';

import { IValidationError } from './Form.types';
import { getErrorMessage } from './Form.utils';

type Props = { errorKey?: IValidationError | string; errorMessage?: string };

export const FormError = ({ errorKey, errorMessage }: Props): JSX.Element => {
  const [t] = useTranslation();

  return (
    <FormErrorMessage alignItems="flex-start" textAlign="start">
      <Trans
        i18nKey="errors.form_field_error"
        values={{
          error: getErrorMessage({
            errorKey,
            errorMessage,
            t
          })
        }}
        components={{
          red: <Box color="error" marginRight={1} />
        }}
      />
    </FormErrorMessage>
  );
};
