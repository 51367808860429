import { PaginationState, SortingState } from '@tanstack/react-table';
import { tableSlice } from 'components';
import merge from 'lodash/merge';
import { useMemo, useReducer } from 'react';
import { useLatest } from 'react-use';
import { QueryFilters } from 'types';

const initializer = (defaultValues?: Partial<QueryFilters>) => ({
  ...tableSlice.getInitialState(),
  ...defaultValues
});

type Props = {
  defaultValues?: Partial<QueryFilters>;
  fixedValues?: Partial<Pick<QueryFilters, 'filter'>>;
};

export const useTableQueryController = ({
  defaultValues: propsDefaultValue,
  fixedValues
}: Props = {}): {
  queryFilters: QueryFilters;
  action: {
    onChangeSearch: (value: Pick<QueryFilters, 'search' | 'searchBy'>) => void;
    onChangeSort: (sort: SortingState) => void;
    onChangeFilters: (data: QueryFilters['filter']) => void;
    onChangePagination: (data: PaginationState) => void;
    onResetFilters: () => void;
  };
} => {
  const defaultValues = merge({}, propsDefaultValue, fixedValues);

  const [data, dispatch] = useReducer(
    tableSlice.reducer,
    defaultValues,
    initializer
  );

  const latestDefaultValues = useLatest(defaultValues);
  const latestFixedValues = useLatest(fixedValues);

  const action = useMemo<
    ReturnType<typeof useTableQueryController>['action']
  >(() => {
    const { setFilters, setSort, setPagination, reset, setSearch } =
      tableSlice.actions;
    const { filter: fixedFilter } = latestFixedValues.current || {};

    return {
      onChangeSearch: (data) => {
        dispatch(setSearch(data));
      },
      onChangeSort: (value) => {
        dispatch(setSort(value));
      },
      onChangeFilters: (data) => {
        dispatch(
          setFilters({
            filters: data,
            fixedFilter
          })
        );
      },
      onChangePagination: (data) =>
        dispatch(
          setPagination({
            page: data.pageIndex + 1,
            limit: data.pageSize
          })
        ),
      onResetFilters: () =>
        dispatch(reset(initializer(latestDefaultValues.current)))
    };
  }, [latestDefaultValues, latestFixedValues]);

  return { queryFilters: data, action };
};
