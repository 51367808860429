import { useQuery } from '@tanstack/react-query';
import { fetchParameterOptions } from 'api';
import { QueryKey } from 'enum/api';
import { checkIfTokenExists } from 'utils';

export const useParameterOptions = (
  params: Parameters<typeof fetchParameterOptions>[0]
) => {
  const isEnabled = checkIfTokenExists();

  return useQuery(
    [
      QueryKey.ParameterOptions,
      params.pagination.page,
      params.pagination.limit,
      params.filter?.defaultValue,
      params.filter?.title,
      params.sort?.title,
      params.sort?.type,
      ...(params.filter?.statuses || []),
      ...(params.filter?.types || [])
    ],
    () => fetchParameterOptions(params),
    {
      retry: false,
      refetchOnWindowFocus: false,
      keepPreviousData: true,
      enabled: isEnabled
    }
  );
};
