import { Badge, Box, Heading, VStack } from '@chakra-ui/react';
import { Dropdown } from 'components';
import { getSelectedValuesLabel } from 'containers/TableFilters/TableFilters.utils';
import { DropdownType, InputElement } from 'enum';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { GroupSelectedValue } from 'types';

const options = Array.from({ length: 10 }).map((_, i) => ({
  value: `item${i + 1}`,
  label: `Item${i + 1}`
}));

const groupOptions = Array.from({ length: 10 }).map((_, i) => ({
  groupLabel: `Group${i + 1}`,
  groupKey: `group${i + 1}`,
  options
}));

export const Selector = (): JSX.Element => {
  const [t] = useTranslation();
  const [selectedDrValue, setSelectedDrValue] = useState<Array<string>>([]);
  const [selectedGroupDrValue, setSelectedGroupDrValue] =
    useState<GroupSelectedValue>({});

  const [selectedRangeValue, setSelectedRangeValue] = useState<
    (string | number | undefined)[]
  >([]);

  const selectedFilters = Object.keys(selectedGroupDrValue).length;

  const groupLabel = (
    <Box as="span">
      <Box marginRight={1} as="span">
        More Filters:
      </Box>
      {selectedFilters > 0 ? <Badge>{selectedFilters}</Badge> : 'All'}
    </Box>
  );

  return (
    <VStack
      w="400px"
      ml="20px"
      spacing={3}
      align="flex-start"
      alignItems="stretch"
    >
      <Box>
        <Heading as="h3" size="md" mt={3} mb={2}>
          Base select
        </Heading>
        <Dropdown
          options={[
            {
              value: 'male',
              label: 'Male'
            },
            {
              value: 'female',
              label: 'Female'
            }
          ]}
        />
      </Box>
      <Box>
        <Heading as="h3" size="md" mt={3} mb={2}>
          Search select
        </Heading>
        <Dropdown isSearchable options={options} />
      </Box>
      <Box>
        <Heading as="h3" size="md" mt={3} mb={2}>
          Multi select
        </Heading>
        <Dropdown options={options} isSearchable isMulti />
      </Box>
      <Box>
        <Heading as="h3" size="md" mt={3} mb={2}>
          Multi select and can add item
        </Heading>
        <Dropdown options={options} isSearchable isMulti canAdd />
      </Box>
      <Box>
        <Heading as="h3" size="md" mt={3} mb={2}>
          Data entry (without options)
        </Heading>
        <Dropdown isSearchable isMulti canAdd shouldHideOptions />
      </Box>
      <Box>
        <Heading as="h3" size="md" mt={3} mb={2}>
          Dropdown Buttons
        </Heading>
        <Dropdown
          type={DropdownType.Button}
          list={{
            options,
            selectedValues: selectedDrValue,
            onChange: setSelectedDrValue
          }}
          label={`User Type: ${
            selectedDrValue.length > 0
              ? selectedDrValue
                  .map(
                    (selectedValue) =>
                      options.find(({ value }) => value === selectedValue)
                        ?.label
                  )
                  .join(', ')
              : 'All'
          }`}
          onReset={() => {
            setSelectedDrValue([]);
          }}
        />
      </Box>
      <Box>
        <Heading as="h3" size="md" mt={3} mb={2}>
          Dropdown Group Button
        </Heading>
        <Dropdown
          type={DropdownType.Button}
          list={{
            type: InputElement.CheckboxGroup,
            options: groupOptions,
            selectedValues: selectedGroupDrValue,
            onChange: (value, key) => {
              setSelectedGroupDrValue((prev) => ({
                ...prev,
                [key]: value
              }));
            }
          }}
          label={groupLabel}
          onReset={() => {
            setSelectedGroupDrValue({});
          }}
          menuButtonProps={{
            background: 'white'
          }}
        />
      </Box>
      <Box>
        <Heading as="h3" size="md" mt={3} mb={2}>
          Dropdown Range Button
        </Heading>
        <Dropdown
          type={DropdownType.Button}
          list={{
            type: InputElement.Range,
            inputProps: {
              type: 'date'
            },
            selectedValues: selectedRangeValue,
            onChange: (values) => setSelectedRangeValue(values)
          }}
          label={`Date: ${getSelectedValuesLabel({
            type: InputElement.Range,
            selectedValues: selectedRangeValue,
            t,
            inputType: 'date'
          })}`}
          onReset={() => {
            setSelectedRangeValue([]);
          }}
          menuButtonProps={{
            background: 'white'
          }}
        />
      </Box>
    </VStack>
  );
};
