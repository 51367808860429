import { useMutation } from '@tanstack/react-query';
import { fetchAdminMe, fetchPermissions } from 'api';
import { PageName } from 'enum';
import { useAppDispatch, useChakraToast } from 'hooks';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import { setSettings } from 'store';
import { resetUser, setUser } from 'store/user';
import { getPageNameByUrl } from 'utils';

import { useAuthUserSettings } from './useAuthUserSettings';

export const useUserData = () => {
  const [t] = useTranslation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const toast = useChakraToast();

  const nextPage =
    getPageNameByUrl(pathname) === PageName.Login ? '/' : pathname;

  const { mutateAsync: mutateAdminsMe, isLoading: isLoadingAdmin } =
    useMutation(fetchAdminMe);
  const { mutateAsync: mutatePermissions, isLoading: isLoadingPermissions } =
    useMutation(fetchPermissions);

  const { refetch, isInitialLoading: isLoadingOptions } = useAuthUserSettings();

  const onLoadUserData = async () => {
    try {
      const [adminData, permissions, { data: settings }] = await Promise.all([
        mutateAdminsMe(),
        mutatePermissions(),
        refetch({
          throwOnError: true
        })
      ]);

      if (!settings) throw new Error('');

      dispatch(
        setUser({
          id: adminData.id,
          userName: adminData.nickname,
          role: adminData.role.type,
          permissions
        })
      );

      dispatch(setSettings(settings));

      navigate(nextPage);
    } catch (e) {
      dispatch(resetUser());
      toast({
        status: 'error',
        title: t('errors.error_occurred_while_loading_data')
      });
      console.error(e);
    }
  };

  return {
    onLoadUserData,
    isFetching: isLoadingAdmin || isLoadingPermissions || isLoadingOptions
  };
};
