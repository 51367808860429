import { VStack } from '@chakra-ui/react';
import { IUserFeedInfo } from 'types/customer';

import { UserCardBody, UserCardDescription } from './components';

export const UserFeedCard = ({
  user,
  imageUrl
}: {
  user: IUserFeedInfo;
  imageUrl: string;
}): JSX.Element => (
  <VStack spacing={1} alignItems="stretch">
    <UserCardBody user={user} imageUrl={imageUrl} />
    <UserCardDescription user={user} />
  </VStack>
);
