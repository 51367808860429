import { useControllableState } from '@chakra-ui/react';
import {
  getCoreRowModel,
  getSortedRowModel,
  PaginationState,
  SortingState,
  Table,
  useReactTable
} from '@tanstack/react-table';
import { Pagination } from 'components';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { TableProps } from './Table.types';

export const useTable = <TData>(
  props: TableProps<TData>
): {
  table: Table<TData>;
  paginationProps: Parameters<typeof Pagination>[0];
  hasResult: boolean;
  noResultMessage: string;
} => {
  const {
    data,
    columns,
    columnOrder = [],
    totalItems,
    shouldHidePagination = false,
    sortBy,
    onChangeSort,
    onChangePagination,
    onRowSelectionChange,
    rowSelection,
    tableOptions,
    pagination: controlledPagination,
    meta,
    instance,
    emptyMessage,
    defaultSortBy
  } = props;

  const [t] = useTranslation();

  const [sorting, setSorting] = useControllableState<SortingState>({
    value: sortBy,
    defaultValue: Object.entries(defaultSortBy || {}).map(([id, value]) => ({
      id,
      desc: value === 'desc'
    })),
    onChange: onChangeSort
  });

  const [pagination, setPagination] = useControllableState<PaginationState>({
    value: controlledPagination && {
      pageIndex: controlledPagination.page - 1,
      pageSize: controlledPagination.limit
    },
    defaultValue: {
      pageIndex: 1,
      pageSize: 50
    },
    onChange: (data) => {
      document.getElementsByClassName(
        'chakra-table__container'
      )[0].scrollTop = 0;

      onChangePagination && onChangePagination(data);
    }
  });

  const totalPages = Math.ceil(totalItems / pagination.pageSize);

  const table = useReactTable({
    data,
    columns,
    state: {
      sorting,
      columnOrder,
      pagination,
      rowSelection
    },
    meta: {
      t,
      ...meta
    },
    isMultiSortEvent: () => true,
    enableMultiSort: true,
    manualSorting: true,
    manualPagination: !shouldHidePagination,
    pageCount: totalPages,
    ...tableOptions,
    onPaginationChange: setPagination,
    onSortingChange: setSorting,
    onRowSelectionChange,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel()
  });

  useEffect(() => {
    if (instance) {
      instance.current = table;
    }
  }, [table, instance]);

  const paginationProps = {
    pagination: table.getState().pagination,
    nextPage: table.nextPage,
    prevPage: table.previousPage,
    canNextPage: table.getCanNextPage(),
    canPrevPage: table.getCanPreviousPage(),
    goToPage: table.setPageIndex,
    totalPages: table.options.pageCount || 1,
    onChangePageSize: table.setPageSize,
    numberOfItems: totalItems
  };

  return {
    table,
    paginationProps,
    hasResult: data.length > 0,
    noResultMessage: emptyMessage || t('messages.sorry_no_records_found')
  };
};
