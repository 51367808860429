import { useQuery } from '@tanstack/react-query';
import { fetchTicketConversations } from 'api';
import { QueryKey } from 'enum/api';
import { QueryFilters } from 'types';

export const useTicketConversations = ({
  ticketId,
  queryFilters,
  enabled
}: {
  ticketId: string;
  queryFilters: QueryFilters;
  enabled?: boolean;
}) =>
  useQuery(
    [QueryKey.TicketConversation, ticketId, queryFilters],
    () => fetchTicketConversations({ ticketId, queryFilters }),
    {
      retry: false,
      refetchOnWindowFocus: false,
      keepPreviousData: true,
      enabled
    }
  );
