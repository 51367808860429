import { modalAnatomy as parts } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers } from '@chakra-ui/react';

const { defineMultiStyleConfig, definePartsStyle } =
  createMultiStyleConfigHelpers(parts.keys);

const baseStyle = definePartsStyle({
  header: {
    px: 10,
    marginTop: 6,
    marginBottom: 0,
    textAlign: 'center',
    fontSize: '1.75rem',
    fontWeight: 'bold'
  },
  body: {
    paddingX: 10,
    paddingY: 6,
    alignSelf: 'center',
    width: 'full',
    textAlign: 'center'
  },
  footer: {
    px: 10,
    py: 10
  },
  closeButton: {
    top: 3,
    insetEnd: 3
  }
});

export const Modal = defineMultiStyleConfig({
  baseStyle,
  sizes: {
    max: {
      dialog: {
        maxWidth: '90vw'
      }
    }
  }
});
