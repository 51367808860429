import {
  IncomeMoneyBalanceChartDataItem,
  IncomeMoneyBalanceResponse
} from 'api';
import { LineChartData, LineChartDataMap, LineChartSettings } from 'containers';
import { useAppSelector } from 'hooks';
import { useMemo } from 'react';
import { selectSettings } from 'store';
import { v4 as uuid } from 'uuid';

export const useIncomesChartData = <T extends IncomeMoneyBalanceChartDataItem>({
  apiData,
  chartSettings
}: {
  apiData?: IncomeMoneyBalanceResponse;
  chartSettings: LineChartSettings<T>;
}) => {
  const settings = useAppSelector(selectSettings);

  return useMemo(() => {
    if (!apiData || apiData.length === 0) return null;

    return (apiData || []).reduce(
      (acc, { id: serieId, data }, index) => {
        const { colors, transformChartData, getLabel } = chartSettings;

        if (!data?.length) return acc;

        data.map(({ id: currencyId, data }) => {
          const id = uuid();

          const chartData: LineChartData<T> = {
            id,
            subId: currencyId,
            serieId,
            color: colors[index],
            getLabel: (t) =>
              getLabel({ t, id: serieId, subId: currencyId, settings }),
            data: data.map((item) => {
              //@ts-ignore
              const { x, y } = transformChartData(item);

              if (y > acc['maxYValue']) {
                acc['maxYValue'] = y;
              }

              if (y < acc['minYValue']) {
                acc['minYValue'] = y;
              }

              return { x, y };
            })
          };

          acc['chartData'].push(chartData);
          acc['dataById'][id] = chartData;
        });

        return acc;
      },
      {
        chartData: [],
        dataById: {},
        maxYValue: 0,
        minYValue: Number.MAX_SAFE_INTEGER,
        chartSettings
      } as LineChartDataMap<T>
    );
  }, [apiData, settings, chartSettings]);
};
