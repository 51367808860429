import { Grid, GridItem, Heading, Skeleton } from '@chakra-ui/react';
import { BarChart, TableFilters } from 'containers';
import { useBarChartData, useCustomerReports } from 'hooks';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ChartFilters } from 'types';

import { chartFilters, CHART_SETTINGS } from './CustomerReports.utils';

export const CustomerReports = (): JSX.Element => {
  const [t] = useTranslation();

  const [filters, setFilters] = useState<ChartFilters>({});

  const { data, isSuccess } = useCustomerReports({
    filters
  });

  const chartData = useBarChartData({
    apiData: data,
    chartSettings: CHART_SETTINGS
  });

  return (
    <Grid
      templateAreas={`
          "title filters"
          "chart chart"
        `}
      rowGap={4}
      columnGap={4}
      templateColumns="1fr auto"
    >
      <GridItem area="title">
        <Heading size="l">{t('attribute.title.customer_reports')}</Heading>
      </GridItem>
      <GridItem area="filters">
        <TableFilters filters={chartFilters} onChange={setFilters} />
      </GridItem>
      <GridItem area="chart" height="30rem">
        <Skeleton isLoaded={isSuccess} boxSize="100%">
          <BarChart data={chartData} chartSettings={CHART_SETTINGS} />
        </Skeleton>
      </GridItem>
    </Grid>
  );
};
