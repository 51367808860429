import { GridItem } from '@chakra-ui/react';
import { Search, Table } from 'components';
import { TableFilters } from 'containers';
import { ArticleStatus, ArticleType } from 'enum/api';
import { useArticleList, useTableQueryController } from 'hooks';
import { Fragment, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import {
  blogManagementColumns,
  getSearchOptions,
  tableFilters
} from '../BlogManagement.utils';

export const NewsContent = (): JSX.Element => {
  const [t] = useTranslation();
  const {
    queryFilters,
    action: {
      onChangeSort,
      onChangeFilters,
      onChangeSearch,
      onChangePagination
    }
  } = useTableQueryController({
    fixedValues: {
      filter: {
        type: ArticleType.News
      }
    }
  });
  const searchOptions = useMemo(() => getSearchOptions(t), [t]);

  const { data } = useArticleList(queryFilters);

  return (
    <Fragment>
      <GridItem area="search">
        <Search options={searchOptions} onChange={onChangeSearch} />
      </GridItem>
      <GridItem area="filters">
        <TableFilters filters={tableFilters} onChange={onChangeFilters} />
      </GridItem>
      {data && (
        <GridItem area="table" overflow="hidden">
          <Table
            data={data.data}
            columns={blogManagementColumns}
            onChangeSort={onChangeSort}
            onChangePagination={onChangePagination}
            pagination={queryFilters.pagination}
            totalItems={data.totalItems}
            getIsDisabled={(row) =>
              row.original.status === ArticleStatus.NotActivated
            }
            canInteractWithDisabled
          />
        </GridItem>
      )}
    </Fragment>
  );
};
