import { FormField, InputElement } from 'enum';
import * as yup from 'yup';

import { SendEmailFormData } from './SendEmail.types';

export const sendEmailFormSchema = {
  fields: {
    [FormField.EmailTemplateId]: {
      type: InputElement.Input,
      translationKey: 'email_template_id',
      getPlaceholder: () => ''
    },
    [FormField.EmailTemplatePayload]: {
      type: InputElement.TextArea,
      translationKey: 'email_template_payload',
      getPlaceholder: () => 'Should be a valid JSON',
      minHeight: 44,
      maxLength: 700
    }
  },
  gridLayout: {
    templateAreas: `
    "${FormField.EmailTemplateId}"
    "${FormField.EmailTemplatePayload}"
  `,
    gridTemplateColumns: '1fr',
    rowGap: 7
  }
} as const;

export const sendEmailValidationSchema: yup.SchemaOf<SendEmailFormData> =
  yup.object({
    [FormField.EmailTemplateId]: yup.string().required(),
    [FormField.EmailTemplatePayload]: yup.string().required()
  });
