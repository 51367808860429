import { Icon, IconProps } from '@chakra-ui/react';

export const EditIcon = (props: IconProps): JSX.Element => (
  <Icon viewBox="0 0 24 24" fill="none" {...props}>
    <path
      d="M14.0441 4.33618C14.8246 3.55454 16.0904 3.55406 16.8715 4.33511L19.3633 6.82697C20.1377 7.60135 20.1458 8.855 19.3814 9.63926L10.1482 19.1125C9.58382 19.6916 8.80969 20.0182 8.00135 20.0181L5.2492 20.018C4.39637 20.018 3.71529 19.3071 3.75116 18.4543L3.86931 15.6453C3.90091 14.8941 4.21314 14.1821 4.7442 13.6502L14.0441 4.33618Z"
      stroke="currentColor"
      strokeWidth="1.5"
    />
    <path
      d="M11.9362 6.61764L16.886 11.5674"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M14.3932 19.9448H20.5149"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </Icon>
);
