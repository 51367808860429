import { Grid, GridItem, Heading, Skeleton } from '@chakra-ui/react';
import { ChartLegend, LineChart, TableFilters } from 'containers';
import { useIncomesChartData, useIncomeMoneyBalance } from 'hooks';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ChartFilters } from 'types';

import { chartFilters, CHART_SETTINGS } from './IncomeMoneyBalance.utils';

export const IncomeMoneyBalance = (): JSX.Element => {
  const [t] = useTranslation();

  const [filters, setFilters] = useState<ChartFilters>({});

  const { data, isSuccess } = useIncomeMoneyBalance({
    filters
  });

  const chartDataMap = useIncomesChartData({
    apiData: data,
    chartSettings: CHART_SETTINGS
  });

  const { chartData = [] } = chartDataMap || {};

  return (
    <Grid
      templateAreas={`
          "title filters"
          "legend legend"
          "chart chart"
        `}
      rowGap={4}
      columnGap={4}
      templateColumns="1fr auto"
    >
      <GridItem area="title">
        <Heading size="l">{t('attribute.title.income_money_balance')}</Heading>
      </GridItem>
      <GridItem area="filters">
        <TableFilters filters={chartFilters} onChange={setFilters} />
      </GridItem>
      <GridItem area="legend">
        <Skeleton isLoaded={isSuccess}>
          <ChartLegend data={chartData} />
        </Skeleton>
      </GridItem>
      <GridItem area="chart" height="30rem">
        <Skeleton isLoaded={isSuccess} boxSize="100%">
          {!!chartDataMap && (
            <LineChart dataMap={chartDataMap} filters={filters} />
          )}
        </Skeleton>
      </GridItem>
    </Grid>
  );
};
