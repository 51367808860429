import { Button } from '@chakra-ui/react';
import { useChakraToast, useDeleteParameterValue } from 'hooks';
import { useTranslation } from 'react-i18next';

type Props = {
  id: string;
};

export const DeleteValue = ({ id }: Props): JSX.Element => {
  const [t] = useTranslation();
  const toast = useChakraToast();

  const { mutate, isLoading } = useDeleteParameterValue();

  const onDeleteValue = () => {
    mutate(
      {
        id
      },
      {
        onError: () => {
          toast({
            status: 'error',
            title: t('errors.codes.unknown')
          });
        }
      }
    );
  };

  return (
    <Button variant="link" onClick={onDeleteValue} isDisabled={isLoading}>
      {t('actions.delete')}
    </Button>
  );
};
