import { createReducer } from '@reduxjs/toolkit';

import { TableFilterReducerState } from '../TableFilters.types';

import { clearAll, resetSelection, updateSelection } from './actions';

export const tableFilterReducer = createReducer<TableFilterReducerState>(
  {},
  (builder) => {
    builder
      .addCase(updateSelection, (state, action) => {
        const { filerKey, values } = action.payload;

        if (values.length > 0) {
          state[filerKey] = values;
        } else {
          delete state[filerKey];
        }
      })
      .addCase(resetSelection, (state, action) => {
        action.payload.forEach(({ filerKey, defaultValues }) => {
          if (defaultValues) {
            state[filerKey] = defaultValues;
          } else {
            delete state[filerKey];
          }
        });
      })
      .addCase(clearAll, () => ({}));
  }
);
