import { Grid, GridItem } from '@chakra-ui/react';
import { FormElement } from 'containers';
import { FormField } from 'enum';
import { Fragment } from 'react';
import { useTranslation } from 'react-i18next';

import { GeneralSettingsFormField } from '../../FormContent.types';

import { likesCountFormFields } from './LikesCount.utils';

export const LikesCount = (): JSX.Element => {
  const [t] = useTranslation();

  return (
    <Fragment>
      <GridItem>{t('attribute.description.likes_limitations')}</GridItem>
      <GridItem>
        <Grid
          templateAreas={`
            "${FormField.FreeLikesAmount} ${FormField.PremiumLikesAmountPerDay}"
          `}
          columnGap={3}
        >
          {likesCountFormFields.map((formField) => (
            <GridItem key={formField.name} area={formField.name}>
              <FormElement
                {...formField}
                name={`${GeneralSettingsFormField.FreeLikesCount}.${formField.name}`}
              />
            </GridItem>
          ))}
        </Grid>
      </GridItem>
    </Fragment>
  );
};
