import { ENDPOINTS, PaymentMethodListResponse } from 'api';
import { serializeBodyFilters } from 'api/api.utils';
import axios from 'axios';
import { GlobalFilters } from 'types';

export const fetchPaymentMethodList = async ({
  globalFilters
}: {
  globalFilters: GlobalFilters;
}): Promise<PaymentMethodListResponse[]> => {
  const { data } = await axios.post<PaymentMethodListResponse[]>(
    `${ENDPOINTS.PAYMENT_METHOD}/list`,
    serializeBodyFilters({ globalFilters })
  );

  return data;
};
