import { Access } from 'containers';
import { AdminDetailsPage } from 'enum';
import { getAllEnumValues } from 'enum-for';
import { PermissionAction, PermissionResource } from 'enum/api';

import { EditAdmin } from './Pages';
import { LoginSession } from './Pages/LoginSession/LoginSession';

export const ADMIN_DETAILS_PAGE_LIST = getAllEnumValues(AdminDetailsPage);

export const ADMIN_DETAILS_PAGE: Record<AdminDetailsPage, () => JSX.Element> = {
  [AdminDetailsPage.Edit]: () => (
    <Access
      restrictions={{
        resource: PermissionResource.Admins,
        actions: PermissionAction.Update
      }}
    >
      <EditAdmin />
    </Access>
  ),
  [AdminDetailsPage.LoginSession]: () => (
    <Access
      restrictions={{
        resource: PermissionResource.Admins,
        actions: PermissionAction.Read
      }}
    >
      <LoginSession />
    </Access>
  )
};
