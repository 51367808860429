import { Fragment } from 'react';

import { PaginationButton } from './PaginationButton';
import { getPaginationList, MAX_BUTTONS } from './utils';

const paginationList = getPaginationList(MAX_BUTTONS);

export const PaginationButtons = ({
  page,
  totalSize,
  onClick
}: {
  page: number;
  totalSize: number;
  onClick: (value: number) => void;
}): JSX.Element => (
  <Fragment>
    {paginationList(page, totalSize).map((item, i) => (
      <PaginationButton
        key={`${i}:${item}`}
        isSelected={item === page}
        onClick={onClick}
      >
        {item}
      </PaginationButton>
    ))}
  </Fragment>
);
