import { Grid, GridItem, IconButton, Text } from '@chakra-ui/react';

import { InfoCardProps } from './InfoCard.types';

export const InfoCard = ({
  Icon,
  header,
  description,
  onClick
}: InfoCardProps): JSX.Element => (
  <Grid
    display="inline-grid"
    templateAreas={`
      "img header"
      "img description"
    `}
    alignItems="center"
    justifyContent="flex-start"
    justifyItems="flex-start"
    columnGap="3"
    rowGap="1"
    cursor={onClick ? 'pointer' : 'default'}
    onClick={onClick}
  >
    <GridItem area="img">
      <IconButton
        icon={<Icon boxSize={6} />}
        aria-label="icon"
        data-readonly
        variant="ghostGray"
        color="gray.500"
        size="md"
      />
    </GridItem>
    <GridItem area="header">
      <Text color="black" textStyle="mds">
        {header}
      </Text>
    </GridItem>
    <GridItem area="description">
      <Text color="subtext" textStyle="xsrg">
        {description}
      </Text>
    </GridItem>
  </Grid>
);
