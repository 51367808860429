import { VStack } from '@chakra-ui/react';
import { yupResolver } from '@hookform/resolvers/yup';
import { CustomerSelect, FormConstructor, ModalContainer } from 'containers';
import { FormField, ModalType } from 'enum';
import {
  useAdminList,
  useChakraToast,
  useCreateTicket,
  useFormValidation,
  useModalActions,
  useTableQueryController
} from 'hooks';
import { useMemo, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { ParamType } from 'types';

import { CreateTicketFormData } from './CreateTicket.types';
import {
  createTicketValidationSchema,
  getCreateTicketFormSchema
} from './CreateTicket.utils';

export const CreateTicket = (): JSX.Element => {
  const [t] = useTranslation();
  const toast = useChakraToast();

  const form = useForm<CreateTicketFormData>({
    mode: 'onSubmit',
    resolver: yupResolver(createTicketValidationSchema),
    defaultValues: {
      [FormField.SupportSourceType]: [],
      [FormField.Comment]: '',
      [FormField.IsReplyFromCustomer]: false
    }
  });

  const [selectedUserId, setSelectedUserId] = useState<string | null>(null);

  const onClearForm = () => {
    form.reset();
    setSelectedUserId(null);
  };

  const { isOpen, onClose, meta } = useModalActions(ModalType.CreateTicket, {
    onClose: onClearForm
  });

  const userId = selectedUserId ?? meta?.customerId;

  const { mutate, isLoading } = useCreateTicket();

  const { queryFilters: allListFilters } = useTableQueryController({
    defaultValues: {
      pagination: {
        page: 1,
        limit: 150
      }
    }
  });

  const { data: { data: adminList = [] } = {} } = useAdminList(allListFilters);

  const { handleSubmit } = form;

  const isFormFilled = useFormValidation<CreateTicketFormData>(
    form,
    createTicketValidationSchema
  );

  const createTicketFormSchema = useMemo(
    () => getCreateTicketFormSchema(adminList),
    [adminList]
  );

  const onSubmit = handleSubmit((data) => {
    const {
      supportSourceType,
      reason,
      id: assigneeId,
      comment,
      sourcePhoneNumber,
      sourceNickname,
      sourceEmail,
      sourceUserId,
      isReplyFromCustomer
    } = data;

    if (userId && reason) {
      mutate(
        {
          userId,
          sources: supportSourceType,
          reason,
          assigneeId: assigneeId || undefined,
          text: comment as string,
          sourcePhoneNumber,
          sourceNickname,
          sourceEmail,
          sourceUserId,
          isReplyFromCustomer
        },
        {
          onSuccess: onClose,
          onError(error) {
            toast({
              status: 'error',
              description: error.message
            });
          }
        }
      );
    } else {
      toast({
        status: 'warning',
        description: t('errors.invalid_form')
      });
    }
  });

  const actions: ParamType<typeof ModalContainer, 'footerButtons'> = [
    { text: t('actions.cancel'), variant: 'ghostGray' },
    {
      text: t('actions.create'),
      isPreventClose: true,
      isLoading,
      isDisabled: !isFormFilled || !userId,
      onClick: () => {
        onSubmit();
      }
    }
  ];

  return (
    <ModalContainer
      header={t('attribute.title.create_ticket')}
      footerButtons={actions}
      isOpen={isOpen}
      onClose={onClose}
      size="xl"
    >
      <VStack spacing={6} alignItems="stretch">
        {!meta?.customerId && (
          <CustomerSelect
            selectedUserId={selectedUserId}
            onChange={setSelectedUserId}
            shouldShowFilters
          />
        )}
        <FormProvider {...form}>
          <FormConstructor formSchema={createTicketFormSchema} />
        </FormProvider>
      </VStack>
    </ModalContainer>
  );
};
