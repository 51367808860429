import { Box } from '@chakra-ui/react';
import { Fragment } from 'react';

import { OnlineMarkProps } from './OnlineMark.types';

export const OnlineMark = ({
  isOnline,
  ...rest
}: OnlineMarkProps): JSX.Element => {
  const baseStyles = {
    margin: 0.5,
    boxSize: 2.5,
    minWidth: 2.5,
    minHeight: 2.5,
    borderRadius: '50%'
  };

  return (
    <Fragment>
      {isOnline ? (
        <Box {...baseStyles} background="#12f709" {...rest} />
      ) : (
        <Box
          {...baseStyles}
          border="1px solid"
          borderColor="gray.400"
          {...rest}
        />
      )}
    </Fragment>
  );
};
