import {
  Table as ChakraTable,
  TableContainer,
  Tbody,
  Thead,
  Tr,
  VStack
} from '@chakra-ui/react';
import { RowData } from '@tanstack/react-table';
import { Pagination } from 'components/Pagination';
import { useRef } from 'react';

import { NoResult } from './NoResult';
import { TableProps } from './Table.types';
import { TableBodyRow, TableHeader, VirtualTableBody } from './components';
import { useTable } from './useTable';

export const Table = <TData extends RowData>(
  props: TableProps<TData>
): JSX.Element => {
  const {
    shouldHidePagination = false,
    getIsDisabled = () => false,
    getIsSelected = () => false,
    getIsAttentioned = () => false,
    shouldHideHeader = false,
    useVirtualTable = false,
    canInteractWithDisabled = false,
    hasHorizontalScroll = false,
    shouldHideErrorMessage = false,
    getHighlightColor
  } = props;

  const tableContainerRef = useRef<HTMLDivElement>(null);

  const { table, paginationProps, hasResult, noResultMessage } =
    useTable(props);

  return (
    <VStack
      alignItems="initial"
      spacing={6}
      height="100%"
      justifyContent="space-between"
    >
      {hasResult || shouldHideErrorMessage ? (
        <TableContainer
          w="100%"
          overflowY="auto"
          overflowX={hasHorizontalScroll ? 'auto' : 'hidden'}
          ref={tableContainerRef}
        >
          <ChakraTable
            variant="simple"
            colorScheme="gray"
            data-hasxscroll={hasHorizontalScroll}
          >
            {!shouldHideHeader && (
              <Thead>
                {table.getHeaderGroups().map((headerGroup) => (
                  <Tr key={headerGroup.id}>
                    {headerGroup.headers.map((header) => (
                      <TableHeader key={header.id} header={header} />
                    ))}
                  </Tr>
                ))}
              </Thead>
            )}
            {useVirtualTable ? (
              <VirtualTableBody
                table={table}
                tableContainerRef={tableContainerRef}
                getIsDisabled={getIsDisabled}
                getIsSelected={getIsSelected}
                getIsAttentioned={getIsAttentioned}
                canInteractWithDisabled={canInteractWithDisabled}
                getHighlightColor={getHighlightColor}
              />
            ) : (
              <Tbody>
                {table.getRowModel().rows.map((row) => (
                  <TableBodyRow
                    key={row.id}
                    row={row}
                    getIsDisabled={getIsDisabled}
                    getIsSelected={getIsSelected}
                    getIsAttentioned={getIsAttentioned}
                    canInteractWithDisabled={canInteractWithDisabled}
                    getHighlightColor={getHighlightColor}
                  />
                ))}
              </Tbody>
            )}
          </ChakraTable>
        </TableContainer>
      ) : (
        <NoResult text={noResultMessage} />
      )}

      {!shouldHidePagination && <Pagination {...paginationProps} />}
    </VStack>
  );
};
