import { DIGITS_ONLY_REGEXP, LATIN_CHARACTERS_REGEXP } from 'utils/validation';
import { setLocale } from 'yup';

export const initYupLocaleErrors = () => {
  setLocale({
    mixed: {
      required: 'required',
      default: 'invalid'
    },
    number: {
      min: ({ min }) => ({ key: 'number_field_too_short', values: { min } }),
      max: ({ max }) => ({ key: 'number_field_too_big', values: { max } }),
      positive: ({ more }) => ({
        key: 'positive_int_numbers',
        values: { more }
      })
    },
    string: {
      email: 'invalid_email',
      min: ({ min }) => ({ key: 'field_too_short', values: { min } }),
      max: ({ max }) => ({ key: 'field_too_big', values: { max } }),
      matches: ({ regex }) => {
        switch (String(regex)) {
          case String(LATIN_CHARACTERS_REGEXP):
            return {
              key: 'latin_characters'
            };
          case String(DIGITS_ONLY_REGEXP):
            return {
              key: 'only_digits'
            };

          default:
            return 'invalid';
        }
      }
    }
  });
};
