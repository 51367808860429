import { AddIcon } from '@chakra-ui/icons';
import { Button, ButtonProps } from '@chakra-ui/react';

export const AddButton = ({ children, ...rest }: ButtonProps): JSX.Element => (
  <Button
    variant="link"
    leftIcon={<AddIcon boxSize="0.6875rem" />}
    iconSpacing="0.6875rem"
    {...rest}
  >
    {children}
  </Button>
);
