import { Tbody, Td, Tr } from '@chakra-ui/react';
import { RowData, Table } from '@tanstack/react-table';
import { TableBodyRow, TableProps } from 'components';
import { RefObject } from 'react';
import { useVirtual } from 'react-virtual';

type Props<TData extends RowData> = {
  tableContainerRef: RefObject<HTMLDivElement>;
  table: Table<TData>;
  getIsDisabled: NonNullable<TableProps<TData>['getIsDisabled']>;
  getIsSelected: NonNullable<TableProps<TData>['getIsSelected']>;
  getIsAttentioned: NonNullable<TableProps<TData>['getIsAttentioned']>;
  canInteractWithDisabled: TableProps<TData>['canInteractWithDisabled'];
  getHighlightColor: TableProps<TData>['getHighlightColor'];
};

export const VirtualTableBody = <TData extends RowData>({
  table,
  tableContainerRef,
  getIsDisabled,
  getIsSelected,
  getIsAttentioned,
  canInteractWithDisabled,
  getHighlightColor
}: Props<TData>): JSX.Element => {
  const { rows } = table.getRowModel();

  const rowVirtualizer = useVirtual({
    parentRef: tableContainerRef,
    size: rows.length,
    overscan: 10
  });

  const { virtualItems: virtualRows, totalSize } = rowVirtualizer;

  const paddingTop = virtualRows.length > 0 ? virtualRows?.[0]?.start || 0 : 0;
  const paddingBottom =
    virtualRows.length > 0
      ? totalSize - (virtualRows?.[virtualRows.length - 1]?.end || 0)
      : 0;

  return (
    <Tbody>
      {paddingTop > 0 && (
        <Tr>
          <Td height={`${paddingTop}px`} />
        </Tr>
      )}
      {virtualRows.map((virtualRow) => {
        const row = rows[virtualRow.index];

        return (
          <TableBodyRow
            key={row.id}
            row={row}
            getIsDisabled={getIsDisabled}
            getIsSelected={getIsSelected}
            getIsAttentioned={getIsAttentioned}
            canInteractWithDisabled={canInteractWithDisabled}
            getHighlightColor={getHighlightColor}
          />
        );
      })}
      {paddingBottom > 0 && (
        <Tr>
          <Td height={`${paddingBottom}px`} />
        </Tr>
      )}
    </Tbody>
  );
};
