import { useMutation, useQueryClient } from '@tanstack/react-query';
import { fetchCreateStaticPage } from 'api';
import { AxiosError } from 'axios';
import { QueryKey } from 'enum/api';
import { AxiosErrorData } from 'types';

export const useCreateStaticPage = () => {
  const queryClient = useQueryClient();

  return useMutation(fetchCreateStaticPage, {
    onSuccess() {
      queryClient.invalidateQueries([QueryKey.StaticPages]);
    },
    onError(error: AxiosError<AxiosErrorData>) {}
  });
};
