import { PageName } from 'enum';
import { PermissionAction, PermissionResource } from 'enum/api';

export const PAGE_RESTRICTIONS = {
  [PageName.AdminManagement]: {
    resource: PermissionResource.Admins,
    actions: PermissionAction.Read
  },
  [PageName.AdminPanel]: {
    resource: PermissionResource.AdminDashboard,
    actions: PermissionAction.Read
  },
  [PageName.Customers]: {
    resource: PermissionResource.Customers,
    actions: PermissionAction.Read
  },
  [PageName.Settings]: {
    resource: PermissionResource.Settings,
    actions: PermissionAction.Read
  },
  [PageName.Moderation]: {
    resource: PermissionResource.Moderation,
    actions: PermissionAction.Read
  },
  [PageName.LogActivity]: {
    resource: PermissionResource.LogActivity,
    actions: PermissionAction.Read
  },
  [PageName.PopupsManagement]: {
    resource: PermissionResource.Popups,
    actions: PermissionAction.Read
  },
  [PageName.ContactUs]: {
    resource: PermissionResource.ContactUs,
    actions: PermissionAction.Read
  },
  [PageName.Reports]: {
    resource: PermissionResource.Reports,
    actions: PermissionAction.Read
  },
  [PageName.PaymentsManagement]: {
    resource: PermissionResource.Payments,
    actions: PermissionAction.Read
  },
  [PageName.BlogManagement]: {
    resource: PermissionResource.Articles,
    actions: PermissionAction.Read
  },
  [PageName.Content]: {
    resource: PermissionResource.Content,
    actions: PermissionAction.Read
  },
  [PageName.CRM]: {
    resource: PermissionResource.CRM,
    actions: PermissionAction.Read
  },
  [PageName.TablesManagement]: [
    {
      resource: PermissionResource.ParameterOptions,
      actions: PermissionAction.Read
    },
    {
      resource: PermissionResource.ParameterValues,
      actions: PermissionAction.Read
    }
  ],
  [PageName.StaticPages]: {
    resource: PermissionResource.StaticPages,
    actions: PermissionAction.Read
  },
  [PageName.DomainsConfig]: {
    resource: PermissionResource.DomainConfigs,
    actions: PermissionAction.Read
  }
};
