import { createSlice } from '@reduxjs/toolkit';

import { resetUser, setUser } from './actions';
import { UserState } from './user.types';

const getInitialState = (): UserState => ({
  id: null,
  userName: null,
  role: null,
  permissions: null
});

export const userSlice = createSlice({
  name: 'user',
  initialState: getInitialState(),
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(setUser, (_, action) => action.payload);
    builder.addCase(resetUser, getInitialState);
  }
});

export const { reducer: userReducer } = userSlice;
