import { FormField, InputElement } from 'enum';
import * as yup from 'yup';

import { DeleteCustomerAccountFormData } from './DeleteCustomerAccount.types';

export const deleteCustomerAccountValidationSchema: yup.SchemaOf<DeleteCustomerAccountFormData> =
  yup.object({
    [FormField.Comment]: yup.string().required()
  });

export const deleteCustomerAccountFormSchema = {
  fields: {
    [FormField.Comment]: {
      type: InputElement.TextArea,
      translationKey: 'comment',
      getPlaceholder: () => '',
      minHeight: 44,
      maxLength: 2000
    }
  },
  gridLayout: {
    templateAreas: `
    "${FormField.Comment}"
    `
  }
} as const;
