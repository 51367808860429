import { Button, Text, TextProps } from '@chakra-ui/react';
import { Fragment, ReactNode, useLayoutEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

type Props = Omit<TextProps, 'onClick'> & {
  visibleLines?: number;
  children: ReactNode;
  onClick?: () => void;
};

export const TruncateText = ({
  visibleLines = 2,
  children,
  onClick,
  ...rest
}: Props): JSX.Element => {
  const [t] = useTranslation();

  const textRef = useRef<HTMLParagraphElement>(null);

  const [isExpanded, setIsExpanded] = useState(false);
  const [canBeExpanded, setCanBeExpanded] = useState(false);

  useLayoutEffect(() => {
    if (textRef.current) {
      const { clientHeight, scrollHeight } = textRef.current;

      setCanBeExpanded(scrollHeight > clientHeight);
    }
  }, []);

  const textContent = (
    <Text
      noOfLines={isExpanded ? undefined : visibleLines}
      {...rest}
      ref={textRef}
    >
      {children}
    </Text>
  );

  return (
    <Fragment>
      {onClick ? (
        <Button variant="link" onClick={onClick} color="black">
          {children ? textContent : '---'}
        </Button>
      ) : (
        textContent
      )}
      {canBeExpanded && (
        <Button
          variant="link"
          onClick={() => {
            setIsExpanded((prev) => !prev);
          }}
        >
          {isExpanded ? t('actions.see_less') : t('actions.see_more')}
        </Button>
      )}
    </Fragment>
  );
};
