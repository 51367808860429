import { ENDPOINTS, ParameterValueCreateParams } from 'api';
import axios from 'axios';

export const fetchCreateParameterValue = ({
  meta,
  ...body
}: ParameterValueCreateParams) =>
  axios.post(`${ENDPOINTS.PARAMETERS_VALUES}`, {
    ...body,
    ...(meta && { meta: JSON.stringify(meta) })
  });
