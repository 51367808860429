import { VStack } from '@chakra-ui/react';

import { NavItem } from './NavItem';
import { SideNavItem } from './SideNav.types';

type Props = {
  list: SideNavItem[];
  selected: string;
};

export const NavList = ({ list, selected }: Props): JSX.Element => (
  <VStack
    spacing={2}
    alignItems="stretch"
    overflow="auto"
    flex={1}
    sx={{
      '-ms-overflow-style': 'none',
      'scrollbar-width': 'none',
      '&::-webkit-scrollbar': {
        display: 'none'
      }
    }}
    paddingBottom="6rem"
  >
    {list.map((item) => (
      <NavItem key={item.id} {...item} selected={selected} />
    ))}
  </VStack>
);
