import { CheckCircleIcon } from '@chakra-ui/icons';
import { chakra, Highlight, useMergeRefs } from '@chakra-ui/react';
import { useRef } from 'react';
import { GroupBase, OptionProps } from 'react-select';
import { useMount } from 'react-use';

import { InputOption } from '../LocationDropdown.types';
import { getAttributeProperty, useStyles } from '../LocationDropdown.utils';

const scrollIntoViewOffset = 10; //px

export const Option = <
  Option extends InputOption<unknown>,
  IsMulti extends boolean,
  Group extends GroupBase<Option>
>(
  props: OptionProps<Option, IsMulti, Group>
): JSX.Element => {
  const {
    children,
    innerRef,
    innerProps,
    selectProps: { inputValue, scrollToValue, value: selectValue },
    isSelected,
    data: option
  } = props;

  const styles = useStyles();

  const label =
    typeof children === 'string' ? (
      <Highlight
        query={inputValue}
        styles={{
          color: 'gray.500'
        }}
      >
        {children}
      </Highlight>
    ) : (
      children
    );

  const ref = useRef<HTMLDivElement | null>(null);
  const refs = useMergeRefs(ref, innerRef);

  useMount(() => {
    const element = ref.current;

    if (!element) {
      return;
    }

    const scrollContainer = element.parentElement;
    const distance = element.offsetTop - scrollIntoViewOffset;

    if (selectValue) {
      if (scrollToValue && isSelected) {
        scrollContainer?.scrollTo(0, distance);
      }
    } else if (
      typeof scrollToValue === 'object' &&
      option.value === scrollToValue.value
    ) {
      scrollContainer?.scrollTo(0, distance);
    }
  });

  return (
    <chakra.div
      ref={refs}
      __css={styles.option}
      {...innerProps}
      {...getAttributeProperty({ ...props, ...option })}
    >
      <chakra.span>{label}</chakra.span>
      {isSelected && <CheckCircleIcon __css={styles.optionSelectedIcon} />}
    </chakra.div>
  );
};
