import { createSelector } from '@reduxjs/toolkit';
import { ModalType } from 'enum';
import { RootState } from 'store';

export const selectOpenModal = (state: RootState) => state.modal.openModal;

export const makeSelectIsOpenModalByType = () =>
  createSelector(
    selectOpenModal,
    (_: unknown, type: ModalType) => type,
    (openModal, type) => openModal.find(({ id }) => id === type) || null
  );
