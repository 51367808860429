import { ENDPOINTS } from 'api/endpoints';
import axios from 'axios';

import { CancelCustomerSubscription } from './Payments.types';

export const fetchCancelCustomerSubscription = ({
  customerId,
  comment
}: CancelCustomerSubscription) =>
  axios.post(`${ENDPOINTS.PAYMENT_SUBSCRIPTION}/cancel/${customerId}`, {
    comment
  });
