import { Grid, GridItem } from '@chakra-ui/react';
import { AddButton } from 'components';
import { ContentContainer } from 'containers';
import { useTranslation } from 'react-i18next';
import { Outlet, useNavigate } from 'react-router-dom';

import { BLOG_SECTION_LIST } from './BlogManagement.utils';
import { BlogDropdownTitle } from './components/BlogDropdownTitle';

export const BlogManagement = (): JSX.Element => {
  const [t] = useTranslation();
  const navigate = useNavigate();

  const createArticle = () => {
    navigate('create');
  };

  return (
    <ContentContainer height="100%" display="flex" flexDirection="column">
      <Grid
        templateAreas={`
            "title ."
            "search actions"
            "filters filters"
            "table table"
       `}
        templateColumns="26.25rem 1fr"
        templateRows="auto min-content min-content 1fr"
        rowGap={6}
        height="100%"
      >
        <GridItem area="title">
          <BlogDropdownTitle sections={BLOG_SECTION_LIST} />
        </GridItem>
        <GridItem area="actions" justifySelf="flex-end">
          <AddButton onClick={createArticle}>
            {t('actions.create_article')}
          </AddButton>
        </GridItem>
        <Outlet />
      </Grid>
    </ContentContainer>
  );
};
