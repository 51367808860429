import { FormField } from 'enum';
import * as yup from 'yup';

import { AuthorizationFormField } from './Authorization.types';

export const validationSchema: yup.SchemaOf<AuthorizationFormField> =
  yup.object({
    [FormField.Email]: yup.string().required().defined().email(),
    [FormField.Password]: yup.string().required().defined().min(10).max(25)
  });
