import { FormField, InputElement } from 'enum';
import * as yup from 'yup';

import { EditJSONFormData } from './EditJSON.types';

export const EditJSONFormSchema = {
  fields: {
    [FormField.Json]: {
      type: InputElement.TextArea,
      translationKey: 'edit_value',
      getPlaceholder: () => '',
      minHeight: 44
    }
  },
  gridLayout: {
    templateAreas: `
    "${FormField.Json}"
  `,
    gridTemplateColumns: '1fr'
  }
} as const;

export const EditJSONValidationSchema: yup.SchemaOf<EditJSONFormData> =
  yup.object({
    [FormField.Json]: yup.string().nullable()
  });
