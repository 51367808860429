import { ENDPOINTS } from 'api';
import axios from 'axios';

export const fetchUpdatePaymentSubscription = ({
  subscriptionId,
  countries
}: {
  subscriptionId: string;
  countries: string[];
}) =>
  axios.patch(`${ENDPOINTS.PAYMENT_SUBSCRIPTION}/${subscriptionId}`, {
    countries
  });
