import { Badge, Button, HStack, Icon, Text } from '@chakra-ui/react';
import { NavigationLink } from 'components';

import { SideNavItem } from './SideNav.types';

type Props = SideNavItem & {
  selected: string;
};

export const NavItem = ({
  id,
  icon,
  label,
  badgeValue,
  selected,
  to,
  isDisabled
}: Props): JSX.Element | null => {
  const buttonContent = badgeValue ? (
    <HStack justifyContent="space-between" spacing="3.5" width="100%">
      <Text>{label}</Text>
      <Badge>{badgeValue}</Badge>
    </HStack>
  ) : (
    label
  );

  return (
    <NavigationLink to={to} isDisabled={isDisabled}>
      <Button
        variant="ghost"
        colorScheme="gray"
        _hover={{
          background: 'transparent',
          color: 'white'
        }}
        _disabled={{
          color: 'gray.900',
          pointerEvents: 'none'
        }}
        aria-selected={selected === id}
        leftIcon={<Icon as={icon} boxSize={5} />}
        iconSpacing={3.5}
        size="lg"
        justifyContent="start"
        paddingRight={2}
        width="100%"
        isDisabled={isDisabled}
      >
        {buttonContent}
      </Button>
    </NavigationLink>
  );
};
