import { Button, Grid, GridItem, VStack } from '@chakra-ui/react';
import {
  GalleryImageResponse,
  MessageImageResponse,
  StoryResponse
} from 'api/Content/Content.types';
import { ModalType } from 'enum';
import { AssetType } from 'enum/api';
import { useAppDispatch } from 'hooks';
import { useTranslation } from 'react-i18next';
import { openModal } from 'store/modal';
import {
  ICustomerGalleryImages,
  ICustomerMessageImages,
  ICustomerStoryList
} from 'types/customer';

import { AssetActionItems, AssetCard } from './AssetCard';

type Props = Pick<
  Parameters<typeof AssetActionItems>[0],
  'onDeclineAsset' | 'onDeleteAsset' | 'onRestoreAsset'
> & {
  data:
    | ICustomerStoryList[]
    | ICustomerMessageImages[]
    | ICustomerGalleryImages[]
    | GalleryImageResponse[]
    | MessageImageResponse[]
    | StoryResponse[];
  enableEditing?: boolean;
};

export const AssetsContainer = ({
  data,
  enableEditing = false,
  onDeleteAsset,
  onRestoreAsset,
  onDeclineAsset
}: Props): JSX.Element => {
  const dispatch = useAppDispatch();
  const [t] = useTranslation();

  const handleEdit = (item: ICustomerGalleryImages) =>
    dispatch(
      openModal({
        id: ModalType.EditGalleryPhoto,
        meta: {
          photo: item
        }
      })
    );

  return (
    <Grid
      gap={4}
      gridTemplateColumns="repeat(auto-fill, minmax(16rem, 1fr))"
      height="100%"
    >
      {data.map((item) => {
        const isStory = 'assets' in item;
        const assets = isStory
          ? item.assets
          : [
              {
                ...item,
                assetUrl: item.imageUrl,
                type: AssetType.Image
              }
            ];

        return (
          <GridItem key={item.id}>
            <VStack>
              <AssetCard
                assets={assets}
                uploadedAt={new Date(item.uploadedAt)}
                statusAssignedAt={new Date(item.statusedAt)}
                status={item.status}
                assetActions={
                  <AssetActionItems
                    assetId={item.id}
                    assetStatus={item.status}
                    onDeleteAsset={onDeleteAsset}
                    onDeclineAsset={onDeclineAsset}
                    onRestoreAsset={onRestoreAsset}
                  />
                }
                userId={
                  (
                    item as
                      | GalleryImageResponse
                      | MessageImageResponse
                      | StoryResponse
                  ).userId
                }
              />
              {enableEditing && (
                <Button
                  width="70%"
                  variant="ghostGray"
                  onClick={() => handleEdit(item as ICustomerGalleryImages)}
                  color="green"
                >
                  {t('actions.edit')}
                </Button>
              )}
            </VStack>
          </GridItem>
        );
      })}
    </Grid>
  );
};
