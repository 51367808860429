import { ENDPOINTS } from 'api/endpoints';
import axios from 'axios';

import { CreateCustomerRequest } from '../Customers.types';

export const fetchCreateCustomer = async (
  customer: CreateCustomerRequest
): Promise<void> => {
  await axios.post(ENDPOINTS.CUSTOMER, customer);
};
