import { GridItem, Spinner } from '@chakra-ui/react';
import { Table } from 'components';
import {
  useCustomerIdFromUrl,
  useCustomerLoginHistory,
  useTableQueryController
} from 'hooks';

import { loginHistoryColumns } from '../AdminInteraction.utils';

export const LoginHistory = (): JSX.Element => {
  const {
    queryFilters,
    action: { onChangeSort, onChangePagination }
  } = useTableQueryController();

  const customerId = useCustomerIdFromUrl();

  const { data } = useCustomerLoginHistory(customerId, queryFilters);

  if (!data) return <Spinner />;

  return (
    <GridItem area="table" overflow="hidden">
      <Table
        data={data.data}
        columns={loginHistoryColumns}
        onChangeSort={onChangeSort}
        onChangePagination={onChangePagination}
        totalItems={data.totalItems}
        pagination={queryFilters.pagination}
      />
    </GridItem>
  );
};
