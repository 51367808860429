import { MinusIcon } from '@chakra-ui/icons';
import { ButtonProps, IconButton } from '@chakra-ui/react';

type Props = ButtonProps & {
  onClick: () => void;
};

export const RemoveButton = ({ onClick, ...rest }: Props): JSX.Element => (
  <IconButton
    marginTop={6}
    aria-label="remove"
    icon={<MinusIcon />}
    variant="link"
    onClick={onClick}
    fontSize="lg"
    boxSize={10}
    {...rest}
  />
);
