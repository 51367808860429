import { Box, FormLabel, HStack, IconButton } from '@chakra-ui/react';
import { AddButton, Avatar, EditIcon } from 'components';
import { ModalType } from 'enum';
import { useAppDispatch, useAppSelector, useCustomerInfo } from 'hooks';
import { useTranslation } from 'react-i18next';
import { openModal, selectAppFilters } from 'store';

type Props = {
  selectedUserId?: string | null;
  onChange: (id: string) => void;
  shouldFilterByCountry?: boolean;
  isReadonly?: boolean;
  title?: string;
  shouldShowFilters?: boolean;
};

export const CustomerSelect = ({
  selectedUserId,
  onChange,
  shouldFilterByCountry = false,
  isReadonly,
  title,
  shouldShowFilters
}: Props): JSX.Element => {
  const [t] = useTranslation();
  const dispatch = useAppDispatch();

  const globalFilters = useAppSelector(selectAppFilters);

  const { data } = useCustomerInfo(selectedUserId || '');

  const addUser = () => {
    if (isReadonly) {
      return;
    }

    dispatch(
      openModal({
        id: ModalType.CustomerFullList,
        meta: {
          onChooseCustomer: ({ id }) => onChange(id),
          countryFilter: shouldFilterByCountry
            ? globalFilters['country']
            : null,
          shouldShowFilters
        }
      })
    );
  };

  const user = selectedUserId && data;

  return (
    <Box>
      <FormLabel>{title ?? t('keywords.user')}</FormLabel>
      <Box padding={4} backgroundColor="gray.100" borderRadius="1.25rem">
        {user ? (
          <HStack justifyContent="space-between">
            <Avatar
              title={user.displayedNickname}
              description={user.email}
              src={user.avatar}
            />
            {!isReadonly && (
              <IconButton
                icon={<EditIcon fontSize="2xl" />}
                aria-label="edit user"
                variant="gray"
                color="gray.500"
                _hover={{
                  color: 'initial'
                }}
                onClick={addUser}
              />
            )}
          </HStack>
        ) : (
          <AddButton onClick={addUser} justifySelf="center">
            {t('actions.add_user')}
          </AddButton>
        )}
      </Box>
    </Box>
  );
};
