import { getCSSVar } from '@chakra-ui/react';
import { Fragment } from 'react';
import { convertRemToPixels } from 'utils';

import { GaugeChartElementProps } from '../GaugeChart.types';
import { calculateTickTextOffset } from '../GaugeChart.utils';

export const GaugeTicks = ({
  gauge,
  settings,
  fontSize,
  theme
}: GaugeChartElementProps): JSX.Element => {
  const { ticks, angleToValue, getTickProps, getLabelProps } = gauge;
  const {
    tickWidth,
    tickTextColor,
    tickColor,
    tickColorWithText,
    tickTextSize = '0.75rem',
    tickTextWeight = 'normal'
  } = settings;

  const tickLength = convertRemToPixels(tickWidth, fontSize).pxValue;

  const textColorVar = getCSSVar(theme, 'colors', tickTextColor);
  const tickColorVar = getCSSVar(theme, 'colors', tickColor);
  const tickColorWithTextVar = getCSSVar(theme, 'colors', tickColorWithText);
  const tickTextWeightVar = getCSSVar(theme, 'fontWeight', tickTextWeight);
  const tickTextSizeVar = getCSSVar(theme, 'size', tickTextSize);

  return (
    <g>
      {ticks.map((angle, index) => {
        const isLabelVisible = index % 5 === 0;

        return (
          <Fragment key={`tick-group-${angle}`}>
            <line
              stroke={isLabelVisible ? tickColorWithTextVar : tickColorVar}
              {...getTickProps({ angle, length: tickLength })}
            />
            {isLabelVisible && (
              <text
                {...getLabelProps({
                  angle,
                  offset: calculateTickTextOffset({
                    angle,
                    value: angleToValue(angle),
                    fontSize: tickTextSizeVar,
                    fontWeight: tickTextWeightVar,
                    tickLength
                  })
                })}
                fontSize={tickTextSizeVar}
                fontWeight={tickTextWeightVar}
                fill={textColorVar}
              >
                {angleToValue(angle)}
              </text>
            )}
          </Fragment>
        );
      })}
    </g>
  );
};
