import { Permission } from 'api';
import { PAGE_RESTRICTIONS } from 'containers';
import { PageName } from 'enum';
import { checkIfHasPermissions } from 'utils';

export const getIsDisabledNavigation = (
  pageName: PageName,
  userPermissions: Permission
) => {
  const restrictions =
    PAGE_RESTRICTIONS[pageName as keyof typeof PAGE_RESTRICTIONS];

  if (!restrictions) {
    return true;
  }

  const permissionsList = Array.isArray(restrictions)
    ? restrictions
    : [restrictions];

  return permissionsList.every(
    (permissions) => !checkIfHasPermissions(userPermissions, permissions)
  );
};
