import { useQuery } from '@tanstack/react-query';
import { fetchAdminList } from 'api';
import { QueryKey } from 'enum/api';
import { useAppSelector } from 'hooks';
import { selectAppFilters } from 'store/app';
import { QueryFilters } from 'types';

export const useAdminList = (queryFilters: QueryFilters) => {
  const globalFilters = useAppSelector(selectAppFilters);

  return useQuery(
    [QueryKey.Admins, globalFilters, queryFilters],
    () => fetchAdminList({ globalFilters, queryFilters }),
    {
      retry: false,
      refetchOnWindowFocus: false,
      keepPreviousData: true
    }
  );
};
