import { HStack, Text, VStack } from '@chakra-ui/react';
import { BaseChartDataTemplate } from 'api';

import { StackedBarSeriesProps } from '../../CustomStackedBarChart.types';
import { useStackedChartSeries } from '../../useStackedChartSeries';

import { HorizontalStackedBar } from './HorizontalStackedBar';

export const HorizontalStackedBarSeries = <T extends BaseChartDataTemplate>({
  series,
  chartSettings
}: StackedBarSeriesProps<T>): JSX.Element => {
  const { seriesTitle, getStackedBarProps } = useStackedChartSeries({
    seriesId: series.id,
    dataCount: series.data.length,
    chartSettings
  });

  const { data } = series;

  return (
    <VStack spacing={4} height="100%" alignItems="flex-start">
      {!!seriesTitle && <Text textStyle="mds">{seriesTitle}</Text>}
      <HStack spacing={0} width="100%" alignItems="flex-start">
        {data.map((barData, index) => (
          <HorizontalStackedBar
            key={barData.id}
            {...getStackedBarProps(barData, index)}
          />
        ))}
      </HStack>
    </VStack>
  );
};
