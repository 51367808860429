import { FormField, InputElement } from 'enum';

export const rateForBlockingFloodFormFields = [
  {
    type: InputElement.NumberInput,
    name: FormField.MessageAmount,
    translationKey: 'num_of_messages',
    getPlaceholder: () => '',
    min: 0
  },
  {
    type: InputElement.NumberInput,
    name: FormField.Period,
    translationKey: 'period_of_time_in_seconds',
    getPlaceholder: () => '',
    min: 0
  }
] as const;
