import {
  Checkbox,
  CheckboxGroup,
  Grid,
  MenuItem,
  Text,
  VStack
} from '@chakra-ui/react';
import { InputElement } from 'enum';
import { Fragment } from 'react';
import { GetUnionType } from 'types';

import { ListContainerProps } from '../ButtonDropdown.types';

type Props = GetUnionType<
  ListContainerProps,
  { type: InputElement.CheckboxGroup }
>;

export const CheckboxGroupList = ({
  options,
  selectedValues,
  onChange
}: Props): JSX.Element => (
  <Grid templateColumns="repeat(2, auto)" marginX={3}>
    {options.map((option) => (
      <Fragment key={option.groupKey}>
        <Text
          paddingTop={6}
          paddingRight={6}
          _notFirst={{
            borderTop: '1px solid',
            borderColor: 'gray.300'
          }}
        >
          {option.groupLabel}
        </Text>
        <VStack
          paddingY={2}
          spacing={0}
          _notFirst={{
            borderTop: '1px solid',
            borderColor: 'gray.300'
          }}
        >
          <CheckboxGroup
            value={selectedValues[option.groupKey] || []}
            onChange={(value: string[]) => onChange(value, option.groupKey)}
          >
            {option?.options?.length &&
              option.options.map(({ label, value }) => (
                <MenuItem
                  as={Checkbox}
                  key={`${option.groupKey}-${value}`}
                  value={value}
                  padding={3}
                >
                  {label}
                </MenuItem>
              ))}
          </CheckboxGroup>
        </VStack>
      </Fragment>
    ))}
  </Grid>
);
