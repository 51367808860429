import { Tag } from '@chakra-ui/react';
import { Dropdown } from 'components';
import { DropdownType } from 'enum';
import { getAllEnumValues } from 'enum-for';
import { ParameterOptionCountryRestrictionType } from 'enum/api';

import { getTagColorSchemeByRestrictionType } from '../TableManagement.utils';

type Props = {
  status: ParameterOptionCountryRestrictionType;
  updateEntity: (data: ParameterOptionCountryRestrictionType) => Promise<void>;
  isRestrictedCountries: boolean;
};

export const RestrictionTypeCell = ({
  updateEntity,
  status: selectedStatus,
  isRestrictedCountries
}: Props): JSX.Element => {
  const options = getAllEnumValues(ParameterOptionCountryRestrictionType).map(
    (value) => ({
      label: (
        <Tag
          variant="solid"
          colorScheme={getTagColorSchemeByRestrictionType(
            value,
            isRestrictedCountries
          )}
          size="md"
        >
          {value}
        </Tag>
      ),
      value
    })
  );

  return (
    <Dropdown
      type={DropdownType.Tag}
      options={options}
      selectedValue={selectedStatus}
      onChange={(value) =>
        updateEntity(value as ParameterOptionCountryRestrictionType)
      }
    />
  );
};
