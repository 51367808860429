import { useState } from 'react';
import { FieldValues, UseFormReturn } from 'react-hook-form';
import { useMount } from 'react-use';
import * as yup from 'yup';

export const useFormValidation = <T extends FieldValues>(
  form: UseFormReturn<T>,
  validationSchema: yup.SchemaOf<T>
): boolean => {
  const [isValidForm, setIsValidForm] = useState(false);

  useMount(() => {
    const { watch } = form;

    const subscription = watch(async (data) => {
      try {
        await validationSchema.validate(data);

        setIsValidForm(true);
      } catch {
        setIsValidForm(false);
      }
    });

    return () => subscription.unsubscribe();
  });

  return isValidForm;
};
