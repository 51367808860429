import { ApiQuerySelection, WithPagination } from 'api/api.types';
import { ENDPOINTS } from 'api/endpoints';
import axios from 'axios';
import { ParameterType } from 'enum';

import { ParameterValue, ParameterValueFromApi } from './Settings.types';
import { parseMeta } from './settings.utils';

export const fetchParameterValues = async (
  params: ApiQuerySelection<
    'value' | 'updatedAt',
    {
      parameterTypes?: ParameterType[];
      languageCodes?: string[];
      parameterTitle?: string;
      value?: string;
    }
  >
): Promise<WithPagination<ParameterValue[]>> => {
  const { data } = await axios.post<WithPagination<ParameterValueFromApi[]>>(
    ENDPOINTS.PARAMETERS_VALUES_LIST,
    params
  );

  return {
    ...data,
    data: data.data.map((option) => ({
      ...option,
      meta: parseMeta(option.meta)
    }))
  };
};
