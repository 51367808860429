import { yupResolver } from '@hookform/resolvers/yup';
import { FormConstructor, ModalContainer } from 'containers';
import { FormField, ModalType } from 'enum';
import {
  useChakraToast,
  useCreatePaymentTransaction,
  useFormValidation,
  useModalActions,
  useSubscriptionOptionsByUser
} from 'hooks';
import startCase from 'lodash/startCase';
import { useEffect, useMemo } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { ParamType } from 'types';

import { AddCustomerTransactionFormData } from './AddTransaction.types';
import {
  addCustomerTransactionValidationSchema,
  getAddCustomerTransactionFormSchema
} from './AddTransaction.utils';

export const AddTransaction = (): JSX.Element => {
  const [t] = useTranslation();
  const toast = useChakraToast();

  const form = useForm<AddCustomerTransactionFormData>({
    mode: 'onSubmit',
    resolver: yupResolver(addCustomerTransactionValidationSchema)
  });

  const { isOpen, onClose, meta } = useModalActions(
    ModalType.AddCustomerTransaction,
    {
      onClose: form.reset
    }
  );

  const { handleSubmit } = form;

  const { data: options } = useSubscriptionOptionsByUser({
    customerId: meta?.customerId || ''
  });

  const { mutate, isLoading } = useCreatePaymentTransaction();

  const isFormFilled = useFormValidation<AddCustomerTransactionFormData>(
    form,
    addCustomerTransactionValidationSchema
  );

  useEffect(() => {
    const { watch, resetField } = form;

    const subscription = watch((_, { name }) => {
      switch (name) {
        case FormField.SubscriptionProgramType:
          resetField(FormField.SubscriptionTypeName);
          return;
        case FormField.SubscriptionTypeName:
          resetField(FormField.SumPaid);
          resetField(FormField.Currency);
          return;
      }
    });

    return () => subscription.unsubscribe();
  }, [form]);

  const onSubmit = handleSubmit((data) => {
    const { customerId } = meta || {};

    if (!customerId) return;

    mutate(
      {
        userId: customerId,
        programType: data.subscriptionProgramType as string,
        subscriptionType: data.subscriptionTypeName as string,
        amount: data.sumPaid as number,
        currency: data.currency as string,
        comment: data.comment as string
      },
      {
        onSuccess: onClose,
        onError: (error) => {
          toast({
            status: 'error',
            title: t('errors.invalid_form'),
            description: error.message
          });
        }
      }
    );
  });

  const actions: ParamType<typeof ModalContainer, 'footerButtons'> = [
    {
      text: t('actions.cancel'),
      variant: 'ghostGray'
    },
    {
      text: startCase(t('actions.add_transaction')),
      colorScheme: 'primary',
      isLoading,
      isPreventClose: true,
      isDisabled: !isFormFilled,
      onClick: () => {
        onSubmit();
      }
    }
  ];

  const addCustomerTransactionFormSchema = useMemo(
    () => getAddCustomerTransactionFormSchema(options),
    [options]
  );

  return (
    <ModalContainer
      header={t('attribute.title.add_transaction')}
      footerButtons={actions}
      isOpen={isOpen}
      onClose={onClose}
      size="xl"
    >
      <FormProvider {...form}>
        <FormConstructor formSchema={addCustomerTransactionFormSchema} />
      </FormProvider>
    </ModalContainer>
  );
};
