import { Text } from '@chakra-ui/react';
import { createColumnHelper } from '@tanstack/react-table';
import { ParameterTypeOptionTranslateCell, TruncateText } from 'components';
import { TableFilterSchema } from 'containers';
import { format } from 'date-fns';
import { CustomerCSVFields, InputElement, OnlineStatus } from 'enum';
import { OptionKey, ParameterType } from 'enum/api';
import { TFunction } from 'react-i18next';
import { ICustomerTable } from 'types/customer';
import { getDateFormattedString } from 'utils';

import { Labels, NicknameCell } from './components';

const columnHelper = createColumnHelper<ICustomerTable>();

export const columns = [
  columnHelper.accessor('displayedNickname', {
    cell: NicknameCell,
    header: ({ table }) => table.options.meta?.t('keywords.nickname')
  }),
  columnHelper.accessor('role', {
    cell: ({ getValue }) => (
      <ParameterTypeOptionTranslateCell
        optionKey={ParameterType.CustomerRole}
        value={getValue()}
      />
    ),
    header: ({ table }) => table.options.meta?.t('keywords.customer_role'),
    enableSorting: false
  }),
  columnHelper.accessor('email', {
    cell: (info) => info.getValue(),
    header: ({ table }) => table.options.meta?.t('keywords.email')
  }),
  columnHelper.accessor('createdAt', {
    cell: ({ getValue, table }) =>
      getDateFormattedString({
        t: table.options.meta?.t,
        date: getValue()
      }),
    header: ({ table }) =>
      table.options.meta?.t('keywords.registration_date_and_time')
  }),
  columnHelper.accessor('fullyRegisteredAt', {
    cell: ({ getValue, table }) => {
      const value = getValue();

      return value
        ? getDateFormattedString({
            t: table.options.meta?.t,
            date: value
          })
        : null;
    },
    header: ({ table }) =>
      table.options.meta?.t('keywords.finish_registration_date_and_time')
  }),
  columnHelper.accessor('deletedAt', {
    cell: (info) => {
      const {
        row: {
          original: { deletedAt }
        },
        table
      } = info;

      return deletedAt ? (
        <Text>
          {getDateFormattedString({
            date: deletedAt,
            t: table.options.meta?.t
          })}
        </Text>
      ) : null;
    },
    header: ({ table }) => table.options.meta?.t('keywords.deletion_info'),
    enableSorting: false
  }),
  columnHelper.accessor('reportsAdminsNum', {
    cell: (info) => info.getValue(),
    header: ({ table }) => (
      <Text overflowWrap="normal">{table.options.meta?.t('keywords.ar')}</Text>
    ),
    enableSorting: false
  }),
  columnHelper.accessor('reportsToNum', {
    cell: (info) => info.getValue(),
    header: ({ table }) => (
      <Text overflowWrap="normal">{table.options.meta?.t('keywords.cr')}</Text>
    ),
    enableSorting: false
  }),
  columnHelper.accessor('labels', {
    cell: (info) => (
      <Labels
        labels={info.getValue()}
        maxItems={3}
        customerId={info.row.original.id}
      />
    ),
    header: ({ table }) => table.options.meta?.t('keywords.labels'),
    enableSorting: false
  }),
  columnHelper.accessor('lastAdminComment', {
    cell: ({ getValue }) => (
      <TruncateText maxWidth={64}>{getValue()}</TruncateText>
    ),
    header: ({ table }) => table.options.meta?.t('keywords.last_admin_comment'),
    enableSorting: false
  })
];

export const tableFilters: TableFilterSchema[] = [
  {
    type: InputElement.Checkbox,
    getLabel: (t) => t('keywords.online_status'),
    optionKey: OptionKey.OnlineStatus,
    updateOutput: (_, value) => ({
      key: 'online',
      value: value.length === 2 ? null : value[0] === OnlineStatus.Online
    })
  },
  {
    type: InputElement.Checkbox,
    getLabel: (t) => t('keywords.customer_role'),
    optionKey: ParameterType.CustomerRole,
    updateOutput: (_, value) => ({
      key: 'roles',
      value
    })
  },
  {
    type: InputElement.Range,
    getLabel: (t) => t('keywords.registration_date'),
    optionKey: 'createdAtBetween',
    updateOutput: (key, value) => ({
      key,
      value: value
        .filter((date): date is string => !!date)
        .map((date) => new Date(date).toISOString())
    }),
    inputProps: {
      type: 'date',
      max: format(new Date(), 'dd.MM.yyyy')
    }
  },
  {
    type: InputElement.Checkbox,
    getLabel: (t) => t('keywords.labels'),
    optionKey: ParameterType.Label,
    updateOutput: (_, value) => ({
      key: 'labels',
      value
    })
  },
  {
    type: InputElement.Checkbox,
    getLabel: (t) => t('keywords.program_type'),
    optionKey: ParameterType.ProgramType,
    updateOutput: (_, value) => ({
      key: 'programTypes',
      value
    })
  },
  {
    type: InputElement.Checkbox,
    getLabel: (t) => t('keywords.account_status'),
    optionKey: OptionKey.AccountStatus,
    updateOutput: (_, value) => ({
      key: 'statuses',
      value
    })
  }
];

export const getSearchOptions = (t: TFunction) => [
  {
    label: t('keywords.nickname'),
    value: 'nickname'
  },
  {
    label: t('keywords.email'),
    value: 'email'
  },
  {
    label: t('keywords.phone_number'),
    value: 'phoneNumber'
  },
  {
    label: t('keywords.utm_params'),
    value: 'utmParams'
  }
];

export const getCSVOptions = (
  t: TFunction
): { label: string; value: CustomerCSVFields }[] => [
  {
    label: t('keywords.nickname'),
    value: CustomerCSVFields.Nickname
  },
  {
    label: t('keywords.program_type'),
    value: CustomerCSVFields.ProgramType
  },
  {
    label: t('keywords.email'),
    value: CustomerCSVFields.Email
  },
  {
    label: t('keywords.labels'),
    value: CustomerCSVFields.Labels
  },
  {
    label: t('keywords.customer_role'),
    value: CustomerCSVFields.CustomerRole
  },
  {
    label: t('keywords.account_status'),
    value: CustomerCSVFields.AccountStatus
  },
  {
    label: t('keywords.registration_date_and_time'),
    value: CustomerCSVFields.CreatedAt
  },
  {
    label: t('keywords.phone_number'),
    value: CustomerCSVFields.PhoneNumber
  }
];
