import { Icon, IconProps } from '@chakra-ui/react';

export const CheckIcon = (props: IconProps): JSX.Element => (
  <Icon viewBox="0 0 16 16" fill="none" {...props}>
    <path
      d="M3.5 8.5L6.5 11.5L12.5 5.5"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </Icon>
);
