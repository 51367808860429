import { GridItem } from '@chakra-ui/react';
import { yupResolver } from '@hookform/resolvers/yup';
import { FormConstructor, ModalContainer } from 'containers';
import { FormField, ModalType } from 'enum';
import { useFormValidation, useModalActions } from 'hooks';
import { useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { ParamType } from 'types';

import { RequiredCustomersChatAccessRequestFormData } from './RequiredCustomersChatAccessRequest.types';
import {
  requiredCustomersChatAccessRequestFormSchema,
  requiredCustomersChatAccessRequestValidationSchema
} from './RequiredCustomersChatAccessRequest.utils';

export const RequiredCustomersChatAccessRequest = (): JSX.Element => {
  const [t] = useTranslation();

  const form = useForm<RequiredCustomersChatAccessRequestFormData>({
    mode: 'onSubmit',
    resolver: yupResolver(requiredCustomersChatAccessRequestValidationSchema),
    defaultValues: {
      [FormField.EverybodyCanMessageToEverybody]: true
    }
  });

  const { handleSubmit, reset, watch } = form;

  const { isOpen, onClose, meta } = useModalActions(
    ModalType.RequiredCustomersChatAccessRequest,
    {
      onClose: reset
    }
  );

  const isEverybodyCanMessageToEverybody = watch(
    FormField.EverybodyCanMessageToEverybody
  );

  useEffect(() => {
    reset({
      [FormField.CustomerTypes]: [],
      [FormField.OtherCustomerTypes]: [],
      [FormField.Genders]: [],
      [FormField.OtherGenders]: [],
      [FormField.ProgramTypes]: [],
      [FormField.OtherProgramTypes]: [],
      [FormField.Countries]: [],
      [FormField.EverybodyCanMessageToEverybody]:
        isEverybodyCanMessageToEverybody
    });
  }, [reset, isEverybodyCanMessageToEverybody]);

  useEffect(() => {
    if (meta) {
      reset(meta.initialData);
    }
  }, [reset, meta]);

  const onSubmit = handleSubmit((data) => {
    if (meta) {
      meta.onUpdateData(data);
      onClose();
    }
  });

  const isFormFilled =
    useFormValidation<RequiredCustomersChatAccessRequestFormData>(
      form,
      requiredCustomersChatAccessRequestValidationSchema
    );

  const actions: ParamType<typeof ModalContainer, 'footerButtons'> = [
    {
      text: t('actions.discard'),
      variant: 'ghostGray'
    },
    {
      text: t('actions.save_changes'),
      colorScheme: 'primary',
      isDisabled: !isFormFilled,
      isPreventClose: true,
      onClick: () => {
        onSubmit();
      }
    }
  ];

  return (
    <ModalContainer
      header={t(
        'attribute.title.require_request_chat_access_from_another_country'
      )}
      footerButtons={actions}
      isOpen={isOpen}
      onClose={onClose}
      size="4xl"
      modalBodyProps={{
        overflow: 'unset',
        overflowX: 'unset'
      }}
    >
      <FormProvider {...form}>
        <FormConstructor
          formSchema={requiredCustomersChatAccessRequestFormSchema}
        >
          <GridItem area="needs-ask" alignSelf="center" color="subtext">
            {t('keywords.needs_ask')}
          </GridItem>
        </FormConstructor>
      </FormProvider>
    </ModalContainer>
  );
};
