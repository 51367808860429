import { ModalContainer } from 'containers';
import { ModalType } from 'enum';
import { useModalActions, useUpdateStoryStatus } from 'hooks';
import { useTranslation } from 'react-i18next';
import { ParamType } from 'types';

import { getUpdateCustomerStoryStatusTitle } from './DeleteCustomerContent.utils';

export const UpdateCustomerStoryStatus = (): JSX.Element => {
  const [t] = useTranslation();

  const { mutate, isLoading, reset } = useUpdateStoryStatus();

  const { isOpen, onClose, meta } = useModalActions(
    ModalType.UpdateCustomerStoryStatus,
    {
      onClose: reset
    }
  );

  const actions: ParamType<typeof ModalContainer, 'footerButtons'> = [
    {
      text: t('actions.no'),
      variant: 'ghostGray'
    },
    {
      text: t('actions.yes'),
      colorScheme: 'secondary',
      isLoading,
      isPreventClose: true,
      onClick: (onClose) => {
        if (meta) {
          mutate(meta, {
            onSuccess: onClose
          });
        }
      }
    }
  ];
  const headerLabel = getUpdateCustomerStoryStatusTitle(t, meta?.status);

  return (
    <ModalContainer
      header={headerLabel}
      footerButtons={actions}
      isOpen={isOpen}
      onClose={onClose}
    >
      {t('attribute.description.basic_action_question', {
        action: headerLabel.toLowerCase()
      })}
    </ModalContainer>
  );
};
