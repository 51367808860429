import { useMutation, useQueryClient } from '@tanstack/react-query';
import { fetchCreateAdminComment } from 'api';
import { AxiosError } from 'axios';
import { QueryKey } from 'enum/api';
import { AxiosErrorData } from 'types';

export const useCreateAdminComment = () => {
  const queryClient = useQueryClient();

  return useMutation(fetchCreateAdminComment, {
    onSuccess(_, { customerId: id }) {
      queryClient.invalidateQueries([QueryKey.CustomerAdminComments, id]);
      queryClient.invalidateQueries([QueryKey.Customers, id]);
    },
    onError(error: AxiosError<AxiosErrorData>) {}
  });
};
