import { Icon, IconProps } from '@chakra-ui/react';

export const LinkIcon = (props: IconProps): JSX.Element => (
  <Icon viewBox="0 0 24 24" fill="none" {...props}>
    <path
      d="M15.8839 13.1976C15.591 13.4905 15.591 13.9654 15.8839 14.2583C16.1768 14.5511 16.6517 14.5511 16.9445 14.2583L15.8839 13.1976ZM8.81282 6.12652C8.51992 6.41942 8.51992 6.89429 8.81282 7.18718C9.10571 7.48008 9.58058 7.48008 9.87348 7.18718L8.81282 6.12652ZM14.1161 17.0867C14.409 16.7938 14.409 16.3189 14.1161 16.026C13.8232 15.7331 13.3483 15.7331 13.0555 16.026L14.1161 17.0867ZM7.04505 10.0156C7.33794 9.72272 7.33794 9.24784 7.04505 8.95495C6.75216 8.66206 6.27728 8.66206 5.98439 8.95495L7.04505 10.0156ZM18.0052 11.0763L15.8839 13.1976L16.9445 14.2583L19.0659 12.1369L18.0052 11.0763ZM9.87348 7.18718L11.9948 5.06586L10.9341 4.0052L8.81282 6.12652L9.87348 7.18718ZM13.0555 16.026L10.9341 18.1473L11.9948 19.208L14.1161 17.0867L13.0555 16.026ZM4.92373 12.1369L7.04505 10.0156L5.98439 8.95495L3.86307 11.0763L4.92373 12.1369ZM4.92373 18.1473C3.264 16.4876 3.264 13.7967 4.92373 12.1369L3.86307 11.0763C1.61755 13.3218 1.61755 16.9625 3.86307 19.208L4.92373 18.1473ZM3.86307 19.208C6.10858 21.4535 9.74928 21.4535 11.9948 19.208L10.9341 18.1473C9.27441 19.8071 6.58346 19.8071 4.92373 18.1473L3.86307 19.208ZM18.0052 5.06586C19.6649 6.72559 19.6649 9.41654 18.0052 11.0763L19.0659 12.1369C21.3114 9.89142 21.3114 6.25072 19.0659 4.0052L18.0052 5.06586ZM19.0659 4.0052C16.8203 1.75969 13.1797 1.75969 10.9341 4.0052L11.9948 5.06586C13.6545 3.40614 16.3455 3.40614 18.0052 5.06586L19.0659 4.0052Z"
      fill="currentColor"
    />
    <path
      d="M14.1161 10.0157C14.409 9.72282 14.409 9.24795 14.1161 8.95506C13.8232 8.66216 13.3483 8.66216 13.0555 8.95506L14.1161 10.0157ZM8.81281 13.1977C8.51992 13.4906 8.51992 13.9655 8.81281 14.2584C9.1057 14.5513 9.58058 14.5513 9.87347 14.2584L8.81281 13.1977ZM9.87347 14.2584L14.1161 10.0157L13.0555 8.95506L8.81281 13.1977L9.87347 14.2584Z"
      fill="currentColor"
    />
  </Icon>
);
