import { ENDPOINTS } from 'api/endpoints';
import axios from 'axios';

import { CustomerNicknameHistoryResponse } from '../Customers.types';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const fakedData: CustomerNicknameHistoryResponse[] = [
  {
    nickname: 'nickname 1',
    setAt: new Date().toLocaleString()
  },
  {
    nickname: 'nickname 2',
    setAt: new Date().toLocaleString()
  }
];

export const fetchCustomerNicknameHistory = async (
  id: string
): Promise<CustomerNicknameHistoryResponse[]> => {
  const { data } = await axios.get<CustomerNicknameHistoryResponse[]>(
    `${ENDPOINTS.NICKNAME_HISTORY}/${id}`
  );

  return data;
};
