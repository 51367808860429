import { Button, Wrap } from '@chakra-ui/react';
import { AdminDetailsPage } from 'enum';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  ADMIN_DETAILS_PAGE_LINK_BY_PAGE,
  getAdminDetailsPageNameByUrl
} from 'routes/routes.utils';

import {
  ADMIN_DETAILS_PAGE,
  ADMIN_DETAILS_PAGE_LIST
} from '../AdminDetails.utils';

export const AdminDetailsTabs = (): JSX.Element => {
  const [t] = useTranslation();
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const selectedTab = getAdminDetailsPageNameByUrl(pathname);

  return (
    <Wrap shouldWrapChildren spacing={3}>
      {ADMIN_DETAILS_PAGE_LIST.map((tab) => (
        <Button
          key={tab}
          variant="ghost"
          colorScheme="gray"
          aria-selected={tab === selectedTab}
          isDisabled={!ADMIN_DETAILS_PAGE[tab]}
          onClick={() => {
            navigate(ADMIN_DETAILS_PAGE_LINK_BY_PAGE[tab]);
          }}
        >
          {tab === AdminDetailsPage.Edit && t('keywords.details')}
          {tab === AdminDetailsPage.LoginSession && t('keywords.login_session')}
        </Button>
      ))}
    </Wrap>
  );
};
