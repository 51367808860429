import { CrmFiltersRequestData, ENDPOINTS } from 'api';
import { serializeBodyFilters } from 'api/api.utils';
import axios from 'axios';
import { GlobalFilters } from 'types';

export const fetchCrmDownloadCSV = async (
  body: CrmFiltersRequestData,
  globalFilters: GlobalFilters
): Promise<string> => {
  const { data } = await axios.post(ENDPOINTS.CRM_DOWNLOAD_CSV, {
    ...Object.fromEntries(
      Object.entries(body).filter(([, value]) => value !== undefined)
    ),
    ...serializeBodyFilters({ globalFilters })
  });

  return data;
};
