import { ChevronDownIcon, ChevronUpIcon } from '@chakra-ui/icons';
import { Box, Th, VStack } from '@chakra-ui/react';
import { flexRender, Header, RowData } from '@tanstack/react-table';
import { Fragment } from 'react';

type Props<TData> = {
  header: Header<TData, unknown>;
};

const CHEVRON_ICON = {
  asc: <ChevronUpIcon />,
  desc: <ChevronDownIcon />,
  default: (
    <Fragment>
      <ChevronUpIcon />
      <ChevronDownIcon />
    </Fragment>
  )
};

export const TableHeader = <TData extends RowData>({
  header
}: Props<TData>): JSX.Element => {
  const { column, isPlaceholder, getContext } = header;
  const { getCanSort, getToggleSortingHandler, getIsSorted, columnDef } =
    column;

  const canSort = getCanSort();
  const sorting = getIsSorted() || null;

  return (
    <Th
      data-cansort={canSort}
      onClick={getToggleSortingHandler()}
      {...columnDef.meta}
    >
      <Box display="inline-flex" alignItems="center">
        {isPlaceholder ? null : flexRender(columnDef.header, getContext())}
        {canSort && (
          <VStack display="inline-flex" spacing={0} ml={1} height="max-content">
            {CHEVRON_ICON[sorting || 'default']}
          </VStack>
        )}
      </Box>
    </Th>
  );
};
