import { useControllableState } from '@chakra-ui/react';
import { RowSelectionState } from '@tanstack/react-table';
import { ParameterValue } from 'api';
import { TableProps } from 'components';
import { useChakraToast, useDeleteParameterValue } from 'hooks';
import isEmpty from 'lodash/isEmpty';
import { useTranslation } from 'react-i18next';

const SEPARATOR = ':';

export const useMarkAndDeleteValues = (): {
  isDisabled: boolean;
  isLoading: boolean;
  onDeleteMany: () => void;
  tableProps: Partial<TableProps<ParameterValue>>;
} => {
  const [t] = useTranslation();
  const toast = useChakraToast();

  const [rowSelection, setRowSelection] =
    useControllableState<RowSelectionState>({
      defaultValue: {}
    });

  const { mutateAsync, isLoading } = useDeleteParameterValue();

  const isDisabled = isEmpty(rowSelection);

  const onDeleteMany = async () => {
    const mutations: Promise<unknown>[] = [];

    Object.keys(rowSelection).forEach((rowId) => {
      const [id] = rowId.split(SEPARATOR);
      mutations.push(
        mutateAsync({
          id
        })
      );
    });

    try {
      await Promise.all(mutations);
    } catch (e) {
      toast({
        status: 'error',
        title: t('errors.error_happened_while_update_report')
      });
    } finally {
      setRowSelection({});
    }
  };

  return {
    isDisabled,
    isLoading,
    onDeleteMany,
    tableProps: {
      tableOptions: {
        enableRowSelection: true,
        enableMultiRowSelection: true,
        getRowId: (row) => row.id
      },
      rowSelection,
      onRowSelectionChange: setRowSelection
    }
  };
};
