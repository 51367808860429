import { ENDPOINTS } from 'api/endpoints';
import axios from 'axios';
import { ActiveOrDeletedByAdminStatus } from 'types/customer';

export const fetchUpdateMessageImageStatus = ({
  imageId,
  status
}: {
  imageId: string;
  status: ActiveOrDeletedByAdminStatus;
}) =>
  axios.patch(`${ENDPOINTS.MESSAGE_IMAGES}/${imageId}/status`, {
    status
  });
