import { CellContext } from '@tanstack/react-table';
import { LogActivityResponse } from 'api';
import { NavigationLink } from 'components';
import { getBaseAdminLinkNavigation } from 'utils';

type Props = CellContext<LogActivityResponse, string>;

export const AdminNicknameCell = ({
  row: { original },
  table: {
    options: { meta }
  }
}: Props): JSX.Element => {
  const adminId = meta?.user?.id || '';

  return (
    <NavigationLink to={getBaseAdminLinkNavigation(adminId)}>
      {original.admin.nickname}
    </NavigationLink>
  );
};
