import {
  Access,
  AvailableRoute,
  ModalManager,
  PAGE_RESTRICTIONS,
  RequireAuth
} from 'containers';
import { AppLayout } from 'containers/Layout';
import { PageName } from 'enum';
import { PermissionAction, PermissionResource } from 'enum/api';
import {
  AdminDetails,
  AdminManagement,
  AdminPanel,
  Authorization,
  BlogManagement,
  Buttons,
  Checkboxes,
  ContactUs,
  Content,
  CreateArticle,
  CreateDomainConfig,
  CreatePopup,
  CRM,
  CustomerInfo,
  Customers,
  DomainConfigList,
  Examples,
  File,
  GeneralSettings,
  Inputs,
  LogActivity,
  Modal,
  Moderation,
  PaymentManagement,
  PopupsManagement,
  Reports,
  Selector,
  StaticPages,
  TableManagement,
  Tables
} from 'pages';
import { Navigate, RouteObject } from 'react-router-dom';

import { ADMIN_DETAILS_ROUTES } from './adminManagemntRoutes';
import { BLOG_ROUTES } from './blogRoutes';
import { CONTENT_ROUTES } from './contentRoutes';
import { CUSTOMER_ROUTES } from './customerRoutes';
import { MODERATION_ROUTES } from './moderationRoutes';
import { PAYMENT_ROUTES } from './paymentRoutes';
import { REPORT_ROUTES } from './reportRotes';
import { SIDE_NAV_LINK_BY_PAGE } from './routes.utils';
import { TABLE_MANAGEMENT_ROUTES } from './tableManagementRotes';

const getPath = (page: PageName) => SIDE_NAV_LINK_BY_PAGE[page];

export const ROUTES: RouteObject[] = [
  {
    path: '/',
    element: (
      <RequireAuth>
        <AppLayout />
        <AvailableRoute />
        <ModalManager />
      </RequireAuth>
    ),
    children: [
      {
        path: getPath(PageName.AdminPanel),
        index: true,
        element: <AdminPanel />
      },
      {
        path: getPath(PageName.AdminManagement),
        children: [
          {
            index: true,
            element: (
              <Access
                restrictions={PAGE_RESTRICTIONS[PageName.AdminManagement]}
              >
                <AdminManagement />
              </Access>
            )
          },
          {
            path: ':adminId',
            element: (
              <Access
                restrictions={{
                  actions: PermissionAction.Update,
                  resource: PermissionResource.Admins
                }}
              >
                <AdminDetails />
              </Access>
            ),
            children: ADMIN_DETAILS_ROUTES
          }
        ]
      },
      {
        path: getPath(PageName.Customers),
        children: [
          {
            index: true,
            element: (
              <Access restrictions={PAGE_RESTRICTIONS[PageName.Customers]}>
                <Customers />
              </Access>
            )
          },
          {
            path: ':customerId',
            element: (
              <Access restrictions={PAGE_RESTRICTIONS[PageName.Customers]}>
                <CustomerInfo />
              </Access>
            ),
            children: [
              ...CUSTOMER_ROUTES,
              {
                index: true,
                element: <Navigate to={CUSTOMER_ROUTES[0].path || ''} replace />
              }
            ]
          }
        ]
      },
      {
        path: getPath(PageName.Settings),
        element: (
          <Access restrictions={PAGE_RESTRICTIONS[PageName.Settings]}>
            <GeneralSettings />
          </Access>
        )
      },
      {
        path: getPath(PageName.LogActivity),
        element: (
          <Access restrictions={PAGE_RESTRICTIONS[PageName.LogActivity]}>
            <LogActivity />
          </Access>
        )
      },
      {
        path: getPath(PageName.PopupsManagement),
        children: [
          {
            index: true,
            element: (
              <Access
                restrictions={PAGE_RESTRICTIONS[PageName.PopupsManagement]}
              >
                <PopupsManagement />
              </Access>
            )
          },
          {
            path: 'create',
            element: (
              <Access
                restrictions={{
                  resource: PermissionResource.Popups,
                  actions: PermissionAction.Create
                }}
              >
                <CreatePopup />
              </Access>
            )
          }
        ]
      },
      {
        path: getPath(PageName.Moderation),
        element: (
          <Access restrictions={PAGE_RESTRICTIONS[PageName.Moderation]}>
            <Moderation />
          </Access>
        ),
        children: MODERATION_ROUTES
      },
      {
        path: getPath(PageName.Reports),
        element: (
          <Access restrictions={PAGE_RESTRICTIONS[PageName.Reports]}>
            <Reports />
          </Access>
        ),
        children: REPORT_ROUTES
      },
      {
        path: getPath(PageName.ContactUs),
        element: (
          <Access restrictions={PAGE_RESTRICTIONS[PageName.ContactUs]}>
            <ContactUs />
          </Access>
        )
      },
      {
        path: getPath(PageName.PaymentsManagement),
        element: (
          <Access restrictions={PAGE_RESTRICTIONS[PageName.PaymentsManagement]}>
            <PaymentManagement />
          </Access>
        ),
        children: PAYMENT_ROUTES
      },
      {
        path: getPath(PageName.BlogManagement),
        children: [
          {
            element: (
              <Access restrictions={PAGE_RESTRICTIONS[PageName.BlogManagement]}>
                <BlogManagement />
              </Access>
            ),
            children: BLOG_ROUTES
          },
          {
            path: 'create',
            element: (
              <Access
                restrictions={{
                  resource: PermissionResource.Articles,
                  actions: PermissionAction.Create
                }}
              >
                <CreateArticle />
              </Access>
            )
          }
        ]
      },
      {
        path: getPath(PageName.DomainsConfig),
        children: [
          {
            index: true,
            element: (
              <Access restrictions={PAGE_RESTRICTIONS[PageName.DomainsConfig]}>
                <DomainConfigList />
              </Access>
            )
          },
          {
            path: 'create',
            element: (
              <Access
                restrictions={{
                  resource: PermissionResource.DomainConfigs,
                  actions: PermissionAction.Create
                }}
              >
                <CreateDomainConfig />
              </Access>
            )
          }
        ]
      },
      {
        path: getPath(PageName.Content),
        children: [
          {
            element: (
              <Access restrictions={PAGE_RESTRICTIONS[PageName.Content]}>
                <Content />
              </Access>
            ),
            children: CONTENT_ROUTES
          }
        ]
      },
      {
        path: getPath(PageName.CRM),
        element: (
          <Access
            restrictions={{
              resource: PermissionResource.CRM,
              actions: PermissionAction.Read
            }}
          >
            <CRM />
          </Access>
        )
      },
      {
        path: getPath(PageName.TablesManagement),
        children: [
          {
            element: (
              <Access
                restrictions={PAGE_RESTRICTIONS[PageName.TablesManagement]}
              >
                <TableManagement />
              </Access>
            ),
            children: TABLE_MANAGEMENT_ROUTES
          }
        ]
      },
      {
        path: getPath(PageName.StaticPages),
        children: [
          {
            index: true,
            element: (
              <Access restrictions={PAGE_RESTRICTIONS[PageName.StaticPages]}>
                <StaticPages />
              </Access>
            )
          }
        ]
      },
      {
        path: '*',
        element: <Navigate to="/" replace />
      }
    ]
  },
  {
    path: getPath(PageName.Login),
    element: <Authorization />
  },
  {
    path: getPath(PageName.Examples),
    element: <Examples />,
    children: [
      {
        path: 'buttons',
        element: <Buttons />
      },
      {
        path: 'inputs',
        element: <Inputs />
      },
      {
        path: 'checkboxes',
        element: <Checkboxes />
      },
      {
        path: 'modal',
        element: <Modal />
      },
      {
        path: 'table',
        element: <Tables />
      },
      {
        path: 'file',
        element: <File />
      },
      {
        path: 'dropdown',
        element: <Selector />
      }
    ]
  },
  {
    path: '*',
    element: <Navigate to="/" replace />
  }
];
