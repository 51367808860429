import { ChevronLeftIcon, ChevronRightIcon } from '@chakra-ui/icons';
import { IconButton } from '@chakra-ui/react';
import { Direction } from 'enum';

type Props = {
  direction: Direction.Left | Direction.Right;
  isDisabled: boolean;
  onClick: () => void;
};

export const ArrowIcon = ({
  direction,
  isDisabled,
  onClick
}: Props): JSX.Element => (
  <IconButton
    key={direction}
    icon={
      direction === Direction.Left ? <ChevronLeftIcon /> : <ChevronRightIcon />
    }
    variant="ghost"
    colorScheme="gray"
    size="xs"
    fontSize="md"
    aria-label={`Pagination ${direction} arrow`}
    disabled={isDisabled}
    onClick={onClick}
  />
);
