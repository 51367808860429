import { ENDPOINTS } from 'api/endpoints';
import axios from 'axios';

import { CreateStaticPageRequest } from '../../pages/StaticPages/types';

export const fetchCreateStaticPage = async (
  data: CreateStaticPageRequest
): Promise<void> => {
  await axios.post(ENDPOINTS.STATIC_PAGES, data);
};
