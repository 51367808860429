import { CustomerGalleryImagesResponse, ENDPOINTS } from 'api';
import axios from 'axios';
import { ImageStatus } from 'enum/api';
import { ICustomerGalleryImages } from 'types/customer';

const photoUrl =
  'https://images.pexels.com/photos/220453/pexels-photo-220453.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const fakedData: ICustomerGalleryImages[] = [
  {
    id: 'id_1',
    uploadedAt: new Date().toLocaleString(),
    statusedAt: new Date().toLocaleString(),
    imageUrl: photoUrl,
    main: false,
    status: ImageStatus.Active
  }
];

export const fetchCustomerGalleryImages = async (
  customerId: string
): Promise<ICustomerGalleryImages[]> => {
  const { data } = await axios.get<CustomerGalleryImagesResponse[]>(
    `${ENDPOINTS.GALLERY_IMAGES}/by-user/${customerId}`
  );

  return data;
};
