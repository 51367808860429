import { ChevronDownIcon } from '@chakra-ui/icons';
import {
  Button,
  Icon,
  Menu,
  MenuButton,
  MenuItem,
  MenuList
} from '@chakra-ui/react';
import { i18n } from 'i18n';
import * as localeCodes from 'locale-codes';

const getLabel = (locale: string) =>
  localeCodes.getByTag(locale)['iso639-2']?.toUpperCase();

export const LocaleButton = (): JSX.Element => {
  const language = 'en';

  return (
    <Menu>
      <MenuButton
        as={Button}
        colorScheme="black"
        variant="locale"
        size="md"
        fontWeight="normal"
        rightIcon={<Icon as={ChevronDownIcon} />}
      >
        {getLabel(language)}
      </MenuButton>
      <MenuList minWidth="6.25rem">
        {i18n.languages.map((locale) => (
          <MenuItem key={locale} value={locale}>
            {getLabel(locale)}
          </MenuItem>
        ))}
      </MenuList>
    </Menu>
  );
};
