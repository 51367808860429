import { Icon, IconProps } from '@chakra-ui/react';

export const FanDeckIcon = (props: IconProps): JSX.Element => (
  <Icon viewBox="0 0 24 24" fill="none" {...props}>
    <rect
      x="2"
      y="2"
      width="10"
      height="20"
      rx="3"
      stroke="currentColor"
      strokeWidth="1.5"
    />
    <path
      d="M12.1421 5L13.0208 4.12132C14.1923 2.94975 16.0918 2.94975 17.2634 4.12132L20.0918 6.94975C21.2634 8.12132 21.2634 10.0208 20.0918 11.1924L12.1421 19.1421"
      stroke="currentColor"
      strokeWidth="1.5"
    />
    <path
      d="M19 12V12C20.6569 12 22 13.3431 22 15L22 19C22 20.6569 20.6569 22 19 22L7 22"
      stroke="currentColor"
      strokeWidth="1.5"
    />
    <circle cx="7" cy="18" r="1" fill="currentColor" />
  </Icon>
);
