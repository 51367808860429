import { Icon, IconProps } from '@chakra-ui/react';

export const ChevronRightIcon = (props: IconProps): JSX.Element => (
  <Icon viewBox="0 0 24 24" fill="none" {...props}>
    <path
      d="M9.5 5L16.5711 12.0711L9.5 19.1421"
      stroke="#ACADB9"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </Icon>
);
