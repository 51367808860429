import { FormField } from 'enum';
import { ParameterType } from 'enum/api';
import identity from 'lodash/identity';
import isNil from 'lodash/isNil';
import omitBy from 'lodash/omitBy';
import pickBy from 'lodash/pickBy';

import { CustomerFullFormData, UpdateCustomerRequest } from './Customers.types';

export const serializeCustomerRequestData = (
  formData: CustomerFullFormData
): Partial<UpdateCustomerRequest> => {
  const { start, end } = formData[FormField.Ages] || {};

  const data: {
    [K in keyof UpdateCustomerRequest]:
      | UpdateCustomerRequest[K]
      | undefined
      | null;
  } = {
    email: formData[FormField.Email],
    nickname: formData[FormField.UserName],
    password: formData[FormField.Password],
    country: formData[FormField.Country],
    registeredCountry: formData[FormField.RegisteredCountry],
    state: formData[FormField.State],
    city: formData[FormField.City],
    latitude: formData[FormField.Latitude]?.toString(),
    longitude: formData[FormField.Longitude]?.toString(),
    phoneNumber: formData[FormField.PhoneNumber],
    gender: formData[FormField.Gender],
    lookFor: formData[FormField.LookingForGender],
    lookForAgeMin: start ? Number(start) : undefined,
    lookForAgeMax: end ? Number(end) : undefined,
    birthDate: formData[FormField.BirthDate],
    labels: formData[FormField.Label],
    labelsComment: formData[FormField.Comment],
    type: formData[FormField.CustomerType],
    height: Number(formData[FormField.Height]),
    motto: formData[FormField.Motto],
    aboutMe: formData[FormField.AboutMe],
    [ParameterType.DrinkingType]: formData[FormField.DrinkingType],
    [ParameterType.EyeColor]: formData[FormField.EyeColor],
    [ParameterType.SmokingType]: formData[FormField.SmokingType],
    [ParameterType.BodyType]: formData[FormField.BodyType],
    [ParameterType.FamilyStatus]: formData[FormField.FamilyStatus],
    [ParameterType.HairColor]: formData[FormField.HairColor],
    [ParameterType.ChildType]: formData[FormField.ChildType],
    [ParameterType.EthnicityType]: formData[FormField.EthnicityType],
    [ParameterType.ReligionType]: formData[FormField.ReligionType],
    relationshipSettings: formData[FormField.RelationshipSettings],
    relationshipTarget: formData[FormField.RelationshipTarget],
    [ParameterType.BusinessType]: formData[FormField.BusinessType],
    [ParameterType.IncomeType]: formData[FormField.IncomeType],
    [ParameterType.AvailabilityType]: formData[FormField.AvailabilityType],
    [ParameterType.Interest]: formData[FormField.Interest],
    [ParameterType.Language]: formData[FormField.Language],
    notificationPreferences: formData[FormField.NotificationPreferences],
    status: formData[FormField.Status],
    suspicious: formData[FormField.Suspicious],
    enabledForceOnline: formData[FormField.ForceOnline],
    isBotUser: formData[FormField.IsBotUser]
  };

  return omitBy(
    {
      ...pickBy(data, identity),
      suspicious: data.suspicious,
      enabledForceOnline: data.enabledForceOnline,
      isBotUser: data.isBotUser,
      motto: formData[FormField.Motto],
      aboutMe: formData[FormField.AboutMe]
    },
    isNil
  );
};
