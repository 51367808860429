import {
  Box,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Heading,
  HStack,
  Input,
  InputGroup,
  VStack
} from '@chakra-ui/react';
import { Password } from 'components';
import { Dropdown } from 'components/Dropdown';
import { Search } from 'components/Search';
import { DropdownType } from 'enum';

export const Inputs = (): JSX.Element => (
  <HStack spacing={3} align="flex-start">
    <VStack
      w="400px"
      ml="20px"
      spacing={3}
      align="flex-start"
      alignItems="stretch"
    >
      <Box>
        <Heading as="h3" size="md" mt="3">
          Base input
        </Heading>
        <FormLabel>Email</FormLabel>
        <Input placeholder="Email" />
      </Box>
      <Box>
        <Heading as="h3" size="md" mt="3">
          Disabled input
        </Heading>
        <FormLabel>Email</FormLabel>
        <Input placeholder="Email" isDisabled value="Some text" size="md" />
      </Box>
      <Box>
        <Heading as="h3" size="md" mt="3">
          Password
        </Heading>
        <Password label="Password" placeholder="Password" defaultValue="123" />
      </Box>
      <Box>
        <FormControl isInvalid isRequired>
          <Heading as="h3" size="md" mt="3">
            Invalid input
          </Heading>
          <FormLabel>Email</FormLabel>
          <Input placeholder="Email" />
          <FormErrorMessage>
            <Box color="red.500" mr={1}>
              Error:
            </Box>
            Email is required.
          </FormErrorMessage>
        </FormControl>
      </Box>
      <Box>
        <Heading as="h3" size="md" mt="3">
          Success input
        </Heading>
        <FormLabel>Email</FormLabel>
        <Input placeholder="Email" colorScheme="success" />
      </Box>
    </VStack>
    <VStack
      w="400px"
      ml="20px"
      spacing={3}
      align="flex-start"
      alignItems="stretch"
    >
      <Box>
        <Heading as="h3" size="md" mt="3">
          Date input
        </Heading>
        <FormLabel>Email</FormLabel>
        <InputGroup>
          <Input placeholder="Date" type="date" />
        </InputGroup>
      </Box>
      <Box>
        <Heading as="h3" size="md" mt="3">
          Dropdown Range
        </Heading>
        <FormLabel>Ages</FormLabel>
        <Dropdown type={DropdownType.Range} />
      </Box>
      <Box>
        <Heading as="h3" size="md" mt="3">
          Search
        </Heading>
        <Search
          options={[
            {
              value: 'type',
              label: 'Type'
            }
          ]}
          onChange={() => {}}
        />
      </Box>
    </VStack>
  </HStack>
);
