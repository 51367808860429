import { ModalContainer } from 'containers';
import { ModalType } from 'enum';
import { useDeleteAdmin, useModalActions } from 'hooks';
import { useTranslation } from 'react-i18next';
import { ParamType } from 'types';

export const DeleteAdmin = (): JSX.Element => {
  const [t] = useTranslation();

  const { isOpen, onClose, meta } = useModalActions(ModalType.DeleteAdminUser);

  const { mutate } = useDeleteAdmin();

  const actions: ParamType<typeof ModalContainer, 'footerButtons'> = [
    {
      text: t('actions.cancel'),
      variant: 'ghostGray'
    },
    {
      text: t('actions.delete_user'),
      colorScheme: 'secondary',
      isPreventClose: true,
      onClick: (onClose) => {
        if (meta?.userId) {
          mutate(meta.userId || '');
        }

        onClose();
      }
    }
  ];

  return (
    <ModalContainer
      header={t('actions.delete_user')}
      footerButtons={actions}
      isOpen={isOpen}
      onClose={onClose}
    >
      Are you sure you want to delete this user?
    </ModalContainer>
  );
};
