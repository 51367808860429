import { GridItem } from '@chakra-ui/react';
import { FormElement } from 'containers';
import { InputElement } from 'enum';
import { Fragment } from 'react';
import { useTranslation } from 'react-i18next';

import { GeneralSettingsFormField } from '../FormContent.types';

export const SeeAllUsersWithFreePlan = (): JSX.Element => {
  const [t] = useTranslation();

  return (
    <Fragment>
      <GridItem>
        {t(
          'attribute.description.see_all_users_in_search_if_customer_has_free_plan'
        )}
      </GridItem>
      <GridItem>
        <FormElement
          type={InputElement.Toggle}
          name={GeneralSettingsFormField.SeeAllUsersWithFreePlan}
        />
      </GridItem>
    </Fragment>
  );
};
