import { Fragment } from 'react';

import { MODALS } from './ModalManager.utils';

export const ModalManager = (): JSX.Element => (
  <Fragment>
    {Object.entries(MODALS).map(([key, Modal]) => (
      <Modal key={key} />
    ))}
  </Fragment>
);
