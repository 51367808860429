import {
  AdminDetailsPage,
  BlogSection,
  ContentSection,
  CustomerPage,
  CustomerSection,
  ModerationPage,
  ModerationSection,
  PageName,
  PaymentPage,
  ReportPage,
  ReportSection,
  TableManagementPage
} from 'enum';
import { getAllEnumValues } from 'enum-for';
import invert from 'lodash/invert';
import kebabCase from 'lodash/kebabCase';

const DEFAULT_ADMIN_DETAILS_PAGE = AdminDetailsPage.Edit;
const DEFAULT_CUSTOMER_PAGE = CustomerPage.Dashboard;
const DEFAULT_MODERATION_PAGE = ModerationPage.GalleryPhoto;
const DEFAULT_REPORT_PAGE = ReportPage.Customer;

/*
  Object of type
  {
    [PageName.Page]: 'page'
  }
*/
export const SIDE_NAV_LINK_BY_PAGE = getAllEnumValues(PageName).reduce(
  (acc, cur) => {
    acc[cur] = cur === PageName.AdminPanel ? '/' : kebabCase(cur);

    return acc;
  },
  {} as Record<PageName, string>
);

export const SIDE_NAV_PAGE_BY_LINK = invert(SIDE_NAV_LINK_BY_PAGE) as Record<
  string,
  PageName
>;

export const ADMIN_DETAILS_PAGE_LINK_BY_PAGE = getAllEnumValues(
  AdminDetailsPage
).reduce((acc, page) => {
  acc[page] = kebabCase(page);
  return acc;
}, {} as Record<AdminDetailsPage, string>);

export const ADMIN_DETAILS_PAGE_BY_LINK = invert(
  ADMIN_DETAILS_PAGE_LINK_BY_PAGE
) as Record<string, AdminDetailsPage>;

export const getAdminDetailsPageNameByUrl = (
  pathname: string
): AdminDetailsPage => {
  const page = pathname.split('/')[3];

  if (!page) return DEFAULT_ADMIN_DETAILS_PAGE;

  return ADMIN_DETAILS_PAGE_BY_LINK[page] || DEFAULT_ADMIN_DETAILS_PAGE;
};

export const CUSTOMER_PAGE_LINK_BY_PAGE = getAllEnumValues(CustomerPage).reduce(
  (acc, page) => {
    acc[page] = kebabCase(page);
    return acc;
  },
  {} as Record<CustomerPage, string>
);

export const CUSTOMER_PAGE_BY_LINK = invert(
  CUSTOMER_PAGE_LINK_BY_PAGE
) as Record<string, CustomerPage>;

export const CUSTOMER_PAGE_SECTION_LINK_BY_SECTION = getAllEnumValues(
  CustomerSection
).reduce((acc, page) => {
  acc[page] = kebabCase(page);
  return acc;
}, {} as Record<CustomerSection, string>);

export const CUSTOMER_PAGE_SECTION_BY_LINK = invert(
  CUSTOMER_PAGE_SECTION_LINK_BY_SECTION
) as Record<string, CustomerSection>;

export const getCustomerPageNameByUrl = (pathname: string): CustomerPage => {
  const page = pathname.split('/')[3];

  if (!page) return DEFAULT_CUSTOMER_PAGE;

  return CUSTOMER_PAGE_BY_LINK[page] || DEFAULT_CUSTOMER_PAGE;
};

export const getCustomerSectionByUrl = (
  pathname: string
): CustomerSection | null => {
  const section = pathname.split('/')[4];

  if (!section) return null;

  return CUSTOMER_PAGE_SECTION_BY_LINK[section] || null;
};

export const CONTENT_PAGE_SECTION_LINK_BY_SECTION = getAllEnumValues(
  ContentSection
).reduce((acc, page) => {
  acc[page] = kebabCase(page);
  return acc;
}, {} as Record<ContentSection, string>);

export const CONTENT_PAGE_SECTION_BY_LINK = invert(
  CONTENT_PAGE_SECTION_LINK_BY_SECTION
) as Record<string, ContentSection>;

export const getContentSectionByUrl = (
  pathname: string
): ContentSection | null => {
  const section = pathname.split('/')[2];

  if (!section) return null;

  return CONTENT_PAGE_SECTION_BY_LINK[section] || null;
};

/**
 *
 * @param {string} pathname  current pathname from location object
 * @param {CustomerSection} newSection  new customer section
 * @returns {string} new pathname with replaced section
 *
 * Example:
 *
 * pathname = `/customers/:customerId/admin-interaction/admin-comments`
 * newSection = `linkedAccounts`
 *
 * result `/customers/:customerId/admin-interaction/linked-accounts`
 *
 */
export const replaceCustomerSectionUrl = (
  pathname: string,
  newSection: CustomerSection
) => {
  const newPath = pathname.split('/');
  newPath[4] = kebabCase(newSection);
  return newPath.join('/');
};

export const MODERATION_PAGE_LINK_BY_PAGE = getAllEnumValues(
  ModerationPage
).reduce((acc, page) => {
  acc[page] = kebabCase(page);
  return acc;
}, {} as Record<ModerationPage, string>);

export const MODERATION_PAGE_BY_LINK = invert(
  MODERATION_PAGE_LINK_BY_PAGE
) as Record<string, ModerationPage>;

export const MODERATION_PAGE_SECTION_LINK_BY_SECTION = getAllEnumValues(
  ModerationSection
).reduce((acc, page) => {
  acc[page] = kebabCase(page);
  return acc;
}, {} as Record<ModerationSection, string>);

export const MODERATION_PAGE_SECTION_BY_LINK = invert(
  MODERATION_PAGE_SECTION_LINK_BY_SECTION
) as Record<string, ModerationSection>;

export const getModerationPageNameByUrl = (
  pathname: string
): ModerationPage => {
  const page = pathname.split('/')[2];

  if (!page) return DEFAULT_MODERATION_PAGE;

  return MODERATION_PAGE_BY_LINK[page] || DEFAULT_MODERATION_PAGE;
};

export const getModerationSectionByUrl = (
  pathname: string
): ModerationSection | null => {
  const section = pathname.split('/')[3];

  if (!section) return null;

  return MODERATION_PAGE_SECTION_BY_LINK[section] || null;
};

export const PAYMENT_PAGE_LINK_BY_PAGE = getAllEnumValues(PaymentPage).reduce(
  (acc, page) => {
    acc[page] = kebabCase(page);
    return acc;
  },
  {} as Record<PaymentPage, string>
);

export const PAYMENT_PAGE_BY_LINK = invert(PAYMENT_PAGE_LINK_BY_PAGE) as Record<
  string,
  PaymentPage
>;

export const getPaymentPageNameByUrl = (
  pathname: string
): PaymentPage | null => {
  const page = pathname.split('/')[2];

  if (!page) return null;

  return PAYMENT_PAGE_BY_LINK[page] || null;
};

export const REPORT_PAGE_LINK_BY_PAGE = getAllEnumValues(ReportPage).reduce(
  (acc, page) => {
    acc[page] = kebabCase(page);
    return acc;
  },
  {} as Record<ReportPage, string>
);

export const REPORT_PAGE_BY_LINK = invert(REPORT_PAGE_LINK_BY_PAGE) as Record<
  string,
  ReportPage
>;

export const getReportPageNameByUrl = (pathname: string): ReportPage => {
  const page = pathname.split('/')[2];

  if (!page) return DEFAULT_REPORT_PAGE;

  return REPORT_PAGE_BY_LINK[page] || DEFAULT_REPORT_PAGE;
};

export const REPORT_PAGE_SECTION_LINK_BY_SECTION = getAllEnumValues(
  ReportSection
).reduce((acc, page) => {
  acc[page] = kebabCase(page);
  return acc;
}, {} as Record<ReportSection, string>);

export const REPORT_PAGE_SECTION_BY_LINK = invert(
  REPORT_PAGE_SECTION_LINK_BY_SECTION
) as Record<string, ReportSection>;

export const getReportSectionByUrl = (
  pathname: string
): ReportSection | null => {
  const section = pathname.split('/')[3];

  if (!section) return null;

  return REPORT_PAGE_SECTION_BY_LINK[section] || null;
};

export const BLOG_PAGE_SECTION_LINK_BY_SECTION = getAllEnumValues(
  BlogSection
).reduce((acc, page) => {
  acc[page] = kebabCase(page);
  return acc;
}, {} as Record<BlogSection, string>);

export const BLOG_PAGE_SECTION_BY_LINK = invert(
  BLOG_PAGE_SECTION_LINK_BY_SECTION
) as Record<string, BlogSection>;

export const getBlogSectionByUrl = (pathname: string): BlogSection | null => {
  const section = pathname.split('/')[2];

  if (!section) return null;

  return BLOG_PAGE_SECTION_BY_LINK[section] || null;
};

export const TABLE_MANAGEMENT_PAGE_LINK_BY_PAGE = getAllEnumValues(
  TableManagementPage
).reduce((acc, page) => {
  acc[page] = kebabCase(page);
  return acc;
}, {} as Record<TableManagementPage, string>);

export const TABLE_MANAGEMENT_PAGE_BY_LINK = invert(
  TABLE_MANAGEMENT_PAGE_LINK_BY_PAGE
) as Record<string, TableManagementPage>;

export const getTableManagementPageNameByUrl = (
  pathname: string
): TableManagementPage | null => {
  const page = pathname.split('/')[2];

  if (!page || !TABLE_MANAGEMENT_PAGE_BY_LINK[page]) {
    return null;
  }

  return TABLE_MANAGEMENT_PAGE_BY_LINK[page];
};
