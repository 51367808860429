import { GridItem, Spinner } from '@chakra-ui/react';
import { AddButton, Table, TableProps } from 'components';
import { TableFilters } from 'containers';
import { AccountStatus, ModalType } from 'enum';
import {
  useAppDispatch,
  useCustomerIdFromUrl,
  useCustomerLinkedAccounts,
  useTableQueryController
} from 'hooks';
import { Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import { openModal } from 'store/modal';

import {
  linkedAccountsColumns,
  linkedAccountTableFilters
} from '../AdminInteraction.utils';

export const LinkedAccounts = (): JSX.Element => {
  const [t] = useTranslation();
  const dispatch = useAppDispatch();

  const {
    queryFilters,
    action: { onChangeSort, onChangePagination, onChangeFilters }
  } = useTableQueryController();

  const customerId = useCustomerIdFromUrl();

  const { data } = useCustomerLinkedAccounts(customerId, queryFilters);

  const handleLinkAccount = () =>
    dispatch(
      openModal({
        id: ModalType.AddLinkedAccount,
        meta: {
          customerId
        }
      })
    );

  if (!data) return <Spinner />;

  const getHighlightColor: TableProps<
    typeof data.data[number]
  >['getHighlightColor'] = ({ original }) => {
    switch (original.accountStatus) {
      case AccountStatus.Deactivated:
        return 'gray.200';
      case AccountStatus.Deleted:
        return 'red.100';
      default:
        return '';
    }
  };

  return (
    <Fragment>
      <GridItem area="filters">
        <TableFilters
          filters={linkedAccountTableFilters}
          onChange={onChangeFilters}
        />
      </GridItem>
      <GridItem area="actions" justifySelf="flex-end">
        <AddButton onClick={handleLinkAccount}>
          {t('actions.link_account')}
        </AddButton>
      </GridItem>
      <GridItem area="table" overflow="hidden">
        <Table
          data={data.data}
          columns={linkedAccountsColumns}
          onChangeSort={onChangeSort}
          onChangePagination={onChangePagination}
          totalItems={data.totalItems}
          pagination={queryFilters.pagination}
          getHighlightColor={getHighlightColor}
        />
      </GridItem>
    </Fragment>
  );
};
