import { ENDPOINTS } from 'api';
import axios, { AxiosError, AxiosRequestConfig } from 'axios';
import { PageName } from 'enum';
import Cookies from 'js-cookie';
import { SIDE_NAV_LINK_BY_PAGE } from 'routes/routes.utils';
import { AxiosErrorData } from 'types';
import { TOKEN } from 'utils';

export const initAxios = () => {
  axios.defaults.baseURL = process.env.REACT_APP_API_URL;
  axios.defaults.headers.common['Content-Type'] = 'application/json';
  axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';
  axios.defaults.headers.common['Accept'] = '*/*';
};

const setAuthorizationToken = (config: AxiosRequestConfig, token?: string) => {
  const auth = token ? `Bearer ${token}` : '';

  if (config.headers) {
    config.headers.Authorization = auth;
  } else {
    config.headers = {
      Authorization: auth
    };
  }
};

axios.interceptors.request.use((config) => {
  const token = Cookies.get(TOKEN);
  setAuthorizationToken(config, token);

  return config;
});

axios.interceptors.response.use(
  (response) => response,
  (error: AxiosError<AxiosErrorData>) => {
    if (
      error &&
      error.response &&
      error.response.status === 401 &&
      error.response.config.url !== ENDPOINTS.LOGIN
    ) {
      window.location.href = `/${SIDE_NAV_LINK_BY_PAGE[PageName.Login]}`;
    }

    return Promise.reject(error);
  }
);
