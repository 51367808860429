import { Button } from '@chakra-ui/react';
import { ModalType } from 'enum';
import { useAppDispatch } from 'hooks';
import { useTranslation } from 'react-i18next';
import { openModal } from 'store/modal';

type Props = {
  labels: string[];
  customerId: string;
};

export const EditLabelsButton = ({
  labels,
  customerId
}: Props): JSX.Element => {
  const [t] = useTranslation();
  const dispatch = useAppDispatch();

  return (
    <Button
      variant="link"
      onClick={() =>
        dispatch(
          openModal({
            id: ModalType.EditLabels,
            meta: {
              labels,
              customerId
            }
          })
        )
      }
    >
      {t('actions.edit_labels')}
    </Button>
  );
};
