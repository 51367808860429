import { useQuery } from '@tanstack/react-query';
import { fetchCustomerReportsByUser } from 'api';
import { QueryKey } from 'enum/api';
import { QueryFilters } from 'types';

export const useCustomerReportsByUser = ({
  customerId,
  queryFilters
}: {
  customerId: string;
  queryFilters: QueryFilters;
}) =>
  useQuery(
    [QueryKey.CustomerReports, customerId, queryFilters],
    () => fetchCustomerReportsByUser({ customerId, queryFilters }),
    {
      retry: false,
      refetchOnWindowFocus: false,
      keepPreviousData: true
    }
  );
