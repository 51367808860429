import { ENDPOINTS } from 'api';
import axios from 'axios';
import { ICustomerBadWords } from 'types/customer';

import { CustomerBadWordsResponse } from './CustomerBadWords.types';

export const fetchAllCustomerBadWordsList = async ({
  customerId
}: {
  customerId: string;
}): Promise<ICustomerBadWords> => {
  const { data } = await axios.get<CustomerBadWordsResponse>(
    `${ENDPOINTS.BAD_WORDS}/by-user/${customerId}`
  );

  return data;
};
