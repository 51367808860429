import { TableManagementPage } from 'enum';
import { getAllEnumValues } from 'enum-for';
import {
  ParameterOptionActivityStatus,
  ParameterOptionCountryRestrictionType
} from 'enum/api';
import { FC } from 'react';
import { TFunction } from 'react-i18next';

import { ParameterOptions, ParameterValues } from './Pages';

export const TABLE_MANAGEMENT_PAGE_LIST = getAllEnumValues(TableManagementPage);

export const TABLE_MANAGEMENT_PAGE: Record<
  TableManagementPage,
  {
    content: FC;
  }
> = {
  [TableManagementPage.ParameterOptions]: {
    content: ParameterOptions
  },
  [TableManagementPage.ParameterValues]: {
    content: ParameterValues
  }
};

export const getTableManagementPageLabel = (
  page: TableManagementPage,
  t: TFunction
): string => {
  if (page === TableManagementPage.ParameterOptions) {
    return t(`keywords.parameter_options`);
  }

  return t(`keywords.parameter_values`);
};

export const getTagColorSchemeByStatus = (
  status: ParameterOptionActivityStatus
) => {
  switch (status) {
    case ParameterOptionActivityStatus.Activated:
      return 'status.green';
    case ParameterOptionActivityStatus.NotActivated:
    default:
      return 'status.gray';
  }
};

export const getTagColorSchemeByRestrictionType = (
  status: ParameterOptionCountryRestrictionType,
  isSelected: boolean
) => {
  switch (status) {
    case ParameterOptionCountryRestrictionType.Allowed:
      return 'status.green';
    case ParameterOptionCountryRestrictionType.Disallowed: {
      if (isSelected) {
        return 'status.red';
      } else {
        return 'status.gray';
      }
    }
    default:
      return 'status.gray';
  }
};
