import { createMultiStyleConfigHelpers } from '@chakra-ui/styled-system';

import { parts } from './parts';

const { definePartsStyle } = createMultiStyleConfigHelpers(parts);

export const baseStyle = definePartsStyle({
  rootContainer: {
    position: 'relative',
    _disabled: {
      pointerEvents: 'none'
    },
    width: '100%'
  },
  control: {
    display: 'flex',
    cursor: 'pointer',
    overflow: 'hidden',
    fontWeight: 'normal',
    height: 'auto',
    minH: '30px',
    borderRadius: 'md',
    p: 0
  },
  indicatorsContainer: {
    display: 'flex',
    maxH: '28px',
    alignItems: 'center',
    alignSelf: 'stretch',
    flexShrink: 0
  },
  indicatorSeparator: {
    display: 'none'
  },
  dropdownIndicator: {
    svg: {
      boxSize: '1.5em'
    },
    px: 3,
    color: 'gray.500'
  },
  valueContainer: {
    position: 'relative',
    overflow: 'hidden',
    display: 'grid',
    alignItems: 'center',
    flex: 1,
    paddingX: 3,
    flexWrap: 'wrap',
    WebkitOverflowScrolling: 'touch',
    '&[data-has-multi]': {
      display: 'flex',
      paddingStart: 0
    }
  },
  placeholder: {
    gridArea: '1 / 1 / 2 / 3',
    color: 'gray.500'
  },
  noOptionsMessage: {
    color: 'disabled'
  },
  menu: {
    borderRadius: 'md',
    background: 'white',
    border: '1px',
    borderColor: 'gray.600',
    overflow: 'hidden'
  },
  menuList: {
    color: 'inherit',
    width: '100%',
    maxHeight: 60,
    borderColor: 'red',
    height: 'auto',
    overflowY: 'auto',
    position: 'relative',
    WebkitOverflowScrolling: 'touch'
  },
  option: {
    paddingX: 5,
    paddingY: 3,
    cursor: 'pointer',
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    _hover: {
      background: 'gray.100'
    },
    _selected: {
      background: 'gray.100'
    },
    _hidden: {
      display: 'none'
    }
  },
  optionSelectedIcon: {
    marginLeft: 2
  },
  singleValue: {
    fontSize: 'md',
    gridArea: '1 / 1 / 2 / 3',
    maxWidth: '100%',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    color: 'inherit'
  },
  multiValueContainer: {
    display: 'flex',
    alignItems: 'center',
    minWidth: 0,
    backgroundColor: 'gray.100',
    borderRadius: 'md',
    border: '1px',
    borderColor: 'primary.500',
    margin: 1,
    padding: 1.5
  },
  multiValueLabel: {
    whiteSpace: 'nowrap',
    color: 'inherit',
    paddingX: 1.5
  },
  multiValueRemove: {
    display: 'flex',
    alignItems: 'center',
    _hover: {
      color: 'gray.800'
    },
    boxSize: 4,
    svg: {
      width: 2,
      height: 2
    }
  }
});
