import { SentMessagesChartData } from 'api';
import { LineChartSettings, TableFilterSchema } from 'containers';
import { format } from 'date-fns';
import { InputElement, Period } from 'enum';
import { OptionKey } from 'enum/api';
import { formatChartAxisDateByPeriod } from 'utils';

export const CHART_SETTINGS: LineChartSettings<SentMessagesChartData> = {
  colors: ['var(--chakra-colors-blue-300)'],
  transformChartData: (data) => ({
    x: data.date,
    y: data.value
  }),
  formatXAxis: (value, period) => {
    if (typeof value !== 'string') return value.toString();

    return formatChartAxisDateByPeriod(value, period);
  },
  getLabel: ({ t }) => t('keywords.all_users')
};

export const chartFiltersSchema: TableFilterSchema[] = [
  {
    type: InputElement.Select,
    getLabel: (t) => t('keywords.period'),
    defaultValues: [Period.LastMonth],
    optionKey: OptionKey.Period,
    updateOutput: (_, value) => ({
      key: 'period',
      value: value[0]
    })
  },
  {
    type: InputElement.Range,
    getLabel: (t) => t('keywords.custom_period'),
    optionKey: 'customRange',
    updateOutput: (key, value) => ({
      key,
      value: value
        .filter((date): date is string => !!date)
        .map((date) => new Date(date).toISOString())
    }),
    inputProps: {
      type: 'date',
      max: format(new Date(), 'dd.MM.yyyy')
    }
  }
];
