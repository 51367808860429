import { ENDPOINTS } from 'api/endpoints';
import axios from 'axios';

export const fetchUpdateAdminComment = async ({
  commentId,
  comment
}: {
  commentId: string;
  comment: string;
}): Promise<void> => {
  await axios.patch(`${ENDPOINTS.ADMIN_COMMENTS}/${commentId}`, {
    text: comment
  });
};
