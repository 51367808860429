import { yupResolver } from '@hookform/resolvers/yup';
import { FormConstructor, ModalContainer } from 'containers';
import { FormField, ModalType } from 'enum';
import {
  useModalActions,
  useUpdatePaymentMethod,
  useUpdatePaymentSubscription,
  useChakraToast
} from 'hooks';
import { useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { ParamType } from 'types';

import { EditCountriesFormData } from './EditPaymentMethodCountries.types';
import {
  editCountriesFormSchema,
  editCountriesValidationSchema
} from './EditPaymentMethodCountries.utils';

export const EditPaymentMethodCountries = (): JSX.Element => {
  const [t] = useTranslation();
  const toast = useChakraToast();

  const form = useForm<EditCountriesFormData>({
    mode: 'onSubmit',
    resolver: yupResolver(editCountriesValidationSchema),
    defaultValues: {
      [FormField.Countries]: []
    }
  });

  const {
    reset,
    handleSubmit,
    formState: { isDirty }
  } = form;

  const {
    mutate: updatePaymentMethod,
    isLoading: isLoadingMethod,
    reset: resetMethod
  } = useUpdatePaymentMethod();

  const {
    mutate: updatePaymentSubscription,
    isLoading: isLoadingSubscription,
    reset: resetSubscription
  } = useUpdatePaymentSubscription();

  const isLoading = isLoadingMethod || isLoadingSubscription;

  const clearComponentStates = () => {
    resetMethod();
    resetSubscription();
    reset();
  };

  const { isOpen, onClose, meta } = useModalActions(
    ModalType.EditPaymentMethodCountries,
    {
      onClose: clearComponentStates
    }
  );

  useEffect(() => {
    if (meta) {
      reset({
        [FormField.Countries]: meta.countries
      });
    }
  }, [meta, reset]);

  const onSubmit = handleSubmit((data) => {
    if (meta) {
      const { methodId, subscriptionId } = meta;

      if (methodId) {
        updatePaymentMethod(
          {
            methodId,
            ...data
          },
          {
            onSuccess: onClose
          }
        );
      }

      if (subscriptionId) {
        updatePaymentSubscription(
          {
            subscriptionId,
            ...data
          },
          {
            onSuccess: onClose,
            onError: (error) => {
              toast({
                status: 'error',
                title: t('errors.invalid_form'),
                description: error.message
              });
            }
          }
        );
      }
    }
  });

  const actions: ParamType<typeof ModalContainer, 'footerButtons'> = [
    {
      text: t('actions.cancel'),
      variant: 'ghostGray'
    },
    {
      text: t('actions.save_changes'),
      isLoading,
      isDisabled: !isDirty,
      isPreventClose: true,
      onClick: () => onSubmit()
    }
  ];

  return (
    <ModalContainer
      header={t('actions.edit_country', { count: 2 })}
      footerButtons={actions}
      isOpen={isOpen}
      onClose={onClose}
      size="xl"
      modalBodyProps={{
        overflow: 'unset',
        overflowX: 'unset'
      }}
    >
      <FormProvider {...form}>
        <FormConstructor formSchema={editCountriesFormSchema} />
      </FormProvider>
    </ModalContainer>
  );
};
