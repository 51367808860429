import { GroupBase, MultiValueProps } from 'react-select';

export const MultiValue = <
  Option,
  IsMulti extends boolean,
  Group extends GroupBase<Option>
>(
  props: MultiValueProps<Option, IsMulti, Group>
): JSX.Element => {
  const { children, components, data, innerProps, removeProps, selectProps } =
    props;

  const { Container, Label, Remove } = components;

  return (
    <Container data={data} innerProps={innerProps} selectProps={selectProps}>
      <Label data={data} innerProps={{}} selectProps={selectProps}>
        {children}
      </Label>
      <Remove data={data} innerProps={removeProps} selectProps={selectProps} />
    </Container>
  );
};
