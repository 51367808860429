import { Tag } from '@chakra-ui/react';
import { Dropdown } from 'components';
import { DropdownType } from 'enum';
import { getAllEnumValues } from 'enum-for';
import { ParameterOptionActivityStatus } from 'enum/api';

import { getTagColorSchemeByStatus } from '../TableManagement.utils';

type Props = {
  status: ParameterOptionActivityStatus;
  updateEntity: (data: ParameterOptionActivityStatus) => Promise<void>;
};

export const StatusCell = ({
  updateEntity,
  status: selectedStatus
}: Props): JSX.Element => {
  const options = getAllEnumValues(ParameterOptionActivityStatus).map(
    (value) => ({
      label: (
        <Tag
          variant="solid"
          colorScheme={getTagColorSchemeByStatus(value)}
          size="md"
        >
          {value}
        </Tag>
      ),
      value
    })
  );

  return (
    <Dropdown
      type={DropdownType.Tag}
      options={options}
      selectedValue={selectedStatus}
      onChange={(value) => updateEntity(value as ParameterOptionActivityStatus)}
    />
  );
};
