import { useQuery } from '@tanstack/react-query';
import { fetchIncomeMoneyBalance } from 'api';
import { QueryKey } from 'enum/api';
import { useAppSelector } from 'hooks';
import { selectAppFilters } from 'store/app';
import { ChartFilters } from 'types';

export const useIncomeMoneyBalance = ({
  filters
}: {
  filters: ChartFilters;
}) => {
  const globalFilters = useAppSelector(selectAppFilters);

  return useQuery(
    [QueryKey.StatisticIncomeMoneyBalance, globalFilters, filters],
    () => fetchIncomeMoneyBalance({ globalFilters, filters }),
    {
      retry: false,
      refetchOnWindowFocus: false,
      keepPreviousData: false
    }
  );
};
