import { Icon, IconProps } from '@chakra-ui/react';

export const DownloadIcon = (props: IconProps): JSX.Element => (
  <Icon viewBox="0 0 16 16" fill="none" {...props}>
    <path
      d="M15 8L15 12.5638C15 13.6684 14.1046 14.5638 13 14.5638L3 14.5638C1.89543 14.5638 1 13.6684 1 12.5638L1 8"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
    />
    <path
      d="M5.8999 8.97996L7.2938 10.3699C7.6841 10.7591 8.31571 10.7591 8.706 10.3699L10.0999 8.97996"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
    />
    <path
      d="M8 10.3758L8 1.99976"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
    />
  </Icon>
);
