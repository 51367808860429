import { GridItem } from '@chakra-ui/react';
import { yupResolver } from '@hookform/resolvers/yup';
import { CustomerSelect, FormConstructor, ModalContainer } from 'containers';
import { FormField, ModalType } from 'enum';
import {
  useChakraToast,
  useCreatePaymentTransaction,
  useFormValidation,
  useModalActions,
  useSubscriptionOptionsByUser
} from 'hooks';
import startCase from 'lodash/startCase';
import { useEffect, useMemo } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { ParamType } from 'types';

import { CreateNewTransactionFormData } from './CreateNewTransaction.types';
import {
  createNewTransactionValidationSchema,
  getCreateNewTransactionFormSchema
} from './CreateNewTransaction.utils';

export const CreateNewTransaction = (): JSX.Element => {
  const [t] = useTranslation();
  const toast = useChakraToast();

  const form = useForm<CreateNewTransactionFormData>({
    mode: 'onSubmit',
    resolver: yupResolver(createNewTransactionValidationSchema),
    defaultValues: {
      [FormField.Comment]: '',
      [FormField.Id]: null
    }
  });
  const { handleSubmit, reset, watch } = form;

  const clearSelection = () => {
    reset({
      [FormField.Id]: null
    });
  };

  const userId = watch(FormField.Id);

  const { isOpen, onClose } = useModalActions(ModalType.CreateNewTransaction, {
    onClose: clearSelection
  });

  const { data: options } = useSubscriptionOptionsByUser({
    customerId: userId || ''
  });

  const { mutate, isLoading } = useCreatePaymentTransaction();

  const isFormFilled = useFormValidation<CreateNewTransactionFormData>(
    form,
    createNewTransactionValidationSchema
  );

  useEffect(() => {
    const { watch, resetField } = form;

    const subscription = watch((_, { name }) => {
      switch (name) {
        case FormField.SubscriptionProgramType:
          resetField(FormField.SubscriptionTypeName);
          return;
        case FormField.SubscriptionTypeName:
          resetField(FormField.SumPaid);
          resetField(FormField.Currency);
          return;
      }
    });

    return () => subscription.unsubscribe();
  }, [form]);

  const onSubmit = handleSubmit((data) => {
    mutate(
      {
        userId: data.id as string,
        programType: data.subscriptionProgramType as string,
        subscriptionType: data.subscriptionTypeName as string,
        amount: data.sumPaid as number,
        currency: data.currency as string,
        comment: data.comment as string
      },
      {
        onSuccess: onClose,
        onError: (error) => {
          toast({
            status: 'error',
            title: t('errors.invalid_form'),
            description: error.message
          });
        }
      }
    );
  });

  const actions: ParamType<typeof ModalContainer, 'footerButtons'> = [
    {
      text: t('actions.cancel'),
      variant: 'ghostGray'
    },
    {
      text: startCase(t('actions.add')),
      isLoading,
      isPreventClose: true,
      isDisabled: !isFormFilled || !userId,
      onClick: () => onSubmit()
    }
  ];

  const createNewTransactionFormSchema = useMemo(
    () => getCreateNewTransactionFormSchema(options),
    [options]
  );

  return (
    <ModalContainer
      header={t('attribute.title.add_new_transaction')}
      footerButtons={actions}
      isOpen={isOpen}
      onClose={onClose}
      size="xl"
    >
      <FormProvider {...form}>
        <FormConstructor formSchema={createNewTransactionFormSchema}>
          <GridItem area={FormField.Id}>
            <CustomerSelect
              selectedUserId={userId}
              onChange={(id) => {
                reset({
                  [FormField.Id]: id
                });
              }}
            />
          </GridItem>
        </FormConstructor>
      </FormProvider>
    </ModalContainer>
  );
};
