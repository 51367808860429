import { CustomerStoryListResponse, ENDPOINTS } from 'api';
import axios from 'axios';
import { AssetType, ImageStatus } from 'enum/api';
import { ICustomerStoryList } from 'types/customer';

const photoUrl =
  'https://images.pexels.com/photos/220453/pexels-photo-220453.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const fakedData: ICustomerStoryList[] = [
  {
    id: 'id_1',
    uploadedAt: new Date().toLocaleString(),
    statusedAt: new Date().toLocaleString(),
    status: ImageStatus.Active,
    assets: [
      {
        id: 'id_1',
        uploadedAt: new Date().toLocaleString(),
        statusedAt: new Date().toLocaleString(),
        status: ImageStatus.Active,
        assetUrl: photoUrl,
        type: AssetType.Image
      },
      {
        id: 'id_2',
        uploadedAt: new Date().toLocaleString(),
        statusedAt: new Date().toLocaleString(),
        status: ImageStatus.Active,
        assetUrl: photoUrl,
        type: AssetType.Image
      },
      {
        id: 'id_3',
        uploadedAt: new Date().toLocaleString(),
        statusedAt: new Date().toLocaleString(),
        status: ImageStatus.Active,
        assetUrl: photoUrl,
        type: AssetType.Image
      },
      {
        id: 'id_4',
        uploadedAt: new Date().toLocaleString(),
        statusedAt: new Date().toLocaleString(),
        status: ImageStatus.Active,
        assetUrl:
          'http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/BigBuckBunny.mp4',
        type: AssetType.Video
      },
      {
        id: 'id_5',
        uploadedAt: new Date().toLocaleString(),
        statusedAt: new Date().toLocaleString(),
        status: ImageStatus.Active,
        assetUrl: photoUrl,
        type: AssetType.Image
      },
      {
        id: 'id_6',
        uploadedAt: new Date().toLocaleString(),
        statusedAt: new Date().toLocaleString(),
        status: ImageStatus.Active,
        assetUrl: photoUrl,
        type: AssetType.Image
      }
    ]
  },
  {
    id: 'id_5',
    uploadedAt: new Date().toLocaleString(),
    statusedAt: new Date().toLocaleString(),
    status: ImageStatus.Active,
    assets: [
      {
        id: 'id_1',
        uploadedAt: new Date().toLocaleString(),
        statusedAt: new Date().toLocaleString(),
        status: ImageStatus.Active,
        assetUrl: photoUrl,
        type: AssetType.Image
      },
      {
        id: 'id_2',
        uploadedAt: new Date().toLocaleString(),
        statusedAt: new Date().toLocaleString(),
        status: ImageStatus.Active,
        assetUrl: photoUrl,
        type: AssetType.Image
      }
    ]
  }
];

export const fetchCustomerStoryList = async (
  customerId: string
): Promise<ICustomerStoryList[]> => {
  const { data } = await axios.get<CustomerStoryListResponse[]>(
    `${ENDPOINTS.CUSTOMER_STORIES}/by-user/${customerId}`
  );

  return data;
};
