import { Box } from '@chakra-ui/react';
import { ContentContainer, ScrollableContainer } from 'containers';
import { CustomerSection } from 'enum';
import { DropdownTitle } from 'pages/Customers/components';
import { Outlet } from 'react-router-dom';

export const CustomerContent = ({
  sections
}: {
  sections: CustomerSection[] | null;
}): JSX.Element => (
  <ContentContainer height="100%" display="flex" flexDirection="column">
    <Box marginBottom={6}>
      <DropdownTitle sections={sections} />
    </Box>
    <ScrollableContainer>
      <Outlet />
    </ScrollableContainer>
  </ContentContainer>
);
