import {
  Alert,
  AlertIcon,
  HStack,
  IconButton,
  Input,
  Text,
  VStack
} from '@chakra-ui/react';
import { CopyIcon } from 'components';
import { ModalContainer } from 'containers';
import { ModalType } from 'enum';
import { useAdminResetPasswords, useModalActions } from 'hooks';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useCopyToClipboard } from 'react-use';
import { ParamType } from 'types';

export const ResetAllAdminPasswords = (): JSX.Element => {
  const [t] = useTranslation();

  const [, copyToClipboard] = useCopyToClipboard();
  const [isPasswordCopied, setIsPasswordCopied] = useState(false);

  const {
    mutate: resetPasswords,
    isSuccess: isPasswordReset,
    isLoading,
    data: { newPassword } = {},
    reset
  } = useAdminResetPasswords();

  const clearComponentStates = () => {
    reset();
    setIsPasswordCopied(false);
  };

  const { isOpen, onClose } = useModalActions(
    ModalType.ResetAllAdminPasswords,
    {
      onClose: clearComponentStates
    }
  );

  const handleCopy = () => {
    copyToClipboard(newPassword || '');
    setIsPasswordCopied(true);
  };

  const actions: ParamType<typeof ModalContainer, 'footerButtons'> =
    isPasswordReset
      ? [
          {
            text: t('actions.ok'),
            colorScheme: 'primary'
          }
        ]
      : [
          {
            text: t('actions.cancel'),
            variant: 'ghostGray'
          },
          {
            text: t('actions.reset_password', { count: 2 }),
            colorScheme: 'secondary',
            isLoading,
            isPreventClose: true,
            onClick: () => {
              resetPasswords();
            }
          }
        ];

  return (
    <ModalContainer
      header={t('actions.reset_all_passwords')}
      footerButtons={actions}
      isOpen={isOpen}
      onClose={onClose}
    >
      {isPasswordReset ? (
        <VStack spacing={3}>
          <Text>{t('attribute.description.new_password')}</Text>
          <HStack width="100%">
            <Input readOnly value={newPassword} />
            <IconButton
              icon={<CopyIcon />}
              aria-label="Copy"
              color="gray.500"
              variant="ghostGray"
              boxSize={10}
              fontSize="xl"
              onClick={handleCopy}
              isDisabled={!newPassword}
            />
          </HStack>
          {isPasswordCopied && (
            <Alert status="success">
              <AlertIcon />
              {t('attribute.description.password_copied')}
            </Alert>
          )}
        </VStack>
      ) : (
        t('attribute.description.reset_all_passwords')
      )}
    </ModalContainer>
  );
};
