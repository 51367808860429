import {
  AspectRatio,
  Button,
  Divider,
  HStack,
  Image,
  Text,
  VStack
} from '@chakra-ui/react';
import { StatusMark } from 'components';
import { ContentContainer } from 'containers';
import { CustomerPage, CustomerSection, ModalType, OnlineStatus } from 'enum';
import { ParameterType } from 'enum/api';
import {
  useAppDispatch,
  useParameterTypeMeta,
  useParameterTypeTranslation
} from 'hooks';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { openModal } from 'store/modal';
import { ICustomer } from 'types/customer';
import { getAgeByBirthday, getCustomerLinkNavigation } from 'utils';

import { getCustomerDetailsInfoSchema } from './CustomerDashboard.utils';
import { InfoItem } from './components';

type Props = {
  customer: ICustomer;
};

export const Details = ({ customer }: Props): JSX.Element => {
  const [t] = useTranslation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const { getTranslation } = useParameterTypeTranslation();
  const { getOptionMetaForField } = useParameterTypeMeta();

  const {
    bio: { birthDate },
    system: { online, galleryTotalItems },
    role: { role },
    displayedNickname,
    avatar,
    gallery
  } = customer;

  const status = online ? OnlineStatus.Online : OnlineStatus.Offline;

  const handleViewNicknameHistory = () =>
    dispatch(
      openModal({
        id: ModalType.NicknameHistory,
        meta: {
          customerId: customer.id
        }
      })
    );

  const handleViewGallery = () => {
    navigate(
      getCustomerLinkNavigation({
        customerId: customer.id,
        page: CustomerPage.CustomerContent,
        section: CustomerSection.GalleryImages
      })
    );
  };

  return (
    <ContentContainer>
      <VStack spacing={4} alignItems="stretch">
        <AspectRatio ratio={7 / 8} minWidth="100%">
          <Image src={avatar || ''} />
        </AspectRatio>
        <VStack spacing={1}>
          <HStack>
            <StatusMark status={status} />
            <Text fontWeight="medium">{`${displayedNickname}, `}</Text>
            <Text color="subtext">{`${getAgeByBirthday(birthDate)} y.o`}</Text>
          </HStack>
          <Text color="subtext">
            {getTranslation(ParameterType.CustomerRole, role)}
          </Text>
          <Button variant="link" onClick={handleViewNicknameHistory}>
            {t('actions.view_nickname_history')}
          </Button>
        </VStack>
        <InfoItem
          title="Gallery"
          actionButton={
            <Button variant="link" onClick={handleViewGallery}>
              {t('actions.view_all', {
                count: galleryTotalItems
              })}
            </Button>
          }
        >
          <HStack spacing={2} width="100%">
            {gallery.map((url, i) => (
              <AspectRatio key={`${url}_${i}`} ratio={7 / 8} flex="0 0 33%">
                <Image src={url} borderRadius="xl" />
              </AspectRatio>
            ))}
          </HStack>
        </InfoItem>
        <Divider />

        {getCustomerDetailsInfoSchema({
          t,
          dispatch,
          customer,
          getTranslation,
          getOptionMetaForField
        }).map(({ id, title, content, action }) => (
          <InfoItem
            key={id}
            title={title}
            actionButton={
              action && (
                <Button variant="link" onClick={action.onClick}>
                  {action.label}
                </Button>
              )
            }
          >
            {typeof content === 'string' ? (
              <Text color="subtext">{content}</Text>
            ) : (
              content
            )}
          </InfoItem>
        ))}
      </VStack>
    </ContentContainer>
  );
};
