import { FormField } from 'enum';
import { ParameterType } from 'enum/api';
import { useParameterTypeTranslation } from 'hooks';
import { TFunction } from 'react-i18next';
import { RequiredNotNullable } from 'types';

import {
  GeneralSettingsFormData,
  GeneralSettingsFormField
} from '../../FormContent.types';

export const isCustomerTypesExist = (
  data: GeneralSettingsFormData[GeneralSettingsFormField.RestrictCustomerFromAnotherCountry][number]
): data is RequiredNotNullable<
  GeneralSettingsFormData[GeneralSettingsFormField.RestrictCustomerFromAnotherCountry][number]
> => !data.isEverybodyCanSeeEverybody;

export const getInitialFieldsState = () => ({
  [FormField.CustomerType]: null,
  [FormField.OtherCustomerType]: null,
  [FormField.Countries]: [],
  [FormField.AppliedForFreeProgramType]: undefined,
  [FormField.EverybodyCanSeeEverybody]: true
});

export const getCustomerTypeLabelByType = (
  type: string,
  t: TFunction,
  getAllTranslations: ReturnType<
    typeof useParameterTypeTranslation
  >['getAllTranslations']
) => {
  const roleTranslations = getAllTranslations(ParameterType.CustomerType);
  return roleTranslations[type];
};
