import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay
} from '@chakra-ui/modal';
import {
  Button,
  ButtonProps,
  HStack,
  ModalBodyProps,
  ModalProps,
  Portal
} from '@chakra-ui/react';
import { ReactNode } from 'react';

type FooterButton = Omit<ButtonProps, 'onClick'> & {
  text: string;
} & (
    | {
        isPreventClose: true;
        onClick: (callback: () => void) => void;
      }
    | {
        isPreventClose?: false;
        onClick?: () => void;
      }
  );

type Props = Omit<ModalProps, 'children'> & {
  children?: ReactNode;
  header?: string;
  footerButtons?: FooterButton[];
  isOpen: boolean;
  onClose: () => void;
  shouldHideCloseButton?: boolean;
  modalBodyProps?: ModalBodyProps;
};

export const ModalContainer = ({
  children,
  header,
  footerButtons,
  isOpen,
  onClose,
  shouldHideCloseButton = false,
  modalBodyProps,
  ...modalProps
}: Props): JSX.Element => {
  const handleFooterAction = (option: FooterButton) => () => {
    if (option.isPreventClose) {
      option.onClick(onClose);
    } else {
      onClose();
      option.onClick?.();
    }
  };

  return (
    <Modal
      onClose={onClose}
      isOpen={isOpen}
      scrollBehavior="inside"
      isCentered
      {...modalProps}
    >
      <Portal>
        <ModalOverlay />
        <ModalContent maxHeight="90vh">
          {!!header && <ModalHeader>{header}</ModalHeader>}
          {!shouldHideCloseButton && <ModalCloseButton />}
          {children && (
            <ModalBody overflowX="hidden" {...modalBodyProps}>
              {children}
            </ModalBody>
          )}
          {!!footerButtons && (
            <ModalFooter>
              <HStack w="full" spacing={4}>
                {footerButtons.map((option) => {
                  const { text, isPreventClose, ...rest } = option;
                  return (
                    <Button
                      key={text}
                      w="full"
                      {...rest}
                      onClick={handleFooterAction(option)}
                    >
                      {text}
                    </Button>
                  );
                })}
              </HStack>
            </ModalFooter>
          )}
        </ModalContent>
      </Portal>
    </Modal>
  );
};
