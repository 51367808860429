import { ENDPOINTS } from 'api/endpoints';
import axios from 'axios';
import { ILink } from 'types/customer';

export const fetchVerificationLink = async (id: string): Promise<ILink> => {
  const { data } = await axios.get<ILink>(
    `${ENDPOINTS.CUSTOMER}/${id}/${ENDPOINTS.VERIFY_EMAIL_LINK}`
  );

  return data;
};
