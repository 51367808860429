import { GridItem } from '@chakra-ui/react';
import { FormElement } from 'containers';
import { InputElement, OptionKey } from 'enum';
import { Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import { GetOptionsArg } from 'types/form';
import { generateOptionsFromSettings } from 'utils';

import { GeneralSettingsFormField } from '../FormContent.types';

export const DefaultLanguage = (): JSX.Element => {
  const [t] = useTranslation();

  return (
    <Fragment>
      <GridItem>{t('attribute.description.default_language')}</GridItem>
      <GridItem>
        <FormElement
          type={InputElement.Select}
          name={GeneralSettingsFormField.DefaultLanguage}
          translationKey="language"
          getPlaceholder={() => 'Language'}
          getOptions={({ t, settings }: GetOptionsArg) =>
            generateOptionsFromSettings(OptionKey.SystemLanguage, t, settings)
          }
        />
      </GridItem>
    </Fragment>
  );
};
