import { Tag } from '@chakra-ui/react';
import { createColumnHelper } from '@tanstack/react-table';
import { ParameterTypeOptionTranslateCell } from 'components';
import { TableFilterSchema } from 'containers';
import { format } from 'date-fns';
import { InputElement } from 'enum';
import { OptionKey, ParameterType } from 'enum/api';
import { ICustomerPayments } from 'types/customer';
import { getDateFormattedString } from 'utils';

import { AdminNicknameCell } from './Cells';

const columnHelper = createColumnHelper<ICustomerPayments>();

export const paymentsColumns = [
  columnHelper.accessor('paymentMethodDisplayName', {
    cell: (info) => info.getValue(),
    header: ({ table }) => table.options.meta?.t('keywords.display_name'),
    enableSorting: false
  }),
  columnHelper.accessor('amount', {
    cell: (info) => info.getValue(),
    header: ({ table }) => table.options.meta?.t('keywords.sum'),
    enableSorting: true
  }),
  columnHelper.accessor('currency', {
    cell: ({ getValue }) => (
      <ParameterTypeOptionTranslateCell
        optionKey={ParameterType.Currency}
        value={getValue()}
      />
    ),
    header: ({ table }) => table.options.meta?.t('keywords.currency'),
    enableSorting: false
  }),
  columnHelper.accessor('createdAt', {
    cell: ({ getValue, table }) =>
      getDateFormattedString({ t: table.options.meta?.t, date: getValue() }),
    header: ({ table }) =>
      table.options.meta?.t('keywords.payment_date_and_time'),
    enableSorting: true
  }),
  columnHelper.accessor((row) => row.admin?.nickname, {
    id: 'nickname',
    cell: AdminNicknameCell,
    header: ({ table }) => table.options.meta?.t('keywords.admin'),
    enableSorting: false
  }),
  columnHelper.accessor('programType', {
    cell: ({ getValue }) => (
      <Tag colorScheme="status.gray" size="md">
        <ParameterTypeOptionTranslateCell
          optionKey={ParameterType.ProgramType}
          value={getValue()}
        />
      </Tag>
    ),
    header: ({ table }) => table.options.meta?.t('keywords.program_type'),
    enableSorting: false
  }),
  columnHelper.accessor('subscriptionType', {
    cell: (info) => (
      <Tag colorScheme="status.gray" size="md">
        {info.getValue()}
      </Tag>
    ),
    header: ({ table }) => table.options.meta?.t('keywords.subscription_type'),
    enableSorting: false
  }),
  columnHelper.accessor('source', {
    header: ({ table }) => table.options.meta?.t('keywords.source'),
    enableSorting: false
  }),
  columnHelper.accessor('status', {
    header: ({ table }) => table.options.meta?.t('keywords.status'),
    enableSorting: false
  })
];

export const paymentTableFilters: TableFilterSchema[] = [
  {
    type: InputElement.Range,
    getLabel: (t) => t('keywords.date_and_time'),
    optionKey: 'createdAtBetween',
    updateOutput: (key, value) => ({
      key,
      value: value
        .filter((date): date is string => !!date)
        .map((date) => new Date(date).toISOString())
    }),
    inputProps: {
      type: 'date',
      max: format(new Date(), 'dd.MM.yyyy')
    }
  },
  {
    type: InputElement.Checkbox,
    getLabel: (t) => t('keywords.currency'),
    optionKey: ParameterType.Currency,
    updateOutput: (_, value) => ({
      key: 'currencies',
      value
    })
  },
  {
    type: InputElement.Checkbox,
    getLabel: (t) => t('keywords.program_type'),
    optionKey: ParameterType.ProgramType,
    updateOutput: (_, value) => ({
      key: 'programTypes',
      value
    })
  },
  {
    type: InputElement.Checkbox,
    getLabel: (t) => t('keywords.subscription_type'),
    optionKey: OptionKey.SubscriptionType,
    updateOutput: (_, value) => ({
      key: 'subscriptionTypes',
      value
    })
  },
  {
    type: InputElement.Checkbox,
    getLabel: (t) => t('keywords.payment_method'),
    optionKey: OptionKey.PaymentMethod,
    updateOutput: (_, value) => ({
      key: 'paymentMethods',
      value
    })
  }
];
