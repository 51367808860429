import { useQuery } from '@tanstack/react-query';
import { fetchCustomerLoginHistory } from 'api';
import { QueryKey } from 'enum/api';
import { QueryFilters } from 'types';

export const useCustomerLoginHistory = (
  customerId: string,
  queryFilters: QueryFilters
) =>
  useQuery(
    [QueryKey.LoginHistory, customerId, queryFilters],
    () => fetchCustomerLoginHistory({ customerId, queryFilters }),
    {
      retry: false,
      refetchOnWindowFocus: false,
      keepPreviousData: true
    }
  );
