import { FormField, InputElement } from 'enum';
import { OptionKey, ParameterType } from 'enum/api';
import { GetOptionsArg } from 'types/form';
import {
  generateCityOptionsByCountry,
  generateOptionsFromSettings,
  generateStatesOptionsByCountry
} from 'utils';
import { LATIN_CHARACTERS_REGEXP } from 'utils/validation';
import * as yup from 'yup';

import { CreateCustomerFormData } from './CreateAccount.types';

export const createCustomerAccountFormSchema = {
  fields: {
    [FormField.UserName]: {
      type: InputElement.Input,
      translationKey: 'nickname',
      getPlaceholder: () => 'Username'
    },
    [FormField.CustomerType]: {
      type: InputElement.Select,
      translationKey: 'customer_type',
      getPlaceholder: () => 'Customer type',
      getOptions: ({ t, settings }: GetOptionsArg) =>
        generateOptionsFromSettings(ParameterType.CustomerType, t, settings)
    },
    [FormField.Email]: {
      type: InputElement.Input,
      translationKey: 'email',
      getPlaceholder: () => 'Email'
    },
    [FormField.Gender]: {
      type: InputElement.Select,
      translationKey: 'gender',
      getPlaceholder: () => 'Select',
      getOptions: ({ t, settings }: GetOptionsArg) =>
        generateOptionsFromSettings(ParameterType.Gender, t, settings)
    },
    [FormField.BirthDate]: {
      type: InputElement.Input,
      translationKey: 'birth_date',
      getPlaceholder: () => '',
      inputType: 'date'
    },
    [FormField.Country]: {
      type: InputElement.Select,
      translationKey: 'country',
      getPlaceholder: () => 'Select country',
      isSearchable: true,
      getOptions: ({ t, settings }: GetOptionsArg) =>
        generateOptionsFromSettings(OptionKey.Country, t, settings)
    },
    [FormField.State]: {
      type: InputElement.Select,
      translationKey: 'state',
      getPlaceholder: () => 'Select state',
      isSearchable: true,
      getOptions: ({ t, getFormValues }: GetOptionsArg) =>
        generateStatesOptionsByCountry({
          t,
          countryCode: getFormValues(FormField.Country)
        })
    },
    [FormField.City]: {
      type: InputElement.Select,
      translationKey: 'city',
      getPlaceholder: () => 'Select city',
      isSearchable: true,
      getOptions: ({ t, getFormValues }: GetOptionsArg) =>
        generateCityOptionsByCountry({
          t,
          countryCode: getFormValues(FormField.Country),
          originCity: getFormValues(FormField.City)
        })
    },
    [FormField.LookingForGender]: {
      type: InputElement.Select,
      translationKey: 'looking_for_gender',
      getPlaceholder: () => 'Select',
      getOptions: ({ t, settings }: GetOptionsArg) =>
        generateOptionsFromSettings(ParameterType.LookForGender, t, settings)
    },
    [FormField.IsAdminUser]: {
      type: InputElement.Toggle,
      translationKey: 'is_admin_user',
      getPlaceholder: () => 'Is admin user'
    }
  },
  gridLayout: {
    templateAreas: `
    "${FormField.UserName} ${FormField.UserName}"
    "${FormField.CustomerType} ${FormField.CustomerType}"
    "${FormField.Email} ${FormField.Email}"
    "${FormField.Gender} ${FormField.BirthDate}"
    "${FormField.Country} ${FormField.Country}"
    "${FormField.State} ${FormField.City}"
    "${FormField.LookingForGender} ${FormField.LookingForGender}"
    "${FormField.IsAdminUser} ."
    `,
    gridTemplateColumns: '1fr 1fr',
    columnGap: 3,
    rowGap: 6
  }
} as const;

export const createCustomerBasicValidationSchema: yup.SchemaOf<CreateCustomerFormData> =
  yup.object({
    [FormField.UserName]: yup.string().required(),
    [FormField.CustomerType]: yup.string().required(),
    [FormField.Email]: yup.string().required(),
    [FormField.Gender]: yup.string().required(),
    [FormField.BirthDate]: yup.string().required(),
    [FormField.Country]: yup.string().required(),
    [FormField.State]: yup.string().optional(),
    [FormField.City]: yup.string().required(),
    [FormField.LookingForGender]: yup.string().required(),
    [FormField.IsAdminUser]: yup.boolean().defined()
  });

export const createCustomerValidationSchema: yup.SchemaOf<CreateCustomerFormData> =
  createCustomerBasicValidationSchema.concat(
    yup.object({
      [FormField.UserName]: yup
        .string()
        .max(50)
        .matches(LATIN_CHARACTERS_REGEXP),
      [FormField.Email]: yup.string().email()
    })
  );
