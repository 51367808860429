import { Button, VStack } from '@chakra-ui/react';
import { ReportAdminListResponse, ReportCustomerListResponse } from 'api';
import { ModalType } from 'enum';
import { useAppDispatch } from 'hooks';
import { MarkButton } from 'pages/Reports/components';
import { useTranslation } from 'react-i18next';
import { openModal } from 'store';

type Props =
  | {
      modalType: ModalType.CustomerReportDetails;
      data: ReportCustomerListResponse;
    }
  | {
      modalType: ModalType.AdminReportDetails;
      data: ReportAdminListResponse;
    };

export const ActionCell = ({ data, modalType }: Props): JSX.Element => {
  const [t] = useTranslation();
  const dispatch = useAppDispatch();

  const onHandleDetails = () => {
    const modal = {
      id: modalType,
      meta: {
        details: data
      }
    } as
      | {
          id: ModalType.CustomerReportDetails;
          meta: {
            details: ReportCustomerListResponse;
          };
        }
      | {
          id: ModalType.AdminReportDetails;
          meta: {
            details: ReportAdminListResponse;
          };
        };

    dispatch(openModal(modal));
  };

  return (
    <VStack spacing={3}>
      <Button variant="link" onClick={onHandleDetails}>
        {t('actions.details')}
      </Button>
      <MarkButton reportId={data.id} hasRead={data.hasRead} />
    </VStack>
  );
};
