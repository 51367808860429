import {
  Button,
  Menu,
  MenuButton,
  MenuButtonProps,
  MenuList
} from '@chakra-ui/react';
import { ReactNode } from 'react';

import { ListContainerProps } from './ButtonDropdown.types';
import { hasSelectedValues } from './ButtonDropdown.utils';
import { ListContainer, ResetSelection } from './components';

type ButtonDropdownProps = {
  label: ReactNode;
  menuButtonProps?: MenuButtonProps;
  onReset: () => void;
  list: ListContainerProps;
};

export const ButtonDropdown = ({
  label,
  onReset,
  menuButtonProps,
  list
}: ButtonDropdownProps): JSX.Element => {
  const hasValue = hasSelectedValues(list);

  return (
    <Menu>
      <MenuButton
        as={Button}
        colorScheme="gray"
        aria-selected={hasValue}
        sx={{
          '> span': {
            pointerEvents: 'unset'
          }
        }}
        {...menuButtonProps}
      >
        {label}
        {hasValue && <ResetSelection onReset={onReset} />}
      </MenuButton>
      <MenuList py={0}>
        <ListContainer {...list} />
      </MenuList>
    </Menu>
  );
};
