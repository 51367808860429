import { Checkbox } from '@chakra-ui/react';
import { createColumnHelper } from '@tanstack/react-table';
import { ParameterValue } from 'api';
import { TruncateText } from 'components';
import { TableFilterSchema } from 'containers';
import { InputElement } from 'enum';
import { OptionKey } from 'enum/api';
import {
  LanguageCell,
  JsonCell,
  TextEditCell,
  DeleteValue
} from 'pages/TableManagement/components';
import { TFunction } from 'react-i18next';

const columnHelper = createColumnHelper<ParameterValue>();

export const parameterColumns = [
  columnHelper.display({
    id: 'checkbox',
    cell: ({ row }) => (
      <Checkbox
        isChecked={row.getIsSelected()}
        onChange={row.getToggleSelectedHandler()}
      />
    ),
    header: ({ table }) => (
      <Checkbox
        isChecked={table.getIsAllRowsSelected()}
        isIndeterminate={table.getIsSomeRowsSelected()}
        onChange={table.getToggleAllRowsSelectedHandler()}
      />
    ),
    enableSorting: false
  }),
  columnHelper.accessor('parameterType', {
    cell: ({ getValue }) => (
      <TruncateText maxWidth="md">{getValue()}</TruncateText>
    ),
    header: ({ table }) => table.options.meta?.t('keywords.type'),

    enableSorting: false
  }),
  columnHelper.accessor('parameterTitle', {
    cell: ({ getValue }) => (
      <TruncateText maxWidth="md">{getValue()}</TruncateText>
    ),
    header: ({ table }) => table.options.meta?.t('keywords.title'),
    enableSorting: false
  }),
  columnHelper.accessor('languageCode', {
    cell: ({ getValue, row, table }) => (
      <LanguageCell
        value={getValue()}
        updateEntity={async (data) =>
          await table.options.meta?.updateEntity?.(row.original.id, {
            languageCode: data
          })
        }
      />
    ),
    header: ({ table }) => table.options.meta?.t('keywords.language'),
    enableSorting: false
  }),
  columnHelper.accessor('value', {
    cell: ({ getValue, row, table }) => (
      <TextEditCell
        maxWidth="md"
        editModalHeader={table.options.meta?.t('actions.edit_value')}
        updateEntity={async (data) =>
          await table.options.meta?.updateEntity?.(row.original.id, {
            value: data ?? undefined
          })
        }
      >
        {getValue()}
      </TextEditCell>
    ),
    header: ({ table }) => table.options.meta?.t('keywords.value'),
    enableSorting: true
  }),
  columnHelper.accessor('meta', {
    cell: ({ getValue, row, table }) => (
      <JsonCell
        value={getValue()}
        editModalHeader={table.options.meta?.t('actions.edit_meta')}
        updateEntity={async (data) =>
          await table.options.meta?.updateEntity?.(row.original.id, {
            meta: data ?? undefined
          })
        }
      />
    ),
    header: ({ table }) => table.options.meta?.t('keywords.meta'),
    enableSorting: false
  }),
  columnHelper.display({
    id: 'actions',
    cell: ({ row }) => <DeleteValue id={row.original.id} />,
    header: () => null,
    enableSorting: false
  })
];

export const tableFilters: TableFilterSchema[] = [
  {
    type: InputElement.Checkbox,
    getLabel: (t) => t('keywords.type'),
    optionKey: OptionKey.ParameterOptionType,
    updateOutput: (_, value) => ({
      key: 'parameterTypes',
      value
    })
  },
  {
    type: InputElement.Checkbox,
    getLabel: (t) => t('keywords.language'),
    optionKey: OptionKey.SystemLanguage,
    updateOutput: (_, value) => ({
      key: 'languageCodes',
      value
    })
  }
];

export const getSearchOptions = (t: TFunction) => [
  {
    label: t('keywords.title'),
    value: 'parameterTitle'
  },
  {
    label: t('keywords.value'),
    value: 'value'
  }
];
