import { Grid, GridItem } from '@chakra-ui/react';
import { ContentContainer } from 'containers';
import { CustomerSection } from 'enum';
import { DropdownTitle } from 'pages';
import { Outlet, useLocation, useOutletContext } from 'react-router-dom';
import { getCustomerSectionByUrl } from 'routes/routes.utils';

export const CustomerInteraction = ({
  sections
}: {
  sections: CustomerSection[] | null;
}): JSX.Element => {
  const { pathname } = useLocation();
  const { customer } = useOutletContext();

  const selectedValue = getCustomerSectionByUrl(pathname);

  if (selectedValue === CustomerSection.CustomerMessages) {
    return (
      <Outlet
        context={{
          customer
        }}
      />
    );
  }

  return (
    <ContentContainer height="100%">
      <Grid
        templateAreas={`
         "title ."
         "table table"
       `}
        templateColumns="auto 1fr"
        templateRows="auto 1fr"
        rowGap={6}
        height="100%"
      >
        <GridItem area="title">
          <DropdownTitle sections={sections} />
        </GridItem>
        <GridItem area="table" overflow="hidden">
          <Outlet />
        </GridItem>
      </Grid>
    </ContentContainer>
  );
};
