import { ModalType } from 'enum';
import { useAppDispatch, useAppSelector, useLogin } from 'hooks';
import Cookies from 'js-cookie';
import { closeModal } from 'store/modal';
import { resetUser, selectIsUserAuthorized } from 'store/user';
import { TOKEN } from 'utils';

import { useUserData } from './useUserData';

type AuthUser = {
  isAuthorized: boolean;
  isFetching: boolean;
  login: ReturnType<typeof useLogin>['mutate'];
  reLogin: () => void;
  logout: () => void;
};

export const useAuthorization = (): AuthUser => {
  const dispatch = useAppDispatch();

  const isAuthorized = useAppSelector(selectIsUserAuthorized);

  const { onLoadUserData, isFetching: isFetchingUserData } = useUserData();
  const { mutate: requestLogin, isLoading: isLoadingLogin } = useLogin({
    onSuccess: onLoadUserData
  });

  const logout = () => {
    dispatch(resetUser());
    dispatch(closeModal(ModalType.Inactivity));
    Cookies.remove(TOKEN);
  };

  return {
    isAuthorized,
    isFetching: isLoadingLogin || isFetchingUserData,
    login: requestLogin,
    reLogin: onLoadUserData,
    logout
  };
};
