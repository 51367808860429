import { FormField, InputElement } from 'enum';
import { OptionKey } from 'enum/api';
import { GetOptionsArg } from 'types/form';
import { generateOptionsFromSettings } from 'utils';
import * as yup from 'yup';

import { DeclineItemFormData } from './DeclineItem.types';

export const declineItemFormSchema = {
  fields: {
    [FormField.Reason]: {
      type: InputElement.Select,
      translationKey: 'reason',
      getPlaceholder: () => 'Select',
      getOptions: ({ t, settings }: GetOptionsArg) =>
        generateOptionsFromSettings(OptionKey.DeclineReason, t, settings),
      isSearchable: true,
      canAdd: true
    }
  },
  gridLayout: {
    templateAreas: `
    "${FormField.Reason}"
  `,
    gridTemplateColumns: '1fr'
  }
} as const;

export const declineItemValidationSchema: yup.SchemaOf<DeclineItemFormData> =
  yup.object({
    [FormField.Reason]: yup.string().required()
  });
