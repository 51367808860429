import { ResponsiveBar } from '@nivo/bar';

import { BarChartProps } from './BarChart.types';
import { BarChartLabelLayer } from './components';
import { useBarChartProps } from './useBarChartProps';

export const BarChart = <T extends unknown>({
  data,
  chartSettings
}: BarChartProps<T>): JSX.Element => {
  const props = useBarChartProps({ data, chartSettings });

  return (
    <ResponsiveBar
      {...props}
      layers={['grid', 'axes', 'bars', 'markers', BarChartLabelLayer]}
    />
  );
};
