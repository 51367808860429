import { SubHeader } from 'containers';
import { Fragment } from 'react';
import { Outlet } from 'react-router-dom';

import { ReportTabs } from './components';

export const Reports = (): JSX.Element => (
  <Fragment>
    <SubHeader>
      <ReportTabs />
    </SubHeader>
    <Outlet />
  </Fragment>
);
