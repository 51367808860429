import { Spinner } from '@chakra-ui/react';
import { Table } from 'components';
import {
  useCustomerIdFromUrl,
  useCustomerLikesToUser,
  useTableQueryController
} from 'hooks';

import { likesColumns } from '../CustomerInteraction.utils';

export const LikesToUser = (): JSX.Element => {
  const {
    queryFilters,
    action: { onChangeSort, onChangePagination }
  } = useTableQueryController();

  const customerId = useCustomerIdFromUrl();

  const { data } = useCustomerLikesToUser(customerId, queryFilters);

  if (!data) return <Spinner />;

  return (
    <Table
      data={data.data}
      columns={likesColumns}
      onChangeSort={onChangeSort}
      onChangePagination={onChangePagination}
      totalItems={data.totalItems}
      pagination={queryFilters.pagination}
    />
  );
};
