import { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { GetIsDisabledFn, GetIsHiddenFn } from 'types/form';

export const useHideFormElement = ({
  getIsHidden,
  getIsDisabled
}: {
  getIsHidden?: GetIsHiddenFn;
  getIsDisabled?: GetIsDisabledFn;
}): {
  isHidden: boolean;
  isDisabled: boolean;
} => {
  const form = useFormContext();

  const [isHidden, setIsHidden] = useState(() => {
    if (!getIsHidden) return false;

    return getIsHidden({
      formValues: form.getValues(),
      defaultValues: form.control._defaultValues
    });
  });

  const [isDisabled, setIsDisabled] = useState(() => {
    if (!getIsDisabled) return false;

    return getIsDisabled({
      formValues: form.getValues(),
      defaultValues: form.control._defaultValues
    });
  });

  useEffect(() => {
    if (!!getIsHidden || !!getIsDisabled) {
      const { watch, control } = form;

      const subscription = watch((value) => {
        if (getIsHidden) {
          setIsHidden(
            getIsHidden({
              formValues: value,
              defaultValues: control._defaultValues
            })
          );
        }

        if (getIsDisabled) {
          setIsDisabled(
            getIsDisabled({
              formValues: value,
              defaultValues: control._defaultValues
            })
          );
        }
      });
      return () => subscription.unsubscribe();
    }
  }, [form, getIsHidden, getIsDisabled]);

  return { isHidden, isDisabled };
};
