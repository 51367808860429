import { minutesToMilliseconds } from 'date-fns';
import { ModalType } from 'enum';
import { useAppDispatch } from 'hooks';
import { useIdleTimer } from 'react-idle-timer';
import { openModal } from 'store/modal';

const IDLE_TIME_MINUTES = 30;
const PROMPT_TIME_MINUTES = 2;

export const useIdleUser = ({ logout }: { logout: () => void }): null => {
  const dispatch = useAppDispatch();

  const onPrompt = () => {
    dispatch(
      openModal({
        id: ModalType.Inactivity
      })
    );
  };

  useIdleTimer({
    onIdle: logout,
    onPrompt,
    timeout: minutesToMilliseconds(IDLE_TIME_MINUTES - PROMPT_TIME_MINUTES),
    promptTimeout: minutesToMilliseconds(PROMPT_TIME_MINUTES)
  });

  return null;
};
