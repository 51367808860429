import { Icon, IconProps } from '@chakra-ui/react';

export const ViewIcon = (props: IconProps): JSX.Element => (
  <Icon viewBox="0 0 20 14" stroke="gray.500" fill="none" {...props}>
    <path
      d="M1.20057 7.78442C0.933139 7.2954 0.933144 6.70445 1.20058 6.21544C2.9 3.10803 6.20336 1 9.99997 1C13.7966 1 17.1 3.10809 18.7994 6.21558C19.0669 6.7046 19.0669 7.29555 18.7994 7.78456C17.1 10.892 13.7966 13 10 13C6.20336 13 2.89997 10.8919 1.20057 7.78442Z"
      strokeWidth="1.5"
    />
    <circle cx="10" cy="7" r="3" strokeWidth="1.5" />
  </Icon>
);
