import { ENDPOINTS, ReportCreateRequestData } from 'api';
import axios from 'axios';
import { toFormData } from 'utils';

export const fetchCreateReport = (data: ReportCreateRequestData) => {
  const formData = toFormData(data);

  return axios.post(ENDPOINTS.REPORT, formData, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  });
};
