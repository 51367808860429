import { MenuItem } from '@chakra-ui/react';
import { ImageStatus } from 'enum/api';
import { Fragment } from 'react';
import { useTranslation } from 'react-i18next';

import { Asset } from './AssetCard.types';

type Props = {
  assetStatus: Asset['status'];
  assetId: Asset['id'];
  onDeleteAsset?: (assetId: string) => void;
  onDeclineAsset?: (assetId: string) => void;
  onRestoreAsset: (assetId: string) => void;
};

export const AssetActionItems = ({
  assetId,
  assetStatus,
  onDeleteAsset,
  onDeclineAsset,
  onRestoreAsset
}: Props): JSX.Element => {
  const [t] = useTranslation();

  const canDelete = !!onDeleteAsset;
  const canDecline = !!onDeclineAsset;

  return (
    <Fragment>
      {assetStatus === ImageStatus.Active && (
        <Fragment>
          {canDelete && (
            <MenuItem onClick={() => onDeleteAsset(assetId)}>
              {t('actions.delete')}
            </MenuItem>
          )}
          {canDecline && (
            <MenuItem onClick={() => onDeclineAsset(assetId)}>
              {t('actions.decline')}
            </MenuItem>
          )}
        </Fragment>
      )}
      {[
        ImageStatus.DeletedByAdmin,
        ImageStatus.DeclinedByAdmin,
        ImageStatus.AutoDeclined
      ].includes(assetStatus) && (
        <MenuItem onClick={() => onRestoreAsset(assetId)}>
          {t('actions.restore')}
        </MenuItem>
      )}
    </Fragment>
  );
};
