import { useQuery } from '@tanstack/react-query';
import { ActiveUsersResponse, fetchActiveUsers } from 'api';
import { GenderTruncated } from 'enum';
import { ParameterType, QueryKey } from 'enum/api';
import { useAppSelector } from 'hooks';
import { selectSettings, selectAppFilters } from 'store';
import { ChartFilters } from 'types';

type ActiveUsersFilter = ChartFilters & {
  genders?: string[];
  programTypes?: string[];
};

export const useActiveUsers = ({ filters }: { filters: ActiveUsersFilter }) => {
  const globalFilters = useAppSelector(selectAppFilters);
  const settings = useAppSelector(selectSettings);

  let processedFilters: ActiveUsersFilter;

  if (filters.genders?.includes(GenderTruncated.Other)) {
    const genders = filters.genders.filter(
      (gender) => gender !== GenderTruncated.Other
    );

    const otherGenders = settings[ParameterType.Gender]
      .filter(
        ({ systemId }) =>
          ![GenderTruncated.Female, GenderTruncated.Male].includes(
            systemId as GenderTruncated
          )
      )
      .map(({ optionId }) => optionId);

    processedFilters = {
      ...filters,
      genders: [...genders, ...otherGenders]
    };
  } else {
    processedFilters = filters;
  }

  return useQuery<ActiveUsersResponse>(
    [QueryKey.StatisticActiveUsers, globalFilters, filters],
    () => fetchActiveUsers({ globalFilters, filters: processedFilters }),
    {
      retry: false,
      refetchOnWindowFocus: false,
      initialData: () => ({
        maxCount: 100,
        count: 0
      }),
      keepPreviousData: true
    }
  );
};
