import { CustomerLikesResponse, ENDPOINTS, WithPagination } from 'api';
import { serializeQueryFilters } from 'api/api.utils';
import axios from 'axios';
import { QueryFilters } from 'types';
import { ICustomerLikes } from 'types/customer';

import { serializeCustomerLikesData } from './CustomerLikes.utils';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const fakeData: ICustomerLikes[] = [
  {
    id: 'id_1',
    nickname: 'nick 1',
    email: 'email@email.com',
    likedAt: new Date().toLocaleString(),
    userId: 'user_1'
  }
];

export const fetchCustomerLikesToUser = async ({
  customerId,
  queryFilters
}: {
  customerId: string;
  queryFilters: QueryFilters;
}): Promise<WithPagination<ICustomerLikes[]>> => {
  const { data } = await axios.post<WithPagination<CustomerLikesResponse[]>>(
    `${ENDPOINTS.LIKES}/to-user/${customerId}/list`,
    serializeQueryFilters(queryFilters)
  );

  return {
    ...data,
    data: serializeCustomerLikesData(data.data)
  };
};
