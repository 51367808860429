import { createColumnHelper } from '@tanstack/react-table';
import { Admin } from 'api';
import { CountryList, TruncateText } from 'components';
import { TableFilterSchema } from 'containers';
import { InputElement } from 'enum';
import { OptionKey } from 'enum/api';
import { snakeCase } from 'lodash';
import { TFunction } from 'react-i18next';

import { AdminNicknameCell } from './Cells';

import { EditButton } from '.';

const columnHelper = createColumnHelper<Admin>();

export const columns = [
  columnHelper.accessor('nickname', {
    cell: AdminNicknameCell,
    header: ({ table }) => table.options.meta?.t('keywords.user_name')
  }),
  columnHelper.accessor('id', {
    cell: (info) => info.getValue(),
    header: ({ table }) => table.options.meta?.t('keywords.id'),
    enableSorting: false
  }),
  columnHelper.accessor('email', {
    cell: (info) => info.getValue(),
    header: ({ table }) => table.options.meta?.t('keywords.email')
  }),
  columnHelper.accessor('role.type', {
    cell: (info) => {
      const { table, getValue } = info;
      return table.options.meta?.t(`enum.admin_role.${snakeCase(getValue())}`);
    },
    header: ({ table }) => table.options.meta?.t('keywords.admin_role'),
    enableSorting: false
  }),
  columnHelper.accessor('countries', {
    cell: ({ getValue }) => <CountryList countryCodes={getValue()} />,
    header: ({ table }) => table.options.meta?.t('keywords.countries')
  }),
  columnHelper.accessor('domains', {
    cell: ({ getValue }) => (
      <TruncateText maxWidth="md">{getValue().join(', ')}</TruncateText>
    ),
    header: ({ table }) => table.options.meta?.t('keywords.domains')
  }),
  columnHelper.accessor('status', {
    cell: (info) => {
      const { table, getValue } = info;
      return table.options.meta?.t(
        `enum.admin_status.${snakeCase(getValue())}`
      );
    },
    header: ({ table }) => table.options.meta?.t('keywords.status'),
    enableSorting: false
  }),
  columnHelper.display({
    id: 'more',
    header: () => null,
    cell: ({ row }) => <EditButton userId={row.original.id} />
  })
];

const tableSearchKeys = [
  'userName',
  'id',
  'email',
  'adminRole',
  'country',
  'adminStatus'
] as const;

export const getSearchOptions = (t: TFunction) => {
  const options = tableSearchKeys.map((key) => ({
    label: t(`keywords.${snakeCase(key)}`),
    value: key
  }));

  const defaultOption = {
    label: t('keywords.all'),
    value: 'all'
  };

  return [defaultOption, ...options];
};

export const tableFilters: TableFilterSchema[] = [
  {
    type: InputElement.Checkbox,
    getLabel: (t) => t('keywords.admin_role'),
    optionKey: OptionKey.AdminRole,
    updateOutput: (_, value) => ({
      key: 'roles',
      value
    })
  },
  {
    type: InputElement.Checkbox,
    getLabel: (t) => t('keywords.admin_status'),
    optionKey: OptionKey.AdminStatus,
    updateOutput: (_, value) => ({
      key: 'statuses',
      value
    })
  }
];
