import { ENDPOINTS, WithPagination } from 'api';
import { serializeBodyFilters } from 'api/api.utils';
import axios from 'axios';
import { ModerationPostStatus } from 'enum';
import { GlobalFilters, QueryFilters } from 'types';

import { ModerationMottoResponse } from '../Moderation.types';

export const fetchModerationMottoList = async ({
  status,
  queryFilters,
  globalFilters
}: {
  status: ModerationPostStatus;
  queryFilters: QueryFilters;
  globalFilters: GlobalFilters;
}): Promise<WithPagination<ModerationMottoResponse[]>> => {
  const { data } = await axios.post<WithPagination<ModerationMottoResponse[]>>(
    `${ENDPOINTS.MODERATION_MOTTO}/${status}/list`,
    serializeBodyFilters({ queryFilters, globalFilters })
  );

  return data;
};
