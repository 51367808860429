import { Button, Heading, HStack } from '@chakra-ui/react';
import { yupResolver } from '@hookform/resolvers/yup';
import { RightSideButtons } from 'components';
import {
  Access,
  ContentContainer,
  FormConstructor,
  RightSide,
  ScrollableContainer
} from 'containers';
import { FormField, ModalType, SystemLanguage } from 'enum';
import {
  AccountStatus,
  ErrorCode,
  PermissionAction,
  PermissionResource
} from 'enum/api';
import {
  useAppDispatch,
  useChakraToast,
  usePasswordRecoveryLink,
  usePermissions,
  useUpdateCustomer,
  useVerificationLink
} from 'hooks';
import snakeCase from 'lodash/snakeCase';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate, useOutletContext } from 'react-router-dom';
import { openModal } from 'store/modal';
import { getUpdatedFields, serializeAxiosError } from 'utils';

import { PersonalDetailsFormData } from './PersonalDetails.types';
import {
  getCustomerPersonalDetailsFormSchema,
  getDefaultFormValues,
  personalDetailsValidationSchema
} from './PersonalDetails.utils';

export const PersonalDetails = (): JSX.Element => {
  const [t, { language }] = useTranslation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const toast = useChakraToast();

  const { customer } = useOutletContext();

  const form = useForm<PersonalDetailsFormData>({
    mode: 'onSubmit',
    shouldUnregister: true, // use it for comment
    resolver: yupResolver(personalDetailsValidationSchema),
    defaultValues: getDefaultFormValues(customer, language as SystemLanguage)
  });

  const {
    formState: { isDirty, dirtyFields },
    handleSubmit,
    getValues,
    reset
  } = form;

  const { mutate: updateCustomer, isLoading } = useUpdateCustomer();
  const { data: verificationLink, isLoading: isVerificationLinkLoading } =
    useVerificationLink(customer.id);
  const {
    data: passwordRecoveryLink,
    isLoading: isPasswordRecoveryLinkLoading
  } = usePasswordRecoveryLink(customer.id);

  const onSubmit = handleSubmit((data) => {
    const updateData = getUpdatedFields(data, dirtyFields);
    const { location, ...restUpdateFields } = updateData;

    updateCustomer(
      {
        id: customer.id,
        ...location,
        ...restUpdateFields
      },
      {
        onSuccess: (_, { id, ...rest }) => {
          reset({
            ...getValues(),
            ...rest,
            [FormField.Comment]: ''
          });

          toast({
            title: t('messages.successfully_saved'),
            status: 'success'
          });
        },
        onError: (error) => {
          const { errorCode } = serializeAxiosError(error);

          const errorMessage = [
            ErrorCode.UserCreateEmailExists,
            ErrorCode.UserUpdateEmailExists
          ].includes(errorCode)
            ? t(`errors.codes.${snakeCase(errorCode)}`)
            : t('errors.error_update_user');

          toast({
            title: errorMessage,
            status: 'error'
          });
        }
      }
    );
  });

  const handleCancel = () => {
    navigate(-1);
  };

  const onResetPassword = () => {
    dispatch(
      openModal({
        id: ModalType.ResetCustomerPassword,
        meta: {
          customerId: customer.id
        }
      })
    );
  };

  const handleDeactivateAccount = () =>
    dispatch(
      openModal({
        id: ModalType.DeactivateCustomerAccount,
        meta: {
          customerId: customer.id
        }
      })
    );

  const handleDeleteAccount = () =>
    dispatch(
      openModal({
        id: ModalType.DeleteCustomerAccount,
        meta: {
          customerId: customer.id
        }
      })
    );

  const handleReactivateAccount = () =>
    dispatch(
      openModal({
        id: ModalType.ReactivateCustomerAccount,
        meta: {
          customerId: customer.id
        }
      })
    );

  const handleCopyToClipboard = (text: string) => {
    navigator.clipboard.writeText(text);

    return toast({
      title: t('messages.copied'),
      status: 'success'
    });
  };

  const handleCopyVerificationLink = () => {
    if (!verificationLink?.link) {
      return toast({
        title: t('errors.error_copy_link'),
        status: 'error'
      });
    }

    handleCopyToClipboard(verificationLink.link);
  };

  const handleCopyPasswordRecoveryLink = () => {
    if (!passwordRecoveryLink?.link) {
      return toast({
        title: t('errors.error_copy_link'),
        status: 'error'
      });
    }

    handleCopyToClipboard(passwordRecoveryLink.link);
  };

  const { hasAccess: canEditPassword } = usePermissions({
    resource: PermissionResource.UserPassword,
    actions: PermissionAction.Update
  });

  return (
    <ScrollableContainer>
      <ContentContainer header={t('keywords.personal_details')}>
        <FormProvider {...form}>
          <FormConstructor
            formSchema={getCustomerPersonalDetailsFormSchema({
              canEditPassword
            })}
            meta={{
              onResetPassword
            }}
          />
        </FormProvider>
        <Heading as="h2" size="xl" marginBottom={6} marginTop={16}>
          {t('attribute.title.actions')}
        </Heading>
        <HStack spacing={4}>
          {customer.accountStatus === AccountStatus.Active ? (
            <Button variant="link" onClick={handleDeactivateAccount}>
              {t('actions.deactivate_account')}
            </Button>
          ) : (
            <Button variant="link" onClick={handleReactivateAccount}>
              {t('actions.reactivate_account')}
            </Button>
          )}
          <Button
            variant="link"
            colorScheme="secondary"
            onClick={handleDeleteAccount}
          >
            {t('actions.delete_account')}
          </Button>
          <Access
            restrictions={{
              resource: PermissionResource.GenerateLink,
              actions: PermissionAction.Create
            }}
            noAccessRender={null}
          >
            <Button
              variant="link"
              colorScheme="status.blue"
              onClick={handleCopyVerificationLink}
              isDisabled={isVerificationLinkLoading}
            >
              {t('actions.copy_verification_link')}
            </Button>
          </Access>
          <Access
            restrictions={{
              resource: PermissionResource.GenerateLink,
              actions: PermissionAction.Create
            }}
            noAccessRender={null}
          >
            <Button
              variant="link"
              colorScheme="status.blue"
              onClick={handleCopyPasswordRecoveryLink}
              isDisabled={isPasswordRecoveryLinkLoading}
            >
              {t('actions.copy_password_recovery_link')}
            </Button>
          </Access>
        </HStack>
        <RightSide>
          <RightSideButtons
            onSubmit={onSubmit}
            isDisabledSubmit={!isDirty}
            onCancel={handleCancel}
            isLoading={isLoading}
          />
        </RightSide>
      </ContentContainer>
    </ScrollableContainer>
  );
};
