import { ComponentStyleConfig } from '@chakra-ui/theme';

export const Switch: ComponentStyleConfig = {
  baseStyle: {
    container: {
      display: 'inline-flex',
      alignItems: 'center'
    }
  },
  sizes: {
    rg: {
      container: {
        '--switch-track-width': 'sizes.10',
        '--switch-track-height': 'sizes.5'
      }
    }
  },
  defaultProps: {
    size: 'rg'
  }
};
