import { getAmountOfSelectedGroupFilters } from 'containers/TableFilters/TableFilters.utils';
import { InputElement } from 'enum';

import { ListContainerProps } from './ButtonDropdown.types';

export const hasSelectedValues = ({
  type,
  selectedValues,
  defaultValues
}: ListContainerProps) => {
  switch (type) {
    case InputElement.Checkbox:
      return selectedValues.length > 0;
    case InputElement.CheckboxGroup:
      return getAmountOfSelectedGroupFilters(selectedValues) > 0;

    case InputElement.Range:
      return Object.values(selectedValues).some((value) => value);
    case InputElement.Select:
      return defaultValues
        ? defaultValues[0] !== selectedValues[0]
        : selectedValues.length > 0;
    default:
      return false;
  }
};
