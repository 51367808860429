import { chakra } from '@chakra-ui/react';
import { GroupBase, IndicatorsContainerProps } from 'react-select';

import { useStyles } from '../LocationDropdown.utils';

export const IndicatorsContainer = <
  Option,
  IsMulti extends boolean,
  Group extends GroupBase<Option>
>(
  props: IndicatorsContainerProps<Option, IsMulti, Group>
): JSX.Element => {
  const { children, innerProps } = props;

  const styles = useStyles();

  return (
    <chakra.div __css={styles.indicatorsContainer} {...innerProps}>
      {children}
    </chakra.div>
  );
};
