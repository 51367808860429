import { ChakraTheme } from '@chakra-ui/react';
import calendarSvg from 'assets/calendar.svg';

interface ITypography
  extends Partial<
    Pick<
      ChakraTheme,
      | 'fonts'
      | 'fontSizes'
      | 'fontWeights'
      | 'styles'
      | 'letterSpacings'
      | 'lineHeights'
    >
  > {}

const MEDIA = [
  ['16', ['1920px', '1080px']],
  ['14', ['1400px', '788px']],
  ['12', ['1280px', '720px']],
  ['10', ['920px', '680px']],
  ['8', ['768px', '500px']],
  ['6', ['500px', '320px']]
] as const;

const buildTypographyMediaQueries = () =>
  MEDIA.reduce((acc, [fontSize, [maxwidth, maxHeight]]) => {
    acc[`@media only screen and (max-width: ${maxwidth})`] = {
      fontSize
    };

    if (maxHeight) {
      acc[`@media only screen and (max-height: ${maxHeight})`] = {
        fontSize
      };
    }

    return acc;
  }, {} as Record<string, { fontSize: string }>);

export const TYPOGRAPHY: ITypography = {
  fonts: {
    heading: `Poppins, sans-serif`,
    body: `Poppins, sans-serif`,
    mono: `Poppins, sans-serif`
  },
  fontSizes: {
    xs: '0.5rem',
    sm: '0.75rem',
    md: '0.875rem',
    rg: '1rem',
    l: '1.25rem',
    xxl: '1.75rem'
  },
  styles: {
    global: {
      html: {
        fontSize: '16px',
        ...buildTypographyMediaQueries()
      },
      'html, body, #root': {
        height: '100%',
        width: '100%'
      },
      body: {
        background: 'gray.100'
      },
      ":root[data-theme='light']": {
        '--chakra-colors-chakra-body-text': 'colors.black',
        '--chakra-colors-chakra-border-color': 'colors.gray.300'
      },
      "input[type='date' i]::-webkit-calendar-picker-indicator": {
        backgroundImage: calendarSvg
      }
    }
  }
};
