import { PaymentPage } from 'enum';
import { getAllEnumValues } from 'enum-for';
import { PermissionResource } from 'enum/api';

import {
  PaymentMethods,
  PaymentSubscriptions,
  PaymentTransactions
} from './Pages';

export const PAYMENT_PAGE_LIST = getAllEnumValues(PaymentPage);

export const PAYMENT_PAGE: Record<
  PaymentPage,
  {
    content: () => JSX.Element;
    resource: PermissionResource;
  }
> = {
  [PaymentPage.PaymentMethod]: {
    content: PaymentMethods,
    resource: PermissionResource.PaymentMethods
  },
  [PaymentPage.Subscription]: {
    content: PaymentSubscriptions,
    resource: PermissionResource.PaymentSubscriptions
  },
  [PaymentPage.Transaction]: {
    content: PaymentTransactions,
    resource: PermissionResource.PaymentTransactions
  }
};
