import { BarDatum, BarSvgProps } from '@nivo/bar';
import { BarChartLayout } from 'enum';
import { useAppSelector } from 'hooks';
import { useTranslation } from 'react-i18next';
import { selectSettings } from 'store';

import { BarChartProps } from './BarChart.types';
import {
  getBarChartAxisProps,
  getBarChartMargins,
  getMaxDataValue
} from './BarChart.utils';
import { useBarChartTheme } from './useBarChartTheme';

export const useBarChartProps = <T extends unknown>({
  data,
  chartSettings
}: BarChartProps<T>): Omit<
  BarSvgProps<BarDatum>,
  'height' | 'width' | 'layers'
> => {
  const [t] = useTranslation();
  const theme = useBarChartTheme();

  const settings = useAppSelector(selectSettings);

  const { indexBy, colors, layout } = chartSettings;

  const axisProps = getBarChartAxisProps({
    chartSettings,
    settings,
    t
  });

  const margin = getBarChartMargins({
    data,
    axisProps,
    chartSettings,
    theme,
    settings,
    t
  });

  return {
    data,
    indexBy,
    layout,
    colors,
    enableLabel: false,
    margin,
    valueScale: {
      type: 'linear',
      nice: 8,
      min: 0,
      max: getMaxDataValue({ data }) === 0 ? 1 : undefined
    },
    indexScale: { type: 'band', round: true },
    padding: 0.5,
    enableGridX: layout === BarChartLayout.Horizontal,
    enableGridY: layout === BarChartLayout.Vertical,
    theme,
    tooltip: () => null,
    ...axisProps
  };
};
