import { ENDPOINTS } from 'api/endpoints';
import axios from 'axios';

export const fetchCreateLinkedAccount = ({
  customerId,
  email,
  comment
}: {
  customerId: string;
  email: string;
  comment: string;
}) =>
  axios.post(`${ENDPOINTS.LINKED_ACCOUNTS}/by-user/${customerId}`, {
    email,
    comment
  });
