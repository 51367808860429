import { chakra } from '@chakra-ui/react';
import { GroupBase, ValueContainerProps } from 'react-select';

import { getAttributeProperty, useStyles } from '../LocationDropdown.utils';

export const ValueContainer = <
  Option,
  IsMulti extends boolean,
  Group extends GroupBase<Option>
>(
  props: ValueContainerProps<Option, IsMulti, Group>
): JSX.Element => {
  const {
    children,
    innerProps,
    isMulti,
    hasValue,
    selectProps: { controlShouldRenderValue: shouldControlRenderValue }
  } = props;

  const styles = useStyles();

  const hasMultiValue = isMulti && hasValue && shouldControlRenderValue;

  return (
    <chakra.div
      __css={styles.valueContainer}
      {...innerProps}
      {...getAttributeProperty({ ...props, hasMultiValue })}
    >
      {children}
    </chakra.div>
  );
};
