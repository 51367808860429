import { TFunction } from 'i18next';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  differenceInDays,
  differenceInHours,
  differenceInMinutes,
  isAfter,
  isValidDate
} from 'utils';

const MINUTES_IN_HOUR = 60;
const MINUTES_IN_DAY = 1440;

const getPassedTimeString = ({
  trans,
  timeAt,
  hideBefore,
  placeholder = ''
}: {
  trans: TFunction;
  timeAt?: string;
  hideBefore?: Date;
  placeholder?: string;
}) => {
  const dateAt = timeAt && new Date(timeAt);

  if (
    !dateAt ||
    !isValidDate(dateAt) ||
    (hideBefore && isAfter(hideBefore, dateAt))
  ) {
    return placeholder;
  }

  const timeInMinutes = differenceInMinutes(new Date(), dateAt) - 60;

  switch (true) {
    case timeInMinutes < MINUTES_IN_HOUR:
      return trans('templates.x_minutes', {
        count: timeInMinutes
      });
    case timeInMinutes < MINUTES_IN_DAY:
      return trans('templates.x_hours', {
        count: differenceInHours(new Date(), dateAt)
      });
    default:
      return trans('templates.x_days', {
        count: differenceInDays(new Date(), dateAt)
      });
  }
};

type Params = {
  timeAt?: string;
  hideBefore?: Date;
  placeholder?: string;
};

export function usePassedTime(params: Params): string;
export function usePassedTime(): {
  getPassedTime: (params: Params) => string;
};

export function usePassedTime(params?: Params) {
  const { timeAt, ...options } = params ?? {};
  const [trans] = useTranslation();

  const [passedTime, setPassedTime] = useState(() =>
    timeAt
      ? getPassedTimeString({
          trans,
          timeAt,
          ...options
        })
      : ''
  );

  useEffect(() => {
    if (!timeAt) {
      return;
    }

    const updatedPassedTime = getPassedTimeString({
      trans,
      timeAt,
      ...options
    });

    if (passedTime !== updatedPassedTime) {
      setPassedTime(updatedPassedTime);
    }

    if (differenceInHours(new Date(), new Date(timeAt)) < 1) {
      const interval = setInterval(() => {
        const updatedPassedTime = getPassedTimeString({
          trans,
          timeAt,
          ...options
        });

        if (passedTime !== updatedPassedTime) {
          setPassedTime(updatedPassedTime);
        }
      }, 60_000);

      return () => clearInterval(interval);
    }
  }, [timeAt, passedTime]);

  if (!params) {
    return {
      getPassedTime: ({ timeAt, ...options }: Params) =>
        getPassedTimeString({
          trans,
          timeAt,
          ...options
        })
    };
  }

  return passedTime;
}
