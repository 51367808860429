import { HStack, Img, Radio, Text } from '@chakra-ui/react';
import { createColumnHelper } from '@tanstack/react-table';
import { AllCustomerListResponse } from 'api';
import { TFunction } from 'react-i18next';

const columnHelper = createColumnHelper<AllCustomerListResponse>();

export const customerColumns = [
  columnHelper.display({
    id: 'select',
    cell: ({ row }) => (
      <Radio
        isChecked={row.getIsSelected()}
        onChange={row.getToggleSelectedHandler()}
      />
    ),
    header: () => null,
    enableSorting: false,
    meta: {
      px: 2
    }
  }),
  columnHelper.accessor('displayedNickname', {
    cell: ({ getValue, row }) => (
      <HStack>
        <Img boxSize={6} src={row.original.programTypeImg || ''} />
        <Text>{getValue()}</Text>
      </HStack>
    ),
    header: ({ table }) => table.options.meta?.t('keywords.nickname'),
    enableSorting: false
  }),
  columnHelper.accessor('email', {
    cell: (info) => info.getValue(),
    header: ({ table }) => table.options.meta?.t('keywords.email'),
    enableSorting: false
  }),
  columnHelper.accessor('phoneNumber', {
    header: ({ table }) => table.options.meta?.t('keywords.phone_number'),
    enableSorting: false
  })
];

export const getSearchOptions = (t: TFunction) => [
  {
    label: t('keywords.nickname'),
    value: 'nickname'
  },
  {
    label: t('keywords.email'),
    value: 'email'
  },
  {
    label: t('keywords.phone_number'),
    value: 'phoneNumber'
  }
];
