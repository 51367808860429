import { useQuery } from '@tanstack/react-query';
import { fetchCustomerEmailHistory } from 'api';
import { QueryKey } from 'enum/api';

export const useCustomerEmailHistory = (id: string) =>
  useQuery(
    [QueryKey.CustomerEmailHistory, id],
    () => fetchCustomerEmailHistory(id),
    {
      retry: false,
      refetchOnWindowFocus: false,
      enabled: !!id
    }
  );
