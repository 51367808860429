import { yupResolver } from '@hookform/resolvers/yup';
import { FormConstructor, ModalContainer } from 'containers';
import { FormField, ModalType } from 'enum';
import { useModalActions } from 'hooks';
import { useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { ParamType } from 'types';

import { EditCountriesFormData } from './EditCountries.types';
import {
  editCountriesFormSchema,
  editCountriesValidationSchema
} from './EditCountries.utils';

export const EditCountries = (): JSX.Element => {
  const [t] = useTranslation();
  const [isLoading, setIsLoading] = useState(false);

  const form = useForm<EditCountriesFormData>({
    mode: 'onSubmit',
    resolver: yupResolver(editCountriesValidationSchema),
    defaultValues: {
      [FormField.Countries]: []
    }
  });

  const {
    reset,
    handleSubmit,
    formState: { isDirty }
  } = form;

  const clearComponentStates = () => {
    reset();
  };

  const { isOpen, onClose, meta } = useModalActions(ModalType.EditCountries, {
    onClose: clearComponentStates
  });

  useEffect(() => {
    if (isOpen && meta?.initialValue) {
      reset({
        [FormField.Countries]: meta.initialValue.map((value) =>
          value.toUpperCase()
        )
      });
    }

    if (!isOpen) {
      reset({
        [FormField.Countries]: []
      });
    }
  }, [isOpen]);

  const onCancel = () => {
    onClose();
    meta?.onCancel?.();
  };

  const onSubmit = handleSubmit(async (data) => {
    if (meta) {
      try {
        setIsLoading(true);
        await meta.onSubmit(data.countries);
      } catch (error) {}

      setIsLoading(false);
      onClose();
    }
  });

  const actions: ParamType<typeof ModalContainer, 'footerButtons'> = [
    {
      text: t('actions.cancel'),
      variant: 'ghostGray'
    },
    {
      text: t('actions.save_changes'),
      isLoading,
      isDisabled: !isDirty,
      isPreventClose: true,
      onClick: () => onSubmit()
    }
  ];

  return (
    <ModalContainer
      header={meta?.header}
      footerButtons={actions}
      isOpen={isOpen}
      onClose={onCancel}
      size="xl"
      modalBodyProps={{
        overflow: 'unset',
        overflowX: 'unset'
      }}
    >
      <FormProvider {...form}>
        <FormConstructor formSchema={editCountriesFormSchema} />
      </FormProvider>
    </ModalContainer>
  );
};
