import identity from 'lodash/identity';
import isEmpty from 'lodash/isEmpty';
import pickBy from 'lodash/pickBy';
import { GlobalFilters, QueryFilters } from 'types';

import { ApiGlobalFilters, ApiQueryFiltering } from './api.types';

const transformSearchIntoRawFilter = ({
  search,
  searchBy
}: Pick<QueryFilters, 'search' | 'searchBy'>): QueryFilters['filter'] => {
  if (!searchBy || !search || search.length < 2) {
    return {};
  }

  return {
    [searchBy]: search
  };
};

export const serializeQueryFilters = (
  queryFilters: QueryFilters
): ApiQueryFiltering => {
  const { pagination, search, searchBy, filter, ...rest } = queryFilters;

  return {
    ...pickBy(
      {
        ...rest,
        filter: {
          ...filter,
          ...transformSearchIntoRawFilter({
            search,
            searchBy
          })
        }
      },
      (value) => (typeof value === 'object' ? !isEmpty(value) : identity(value))
    ),
    pagination
  };
};

export const serializeGlobalFilters = (
  globalFilters: GlobalFilters
): ApiGlobalFilters => {
  const { country } = globalFilters;

  const data: ApiGlobalFilters = {
    globalCountriesFilter: country || undefined
  };

  return pickBy(data, identity);
};

export function serializeBodyFilters({
  queryFilters
}: {
  queryFilters: QueryFilters;
}): ApiQueryFiltering;
export function serializeBodyFilters({
  globalFilters
}: {
  globalFilters: GlobalFilters;
}): ApiGlobalFilters;
export function serializeBodyFilters({
  queryFilters,
  globalFilters
}: {
  queryFilters: QueryFilters;
  globalFilters: GlobalFilters;
}): ApiQueryFiltering & ApiGlobalFilters;

export function serializeBodyFilters({
  queryFilters,
  globalFilters
}: {
  queryFilters?: QueryFilters;
  globalFilters?: GlobalFilters;
}):
  | ApiQueryFiltering
  | ApiGlobalFilters
  | (ApiQueryFiltering & ApiGlobalFilters) {
  const serializedQueryFilters = queryFilters
    ? serializeQueryFilters(queryFilters)
    : {};

  const serializedGlobalFilters = globalFilters
    ? serializeGlobalFilters(globalFilters)
    : {};

  return {
    ...serializedQueryFilters,
    ...serializedGlobalFilters
  };
}
