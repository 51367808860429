import { Highlight } from '@chakra-ui/react';
import { CSSProperties } from 'react';
import { Option } from 'types';

import { MenuOptionItem } from './MenuOptionItem';

export const SelectItem = ({
  option,
  search,
  isSearchable,
  isSelected = false,
  style
}: {
  option?: Option;
  search: string;
  isSearchable: boolean;
  isSelected?: boolean;
  style?: CSSProperties;
}): JSX.Element | null => {
  if (!option) return null;

  const { value, label, color } = option;

  return (
    <MenuOptionItem
      value={value}
      aria-selected={isSelected}
      aria-readonly={isSelected}
      style={style}
      tabIndex={-1}
      color={color}
    >
      {isSearchable && search ? (
        <Highlight
          query={search}
          styles={{
            color: 'gray.500'
          }}
        >
          {label}
        </Highlight>
      ) : (
        label
      )}
    </MenuOptionItem>
  );
};
