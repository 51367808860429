import { VStack } from '@chakra-ui/react';
import { createColumnHelper } from '@tanstack/react-table';
import { PaymentSubscriptionListResponse } from 'api';
import {
  CountryList,
  ParameterTypeOptionTranslateCell,
  TruncateText
} from 'components';
import { TableFilterSchema } from 'containers';
import { InputElement } from 'enum';
import { ParameterType, PaymentSubscriptionStatus } from 'enum/api';

import { EditCountriesCell, ActionCell } from '../components';

const columnHelper = createColumnHelper<PaymentSubscriptionListResponse>();

export const paymentSubscriptionColumns = [
  columnHelper.accessor('type', {
    header: ({ table }) => table.options.meta?.t('keywords.type_name'),
    enableSorting: true
  }),
  columnHelper.accessor('title', {
    header: ({ table }) => table.options.meta?.t('keywords.display_name'),
    enableSorting: false
  }),
  columnHelper.accessor('description', {
    cell: ({ getValue }) => <TruncateText>{getValue()}</TruncateText>,
    header: ({ table }) => table.options.meta?.t('keywords.description'),
    enableSorting: false,
    meta: {
      minWidth: 40
    }
  }),
  columnHelper.accessor('programType', {
    cell: ({ getValue }) => (
      <ParameterTypeOptionTranslateCell
        optionKey={ParameterType.ProgramType}
        value={getValue()}
      />
    ),
    header: ({ table }) => table.options.meta?.t('keywords.program_type'),
    enableSorting: false
  }),
  columnHelper.accessor('duration', {
    header: ({ table }) =>
      table.options.meta?.t('keywords.subscription_duration'),
    enableSorting: true
  }),
  columnHelper.accessor('period', {
    header: ({ table }) =>
      table.options.meta?.t('keywords.subscription_period'),
    enableSorting: false
  }),
  columnHelper.accessor('currency', {
    cell: ({ getValue }) => (
      <ParameterTypeOptionTranslateCell
        optionKey={ParameterType.Currency}
        value={getValue()}
      />
    ),
    header: ({ table }) => table.options.meta?.t('keywords.currency'),
    enableSorting: false
  }),
  columnHelper.accessor('price', {
    header: ({ table }) => table.options.meta?.t('keywords.price'),
    enableSorting: true
  }),
  columnHelper.accessor('countries', {
    cell: ({ getValue }) => <CountryList countryCodes={getValue()} />,
    header: ({ table }) =>
      table.options.meta?.t('keywords.assigned_country', { count: 2 }),
    enableSorting: false,
    meta: {
      minWidth: 40
    }
  }),
  columnHelper.display({
    id: 'actions',
    cell: ({ row }) =>
      row.original.status === PaymentSubscriptionStatus.Active && (
        <VStack>
          <EditCountriesCell
            subscriptionId={row.original.id}
            countries={row.original.countries}
          />
          <ActionCell subscriptionId={row.original.id} />
        </VStack>
      ),
    header: () => null,
    enableSorting: false
  })
];

export const paymentSubscriptionTableFilters: TableFilterSchema[] = [
  {
    type: InputElement.Checkbox,
    getLabel: (t) => t('keywords.program_type'),
    optionKey: ParameterType.ProgramType,
    updateOutput: (_, value) => ({
      key: 'programTypes',
      value
    })
  },
  {
    type: InputElement.Checkbox,
    getLabel: (t) => t('keywords.currency'),
    optionKey: ParameterType.Currency,
    updateOutput: (_, value) => ({
      key: 'currencies',
      value
    })
  }
];
