import { yupResolver } from '@hookform/resolvers/yup';
import { ModalContainer } from 'containers';
import { FormConstructor } from 'containers';
import { ErrorCode, FormField, ModalType } from 'enum';
import {
  useFormValidation,
  useCreateStaticPage,
  useModalActions,
  useChakraToast
} from 'hooks';
import { snakeCase } from 'lodash';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { ParamType } from 'types';
import { serializeAxiosError } from 'utils';

import { CreateStaticPageFormData } from './CreateStaticPage.types';
import {
  createStaticPageFormSchema,
  createStaticPageValidationSchema
} from './CreateStaticPage.utils';

export const CreateStaticPage = (): JSX.Element => {
  const [t] = useTranslation();
  const toast = useChakraToast();

  const { isOpen, onClose } = useModalActions(ModalType.CreateStaticPage);

  const form = useForm<CreateStaticPageFormData>({
    mode: 'onSubmit',
    resolver: yupResolver(createStaticPageValidationSchema),
    defaultValues: {
      [FormField.IsFaqPage]: false
    }
  });

  const { handleSubmit, reset } = form;

  const { mutate, isLoading } = useCreateStaticPage();

  const isFormFilled = useFormValidation<CreateStaticPageFormData>(
    form,
    createStaticPageValidationSchema
  );

  const onSubmit = handleSubmit((data) => {
    const {
      language,
      faqItems,
      order,
      isFaqPage,
      metaDescription,
      ...restData
    } = data;

    mutate(
      {
        ...restData,
        faqItems: faqItems ? JSON.parse(faqItems) : undefined,
        languageCode: language as string,
        order: order as number,
        metaDescription: metaDescription as string
      },
      {
        onSuccess: onClose,
        onError: (error) => {
          const { errorCode } = serializeAxiosError(error);

          if (errorCode === ErrorCode.StaticPageDuplicate) {
            toast({
              title: t(`errors.codes.${snakeCase(errorCode)}`),
              status: 'error'
            });
          } else {
            toast({
              status: 'error',
              title: t('errors.invalid_form'),
              description: error.message
            });
          }
        }
      }
    );
  });

  const actions: ParamType<typeof ModalContainer, 'footerButtons'> = [
    {
      text: t('actions.cancel'),
      variant: 'ghostGray',
      disabled: isLoading
    },
    {
      text: t('actions.save'),
      colorScheme: 'secondary',
      isLoading,
      isPreventClose: true,
      isDisabled: !isFormFilled,
      onClick: () => {
        onSubmit();
        reset();
      }
    }
  ];

  return (
    <ModalContainer
      header={t('attribute.title.create_static_page')}
      footerButtons={actions}
      isOpen={isOpen}
      onClose={() => {
        onClose();
        reset();
      }}
      size="5xl"
    >
      <FormProvider {...form}>
        <FormConstructor formSchema={createStaticPageFormSchema} />
      </FormProvider>
    </ModalContainer>
  );
};
