import { BarChartData, BarChartSettings } from 'containers';
import { useMemo } from 'react';

export const useBarChartData = <T extends unknown>({
  apiData,
  chartSettings
}: {
  apiData?: T;
  chartSettings: BarChartSettings<T>;
}): BarChartData[] =>
  useMemo(() => {
    const { transformChartData } = chartSettings;

    return apiData ? transformChartData(apiData) : [];
  }, [apiData, chartSettings]);
