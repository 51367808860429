import { MenuItem } from '@chakra-ui/react';
import { EllipsisButton } from 'components';
import { ModalType } from 'enum';
import { useAppDispatch, useAppSelector } from 'hooks';
import { useTranslation } from 'react-i18next';
import { selectAuthorizedUser } from 'store';
import { openModal } from 'store/modal';
import { ICustomerAdminComments } from 'types/customer';

export const EditCommentButton = ({
  comment
}: {
  comment: ICustomerAdminComments;
}): JSX.Element | null => {
  const [t] = useTranslation();
  const dispatch = useAppDispatch();

  const currentAdmin = useAppSelector(selectAuthorizedUser);

  const { id, text, imageUrl, admin } = comment;

  if (!admin || admin.id !== currentAdmin.id) return null;

  const onDeleteComment = () => {
    dispatch(
      openModal({
        id: ModalType.DeleteAdminComment,
        meta: {
          commentId: id
        }
      })
    );
  };

  const onEditComment = () => {
    dispatch(
      openModal({
        id: ModalType.EditAdminComment,
        meta: {
          commentId: id,
          text,
          imageUrl
        }
      })
    );
  };

  return (
    <EllipsisButton>
      <MenuItem onClick={onEditComment} isDisabled={false}>
        {t('actions.edit')}
      </MenuItem>
      <MenuItem onClick={onDeleteComment} isDisabled={false}>
        {t('actions.delete')}
      </MenuItem>
    </EllipsisButton>
  );
};
