import { useQuery } from '@tanstack/react-query';
import { fetchAllMessageImages } from 'api';
import { QueryKey } from 'enum/api';
import { useAppSelector } from 'hooks';
import { selectAppFilters } from 'store/app';
import { QueryFilters } from 'types';

import { ContentFilters } from '../../../pages/Content/Content.types';

export const useAllMessageImages = (
  queryFilters: QueryFilters,
  contentFilters?: ContentFilters
) => {
  const globalFilters = useAppSelector(selectAppFilters);

  return useQuery(
    [QueryKey.AllGalleryImages, globalFilters, queryFilters, contentFilters],
    () =>
      fetchAllMessageImages({ globalFilters, queryFilters, contentFilters }),
    {
      retry: false,
      refetchOnWindowFocus: false
      // keepPreviousData: true
    }
  );
};
