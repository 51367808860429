import { createSelector } from '@reduxjs/toolkit';
import { RootState } from 'store';
import { checkIfUserExist } from 'utils';

export const selectUser = (state: RootState) => state.user;

export const selectAuthorizedUser = (state: RootState) => {
  if (checkIfUserExist(state.user)) return state.user;

  throw new Error('User is not exist');
};

export const selectIsUserAuthorized = createSelector(
  selectUser,
  ({ role }) => !!role
);

export const selectPermissions = createSelector(
  selectAuthorizedUser,
  ({ permissions }) => permissions
);
