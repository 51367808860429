import { Box, Button, VStack } from '@chakra-ui/react';
import { LabelList } from 'components';
import { ParameterType } from 'enum/api';
import { useParameterTypeMeta, useParameterTypeTranslation } from 'hooks';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { EditLabelsButton } from './EditLabelsButton';

type Props = {
  labels: string[];
  maxItems?: number;
  customerId: string;
};

export const Labels = ({
  labels,
  maxItems,
  customerId
}: Props): JSX.Element => {
  const [t] = useTranslation();

  const { getTranslation } = useParameterTypeTranslation();
  const { getOptionMetaForField } = useParameterTypeMeta();

  const [shouldShowAll, setShouldShowAll] = useState(
    !maxItems || maxItems >= labels.length
  );

  const list = (shouldShowAll ? labels : labels.slice(0, maxItems)).map(
    (item) => {
      const color = getOptionMetaForField<string>(
        ParameterType.Label,
        item,
        'color'
      );

      return {
        label: getTranslation(ParameterType.Label, item),
        color
      };
    }
  );

  return (
    <VStack spacing={1}>
      <LabelList
        list={list}
        itemProps={{
          colorScheme: 'gray',
          maxWidth: '160px',
          whiteSpace: 'normal',
          size: 'md'
        }}
      />
      <EditLabelsButton labels={labels} customerId={customerId} />
      {!shouldShowAll && (
        <Box>
          <Button
            onClick={() => {
              setShouldShowAll(true);
            }}
            variant="link"
            marginTop={3}
          >
            {t('actions.see_more')}
          </Button>
        </Box>
      )}
    </VStack>
  );
};
