import { useMutation, useQueryClient } from '@tanstack/react-query';
import { fetchDeleteArticle } from 'api';
import { QueryKey } from 'enum/api';

export const useDeleteArticle = () => {
  const queryClient = useQueryClient();

  return useMutation(fetchDeleteArticle, {
    onSuccess() {
      queryClient.invalidateQueries([QueryKey.BlogManagementList]);
    }
  });
};
