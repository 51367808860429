import { AddIcon, DeleteIcon, MinusIcon } from '@chakra-ui/icons';
import {
  FormControl,
  FormLabel,
  HStack,
  IconButton,
  Input
} from '@chakra-ui/react';
import { Dropdown, FormError } from 'components';
import { DropdownType } from 'enum';
import { ChangeEvent, FC, Fragment, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { TranslationType } from 'types';

type Props = {
  values?: string[];
  error?: string;
  labelKey: keyof TranslationType['keywords'];
  value?: string;
  onChange: (value: string | undefined) => void;
  onBlur: (value: string) => void;
  onDelete: (value: string) => Promise<void>;
  isDisabled?: boolean;
  mode?: 'use' | 'edit';
};

export const AddSelectDeleteField: FC<Props> = ({
  onBlur,
  onChange,
  onDelete,
  value,
  values = [],
  error,
  labelKey,
  isDisabled,
  mode = 'use'
}) => {
  const [t] = useTranslation();
  const [type, setType] = useState<'select' | 'input'>('input');
  const inputRef = useRef<HTMLInputElement>(null);
  const [isDeleting, setIsDeleting] = useState(false);
  const isInitialized = useRef(false);

  useEffect(() => {
    if (
      type === 'input' &&
      ((!isInitialized.current && !value) ||
        (value && values.includes(value))) &&
      values?.length
    ) {
      setType('select');
      isInitialized.current = true;
      return;
    }

    if (mode === 'edit' && type === 'select' && !values.length) {
      setType('input');
    }
  }, [values.length, value, type, values, mode]);

  const onChangeType = () => {
    onChange(undefined);
    isInitialized.current = true;
    if (type === 'input' && values.length) {
      setType('select');
    } else if (mode === 'edit') {
      setType('input');
      setTimeout(() => {
        inputRef.current?.focus();
      });
    }
  };

  const handleDelete = async (value?: string) => {
    if (!value || mode === 'use') {
      return;
    }

    setIsDeleting(true);
    try {
      await onDelete(value);
    } catch {}
    setIsDeleting(false);
  };

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    isInitialized.current = true;
    onChange(e.target.value);
  };

  return (
    <FormControl isInvalid={!!error}>
      <FormLabel>{t(`keywords.${labelKey}`)}</FormLabel>
      <HStack>
        {mode === 'edit' && type === 'input' ? (
          <Input
            isDisabled={isDisabled || isDeleting}
            onChange={handleChange}
            onBlur={() => onBlur(value || '')}
            value={value}
            ref={inputRef}
          />
        ) : (
          <Fragment>
            <Dropdown
              type={DropdownType.Select}
              isDisabled={isDisabled || isDeleting}
              isControllable
              options={values.map((value) => ({ label: value, value }))}
              values={value ? [value] : []}
              onChange={(values) => {
                onChange(values[0]);
                onBlur(values[0]);
              }}
              isInvalid={!!error}
            />
            {mode === 'edit' && (
              <IconButton
                aria-label="delete"
                icon={<DeleteIcon />}
                onClick={() => handleDelete(value)}
                size="md"
                isLoading={isDeleting}
                isDisabled={isDisabled}
              />
            )}
          </Fragment>
        )}
        {mode === 'edit' && !!values.length && (
          <IconButton
            aria-label="input"
            isDisabled={isDeleting || isDisabled}
            icon={type === 'input' ? <MinusIcon /> : <AddIcon />}
            onClick={onChangeType}
            size="md"
          />
        )}
      </HStack>
      <FormError errorKey={error} />
    </FormControl>
  );
};
