import { ENDPOINTS, ModerationTotal, ModerationTotalResponse } from 'api';
import { serializeBodyFilters } from 'api/api.utils';
import axios from 'axios';
import { GlobalFilters } from 'types';

import { serializeModerationTotalResponse } from './Moderation.utils';

export const fetchModerationItemsTotal = async ({
  globalFilters
}: {
  globalFilters: GlobalFilters;
}): Promise<ModerationTotal> => {
  const { data } = await axios.post<ModerationTotalResponse>(
    ENDPOINTS.MODERATION_TOTAL,
    serializeBodyFilters({ globalFilters })
  );

  return serializeModerationTotalResponse(data);
};
