import { Button, Link, VStack } from '@chakra-ui/react';
import { fetchArticle } from 'api';
import { ModalType } from 'enum';
import { ArticleStatus } from 'enum/api';
import { useAppDispatch, useChakraToast, useUpdateArticleStatus } from 'hooks';
import { useTranslation } from 'react-i18next';
import { openModal } from 'store';

type Props = {
  articleId: string;
  status: ArticleStatus;
  viewUrl: string;
};

export const ActionCell = ({
  articleId,
  status,
  viewUrl
}: Props): JSX.Element => {
  const [t] = useTranslation();
  const toast = useChakraToast();
  const dispatch = useAppDispatch();

  const { mutate, isLoading } = useUpdateArticleStatus();

  const onHandleStatus = (newStatus: ArticleStatus) => {
    mutate(
      {
        articleId,
        status: newStatus
      },
      {
        onError(error) {
          toast({
            status: 'error',
            title: t('errors.codes.unknown'),
            description: error.message
          });
        }
      }
    );
  };

  const onDeleteArticle = () => {
    dispatch(
      openModal({
        id: ModalType.DeleteArticle,
        meta: {
          articleId
        }
      })
    );
  };

  const onEditArticle = () => {
    (async () => {
      const article = await fetchArticle(articleId);

      if (article)
        dispatch(
          openModal({
            id: ModalType.EditArticle,
            meta: {
              article
            }
          })
        );
    })();
  };

  return (
    <VStack>
      {status === ArticleStatus.Activated ? (
        <Button
          variant="link"
          colorScheme="secondary"
          isLoading={isLoading}
          onClick={() => onHandleStatus(ArticleStatus.NotActivated)}
        >
          {t('actions.deactivate')}
        </Button>
      ) : (
        <Button
          variant="link"
          colorScheme="primary"
          isLoading={isLoading}
          onClick={() => onHandleStatus(ArticleStatus.Activated)}
        >
          {t('actions.activate')}
        </Button>
      )}
      <Button variant="link" onClick={onEditArticle} disabled={isLoading}>
        {t('actions.edit')}
      </Button>
      {status === ArticleStatus.Activated && (
        <Button as={Link} variant="link" href={viewUrl} target="_blank">
          {t('actions.view')}
        </Button>
      )}
      <Button
        variant="link"
        colorScheme="red"
        onClick={onDeleteArticle}
        disabled={isLoading}
      >
        {t('actions.delete')}
      </Button>
    </VStack>
  );
};
