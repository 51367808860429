import { Box, BoxProps, SystemStyleObject } from '@chakra-ui/react';
import { ScrollDirection } from 'enum';
import { ReactNode } from 'react';

export const ScrollableContainer = ({
  children,
  scrollDirection = ScrollDirection.Vertical,
  shouldHideScrollBar = false
}: {
  children: ReactNode;
  scrollDirection?: ScrollDirection;
  shouldHideScrollBar?: boolean;
}): JSX.Element => {
  const boxProps: BoxProps =
    scrollDirection === ScrollDirection.Vertical
      ? {
          height: '100%',
          overflowY: 'auto'
        }
      : {
          width: '100%',
          overflowX: 'auto'
        };

  const hideScrollBarProps: SystemStyleObject | undefined = shouldHideScrollBar
    ? {
        '::-webkit-scrollbar': {
          display: 'none'
        }
      }
    : undefined;

  return (
    <Box
      {...boxProps}
      sx={hideScrollBarProps}
      position="relative"
      className="scrollable-container"
    >
      {children}
    </Box>
  );
};
