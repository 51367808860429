import { InputProps, Input, Box } from '@chakra-ui/react';

export const FlexibleInput = (props: InputProps) => (
  <Box
    sx={{
      display: 'inline-grid',
      '> input': {
        width: 'auto',
        resize: 'none',
        gridArea: '1 / 2'
      },
      '&::after': {
        content: 'attr(data-value)',
        width: 'auto',
        visibility: 'hidden',
        whiteSpace: 'pre-wrap',
        gridArea: '1 / 2'
      }
    }}
    data-value={props.value}
  >
    <Input {...props} />
  </Box>
);
