import { Admin } from 'api';
import { FormField, InputElement } from 'enum';
import { getAllEnumValues } from 'enum-for';
import { OptionKey, ParameterType, SupportSourceType } from 'enum/api';
import { generateAdminListOptions } from 'pages/ContactUs/ContactUs.utils';
import { GetOptionsArg } from 'types/form';
import { generateOptionsFromSettings } from 'utils';
import * as yup from 'yup';

import { CreateTicketFormData } from './CreateTicket.types';

export const getCreateTicketFormSchema = (adminList: Admin[]) =>
  ({
    fields: {
      [FormField.SupportSourceType]: {
        type: InputElement.MultiSelect,
        translationKey: 'support_source_type',
        getPlaceholder: () => 'Select',
        getOptions: ({ t, settings }: GetOptionsArg) =>
          generateOptionsFromSettings(OptionKey.SupportSourceType, t, settings)
      },
      [FormField.Reason]: {
        type: InputElement.Select,
        translationKey: 'reason',
        getPlaceholder: () => 'Select',
        getOptions: ({ t, settings }: GetOptionsArg) =>
          generateOptionsFromSettings(
            ParameterType.ContactUsReason,
            t,
            settings
          )
      },
      [FormField.Id]: {
        type: InputElement.Select,
        isSearchable: true,
        translationKey: 'assignee',
        getPlaceholder: () => 'Select',
        getOptions: () => generateAdminListOptions(adminList)
      },
      [FormField.SourcePhoneNumber]: {
        type: InputElement.Input,
        translationKey: 'source_phone_number',
        getPlaceholder: () => '(optional)',
        maxLength: 30
      },
      [FormField.SourceNickname]: {
        type: InputElement.Input,
        translationKey: 'source_nickname',
        getPlaceholder: () => '(optional)',
        maxLength: 30
      },
      [FormField.SourceEmail]: {
        type: InputElement.Input,
        translationKey: 'source_email',
        getPlaceholder: () => '(optional)',
        maxLength: 30
      },
      [FormField.SourceUserId]: {
        type: InputElement.Input,
        translationKey: 'source_user_id',
        getPlaceholder: () => '(optional)',
        maxLength: 15
      },
      [FormField.Comment]: {
        type: InputElement.TextArea,
        translationKey: 'text_message',
        getPlaceholder: () => '',
        minHeight: 44,
        maxLength: 2000
      },
      [FormField.IsReplyFromCustomer]: {
        type: InputElement.Toggle,
        translationKey: 'reply_from_customer',
        getPlaceholder: () => 'Reply from customer'
      }
    },
    gridLayout: {
      templateAreas: `
      "${FormField.SupportSourceType} ${FormField.SupportSourceType}"
      "${FormField.Reason} ${FormField.Reason}"
      "${FormField.Id} ${FormField.Id}"
      "${FormField.SourcePhoneNumber} ${FormField.SourceNickname}"
      "${FormField.SourceEmail} ${FormField.SourceUserId}"
      "${FormField.IsReplyFromCustomer} ."
      "${FormField.Comment} ${FormField.Comment}"
    `,
      templateColumns: '1fr 1fr',
      columnGap: 3,
      rowGap: 6
    }
  } as const);

export const createTicketValidationSchema: yup.SchemaOf<CreateTicketFormData> =
  yup.object({
    [FormField.SupportSourceType]: yup
      .array(yup.mixed().oneOf(getAllEnumValues(SupportSourceType)))
      .min(1),
    [FormField.Reason]: yup.string().required(),
    [FormField.Id]: yup.string().optional(),
    [FormField.SourcePhoneNumber]: yup.string().optional(),
    [FormField.SourceNickname]: yup.string().optional(),
    [FormField.SourceEmail]: yup.string().optional(),
    [FormField.SourceUserId]: yup.string().optional(),
    [FormField.Comment]: yup.string().required(),
    [FormField.IsReplyFromCustomer]: yup.boolean().defined()
  });
