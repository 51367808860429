import { useMutation, useQueryClient } from '@tanstack/react-query';
import { CrmFiltersRequestData, fetchCrmSendEmail } from 'api';
import { QueryKey } from 'enum/api';
import { useAppSelector } from 'hooks';
import { selectAppFilters } from 'store/app';

export const useCrmSendEmail = () => {
  const queryClient = useQueryClient();
  const globalFilters = useAppSelector(selectAppFilters);

  return useMutation(
    (
      body: CrmFiltersRequestData & {
        emailTemplateId: string;
        payload: string;
      }
    ) => fetchCrmSendEmail(body, globalFilters),
    {
      onSuccess() {
        queryClient.invalidateQueries([QueryKey.CrmSendEmail]);
      }
    }
  );
};
