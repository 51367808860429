import { useQuery } from '@tanstack/react-query';
import { fetchCustomerLinkedAccounts } from 'api';
import { QueryKey } from 'enum/api';
import { QueryFilters } from 'types';

export const useCustomerLinkedAccounts = (
  customerId: string,
  queryFilters: QueryFilters
) =>
  useQuery(
    [QueryKey.CustomerLinkedAccounts, customerId, queryFilters],
    () => fetchCustomerLinkedAccounts({ customerId, queryFilters }),
    {
      retry: false,
      refetchOnWindowFocus: false,
      keepPreviousData: true
    }
  );
