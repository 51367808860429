import { VStack } from '@chakra-ui/react';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  ContentSelect,
  CustomerSelect,
  FormConstructor,
  ModalContainer
} from 'containers';
import {
  AssetType,
  ContentSection,
  FormField,
  ModalType,
  ReportType
} from 'enum';
import {
  useChakraToast,
  useCreateReport,
  useFormValidation,
  useModalActions
} from 'hooks';
import { useEffect, useMemo, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { ParamType } from 'types';

import { CreateReportFormData } from './CreateReport.types';
import {
  createReportValidationSchema,
  createReportFormSchema
} from './CreateReport.utils';

export const CreateReport = (): JSX.Element => {
  const [t] = useTranslation();
  const toast = useChakraToast();

  const form = useForm<CreateReportFormData>({
    mode: 'onSubmit',
    resolver: yupResolver(createReportValidationSchema),
    defaultValues: {
      [FormField.Comment]: '',
      [FormField.Files]: []
    }
  });

  const [reporterId, setReporterId] = useState<string | null>(null);

  const [reportedId, setReportedId] = useState<string | null>(null);

  const [source, setSource] = useState<{
    id: string;
    preview: string;
    previewType: AssetType;
    description: string;
  } | null>(null);

  const onClearForm = () => {
    form.reset();
    setReportedId(null);
    setReporterId(null);
  };

  const { isOpen, onClose, meta } = useModalActions(ModalType.CreateReport, {
    onClose: onClearForm
  });

  const { mutate, isLoading } = useCreateReport();

  const { handleSubmit } = form;

  const isFormFilled = useFormValidation<CreateReportFormData>(
    form,
    createReportValidationSchema
  );

  const type = meta?.reportType ?? form.watch(FormField.ReportType);

  useEffect(() => {
    setSource(null);
  }, [type]);

  const isRequiredDataSelected = useMemo(() => {
    switch (type) {
      case ReportType.Profile:
        return !!((reportedId ?? meta?.customerId) && !!reporterId);
      case ReportType.Gallery:
      case ReportType.Story:
        return !!((reportedId ?? meta?.customerId) && !!source && !!reporterId);
      default:
        return false;
    }
  }, [meta?.customerId, reportedId, reporterId, source, type]);

  const onSubmit = handleSubmit((data) => {
    const { comment, files, reason } = data;

    if (reason && type && isFormFilled && isRequiredDataSelected) {
      mutate(
        {
          type,
          reason,
          comment: comment ?? undefined,
          reportedId: reportedId ?? meta?.customerId,
          reporterId: reporterId ?? undefined,
          image: files[0]?.file,
          sourceId: source?.id
        },
        {
          onSuccess: onClose,
          onError(error: unknown) {
            toast({
              status: 'error',
              description:
                error instanceof Error
                  ? error.message
                  : t('errors.error_happened_while_create_report')
            });
          }
        }
      );
    } else {
      toast({
        status: 'warning',
        description: t('errors.invalid_form')
      });
    }
  });

  const actions: ParamType<typeof ModalContainer, 'footerButtons'> = [
    { text: t('actions.cancel'), variant: 'ghostGray' },
    {
      text: t('actions.create'),
      isPreventClose: true,
      isLoading,
      isDisabled: !(isFormFilled && isRequiredDataSelected),
      onClick: () => {
        onSubmit();
      }
    }
  ];

  return (
    <ModalContainer
      header={t('attribute.title.create_report')}
      footerButtons={actions}
      isOpen={isOpen}
      onClose={onClose}
      size="xl"
    >
      <VStack spacing={6} alignItems="stretch">
        <CustomerSelect
          selectedUserId={reporterId}
          onChange={setReporterId}
          title={t('keywords.reporter')}
          shouldShowFilters
        />
        {!meta?.customerId && (
          <CustomerSelect
            selectedUserId={reportedId}
            onChange={setReportedId}
            title={t('keywords.reported')}
            shouldShowFilters
          />
        )}
        <FormProvider {...form}>
          <FormConstructor
            formSchema={createReportFormSchema({
              withReportType: !!meta?.reportType
            })}
            insert={
              type && type !== ReportType.Profile
                ? [
                    {
                      afterField: FormField.ReportType,
                      node: (
                        <ContentSelect
                          marginTop={meta?.reportType ? 0 : 6}
                          contentType={
                            type === ReportType.Gallery
                              ? ContentSection.GalleryImages
                              : ContentSection.Stories
                          }
                          onChange={setSource}
                          selectedItem={source}
                          userId={reportedId ?? meta?.customerId}
                        />
                      )
                    }
                  ]
                : undefined
            }
          />
        </FormProvider>
      </VStack>
    </ModalContainer>
  );
};
