import { useQuery } from '@tanstack/react-query';
import { fetchCustomerStoryList } from 'api';
import { QueryKey } from 'enum/api';

export const useCustomerStoryList = (
  customerId: string,
  options?: { enabled?: boolean }
) =>
  useQuery(
    [QueryKey.CustomerStoryList, customerId],
    () => fetchCustomerStoryList(customerId),
    {
      ...options,
      retry: false,
      refetchOnWindowFocus: false
    }
  );
