import { Image } from '@chakra-ui/react';
import { createColumnHelper } from '@tanstack/react-table';
import { ContactUsTicketListResponse } from 'api';
import { ParameterTypeOptionTranslateCell, TruncateText } from 'components';
import { TableFilterSchema } from 'containers';
import { format } from 'date-fns';
import { InputElement } from 'enum';
import { OptionKey, ParameterType } from 'enum/api';
import { i18n } from 'i18n';
import { getName } from 'i18n-iso-countries';
import snakeCase from 'lodash/snakeCase';
import {
  ActionsCell,
  AssigneeCell,
  MessageCell,
  StatusCell
} from 'pages/ContactUs';
import {
  ICustomerAdminComments,
  ICustomerLinkedAccounts,
  ICustomerLoginHistory
} from 'types/customer';
import {
  getBaseAdminLinkNavigation,
  getBaseCustomerLinkNavigation,
  getDateFormattedString
} from 'utils';

import { NicknameCell } from './Cells';
import { EditCommentButton, EditLinkedAccountButton } from './components';

const adminCommentsColumnHelper = createColumnHelper<ICustomerAdminComments>();

export const adminCommentsColumns = [
  adminCommentsColumnHelper.accessor((row) => row.admin?.nickname, {
    id: 'nickname',
    cell: ({ table, getValue, row }) => {
      const value = getValue();
      if (value) {
        return (
          <NicknameCell
            navigateTo={getBaseAdminLinkNavigation(
              row.original.admin?.id || ''
            )}
          >
            {value}
          </NicknameCell>
        );
      }

      return table.options.meta?.t('keywords.auto_comment');
    },
    header: ({ table }) => table.options.meta?.t('keywords.author'),
    enableSorting: false
  }),
  adminCommentsColumnHelper.accessor('commentedAt', {
    cell: ({ getValue, table }) =>
      getDateFormattedString({ t: table.options.meta?.t, date: getValue() }),
    header: ({ table }) =>
      table.options.meta?.t('keywords.comment_date_and_time'),
    enableSorting: true
  }),
  adminCommentsColumnHelper.accessor('imageUrl', {
    cell: ({ getValue }) => {
      const imageUrl = getValue();

      return (
        imageUrl && (
          <Image
            boxSize={10}
            minWidth={10}
            src={imageUrl}
            borderRadius="md"
            objectFit="cover"
          />
        )
      );
    },
    header: ({ table }) => table.options.meta?.t('keywords.attached_image'),
    enableSorting: false
  }),
  adminCommentsColumnHelper.accessor('text', {
    cell: (info) => (
      <TruncateText maxWidth="md">{info.getValue()}</TruncateText>
    ),
    header: ({ table }) => table.options.meta?.t('keywords.comment_text'),
    enableSorting: false
  }),
  adminCommentsColumnHelper.display({
    id: 'more',
    header: () => null,
    cell: ({ row }) => <EditCommentButton comment={row.original} />,
    enableSorting: false
  })
];

const linkedAccountsColumnHelper =
  createColumnHelper<ICustomerLinkedAccounts>();

export const linkedAccountsColumns = [
  linkedAccountsColumnHelper.accessor('linkedAccountId', {
    cell: (info) => info.getValue(),
    header: ({ table }) => table.options.meta?.t('keywords.customer_id')
  }),
  linkedAccountsColumnHelper.accessor('email', {
    cell: (info) => info.getValue(),
    header: ({ table }) => table.options.meta?.t('keywords.email')
  }),
  linkedAccountsColumnHelper.accessor('nickname', {
    cell: ({ row, getValue }) => (
      <NicknameCell
        navigateTo={getBaseCustomerLinkNavigation(row.original.linkedAccountId)}
      >
        {getValue()}
      </NicknameCell>
    ),
    header: ({ table }) => table.options.meta?.t('keywords.nickname')
  }),
  linkedAccountsColumnHelper.accessor('lastAccessAt', {
    cell: ({ table, getValue }) => {
      const date = getValue();

      return (
        date &&
        getDateFormattedString({
          t: table.options.meta?.t,
          date
        })
      );
    },
    header: ({ table }) =>
      table.options.meta?.t('keywords.last_login_date_and_time')
  }),
  linkedAccountsColumnHelper.accessor('linkedAt', {
    cell: ({ table, getValue }) =>
      getDateFormattedString({ t: table.options.meta?.t, date: getValue() }),
    header: ({ table }) => table.options.meta?.t('keywords.account_was_linked')
  }),
  linkedAccountsColumnHelper.accessor('reason', {
    cell: ({ table, getValue }) =>
      table.options.meta?.t(
        `enum.link_account_reason.${snakeCase(getValue())}`,
        {
          defaultValue: getValue()
        }
      ),
    header: ({ table }) => table.options.meta?.t('keywords.reason'),
    enableSorting: false
  }),
  linkedAccountsColumnHelper.accessor('adminNickname', {
    cell: ({ getValue, row }) => (
      <NicknameCell
        navigateTo={getBaseAdminLinkNavigation(row.original.adminId || '')}
      >
        {getValue()}
      </NicknameCell>
    ),
    header: ({ table }) =>
      table.options.meta?.t('keywords.admin_that_linked_account')
  }),
  linkedAccountsColumnHelper.accessor('comment', {
    cell: ({ getValue }) => (
      <TruncateText maxWidth="md">{getValue()}</TruncateText>
    ),
    header: ({ table }) => table.options.meta?.t('keywords.linking_comments'),
    enableSorting: false
  }),
  linkedAccountsColumnHelper.display({
    id: 'more',
    cell: ({ row }) => <EditLinkedAccountButton linkId={row.original.id} />,
    header: () => null,
    enableSorting: false
  })
];

const loginHistoryColumnHelper = createColumnHelper<ICustomerLoginHistory>();

export const loginHistoryColumns = [
  loginHistoryColumnHelper.accessor('ip', {
    cell: (info) => info.getValue(),
    header: ({ table }) => table.options.meta?.t('keywords.ip_used_to_login'),
    enableSorting: false
  }),
  loginHistoryColumnHelper.accessor('loginAt', {
    cell: ({ getValue, table }) =>
      getDateFormattedString({ t: table.options.meta?.t, date: getValue() }),
    header: ({ table }) =>
      table.options.meta?.t('keywords.time_and_date_login'),
    enableSorting: true
  }),
  loginHistoryColumnHelper.accessor('device', {
    cell: (info) => info.getValue(),
    header: ({ table }) => table.options.meta?.t('keywords.source_login'),
    enableSorting: false
  }),
  loginHistoryColumnHelper.accessor('cookie', {
    cell: (info) => info.getValue(),
    header: ({ table }) => table.options.meta?.t('keywords.cookie'),
    enableSorting: false
  }),
  loginHistoryColumnHelper.accessor('country', {
    cell: (info) => getName(info.getValue(), i18n.language),
    header: ({ table }) => table.options.meta?.t('keywords.country'),
    enableSorting: false
  })
];

export const linkedAccountTableFilters: TableFilterSchema[] = [
  {
    type: InputElement.Checkbox,
    getLabel: (t) => t('keywords.link_account_reason'),
    optionKey: OptionKey.LinkAccountReason,
    updateOutput: (_, value) => ({
      key: 'reasons',
      value
    })
  },
  {
    type: InputElement.Range,
    getLabel: (t) => t('keywords.account_was_linked'),
    optionKey: 'linkedAtBetween',
    updateOutput: (key, value) => ({
      key,
      value: value
        .filter((date): date is string => !!date)
        .map((date) => new Date(date).toISOString())
    }),
    inputProps: {
      type: 'date',
      max: format(new Date(), 'dd.MM.yyyy')
    }
  }
];

const ticketHistoryColumnHelper =
  createColumnHelper<ContactUsTicketListResponse>();

export const ticketHistoryColumns = [
  ticketHistoryColumnHelper.accessor('status', {
    cell: ({ getValue, row }) => (
      <StatusCell status={getValue()} ticketId={row.original.id} />
    ),
    header: ({ table }) => table.options.meta?.t('keywords.status'),
    enableSorting: false
  }),
  ticketHistoryColumnHelper.accessor('assignee', {
    cell: ({ getValue, table, row }) => (
      <AssigneeCell
        ticketId={row.original.id}
        options={table.options.meta?.assigneeOptions || []}
        assignee={getValue()}
      />
    ),
    header: ({ table }) => table.options.meta?.t('keywords.assignee'),
    enableSorting: false
  }),
  ticketHistoryColumnHelper.accessor('reason', {
    cell: ({ getValue }) => (
      <ParameterTypeOptionTranslateCell
        optionKey={ParameterType.ContactUsReason}
        value={getValue()}
      />
    ),
    header: ({ table }) => table.options.meta?.t('keywords.reason'),
    enableSorting: true
  }),
  ticketHistoryColumnHelper.accessor('lastMessageComment', {
    cell: ({ getValue }) => getValue(),
    header: ({ table }) => table.options.meta?.t('keywords.comment'),
    enableSorting: false
  }),
  ticketHistoryColumnHelper.accessor('lastMessageAt', {
    cell: ({ getValue, table }) => {
      const date = getValue();

      return (
        date &&
        getDateFormattedString({
          t: table.options.meta?.t,
          date
        })
      );
    },
    header: ({ table }) => table.options.meta?.t('keywords.date_and_time'),
    enableSorting: true
  }),
  ticketHistoryColumnHelper.accessor('lastMessageSource', {
    cell: ({ getValue, table }) => {
      const source = getValue();

      return (
        source &&
        table.options.meta?.t(`enum.support_source_type.${snakeCase(source)}`, {
          defaultValue: source
        })
      );
    },
    header: ({ table }) => table.options.meta?.t('keywords.source'),
    enableSorting: true
  }),
  ticketHistoryColumnHelper.accessor('lastMessageText', {
    cell: ({ getValue, row }) => (
      <MessageCell ticketId={row.original.id}>{getValue()}</MessageCell>
    ),
    header: ({ table }) => table.options.meta?.t('keywords.last_message_text'),
    enableSorting: false
  }),
  ticketHistoryColumnHelper.display({
    id: 'actions',
    header: () => null,
    cell: ({ row }) => <ActionsCell ticketId={row.original.id} />,
    enableSorting: false
  })
];
