import { Icon, IconProps } from '@chakra-ui/react';

export const EllipsisIcon = (props: IconProps): JSX.Element => (
  <Icon viewBox="0 0 24 24" {...props}>
    <path
      d="M8.23077 11.6154C8.23077 12.5075 7.50754 13.2308 6.61538 13.2308C5.72323 13.2308 5 12.5075 5 11.6154C5 10.7232 5.72323 10 6.61538 10C7.50754 10 8.23077 10.7232 8.23077 11.6154Z"
      fill="currentColor"
    />
    <path
      d="M13.6154 11.6154C13.6154 12.5075 12.8922 13.2308 12 13.2308C11.1078 13.2308 10.3846 12.5075 10.3846 11.6154C10.3846 10.7232 11.1078 10 12 10C12.8922 10 13.6154 10.7232 13.6154 11.6154Z"
      fill="currentColor"
    />
    <path
      d="M19 11.6154C19 12.5075 18.2768 13.2308 17.3846 13.2308C16.4925 13.2308 15.7692 12.5075 15.7692 11.6154C15.7692 10.7232 16.4925 10 17.3846 10C18.2768 10 19 10.7232 19 11.6154Z"
      fill="currentColor"
    />
  </Icon>
);
