import { Checkbox, Text } from '@chakra-ui/react';
import { createColumnHelper } from '@tanstack/react-table';
import { Admin, ContactUsTicketListResponse } from 'api';
import { ParameterTypeOptionTranslateCell } from 'components';
import { TableFilterSchema } from 'containers';
import { InputElement } from 'enum';
import { OptionKey, ParameterType, SupportTicketStatus } from 'enum/api';
import { snakeCase } from 'lodash';
import { Fragment } from 'react';
import { TFunction } from 'react-i18next';
import { Option } from 'types';
import { getDateFormattedString } from 'utils';

import {
  ActionsCell,
  AssigneeCell,
  MessageCell,
  StatusCell,
  UserCell
} from './components';

const columnHelper = createColumnHelper<ContactUsTicketListResponse>();

export const contactUsColumns = [
  columnHelper.accessor((row) => row.user, {
    id: 'nickname',
    cell: ({ getValue, row }) => (
      <UserCell user={getValue()} ticketId={row.original.id} />
    ),
    header: ({ table }) => table.options.meta?.t('keywords.user_name'),
    enableSorting: true
  }),
  columnHelper.accessor('status', {
    cell: ({ getValue, row }) => (
      <StatusCell status={getValue()} ticketId={row.original.id} />
    ),
    header: ({ table }) => table.options.meta?.t('keywords.status'),
    enableSorting: false
  }),
  columnHelper.accessor('assignee', {
    cell: ({ getValue, table, row }) => (
      <AssigneeCell
        ticketId={row.original.id}
        options={table.options.meta?.assigneeOptions || []}
        assignee={getValue()}
      />
    ),
    header: ({ table }) => table.options.meta?.t('keywords.assignee'),
    enableSorting: false
  }),
  columnHelper.accessor('reason', {
    cell: ({ getValue }) => (
      <ParameterTypeOptionTranslateCell
        optionKey={ParameterType.ContactUsReason}
        value={getValue()}
      />
    ),
    header: ({ table }) => table.options.meta?.t('keywords.reason'),
    enableSorting: true
  }),
  columnHelper.accessor('lastMessageAt', {
    cell: ({ getValue, table }) => {
      const date = getValue();

      return (
        date &&
        getDateFormattedString({
          t: table.options.meta?.t,
          date
        })
      );
    },
    header: ({ table }) => table.options.meta?.t('keywords.date_and_time'),
    enableSorting: true
  }),
  columnHelper.accessor('isManuallyAdded', {
    cell: ({ getValue }) => (
      <Checkbox isChecked={getValue()} onChange={() => null} />
    ),
    header: ({ table }) =>
      table.options.meta?.t('keywords.is_manually_added', { count: 2 }),
    enableSorting: false
  }),
  columnHelper.accessor('lastMessageSource', {
    cell: ({ getValue, table, row }) => {
      const source = getValue();

      return (
        source && (
          <Fragment>
            <Text>
              {table.options.meta?.t(
                `enum.support_source_type.${snakeCase(source)}`,
                {
                  defaultValue: source
                }
              )}
            </Text>
            {row.original.sourcePhoneNumber && (
              <Text fontSize="sm">{row.original.sourcePhoneNumber}</Text>
            )}
            {row.original.sourceEmail && (
              <Text fontSize="sm">{row.original.sourceEmail}</Text>
            )}
            {row.original.sourceNickname && (
              <Text fontSize="sm">{row.original.sourceNickname}</Text>
            )}
            {row.original.sourceUserLink && (
              <Text fontSize="sm">{row.original.sourceUserLink}</Text>
            )}
          </Fragment>
        )
      );
    },
    header: ({ table }) => table.options.meta?.t('keywords.source'),
    enableSorting: true
  }),
  columnHelper.accessor('lastMessageText', {
    cell: ({ getValue, row }) => (
      <MessageCell ticketId={row.original.id}>{getValue()}</MessageCell>
    ),
    header: ({ table }) => table.options.meta?.t('keywords.last_message_text'),
    enableSorting: false
  }),
  columnHelper.accessor('lastMessageComment', {
    cell: ({ getValue }) => getValue(),
    header: ({ table }) => table.options.meta?.t('keywords.comment'),
    enableSorting: false
  }),
  columnHelper.display({
    id: 'actions',
    header: () => null,
    cell: ({ row }) => <ActionsCell ticketId={row.original.id} />,
    enableSorting: false
  })
];

export const getTagColorSchemeByStatus = (status: SupportTicketStatus) => {
  switch (status) {
    case SupportTicketStatus.Opened:
      return 'status.blue';
    case SupportTicketStatus.InProgressGeneral:
    case SupportTicketStatus.InProgressWaitAdmin:
    case SupportTicketStatus.InProgressWaitUser:
      return 'status.green';
    default:
      return 'status.gray';
  }
};

export const generateAdminListOptions = (list: Admin[]): Option[] =>
  list.map(({ id, nickname }) => ({
    label: nickname,
    value: id
  }));

export const contactUsTableFilters: TableFilterSchema[] = [
  {
    type: InputElement.Checkbox,
    getLabel: (t) => t('keywords.support_source_type'),
    optionKey: OptionKey.SupportSourceType,
    updateOutput: (_, value) => ({
      key: 'sources',
      value
    })
  },
  {
    type: InputElement.Checkbox,
    getLabel: (t) => t('keywords.support_ticket_reason'),
    optionKey: ParameterType.ContactUsReason,
    updateOutput: (_, value) => ({
      key: 'reasons',
      value
    })
  },
  {
    type: InputElement.Checkbox,
    getLabel: (t) => t('keywords.support_status_type'),
    optionKey: OptionKey.SupportStatusType,
    updateOutput: (_, value) => ({
      key: 'statuses',
      value
    })
  }
];

export const getSearchOptions = (t: TFunction) => [
  {
    label: t('keywords.nickname'),
    value: 'nickname'
  },
  {
    label: t('keywords.email'),
    value: 'email'
  },
  {
    label: t('keywords.phone_number'),
    value: 'phoneNumber'
  },
  {
    label: t('keywords.source_phone_number'),
    value: 'sourcePhoneNumber'
  }
];
