import { Button, HStack } from '@chakra-ui/react';
import { Link, Outlet } from 'react-router-dom';

export const Examples = (): JSX.Element => (
  <div>
    <HStack mt={5} spacing={3}>
      <Link to="buttons">
        <Button>Buttons</Button>
      </Link>
      <Link to="inputs">
        <Button>Inputs</Button>
      </Link>
      <Link to="checkboxes">
        <Button>Checkboxes</Button>
      </Link>
      <Link to="modal">
        <Button>Modal</Button>
      </Link>
      <Link to="table">
        <Button>Table</Button>
      </Link>
      <Link to="file">
        <Button>File loader</Button>
      </Link>
      <Link to="dropdown">
        <Button>Dropdown</Button>
      </Link>
    </HStack>
    <div>
      <Outlet />
    </div>
  </div>
);
