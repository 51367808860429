import { Box, Heading, VStack } from '@chakra-ui/react';
import { createColumnHelper } from '@tanstack/react-table';
import { Table } from 'components';

type TData = {
  id: string;
  firstName: string | null;
  lastName: string;
  disabled: boolean;
};

const ApiData: TData[] = [
  {
    id: '1',
    firstName: 'Valentina',
    lastName: 'Daryush',
    disabled: true
  },
  {
    id: '2',
    firstName: 'Aamir',
    lastName: 'Seachnall',
    disabled: false
  },
  {
    id: '3',
    firstName: null,
    lastName: 'Ery',
    disabled: false
  }
];

const columnHelper = createColumnHelper<TData>();

const columnOrder = ['lastName', 'firstName'];

const columns = [
  columnHelper.accessor('firstName', {
    cell: (info) => info.getValue(),
    header: () => 'First Name'
  }),
  columnHelper.accessor('lastName', {
    cell: (info) => info.getValue(),
    header: () => 'Last Name',
    enableSorting: false
  })
];

export const Tables = (): JSX.Element => (
  <VStack w="70%" ml="20px" spacing={3} align="flex-start" alignItems="stretch">
    <Box>
      <Heading as="h3" size="md" mt={3} mb={2}>
        Base Table
      </Heading>
    </Box>
    <Table
      data={ApiData}
      columns={columns}
      columnOrder={columnOrder}
      totalItems={20}
      getIsDisabled={(row) => row.original.disabled}
    />
  </VStack>
);
