import { createMultiStyleConfigHelpers } from '@chakra-ui/styled-system';

import { parts } from '../parts';

const { definePartsStyle } = createMultiStyleConfigHelpers(parts);

export const variantOutline = definePartsStyle(() => ({
  control: {
    border: '1px',
    borderColor: 'inherit',
    background: 'white',
    _invalid: {
      borderColor: 'error'
    },
    _focusWithin: {
      borderColor: 'primary.500'
    },
    _hover: { borderColor: 'primary.500' },
    _disabled: {
      bgColor: 'gray.100',
      color: 'gray.600',
      borderColor: 'gray.300'
    }
  },
  inputContainer: {
    visibility: 'visible',
    _disabled: {
      visibility: 'hidden'
    },
    flex: '1 1 auto',
    display: 'inline-grid',
    gridArea: '1 / 1 / 2 / 3',
    gridTemplateColumns: '0 min-content',
    color: 'inherit',

    _after: {
      content: 'attr(data-value)',
      visibility: 'hidden',
      whiteSpace: 'pre',
      gridArea: '1 / 2',
      minWidth: 0.5,
      border: 0,
      margin: 0,
      outline: 0,
      padding: 0
    }
  },
  input: {
    color: 'inherit',
    background: 0,
    opacity: 1,
    _hidden: {
      opacity: 0
    },
    width: '100%',
    gridArea: '1 / 2',
    minWidth: 0.5,
    border: 0,
    margin: 0,
    outline: 0
  }
}));
