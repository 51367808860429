import { ENDPOINTS, WithPagination } from 'api';
import { serializeBodyFilters } from 'api/api.utils';
import axios from 'axios';
import { GlobalFilters, QueryFilters } from 'types';

import { ContentFilters } from '../../pages/Content/Content.types';

import {
  MessageImageUnhandledResponse,
  MessageImageResponse
} from './Content.types';

export const fetchAllMessageImages = async ({
  queryFilters,
  globalFilters,
  contentFilters
}: {
  queryFilters: QueryFilters;
  globalFilters: GlobalFilters;
  contentFilters?: ContentFilters;
}): Promise<WithPagination<MessageImageResponse[]>> => {
  const { data } = await axios.post<
    WithPagination<MessageImageUnhandledResponse[]>
  >(ENDPOINTS.MESSAGE_IMAGES_LIST, {
    filter: contentFilters,
    ...serializeBodyFilters({ queryFilters, globalFilters })
  });

  return {
    ...data,
    data: data.data.map(
      ({ uploaded, statused, originalName, ...restData }) => ({
        ...restData,
        uploadedAt: uploaded,
        statusedAt: statused
      })
    )
  };
};
