import { GridItem } from '@chakra-ui/react';
import { FormElement } from 'containers';
import { InputElement } from 'enum';
import { Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import { MAX_NUMBER_VALUE_WITH_10_CHARACTERS } from 'utils';

import { GeneralSettingsFormField } from '../FormContent.types';

export const InstagramFollowersMinCountToAutoverify = (): JSX.Element => {
  const [t] = useTranslation();

  return (
    <Fragment>
      <GridItem>
        {t(
          'attribute.description.how_many_followers_customer_need_to_instagram_autoverify'
        )}
      </GridItem>
      <GridItem width="50%">
        <FormElement
          type={InputElement.NumberInput}
          name={GeneralSettingsFormField.CountOfFollowersToInstagramVerify}
          translationKey="count_of_followers"
          getPlaceholder={() => ''}
          min={0}
          max={MAX_NUMBER_VALUE_WITH_10_CHARACTERS}
        />
      </GridItem>
    </Fragment>
  );
};
