import { ENDPOINTS, WithPagination } from 'api';
import { serializeBodyFilters } from 'api/api.utils';
import axios from 'axios';
import { GlobalFilters, QueryFilters } from 'types';

import { ContentFilters } from '../../pages/Content/Content.types';

import { StoryResponse } from './Content.types';

export const fetchAllStories = async ({
  queryFilters,
  globalFilters,
  contentFilters
}: {
  queryFilters: QueryFilters;
  globalFilters: GlobalFilters;
  contentFilters?: ContentFilters;
}): Promise<WithPagination<StoryResponse[]>> => {
  const { data } = await axios.post<WithPagination<StoryResponse[]>>(
    ENDPOINTS.STORIES_LIST,
    {
      filter: contentFilters,
      ...serializeBodyFilters({ queryFilters, globalFilters })
    }
  );

  return data;
};
