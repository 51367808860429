import { useQuery } from '@tanstack/react-query';
import { fetchCustomerGalleryImages } from 'api';
import { QueryKey } from 'enum/api';

export const useCustomerGalleryImages = (
  customerId: string,
  options?: { enabled?: boolean }
) =>
  useQuery(
    [QueryKey.CustomerGalleryImages, customerId],
    () => fetchCustomerGalleryImages(customerId),
    {
      ...options,
      retry: false,
      refetchOnWindowFocus: false
    }
  );
