import { Spinner } from '@chakra-ui/react';
import { Table } from 'components';
import { usePaymentMethodList } from 'hooks';

import { paymentMethodColumns } from './PaymentMethods.utils';

export const PaymentMethods = (): JSX.Element => {
  const { data } = usePaymentMethodList();

  if (!data) return <Spinner />;

  return (
    <Table
      data={data}
      columns={paymentMethodColumns}
      shouldHidePagination
      totalItems={data.length}
    />
  );
};
