import { ENDPOINTS, UpdateModerationItemStatusRequestData } from 'api';
import axios, { AxiosResponse } from 'axios';

export const fetchUpdateMottoStatus = ({
  mottoId,
  ...body
}: UpdateModerationItemStatusRequestData & {
  mottoId: string;
}) =>
  axios.patch<
    unknown,
    AxiosResponse<unknown>,
    UpdateModerationItemStatusRequestData
  >(`${ENDPOINTS.MODERATION_MOTTO}/${mottoId}/status`, body);
