export const parts = [
  'rootContainer',
  'control',
  'placeholder',
  'indicatorsContainer',
  'indicatorSeparator',
  'dropdownIndicator',
  'valueContainer',
  'inputContainer',
  'input',
  'noOptionsMessage',
  'menu',
  'menuList',
  'option',
  'optionSelectedIcon',
  'singleValue',
  'multiValueContainer',
  'multiValueLabel',
  'multiValueRemove',
  'multiValue'
] as const;
