import { useQuery } from '@tanstack/react-query';
import { fetchGeneralSettings } from 'api';
import { QueryKey } from 'enum/api';

export const useGeneralSettings = (country?: string) =>
  useQuery(
    [QueryKey.GeneralSettings, country],
    () => fetchGeneralSettings({ country }),
    {
      retry: false,
      refetchOnWindowFocus: false,
      enabled: !!country
    }
  );
