import { CellContext } from '@tanstack/react-table';
import { PaymentTransactionListResponse } from 'api';
import { NavigationLink } from 'components';
import { getBaseAdminLinkNavigation } from 'utils';

type Props = CellContext<PaymentTransactionListResponse, string | undefined>;

export const AdminNicknameCell = ({
  row: { original }
}: Props): JSX.Element | null => {
  const { admin } = original;

  if (!admin) return null;

  return (
    <NavigationLink to={getBaseAdminLinkNavigation(admin.id)}>
      {admin.nickname}
    </NavigationLink>
  );
};
