import { Icon, IconProps } from '@chakra-ui/react';

export const UserIcon = (props: IconProps): JSX.Element => (
  <Icon viewBox="0 0 24 24" fill="none" {...props}>
    <circle
      r="4"
      transform="matrix(-1 0 0 1 12 7)"
      stroke="currentColor"
      strokeWidth="1.5"
    />
    <path
      d="M15.8184 20.0455L15.7123 20.7879L15.8184 20.0455ZM8.18162 20.0455L8.07556 19.303L8.18162 20.0455ZM18.25 16.9347V18.2502H19.75V16.9347H18.25ZM5.75 18.2502V16.9347H4.25V18.2502H5.75ZM16.8789 19.4394L15.9244 19.303L15.7123 20.7879L16.6667 20.9243L16.8789 19.4394ZM8.07556 19.303L7.12114 19.4394L7.33327 20.9243L8.28769 20.7879L8.07556 19.303ZM15.9244 19.303C13.3214 18.9312 10.6786 18.9312 8.07556 19.303L8.28769 20.7879C10.7501 20.4362 13.2499 20.4362 15.7123 20.7879L15.9244 19.303ZM6.60334 15.7238C10.0932 14.4774 13.9068 14.4774 17.3967 15.7238L17.9012 14.3112C14.0851 12.9483 9.91491 12.9483 6.09884 14.3112L6.60334 15.7238ZM4.25 18.2502C4.25 19.894 5.70594 21.1568 7.33327 20.9243L7.12114 19.4394C6.39746 19.5427 5.75 18.9812 5.75 18.2502H4.25ZM18.25 18.2502C18.25 18.9812 17.6025 19.5427 16.8789 19.4394L16.6667 20.9243C18.2941 21.1568 19.75 19.894 19.75 18.2502H18.25ZM19.75 16.9347C19.75 15.7574 19.0099 14.7071 17.9012 14.3112L17.3967 15.7238C17.9084 15.9065 18.25 16.3913 18.25 16.9347H19.75ZM5.75 16.9347C5.75 16.3913 6.0916 15.9065 6.60334 15.7238L6.09884 14.3112C4.99011 14.7071 4.25 15.7574 4.25 16.9347H5.75Z"
      fill="currentColor"
    />
  </Icon>
);
