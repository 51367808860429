import { Flex } from '@chakra-ui/react';
import { createColumnHelper } from '@tanstack/react-table';
import { DomainConfigListResponse } from 'api';
import { TruncateText } from 'components';
import { DEFAULT_DOMAIN_CONFIGS_NAME, getDateFormattedString } from 'utils';

import { ActionCell } from './components';

const columnHelper = createColumnHelper<DomainConfigListResponse>();

export const domainsConfigsColumns = [
  columnHelper.accessor('name', {
    header: ({ table }) => table.options.meta?.t('keywords.domain_config_name'),
    enableSorting: false
  }),
  columnHelper.accessor('domain', {
    cell: ({ getValue }) => <TruncateText>{getValue()}</TruncateText>,
    header: ({ table }) =>
      table.options.meta?.t('keywords.domain_config_domain'),
    meta: {
      maxWidth: 'md'
    },
    enableSorting: false
  }),
  columnHelper.accessor('subDir', {
    cell: ({ getValue }) => <TruncateText>{getValue()}</TruncateText>,
    header: ({ table }) =>
      table.options.meta?.t('keywords.domain_config_subdir'),
    enableSorting: false
  }),
  columnHelper.accessor('createdAt', {
    cell: ({ getValue, table }) =>
      getDateFormattedString({
        t: table.options.meta?.t,
        date: getValue()
      }),
    header: ({ table }) =>
      table.options.meta?.t('keywords.creation_date_and_time'),
    enableSorting: true
  }),
  columnHelper.accessor('welcomePageAlias', {
    cell: ({ getValue }) => <TruncateText>{getValue()}</TruncateText>,
    header: ({ table }) =>
      table.options.meta?.t('keywords.domain_config_welcome_page_alias'),
    enableSorting: false
  }),
  columnHelper.accessor('defaultTranslation', {
    cell: ({ getValue }) => <TruncateText>{getValue()}</TruncateText>,
    header: ({ table }) =>
      table.options.meta?.t('keywords.domain_config_default_translation'),
    enableSorting: false
  }),
  columnHelper.accessor('availableTranslations', {
    cell: ({ getValue }) => (
      <Flex gap={2}>
        {getValue().map((el) => (
          <TruncateText key={el}>{el}</TruncateText>
        ))}
      </Flex>
    ),
    header: ({ table }) =>
      table.options.meta?.t('keywords.domain_config_available_translation'),
    enableSorting: false
  }),
  columnHelper.display({
    id: 'actions',
    cell: ({ row }) => (
      <ActionCell
        domainConfig={row.original}
        isDefaultConfig={row.original.name === DEFAULT_DOMAIN_CONFIGS_NAME}
      />
    ),
    header: () => null,
    enableSorting: false
  })
];
