import kebabCase from 'lodash/kebabCase';
import {
  CONTENT_SECTION,
  CONTENT_SECTION_LIST
} from 'pages/Content/Content.utils';
import { Navigate, RouteObject } from 'react-router-dom';

export const CONTENT_ROUTES: RouteObject[] =
  CONTENT_SECTION_LIST.map<RouteObject>((section) => {
    const Section = CONTENT_SECTION[section];

    return {
      path: kebabCase(section),
      element: <Section />
    };
  }).concat([
    {
      index: true,
      element: <Navigate to={kebabCase(CONTENT_SECTION_LIST[0])} replace />
    }
  ]);
