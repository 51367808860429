import { Button, Wrap } from '@chakra-ui/react';
import { PermissionAction } from 'enum/api';
import { usePermissions } from 'hooks';
import snakeCase from 'lodash/snakeCase';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  getPaymentPageNameByUrl,
  PAYMENT_PAGE_LINK_BY_PAGE
} from 'routes/routes.utils';

import { PAYMENT_PAGE, PAYMENT_PAGE_LIST } from '../PaymentManagement.utils';

export const PaymentManagementTabs = (): JSX.Element => {
  const [t] = useTranslation();
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const { checkPermissions } = usePermissions();

  const selectedTab = getPaymentPageNameByUrl(pathname);

  return (
    <Wrap shouldWrapChildren spacing={3}>
      {PAYMENT_PAGE_LIST.map((tab) => (
        <Button
          key={tab}
          variant="ghost"
          colorScheme="gray"
          aria-selected={tab === selectedTab}
          isDisabled={
            !checkPermissions({
              resource: PAYMENT_PAGE[tab].resource,
              actions: PermissionAction.Read
            })
          }
          onClick={() => {
            navigate(PAYMENT_PAGE_LINK_BY_PAGE[tab]);
          }}
        >
          {t(`keywords.${snakeCase(tab)}`, {
            count: 2
          })}
        </Button>
      ))}
    </Wrap>
  );
};
