import { Dropdown } from 'components';
import { DropdownType } from 'enum';
import { useUpdateTicket } from 'hooks';
import { Option } from 'types';
import { NO_ASSIGNEE } from 'utils';

type Props = {
  ticketId: string;
  options: Option[];
  assignee: {
    id: string;
    nickname: string;
  } | null;
};

export const AssigneeCell = ({
  options,
  assignee,
  ticketId
}: Props): JSX.Element => {
  const { mutate } = useUpdateTicket();

  return (
    <Dropdown
      type={DropdownType.Select}
      options={options}
      isControllable
      isSearchable
      values={[assignee?.id || NO_ASSIGNEE]}
      onChange={([value]) => {
        mutate({
          ticketId,
          assigneeId: value === NO_ASSIGNEE ? null : value
        });
      }}
      menuButtonProps={{
        minWidth: 36
      }}
    />
  );
};
