import { useControllableState } from '@chakra-ui/react';
import { RowSelectionState } from '@tanstack/react-table';
import { ReportAdminListResponse, ReportCustomerListResponse } from 'api';
import { TableProps } from 'components';
import { useChakraToast, useUpdateReport } from 'hooks';
import isEmpty from 'lodash/isEmpty';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

const SEPARATOR = ':';

export const useMarkReport = <
  TData extends ReportCustomerListResponse | ReportAdminListResponse
>(): {
  isDisabled: boolean;
  isLoading: boolean;
  label: string;
  onHandleClick: () => void;
  tableProps: Partial<TableProps<TData>>;
} => {
  const [t] = useTranslation();
  const toast = useChakraToast();

  const [canRead, setCanRead] = useState(true);

  const [rowSelection, setRowSelection] =
    useControllableState<RowSelectionState>({
      defaultValue: {},
      onChange(value) {
        const shouldRead = Object.keys(value).every((rowId) => {
          const [, hasReadString] = rowId.split(SEPARATOR);
          const hasRead = hasReadString === 'true';

          return !hasRead;
        });

        setCanRead(shouldRead);
      }
    });

  const { mutateAsync, isLoading } = useUpdateReport();

  const isDisabled = isEmpty(rowSelection);

  const label = canRead ? t('actions.mark_read') : t('actions.mark_unread');

  const onHandleClick = async () => {
    const mutations: Promise<unknown>[] = [];

    Object.keys(rowSelection).forEach((rowId) => {
      const [reportId] = rowId.split(SEPARATOR);

      mutations.push(
        mutateAsync({
          reportId,
          hasRead: canRead
        })
      );
    });

    try {
      await Promise.all(mutations);
    } catch (e) {
      toast({
        status: 'error',
        title: t('errors.error_happened_while_update_report')
      });
    } finally {
      setRowSelection({});
    }
  };

  return {
    isDisabled,
    isLoading,
    label,
    onHandleClick,
    tableProps: {
      tableOptions: {
        enableRowSelection: true,
        enableMultiRowSelection: true,
        getRowId: (row) => `${row.id}${SEPARATOR}${row.hasRead}`
      },
      getIsSelected: (row) => !row.original.hasRead,
      rowSelection,
      onRowSelectionChange: setRowSelection
    }
  };
};
