import { Icon, IconProps } from '@chakra-ui/react';

export const MessageCircleIcon = (props: IconProps): JSX.Element => (
  <Icon viewBox="0 0 24 24" fill="none" {...props}>
    <path
      d="M3.29893 18.9895L2.59178 18.7396H2.59178L3.29893 18.9895ZM4.83018 20.5207L4.5803 19.8136H4.5803L4.83018 20.5207ZM6.81843 19.8182L7.06831 20.5253L6.81843 19.8182ZM7.68437 19.8997L7.32422 20.5576L7.68437 19.8997ZM3.97013 16.0689L3.30139 16.4084L3.97013 16.0689ZM3.75 12C3.75 7.44365 7.44365 3.75 12 3.75V2.25C6.61522 2.25 2.25 6.61522 2.25 12H3.75ZM4.63887 15.7294C4.07062 14.6102 3.75 13.3436 3.75 12H2.25C2.25 13.585 2.62876 15.0837 3.30139 16.4084L4.63887 15.7294ZM4.00608 19.2394L4.74251 17.1553L3.32821 16.6555L2.59178 18.7396L4.00608 19.2394ZM4.5803 19.8136C4.22363 19.9396 3.88005 19.596 4.00608 19.2394L2.59178 18.7396C2.04564 20.2852 3.53446 21.774 5.08005 21.2279L4.5803 19.8136ZM6.56855 19.111L4.5803 19.8136L5.08005 21.2279L7.06831 20.5253L6.56855 19.111ZM12 20.25C10.5653 20.25 9.21818 19.8844 8.04453 19.2419L7.32422 20.5576C8.71314 21.318 10.3072 21.75 12 21.75V20.25ZM20.25 12C20.25 16.5563 16.5563 20.25 12 20.25V21.75C17.3848 21.75 21.75 17.3848 21.75 12H20.25ZM12 3.75C16.5563 3.75 20.25 7.44365 20.25 12H21.75C21.75 6.61522 17.3848 2.25 12 2.25V3.75ZM7.06831 20.5253C7.1332 20.5024 7.22637 20.504 7.32422 20.5576L8.04453 19.2419C7.6108 19.0044 7.07506 18.932 6.56855 19.111L7.06831 20.5253ZM3.30139 16.4084C3.34984 16.5039 3.35029 16.5931 3.32821 16.6555L4.74251 17.1553C4.91459 16.6683 4.85424 16.1535 4.63887 15.7294L3.30139 16.4084Z"
      fill="currentColor"
    />
    <circle cx="7.54504" cy="12.0449" r="1.125" fill="currentColor" />
    <circle cx="12.0449" cy="12.0449" r="1.125" fill="currentColor" />
    <circle cx="16.5449" cy="12.0449" r="1.125" fill="currentColor" />
  </Icon>
);
