import { Grid, GridItem, VStack } from '@chakra-ui/react';
import { AddButton } from 'components';
import { FormElement } from 'containers';
import { FormField, InputElement, ModalType } from 'enum';
import { useAppDispatch } from 'hooks';
import { RemoveButton } from 'pages/GeneralSettings/components';
import { Fragment } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { openModal } from 'store';

import {
  GeneralSettingsFormData,
  GeneralSettingsFormField
} from '../../FormContent.types';
import { useSettingsFormFields } from '../../useSettingsFormFields';

import {
  getInitialFieldsState,
  warningBadWordsCustomerDescriptionByCustomerFormFields
} from './WarningBadWordsCustomerDescription.utils';

export const WarningBadWordsCustomerDescription = (): JSX.Element => {
  const [t] = useTranslation();
  const dispatch = useAppDispatch();

  const formItemName =
    GeneralSettingsFormField.WarningBadWordsInCustomerDescriptionByCustomer;

  const { fields, handleRemove, update, appendField } = useSettingsFormFields({
    name: formItemName,
    getInitialFieldsState
  });

  const { getValues } = useFormContext<GeneralSettingsFormData>();

  const onClickCustomer = (index: number) => {
    const country = getValues(GeneralSettingsFormField.Country);

    dispatch(
      openModal({
        id: ModalType.CustomerFullList,
        meta: {
          onChooseCustomer({ id: customerId, email }) {
            update(index, {
              [FormField.User]: {
                [FormField.Id]: customerId,
                [FormField.Email]: email
              },
              [FormField.BadWords]: []
            });
          },
          countryFilter: [country],
          shouldShowFilters: true
        }
      })
    );
  };

  return (
    <Fragment>
      <GridItem>
        {t(
          'attribute.description.warning_bad_words_not_be_used_in_profile_description'
        )}
      </GridItem>
      <GridItem marginBottom={10}>
        <FormElement
          type={InputElement.MultiSelect}
          name={GeneralSettingsFormField.WarningBadWordsInCustomerDescription}
          translationKey="word_other"
          getPlaceholder={() => ''}
          shouldHideOptions
          isSearchable
          canAdd
        />
      </GridItem>
      <GridItem>
        {t(
          'attribute.description.warning_bad_words_for_specific_customer_not_be_used_in_profile_description'
        )}
      </GridItem>
      <GridItem>
        <VStack
          alignItems="stretch"
          spacing={10}
          maxHeight="md"
          overflowY="auto"
        >
          {fields.map((field, index) => (
            <Grid
              key={field.id}
              templateAreas={`
                "${FormField.Id} remove"
                "${FormField.BadWords} ."
              `}
              templateColumns="1fr auto"
              columnGap={2}
              rowGap={4}
            >
              {warningBadWordsCustomerDescriptionByCustomerFormFields.map(
                (formField) => (
                  <GridItem key={formField.name} area={formField.name}>
                    <FormElement
                      key={formField.name}
                      {...formField}
                      name={`${formItemName}.${index}.${formField.name}`}
                      menuProps={{
                        onOpen: () => {
                          if (
                            formField.name ===
                            `${FormField.User}.${FormField.Email}`
                          ) {
                            onClickCustomer(index);
                          }
                        }
                      }}
                    />
                  </GridItem>
                )
              )}
              <GridItem area="remove">
                <RemoveButton onClick={() => handleRemove(index)} />
              </GridItem>
            </Grid>
          ))}
        </VStack>
        <AddButton alignSelf="flex-start" onClick={appendField} marginTop={4}>
          {t('actions.add_customer')}
        </AddButton>
      </GridItem>
    </Fragment>
  );
};
