import { yupResolver } from '@hookform/resolvers/yup';
import {
  EditDomainConfigFormData,
  FormConstructor,
  ModalContainer
} from 'containers';
import { ErrorCode, ModalType } from 'enum';
import { useChakraToast, useModalActions, useUpdateDomainConfig } from 'hooks';
import { snakeCase } from 'lodash';
import { useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { ParamType } from 'types';
import { serializeAxiosError } from 'utils';

import {
  getEditDomainConfigFormSchema,
  editDomainConfigValidationSchema,
  getDefaultFormValues,
  serializeRequestData
} from './EditDomainConfig.utils';

export const EditDomainConfig = (): JSX.Element => {
  const [t] = useTranslation();
  const toast = useChakraToast();

  const { isOpen, onClose, meta } = useModalActions(ModalType.EditDomainConfig);

  const form = useForm<EditDomainConfigFormData>({
    mode: 'onSubmit',
    resolver: yupResolver(editDomainConfigValidationSchema),
    defaultValues: getDefaultFormValues(meta?.domainConfig)
  });

  const { handleSubmit, reset } = form;

  useEffect(() => {
    if (meta?.domainConfig) {
      reset({
        ...getDefaultFormValues(meta.domainConfig)
      });
    }
  }, [reset, meta]);

  const { mutate, isLoading } = useUpdateDomainConfig();

  const onSubmit = handleSubmit((data) => {
    mutate(
      {
        domainConfigId: meta?.domainConfig.id || '',
        ...serializeRequestData(data)
      },
      {
        onSuccess: onClose,
        onError: (error) => {
          const { errorCode } = serializeAxiosError(error);

          if (errorCode === ErrorCode.ArticleDuplicate) {
            toast({
              title: t(`errors.codes.${snakeCase(errorCode)}`),
              status: 'error'
            });
          } else {
            toast({
              status: 'error',
              title: t('errors.invalid_form'),
              description: error.message
            });
          }
        }
      }
    );
  });

  const actions: ParamType<typeof ModalContainer, 'footerButtons'> = [
    {
      text: t('actions.cancel'),
      variant: 'ghostGray',
      disabled: isLoading
    },
    {
      text: t('actions.save'),
      colorScheme: 'secondary',
      isLoading,
      isPreventClose: true,
      isDisabled: false,
      onClick: () => {
        onSubmit();
      }
    }
  ];

  return (
    <ModalContainer
      header={t('attribute.title.edit_domain_config')}
      footerButtons={!meta?.isDefaultConfig ? actions : undefined}
      isOpen={isOpen}
      onClose={onClose}
      size="5xl"
    >
      <FormProvider {...form}>
        <FormConstructor
          formSchema={getEditDomainConfigFormSchema({
            isDefaultConfig: meta?.isDefaultConfig || false
          })}
        />
      </FormProvider>
    </ModalContainer>
  );
};
