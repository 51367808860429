import { Checkbox, Flex } from '@chakra-ui/react';
import { createColumnHelper } from '@tanstack/react-table';
import { ArticleListResponse } from 'api';
import { CountryList, TruncateText } from 'components';
import { TableFilterSchema } from 'containers';
import {
  BlogSection,
  InputElement,
  OptionKey,
  ProdDomains,
  StagingDomains
} from 'enum';
import { getAllEnumValues } from 'enum-for';
import { TFunction } from 'react-i18next';
import { getDateFormattedString, IS_PROD } from 'utils';

import { BlogContent, NewsContent } from './Pages';
import { ActionCell } from './components';

export const BLOG_SECTION_LIST = getAllEnumValues(BlogSection);

export const BLOG_SECTION: Record<BlogSection, () => JSX.Element> = {
  [BlogSection.Blog]: BlogContent,
  [BlogSection.News]: NewsContent
};

const columnHelper = createColumnHelper<ArticleListResponse>();

export const blogManagementColumns = [
  columnHelper.accessor('title', {
    header: ({ table }) => table.options.meta?.t('keywords.title'),
    enableSorting: true
  }),
  columnHelper.accessor('subtitle', {
    header: ({ table }) => table.options.meta?.t('keywords.subtitle'),
    enableSorting: true
  }),
  columnHelper.accessor('code', {
    cell: ({ getValue }) => getValue(),
    header: ({ table }) => table.options.meta?.t('keywords.code'),
    enableSorting: true
  }),
  columnHelper.accessor('createdAt', {
    cell: ({ getValue, table }) =>
      getDateFormattedString({
        t: table.options.meta?.t,
        date: getValue()
      }),
    header: ({ table }) =>
      table.options.meta?.t('keywords.uploading_date_and_time'),
    enableSorting: true
  }),
  columnHelper.accessor('countries', {
    cell: ({ getValue }) => <CountryList countryCodes={getValue()} />,
    header: ({ table }) => table.options.meta?.t('keywords.country'),
    enableSorting: false
  }),
  columnHelper.accessor('domains', {
    cell: ({ getValue }) => (
      <Flex gap={2}>
        {getValue().map((el) => (
          <TruncateText key={el}>{el}</TruncateText>
        ))}
      </Flex>
    ),
    header: ({ table }) => table.options.meta?.t('keywords.domains'),
    enableSorting: false
  }),
  columnHelper.accessor('language', {
    cell: ({ getValue }) => getValue(),
    header: ({ table }) => table.options.meta?.t('keywords.language'),
    enableSorting: false
  }),
  columnHelper.accessor('general', {
    cell: ({ row }) => <Checkbox isChecked={row.original.general} />,
    header: ({ table }) => table.options.meta?.t('keywords.general'),
    enableSorting: false
  }),
  columnHelper.accessor(
    ({ usersLikesCount, mockedLikesCount }) =>
      `${usersLikesCount}/${mockedLikesCount}`,
    {
      id: 'usersAndMockedArticleLikes',
      header: ({ table }) =>
        table.options.meta?.t('keywords.users_and_mocked_article_likes'),
      enableSorting: false
    }
  ),
  columnHelper.display({
    id: 'actions',
    cell: ({ row }) => {
      const viewUrl = `${
        row.original.countries.includes('NG')
          ? `${IS_PROD ? ProdDomains.Nigeria : StagingDomains.Nigeria}`
          : `${IS_PROD ? ProdDomains.Russia : StagingDomains.Russia}`
      }/${row.original.language}/${row.original.type.toLowerCase()}/${
        row.original.code
      }?preview`;

      return (
        <ActionCell
          status={row.original.status}
          articleId={row.original.id}
          viewUrl={viewUrl}
        />
      );
    },
    header: () => null,
    enableSorting: false
  })
];

export const tableFilters: TableFilterSchema[] = [
  {
    type: InputElement.Checkbox,
    getLabel: (t) => t('keywords.domain'),
    optionKey: OptionKey.DomainName,
    updateOutput: (_, value) => ({
      key: 'domains',
      value
    })
  },
  {
    type: InputElement.Checkbox,
    getLabel: (t) => t('keywords.language'),
    optionKey: OptionKey.SystemLanguage,
    updateOutput: (_, value) => ({
      key: 'languages',
      value
    })
  }
];

export const getSearchOptions = (t: TFunction) => [
  {
    label: t('keywords.all'),
    value: 'searchInput'
  }
];
