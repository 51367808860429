import { CloseIcon } from '@chakra-ui/icons';
import { chakra } from '@chakra-ui/react';
import { GroupBase, MultiValueRemoveProps } from 'react-select';

import { useStyles } from '../LocationDropdown.utils';

export const MultiValueRemove = <
  Option,
  IsMulti extends boolean,
  Group extends GroupBase<Option>
>(
  props: MultiValueRemoveProps<Option, IsMulti, Group>
): JSX.Element => {
  const { children, innerProps } = props;

  const styles = useStyles();

  return (
    <chakra.div role="button" __css={styles.multiValueRemove} {...innerProps}>
      {children || <CloseIcon />}
    </chakra.div>
  );
};
