import { useQuery } from '@tanstack/react-query';
import { fetchCustomerList } from 'api';
import { QueryKey } from 'enum/api';
import { useAppSelector } from 'hooks';
import { selectAppFilters } from 'store/app';
import { QueryFilters } from 'types';

export const useCustomerList = ({
  queryFilters,
  shouldSearchStartsWith,
  enabled
}: {
  queryFilters: QueryFilters;
  shouldSearchStartsWith: boolean;
  enabled?: boolean;
}) => {
  const globalFilters = useAppSelector(selectAppFilters);

  return useQuery(
    [QueryKey.Customers, globalFilters, queryFilters, shouldSearchStartsWith],
    () =>
      fetchCustomerList({
        globalFilters,
        queryFilters,
        shouldSearchStartsWith
      }),
    {
      retry: false,
      refetchOnWindowFocus: false,
      keepPreviousData: true,
      enabled
    }
  );
};
