import { FormField, InputElement } from 'enum';
import { OptionKey, ParameterType } from 'enum/api';
import { GetIsDisabledFnArg, GetOptionsArg } from 'types/form';
import { generateOptionsFromSettings } from 'utils';
import * as yup from 'yup';

import { RequiredCustomersChatAccessRequestFormData } from './RequiredCustomersChatAccessRequest.types';

export const requiredCustomersChatAccessRequestFormSchema = {
  fields: {
    [FormField.CustomerTypes]: {
      type: InputElement.MultiSelect,
      translationKey: 'customer_types_of_my_country',
      getPlaceholder: () => 'Customer types',
      getOptions: ({ t, settings }: GetOptionsArg) =>
        generateOptionsFromSettings(ParameterType.CustomerType, t, settings),
      getIsDisabled: ({ formValues }: GetIsDisabledFnArg) =>
        formValues[FormField.EverybodyCanMessageToEverybody]
    },
    [FormField.OtherCustomerTypes]: {
      type: InputElement.MultiSelect,
      translationKey: 'customer_types_of_another_country',
      getPlaceholder: () => 'Customer types',
      getOptions: ({ t, settings }: GetOptionsArg) =>
        generateOptionsFromSettings(ParameterType.CustomerType, t, settings),
      getIsDisabled: ({ formValues }: GetIsDisabledFnArg) =>
        formValues[FormField.EverybodyCanMessageToEverybody]
    },
    [FormField.Genders]: {
      type: InputElement.MultiSelect,
      translationKey: 'genders_of_my_country',
      getPlaceholder: () => 'Genders',
      getOptions: ({ t, settings }: GetOptionsArg) =>
        generateOptionsFromSettings(ParameterType.Gender, t, settings),
      getIsDisabled: ({ formValues }: GetIsDisabledFnArg) =>
        formValues[FormField.EverybodyCanMessageToEverybody]
    },
    [FormField.OtherGenders]: {
      type: InputElement.MultiSelect,
      translationKey: 'genders_of_another_country',
      getPlaceholder: () => 'Genders',
      getOptions: ({ t, settings }: GetOptionsArg) =>
        generateOptionsFromSettings(ParameterType.Gender, t, settings),
      getIsDisabled: ({ formValues }: GetIsDisabledFnArg) =>
        formValues[FormField.EverybodyCanMessageToEverybody]
    },
    [FormField.ProgramTypes]: {
      type: InputElement.MultiSelect,
      translationKey: 'program_types_of_my_country',
      getPlaceholder: () => 'Program types',
      getOptions: ({ t, settings }: GetOptionsArg) =>
        generateOptionsFromSettings(ParameterType.ProgramType, t, settings),
      getIsDisabled: ({ formValues }: GetIsDisabledFnArg) =>
        formValues[FormField.EverybodyCanMessageToEverybody]
    },
    [FormField.OtherProgramTypes]: {
      type: InputElement.MultiSelect,
      translationKey: 'program_types_of_another_country',
      getPlaceholder: () => 'Program types',
      getOptions: ({ t, settings }: GetOptionsArg) =>
        generateOptionsFromSettings(ParameterType.ProgramType, t, settings),
      getIsDisabled: ({ formValues }: GetIsDisabledFnArg) =>
        formValues[FormField.EverybodyCanMessageToEverybody]
    },
    [FormField.Countries]: {
      type: InputElement.MultiSelect,
      translationKey: 'countries',
      getPlaceholder: () => 'Countries',
      isSearchable: true,
      getOptions: ({ t, settings }: GetOptionsArg) =>
        generateOptionsFromSettings(OptionKey.Country, t, settings),
      getIsDisabled: ({ formValues }: GetIsDisabledFnArg) =>
        formValues[FormField.EverybodyCanMessageToEverybody]
    },
    [FormField.EverybodyCanMessageToEverybody]: {
      type: InputElement.Toggle,
      translationKey: 'everybody_can_message_to_everybody'
    }
  },
  gridLayout: {
    templateAreas: `
    "${FormField.CustomerTypes} ${FormField.Genders} ${FormField.ProgramTypes}"
    "needs-ask needs-ask needs-ask"
    "${FormField.Countries} ${FormField.Countries} ${FormField.Countries}"
    "${FormField.OtherCustomerTypes} ${FormField.OtherGenders} ${FormField.OtherProgramTypes}"
    "${FormField.EverybodyCanMessageToEverybody} ${FormField.EverybodyCanMessageToEverybody} ${FormField.EverybodyCanMessageToEverybody}"
  `,
    templateColumns: '1fr 1fr 1fr',
    columnGap: 3,
    rowGap: 6,
    textAlign: 'left'
  }
} as const;

export const requiredCustomersChatAccessRequestValidationSchema: yup.SchemaOf<RequiredCustomersChatAccessRequestFormData> =
  yup.object({
    [FormField.CustomerTypes]: yup
      .array(yup.string().required())
      .when(FormField.EverybodyCanMessageToEverybody, {
        is: true,
        then: (schema) => schema.max(0),
        otherwise: (schema) => schema.min(1)
      }),
    [FormField.OtherCustomerTypes]: yup
      .array(yup.string().required())
      .when(FormField.EverybodyCanMessageToEverybody, {
        is: true,
        then: (schema) => schema.max(0),
        otherwise: (schema) => schema.min(1)
      }),
    [FormField.Genders]: yup
      .array(yup.string().required())
      .when(FormField.EverybodyCanMessageToEverybody, {
        is: true,
        then: (schema) => schema.max(0),
        otherwise: (schema) => schema.min(1)
      }),
    [FormField.OtherGenders]: yup
      .array(yup.string().required())
      .when(FormField.EverybodyCanMessageToEverybody, {
        is: true,
        then: (schema) => schema.max(0),
        otherwise: (schema) => schema.min(1)
      }),
    [FormField.ProgramTypes]: yup
      .array(yup.string().required())
      .when(FormField.EverybodyCanMessageToEverybody, {
        is: true,
        then: (schema) => schema.max(0),
        otherwise: (schema) => schema.min(1)
      }),
    [FormField.OtherProgramTypes]: yup
      .array(yup.string().required())
      .when(FormField.EverybodyCanMessageToEverybody, {
        is: true,
        then: (schema) => schema.max(0),
        otherwise: (schema) => schema.min(1)
      }),
    [FormField.Countries]: yup
      .array(yup.string().required())
      .when(FormField.EverybodyCanMessageToEverybody, {
        is: true,
        then: (schema) => schema.max(0),
        otherwise: (schema) => schema.min(1)
      }),
    [FormField.EverybodyCanMessageToEverybody]: yup.boolean().required()
  });
