import { useMutation, useQueryClient } from '@tanstack/react-query';
import { fetchUpdateMessageImageStatus } from 'api';
import { AxiosError } from 'axios';
import { QueryKey } from 'enum/api';
import { AxiosErrorData } from 'types';

export const useUpdateMessageImageStatus = () => {
  const queryClient = useQueryClient();

  return useMutation(fetchUpdateMessageImageStatus, {
    onSuccess() {
      queryClient.invalidateQueries([QueryKey.CustomerMessageImages]);
    },
    onError(error: AxiosError<AxiosErrorData>) {}
  });
};
