import { ENDPOINTS, ParameterValueUpdateParams } from 'api';
import axios from 'axios';

export const fetchUpdateParameterValue = ({
  id,
  languageCode,
  meta,
  value
}: ParameterValueUpdateParams) =>
  axios.patch(`${ENDPOINTS.PARAMETERS_VALUES}/${id}`, {
    languageCode,
    value,
    ...(meta && { meta: JSON.stringify(meta) })
  });
