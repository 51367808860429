import { yupResolver } from '@hookform/resolvers/yup';
import { RightSideButtons } from 'components';
import {
  ContentContainer,
  FormConstructor,
  RightSide,
  ScrollableContainer
} from 'containers';
import { ErrorCode } from 'enum';
import {
  useAppSelector,
  useChakraToast,
  useCreateArticle,
  useFormValidation
} from 'hooks';
import { snakeCase } from 'lodash';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { selectSettings } from 'store';
import { serializeAxiosError } from 'utils';

import { CreateArticleFormData } from './CreateArticle.types';
import {
  createArticleFormSchema,
  createArticleValidationSchema,
  getDefaultFormValues,
  serializeRequestData
} from './CreateArticle.utils';

export const CreateArticle = (): JSX.Element => {
  const [t] = useTranslation();
  const navigate = useNavigate();
  const toast = useChakraToast();

  const settings = useAppSelector(selectSettings);

  const form = useForm<CreateArticleFormData>({
    mode: 'onSubmit',
    shouldUnregister: true,
    resolver: yupResolver(createArticleValidationSchema),
    defaultValues: getDefaultFormValues(settings)
  });

  const { handleSubmit, reset } = form;

  const isFormFilled = useFormValidation<CreateArticleFormData>(
    form,
    createArticleValidationSchema
  );

  const { mutate, isLoading } = useCreateArticle();

  const onSubmit = handleSubmit((data) => {
    mutate(serializeRequestData(data), {
      onSuccess: () => {
        reset();
        navigate(-1);
      },
      onError: (error) => {
        const { errorCode } = serializeAxiosError(error);

        if (errorCode === ErrorCode.ArticleDuplicate) {
          toast({
            title: t(`errors.codes.${snakeCase(errorCode)}`),
            status: 'error'
          });
        } else {
          toast({
            status: 'error',
            title: t('errors.invalid_form'),
            description: error.message
          });
        }
      }
    });
  });

  const handleCancel = () => {
    navigate(-1);
  };

  return (
    <ContentContainer height="100%">
      <ScrollableContainer>
        <FormProvider {...form}>
          <FormConstructor formSchema={createArticleFormSchema} />
        </FormProvider>
        <RightSide>
          <RightSideButtons
            onSubmit={onSubmit}
            isDisabledSubmit={!isFormFilled}
            onCancel={handleCancel}
            isLoading={isLoading}
            label={t('actions.save_article')}
          />
        </RightSide>
      </ScrollableContainer>
    </ContentContainer>
  );
};
