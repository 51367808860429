import { Box, Flex } from '@chakra-ui/react';
import { Logo } from 'components';
import { useLocation } from 'react-router-dom';
import { getPageNameByUrl } from 'utils';

import { NavList } from './NavList';
import { useSideNav } from './useSideNav';

export const SideNav = (): JSX.Element => {
  const { pathname } = useLocation();

  const list = useSideNav();

  return (
    <Flex
      background="black"
      as="nav"
      padding="4"
      height="100%"
      overflow="hidden"
      flexDirection="column"
    >
      <Logo marginBottom="3.125rem" color="white" />
      <NavList list={list} selected={getPageNameByUrl(pathname)} />
    </Flex>
  );
};
