import { TFunction } from 'react-i18next';

import { ErrorsFormKeys, IValidationError } from './Form.types';

export const getErrorMessage = ({
  errorKey,
  errorMessage,
  t
}: {
  errorKey?: IValidationError | string;
  errorMessage?: string;
  t: TFunction;
}): string => {
  if (errorMessage) return errorMessage;

  const defaultErrorMessage = t('errors.form.invalid');

  if (typeof errorKey === 'string') {
    return t(`errors.form.${errorKey as ErrorsFormKeys}`, {
      defaultValue: defaultErrorMessage
    });
  }

  if (typeof errorKey === 'object') {
    const { key, values } = errorKey;

    return t(`errors.form.${key}`, {
      defaultValue: defaultErrorMessage,
      ...values
    });
  }

  return defaultErrorMessage;
};
