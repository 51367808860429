import kebabCase from 'lodash/kebabCase';
import {
  MODERATION_PAGE,
  MODERATION_PAGE_LIST,
  SECTION_CONTENT
} from 'pages/Moderation/Moderation.utils';
import { Navigate, RouteObject } from 'react-router-dom';

export const MODERATION_ROUTES: RouteObject[] =
  MODERATION_PAGE_LIST.map<RouteObject>((page) => {
    const { sections, content: Content } = MODERATION_PAGE[page];

    const childrenRoutes = sections
      ? [
          ...sections.map((section) => {
            const Section = SECTION_CONTENT[section];

            return {
              path: kebabCase(section),
              element: <Section />
            };
          }),
          {
            index: true,
            element: <Navigate to={kebabCase(sections[0])} replace />
          }
        ]
      : undefined;

    return {
      path: kebabCase(page),
      element: <Content sections={sections} />,
      children: childrenRoutes
    };
  }).concat({
    index: true,
    element: <Navigate to={kebabCase(MODERATION_PAGE_LIST[0])} replace />
  });
