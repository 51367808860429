import { Icon, IconProps } from '@chakra-ui/react';

export const CreditCardIcon = (props: IconProps): JSX.Element => (
  <Icon viewBox="0 0 24 24" fill="none" {...props}>
    <rect
      x="2"
      y="4"
      width="20"
      height="16"
      rx="5"
      stroke="currentColor"
      strokeWidth="1.5"
    />
    <path
      d="M2 9.5H22"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M6 15.5H11"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </Icon>
);
