import { Spinner } from '@chakra-ui/react';
import { ModerationPostStatus } from 'enum';
import { useModerationGalleryPhoto } from 'hooks';

import { GalleryPhotoContainer } from './components';

export const GalleryPhotos = ({
  status = ModerationPostStatus.NotAccepted
}: {
  status?: ModerationPostStatus;
}): JSX.Element => {
  const { data, hasNextPage, fetchNextPage, isLoading, isFetchingNextPage } =
    useModerationGalleryPhoto(status);

  const loadMore = () => {
    if (hasNextPage) {
      fetchNextPage();
    }
  };

  if (!data?.pages?.length) return <Spinner />;

  return (
    <GalleryPhotoContainer
      data={data.pages.map(({ data }) => data).flat()}
      isLoading={isLoading || isFetchingNextPage}
      loadMore={loadMore}
    />
  );
};
