import { FormControl, FormHelperText, FormLabel } from '@chakra-ui/react';
import { FormError, MdEditor } from 'components';
import { InputElement } from 'enum';
import { PermissionAction } from 'enum/api';
import { usePermissions } from 'hooks';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { FormComponentProps } from '../FormConstructor.types';

type Props = FormComponentProps<{ type: InputElement.MdEditor }>;

export const FormMdEditor = (props: Props): JSX.Element | null => {
  const [t] = useTranslation();

  const { formState, getFieldState, control } = useFormContext();
  const { checkPermissions } = usePermissions();

  const {
    type,
    translationKey,
    name,
    hintTranslationKey,
    getPlaceholder,
    resource,
    isDisabled
  } = props;
  const { error } = getFieldState(name, formState);
  const hasUpdateAccess = resource
    ? checkPermissions({
        resource,
        actions: PermissionAction.Update
      })
    : true;

  const isEditorDisabled = isDisabled || !hasUpdateAccess;

  return (
    <FormControl isInvalid={!!error}>
      <FormLabel>{t(`keywords.${translationKey}`)}</FormLabel>
      <Controller
        control={control}
        name={name}
        render={({ field: { onChange, value } }) => (
          <MdEditor
            value={value}
            onChange={isEditorDisabled ? undefined : onChange}
            placeholder={getPlaceholder(t)}
          />
        )}
      />
      {!!hintTranslationKey && !error && (
        <FormHelperText>
          {t(`attribute.hint.${hintTranslationKey}`)}
        </FormHelperText>
      )}
      <FormError errorKey={error?.message} />
    </FormControl>
  );
};
