import { ENDPOINTS, WithPagination } from 'api';
import { serializeBodyFilters } from 'api/api.utils';
import axios from 'axios';
import { ArticleStatus, ArticleType } from 'enum/api';
import { GlobalFilters, QueryFilters } from 'types';

import { ArticleListResponse } from './BlogManagement.types';

const lorem =
  'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque at efficitur quam, quis elementum ex. Cras at maximus turpis. Proin purus ligula, fringilla ut metus vel, auctor placerat urna. Donec eget venenatis orci, id venenatis urna. Donec volutpat suscipit neque fermentum bibendum. Maecenas nunc erat, tristique vitae lorem eu, commodo molestie risus. Nam faucibus odio nec felis accumsan, at convallis mauris ullamcorper. Interdum et malesuada fames ac ante ipsum primis in faucibus. ';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const fakedData: ArticleListResponse[] = [
  {
    id: 'id_1',
    createdAt: new Date().toISOString(),
    code: 'asd123',
    title: 'Some title',
    subtitle: 'Subtitle',
    text: lorem,
    status: ArticleStatus.Activated,
    countries: [],
    general: false,
    type: ArticleType.Blog
  }
];

export const fetchArticleList = async ({
  queryFilters,
  globalFilters
}: {
  queryFilters: QueryFilters;
  globalFilters: GlobalFilters;
}): Promise<WithPagination<ArticleListResponse[]>> => {
  const { data } = await axios.post<WithPagination<ArticleListResponse[]>>(
    ENDPOINTS.ARTICLE_LIST,
    serializeBodyFilters({ queryFilters, globalFilters })
  );

  return data;
};
