import { TableManagementSettings } from 'api';
import { Label } from 'enum';
import { ParameterType } from 'enum/api';

const LABELS_COLOR: Record<Label, string> = {
  [Label.NewUser]: '#573279',
  [Label.EmailVerified]: '#fd236a',
  [Label.EmailNotVerified]: '#d9c319',
  [Label.NewUserNotFullyRegistered]: '#b025e5',
  [Label.SuspendedIn]: '#e2e9a0',
  [Label.NewUserWithNoMessage]: '#9f4462',
  [Label.SecondWarning]: '#c353ea',
  [Label.PhoneVerified]: '#a54895',
  [Label.PhotoVerified]: '#774d38',
  [Label.IdVerified]: '#c1d449',
  [Label.Blocked]: '#abfb57',
  [Label.Suspended]: '#0aebbe',
  [Label.NoFollowUpCorrespondence]: '#37088e',
  [Label.FirstWarning]: '#37088e',
  [Label.NoPhoneLeft]: '#38109a',
  [Label.TransactionDenial]: '#bfa653',
  [Label.NoPhoneEntered]: '#bfa653',
  [Label.ForFollowUp]: '#461f3a',
  [Label.PhoneVerification]: '#2f469c',
  [Label.PhoneNotVerified]: '#3d7fb7',
  [Label.ImagesInTrace]: '#de0619',
  [Label.TestingProfile]: '#68f17f',
  [Label.ImagesBlock]: '#be6d74',
  [Label.InstagramNotVerified]: '#F56040',
  [Label.InstagramVerificationInProcess]: '#4f5bd5',
  [Label.InstagramVerified]: '#bc2e9d',
  [Label.InstagramVerificationFailed]: '#bfa653'
};

export const serializeTMSettings = (
  data: TableManagementSettings
): TableManagementSettings => ({
  ...data,
  [ParameterType.Label]: data[ParameterType.Label].map((option) => {
    const { meta, systemId } = option;

    const color = LABELS_COLOR[systemId as Label];

    const newMeta = color
      ? {
          ...meta,
          color
        }
      : meta;

    return {
      ...option,
      meta: newMeta
    };
  })
});
