import { createColumnHelper } from '@tanstack/react-table';
import { PaymentTransactionListResponse } from 'api';
import { ParameterTypeOptionTranslateCell, TruncateText } from 'components';
import { TableFilterSchema } from 'containers';
import { format, formatISO } from 'date-fns';
import { InputElement } from 'enum';
import { OptionKey, ParameterType } from 'enum/api';
import { getDateFormattedString, isEmpty } from 'utils';

import {
  AdminNicknameCell,
  EditTransactionCell,
  NicknameCell
} from './components';

const columnHelper = createColumnHelper<PaymentTransactionListResponse>();

export const paymentTransactionColumns = [
  columnHelper.accessor('id', {
    header: ({ table }) => table.options.meta?.t('keywords.transaction_id'),
    enableSorting: false
  }),
  columnHelper.accessor((row) => row.admin?.nickname, {
    id: 'admin',
    cell: AdminNicknameCell,
    header: ({ table }) => table.options.meta?.t('keywords.admin_nickname'),
    enableSorting: false
  }),
  columnHelper.accessor('createdAt', {
    cell: ({ getValue, table }) =>
      getDateFormattedString({
        t: table.options.meta?.t,
        date: getValue()
      }),
    header: ({ table }) => table.options.meta?.t('keywords.date_and_time'),
    enableSorting: true
  }),
  columnHelper.accessor((row) => row.user.displayedNickname, {
    id: 'nickname',
    cell: ({ getValue, row }) => (
      <NicknameCell customerId={row.original.user.id}>
        {getValue()}
      </NicknameCell>
    ),
    header: ({ table }) => table.options.meta?.t('keywords.nickname'),
    enableSorting: true
  }),
  columnHelper.accessor('paymentMethodDisplayName', {
    header: ({ table }) => table.options.meta?.t('keywords.display_name'),
    enableSorting: false
  }),
  columnHelper.accessor('programType', {
    cell: ({ getValue }) => (
      <ParameterTypeOptionTranslateCell
        optionKey={ParameterType.ProgramType}
        value={getValue()}
      />
    ),
    header: ({ table }) =>
      table.options.meta?.t('keywords.subscription_program_type'),
    enableSorting: false
  }),
  columnHelper.accessor('subscriptionType', {
    header: ({ table }) =>
      table.options.meta?.t('keywords.subscription_type_name'),
    enableSorting: false
  }),
  columnHelper.accessor('amount', {
    header: ({ table }) => table.options.meta?.t('keywords.sum_paid'),
    enableSorting: true
  }),
  columnHelper.accessor('status', {
    header: ({ table }) => table.options.meta?.t('keywords.status'),
    enableSorting: false
  }),
  columnHelper.accessor('source', {
    header: ({ table }) => table.options.meta?.t('keywords.source'),
    enableSorting: false
  }),
  columnHelper.accessor('currency', {
    cell: ({ getValue }) => (
      <ParameterTypeOptionTranslateCell
        optionKey={ParameterType.Currency}
        value={getValue()}
      />
    ),
    header: ({ table }) => table.options.meta?.t('keywords.currency_paid'),
    enableSorting: false
  }),
  columnHelper.accessor('comment', {
    cell: ({ getValue }) => <TruncateText>{getValue()}</TruncateText>,
    header: ({ table }) =>
      table.options.meta?.t('keywords.transaction_comment'),
    enableSorting: false,
    meta: {
      minWidth: 40
    }
  }),
  columnHelper.display({
    id: 'actions',
    cell: ({ row }) => (
      <EditTransactionCell
        transactionId={row.original.id}
        comment={row.original.comment}
      />
    ),
    header: () => null,
    enableSorting: false
  })
];

export const paymentTransactionTableFilters: TableFilterSchema[] = [
  {
    type: InputElement.Range,
    getLabel: (t) => t('keywords.date_and_time'),
    optionKey: 'createdAtBetween',
    updateOutput: (key, value) => ({
      key,
      value: value
        .filter((date): date is string => !!date)
        .map((date, idx) => {
          const isLastItem = idx === value.length - 1;

          return isLastItem
            ? //date max range set to end of day to filter data by specific date
              formatISO(new Date(date).setHours(23, 59, 59))
            : new Date(date).toISOString();
        })
    }),
    inputProps: {
      type: 'date',
      max: format(new Date(), 'dd.MM.yyyy')
    }
  },
  {
    type: InputElement.Range,
    getLabel: (t) => t('keywords.sum_paid'),
    optionKey: 'amountBetween',
    updateOutput: (key, values) => ({
      key,
      value: values.map((value) =>
        isEmpty(value) ? null : parseFloat(value as string)
      )
    }),
    inputProps: {
      type: 'number',
      precision: 2,
      min: 0
    }
  },
  {
    type: InputElement.Checkbox,
    getLabel: (t) => t('keywords.payment_method'),
    optionKey: OptionKey.PaymentMethod,
    updateOutput: (_, value) => ({
      key: 'paymentMethods',
      value
    })
  },
  {
    type: InputElement.Checkbox,
    getLabel: (t) => t('keywords.program_type'),
    optionKey: ParameterType.ProgramType,
    updateOutput: (_, value) => ({
      key: 'programTypes',
      value
    })
  },
  {
    type: InputElement.Checkbox,
    getLabel: (t) => t('keywords.subscription_type'),
    optionKey: OptionKey.SubscriptionType,
    updateOutput: (_, value) => ({
      key: 'subscriptionTypes',
      value
    })
  },
  {
    type: InputElement.Checkbox,
    getLabel: (t) => t('keywords.currency'),
    optionKey: ParameterType.Currency,
    updateOutput: (_, value) => ({
      key: 'currencies',
      value
    })
  },
  {
    type: InputElement.Checkbox,
    getLabel: (t) => t('keywords.status'),
    optionKey: OptionKey.TransactionStatus,
    updateOutput: (_, value) => ({
      key: 'statuses',
      value
    })
  },
  {
    type: InputElement.Checkbox,
    getLabel: (t) => t('keywords.source'),
    optionKey: OptionKey.TransactionSource,
    updateOutput: (_, value) => ({
      key: 'sources',
      value
    })
  }
];
