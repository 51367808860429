import { useMutation, useQueryClient } from '@tanstack/react-query';
import { fetchUpdateMottoStatus } from 'api';
import { AxiosError } from 'axios';
import { QueryKey } from 'enum/api';
import { AxiosErrorData } from 'types';

export const useUpdateMottoStatus = () => {
  const queryClient = useQueryClient();

  return useMutation(fetchUpdateMottoStatus, {
    onSuccess() {
      queryClient.invalidateQueries([QueryKey.ModerationMotto]);
      queryClient.invalidateQueries([QueryKey.ModerationTotal]);
    },
    onError(error: AxiosError<AxiosErrorData>) {}
  });
};
