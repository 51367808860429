import { yupResolver } from '@hookform/resolvers/yup';
import { FormConstructor, ModalContainer } from 'containers';
import { ErrorCode, ModalType } from 'enum';
import { useModalActions, useChakraToast, useCrmSendEmail } from 'hooks';
import { snakeCase } from 'lodash';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { ParamType } from 'types';
import { serializeAxiosError } from 'utils';

import { SendEmailFormData } from './SendEmail.types';
import {
  sendEmailFormSchema,
  sendEmailValidationSchema
} from './SendEmail.utils';

export const SendEmail = (): JSX.Element => {
  const [t] = useTranslation();
  const toast = useChakraToast();

  const form = useForm<SendEmailFormData>({
    mode: 'onSubmit',
    resolver: yupResolver(sendEmailValidationSchema)
  });

  const {
    reset,
    handleSubmit,
    formState: { isDirty }
  } = form;

  const { mutate, isLoading, reset: resetSendEmail } = useCrmSendEmail();

  const clearComponentStates = () => {
    resetSendEmail();
    reset();
  };

  const { isOpen, onClose, meta } = useModalActions(ModalType.SendEmail, {
    onClose: clearComponentStates
  });

  const onSubmit = handleSubmit((data) => {
    if (meta) {
      try {
        JSON.parse(data.emailTemplatePayload || '');
      } catch (_err) {
        toast({
          status: 'error',
          title: t('errors.invalid_json')
        });
      }

      mutate(
        {
          ...meta,
          emailTemplateId: data.emailTemplateId,
          payload: data.emailTemplatePayload
            ? JSON.stringify(JSON.parse(data.emailTemplatePayload))
            : '{}'
        },
        {
          onSuccess: onClose,
          onError: (error) => {
            const { errorCode } = serializeAxiosError(error);

            if (errorCode === ErrorCode.EmailTemplateNotValid) {
              toast({
                title: `${t(`errors.codes.${snakeCase(errorCode)}`)}`,
                status: 'error'
              });
            } else {
              toast({
                status: 'error',
                title: t('errors.invalid_form'),
                description: error.message
              });
            }
          }
        }
      );
    }
  });

  const actions: ParamType<typeof ModalContainer, 'footerButtons'> = [
    {
      text: t('actions.cancel'),
      variant: 'ghostGray'
    },
    {
      text: t('actions.send'),
      isLoading,
      isDisabled: !isDirty,
      isPreventClose: true,
      onClick: () => onSubmit()
    }
  ];

  return (
    <ModalContainer
      header={t('actions.send_email', { count: 2 })}
      footerButtons={actions}
      isOpen={isOpen}
      onClose={onClose}
      size="xl"
      modalBodyProps={{
        overflow: 'unset',
        overflowX: 'unset'
      }}
    >
      <FormProvider {...form}>
        <FormConstructor formSchema={sendEmailFormSchema} />
      </FormProvider>
    </ModalContainer>
  );
};
