import { ENDPOINTS } from 'api/endpoints';
import axios from 'axios';

import { ResetPasswordsResponse } from './AdminManagement.types';

export const fetchResetAllAdminPasswords =
  async (): Promise<ResetPasswordsResponse> => {
    const { data } = await axios.get<ResetPasswordsResponse>(
      ENDPOINTS.ADMINS_RESET_PASSWORD
    );

    return data;
  };
