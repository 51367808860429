import { GridItem } from '@chakra-ui/react';
import { FormElement } from 'containers';
import { InputElement } from 'enum';
import { Fragment } from 'react';
import { useTranslation } from 'react-i18next';

import { GeneralSettingsFormField } from '../FormContent.types';

export const ContactUsTelegramLink = (): JSX.Element => {
  const [t] = useTranslation();

  return (
    <Fragment>
      <GridItem>{t('attribute.description.contact_us_telegram_link')}</GridItem>
      <GridItem>
        <FormElement
          type={InputElement.Input}
          name={GeneralSettingsFormField.ContactUsTelegramLink}
          translationKey="link"
          getPlaceholder={() => 'Link'}
          maxLength={90}
        />
      </GridItem>
    </Fragment>
  );
};
