import {
  AspectRatio,
  Box,
  Image,
  Text,
  VStack,
  chakra
} from '@chakra-ui/react';
import { AssetType } from 'enum';
import { MouseEventHandler } from 'react';

type Props = {
  preview: string;
  previewType: AssetType;
  description: string;
  onClick: () => void;
  isSelected: boolean;
};

export const ContentCard = ({
  preview,
  previewType,
  description,
  onClick,
  isSelected
}: Props): JSX.Element => {
  const preventEvent: MouseEventHandler<HTMLVideoElement | HTMLImageElement> = (
    e
  ) => {
    e.preventDefault();
  };

  return (
    <VStack
      padding={4}
      borderRadius="1.25rem"
      border="1px"
      borderColor={isSelected ? 'blue.500' : 'gray.300'}
      display="inline-block"
      width={64}
      spacing={3}
      role="button"
      onClick={onClick}
    >
      <Box
        position="relative"
        userSelect="none"
        width="100%"
        borderRadius="xl"
        overflow="hidden"
      >
        <AspectRatio ratio={1} flex={1}>
          {previewType === AssetType.Video ? (
            <chakra.video
              src={`${preview}#t=0.1`}
              controls
              controlsList="nodownload"
              onContextMenu={preventEvent}
              onClick={preventEvent}
            />
          ) : (
            <Image src={preview} onContextMenu={preventEvent} />
          )}
        </AspectRatio>
      </Box>
      <Text textStyle="srg" color="subtext">
        {description}
      </Text>
    </VStack>
  );
};
