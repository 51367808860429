import { ENDPOINTS } from 'api';
import axios from 'axios';

import { UpdateStaticPageRequest } from '../../pages/StaticPages/types';

export const fetchUpdateStaticPage = async (
  data: UpdateStaticPageRequest & { id: string }
): Promise<void> => {
  const { id, ...restData } = data;

  await axios.patch(`${ENDPOINTS.STATIC_PAGES}/${id}`, { ...restData });
};
