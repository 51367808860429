import { yupResolver } from '@hookform/resolvers/yup';
import { FormConstructor, ModalContainer } from 'containers';
import { FormField, ModalType } from 'enum';
import { SupportSourceType } from 'enum/api';
import {
  useChakraToast,
  useFormValidation,
  useModalActions,
  useTicketReply
} from 'hooks';
import snakeCase from 'lodash/snakeCase';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { ParamType } from 'types';
import { serializeAxiosError } from 'utils';

import { ReplyCustomerFormData } from './ReplyCustomer.types';
import {
  replyCustomerFormSchema,
  replyCustomerValidationSchema
} from './ReplyCustomer.utils';

export const ReplyCustomer = (): JSX.Element => {
  const [t] = useTranslation();
  const toast = useChakraToast();

  const form = useForm<ReplyCustomerFormData>({
    mode: 'onSubmit',
    resolver: yupResolver(replyCustomerValidationSchema),
    defaultValues: {
      [FormField.Comment]: '',
      [FormField.IsReplyFromCustomer]: false
    }
  });

  const { isOpen, onClose, meta } = useModalActions(ModalType.ReplyCustomer, {
    onClose: form.reset
  });

  const { mutate, isLoading } = useTicketReply();

  const { handleSubmit } = form;

  const isFormFilled = useFormValidation<ReplyCustomerFormData>(
    form,
    replyCustomerValidationSchema
  );

  const onSubmit = handleSubmit((data) => {
    if (meta) {
      mutate(
        {
          ticketId: meta.ticketId,
          source: data[FormField.SupportSourceType] as SupportSourceType,
          text: data[FormField.Comment] as string,
          isReplyFromCustomer: data[FormField.IsReplyFromCustomer]
        },
        {
          onSuccess: onClose,
          onError(error) {
            const { errorCode } = serializeAxiosError(error);

            toast({
              title: t(`errors.codes.${snakeCase(errorCode)}`),
              status: 'error'
            });
          }
        }
      );
    }
  });

  const actions: ParamType<typeof ModalContainer, 'footerButtons'> = [
    { text: t('actions.cancel'), variant: 'ghostGray' },
    {
      text: t('actions.reply'),
      isPreventClose: true,
      isLoading,
      isDisabled: !isFormFilled,
      onClick: () => {
        onSubmit();
      }
    }
  ];

  return (
    <ModalContainer
      header={t('attribute.title.reply_customer')}
      footerButtons={actions}
      isOpen={isOpen}
      onClose={onClose}
    >
      <FormProvider {...form}>
        <FormConstructor formSchema={replyCustomerFormSchema} />
      </FormProvider>
    </ModalContainer>
  );
};
