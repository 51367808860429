import { Checkbox, Image } from '@chakra-ui/react';
import { createColumnHelper } from '@tanstack/react-table';
import { ReportAdminListResponse } from 'api';
import { TruncateText } from 'components';
import { TableFilterSchema } from 'containers';
import { format } from 'date-fns';
import { InputElement, ModalType } from 'enum';
import { OptionKey } from 'enum/api';
import snakeCase from 'lodash/snakeCase';
import { ActionCell, StatusCell } from 'pages/Reports/components';
import { UserCell } from 'pages/Reports/components/UserCell';
import { TFunction } from 'react-i18next';

const columnHelper = createColumnHelper<ReportAdminListResponse>();

export const reportAdminColumns = [
  columnHelper.display({
    id: 'checkbox',
    cell: ({ row }) => (
      <Checkbox
        isChecked={row.getIsSelected()}
        onChange={row.getToggleSelectedHandler()}
      />
    ),
    header: ({ table }) => (
      <Checkbox
        isChecked={table.getIsAllRowsSelected()}
        isIndeterminate={table.getIsSomeRowsSelected()}
        onChange={table.getToggleAllRowsSelectedHandler()}
      />
    ),
    enableSorting: false
  }),
  columnHelper.accessor('reported', {
    cell: ({ getValue }) => <UserCell user={getValue()} />,
    header: ({ table }) => table.options.meta?.t('keywords.reported'),
    enableSorting: false
  }),
  columnHelper.accessor('reason', {
    cell: ({ getValue, table }) =>
      table.options.meta?.t(
        `enum.admin_report_reason.${snakeCase(getValue())}`
      ),
    header: ({ table }) => table.options.meta?.t('keywords.reason'),
    enableSorting: true
  }),
  columnHelper.accessor('comment', {
    cell: ({ getValue }) => (
      <TruncateText maxWidth="md">{getValue()}</TruncateText>
    ),
    header: ({ table }) => table.options.meta?.t('keywords.report_text'),
    enableSorting: false,
    meta: {
      minWidth: 'xs'
    }
  }),
  columnHelper.accessor('imageUrl', {
    cell: ({ getValue }) => (
      <Image boxSize={10} minWidth={10} src={getValue()} borderRadius="md" />
    ),
    header: () => null,
    enableSorting: false
  }),
  columnHelper.accessor('status', {
    cell: ({ getValue, row }) => (
      <StatusCell status={getValue()} reportId={row.original.id} />
    ),
    header: ({ table }) => table.options.meta?.t('keywords.status'),
    enableSorting: false
  }),
  columnHelper.accessor('createdAt', {
    cell: ({ getValue }) => format(new Date(getValue()), 'HH:mm, dd.MM.yyyy'),
    header: ({ table }) => table.options.meta?.t('keywords.date_and_time'),
    enableSorting: true
  }),
  columnHelper.display({
    id: 'actions',
    cell: ({ row }) => (
      <ActionCell
        data={row.original}
        modalType={ModalType.AdminReportDetails}
      />
    ),
    header: () => null,
    enableSorting: false
  })
];

export const adminReportsTableFilters: TableFilterSchema[] = [
  {
    type: InputElement.Checkbox,
    getLabel: (t) => t('keywords.report_reason'),
    optionKey: OptionKey.AdminReportReason,
    updateOutput: (_, value) => ({
      key: 'reasons',
      value
    })
  },
  {
    type: InputElement.Checkbox,
    getLabel: (t) => t('keywords.report_status'),
    optionKey: OptionKey.ReportStatus,
    updateOutput: (_, value) => ({
      key: 'statuses',
      value
    })
  }
];

export const getSearchOptions = (t: TFunction) => [
  {
    label: t('keywords.nickname'),
    value: 'nickname'
  }
];
