import { ENDPOINTS } from 'api/endpoints';
import axios from 'axios';

import { StopRenewSubscription } from './Payments.types';

export const fetchStopRenewSubscription = ({
  customerId,
  comment
}: StopRenewSubscription) =>
  axios.post(`${ENDPOINTS.PAYMENT_SUBSCRIPTION}/stop/${customerId}`, {
    comment
  });
