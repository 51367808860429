import { TruncateText } from 'components';
import { PopupDisplayPage } from 'enum/api';
import snakeCase from 'lodash/snakeCase';
import { useTranslation } from 'react-i18next';

type Props = {
  pages: PopupDisplayPage[];
};

export const PagesCell = ({ pages }: Props): JSX.Element => {
  const [t] = useTranslation();

  return (
    <TruncateText maxWidth={64}>
      {pages
        .map((popupPage) =>
          t(`enum.popup_display_page.${snakeCase(popupPage)}`)
        )
        .join(', ')}
    </TruncateText>
  );
};
