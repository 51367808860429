import { Grid, GridItem } from '@chakra-ui/react';
import { LocaleButton } from 'components';

import { CountryFilter } from './CountryFilter';
import { ProfileMenu } from './ProfileMenu';
import { Title } from './Title';

export const Header = (): JSX.Element => (
  <Grid
    templateAreas={`"title country locale avatar"`}
    gridTemplateColumns="1fr min(auto, 28rem) auto auto"
    columnGap="3"
    height="100%"
    alignItems="center"
    paddingY={4}
  >
    <GridItem area="title">
      <Title />
    </GridItem>
    <GridItem area="country">
      <CountryFilter />
    </GridItem>
    <GridItem area="locale">
      <LocaleButton />
    </GridItem>
    <GridItem area="avatar">
      <ProfileMenu />
    </GridItem>
  </Grid>
);
