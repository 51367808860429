import { Box, Text, VStack } from '@chakra-ui/react';

import { CustomStackedBarProps } from '../../CustomStackedBarChart.types';

export const HorizontalStackedBar = ({
  container,
  base
}: CustomStackedBarProps): JSX.Element => {
  const { height, width, minWidth, minHeight, borders } = container;
  const { value, color, filter, description } = base;

  return (
    <VStack
      spacing={2}
      width={width}
      minWidth={minWidth}
      alignItems="flex-start"
    >
      <Box
        width="100%"
        height={height}
        minHeight={minHeight}
        background={color}
        filter={filter}
        {...borders}
      />
      <VStack
        spacing={1}
        alignItems="flex-start"
        paddingStart={4}
        width="100%"
        overflow="hidden"
      >
        <Text color={color} textStyle="mds" filter={filter}>
          {value}
        </Text>
        <Text color="subtext" textStyle="xsrg" overflowWrap="anywhere">
          {description}
        </Text>
      </VStack>
    </VStack>
  );
};
