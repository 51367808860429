import { Box, BoxProps, Heading, Text } from '@chakra-ui/react';
import { Fragment, ReactNode } from 'react';

type Props = BoxProps & {
  children: ReactNode;
} & (
    | {
        header: string;
        subHeader?: string;
      }
    | {
        header?: undefined;
        subHeader?: undefined;
      }
  );

export const ContentContainer = ({
  children,
  header,
  subHeader,
  ...rest
}: Props): JSX.Element => (
  <Box borderRadius="1.25rem" padding={6} background="white" {...rest}>
    {!!header && (
      <Fragment>
        <Box marginBottom={6}>
          <Heading as="h3" size="l">
            {header}
          </Heading>
          {!!subHeader && (
            <Text size="sm" color="gray.600" marginTop={2}>
              {subHeader}
            </Text>
          )}
        </Box>
      </Fragment>
    )}
    {children}
  </Box>
);
