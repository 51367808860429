import { ModalContainer } from 'containers';
import { ModalType } from 'enum';
import { useDeleteCustomerComment, useModalActions } from 'hooks';
import { useTranslation } from 'react-i18next';
import { ParamType } from 'types';

export const DeleteCustomerComment = (): JSX.Element => {
  const [t] = useTranslation();

  const { mutate, isLoading, reset } = useDeleteCustomerComment();

  const { isOpen, onClose, meta } = useModalActions(
    ModalType.DeleteCustomerComment,
    {
      onClose: reset
    }
  );

  const actions: ParamType<typeof ModalContainer, 'footerButtons'> = [
    {
      text: t('actions.no'),
      variant: 'ghostGray'
    },
    {
      text: t('actions.yes'),
      colorScheme: 'secondary',
      isLoading,
      isPreventClose: true,
      onClick: (onClose) => {
        mutate(meta?.commentId || '', {
          onSuccess: onClose
        });
      }
    }
  ];

  return (
    <ModalContainer
      header={t('attribute.title.delete_customer_comment')}
      footerButtons={actions}
      isOpen={isOpen}
      onClose={onClose}
      size="xl"
    >
      {t('attribute.description.basic_action_question', {
        action: t('attribute.title.delete_customer_comment').toLowerCase()
      })}
    </ModalContainer>
  );
};
