import { useMutation, useQueryClient } from '@tanstack/react-query';
import { fetchCreateQuickReply } from 'api';
import { QueryKey } from 'enum/api';

export const useCreateQuickReply = () => {
  const queryClient = useQueryClient();

  return useMutation(fetchCreateQuickReply, {
    onSettled() {
      queryClient.invalidateQueries([QueryKey.QuickReplies]);
    }
  });
};
