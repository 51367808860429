import { Button, Heading, Text } from '@chakra-ui/react';
import { yupResolver } from '@hookform/resolvers/yup';
import { RightSideButtons } from 'components';
import { Access, ContentContainer, RightSide } from 'containers';
import { FormConstructor } from 'containers/FormConstructor/FormConstructor';
import { format } from 'date-fns';
import { FormField, ModalType, PageName } from 'enum';
import {
  ErrorCode,
  OptionKey,
  PermissionAction,
  PermissionResource
} from 'enum/api';
import {
  useAdminInfo,
  useAppDispatch,
  useAppSelector,
  useChakraToast,
  useUpdateAdmin
} from 'hooks';
import snakeCase from 'lodash/snakeCase';
import { useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { SIDE_NAV_LINK_BY_PAGE } from 'routes/routes.utils';
import { openModal } from 'store/modal';
import { selectSettings } from 'store/settings';
import { getUpdatedFields, serializeAxiosError } from 'utils';

import { EditAdminFormData } from './EditAdmin.types';
import {
  editAdminFormSchema,
  editAdminValidationSchema
} from './EditAdmin.utils';

export const EditAdmin = (): JSX.Element => {
  const [t] = useTranslation();
  const dispatch = useAppDispatch();
  const params = useParams();
  const navigate = useNavigate();
  const toast = useChakraToast();

  const settings = useAppSelector(selectSettings);

  const form = useForm<EditAdminFormData>({
    mode: 'onSubmit',
    resolver: yupResolver(editAdminValidationSchema)
  });

  const {
    handleSubmit,
    formState: { isDirty, dirtyFields },
    reset
  } = form;

  const { data } = useAdminInfo({
    id: params.adminId || '',
    shouldPreventRequest: isDirty
  });

  const { mutate: updateAdmin } = useUpdateAdmin();

  useEffect(() => {
    if (data) {
      const availableCountries = settings[OptionKey.Country];

      reset({
        [FormField.Id]: data.id,
        [FormField.UserName]: data.nickname,
        [FormField.UserType]: data.role.type,
        [FormField.Password]: '',
        [FormField.Status]: data.status,
        [FormField.Countries]: data.countries.filter((country) =>
          availableCountries.includes(country)
        ),
        [FormField.Domains]: data.domains
      });
    }
  }, [reset, settings, data]);

  const onSubmit = handleSubmit((data) => {
    const updateData = getUpdatedFields(data, dirtyFields);

    if (data.id) {
      updateAdmin(
        {
          id: data.id || '',
          ...updateData
        },
        {
          onSuccess: () => {
            navigate(`/${SIDE_NAV_LINK_BY_PAGE[PageName.AdminManagement]}`);
          },
          onError: (error) => {
            const { errorCode } = serializeAxiosError(error);

            const errorMessage = [ErrorCode.AdminUpdateNicknameExists].includes(
              errorCode
            )
              ? t(`errors.codes.${snakeCase(errorCode)}`)
              : t('errors.error_update_admin');

            toast({
              title: errorMessage,
              status: 'error'
            });
          }
        }
      );
    }
  });

  const handleDeleteAccount = () => {
    dispatch(
      openModal({
        id: ModalType.DeleteAdminUser,
        meta: {
          userId: params.adminId || ''
        }
      })
    );
  };

  const handleCancel = () => {
    navigate(-1);
  };

  const createdAt = data?.createdAt
    ? format(new Date(data.createdAt), 'dd.MM.yyyy')
    : '';

  return (
    <ContentContainer>
      <Heading as="h3" size="l" marginBottom={2}>
        {t('attribute.title.detailed_information')}
      </Heading>
      <Text marginBottom={6} size="sm" color="gray.600">
        {t('attribute.description.created_with_date', {
          date: createdAt
        })}
      </Text>
      <FormProvider {...form}>
        <FormConstructor formSchema={editAdminFormSchema} />
      </FormProvider>
      <Access
        restrictions={{
          resource: PermissionResource.Admins,
          actions: PermissionAction.Delete
        }}
      >
        <Heading as="h2" size="xl" marginBottom={6} marginTop={16}>
          {t('attribute.title.actions')}
        </Heading>
        <Button
          colorScheme="secondary"
          variant="link"
          onClick={handleDeleteAccount}
        >
          {t('actions.delete_account')}
        </Button>
      </Access>
      <RightSide>
        <RightSideButtons
          onSubmit={onSubmit}
          isDisabledSubmit={!isDirty}
          onCancel={handleCancel}
        />
      </RightSide>
    </ContentContainer>
  );
};
