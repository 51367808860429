import { Icon, IconProps } from '@chakra-ui/react';

export const UsersIcon = (props: IconProps): JSX.Element => (
  <Icon viewBox="0 0 24 24" fill="none" {...props}>
    <circle
      r="3.40426"
      transform="matrix(-1 0 0 1 12.2127 8.40426)"
      stroke="currentColor"
      strokeWidth="1.5"
    />
    <path
      d="M15.9412 19.5753L15.8351 20.3177L15.9412 19.5753ZM17.4202 16.8594V17.979H18.9202V16.8594H17.4202ZM7.00531 17.979V16.8594H5.50531V17.979H7.00531ZM16.3808 18.8805L16.0472 18.8328L15.8351 20.3177L16.1686 20.3654L16.3808 18.8805ZM8.37829 18.8328L8.04474 18.8805L8.25687 20.3654L8.59042 20.3177L8.37829 18.8328ZM16.0472 18.8328C13.5038 18.4695 10.9217 18.4695 8.37829 18.8328L8.59042 20.3177C10.9931 19.9745 13.4324 19.9745 15.8351 20.3177L16.0472 18.8328ZM7.65743 15.934C10.6032 14.882 13.8223 14.882 16.7681 15.934L17.2726 14.5214C14.0006 13.3529 10.4249 13.3529 7.15292 14.5214L7.65743 15.934ZM5.50531 17.979C5.50531 19.446 6.80462 20.5729 8.25687 20.3654L8.04474 18.8805C7.49614 18.9588 7.00531 18.5332 7.00531 17.979H5.50531ZM17.4202 17.979C17.4202 18.5332 16.9294 18.9588 16.3808 18.8805L16.1686 20.3654C17.6209 20.5729 18.9202 19.446 18.9202 17.979H17.4202ZM18.9202 16.8594C18.9202 15.8102 18.2606 14.8743 17.2726 14.5214L16.7681 15.934C17.1592 16.0737 17.4202 16.4441 17.4202 16.8594H18.9202ZM7.00531 16.8594C7.00531 16.4441 7.26636 16.0737 7.65743 15.934L7.15292 14.5214C6.16486 14.8743 5.50531 15.8102 5.50531 16.8594H7.00531Z"
      fill="currentColor"
    />
    <path
      d="M17.3191 12.6528C16.9049 12.6528 16.5691 12.317 16.5691 11.9028C16.5691 11.4886 16.9049 11.1528 17.3191 11.1528V12.6528ZM17.3191 7.30322C16.9049 7.30322 16.5691 6.96744 16.5691 6.55322C16.5691 6.13901 16.9049 5.80322 17.3191 5.80322V7.30322ZM17.3191 11.1528C18.3821 11.1528 19.2439 10.291 19.2439 9.22799H20.7439C20.7439 11.1194 19.2106 12.6528 17.3191 12.6528V11.1528ZM19.2439 9.22799C19.2439 8.16497 18.3821 7.30322 17.3191 7.30322V5.80322C19.2106 5.80322 20.7439 7.33655 20.7439 9.22799H19.2439Z"
      fill="currentColor"
    />
    <path
      d="M20.3547 17.2626C19.9447 17.204 19.5648 17.4889 19.5062 17.899C19.4476 18.309 19.7325 18.6889 20.1426 18.7475L20.3547 17.2626ZM21.0966 14.5891L20.8443 15.2954H20.8443L21.0966 14.5891ZM20.5107 18.0425L20.4046 18.785H20.4046L20.5107 18.0425ZM19.5889 13.4018C19.1822 13.3233 18.7889 13.5894 18.7104 13.9961C18.6319 14.4028 18.898 14.7961 19.3048 14.8746L19.5889 13.4018ZM21.25 15.8711V16.7508H22.75V15.8711H21.25ZM20.6168 17.3L20.3547 17.2626L20.1426 18.7475L20.4046 18.785L20.6168 17.3ZM21.25 16.7508C21.25 17.0884 20.951 17.3478 20.6168 17.3L20.4046 18.785C21.6425 18.9618 22.75 18.0013 22.75 16.7508H21.25ZM22.75 15.8711C22.75 14.9789 22.1891 14.1829 21.3488 13.8828L20.8443 15.2954C21.0876 15.3823 21.25 15.6128 21.25 15.8711H22.75ZM19.3048 14.8746C19.8252 14.975 20.3399 15.1153 20.8443 15.2954L21.3488 13.8828C20.7723 13.6769 20.1839 13.5166 19.5889 13.4018L19.3048 14.8746Z"
      fill="currentColor"
    />
    <path
      d="M6.68088 12.6528C7.09509 12.6528 7.43088 12.317 7.43088 11.9028C7.43088 11.4886 7.09509 11.1528 6.68088 11.1528V12.6528ZM6.68088 7.30322C7.09509 7.30322 7.43088 6.96744 7.43088 6.55322C7.43088 6.13901 7.09509 5.80322 6.68088 5.80322V7.30322ZM6.68088 11.1528C5.61785 11.1528 4.7561 10.291 4.7561 9.22799H3.2561C3.2561 11.1194 4.78943 12.6528 6.68088 12.6528V11.1528ZM4.7561 9.22799C4.7561 8.16497 5.61785 7.30322 6.68088 7.30322V5.80322C4.78943 5.80322 3.2561 7.33655 3.2561 9.22799H4.7561Z"
      fill="currentColor"
    />
    <path
      d="M3.6453 17.2626C4.05535 17.204 4.43525 17.4889 4.49383 17.899C4.55241 18.309 4.26748 18.6889 3.85743 18.7475L3.6453 17.2626ZM2.90344 14.5891L3.15569 15.2954H3.15569L2.90344 14.5891ZM3.48929 18.0425L3.59536 18.785H3.59536L3.48929 18.0425ZM4.41108 13.4018C4.81779 13.3233 5.21111 13.5894 5.28958 13.9961C5.36805 14.4028 5.10196 14.7961 4.69524 14.8746L4.41108 13.4018ZM2.74997 15.8711V16.7508H1.24997V15.8711H2.74997ZM3.38322 17.3L3.6453 17.2626L3.85743 18.7475L3.59536 18.785L3.38322 17.3ZM2.74997 16.7508C2.74997 17.0884 3.049 17.3478 3.38322 17.3L3.59536 18.785C2.35748 18.9618 1.24997 18.0013 1.24997 16.7508H2.74997ZM1.24997 15.8711C1.24997 14.9789 1.81089 14.1829 2.65118 13.8828L3.15569 15.2954C2.91238 15.3823 2.74997 15.6128 2.74997 15.8711H1.24997ZM4.69524 14.8746C4.17478 14.975 3.66007 15.1153 3.15569 15.2954L2.65118 13.8828C3.2277 13.6769 3.81609 13.5166 4.41108 13.4018L4.69524 14.8746Z"
      fill="currentColor"
    />
  </Icon>
);
