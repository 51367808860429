import { SystemStyleObject } from '@chakra-ui/react';

export const textStyles: SystemStyleObject = {
  mds: {
    fontSize: 'rg',
    fontWeight: 'semibold'
  },
  md: {
    fontSize: 'rg',
    fontWeight: 'medium'
  },
  ss: {
    fontSize: 'md',
    fontWeight: 'semibold'
  },
  sm: {
    fontSize: 'md',
    fontWeight: 'medium'
  },
  srg: {
    fontSize: 'md',
    fontWeight: 'normal'
  },
  xss: {
    fontSize: 'sm',
    fontWeight: 'semibold'
  },
  xsm: {
    fontSize: 'sm',
    fontWeight: 'medium'
  },
  xsrg: {
    fontSize: 'sm',
    fontWeight: 'normal'
  }
};
