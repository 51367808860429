import { Box } from '@chakra-ui/react';
import { TruncateText } from 'components';
import { ModalType } from 'enum';
import { useAppDispatch } from 'hooks';
import { ReactNode } from 'react';
import { openModal } from 'store';

type Props = {
  children: ReactNode;
  ticketId: string;
};

export const MessageCell = ({ children, ticketId }: Props): JSX.Element => {
  const dispatch = useAppDispatch();

  const onOpenConversation = () => {
    dispatch(
      openModal({
        id: ModalType.TicketConversation,
        meta: {
          ticketId
        }
      })
    );
  };

  return (
    <TruncateText minWidth={32} maxWidth="md">
      <Box cursor="pointer" onClick={onOpenConversation}>
        {children}
      </Box>
    </TruncateText>
  );
};
