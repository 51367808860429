import { Icon, IconProps } from '@chakra-ui/react';

export const LikeIcon = (props: IconProps): JSX.Element => (
  <Icon viewBox="0 0 24 24" fill="none" {...props}>
    <path
      d="M4.49647 13.1921L12 21.1L19.5035 13.1921C21.4988 11.0893 21.4988 7.67995 19.5035 5.57712C17.5082 3.4743 14.2732 3.4743 12.2779 5.57712L12 5.87L11.7221 5.57712C9.72679 3.4743 6.49177 3.47429 4.49647 5.57712C2.50117 7.67994 2.50117 11.0893 4.49647 13.1921Z"
      stroke="currentColor"
      strokeWidth="1.5"
    />
  </Icon>
);
