import { Breadcrumb, BreadcrumbItem, Center } from '@chakra-ui/react';
import { Avatar } from 'components';
import { PageName } from 'enum';
import { useAdminInfo, useCustomerInfo } from 'hooks';
import { useTranslation } from 'react-i18next';
import { useLocation, useParams } from 'react-router-dom';
import { getPageNameByUrl } from 'utils';

export const BreadcrumbGenerator = (): JSX.Element | null => {
  const [t] = useTranslation();
  const { pathname } = useLocation();
  const { customerId, adminId } = useParams();

  const { data: customer } = useCustomerInfo(customerId || '');
  const { data: admin } = useAdminInfo({
    id: adminId || '',
    shouldPreventRequest: !adminId
  });

  const page = getPageNameByUrl(pathname) as Exclude<
    PageName,
    PageName.Login | PageName.Examples
  >;

  let children: JSX.Element[] | JSX.Element;

  const pageTitle = t(`attribute.title.${page}`);

  switch (page) {
    case PageName.AdminPanel:
    case PageName.Settings:
    case PageName.Moderation:
    case PageName.LogActivity:
    case PageName.ContactUs:
    case PageName.Reports:
    case PageName.PaymentsManagement:
    case PageName.TablesManagement:
    case PageName.CRM:
    case PageName.StaticPages:
      children = <span>{pageTitle}</span>;
      break;
    case PageName.AdminManagement: {
      children = [<span key="title">{pageTitle}</span>];

      if (admin) {
        children.push(
          <Avatar key="avatar" title={admin.nickname} description={admin.id} />
        );
      }
      break;
    }
    case PageName.Customers: {
      children = [<span key="title">{pageTitle}</span>];

      if (customer) {
        children.push(
          <Avatar
            key="avatar"
            src={customer.avatar}
            title={customer.displayedNickname}
            description={customer.id}
          />
        );
      }
      break;
    }
    case PageName.PopupsManagement: {
      children = [<span key="title">{pageTitle}</span>];

      if (pathname.includes('create')) {
        children.push(
          <span key="create">{t('attribute.title.create_popup')}</span>
        );
      }
      break;
    }
    case PageName.BlogManagement: {
      children = [<span key="title">{pageTitle}</span>];

      if (pathname.includes('create')) {
        children.push(
          <span key="create">{t('attribute.title.create_article')}</span>
        );
      }
      break;
    }
    case PageName.DomainsConfig: {
      children = [<span key="title">{pageTitle}</span>];

      if (pathname.includes('create')) {
        children.push(
          <span key="create">{t('actions.create_domain_config')}</span>
        );
      }
      break;
    }

    default:
      return null;
  }

  return (
    <Breadcrumb separator={<Center boxSize={6}>&#183;</Center>} spacing={1}>
      {Array.isArray(children) ? (
        children.map((child, index) => (
          <BreadcrumbItem
            key={index}
            color="gray.500"
            _last={{
              color: 'inherit'
            }}
          >
            {child}
          </BreadcrumbItem>
        ))
      ) : (
        <BreadcrumbItem
          color="gray.500"
          _last={{
            color: 'inherit'
          }}
        >
          {children}
        </BreadcrumbItem>
      )}
    </Breadcrumb>
  );
};
