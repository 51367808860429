import {
  Grid,
  GridItem,
  Heading,
  Skeleton,
  useDisclosure
} from '@chakra-ui/react';
import {
  ChartLegend,
  FilterButtons,
  LineChart,
  ModalContainer,
  useTableFilters
} from 'containers';
import { useChartData, useSentMessages } from 'hooks';
import { FullScreenButton } from 'pages/AdminPanel/components';
import { Fragment, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ChartFilters } from 'types';

import { chartFiltersSchema, CHART_SETTINGS } from './SentMessages.utils';

export const SentMessages = (): JSX.Element => {
  const [t] = useTranslation();

  const [filterData, setFilterData] = useState<ChartFilters>({});
  const { isOpen: isModalOpen, onToggle, onClose } = useDisclosure();

  const filterButtonsProps = useTableFilters({
    filters: chartFiltersSchema,
    onChange: setFilterData
  });

  const { data, isSuccess } = useSentMessages({
    filters: filterData
  });

  const chartDataMap = useChartData({
    apiData: data,
    chartSettings: CHART_SETTINGS
  });

  const { chartData = [] } = chartDataMap || {};

  const title = t('attribute.title.sent_messages');

  const content = (
    <Grid
      templateAreas={`
      "title filters full"
      "legend legend legend"
      "chart chart chart"
    `}
      rowGap={4}
      columnGap={4}
      templateColumns="1fr auto auto"
    >
      <GridItem area="title">
        <Heading size="l">{title}</Heading>
      </GridItem>
      <GridItem area="filters">
        <FilterButtons {...filterButtonsProps} />
      </GridItem>
      <GridItem area="full">
        <FullScreenButton isFullMode={isModalOpen} onClick={onToggle} />
      </GridItem>
      <GridItem area="legend">
        <Skeleton isLoaded={isSuccess}>
          <ChartLegend data={chartData} />
        </Skeleton>
      </GridItem>
      <GridItem area="chart" height={isModalOpen ? '60vh' : '30rem'}>
        <Skeleton isLoaded={isSuccess} boxSize="100%">
          {!!chartDataMap && (
            <LineChart dataMap={chartDataMap} filters={filterData} />
          )}
        </Skeleton>
      </GridItem>
    </Grid>
  );

  return (
    <Fragment>
      {content}
      <ModalContainer
        isOpen={isModalOpen}
        onClose={onClose}
        size="max"
        modalBodyProps={{
          textAlign: 'left'
        }}
        shouldHideCloseButton
      >
        {content}
      </ModalContainer>
    </Fragment>
  );
};
