import { Grid, useToast, VStack } from '@chakra-ui/react';
import { Button, GridItem } from '@chakra-ui/react';
import { ParameterValue } from 'api';
import { AddButton, CheckIcon, Search, Table } from 'components';
import { ContentContainer } from 'containers';
import { TableFilters } from 'containers';
import { ModalType } from 'enum';
import {
  useAppDispatch,
  useParameterValues,
  useTableQueryController,
  useUpdateParameterValue
} from 'hooks';
import { useMarkAndDeleteValues } from 'pages/TableManagement/Pages/ParameterValues/useMarkAndDeleteValues';
import { FC } from 'react';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { openModal } from 'store';

import {
  getSearchOptions,
  parameterColumns,
  tableFilters
} from './ParameterValues.utils';

export const ParameterValues: FC = () => {
  const [t] = useTranslation();
  const toast = useToast();
  const dispatch = useAppDispatch();

  const {
    queryFilters,
    action: {
      onChangeSort,
      onChangePagination,
      onChangeFilters,
      onChangeSearch
    }
  } = useTableQueryController();

  const { data } = useParameterValues(queryFilters);
  const { mutateAsync: updateValue } = useUpdateParameterValue(queryFilters);

  const searchOptions = useMemo(() => getSearchOptions(t), [t]);

  const { isDisabled, isLoading, onDeleteMany, tableProps } =
    useMarkAndDeleteValues();

  const updateEntity = async (id: string, data: Partial<ParameterValue>) => {
    try {
      await updateValue({
        id,
        ...data
      });
    } catch (error) {
      toast({
        status: 'error',
        description:
          error instanceof Error
            ? error.message || t('errors.error_occurred_while_saving_data')
            : t('errors.error_occurred_while_saving_data')
      });
    }
  };

  const handleCreateValue = () => {
    dispatch(
      openModal({
        id: ModalType.CreateParameterValue
      })
    );
  };

  return (
    <ContentContainer height="100%" display="flex" flexDirection="column">
      <Grid
        templateAreas={`
             "search actions"
             "filters filters"
             "table table"
            `}
        templateColumns="26.25rem 1fr"
        templateRows="min-content  min-content 1fr"
        rowGap={6}
        overflow="hidden"
        flexGrow={1}
      >
        <GridItem area="search">
          <Search options={searchOptions} onChange={onChangeSearch} />
        </GridItem>
        <GridItem area="actions" justifySelf="flex-end">
          <VStack alignItems="flex-start">
            <AddButton onClick={handleCreateValue}>
              {t('actions.create_parameter_value')}
            </AddButton>
            <Button
              onClick={onDeleteMany}
              variant="link"
              leftIcon={<CheckIcon />}
              isDisabled={isDisabled}
              isLoading={isLoading}
            >
              {t('actions.delete_selected')}
            </Button>
          </VStack>
        </GridItem>
        <GridItem area="filters">
          <TableFilters filters={tableFilters} onChange={onChangeFilters} />
        </GridItem>
        {!!data && (
          <GridItem area="table" overflow="hidden">
            <Table
              data={data.data}
              columns={parameterColumns}
              onChangeSort={onChangeSort}
              onChangePagination={onChangePagination}
              totalItems={data.totalItems}
              pagination={queryFilters.pagination}
              hasHorizontalScroll
              meta={{
                updateEntity
              }}
              {...tableProps}
            />
          </GridItem>
        )}
      </Grid>
    </ContentContainer>
  );
};
