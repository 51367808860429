import { Grid, GridItem } from '@chakra-ui/react';
import { ModerationSection } from 'enum';
import { Outlet } from 'react-router-dom';

import { ModerationDropdownTitle } from './components';

export const Declined = ({
  sections
}: {
  sections: ModerationSection[] | null;
}): JSX.Element => (
  <Grid
    templateAreas={`
        "title ."
        "content content"
      `}
    templateRows="auto 1fr"
    templateColumns="auto 1fr"
    rowGap={6}
    height="100%"
  >
    <GridItem area="title">
      <ModerationDropdownTitle sections={sections} />
    </GridItem>
    <GridItem area="content" overflow="hidden">
      <Outlet />
    </GridItem>
  </Grid>
);
