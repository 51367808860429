import { IconButton, Menu, MenuButton, MenuList } from '@chakra-ui/react';
import { EllipsisIcon } from 'components/Icons';
import { ReactNode } from 'react';

export const EllipsisButton = ({
  children
}: {
  children: ReactNode;
}): JSX.Element => (
  <Menu>
    <MenuButton
      as={IconButton}
      icon={<EllipsisIcon />}
      aria-label="More options"
      variant="icon"
      size="md"
      fontSize="2xl"
    />
    <MenuList minWidth="36">{children}</MenuList>
  </Menu>
);
