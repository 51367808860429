import { Menu, MenuButton, MenuItem, MenuList } from '@chakra-ui/react';
import { Avatar } from 'components';
import { useAppSelector, useAuthorization } from 'hooks';
import snakeCase from 'lodash/snakeCase';
import { useTranslation } from 'react-i18next';
import { selectAuthorizedUser } from 'store/user';

export const ProfileMenu = (): JSX.Element => {
  const [t] = useTranslation();

  const user = useAppSelector(selectAuthorizedUser);
  const { logout } = useAuthorization();

  return (
    <Menu variant="unstyled" autoSelect={false}>
      <MenuButton>
        <Avatar
          title={user.userName}
          description={t(`enum.admin_role.${snakeCase(user.role)}`)}
        />
      </MenuButton>
      <MenuList minWidth="36">
        <MenuItem textAlign="center" onClick={logout}>
          {t('actions.logout')}
        </MenuItem>
      </MenuList>
    </Menu>
  );
};
