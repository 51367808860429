import { getTextWidth } from 'utils';

export const calculateTickTextOffset = ({
  angle,
  value,
  fontSize,
  fontWeight,
  tickLength
}: {
  angle: number;
  value: number;
  fontSize: string;
  fontWeight: string;
  tickLength: number;
}) => {
  const { width } = getTextWidth(value, {
    fontSize,
    fontWeight
  });
  const baseOffset = 5;

  const angleToRadian = angle * (Math.PI / 180);

  return (
    tickLength + baseOffset + (width / 2) * Math.abs(Math.sin(angleToRadian))
  );
};
