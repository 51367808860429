import { GridItem, VStack } from '@chakra-ui/react';
import { FormElement } from 'containers';
import { FormField, InputElement } from 'enum';
import { PermissionResource } from 'enum/api';
import { Fragment } from 'react';
import { useTranslation } from 'react-i18next';

import {
  FormContentProps,
  GeneralSettingsFormField
} from '../FormContent.types';

export const PasswordToViewCustomerMessages = ({
  prefilledFields
}: FormContentProps): JSX.Element => {
  const [t] = useTranslation();
  const isPrefilled = !!prefilledFields?.includes(
    GeneralSettingsFormField.PasswordForViewCustomerMessages
  );

  return (
    <Fragment>
      <GridItem>
        {t('attribute.description.password_for_view_customer_messages')}
      </GridItem>
      <GridItem>
        <VStack spacing={6}>
          <FormElement
            type={InputElement.Password}
            name={`${GeneralSettingsFormField.PasswordForViewCustomerMessages}.${FormField.Password}`}
            translationKey="password"
            getPlaceholder={() => ''}
            resource={PermissionResource.SettingsPassword}
            isPrefilled={isPrefilled}
            autoComplete="off"
          />
          <FormElement
            type={InputElement.Password}
            name={`${GeneralSettingsFormField.PasswordForViewCustomerMessages}.${FormField.PasswordConfirmation}`}
            translationKey="password_confirmation"
            getPlaceholder={() => ''}
            resource={PermissionResource.SettingsPassword}
          />
        </VStack>
      </GridItem>
    </Fragment>
  );
};
