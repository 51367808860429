import { yupResolver } from '@hookform/resolvers/yup';
import { FormConstructor, ModalContainer } from 'containers';
import { FormField, ModalType } from 'enum';
import { AdminRole, AdminStatus, ErrorCode } from 'enum/api';
import { useChakraToast, useCreateAdmin, useModalActions } from 'hooks';
import snakeCase from 'lodash/snakeCase';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { ParamType } from 'types';
import { getFormFieldsListBySchema, serializeAxiosError } from 'utils';
import { isNotEmpty } from 'utils/validation';

import { CreateAdminFormData } from './CreateNewAdmin.types';
import {
  createNewAdminFormSchema,
  createNewAdminValidationSchema
} from './CreateNewAdmin.utils';

export const CreateNewAdmin = (): JSX.Element => {
  const [t] = useTranslation();
  const toast = useChakraToast();

  const form = useForm<CreateAdminFormData>({
    mode: 'onSubmit',
    resolver: yupResolver(createNewAdminValidationSchema),
    defaultValues: {
      [FormField.Email]: '',
      [FormField.Password]: '',
      [FormField.UserName]: '',
      [FormField.Status]: undefined,
      [FormField.UserType]: undefined,
      [FormField.Countries]: []
    }
  });

  const { isOpen, onClose } = useModalActions(ModalType.CreateAdminUser, {
    onClose: form.reset
  });

  const { mutate, isLoading } = useCreateAdmin();

  const {
    handleSubmit,
    formState: { dirtyFields }
  } = form;

  const isSubmitDisabled = !isNotEmpty(
    dirtyFields,
    getFormFieldsListBySchema(createNewAdminFormSchema)
  );

  const onSubmit = (onSuccess: () => void) =>
    handleSubmit((data) => {
      const { userType, userName, status, ...rest } = data;

      mutate(
        {
          ...rest,
          nickname: userName,
          role: userType as AdminRole,
          status: status as AdminStatus
        },
        {
          onSuccess,
          onError: (error) => {
            const { errorCode } = serializeAxiosError(error);

            if (
              [
                ErrorCode.AdminCreateEmailExists,
                ErrorCode.AdminCreateNicknameExists
              ].includes(errorCode)
            ) {
              toast({
                title: t(`errors.codes.${snakeCase(errorCode)}`),
                status: 'error'
              });
            } else {
              toast({
                title: t('errors.error_create_new_admin'),
                status: 'error'
              });
            }
          }
        }
      );
    });

  const actions: ParamType<typeof ModalContainer, 'footerButtons'> = [
    {
      text: t('actions.save_admin_account_details'),
      colorScheme: 'primary',
      isPreventClose: true,
      isLoading,
      isDisabled: isSubmitDisabled,
      onClick: (onClose) => {
        onSubmit(onClose)();
      }
    }
  ];

  return (
    <ModalContainer
      header={t('attribute.title.create_admin_user')}
      footerButtons={actions}
      isOpen={isOpen}
      onClose={onClose}
    >
      <FormProvider {...form}>
        <FormConstructor formSchema={createNewAdminFormSchema} />
      </FormProvider>
    </ModalContainer>
  );
};
