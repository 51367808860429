import { Button, GridItem, VStack } from '@chakra-ui/react';
import { ReportCustomerListResponse } from 'api';
import { AddButton, CheckIcon, Search, Table } from 'components';
import { TableFilters } from 'containers';
import { ModalType } from 'enum';
import { ReportType } from 'enum/api';
import {
  useAppDispatch,
  useCustomerReportList,
  useTableQueryController
} from 'hooks';
import { useMarkReport } from 'pages/Reports/useMarkReport';
import { Fragment, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { openModal } from 'store';

import {
  customerReportsTableFilters,
  reportCustomerColumns,
  getSearchOptions
} from './CustomerReports.utils';

type Props = {
  reportType: ReportType;
};

export const CustomerReportContent = ({ reportType }: Props): JSX.Element => {
  const [t] = useTranslation();
  const dispatch = useAppDispatch();

  const {
    queryFilters,
    action: {
      onChangeSort,
      onChangePagination,
      onChangeFilters,
      onChangeSearch
    }
  } = useTableQueryController({
    fixedValues: {
      filter: {
        type: reportType
      }
    }
  });

  const { data } = useCustomerReportList(queryFilters);
  const searchOptions = useMemo(() => getSearchOptions(t), [t]);

  const { isDisabled, isLoading, label, onHandleClick, tableProps } =
    useMarkReport<ReportCustomerListResponse>();

  const handleCreateReport = () => {
    dispatch(
      openModal({
        id: ModalType.CreateReport,
        meta: {
          reportType
        }
      })
    );
  };

  return (
    <Fragment>
      <GridItem area="search">
        <Search options={searchOptions} onChange={onChangeSearch} />
      </GridItem>
      <GridItem area="actions" justifySelf="flex-end">
        <VStack>
          <AddButton onClick={handleCreateReport}>
            {t('actions.create_report')}
          </AddButton>
          <Button
            onClick={onHandleClick}
            variant="link"
            leftIcon={<CheckIcon />}
            isDisabled={isDisabled}
            isLoading={isLoading}
          >
            {label}
          </Button>
        </VStack>
      </GridItem>
      <GridItem area="filters">
        <TableFilters
          filters={customerReportsTableFilters}
          onChange={onChangeFilters}
        />
      </GridItem>
      {!!data && (
        <GridItem area="table" overflow="hidden">
          <Table
            data={data.data}
            columns={reportCustomerColumns}
            onChangeSort={onChangeSort}
            onChangePagination={onChangePagination}
            totalItems={data.totalItems}
            pagination={queryFilters.pagination}
            hasHorizontalScroll
            {...tableProps}
          />
        </GridItem>
      )}
    </Fragment>
  );
};
