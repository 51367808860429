import { ENDPOINTS, WithPagination } from 'api';
import { serializeBodyFilters } from 'api/api.utils';
import axios from 'axios';
import { GlobalFilters, QueryFilters } from 'types';
import { ICustomerTable } from 'types/customer';

import { CustomerTableResponse } from '../Customers.types';

export const fetchCustomerList = async ({
  queryFilters,
  globalFilters,
  shouldSearchStartsWith
}: {
  queryFilters: QueryFilters;
  globalFilters: GlobalFilters;
  shouldSearchStartsWith: boolean;
}): Promise<WithPagination<ICustomerTable[]>> => {
  const { data } = await axios.post<WithPagination<CustomerTableResponse[]>>(
    ENDPOINTS.CUSTOMERS_LIST,
    serializeBodyFilters({ queryFilters, globalFilters }),
    {
      params: {
        searchStartsWith: shouldSearchStartsWith
      }
    }
  );

  return data;
};
