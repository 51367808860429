import { Access } from 'containers';
import { CustomerPage, CustomerSection } from 'enum';
import { getAllEnumValues } from 'enum-for';
import { PermissionAction, PermissionResource } from 'enum/api';

import {
  AboutMe,
  AccountSettings,
  AdminComments,
  AdminInteraction,
  BlackListByUser,
  BlackListToUser,
  Conversations,
  CustomerBadWords,
  CustomerContent,
  CustomerDashboard,
  CustomerInteraction,
  CustomerPayments,
  CustomerReport,
  FavouritesByUser,
  FavouritesToUser,
  GalleryImages,
  LikesByUser,
  LikesToUser,
  LinkedAccounts,
  LoginHistory,
  MessageImages,
  NotesByUser,
  NotesToUser,
  NotificationSettings,
  PersonalDetails,
  Reports,
  ReportsAgainstCustomer,
  Stories,
  TicketHistory,
  AdminReports,
  WrittenByUser,
  WrittenToUser
} from './CustomerPages';

export const CUSTOMER_PAGE_LIST = getAllEnumValues(CustomerPage);

export const CUSTOMER_PAGE: Record<
  CustomerPage,
  {
    sections: CustomerSection[] | null;
    content: ({
      sections
    }: {
      sections: CustomerSection[] | null;
    }) => JSX.Element;
  }
> = {
  [CustomerPage.Dashboard]: { sections: null, content: CustomerDashboard },
  [CustomerPage.PersonalDetails]: { sections: null, content: PersonalDetails },
  [CustomerPage.AboutMe]: { sections: null, content: AboutMe },
  [CustomerPage.AccountSettings]: { sections: null, content: AccountSettings },
  [CustomerPage.NotificationSettings]: {
    sections: null,
    content: NotificationSettings
  },
  [CustomerPage.AdminInteraction]: {
    sections: [
      CustomerSection.AdminComments,
      CustomerSection.TicketHistory,
      CustomerSection.LinkedAccounts,
      CustomerSection.LoginHistory
    ],
    content: AdminInteraction
  },
  [CustomerPage.CustomerInteractions]: {
    sections: [
      CustomerSection.CommentsWrittenToUser,
      CustomerSection.CommentsWrittenByUser,
      CustomerSection.CustomerNotesToUser,
      CustomerSection.CustomerNotesByUser,
      CustomerSection.CustomerMessages,
      CustomerSection.FavouritesAddedByCustomer,
      CustomerSection.PartnersAddedCustomerToFavourites,
      CustomerSection.LikedByCustomer,
      CustomerSection.PartnersLikedCustomer,
      CustomerSection.BlackListByCustomer,
      CustomerSection.CustomersAddedCustomerToBlacklist
    ],
    content: CustomerInteraction
  },
  [CustomerPage.CustomerReports]: {
    sections: [
      CustomerSection.CustomerReports,
      CustomerSection.ReportsAgainstCustomer,
      CustomerSection.AdminReports
    ],
    content: Reports
  },
  [CustomerPage.CustomerContent]: {
    sections: [
      CustomerSection.MessageImages,
      CustomerSection.GalleryImages,
      CustomerSection.Stories
    ],
    content: CustomerContent
  },
  [CustomerPage.CustomerPayments]: {
    sections: null,
    content: () => (
      <Access
        restrictions={{
          resource: PermissionResource.PaymentTransactions,
          actions: PermissionAction.Read
        }}
      >
        <CustomerPayments />
      </Access>
    )
  },
  [CustomerPage.CustomerBadWords]: { sections: null, content: CustomerBadWords }
};

export const SECTION_CONTENT: Record<CustomerSection, () => JSX.Element> = {
  [CustomerSection.AdminComments]: AdminComments,
  [CustomerSection.TicketHistory]: TicketHistory,
  [CustomerSection.LinkedAccounts]: LinkedAccounts,
  [CustomerSection.LoginHistory]: LoginHistory,
  [CustomerSection.CommentsWrittenToUser]: WrittenToUser,
  [CustomerSection.CommentsWrittenByUser]: WrittenByUser,
  [CustomerSection.CustomerNotesToUser]: NotesToUser,
  [CustomerSection.CustomerNotesByUser]: NotesByUser,
  [CustomerSection.CustomerMessages]: () => (
    <Access
      restrictions={{
        resource: PermissionResource.CustomerConversations,
        actions: PermissionAction.Read
      }}
    >
      <Conversations />
    </Access>
  ),
  [CustomerSection.FavouritesAddedByCustomer]: FavouritesByUser,
  [CustomerSection.PartnersAddedCustomerToFavourites]: FavouritesToUser,
  [CustomerSection.LikedByCustomer]: LikesByUser,
  [CustomerSection.PartnersLikedCustomer]: LikesToUser,
  [CustomerSection.BlackListByCustomer]: BlackListByUser,
  [CustomerSection.CustomersAddedCustomerToBlacklist]: BlackListToUser,
  [CustomerSection.MessageImages]: () => (
    <Access
      restrictions={{
        resource: PermissionResource.CustomerMessageImages,
        actions: PermissionAction.Read
      }}
    >
      <MessageImages />
    </Access>
  ),
  [CustomerSection.GalleryImages]: () => (
    <Access
      restrictions={{
        resource: PermissionResource.Content,
        actions: PermissionAction.Read
      }}
    >
      <GalleryImages />
    </Access>
  ),
  [CustomerSection.Stories]: () => (
    <Access
      restrictions={{
        resource: PermissionResource.CustomerStories,
        actions: PermissionAction.Read
      }}
    >
      <Stories />
    </Access>
  ),
  [CustomerSection.CustomerReports]: CustomerReport,
  [CustomerSection.ReportsAgainstCustomer]: ReportsAgainstCustomer,
  [CustomerSection.AdminReports]: AdminReports
};
