import { useQuery } from '@tanstack/react-query';
import { fetchCustomerNicknameHistory } from 'api';
import { QueryKey } from 'enum/api';

export const useCustomerNicknameHistory = (id: string) =>
  useQuery(
    [QueryKey.CustomerNicknameHistory, id],
    () => fetchCustomerNicknameHistory(id),
    {
      retry: false,
      refetchOnWindowFocus: false,
      enabled: !!id
    }
  );
