import { ActiveUsersResponse } from 'api';
import { GaugeChartProps } from 'containers';
import { useMemo } from 'react';

export const useActiveUsersChartData = ({
  apiData
}: {
  apiData: ActiveUsersResponse;
}): Omit<GaugeChartProps, 'settings' | 'children'> => {
  const { maxCount, count } = apiData;
  const minCount = 0;

  const segments = useMemo(() => {
    const { maxCount } = apiData;
    const firstDivision = Math.floor(maxCount / 3);
    const lastDivision = Math.floor((maxCount * 2) / 3);

    return [
      {
        start: minCount,
        end: firstDivision,
        color: 'error'
      },
      {
        start: firstDivision,
        end: lastDivision,
        color: 'warning'
      },
      {
        start: lastDivision,
        end: maxCount,
        color: 'success'
      }
    ];
  }, [apiData]);

  return {
    min: minCount,
    max: maxCount,
    value: count,
    segments
  };
};
