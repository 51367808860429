import { chakra } from '@chakra-ui/react';
import { GroupBase, MenuProps } from 'react-select';

import { useStyles } from '../LocationDropdown.utils';

export const Menu = <
  Option,
  IsMulti extends boolean,
  Group extends GroupBase<Option>
>(
  props: MenuProps<Option, IsMulti, Group>
): JSX.Element => {
  const { children, innerRef, innerProps } = props;

  const styles = useStyles();

  return (
    <chakra.div ref={innerRef} __css={styles.menu} {...innerProps}>
      {children}
    </chakra.div>
  );
};
