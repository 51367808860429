import { FormField, InputElement } from 'enum';
import { LengthUnits, OptionKey, ParameterType } from 'enum/api';
import { DefaultValues } from 'react-hook-form';
import { ICustomer } from 'types/customer';
import { GetOptionsArg } from 'types/form';
import { generateOptionsFromSettings } from 'utils';
import * as yup from 'yup';

import { AboutMeFormData } from './AboutMe.types';

export const getCustomerAboutMeFormSchema = ({
  heightUnit
}: {
  heightUnit?: LengthUnits;
}) =>
  ({
    fields: {
      [FormField.Height]: {
        type: InputElement.Select,
        translationKey: 'height',
        getPlaceholder: () => 'Select',
        getOptions: ({ t, settings }: GetOptionsArg) =>
          generateOptionsFromSettings(
            heightUnit === LengthUnits.INCH
              ? OptionKey.HeightInch
              : OptionKey.Height,
            t,
            settings
          )
      },
      [FormField.DrinkingType]: {
        type: InputElement.Select,
        translationKey: 'drinking_type',
        getPlaceholder: () => 'Select',
        getOptions: ({ t, settings }: GetOptionsArg) =>
          generateOptionsFromSettings(ParameterType.DrinkingType, t, settings)
      },
      [FormField.EyeColor]: {
        type: InputElement.Select,
        translationKey: 'eye_color',
        getPlaceholder: () => 'Select',
        getOptions: ({ t, settings }: GetOptionsArg) =>
          generateOptionsFromSettings(ParameterType.EyeColor, t, settings)
      },
      [FormField.SmokingType]: {
        type: InputElement.Select,
        translationKey: 'smoking_type',
        getPlaceholder: () => 'Select',
        getOptions: ({ t, settings }: GetOptionsArg) =>
          generateOptionsFromSettings(ParameterType.SmokingType, t, settings)
      },
      [FormField.BodyType]: {
        type: InputElement.Select,
        translationKey: 'body_type',
        getPlaceholder: () => 'Select',
        getOptions: ({ t, settings }: GetOptionsArg) =>
          generateOptionsFromSettings(ParameterType.BodyType, t, settings)
      },
      [FormField.FamilyStatus]: {
        type: InputElement.Select,
        translationKey: 'family_status',
        getPlaceholder: () => 'Select',
        getOptions: ({ t, settings }: GetOptionsArg) =>
          generateOptionsFromSettings(ParameterType.FamilyStatus, t, settings)
      },
      [FormField.HairColor]: {
        type: InputElement.Select,
        translationKey: 'hair_color',
        getPlaceholder: () => 'Select',
        getOptions: ({ t, settings }: GetOptionsArg) =>
          generateOptionsFromSettings(ParameterType.HairColor, t, settings)
      },
      [FormField.ChildType]: {
        type: InputElement.Select,
        translationKey: 'child_type',
        getPlaceholder: () => 'Select',
        getOptions: ({ t, settings }: GetOptionsArg) =>
          generateOptionsFromSettings(ParameterType.ChildType, t, settings)
      },
      [FormField.EthnicityType]: {
        type: InputElement.Select,
        translationKey: 'ethnicity_type',
        getPlaceholder: () => 'Select',
        getOptions: ({ t, settings }: GetOptionsArg) =>
          generateOptionsFromSettings(ParameterType.EthnicityType, t, settings)
      },
      [FormField.ReligionType]: {
        type: InputElement.Select,
        translationKey: 'religion_type',
        getPlaceholder: () => 'Select',
        getOptions: ({ t, settings }: GetOptionsArg) =>
          generateOptionsFromSettings(ParameterType.ReligionType, t, settings)
      },
      [FormField.Motto]: {
        type: InputElement.TextArea,
        translationKey: 'motto',
        getPlaceholder: () => '',
        minHeight: 44,
        maxLength: 255
      },
      [FormField.AboutMe]: {
        type: InputElement.TextArea,
        translationKey: 'about_me',
        getPlaceholder: () => '',
        minHeight: 44,
        maxLength: 400
      }
    },
    gridLayout: {
      templateAreas: `
    "${FormField.Height} ${FormField.DrinkingType}"
    "${FormField.EyeColor} ${FormField.SmokingType}"
    "${FormField.BodyType} ${FormField.FamilyStatus}"
    "${FormField.HairColor} ${FormField.ChildType}"
    "${FormField.EthnicityType} ${FormField.ReligionType}"
    "${FormField.Motto} ${FormField.AboutMe}"

  `,
      gridTemplateColumns: '1fr 1fr',
      rowGap: 6,
      columnGap: 10
    }
  } as const);

export const aboutMeValidationSchema: yup.SchemaOf<AboutMeFormData> = yup
  .object()
  .shape({
    [FormField.Height]: yup.string().optional().nullable(),
    [FormField.DrinkingType]: yup.string().optional().nullable(),
    [FormField.EyeColor]: yup.string().optional().nullable(),
    [FormField.SmokingType]: yup.string().optional().nullable(),
    [FormField.BodyType]: yup.string().optional().nullable(),
    [FormField.FamilyStatus]: yup.string().optional().nullable(),
    [FormField.HairColor]: yup.string().optional().nullable(),
    [FormField.ChildType]: yup.string().optional().nullable(),
    [FormField.EthnicityType]: yup.string().optional().nullable(),
    [FormField.ReligionType]: yup.string().optional().nullable(),
    [FormField.Motto]: yup.string().optional().nullable(),
    [FormField.AboutMe]: yup.string().optional().nullable()
  });

export const getDefaultFormValues = (
  customer: ICustomer
): DefaultValues<AboutMeFormData> => ({
  [FormField.Height]: customer.bio.height ? String(customer.bio.height) : null,
  [FormField.DrinkingType]: customer.bio?.[ParameterType.DrinkingType] || null,
  [FormField.EyeColor]: customer.bio?.[ParameterType.EyeColor] || null,
  [FormField.SmokingType]: customer.bio?.[ParameterType.SmokingType] || null,
  [FormField.BodyType]: customer.bio?.[ParameterType.BodyType] || null,
  [FormField.FamilyStatus]: customer.bio?.[ParameterType.FamilyStatus] || null,
  [FormField.HairColor]: customer.bio?.[ParameterType.HairColor] || null,
  [FormField.ChildType]: customer.bio?.[ParameterType.ChildType] || null,
  [FormField.EthnicityType]:
    customer.bio?.[ParameterType.EthnicityType] || null,
  [FormField.ReligionType]: customer.bio?.[ParameterType.ReligionType] || null,
  [FormField.Motto]: customer.bio?.motto || null,
  [FormField.AboutMe]: customer.bio?.aboutMe || null
});
