import { ContentContainer, InfoCardContainer } from 'containers';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { ICustomer } from 'types/customer';

import { getCustomerMessages } from './CustomerDashboard.utils';

type Props = {
  customer: ICustomer;
};

export const Messages = ({ customer }: Props): JSX.Element => {
  const [t] = useTranslation();
  const navigate = useNavigate();

  const messages = getCustomerMessages(customer, t, navigate);

  return (
    <ContentContainer header="Messages">
      <InfoCardContainer data={messages} />
    </ContentContainer>
  );
};
