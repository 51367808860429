import { Permission } from 'api';
import { AdminRole } from 'enum/api';

export type AuthorizedUserState = {
  id: string;
  userName: string;
  role: AdminRole;
  permissions: Permission;
};

export type EmptyUserState = {
  id: null;
  userName: null;
  role: null;
  permissions: null;
};

export type UserState = AuthorizedUserState | EmptyUserState;

export enum UserAction {
  SetUser = 'user/setUser',
  ResetUser = 'user/reset'
}
