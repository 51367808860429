import { NavigationLink } from 'components';
import { PropsWithChildren } from 'react';

type Props = PropsWithChildren<{
  navigateTo: string;
}>;

export const NicknameCell = ({ children, navigateTo }: Props): JSX.Element => (
  <NavigationLink to={navigateTo}>{children}</NavigationLink>
);
