import { WithPagination } from 'api';
import { serializeQueryFilters } from 'api/api.utils';
import { ENDPOINTS } from 'api/endpoints';
import axios from 'axios';
import { QueryFilters } from 'types';
import { ICustomerLoginHistory } from 'types/customer';

import { CustomerLoginHistoryResponse } from '../Customers.types';

export const fetchCustomerLoginHistory = async ({
  customerId,
  queryFilters
}: {
  customerId: string;
  queryFilters: QueryFilters;
}): Promise<WithPagination<ICustomerLoginHistory[]>> => {
  const { data } = await axios.post<
    WithPagination<CustomerLoginHistoryResponse[]>
  >(
    `${ENDPOINTS.LOGIN_SESSION}/by-user/${customerId}/list`,
    serializeQueryFilters(queryFilters)
  );

  return data;
};
