import { Box, Text } from '@chakra-ui/react';
import { UserProfileProps } from 'components';
import { ModalType } from 'enum';
import { useAppDispatch } from 'hooks';
import { openModal } from 'store/modal';

type Props = UserProfileProps;

export const UserCell = ({ user, compareData }: Props): JSX.Element => {
  const dispatch = useAppDispatch();

  const onHandleClick = () => {
    dispatch(
      openModal({
        id: ModalType.UserProfile,
        meta: {
          user,
          compareData
        }
      })
    );
  };

  return (
    <Box cursor="pointer" onClick={onHandleClick}>
      <Text>{user.displayedNickname}</Text>
      <Text color="subtext">{user.email}</Text>
    </Box>
  );
};
