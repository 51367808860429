import { HStack, Icon, Text } from '@chakra-ui/react';
import { BaseChartDataTemplate } from 'api';
import { StatusMarkIcon } from 'components';
import { useTranslation } from 'react-i18next';

import { LineChartData } from './LineChart/LineChart.types';

type Props<T extends BaseChartDataTemplate> = {
  data: LineChartData<T>[];
};

export const ChartLegend = <T extends BaseChartDataTemplate>({
  data
}: Props<T>): JSX.Element => {
  const [t] = useTranslation();

  return (
    <HStack spacing={8}>
      {data.map(({ serieId, subId, color, getLabel }) => (
        <HStack key={`${serieId}${subId || ''}`} spacing={2}>
          <Icon as={StatusMarkIcon} color={color} boxSize={3} />
          <Text>{getLabel(t)}</Text>
        </HStack>
      ))}
    </HStack>
  );
};
