import { Grid, GridItem } from '@chakra-ui/react';
import { ContentContainer } from 'containers';
import { CustomerSection } from 'enum';
import { DropdownTitle } from 'pages/Customers/components';
import { Outlet } from 'react-router-dom';

export const Reports = ({
  sections
}: {
  sections: CustomerSection[] | null;
}): JSX.Element => (
  <ContentContainer height="100%">
    <Grid
      templateAreas={`
          "title ."
          "table table"
        `}
      templateColumns="auto 1fr"
      templateRows="auto 1fr"
      rowGap={6}
      height="100%"
    >
      <GridItem area="title">
        <DropdownTitle sections={sections} />
      </GridItem>
      <GridItem area="table" overflow="hidden">
        <Outlet />
      </GridItem>
    </Grid>
  </ContentContainer>
);
