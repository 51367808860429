import { SubHeader } from 'containers';
import { Fragment } from 'react';
import { Outlet, useOutletContext } from 'react-router-dom';

import { AdminDetailsTabs } from './components';

export const AdminDetails = (): JSX.Element => {
  const context = useOutletContext();

  return (
    <Fragment>
      <SubHeader>
        <AdminDetailsTabs />
      </SubHeader>
      <Outlet context={context} />
    </Fragment>
  );
};
