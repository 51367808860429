import { CustomerBlackListResponse, ENDPOINTS, WithPagination } from 'api';
import { serializeQueryFilters } from 'api/api.utils';
import axios from 'axios';
import { QueryFilters } from 'types';
import { ICustomerBlackList } from 'types/customer';

import { serializeCustomerBlackListData } from './CustomerBlackList.utils';

export const fetchCustomerBlackListToUser = async ({
  customerId,
  queryFilters
}: {
  customerId: string;
  queryFilters: QueryFilters;
}): Promise<WithPagination<ICustomerBlackList[]>> => {
  const { data } = await axios.post<
    WithPagination<CustomerBlackListResponse[]>
  >(
    `${ENDPOINTS.BLACK_LIST}/to-user/${customerId}/list`,
    serializeQueryFilters(queryFilters)
  );

  return {
    ...data,
    data: serializeCustomerBlackListData(data.data)
  };
};
