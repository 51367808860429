import { yupResolver } from '@hookform/resolvers/yup';
import { FormConstructor, ModalContainer } from 'containers';
import { FormField, ModalType } from 'enum';
import { useModalActions, useUpdatePaymentTransaction } from 'hooks';
import startCase from 'lodash/startCase';
import { useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { ParamType } from 'types';

import { EditTransactionFormData } from './EditTransaction.types';
import {
  editTransactionFormSchema,
  editTransactionValidationSchema
} from './EditTransaction.utils';

export const EditTransaction = (): JSX.Element => {
  const [t] = useTranslation();

  const form = useForm<EditTransactionFormData>({
    mode: 'onChange',
    resolver: yupResolver(editTransactionValidationSchema),
    defaultValues: {
      [FormField.Comment]: ''
    }
  });

  const {
    handleSubmit,
    formState: { isDirty },
    reset
  } = form;

  const { isOpen, onClose, meta } = useModalActions(
    ModalType.UpdateTransaction,
    {
      onClose: reset
    }
  );

  const { mutate, isLoading } = useUpdatePaymentTransaction();

  useEffect(() => {
    if (meta) {
      reset({
        [FormField.Comment]: meta.comment
      });
    }
  }, [meta, reset]);

  const onSubmit = handleSubmit((data) => {
    if (meta) {
      mutate(
        {
          transactionId: meta.transactionId,
          comment: data.comment as string
        },
        {
          onSuccess: onClose
        }
      );
    }
  });

  const actions: ParamType<typeof ModalContainer, 'footerButtons'> = [
    {
      text: t('actions.cancel'),
      variant: 'ghostGray'
    },
    {
      text: startCase(t('actions.save')),
      isLoading,
      isPreventClose: true,
      isDisabled: !isDirty,
      onClick: () => onSubmit()
    }
  ];

  return (
    <ModalContainer
      header={t('attribute.title.edit_transaction')}
      footerButtons={actions}
      isOpen={isOpen}
      onClose={onClose}
      size="xl"
    >
      <FormProvider {...form}>
        <FormConstructor formSchema={editTransactionFormSchema} />
      </FormProvider>
    </ModalContainer>
  );
};
