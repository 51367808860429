import { Box, Flex, HStack, Spinner } from '@chakra-ui/react';
import { AddButton, Table } from 'components';
import { ModalType } from 'enum';
import {
  useAppDispatch,
  useCustomerIdFromUrl,
  useCustomerReportsToUser,
  useTableQueryController
} from 'hooks';
import { useTranslation } from 'react-i18next';
import { openModal } from 'store';

import { reportsColumns } from '../Reports.utils';

export const ReportsAgainstCustomer = (): JSX.Element => {
  const dispatch = useAppDispatch();
  const [t] = useTranslation();

  const customerId = useCustomerIdFromUrl();

  const {
    queryFilters,
    action: { onChangeSort, onChangePagination }
  } = useTableQueryController();

  const { data } = useCustomerReportsToUser({ customerId, queryFilters });

  const handleCreateReport = () => {
    dispatch(
      openModal({
        id: ModalType.CreateReport,
        meta: {
          customerId
        }
      })
    );
  };

  const handleCreateTicket = () => {
    dispatch(
      openModal({
        id: ModalType.CreateTicket,
        meta: {}
      })
    );
  };

  if (!data) return <Spinner />;

  return (
    <Box>
      <Flex justifyContent="flex-end">
        <HStack spacing={4}>
          <AddButton onClick={handleCreateReport}>
            {t('actions.create_report')}
          </AddButton>
          <AddButton onClick={handleCreateTicket}>
            {t('actions.create_ticket')}
          </AddButton>
        </HStack>
      </Flex>
      <Table
        data={data.data}
        columns={reportsColumns}
        onChangeSort={onChangeSort}
        onChangePagination={onChangePagination}
        pagination={queryFilters.pagination}
        totalItems={data.totalItems}
      />
    </Box>
  );
};
