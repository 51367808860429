import { ENDPOINTS } from 'api';
import axios from 'axios';
import { ChartFilters } from 'types';

import { SentMessagesResponse } from './AdminPanel.types';

export const fetchSentMessages = async ({
  filters
}: {
  filters: ChartFilters;
}): Promise<SentMessagesResponse[]> => {
  const { data } = await axios.post<SentMessagesResponse[]>(
    ENDPOINTS.SENT_MESSAGES,
    {
      filter: filters
    }
  );

  return data;
};
