import { ENDPOINTS, UpdateGalleryPhotoRequestData } from 'api';
import axios from 'axios';

export const fetchUpdateGalleryPhoto = async ({
  photoId,
  croppedImage,
  status,
  declineReason
}: UpdateGalleryPhotoRequestData) => {
  const formData = new FormData();

  if (status) formData.append('status', status);

  if (declineReason) formData.append('declineReason', declineReason);

  if (croppedImage) formData.append('image', croppedImage);

  await axios.patch(`${ENDPOINTS.MODERATION_PHOTO}/${photoId}`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  });
};
