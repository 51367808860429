import { Center, Flex } from '@chakra-ui/react';
import { ModalContainer } from 'containers';
import { format } from 'date-fns';
import { AssetType, ContentSection, ModalType } from 'enum';
import {
  useCustomerGalleryImages,
  useCustomerStoryList,
  useModalActions
} from 'hooks';
import { capitalize, words } from 'lodash';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ParamType } from 'types';

import { ContentCard } from './components';

export const CustomerContentList = (): JSX.Element => {
  const [t] = useTranslation();

  const { isOpen, onClose, meta } = useModalActions(
    ModalType.CustomerContentList
  );

  const { data: galleryData } = useCustomerGalleryImages(meta?.userId ?? '', {
    enabled: !!meta?.userId && meta.contentType === ContentSection.GalleryImages
  });

  const { data: storiesData } = useCustomerStoryList(meta?.userId ?? '', {
    enabled: !!meta?.userId && meta.contentType === ContentSection.Stories
  });

  const [selection, setSelection] = useState<{
    id: string;
    preview: string;
    previewType: AssetType;
    description: string;
  } | null>(null);

  const items = useMemo(() => {
    if (meta?.contentType === ContentSection.GalleryImages && galleryData) {
      return galleryData.map(({ imageUrl, id, status, uploadedAt }) => ({
        id,
        preview: imageUrl,
        previewType: AssetType.Image,
        description: `${t('keywords.uploaded_at', {
          uploadedAt: format(new Date(uploadedAt), 'dd.MM.yyyy')
        })}, ${words(status).map(capitalize).join(' ')}`
      }));
    }

    if (meta?.contentType === ContentSection.Stories && storiesData) {
      return storiesData.map(({ id, assets, status, uploadedAt }) => ({
        id,
        preview: assets[0].assetUrl,
        previewType: assets[0].type,
        description: `${t('keywords.uploaded_at', {
          uploadedAt: format(new Date(uploadedAt), 'dd.MM.yyyy')
        })}, ${words(status).map(capitalize).join(' ')}`
      }));
    }

    return [];
  }, [meta?.contentType, galleryData, storiesData]);

  const actions: ParamType<typeof ModalContainer, 'footerButtons'> = [
    {
      text: t('actions.cancel'),
      variant: 'ghostGray'
    },
    {
      text: t('actions.choose'),
      isPreventClose: true,
      onClick: (onClose) => {
        if (selection && meta) {
          meta.onChooseItem(selection);
          onClose();
        }
      }
    }
  ];

  return (
    <ModalContainer
      header={
        meta &&
        t(
          meta.contentType === ContentSection.GalleryImages
            ? 'keywords.gallery_images'
            : 'keywords.stories'
        )
      }
      footerButtons={actions}
      isOpen={isOpen}
      onClose={onClose}
      modalBodyProps={{
        display: 'flex'
      }}
      size="3xl"
    >
      <Flex width="100%" flexWrap="wrap" gap={4} minHeight="20vh">
        {!!items.length ? (
          items.map((item) => (
            <ContentCard
              key={item.id}
              onClick={() => setSelection(item)}
              isSelected={selection?.id === item.id}
              {...item}
            />
          ))
        ) : (
          <Center width="full">{t('messages.sorry_no_records_found')}</Center>
        )}
      </Flex>
    </ModalContainer>
  );
};
