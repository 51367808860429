import { ComponentStyleConfig } from '@chakra-ui/theme';

export const Select: ComponentStyleConfig = {
  baseStyle: {
    field: {},
    icon: {
      color: 'gray.500'
    }
  }
};
