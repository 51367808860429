import { ConversationMessageResponse, ENDPOINTS } from 'api';
import axios from 'axios';
import { ICustomerConversationMessage } from 'types/customer';

export const fetchConversationMessages = async ({
  conversationId
}: {
  conversationId: string;
}): Promise<ICustomerConversationMessage[]> => {
  const { data } = await axios.get<ConversationMessageResponse[]>(
    `${ENDPOINTS.CONVERSATIONS}/messages/${conversationId}`
  );

  return data;
};
