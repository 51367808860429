import { ChakraProvider } from '@chakra-ui/react';
import { QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { i18n } from 'i18n';
import { ReactNode } from 'react';
import { I18nextProvider } from 'react-i18next';
import { Provider as ReduxProvider } from 'react-redux';
import { queryClient } from 'services/queryClient';
import { store } from 'store';
import { theme } from 'utils/theme';

export const Providers = ({
  children
}: {
  children: ReactNode;
}): JSX.Element => (
  <I18nextProvider i18n={i18n}>
    <ChakraProvider theme={theme}>
      <QueryClientProvider client={queryClient}>
        <ReduxProvider store={store}>{children}</ReduxProvider>
        <ReactQueryDevtools initialIsOpen={false} />
      </QueryClientProvider>
    </ChakraProvider>
  </I18nextProvider>
);
