import { ComponentStyleConfig } from '@chakra-ui/theme';

export const FormLabel: ComponentStyleConfig = {
  baseStyle: {
    color: 'gray.600',
    mb: 1,
    marginEnd: 0,
    fontSize: 'sm',
    fontWeight: 'semibold'
  }
};

export const FormError: ComponentStyleConfig = {
  baseStyle: {
    text: {
      color: 'gray.600',
      mt: 1,
      fontSize: 'sm',
      fontWeight: 'semibold'
    }
  }
};

export const Form: ComponentStyleConfig = {
  baseStyle: {
    helperText: {
      textAlign: 'start',
      color: 'gray.500',
      marginTop: 1
    }
  }
};
