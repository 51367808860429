import { DomainConfigListResponse, ENDPOINTS, WithPagination } from 'api';
import { serializeBodyFilters } from 'api/api.utils';
import axios from 'axios';
import { GlobalFilters, QueryFilters } from 'types';

export const fetchDomainsConfigList = async ({
  queryFilters,
  globalFilters
}: {
  queryFilters: QueryFilters;
  globalFilters: GlobalFilters;
}): Promise<WithPagination<DomainConfigListResponse[]>> => {
  const { data } = await axios.post<WithPagination<DomainConfigListResponse[]>>(
    ENDPOINTS.DOMAINS_CONFIG_LIST,
    serializeBodyFilters({ queryFilters, globalFilters })
  );

  return data;
};
