import { useQuery } from '@tanstack/react-query';
import { fetchModerationAboutMeList } from 'api';
import { ModerationPostStatus } from 'enum';
import { QueryKey } from 'enum/api';
import { useAppSelector } from 'hooks';
import { selectAppFilters } from 'store/app';
import { QueryFilters } from 'types';

export const useModerationAboutMeList = (
  status: ModerationPostStatus,
  queryFilters: QueryFilters
) => {
  const globalFilters = useAppSelector(selectAppFilters);

  return useQuery(
    [QueryKey.ModerationAboutMe, status, globalFilters, queryFilters],
    () => fetchModerationAboutMeList({ status, globalFilters, queryFilters }),
    {
      retry: false,
      refetchOnWindowFocus: false,
      keepPreviousData: true
    }
  );
};
