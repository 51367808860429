import {
  AspectRatio,
  Box,
  BoxProps,
  FormLabel,
  HStack,
  IconButton,
  Image,
  Text
} from '@chakra-ui/react';
import { AddButton, EditIcon } from 'components';
import { AssetType, ContentSection, ModalType } from 'enum';
import { useAppDispatch, useVideoThumbnails } from 'hooks';
import { useTranslation } from 'react-i18next';
import { openModal } from 'store';

type Item = {
  id: string;
  preview: string;
  previewType: AssetType;
  description: string;
};

type Props = {
  userId?: string | null;
  selectedItem?: Item | null;
  onChange: (item: Item) => void;
  isReadonly?: boolean;
  contentType: ContentSection.GalleryImages | ContentSection.Stories;
} & Omit<BoxProps, 'onChange'>;

export const ContentSelect = ({
  userId,
  selectedItem,
  onChange,
  isReadonly,
  contentType,
  ...boxProps
}: Props): JSX.Element => {
  const [t] = useTranslation();
  const dispatch = useAppDispatch();
  const { thumbnail } = useVideoThumbnails();
  const addUser = () => {
    if (isReadonly || !userId) {
      return;
    }

    dispatch(
      openModal({
        id: ModalType.CustomerContentList,
        meta: {
          onChooseItem: onChange,
          userId: userId ?? undefined,
          contentType
        }
      })
    );
  };

  return (
    <Box {...boxProps}>
      <FormLabel>{t('keywords.source')}</FormLabel>
      <Box padding={4} backgroundColor="gray.100" borderRadius="1.25rem">
        {selectedItem ? (
          <HStack justifyContent="space-between">
            <HStack>
              <AspectRatio
                width={10}
                ratio={1}
                borderRadius={4}
                overflow="hidden"
              >
                <Image
                  src={
                    selectedItem.previewType === AssetType.Video
                      ? thumbnail(selectedItem.preview)
                      : selectedItem.preview
                  }
                  alt={selectedItem.description}
                  fallback={<Box />}
                />
              </AspectRatio>
              <Text>{selectedItem.description}</Text>
            </HStack>
            {!isReadonly && (
              <IconButton
                icon={<EditIcon fontSize="2xl" />}
                aria-label="edit user"
                variant="gray"
                color="gray.500"
                _hover={{
                  color: 'initial'
                }}
                onClick={addUser}
              />
            )}
          </HStack>
        ) : (
          <AddButton onClick={addUser} justifySelf="center">
            {t('actions.add_source')}
          </AddButton>
        )}
      </Box>
    </Box>
  );
};
