import { createAction } from '@reduxjs/toolkit';

export const updateSelection = createAction(
  'filter/updateSelection',
  (filerKey: string, values: (string | number | undefined)[]) => ({
    payload: {
      filerKey,
      values
    }
  })
);

export const resetSelection = createAction(
  'filter/resetSelection',
  (payload: { filerKey: string; defaultValues?: string[] }[]) => ({
    payload
  })
);
// export const resetSelection = createAction(
//   'filter/resetSelection',
//   (filerKeys: string | string[]) => ({
//     payload: filerKeys
//   })
// );

export const clearAll = createAction('filter/clearAll');
