import { ComponentStyleConfig } from '@chakra-ui/theme';

export const Checkbox: ComponentStyleConfig = {
  baseStyle: {
    control: {
      border: '1px',
      borderRadius: 'md'
    }
  },
  sizes: {
    xl: {
      control: {
        boxSize: 6
      },
      label: { fontSize: 'md', ms: 3 },
      icon: { fontSize: 'xs' }
    }
  },
  defaultProps: {
    size: 'xl',
    colorScheme: 'primary'
  }
};
