import { ReportAuthorType } from 'enum';
import { getAllEnumValues } from 'enum-for';
import { IncomePeriodType, ReportStatus } from 'enum/api';
import { STATISTIC_TOTAL } from 'utils';

import {
  IncomeMoneyBalanceChartData,
  IncomeMoneyBalanceResponse,
  ReportsByStatusApiResponse,
  ReportsByStatusResponse,
  ReportsByStatusTotalResponse
} from './AdminPanel.types';

export const serializeReportsByStatusResponse = (
  data: ReportsByStatusApiResponse
): {
  total: ReportsByStatusTotalResponse[];
  reportsByStatus: ReportsByStatusResponse[];
} => {
  const totalData: ReportsByStatusTotalResponse[] = [
    {
      id: STATISTIC_TOTAL,
      data: [ReportAuthorType.Admin, ReportAuthorType.User].map((author) => ({
        author,
        value: data[STATISTIC_TOTAL][author]
      }))
    }
  ];

  const reportsByStatus: ReportsByStatusResponse[] = [
    ReportAuthorType.Admin,
    ReportAuthorType.User
  ].map((author) => ({
    id: author,
    data: getAllEnumValues(ReportStatus).map((status) => ({
      status,
      value: data[author][status]
    }))
  }));

  return {
    total: totalData,
    reportsByStatus
  };
};

export const serializeIncomeMoneyBalanceResponse = (
  response: IncomeMoneyBalanceResponse
): IncomeMoneyBalanceResponse => {
  const incomeData = response.find(
    ({ id }) => id === IncomePeriodType.Income
  ) as IncomeMoneyBalanceChartData;

  return response.map(({ id, data }) => {
    if (id === IncomePeriodType.PrevIncome) {
      return {
        id,
        data: data.map(({ id: currencyId, data }) => ({
          id: currencyId,
          data: data.map(({ value }, idx) => ({
            value,
            // For previous income data we should change "date" to be the same as inside 'Income' date array.
            // That need to correctly get "X" axis values as uniq values for the chart axis.
            date:
              incomeData.data.find(({ id }) => id === currencyId)?.data[idx]
                ?.date || ''
          }))
        }))
      };
    }

    return {
      id,
      data
    };
  });
};
