import { Button, VStack } from '@chakra-ui/react';
import { fetchPopup, PopupMetrics } from 'api';
import { ModalType } from 'enum';
import { PopupStatus } from 'enum/api';
import { useAppDispatch, useChakraToast, useUpdatePopupStatus } from 'hooks';
import { useTranslation } from 'react-i18next';
import { openModal } from 'store';

type Props = {
  popupId: string;
  status: PopupStatus;
  metrics: PopupMetrics | null;
};

export const ActionCell = ({
  popupId,
  status,
  metrics
}: Props): JSX.Element => {
  const [t] = useTranslation();
  const toast = useChakraToast();
  const dispatch = useAppDispatch();

  const { mutate, isLoading } = useUpdatePopupStatus();

  const onHandleStatus = (newStatus: PopupStatus) => {
    mutate(
      {
        popupId,
        status: newStatus
      },
      {
        onError(error) {
          toast({
            status: 'error',
            title: t('errors.codes.unknown'),
            description: error.message
          });
        }
      }
    );
  };

  const onDeletePopup = () => {
    dispatch(
      openModal({
        id: ModalType.DeletePopup,
        meta: {
          popupId
        }
      })
    );
  };

  const onEditPopup = () => {
    (async () => {
      const popup = await fetchPopup(popupId);

      if (popup)
        dispatch(
          openModal({
            id: ModalType.EditPopup,
            meta: {
              popup
            }
          })
        );
    })();
  };

  const onViewPopupMetrics = () => {
    if (metrics)
      dispatch(
        openModal({
          id: ModalType.ViewPopupMetrics,
          meta: {
            metrics
          }
        })
      );
  };

  return (
    <VStack>
      {status === PopupStatus.Activated ? (
        <Button
          variant="link"
          colorScheme="secondary"
          isLoading={isLoading}
          onClick={() => onHandleStatus(PopupStatus.NotActivated)}
        >
          {t('actions.deactivate')}
        </Button>
      ) : (
        <Button
          variant="link"
          colorScheme="primary"
          isLoading={isLoading}
          onClick={() => onHandleStatus(PopupStatus.Activated)}
        >
          {t('actions.activate')}
        </Button>
      )}
      <Button variant="link" colorScheme="primary" onClick={onEditPopup}>
        {t('actions.edit')}
      </Button>
      <Button variant="link" colorScheme="red" onClick={onDeletePopup}>
        {t('actions.delete')}
      </Button>
      {metrics && (
        <Button variant="link" colorScheme="green" onClick={onViewPopupMetrics}>
          {t('actions.metrics')}
        </Button>
      )}
    </VStack>
  );
};
