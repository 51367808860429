import { TruncateText } from 'components/TruncateText';
import { getName } from 'i18n-iso-countries';
import { useTranslation } from 'react-i18next';

export const CountryList = ({
  countryCodes,
  onClick
}: {
  countryCodes: string[];
  onClick?: () => void;
}): JSX.Element => {
  const [, { language }] = useTranslation();

  return (
    <TruncateText maxWidth="md" onClick={onClick}>
      {countryCodes.map((code) => getName(code, language)).join(', ')}
    </TruncateText>
  );
};
