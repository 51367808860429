import { ENDPOINTS, WithPagination } from 'api';
import { serializeBodyFilters } from 'api/api.utils';
import axios from 'axios';
import { QueryFilters } from 'types';
import { ICustomerReports } from 'types/customer';

import { CustomerReportsResponse } from './Reports.types';

export const fetchCustomerReportsByUser = async ({
  customerId,
  queryFilters
}: {
  customerId: string;
  queryFilters: QueryFilters;
}): Promise<WithPagination<ICustomerReports[]>> => {
  const { data } = await axios.post<WithPagination<CustomerReportsResponse[]>>(
    `${ENDPOINTS.CUSTOMER_REPORTS}/by-user/${customerId}/list`,
    serializeBodyFilters({ queryFilters })
  );

  return data;
};
