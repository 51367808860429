import { ENDPOINTS } from 'api/endpoints';
import axios from 'axios';
import { ICustomer } from 'types/customer';

import { CustomerResponse } from '../Customers.types';

export const fetchCustomerInfo = async (id: string): Promise<ICustomer> => {
  const { data } = await axios.get<CustomerResponse>(
    `${ENDPOINTS.CUSTOMER}/${id}`
  );

  return data;
};
