import {
  ComponentStyleConfig,
  SystemStyleFunction,
  SystemStyleObject
} from '@chakra-ui/react';

const baseStyle: SystemStyleFunction = (props) => {
  const { colorScheme: c } = props;

  if (c === 'black') {
    return {
      color: 'black',
      bg: 'transparent',
      _hover: {
        color: 'white',
        bg: 'gray.600'
      },
      _active: {
        color: 'white',
        bg: 'black'
      }
    };
  }

  if (c === 'white') {
    return {
      color: 'white',
      bg: 'transparent',
      _hover: {
        color: 'white',
        bg: 'gray.800'
      },
      _active: {
        color: 'black',
        bg: 'white'
      }
    };
  }

  return {};
};

const variantRound: SystemStyleObject = {
  borderRadius: 'full'
};

const variants = {
  round: variantRound
};

const buttonSizeCss = '--close-button-size';

export const CloseButton: ComponentStyleConfig = {
  baseStyle,
  variants,
  sizes: {
    sm: {
      [buttonSizeCss]: 'sizes.4',
      fontSize: 'xs'
    }
  },
  defaultProps: {
    size: 'md'
  }
};
