import { Icon, IconProps } from '@chakra-ui/react';
import { StatusMarkIcon } from 'components';
import { OnlineStatus } from 'enum';

type Props = IconProps & {
  status: OnlineStatus | null;
};

export const StatusMark = ({ status, ...iconProps }: Props): JSX.Element => {
  let color;

  switch (status) {
    case OnlineStatus.Online:
      color = 'success';
      break;
    case OnlineStatus.Offline:
      color = 'error';
      break;
    default:
      color = 'gray.600';
  }

  return <Icon as={StatusMarkIcon} color={color} boxSize={2} {...iconProps} />;
};
