import {
  ComponentWithAs,
  IconProps,
  Tag,
  TagLabel,
  TagLeftIcon,
  TagProps,
  TagRightIcon
} from '@chakra-ui/react';
import { ReactNode } from 'react';

type Props = TagProps & {
  children: ReactNode;
  leftIcon?: ComponentWithAs<'svg', IconProps>;
  rightIcon?: ComponentWithAs<'svg', IconProps>;
};

export const Label = ({
  children,
  leftIcon,
  rightIcon,
  ...tagProps
}: Props): JSX.Element => (
  <Tag variant="subtle" whiteSpace="nowrap" {...tagProps}>
    {leftIcon && <TagLeftIcon as={leftIcon} />}
    <TagLabel>{children}</TagLabel>
    {rightIcon && <TagRightIcon as={rightIcon} />}
  </Tag>
);
