import { Button, Wrap } from '@chakra-ui/react';
import { useModerationItemsTotal } from 'hooks';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  getModerationPageNameByUrl,
  MODERATION_PAGE_LINK_BY_PAGE
} from 'routes/routes.utils';

import {
  getModerationPageLabel,
  MODERATION_PAGE,
  MODERATION_PAGE_LIST
} from '../Moderation.utils';

export const ModerationTabs = (): JSX.Element => {
  const [t] = useTranslation();
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const selectedTab = getModerationPageNameByUrl(pathname);

  const { data: totalItems } = useModerationItemsTotal();

  return (
    <Wrap shouldWrapChildren spacing={3}>
      {MODERATION_PAGE_LIST.map((tab) => (
        <Button
          key={tab}
          variant="ghost"
          colorScheme="gray"
          aria-selected={tab === selectedTab}
          isDisabled={!MODERATION_PAGE[tab]}
          onClick={() => {
            navigate(MODERATION_PAGE_LINK_BY_PAGE[tab]);
          }}
        >
          {getModerationPageLabel(tab, totalItems, t)}
        </Button>
      ))}
    </Wrap>
  );
};
