import { HStack } from '@chakra-ui/react';
import { RowData, TableMeta } from '@tanstack/react-table';
import { Avatar, StatusMark } from 'components';
import { PageName } from 'enum';
import { useNavigate } from 'react-router-dom';
import { SIDE_NAV_LINK_BY_PAGE } from 'routes/routes.utils';

export const CustomerInfoBlock = <TData extends RowData>({
  userId,
  customerId,
  conversation
}: {
  userId: string;
  customerId: TableMeta<TData>['customerId'];
  conversation: TableMeta<TData>['conversation'];
}): JSX.Element | null => {
  const navigate = useNavigate();

  if (!conversation || !customerId) return null;

  const { usersInfoMap, creatorId } = conversation;

  const isPartner = userId !== customerId;
  const isInitiator = creatorId === userId;

  const { displayedNickname, email, photoUrl: avatar } = usersInfoMap[userId];

  return (
    <HStack>
      <StatusMark color="error" status={null} opacity={isInitiator ? 1 : 0} />
      <Avatar
        title={displayedNickname}
        description={email}
        src={avatar}
        onClick={
          isPartner
            ? () => {
                navigate(
                  `/${SIDE_NAV_LINK_BY_PAGE[PageName.Customers]}/${userId}`
                );
              }
            : undefined
        }
      />
    </HStack>
  );
};
