import { FieldArrayPath, useFieldArray, useFormContext } from 'react-hook-form';

import { GeneralSettingsFormData } from './FormContent.types';

type TFieldArrayName = FieldArrayPath<GeneralSettingsFormData>;

type Props<T extends TFieldArrayName> = {
  name: T;
  getInitialFieldsState: () => T extends keyof GeneralSettingsFormData
    ? GeneralSettingsFormData[T][number]
    : never;
};

export const useSettingsFormFields = <T extends TFieldArrayName>({
  name,
  getInitialFieldsState
}: Props<T>) => {
  const { control, clearErrors } = useFormContext<GeneralSettingsFormData>();

  const fieldArray = useFieldArray<GeneralSettingsFormData, typeof name>({
    name,
    control
  });

  const { fields, append, remove, update } = fieldArray;

  const handleRemove = (index: number) => {
    if (fields.length === 1) {
      update(index, getInitialFieldsState());
      clearErrors(`${name}.${index}`);
    } else {
      remove(index);
    }
  };

  const appendField = () => {
    append(getInitialFieldsState());
  };

  return {
    ...fieldArray,
    handleRemove,
    appendField
  };
};
