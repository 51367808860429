import { Input, NumberInput, NumberInputField } from '@chakra-ui/react';
import isNaN from 'lodash/isNaN';

import { RangeInputProps } from './RangeList.types';

type Props = RangeInputProps & {
  onChange: (value: string | number | undefined) => void;
};

export const RangeInput = (props: Props): JSX.Element => {
  switch (props.type) {
    case 'number':
      const { type, value, onChange, ...rest } = props;

      return (
        <NumberInput
          {...rest}
          value={value ?? ''}
          onChange={(valueAsString, valueAsNumber) => {
            const newValue = rest.precision
              ? valueAsString
              : isNaN(valueAsNumber)
              ? undefined
              : valueAsNumber;

            onChange(newValue);
          }}
        >
          <NumberInputField />
        </NumberInput>
      );

    default: {
      const { type, value, onChange, ...rest } = props;

      return (
        <Input
          {...rest}
          type={type}
          value={value ?? ''}
          onChange={(e) => onChange(e.target.value)}
        />
      );
    }
  }
};
