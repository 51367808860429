import { Table } from 'components';
import { ModalContainer } from 'containers';
import { ModalType } from 'enum';
import { useCustomerEmailHistory, useModalActions } from 'hooks';
import { useTranslation } from 'react-i18next';

import { emailColumns } from './EmailHistory.utils';

export const EmailHistory = (): JSX.Element => {
  const [t] = useTranslation();

  const { isOpen, onClose, meta } = useModalActions(ModalType.EmailHistory);

  const { data } = useCustomerEmailHistory(meta?.customerId || '');

  return (
    <ModalContainer
      header={t('attribute.title.email_history')}
      footerButtons={[
        {
          text: t('actions.ok')
        }
      ]}
      isOpen={isOpen}
      onClose={onClose}
      scrollBehavior="outside"
      size="xl"
    >
      {!!data && (
        <Table
          data={data}
          columns={emailColumns}
          totalItems={1}
          shouldHidePagination
          tableOptions={{
            manualSorting: false
          }}
        />
      )}
    </ModalContainer>
  );
};
