import { ContactUsUpdateTicketRequest, ENDPOINTS } from 'api';
import axios from 'axios';

export const fetchUpdateTicket = ({
  ticketId,
  ...body
}: {
  ticketId: string;
} & ContactUsUpdateTicketRequest) =>
  axios.patch(`${ENDPOINTS.CONTACT_US_TICKET}/${ticketId}`, body);
