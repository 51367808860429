import {
  Button,
  FormControl,
  FormLabel,
  Heading,
  Input,
  VStack
} from '@chakra-ui/react';
import { Password } from 'components';
import { FormField } from 'enum';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { isNotEmpty } from 'utils/validation';

import { AuthorizationFormField } from './Authorization.types';

type Props = {
  title: string;
  buttonLabel: string;
  isLoading: boolean;
};

export const LoginForm = ({
  title,
  buttonLabel,
  isLoading
}: Props): JSX.Element => {
  const [t] = useTranslation();

  const {
    register,
    formState: { dirtyFields }
  } = useFormContext<AuthorizationFormField>();

  const isDisabledButton = !isNotEmpty(dirtyFields, [
    FormField.Email,
    FormField.Password
  ]);

  return (
    <VStack spacing={16} width="26.25rem" alignItems="stretch">
      <Heading as="h1" size="xxl">
        {title}
      </Heading>
      <VStack spacing={6}>
        <FormControl>
          <FormLabel>{t('keywords.email')}</FormLabel>
          <Input
            placeholder={t('keywords.email')}
            maxLength={50}
            {...register('email')}
          />
        </FormControl>
        <Password
          placeholder={t('keywords.password')}
          label={t('keywords.password')}
          {...register('password')}
        />
      </VStack>
      <Button
        type="submit"
        w="full"
        isLoading={isLoading}
        isDisabled={isDisabledButton}
      >
        {buttonLabel}
      </Button>
    </VStack>
  );
};
