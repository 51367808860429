import { WithPagination } from 'api';
import { ICustomerConversation } from 'types/customer';

import { CustomerConversationResponse } from '../Customers.types';

export const serializeCustomerConversationData = (
  response: WithPagination<CustomerConversationResponse[]>
): WithPagination<ICustomerConversation[]> => {
  const data = response.data.map<ICustomerConversation>((conversation) => {
    const { usersInfo } = conversation;

    const usersInfoMap = usersInfo.reduce((acc, user) => {
      acc[user.id] = user;

      return acc;
    }, {} as Record<string, ICustomerConversation['usersInfo'][number]>);

    return {
      ...conversation,
      usersInfoMap
    };
  });

  return {
    ...response,
    data
  };
};
