import { Icon, IconProps } from '@chakra-ui/react';

export const CategoryIcon = (props: IconProps): JSX.Element => (
  <Icon viewBox="0 0 24 24" fill="none" {...props}>
    <rect
      x="3.5"
      y="4"
      width="7"
      height="7"
      rx="2.5"
      strokeWidth="1.5"
      stroke="currentColor"
    />
    <rect
      x="3.5"
      y="14"
      width="7"
      height="7"
      rx="2.5"
      strokeWidth="1.5"
      stroke="currentColor"
    />
    <rect
      x="13.5"
      y="4"
      width="7"
      height="7"
      rx="2.5"
      strokeWidth="1.5"
      stroke="currentColor"
    />
    <rect
      x="13.5"
      y="14"
      width="7"
      height="7"
      rx="2.5"
      strokeWidth="1.5"
      stroke="currentColor"
    />
  </Icon>
);
