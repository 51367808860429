import { HStack } from '@chakra-ui/react';
import { BaseChartDataTemplate } from 'api';
import { LineChartData } from 'containers';
import { useTranslation } from 'react-i18next';
import { STATISTIC_TOTAL } from 'utils';

import { STAT_ICONS } from '../RegisteredUsers.utils';

import { StatisticCard } from '.';

type Props<T extends BaseChartDataTemplate> = {
  data: LineChartData<T>[];
};

export const StatisticCardContainer = <T extends BaseChartDataTemplate>({
  data
}: Props<T>): JSX.Element => {
  const [t] = useTranslation();

  return (
    <HStack>
      {data.map(({ serieId, data, getLabel, color }) => (
        <StatisticCard
          key={serieId}
          icon={STAT_ICONS[serieId] || STAT_ICONS[STATISTIC_TOTAL]}
          color={color}
          title={data[data.length - 1].y}
          subtitle={getLabel(t)}
        />
      ))}
    </HStack>
  );
};
