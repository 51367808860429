import { chakra, Portal, usePopper, useTheme } from '@chakra-ui/react';
import { AnimatePresence, motion, Variants } from 'framer-motion';
import { useEffect, useRef } from 'react';
import { GroupBase } from 'react-select';
import { MenuPortalProps } from 'react-select/dist/declarations/src/components/Menu';

const MotionDiv = chakra(motion.div);

const scale: Variants = {
  exit: {
    opacity: 0,
    scale: 0.95,
    transition: {
      duration: 0.1,
      ease: [0.4, 0, 1, 1]
    }
  },
  enter: {
    scale: 1,
    opacity: 1,
    transition: {
      duration: 0.15,
      ease: [0, 0, 0.2, 1]
    }
  }
};

export const MenuPortal = <
  Option,
  IsMulti extends boolean,
  Group extends GroupBase<Option>
>(
  props: MenuPortalProps<Option, IsMulti, Group>
): JSX.Element => {
  const theme = useTheme();
  const controlRef = useRef<HTMLDivElement | null>(null);

  const {
    children,
    controlElement,
    selectProps: { menuIsOpen: isOpen, menuProps }
  } = props;

  const {
    containerRef,
    minWidth,
    placement = 'bottom-start',
    matchWidth: shouldMatchWidth = true,
    shouldAttachToControl = false,
    ...popperProps
  } = menuProps || {};

  const { getPopperProps, referenceRef, transformOrigin } = usePopper({
    ...popperProps,
    matchWidth: shouldMatchWidth,
    placement,
    direction: theme.direction
  });

  useEffect(() => {
    if (shouldAttachToControl) {
      controlRef.current = controlElement;
      referenceRef(controlElement);

      return;
    }

    if (containerRef) {
      referenceRef(containerRef.current);

      return;
    }

    referenceRef(controlElement);
  }, [controlElement, referenceRef, containerRef, shouldAttachToControl]);

  return (
    <AnimatePresence>
      {isOpen && (
        <Portal
          containerRef={shouldAttachToControl ? controlRef : containerRef}
        >
          <chakra.div
            {...getPopperProps()}
            __css={{
              zIndex: 'popover',
              // https://popper.js.org/docs/v2/modifiers/hide/
              '&[data-popper-reference-hidden]': {
                visibility: 'hidden',
                pointerEvents: 'none'
              }
            }}
          >
            <MotionDiv
              variants={scale}
              initial="exit"
              animate="enter"
              exit="exit"
              transformOrigin={transformOrigin}
              minWidth={minWidth}
            >
              {children}
            </MotionDiv>
          </chakra.div>
        </Portal>
      )}
    </AnimatePresence>
  );
};
