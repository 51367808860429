import {
  omitThemingProps,
  useFormControlProps,
  useMultiStyleConfig
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { GroupBase } from 'react-select';
import ReactSelectAsync from 'react-select/async';

import {
  DropdownProps,
  DropdownStyleConfig,
  InputOption
} from './LocationDropdown.types';
import { StylesProvider } from './LocationDropdown.utils';
import * as customComponents from './components';

export const LocationDropdown = <
  Option extends InputOption<unknown>,
  IsMulti extends boolean = false,
  Group extends GroupBase<Option> = GroupBase<Option>
>(
  props: DropdownProps<Option, IsMulti, Group>
): JSX.Element => {
  const [t] = useTranslation();

  const styles = useMultiStyleConfig('Dropdown', props) as DropdownStyleConfig;
  const { useAsync, isInvalid, ...ownProps } = omitThemingProps(props);
  const formControlProps = useFormControlProps(props);

  const selectProps = {
    noOptionsMessage: () => t('keywords.no_options'),
    isClearable: false,
    isSearchable: false,
    isInvalid: isInvalid || formControlProps.isInvalid,
    closeMenuOnSelect: !ownProps.isMulti,
    ...ownProps,
    components: { ...customComponents, ...ownProps.components },
    // should be provided to always render  MenuPortal (value never use)
    menuPortalTarget: document.body
  };

  return (
    <StylesProvider value={styles}>
      <ReactSelectAsync {...selectProps} />
    </StylesProvider>
  );
};
