import { Button, Grid, GridItem, HStack } from '@chakra-ui/react';
import { AddButton, Search, Table } from 'components';
import { Access, ContentContainer, TableFilters } from 'containers';
import { ModalType } from 'enum';
import { PermissionAction, PermissionResource } from 'enum/api';
import { useAdminList, useAppDispatch, useTableQueryController } from 'hooks';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { openModal } from 'store/modal';

import {
  columns,
  getSearchOptions,
  tableFilters
} from './AdminManagement.utils';

export const AdminManagement = (): JSX.Element => {
  const [t] = useTranslation();
  const dispatch = useAppDispatch();

  const {
    queryFilters,
    action: { onChangeFilters, onChangeSort, onChangePagination }
  } = useTableQueryController();

  const { data } = useAdminList(queryFilters);

  const searchOptions = useMemo(() => getSearchOptions(t), [t]);

  const handleCreateNewUser = () => {
    dispatch(
      openModal({
        id: ModalType.CreateAdminUser
      })
    );
  };

  const handleResetAllPasswords = () => {
    dispatch(
      openModal({
        id: ModalType.ResetAllAdminPasswords
      })
    );
  };

  return (
    <ContentContainer height="100%">
      <Grid
        templateAreas={`"search actions"
                       "filters filters"
                       "table table"
       `}
        templateColumns="26.25rem 1fr"
        templateRows="min-content min-content 1fr"
        rowGap={6}
        height="100%"
      >
        <GridItem area="search">
          <Search options={searchOptions} onChange={() => {}} />
        </GridItem>
        <GridItem area="actions" justifySelf="flex-end">
          <HStack spacing={3}>
            <Access
              restrictions={{
                resource: PermissionResource.Admins,
                actions: PermissionAction.Create
              }}
              noAccessRender={null}
            >
              <AddButton onClick={handleCreateNewUser}>
                {t('actions.create_admin_user')}
              </AddButton>
            </Access>
            <Access
              restrictions={{
                resource: PermissionResource.AdminPassword,
                actions: PermissionAction.Update
              }}
              noAccessRender={null}
            >
              <Button variant="ghostWhite" onClick={handleResetAllPasswords}>
                {t('actions.reset_all_passwords')}
              </Button>
            </Access>
          </HStack>
        </GridItem>
        <GridItem area="filters">
          <TableFilters filters={tableFilters} onChange={onChangeFilters} />
        </GridItem>
        {data && (
          <GridItem area="table" overflow="hidden">
            <Table
              data={data.data}
              columns={columns}
              onChangeSort={onChangeSort}
              onChangePagination={onChangePagination}
              totalItems={data.totalItems}
              pagination={queryFilters.pagination}
            />
          </GridItem>
        )}
      </Grid>
    </ContentContainer>
  );
};
