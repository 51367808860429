import { CreateDomainConfigRequestData, ENDPOINTS } from 'api';
import axios from 'axios';

export const fetchUpdateDomainsConfig = ({
  domainConfigId,
  ...body
}: {
  domainConfigId: string;
} & Partial<CreateDomainConfigRequestData>) =>
  axios.patch(`${ENDPOINTS.DOMAINS_CONFIG}/${domainConfigId}`, body);
