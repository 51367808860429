import { Settings } from 'api';
import { InputElement } from 'enum';
import { useAppSelector } from 'hooks';
import { debounce } from 'lodash';
import { useEffect, useMemo, useReducer } from 'react';
import { useTranslation } from 'react-i18next';
import { useLatest } from 'react-use';
import { selectSettings } from 'store';
import { generateOptionsFromSettingsByKeys } from 'utils';

import {
  TableFilterReducerState,
  TableFiltersProps
} from './TableFilters.types';
import {
  getTableFilterInitialState,
  serializeGroupFilters,
  serializeOutputFilters,
  serializeSingleFilter
} from './TableFilters.utils';
import { clearAll, tableFilterReducer } from './reducer';

type Props = Pick<TableFiltersProps, 'filters' | 'onChange'>;

export const useTableFilters = ({ filters, onChange }: Props) => {
  const [t] = useTranslation();

  const settings = useAppSelector(selectSettings);

  const [data, dispatch] = useReducer(tableFilterReducer, undefined, () =>
    getTableFilterInitialState({ filters })
  );

  const latestOnChange = useLatest(onChange);

  const debouncedUpdate = useMemo(
    () =>
      debounce((state: TableFilterReducerState) => {
        const { current: onChange } = latestOnChange;

        onChange?.(
          serializeOutputFilters({
            filters,
            data: state
          })
        );
      }, 400),
    [latestOnChange, filters]
  );

  useEffect(() => {
    debouncedUpdate(data);
  }, [debouncedUpdate, data]);

  const optionsByKey = useMemo(() => {
    const keys = filters.reduce((acc, cur) => {
      const { optionKey, getOptions, type } = cur;

      if (!getOptions && type !== InputElement.Range) {
        acc.push(optionKey);
      }

      return acc;
    }, [] as (keyof Settings)[]);

    return generateOptionsFromSettingsByKeys(keys, t, settings);
  }, [filters, settings, t]);

  const singleFilters = filters.slice(0, 3).map((filter) =>
    serializeSingleFilter({
      filter,
      optionsByKey,
      selectedValues: data[filter.optionKey],
      settings,
      dispatch,
      t
    })
  );

  const groupFilters = filters.slice(3);

  const groupFilter =
    groupFilters.length > 0
      ? serializeGroupFilters({
          filters: groupFilters,
          optionsByKey,
          data,
          settings,
          dispatch,
          t
        })
      : null;

  const handleClearSelection = () => {
    dispatch(clearAll());
  };

  return {
    singleFilters,
    groupFilter,
    handleClearSelection
  };
};
