import { Icon, IconProps } from '@chakra-ui/react';

export const GenderMaleIcon = (props: IconProps): JSX.Element => (
  <Icon viewBox="0 0 24 24" fill="none" {...props}>
    <path
      d="M9.75 21C13.4779 21 16.5 17.9779 16.5 14.25C16.5 10.5221 13.4779 7.5 9.75 7.5C6.02208 7.5 3 10.5221 3 14.25C3 17.9779 6.02208 21 9.75 21Z"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M14.5217 9.47812L20.2499 3.75"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M15.75 3.75H20.25V8.25"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </Icon>
);
