import { Icon, IconProps } from '@chakra-ui/react';

export const FullScreenExitIcon = (props: IconProps): JSX.Element => (
  <Icon viewBox="0 0 24 24" stroke="gray.500" fill="none" {...props}>
    <path
      d="M8.5 2V8L2.5 8"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M8.5 21V15H2.5"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M15.5 2V8H21.5"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M15.5 21V15H21.5"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </Icon>
);
