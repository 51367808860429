import { Box, Center, ChakraTheme, useTheme, VStack } from '@chakra-ui/react';
import { useHtmlFontSize } from 'hooks';
import { useGauge } from 'use-gauge';
import { convertRemToPixels } from 'utils';

import { GaugeChartElementProps, GaugeChartProps } from './GaugeChart.types';
import { GaugeArc, GaugeNeedle, GaugeTicks } from './components';

export const GaugeChart = ({
  min,
  max,
  value,
  segments,
  settings,
  children
}: GaugeChartProps): JSX.Element => {
  const theme = useTheme<ChakraTheme>();
  const { htmlFontSizeValue } = useHtmlFontSize();

  const { radius } = settings;

  const gauge = useGauge({
    startAngle: 90,
    endAngle: 270,
    numTicks: 21,
    diameter: convertRemToPixels(radius, htmlFontSizeValue).pxValue * 2,
    domain: [min, max]
  });

  const itemProps: GaugeChartElementProps = {
    gauge,
    theme,
    segments,
    settings,
    currentValue: value,
    fontSize: htmlFontSizeValue
  };

  const { getSVGProps } = gauge;

  return (
    <Center width="100%" height="100%">
      <VStack spacing={5}>
        <svg {...getSVGProps()} overflow="visible">
          <GaugeArc {...itemProps} />
          <GaugeTicks {...itemProps} />
          <GaugeNeedle {...itemProps} />
        </svg>
        <Box>{children}</Box>
      </VStack>
    </Center>
  );
};
