import { Button, Wrap } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  getReportPageNameByUrl,
  REPORT_PAGE_LINK_BY_PAGE
} from 'routes/routes.utils';

import {
  getReportPageLabel,
  REPORT_PAGE,
  REPORT_PAGE_LIST
} from '../Reports.utils';

export const ReportTabs = (): JSX.Element => {
  const [t] = useTranslation();
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const selectedTab = getReportPageNameByUrl(pathname);

  return (
    <Wrap shouldWrapChildren spacing={3}>
      {REPORT_PAGE_LIST.map((tab) => (
        <Button
          key={tab}
          variant="ghost"
          colorScheme="gray"
          aria-selected={tab === selectedTab}
          isDisabled={!REPORT_PAGE[tab]}
          onClick={() => {
            navigate(REPORT_PAGE_LINK_BY_PAGE[tab]);
          }}
        >
          {getReportPageLabel(tab, t)}
        </Button>
      ))}
    </Wrap>
  );
};
