import { NavigationLink } from 'components';
import { ReactNode } from 'react';
import { getBaseCustomerLinkNavigation } from 'utils';

type Props = {
  children: ReactNode;
  customerId: string;
};

export const NicknameCell = ({ children, customerId }: Props): JSX.Element => (
  <NavigationLink to={getBaseCustomerLinkNavigation(customerId)}>
    {children}
  </NavigationLink>
);
