import {
  ComponentStyleConfig,
  SystemStyleFunction,
  SystemStyleObject
} from '@chakra-ui/react';
import produce from 'immer';

const variantSolid: SystemStyleFunction = (props): SystemStyleObject => {
  const { colorScheme: c } = props;

  const disabled = {
    color: 'white',
    bg: 'disabled'
  };
  const selected = {
    bg: 'gray.900',
    color: 'white'
  };
  const active = {
    bg: 'gray.600',
    color: 'white'
  };

  if (c === 'gray') {
    return {
      _active: {
        ...active,
        _selected: active
      },
      _hover: {
        _selected: selected
      },
      _selected: selected,
      _disabled: disabled
    };
  }

  return {
    _disabled: disabled
  };
};

const variantLink: SystemStyleFunction = (props) => {
  const { colorScheme: c } = props;
  return {
    _disabled: {
      color: 'disabled'
    },
    _hover: {
      color: `${c}.600`,
      textDecoration: 'none'
    }
  };
};

const variantGhostWhite: SystemStyleFunction = (props) => {
  const { colorScheme: c } = props;
  return {
    bg: 'white',
    border: '1px',
    borderColor: 'transparent',
    _hover: {
      color: `${c}.600`,
      border: '1px'
    },
    _active: {
      color: `${c}.700`,
      border: '1px'
    },
    _disabled: {
      color: 'disabled',
      bg: 'gray.200'
    }
  };
};

const variantGhostGray: SystemStyleFunction = (props) =>
  produce(variantGhostWhite(props), (draft) => {
    draft.bg = 'gray.100';
  });

const variantGhost: SystemStyleFunction = (props) => {
  const { colorScheme: c } = props;
  const activeStyles = {
    color: 'white',
    bgColor: `${c}.900`
  };
  return {
    color: `${c}.600`,
    _hover: {
      color: 'black',
      bg: `${c}.100`
    },
    _disabled: {
      color: `disabled`,
      bg: `${c}.100`
    },
    _active: activeStyles,
    _selected: activeStyles
  };
};

const variantLocale: SystemStyleObject = {
  border: '1px',
  borderColor: 'transparent',
  svg: {
    color: 'gray.500'
  },
  _hover: {
    borderColor: 'inherit'
  }
};

const variantIcon: SystemStyleFunction = () => ({
  background: 'gray.100',
  color: 'gray.500',
  _hover: {
    background: 'gray.200',
    color: 'primary.600'
  },
  _active: {
    background: 'gray.200',
    color: 'primary.700'
  },
  _disabled: {
    color: 'gray.500',
    background: 'gray.200'
  }
});

const variants = {
  solid: variantSolid,
  link: variantLink,
  ghostWhite: variantGhostWhite,
  ghostGray: variantGhostGray,
  ghost: variantGhost,
  locale: variantLocale,
  icon: variantIcon
};

export const Button: ComponentStyleConfig = {
  baseStyle: {
    alignItems: 'center',
    fontWeight: 'medium',
    _disabled: {
      opacity: 1,
      pointerEvents: 'none'
    },
    _readOnly: {
      pointerEvents: 'none'
    }
  },
  sizes: {
    rg: {
      h: 11,
      minW: 11,
      fontSize: 'md',
      lineHeight: 'base',
      px: 3,
      py: 2
    },
    sm: {
      h: 8,
      minW: 8,
      fontSize: 'md',
      lineHeight: 'base',
      px: 3,
      py: 1
    },
    lg: {
      fontSize: 'md',
      lineHeight: 'base',
      p: 3.5
    },
    md: {
      fontSize: 'sm',
      h: '2.375rem',
      minW: '2.375rem',
      lineHeight: 'base',
      px: 3,
      py: 2.5
    }
  },
  variants,
  defaultProps: {
    size: 'rg',
    colorScheme: 'primary'
  }
};
