import { ENDPOINTS } from 'api/endpoints';
import axios from 'axios';

export const fetchDeleteStaticPage = async ({
  id
}: {
  id: string;
}): Promise<void> => {
  await axios.delete(`${ENDPOINTS.STATIC_PAGES}/${id}`);
};
