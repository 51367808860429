import { JsonType } from './Settings.types';

export const parseMeta = (meta?: string | null): JsonType => {
  if (!meta) {
    return null;
  }

  try {
    return JSON.parse(meta);
  } catch (error) {
    return meta;
  }
};
