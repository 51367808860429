import { ENDPOINTS, UpdateGeneralSettingsRequestData } from 'api';
import axios from 'axios';

export const fetchUpdateGeneralSettings = ({
  country,
  body
}: {
  country: string;
  body: UpdateGeneralSettingsRequestData;
}) => axios.patch(`${ENDPOINTS.GENERAL_SETTINGS}/${country}`, body);
