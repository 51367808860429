import { useInfiniteQuery } from '@tanstack/react-query';
import { fetchCustomerConversations } from 'api';
import { QueryKey } from 'enum/api';
import { QueryFilters } from 'types';

export const useCustomerConversations = ({
  customerId,
  queryFilters,
  enabled
}: {
  customerId: string;
  queryFilters: QueryFilters;
  enabled?: boolean;
}) =>
  useInfiniteQuery(
    [QueryKey.CustomerConversations, customerId, queryFilters],
    () => fetchCustomerConversations({ customerId, queryFilters }),
    {
      retry: false,
      refetchOnWindowFocus: false,
      keepPreviousData: true,
      enabled
    }
  );
