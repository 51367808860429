import { createMultiStyleConfigHelpers } from '@chakra-ui/styled-system';
import { runIfFn } from '@chakra-ui/utils';
import { merge } from 'lodash';

import { parts } from '../parts';

import { variantButton } from './button';

const { definePartsStyle } = createMultiStyleConfigHelpers(parts);

const variant3Col = definePartsStyle((props) => ({
  menuList: {
    display: 'grid',
    gridTemplateColumns: 'repeat(3, 1fr)'
  }
}));

export const variantButton3Col = definePartsStyle(() =>
  merge(runIfFn(variantButton), runIfFn(variant3Col))
);
