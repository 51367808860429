import { TableFilterSchema } from 'containers';
import { format } from 'date-fns';
import {
  ContentSection,
  InputElement,
  OptionKey,
  ParameterType,
  Period
} from 'enum';
import { getAllEnumValues } from 'enum-for';

import {
  MessageImagesContent,
  GalleryImagesContent,
  StoriesContent
} from './Pages';

export const CONTENT_SECTION_LIST = getAllEnumValues(ContentSection);

export const CONTENT_SECTION: Record<ContentSection, () => JSX.Element> = {
  [ContentSection.MessageImages]: MessageImagesContent,
  [ContentSection.GalleryImages]: GalleryImagesContent,
  [ContentSection.Stories]: StoriesContent
};

export const chartFilters: TableFilterSchema[] = [
  {
    type: InputElement.Select,
    getLabel: (t) => t('keywords.period'),
    defaultValues: [Period.AllTime],
    optionKey: OptionKey.Period,
    updateOutput: (_, value) => ({
      key: 'period',
      value: value[0]
    })
  },
  {
    type: InputElement.Range,
    getLabel: (t) => t('keywords.custom_period'),
    optionKey: 'customRange',
    updateOutput: (key, value) => ({
      key,
      value: value
        .filter((date): date is string => !!date)
        .map((date) => new Date(date).toISOString())
    }),
    inputProps: {
      type: 'date',
      max: format(new Date(), 'dd.MM.yyyy')
    }
  },
  {
    type: InputElement.Checkbox,
    getLabel: (t) => t('keywords.gender'),
    optionKey: ParameterType.Gender,
    updateOutput: (_, value) => ({
      key: 'genders',
      value
    })
  }
];
