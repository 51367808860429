import { ENDPOINTS } from 'api/endpoints';
import axios, { AxiosResponse } from 'axios';

import {
  Admin,
  AdminFormData,
  AdminResponse,
  CreateAdminRequestData
} from './AdminManagement.types';

export const fetchCreateAdmin = async (
  admin: AdminFormData
): Promise<Admin> => {
  const { data } = await axios.post<
    AdminResponse,
    AxiosResponse<AdminResponse>,
    CreateAdminRequestData
  >(ENDPOINTS.ADMINS, admin);

  return data;
};
