import { City, Country, ICity } from 'country-state-city';

export const CITY_OBJECT_BY_COUNTRY_CODE = Country.getAllCountries().reduce(
  (acc, country) => {
    const countryCode = country.isoCode;

    const cityByCountry = City.getCitiesOfCountry(countryCode)?.reduce(
      (cities, city) => {
        if (city.name) {
          cities[city.name] = city;
        }

        return cities;
      },
      {} as Record<string, ICity>
    );

    acc[country.isoCode] = cityByCountry || {};

    return acc;
  },
  {} as Record<string, Record<string, ICity>>
);

export const isValidCountry = (
  countryCode?: string | null
): countryCode is string =>
  countryCode ? !!CITY_OBJECT_BY_COUNTRY_CODE[countryCode] : false;

export const getUniqCityArrayByCountryCode = (countryCode: string): string[] =>
  Object.keys(CITY_OBJECT_BY_COUNTRY_CODE[countryCode] || {});
