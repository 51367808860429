import { useMutation, useQueryClient } from '@tanstack/react-query';
import { fetchCreateReport } from 'api';
import { QueryKey } from 'enum/api';

export const useCreateReport = () => {
  const queryClient = useQueryClient();

  return useMutation(fetchCreateReport, {
    onSuccess(_, { reportedId }) {
      queryClient.invalidateQueries([
        QueryKey.ReportsAgainstCustomer,
        reportedId
      ]);
      queryClient.invalidateQueries([QueryKey.ReportCustomerList]);
      queryClient.invalidateQueries([QueryKey.ReportAdminList]);
      queryClient.invalidateQueries([QueryKey.ReportTotal]);
    }
  });
};
