import { ENDPOINTS } from 'api/endpoints';
import axios from 'axios';

export const fetchDeleteCustomer = ({
  id,
  adminComment
}: {
  id: string;
  adminComment: string;
}) => axios.post(`${ENDPOINTS.CUSTOMER}/delete/${id}`, { adminComment });
