import { MenuItem } from '@chakra-ui/react';
import { EllipsisButton } from 'components';
import { ModalType } from 'enum';
import { useAppDispatch } from 'hooks';
import { useTranslation } from 'react-i18next';
import { openModal } from 'store/modal';

export const EditLinkedAccountButton = ({
  linkId
}: {
  linkId: string;
}): JSX.Element => {
  const [t] = useTranslation();
  const dispatch = useAppDispatch();

  const onUnlinkAccount = () => {
    dispatch(
      openModal({
        id: ModalType.UnlinkLinkedAccount,
        meta: {
          linkId
        }
      })
    );
  };

  return (
    <EllipsisButton>
      <MenuItem onClick={onUnlinkAccount} isDisabled={false}>
        {t('actions.unlink')}
      </MenuItem>
    </EllipsisButton>
  );
};
