import { ENDPOINTS, PaymentSubscriptionOptionsByUserResponse } from 'api';
import axios from 'axios';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const fakedData: PaymentSubscriptionOptionsByUserResponse = {
  free: ['subscription1']
};

export const fetchSubscriptionOptionsByUser = async ({
  customerId
}: {
  customerId: string;
}): Promise<PaymentSubscriptionOptionsByUserResponse> => {
  const { data } = await axios.get<PaymentSubscriptionOptionsByUserResponse>(
    `${ENDPOINTS.PAYMENT_SUBSCRIPTION}/dropdown/${customerId}`
  );

  return data;
};
