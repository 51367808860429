import { PageName } from 'enum';
import { OptionKey } from 'enum/api';
import { useAppDispatch, useAppSelector } from 'hooks';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { SIDE_NAV_LINK_BY_PAGE } from 'routes/routes.utils';
import { selectAppFilters, updateFilter } from 'store/app';
import { selectSettings } from 'store/settings';
import { Option } from 'types';
import { ALL_COUNTRIES, generateOptionsFromSettings } from 'utils';

const PAGES_REGEXP_TO_DISABLE_COUNTRY_FILTER = [
  new RegExp(`/${SIDE_NAV_LINK_BY_PAGE[PageName.Customers]}/`, 'i'),
  new RegExp(`/${SIDE_NAV_LINK_BY_PAGE[PageName.AdminManagement]}/`, 'i'),
  new RegExp(`/${SIDE_NAV_LINK_BY_PAGE[PageName.Settings]}`, 'i')
];

export const useCountryFilter = (): {
  options: Option[];
  selectedValues: string[];
  handleChange: (values: string[]) => void;
  isDisabled: boolean;
} => {
  const [t] = useTranslation();
  const dispatch = useAppDispatch();
  const { pathname } = useLocation();

  const settings = useAppSelector(selectSettings);
  const { country } = useAppSelector(selectAppFilters);

  const options = useMemo(
    () => [
      {
        label: t('keywords.all'),
        value: ALL_COUNTRIES
      },
      ...generateOptionsFromSettings(OptionKey.Country, t, settings)
    ],
    [t, settings]
  );

  const handleChange = (values: string[]) => {
    if (values.length === 0 || values[values.length - 1] === ALL_COUNTRIES) {
      dispatch(
        updateFilter({
          country: null
        })
      );
      return;
    }

    if (values.length > 0) {
      const newCountry = values[0] === ALL_COUNTRIES ? values.slice(1) : values;

      dispatch(
        updateFilter({
          country: newCountry
        })
      );
    }
  };

  const isDisabled = PAGES_REGEXP_TO_DISABLE_COUNTRY_FILTER.some(
    (regExp) => !!pathname.match(regExp)
  );

  return {
    options,
    selectedValues: country || [ALL_COUNTRIES],
    handleChange,
    isDisabled
  };
};
