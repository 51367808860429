export const getPlacePredictions = (
  request: google.maps.places.AutocompletionRequest
): Promise<google.maps.places.AutocompletePrediction[] | null> =>
  new Promise((resolve, reject) => {
    if (!request.input) {
      return resolve([]);
    }

    try {
      new google.maps.places.AutocompleteService().getPlacePredictions(
        { ...request, language: 'en' },
        resolve
      );
    } catch (e) {
      reject(e);
    }
  });
