import kebabCase from 'lodash/kebabCase';
import {
  ADMIN_DETAILS_PAGE,
  ADMIN_DETAILS_PAGE_LIST
} from 'pages/AdminManagement/AdminDetails/AdminDetails.utils';
import { Navigate, RouteObject } from 'react-router-dom';

export const ADMIN_DETAILS_ROUTES: RouteObject[] =
  ADMIN_DETAILS_PAGE_LIST.map<RouteObject>((page) => {
    const Content = ADMIN_DETAILS_PAGE[page];

    return {
      path: kebabCase(page),
      element: <Content />
    };
  }).concat({
    index: true,
    element: <Navigate to={kebabCase(ADMIN_DETAILS_PAGE_LIST[0])} replace />
  });
