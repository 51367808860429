import { createColumnHelper } from '@tanstack/react-table';
import { ContactUsTicketConversationListResponse } from 'api';
import { Avatar, TruncateText } from 'components';
import snakeCase from 'lodash/snakeCase';
import { getDateFormattedString } from 'utils';

const columnHelper =
  createColumnHelper<ContactUsTicketConversationListResponse>();

export const ticketConversationsColumns = [
  columnHelper.display({
    id: 'conversation',
    cell: ({ row, table }) => {
      const sender = row.original.sender;

      return sender ? (
        <Avatar
          title={sender.displayedNickname}
          description={sender.email}
          src={sender.avatar}
        />
      ) : (
        table.options.meta?.t('keywords.unknown_user')
      );
    },
    header: ({ table }) => table.options.meta?.t('keywords.user_name'),
    enableSorting: false
  }),
  columnHelper.accessor('sentAt', {
    cell: ({ getValue, table }) =>
      getDateFormattedString({
        date: getValue(),
        t: table.options.meta?.t
      }),
    header: ({ table }) => table.options.meta?.t('keywords.date_and_time'),
    enableSorting: false
  }),
  columnHelper.accessor('text', {
    cell: ({ getValue }) => (
      <TruncateText maxWidth="md">{getValue()}</TruncateText>
    ),
    header: ({ table }) => table.options.meta?.t('keywords.message_text'),
    enableSorting: false
  }),
  columnHelper.accessor('comment', {
    cell: ({ getValue }) => getValue(),
    header: ({ table }) => table.options.meta?.t('keywords.comment'),
    enableSorting: false
  }),
  columnHelper.accessor('source', {
    cell: ({ getValue, table }) => {
      const source = getValue();

      return table.options.meta?.t(
        `enum.support_source_type.${snakeCase(source)}`,
        {
          defaultValue: source
        }
      );
    },
    header: ({ table }) => table.options.meta?.t('keywords.source'),
    enableSorting: false,
    meta: {
      overflowWrap: 'normal'
    }
  })
];
