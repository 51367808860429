import { Grid, GridItem } from '@chakra-ui/react';
import { InfoCard, InfoCardProps } from 'components';

type Props = {
  data: InfoCardProps[];
};

export const InfoCardContainer = ({ data }: Props): JSX.Element => (
  <Grid
    gridTemplateColumns="repeat(2, 1fr)"
    rowGap={6}
    columnGap={4}
    justifyItems="flex-start"
  >
    {data.map((info, index) => (
      <GridItem key={`info_card_${index}`}>
        <InfoCard {...info} />
      </GridItem>
    ))}
  </Grid>
);
