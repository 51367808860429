import { Spinner } from '@chakra-ui/react';
import { Table } from 'components';
import { ContentContainer } from 'containers';
import { ModerationPostStatus } from 'enum';
import { useModerationMottoList, useTableQueryController } from 'hooks';
import { getInitColumnVisibility } from 'pages/Moderation/Moderation.utils';

import { moderationMottoColumns } from './Motto.utils';

export const Motto = ({
  status = ModerationPostStatus.NotAccepted
}: {
  status?: ModerationPostStatus;
}): JSX.Element => {
  const {
    queryFilters,
    action: { onChangeSort, onChangePagination }
  } = useTableQueryController();

  const { data } = useModerationMottoList(status, queryFilters);

  if (!data) return <Spinner />;

  return (
    <ContentContainer height="100%">
      <Table
        data={data.data}
        columns={moderationMottoColumns}
        onChangeSort={onChangeSort}
        onChangePagination={onChangePagination}
        totalItems={data.totalItems}
        pagination={queryFilters.pagination}
        tableOptions={{
          initialState: {
            columnVisibility: getInitColumnVisibility(status)
          }
        }}
      />
    </ContentContainer>
  );
};
