import { Grid, GridItem, Heading, Skeleton } from '@chakra-ui/react';
import { AdminUsersLoginsResponse } from 'api';
import { ChartLegend, LineChart, TableFilters } from 'containers';
import { GenderTruncated, ParameterType } from 'enum';
import { useAppSelector, useChartData, useUsersLogins } from 'hooks';
import { useState, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { selectSettings } from 'store';
import { ChartFilters } from 'types';
import { STATISTIC_TOTAL } from 'utils';

import { chartFilters, CHART_SETTINGS } from './UsersLogins.utils';

export const UsersLogins = (): JSX.Element => {
  const [t] = useTranslation();
  const settings = useAppSelector(selectSettings);

  const [filters, setFilters] = useState<ChartFilters>({});

  const { data, isSuccess } = useUsersLogins({
    filters
  });

  const processedData = useMemo(() => {
    if (!data?.length) return [];

    const settingsOptions = settings[ParameterType.Gender];
    const mainGendersData: AdminUsersLoginsResponse[] = [];
    const otherGendersData: AdminUsersLoginsResponse[] = [];
    let totalData;
    let processedData;

    data.map((item) =>
      [GenderTruncated.Female, GenderTruncated.Male].includes(
        settingsOptions.find(({ optionId }) => optionId === item.id)
          ?.systemId as GenderTruncated
      )
        ? mainGendersData.push(item)
        : item.id === STATISTIC_TOTAL
        ? (totalData = item)
        : otherGendersData.push(item)
    );

    if (otherGendersData.length) {
      processedData = [
        ...mainGendersData,
        otherGendersData.reduce(
          (acc, item) => ({
            ...acc,
            data: acc.data.map(({ date, value }, idx) => ({
              date,
              value: value + item.data[idx].value
            }))
          }),
          {
            id: GenderTruncated.Other,
            data: otherGendersData[0].data.map(({ date }) => ({
              date,
              value: 0
            }))
          }
        )
      ];
    } else {
      processedData = mainGendersData;
    }

    if (totalData) processedData.push(totalData);

    return processedData;
  }, [data, settings]);

  const chartDataMap = useChartData({
    apiData: processedData,
    chartSettings: CHART_SETTINGS
  });

  const { chartData = [] } = chartDataMap || {};

  return (
    <Grid
      templateAreas={`
          "title filters"
          "legend legend"
          "chart chart"
        `}
      templateColumns="1fr auto"
      rowGap={4}
      columnGap={4}
    >
      <GridItem area="title">
        <Heading size="l">{t('attribute.title.users_logins')}</Heading>
      </GridItem>
      <GridItem area="filters">
        <TableFilters filters={chartFilters} onChange={setFilters} />
      </GridItem>
      <GridItem area="legend">
        <ChartLegend data={chartData} />
      </GridItem>
      <GridItem area="chart" height="30rem">
        <Skeleton isLoaded={isSuccess} boxSize="100%">
          {!!chartDataMap && (
            <LineChart dataMap={chartDataMap} filters={filters} />
          )}
        </Skeleton>
      </GridItem>
    </Grid>
  );
};
