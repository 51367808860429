import { Tag } from '@chakra-ui/react';
import { createColumnHelper } from '@tanstack/react-table';
import { LogActivityResponse } from 'api';
import { CountryList } from 'components';
import snakeCase from 'lodash/snakeCase';
import { getDateFormattedString } from 'utils';

import { AdminNicknameCell } from './Cells';

const columnHelper = createColumnHelper<LogActivityResponse>();

export const logActivityColumns = [
  columnHelper.accessor((row) => row.admin.nickname, {
    id: 'nickname',
    cell: AdminNicknameCell,
    header: ({ table }) => table.options.meta?.t('keywords.nickname'),
    enableSorting: true
  }),
  columnHelper.accessor('actedAt', {
    cell: ({ getValue, table }) =>
      getDateFormattedString({
        t: table.options.meta?.t,
        date: getValue()
      }),
    header: ({ table }) => table.options.meta?.t('keywords.date_and_time'),
    enableSorting: true
  }),
  columnHelper.accessor('scope', {
    cell: ({ getValue, table }) => (
      <Tag colorScheme="status.blue" variant="solid">
        {table.options.meta?.t(
          `enum.admin_activity_scope.${snakeCase(getValue())}`
        )}
      </Tag>
    ),
    header: ({ table }) => table.options.meta?.t('keywords.activity_type'),
    enableSorting: false
  }),
  columnHelper.accessor('description', {
    cell: ({ getValue, table }) =>
      table.options.meta?.t(
        `enum.admin_activity_action.${snakeCase(getValue())}`,
        {
          defaultValue: getValue()
        }
      ),
    header: ({ table }) => table.options.meta?.t('keywords.activity'),
    enableSorting: false
  }),
  columnHelper.accessor('admin.countries', {
    cell: ({ getValue }) => <CountryList countryCodes={getValue()} />,
    header: ({ table }) => table.options.meta?.t('keywords.country'),
    enableSorting: false
  })
];
