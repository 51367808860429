import { FormField, InputElement } from 'enum';
import * as yup from 'yup';

import { EditTransactionFormData } from './EditTransaction.types';

export const editTransactionFormSchema = {
  fields: {
    [FormField.Comment]: {
      type: InputElement.TextArea,
      translationKey: 'transaction_comment',
      getPlaceholder: () => 'Comment',
      minHeight: 32
    }
  },
  gridLayout: {
    templateAreas: `
      "${FormField.Comment}"
    `,
    gridTemplateColumns: '1fr',
    rowGap: 6
  }
} as const;

export const editTransactionValidationSchema: yup.SchemaOf<EditTransactionFormData> =
  yup.object({
    [FormField.Comment]: yup.string().defined()
  });
