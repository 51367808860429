import difference from 'lodash/difference';
import { FieldNamesMarkedBoolean, FieldValues } from 'react-hook-form';

export const getUpdatedFields = <T extends FieldValues>(
  data: T,
  dirtyFields: FieldNamesMarkedBoolean<T>
) =>
  Object.keys(dirtyFields).reduce((acc, field) => {
    // @ts-ignore
    acc[field] = data[field];

    return acc;
  }, {} as Partial<T>);

export const getIsArrayChanged = (
  values: unknown[],
  initialValues: unknown[]
): boolean =>
  difference(values, initialValues).length > 0 ||
  difference(initialValues, values).length > 0;

/**
 * Use it for get form values which was modified
 * see link: https://github.com/react-hook-form/react-hook-form/discussions/1991#discussioncomment-31308
 */
export const getDirtyValues = <T extends FieldValues>(
  data: T,
  dirtyFields: FieldNamesMarkedBoolean<T>
): Partial<T> => {
  if (dirtyFields === true || Array.isArray(dirtyFields)) return data;

  return Object.fromEntries(
    Object.keys(dirtyFields).map((key) => [
      key,
      getDirtyValues(data[key as string], dirtyFields[key])
    ])
  ) as Partial<T>;
};
