import { GridItem, Heading } from '@chakra-ui/react';
import { yupResolver } from '@hookform/resolvers/yup';
import { RightSideButtons } from 'components';
import {
  ContentContainer,
  FormConstructor,
  RightSide,
  ScrollableContainer
} from 'containers';
import { useChakraToast, useCreatePopup } from 'hooks';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { CreatePopupFormData } from './CreatePopup.types';
import {
  createPopupFormSchema,
  createPopupValidationSchema,
  getDefaultFormValues,
  serializeRequestData
} from './CreatePopup.utils';

export const CreatePopup = (): JSX.Element => {
  const [t] = useTranslation();
  const navigate = useNavigate();
  const toast = useChakraToast();

  const form = useForm<CreatePopupFormData>({
    mode: 'onSubmit',
    shouldUnregister: true,
    resolver: yupResolver(createPopupValidationSchema),
    defaultValues: getDefaultFormValues()
  });

  const { handleSubmit } = form;

  const { mutate, isLoading } = useCreatePopup();

  const onSubmit = handleSubmit((data) => {
    mutate(serializeRequestData(data), {
      onSuccess: () => {
        toast({
          status: 'success',
          title: t('messages.successfully_saved')
        });
        navigate(-1);
      }
    });
  });

  const handleCancel = () => {
    navigate(-1);
  };

  return (
    <ContentContainer height="100%">
      <ScrollableContainer>
        <FormProvider {...form}>
          <FormConstructor formSchema={createPopupFormSchema}>
            <GridItem area="heading">
              <Heading as="h3" size="l">
                {t('attribute.title.list_pages_for_settings_popup')}
              </Heading>
            </GridItem>
            <GridItem area="settings">
              <Heading as="h3" size="l">
                {t('attribute.title.settings')}
              </Heading>
            </GridItem>
          </FormConstructor>
        </FormProvider>
        <RightSide>
          <RightSideButtons
            onSubmit={onSubmit}
            isDisabledSubmit={false}
            onCancel={handleCancel}
            isLoading={isLoading}
            label={t('actions.save_popup')}
          />
        </RightSide>
      </ScrollableContainer>
    </ContentContainer>
  );
};
