import { Button, Text } from '@chakra-ui/react';
import { JsonType } from 'api';
import { ModalType } from 'enum';
import { useAppDispatch } from 'hooks';
import { FC } from 'react';
import { openModal } from 'store';

export const JsonCell: FC<{
  value: JsonType;
  updateEntity: (data: JsonType | null) => Promise<void>;
  editModalHeader?: string;
}> = ({ updateEntity, value, editModalHeader }) => {
  const dispatch = useAppDispatch();

  const onEditOption = () => {
    dispatch(
      openModal({
        id: ModalType.EditJSON,
        meta: {
          onSubmit: updateEntity,
          initialValue: value,
          header: editModalHeader
        }
      })
    );
  };

  return (
    <Button variant="link" onClick={onEditOption} color="black">
      <Text maxWidth="100px" overflow="hidden" textOverflow="ellipsis">
        {value ? JSON.stringify(value) : '---'}
      </Text>
    </Button>
  );
};
