import { Button, GridItem } from '@chakra-ui/react';
import { ReportAdminListResponse } from 'api';
import { CheckIcon, Search, Table } from 'components';
import { TableFilters } from 'containers';
import { ReportType } from 'enum/api';
import { useAdminReportList, useTableQueryController } from 'hooks';
import { useMarkReport } from 'pages/Reports/useMarkReport';
import { Fragment, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import {
  adminReportsTableFilters,
  reportAdminColumns,
  getSearchOptions
} from './AdminReports.utils';

type Props = {
  reportType: ReportType;
};

export const AdminReportContent = ({ reportType }: Props): JSX.Element => {
  const [t] = useTranslation();

  const {
    queryFilters,
    action: {
      onChangeSort,
      onChangePagination,
      onChangeFilters,
      onChangeSearch
    }
  } = useTableQueryController({
    fixedValues: {
      filter: {
        type: reportType
      }
    }
  });

  const { data } = useAdminReportList(queryFilters);
  const searchOptions = useMemo(() => getSearchOptions(t), [t]);

  const { isDisabled, isLoading, label, onHandleClick, tableProps } =
    useMarkReport<ReportAdminListResponse>();

  return (
    <Fragment>
      <GridItem area="search">
        <Search options={searchOptions} onChange={onChangeSearch} />
      </GridItem>
      <GridItem area="actions" justifySelf="flex-end">
        <Button
          onClick={onHandleClick}
          variant="link"
          leftIcon={<CheckIcon />}
          isDisabled={isDisabled}
          isLoading={isLoading}
        >
          {label}
        </Button>
      </GridItem>
      <GridItem area="filters">
        <TableFilters
          filters={adminReportsTableFilters}
          onChange={onChangeFilters}
        />
      </GridItem>
      {!!data && (
        <GridItem area="table" overflow="hidden">
          <Table
            data={data.data}
            columns={reportAdminColumns}
            onChangeSort={onChangeSort}
            onChangePagination={onChangePagination}
            totalItems={data.totalItems}
            pagination={queryFilters.pagination}
            hasHorizontalScroll
            {...tableProps}
          />
        </GridItem>
      )}
    </Fragment>
  );
};
