import { Box, Image } from '@chakra-ui/react';
import { createColumnHelper } from '@tanstack/react-table';
import { ModerationCommentResponse } from 'api';
import { TruncateText } from 'components';
import { DeclineReasonCell, UserCell } from 'pages/Moderation/components';
import { getDateFormattedString } from 'utils';

import { CommentActionButtons } from './components';

const moderationCommentsColumnHelper =
  createColumnHelper<ModerationCommentResponse>();

export const moderationCommentsColumns = [
  moderationCommentsColumnHelper.accessor((row) => row.user, {
    id: 'nickname',
    cell: ({ getValue }) => <UserCell user={getValue()} />,
    header: ({ table }) => table.options.meta?.t('keywords.user'),
    enableSorting: false
  }),
  moderationCommentsColumnHelper.accessor('postedAt', {
    cell: ({ getValue, table }) =>
      getDateFormattedString({ t: table.options.meta?.t, date: getValue() }),
    header: ({ table }) => table.options.meta?.t('keywords.creation_date'),
    enableSorting: false
  }),
  moderationCommentsColumnHelper.accessor('text', {
    cell: ({ getValue }) => (
      <TruncateText maxWidth="md">{getValue()}</TruncateText>
    ),
    header: ({ table }) => table.options.meta?.t('keywords.comment'),
    enableSorting: false,
    meta: {
      maxWidth: 60
    }
  }),
  moderationCommentsColumnHelper.accessor('imageUrl', {
    cell: ({ getValue }) => (
      <Box minWidth={15}>
        <Image boxSize={15} src={getValue()} borderRadius="md" />
      </Box>
    ),
    header: ({ table }) => table.options.meta?.t('keywords.photo'),
    enableSorting: false
  }),
  moderationCommentsColumnHelper.accessor('commentedToNickname', {
    header: ({ table }) => table.options.meta?.t('keywords.user'),
    enableSorting: false
  }),
  moderationCommentsColumnHelper.display({
    id: 'actions',
    header: ({ table }) =>
      table.options.meta?.t('keywords.action', {
        count: 10
      }),
    cell: ({ row }) => <CommentActionButtons commentId={row.original.id} />,
    enableSorting: false
  }),
  moderationCommentsColumnHelper.accessor('declineReason', {
    cell: ({ getValue, row: { original } }) => (
      <DeclineReasonCell status={original.status} declineReason={getValue()} />
    ),
    header: ({ table }) => table.options.meta?.t('keywords.reason'),
    enableSorting: false
  })
];
