import { TableManagementOption } from 'api';
import { ParameterType } from 'enum/api';
import { useAppSelector } from 'hooks';
import { selectSettings } from 'store';

export const useParameterTypeMeta = () => {
  const settings = useAppSelector(selectSettings);

  const getOptionMeta = (
    key: ParameterType,
    value?: string | null
  ): TableManagementOption['meta'] => {
    if (!value) return null;

    const option = settings[key].find(({ optionId }) => optionId === value);

    if (!option) return null;

    return option.meta;
  };

  const getOptionMetaForField = <R extends unknown = unknown>(
    key: ParameterType,
    value?: string | null,
    field?: string
  ): R | undefined => {
    const meta = getOptionMeta(key, value);

    if (!meta || !field) return;

    if (field in meta) {
      return meta[field] as R;
    }

    return;
  };

  return {
    getOptionMeta,
    getOptionMetaForField
  };
};
