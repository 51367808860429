import { PaymentSubscriptionOptionsByUserResponse } from 'api';
import { FormField, InputElement } from 'enum';
import { ParameterType } from 'enum/api';
import { GetIsDisabledFnArg, GetOptionsArg } from 'types/form';
import { generateOptionsFromSettings } from 'utils';
import * as yup from 'yup';

import {
  generateProgramTypeOptions,
  generateSubscriptionTypeNameOptions
} from '../../PaymentManagement/CreateNewTransaction/CreateNewTransaction.utils';

import { AddCustomerTransactionFormData } from './AddTransaction.types';

export const getAddCustomerTransactionFormSchema = (
  options: PaymentSubscriptionOptionsByUserResponse
) =>
  ({
    fields: {
      [FormField.SubscriptionProgramType]: {
        type: InputElement.Select,
        translationKey: 'subscription_program_type',
        getPlaceholder: () => 'Select type',
        getOptions: ({ t, settings }: GetOptionsArg) =>
          generateProgramTypeOptions({ options, t, settings })
      },
      [FormField.SubscriptionTypeName]: {
        type: InputElement.Select,
        translationKey: 'subscription_type_name',
        getPlaceholder: () => 'Type name',
        getOptions: ({ t, getFormValues }: GetOptionsArg) =>
          generateSubscriptionTypeNameOptions({
            options,
            t,
            programType: getFormValues(FormField.SubscriptionProgramType)
          }),
        getIsDisabled: ({ formValues }: GetIsDisabledFnArg) =>
          !formValues[FormField.SubscriptionProgramType]
      },
      [FormField.SumPaid]: {
        type: InputElement.NumberInput,
        translationKey: 'sum_paid',
        getPlaceholder: () => 'Sum paid',
        getIsDisabled: ({ formValues }: GetIsDisabledFnArg) =>
          !formValues[FormField.SubscriptionTypeName],
        min: 0,
        precision: 2
      },
      [FormField.Currency]: {
        type: InputElement.Select,
        translationKey: 'currency_paid',
        getPlaceholder: () => 'Select',
        getOptions: ({ t, settings }: GetOptionsArg) =>
          generateOptionsFromSettings(ParameterType.Currency, t, settings),
        getIsDisabled: ({ formValues }: GetIsDisabledFnArg) =>
          !formValues[FormField.SubscriptionTypeName]
      },
      [FormField.Comment]: {
        type: InputElement.TextArea,
        translationKey: 'comment',
        getPlaceholder: () => 'Write your comment',
        maxLength: 2500
      }
    },
    gridLayout: {
      templateAreas: `
    "${FormField.SubscriptionProgramType}"
    "${FormField.SubscriptionTypeName}"
    "${FormField.SumPaid}"
    "${FormField.Currency}"
    "${FormField.Comment}"
    `,
      gridTemplateColumns: '1fr',
      rowGap: 6
    }
  } as const);

export const addCustomerTransactionValidationSchema: yup.SchemaOf<AddCustomerTransactionFormData> =
  yup.object({
    [FormField.SubscriptionProgramType]: yup.string().required(),
    [FormField.SubscriptionTypeName]: yup.string().required(),
    [FormField.SumPaid]: yup.number().min(0, 'positive_value').required(),
    [FormField.Currency]: yup.string().required(),
    [FormField.Comment]: yup.string().optional()
  });
