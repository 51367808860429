import { Button, Center, HStack, Text, VStack } from '@chakra-ui/react';
import { Table, TableProps } from 'components';
import { ContentContainer } from 'containers';
import { CustomerType, ParameterType } from 'enum';
import { useAppSelector, useCustomerConversationMessages } from 'hooks';
import omit from 'lodash/omit';
import { Dispatch, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';
import { selectSettings } from 'store';
import {
  ICustomerConversation,
  ICustomerConversationMessage
} from 'types/customer';
import { v4 as uuid } from 'uuid';

import { conversationsColumns } from '../CustomerInteraction.utils';

type Props = {
  conversation: ICustomerConversation;
  expandedConversations: Record<string, boolean>;
  setExpandedConversations: Dispatch<
    SetStateAction<Props['expandedConversations']>
  >;
  customerId: string;
};

export const Conversation = ({
  conversation,
  expandedConversations,
  setExpandedConversations,
  customerId
}: Props): JSX.Element => {
  const [t] = useTranslation();
  const settings = useAppSelector(selectSettings);

  const {
    id: conversationId,
    lastMessageTime,
    lastMessageUserId,
    lastMessageContent,
    lastMessageImages,
    totalMessages
  } = conversation;
  const isOpen = expandedConversations[conversationId] ?? false;

  const { data: messages = [], isSuccess } = useCustomerConversationMessages({
    conversationId,
    enabled: isOpen
  });

  const shouldShowButton = totalMessages > 1;

  const lastMessage: ICustomerConversationMessage = {
    id: uuid(),
    message: lastMessageContent,
    images: [],
    imageFiles: (lastMessageImages || []).map((url) => ({
      imageUrl: url
    })),
    created: lastMessageTime,
    userId: lastMessageUserId
  };

  const handleClick = () => {
    setExpandedConversations((prev) => {
      if (prev[conversationId]) return omit(prev, conversationId);

      return {
        ...prev,
        [conversationId]: true
      };
    });
  };

  const displayedMessages = isOpen && isSuccess ? messages : [lastMessage];

  const getHighlightColor: TableProps<ICustomerConversationMessage>['getHighlightColor'] =
    ({ original: { userId } }) => {
      const user = conversation.usersInfo.find(({ id }) => id === userId);
      const secondUserTypeSystemId = settings[ParameterType.CustomerType].find(
        ({ optionId }) => user?.typeId && user.typeId === optionId
      )?.systemId;

      switch (secondUserTypeSystemId) {
        case CustomerType.Supported:
          return '#fff5f2';
        case CustomerType.Supporter:
          return '#f4faff';
        default:
          return '';
      }
    };

  return (
    <ContentContainer key={conversationId}>
      <VStack height="full" spacing={1} width="full" alignItems="stretch">
        <HStack justifyContent="space-between" paddingX={4}>
          <Text textStyle="mds">
            {conversation.usersInfo[1].displayedNickname}
          </Text>
          {shouldShowButton && (
            <Center>
              {isOpen ? (
                <Button onClick={handleClick} variant="link">
                  {t('actions.see_less')}
                </Button>
              ) : (
                <Button onClick={handleClick} variant="link">{`${t(
                  'actions.see_more'
                )} (${totalMessages - 1})`}</Button>
              )}
            </Center>
          )}
        </HStack>
        <Table
          useVirtualTable
          data={displayedMessages}
          columns={conversationsColumns}
          meta={{
            conversation,
            customerId
          }}
          totalItems={displayedMessages.length}
          shouldHideHeader
          shouldHidePagination
          getHighlightColor={getHighlightColor}
        />
      </VStack>
    </ContentContainer>
  );
};
