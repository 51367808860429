import { yupResolver } from '@hookform/resolvers/yup';
import { FormConstructor, ModalContainer } from 'containers';
import {
  ErrorCode,
  FormField,
  ModalType,
  PaymentSubscriptionPeriod
} from 'enum';
import {
  useChakraToast,
  useCreatePaymentSubscription,
  useFormValidation,
  useModalActions
} from 'hooks';
import { snakeCase } from 'lodash';
import startCase from 'lodash/startCase';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { ParamType } from 'types';
import { serializeAxiosError } from 'utils';

import { CreateNewSubscriptionFormData } from './CreateNewSubscription.types';
import {
  baseCreateNewSubscriptionValidationSchema,
  createNewSubscriptionFormSchema,
  createNewSubscriptionValidationSchema
} from './CreateNewSubscription.utils';

export const CreateNewSubscription = (): JSX.Element => {
  const [t] = useTranslation();
  const toast = useChakraToast();

  const form = useForm<CreateNewSubscriptionFormData>({
    mode: 'onSubmit',
    resolver: yupResolver(createNewSubscriptionValidationSchema),
    defaultValues: {
      [FormField.SubscriptionTypeName]: '',
      [FormField.Countries]: [],
      [FormField.Comment]: '',
      [FormField.SubscriptionShowTotal]: false,
      [FormField.SubscriptionShowSeparately]: false,
      [FormField.SubscriptionIsRenewing]: true
    }
  });

  const { isOpen, onClose } = useModalActions(ModalType.CreateNewSubscription, {
    onClose: form.reset
  });

  const { mutate, isLoading } = useCreatePaymentSubscription();

  const { handleSubmit } = form;

  const isFormFilled = useFormValidation<CreateNewSubscriptionFormData>(
    form,
    baseCreateNewSubscriptionValidationSchema
  );

  const onSubmit = handleSubmit((data) => {
    mutate(
      {
        type: data.subscriptionTypeName,
        title: data.subscriptionDisplayName,
        description: data.comment,
        programType: data.subscriptionProgramType as string,
        duration: data.duration as number,
        period: data.period as PaymentSubscriptionPeriod,
        splitBy: data.subscriptionSplitBy as PaymentSubscriptionPeriod,
        currency: data.currency as string,
        currencySign: data.subscriptionCurrencySign,
        price: data.sumPaid as number,
        countries: data.countries,
        showTotal: data.subscriptionShowTotal,
        showSeparately: data.subscriptionShowSeparately,
        isRenewing: data.subscriptionIsRenewing,
        order: data.subscriptionOrder as number,
        userType: data.customerType as string,
        gender: data.gender as string
      },
      {
        onSuccess: onClose,
        onError: (error) => {
          const { errorCode } = serializeAxiosError(error);

          if (errorCode === ErrorCode.SubscriptionDuplicate) {
            toast({
              title: t(`errors.codes.${snakeCase(errorCode)}`),
              status: 'error'
            });
          } else {
            toast({
              status: 'error',
              title: t('errors.invalid_form'),
              description: error.message
            });
          }
        }
      }
    );
  });

  const actions: ParamType<typeof ModalContainer, 'footerButtons'> = [
    {
      text: t('actions.cancel'),
      variant: 'ghostGray'
    },
    {
      text: startCase(t('actions.add')),
      isLoading,
      isPreventClose: true,
      isDisabled: !isFormFilled,
      onClick: () => {
        onSubmit();
      }
    }
  ];

  return (
    <ModalContainer
      header={t('attribute.title.add_new_subscription')}
      footerButtons={actions}
      isOpen={isOpen}
      onClose={onClose}
      size="xl"
    >
      <FormProvider {...form}>
        <FormConstructor formSchema={createNewSubscriptionFormSchema} />
      </FormProvider>
    </ModalContainer>
  );
};
