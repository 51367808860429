import { DeclineItemType, ModalType, ModerationPostStatus } from 'enum';
import { useAppDispatch, useUpdateMottoStatus } from 'hooks';
import { ModerationActionType } from 'pages/Moderation/Moderation.utils';
import { ModerationActions } from 'pages/Moderation/components';
import { openModal } from 'store/modal';

export const MottoActionButtons = ({
  mottoId
}: {
  mottoId: string;
}): JSX.Element => {
  const dispatch = useAppDispatch();

  const { mutate } = useUpdateMottoStatus();

  const handleClick = (type: ModerationActionType) => {
    switch (type) {
      case ModerationActionType.Decline:
        return dispatch(
          openModal({
            id: ModalType.DeclineItem,
            meta: {
              mottoId,
              type: DeclineItemType.Motto
            }
          })
        );
      case ModerationActionType.AutoDecline:
        return mutate({
          mottoId,
          status: ModerationPostStatus.AutoDeclined
        });
      case ModerationActionType.Approve:
        return mutate({
          mottoId,
          status: ModerationPostStatus.Approved
        });
    }
  };

  return (
    <ModerationActions
      handleClick={handleClick}
      gridProps={{
        templateColumns: 'repeat(3, min-content)'
      }}
    />
  );
};
