import { Box, Checkbox, Image } from '@chakra-ui/react';
import { createColumnHelper } from '@tanstack/react-table';
import { ReportAdminListResponse } from 'api';
import { TruncateText } from 'components';
import { format } from 'date-fns';
import { ModalType } from 'enum';
import snakeCase from 'lodash/snakeCase';
import { ActionCell, StatusCell } from 'pages/Reports/components';
import { ICustomerReports } from 'types/customer';
import { getBaseCustomerLinkNavigation, getDateFormattedString } from 'utils';

import { NicknameCell } from './Cells';

const columnHelper = createColumnHelper<ICustomerReports>();

export const reportsColumns = [
  columnHelper.accessor((row) => row.partner, {
    id: 'nickname',
    cell: ({ getValue }) => {
      const partner = getValue();

      if (!partner) return null;

      return (
        <NicknameCell navigateTo={getBaseCustomerLinkNavigation(partner.id)}>
          {partner.displayedNickname}
        </NicknameCell>
      );
    },
    header: ({ table }) => table.options.meta?.t('keywords.nickname'),
    enableSorting: false
  }),
  columnHelper.accessor((row) => row.partner, {
    id: 'email',
    cell: ({ getValue }) => {
      const partner = getValue();

      if (!partner) return null;

      return partner.email;
    },
    header: ({ table }) => table.options.meta?.t('keywords.email'),
    enableSorting: false
  }),
  columnHelper.accessor('type', {
    cell: ({ table, getValue }) =>
      table.options.meta?.t(`enum.report_type.${snakeCase(getValue())}`),
    header: ({ table }) => table.options.meta?.t('keywords.report_type'),
    enableSorting: false
  }),
  columnHelper.accessor('createdAt', {
    cell: ({ getValue, table }) =>
      getDateFormattedString({ t: table.options.meta?.t, date: getValue() }),
    header: ({ table }) => table.options.meta?.t('keywords.date_and_time'),
    enableSorting: true
  }),
  columnHelper.accessor('comment', {
    cell: (info) => (
      <TruncateText maxWidth="md">{info.getValue()}</TruncateText>
    ),
    header: ({ table }) => table.options.meta?.t('keywords.report_text'),
    enableSorting: false
  }),
  columnHelper.accessor('imageUrl', {
    cell: ({ getValue }) => {
      const url = getValue();

      return (
        !!url && (
          <Box>
            <Image src={url} boxSize={10} borderRadius="md" />
          </Box>
        )
      );
    },
    header: ({ table }) => table.options.meta?.t('keywords.reports_image'),
    enableSorting: false
  })
];

const adminReportsColumnHelper = createColumnHelper<ReportAdminListResponse>();

export const adminReportsColumns = [
  adminReportsColumnHelper.display({
    id: 'checkbox',
    cell: ({ row }) => (
      <Checkbox
        isChecked={row.getIsSelected()}
        onChange={row.getToggleSelectedHandler()}
      />
    ),
    header: ({ table }) => (
      <Checkbox
        isChecked={table.getIsAllRowsSelected()}
        isIndeterminate={table.getIsSomeRowsSelected()}
        onChange={table.getToggleAllRowsSelectedHandler()}
      />
    ),
    enableSorting: false
  }),
  adminReportsColumnHelper.accessor('reason', {
    cell: ({ getValue, table }) =>
      table.options.meta?.t(
        `enum.admin_report_reason.${snakeCase(getValue())}`
      ),
    header: ({ table }) => table.options.meta?.t('keywords.reason'),
    enableSorting: false
  }),
  adminReportsColumnHelper.accessor('comment', {
    cell: ({ getValue }) => (
      <TruncateText maxWidth="md">{getValue()}</TruncateText>
    ),
    header: ({ table }) => table.options.meta?.t('keywords.report_text'),
    enableSorting: false,
    meta: {
      minWidth: 'xs'
    }
  }),
  adminReportsColumnHelper.accessor('imageUrl', {
    cell: ({ getValue }) => (
      <Image boxSize={10} minWidth={10} src={getValue()} borderRadius="md" />
    ),
    header: () => null,
    enableSorting: false
  }),
  adminReportsColumnHelper.accessor('status', {
    cell: ({ getValue, row }) => (
      <StatusCell status={getValue()} reportId={row.original.id} />
    ),
    header: ({ table }) => table.options.meta?.t('keywords.status'),
    enableSorting: false
  }),
  adminReportsColumnHelper.accessor('createdAt', {
    cell: ({ getValue }) => format(new Date(getValue()), 'HH:mm, dd.MM.yyyy'),
    header: ({ table }) => table.options.meta?.t('keywords.date_and_time'),
    enableSorting: true
  }),
  adminReportsColumnHelper.display({
    id: 'actions',
    cell: ({ row }) => (
      <ActionCell
        data={row.original}
        modalType={ModalType.AdminReportDetails}
      />
    ),
    header: () => null,
    enableSorting: false
  })
];
