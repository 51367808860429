import { ENDPOINTS } from 'api';
import axios from 'axios';

import { UpdateCustomerBadWordsRequestData } from './CustomerBadWords.types';

export const fetchUpdateCustomerBadWords = ({
  customerId,
  body
}: {
  customerId: string;
  body: UpdateCustomerBadWordsRequestData;
}) => axios.patch(`${ENDPOINTS.BAD_WORDS}/by-user/${customerId}`, body);
