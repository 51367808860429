import { IconButton } from '@chakra-ui/react';
import { FullScreenExitIcon, FullScreenIcon } from 'components';

type Props = {
  isFullMode: boolean;
  onClick: () => void;
};

export const FullScreenButton = ({
  isFullMode,
  onClick
}: Props): JSX.Element => (
  <IconButton
    icon={
      isFullMode ? (
        <FullScreenExitIcon boxSize={6} />
      ) : (
        <FullScreenIcon boxSize={6} />
      )
    }
    color="gray.600"
    aria-label="full screen"
    variant="unstyled"
    onClick={onClick}
  />
);
