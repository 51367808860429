import { useMutation, useQueryClient } from '@tanstack/react-query';
import { fetchDeletePopup } from 'api';
import { QueryKey } from 'enum/api';

export const useDeletePopup = () => {
  const queryClient = useQueryClient();

  return useMutation(fetchDeletePopup, {
    onSuccess() {
      queryClient.invalidateQueries([QueryKey.PopupManagementList]);
    }
  });
};
