import {
  Checkbox,
  CheckboxGroup,
  Grid,
  GridItem,
  Text,
  VStack
} from '@chakra-ui/react';
import { yupResolver } from '@hookform/resolvers/yup';
import { ModalContainer } from 'containers';
import { FormField, ModalType } from 'enum';
import { useChakraToast, useCustomerDownloadCSV, useModalActions } from 'hooks';
import { useEffect } from 'react';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { ParamType } from 'types';

import { DownloadCSVFormData } from './DownloadCSV.types';
import { downloadCSVValidationSchema } from './DownloadCSV.utils';

export const DownloadCSV = (): JSX.Element => {
  const [t] = useTranslation();

  const toast = useChakraToast();

  const form = useForm<DownloadCSVFormData>({
    mode: 'onChange',
    resolver: yupResolver(downloadCSVValidationSchema),
    defaultValues: {
      [FormField.Custom]: []
    }
  });

  const {
    handleSubmit,
    reset,
    formState: { isValid }
  } = form;

  const { isOpen, onClose, meta } = useModalActions(
    ModalType.DownloadCustomerCSV,
    {
      onClose: reset
    }
  );

  useEffect(() => {
    if (meta) {
      reset({
        [FormField.Custom]: meta.defaultValues
      });
    }
  }, [reset, meta]);

  const { mutate, isLoading } = useCustomerDownloadCSV();

  const onSubmit = handleSubmit((data) => {
    mutate(data[FormField.Custom], {
      onSuccess(data) {
        window.open(data);

        onClose();
      },
      onError: () => {
        toast({
          status: 'error',
          title: t('errors.error_while_create_csv')
        });
      }
    });
  });

  const actions: ParamType<typeof ModalContainer, 'footerButtons'> = [
    {
      text: t('actions.cancel'),
      variant: 'ghostGray'
    },
    {
      text: t('actions.download'),
      colorScheme: 'primary',
      isLoading,
      isPreventClose: true,
      isDisabled: !isValid,
      onClick: () => {
        onSubmit();
      }
    }
  ];

  return (
    <ModalContainer
      header={t('attribute.title.download_csv_file')}
      footerButtons={actions}
      isOpen={isOpen}
      onClose={onClose}
      size="xl"
    >
      <VStack spacing={6}>
        <Text color="gray.600" maxWidth="2xs">
          {t('attribute.description.which_info_include_for_downloading')}
        </Text>
        <FormProvider {...form}>
          {!!meta && (
            <Controller
              control={form.control}
              name={FormField.Custom}
              render={({ field: { onChange, value } }) => (
                <CheckboxGroup value={value} onChange={onChange}>
                  <Grid
                    templateColumns="repeat(2, 1fr)"
                    justifyItems="flex-start"
                    textAlign="start"
                    paddingX={3}
                    columnGap={10}
                    rowGap={6}
                  >
                    {meta.options.map((option) => (
                      <GridItem key={option.value}>
                        <Checkbox value={option.value}>{option.label}</Checkbox>
                      </GridItem>
                    ))}
                  </Grid>
                </CheckboxGroup>
              )}
            />
          )}
        </FormProvider>
      </VStack>
    </ModalContainer>
  );
};
