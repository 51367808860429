import { ReportsByStatusChartData, ReportsByStatusTotalChartData } from 'api';
import { CustomStackedBarChartSettings } from 'containers';
import { BarChartLayout, ReportAuthorType } from 'enum';
import { OptionKey, ReportStatus } from 'enum/api';
import { getTranslationByOptionKey } from 'utils';

export const TOTAL_REPORTS_CHART_SETTINGS: CustomStackedBarChartSettings<ReportsByStatusTotalChartData> =
  {
    colors: ['#005C9F', '#F4AA23'],
    transformChartData: ({ author, value }) => ({
      id: author,
      value
    }),
    getTitleLabel: () => '',
    getLabel: ({ id: author, t }) => {
      if (author === ReportAuthorType.User) {
        return t('keywords.text_report', {
          count: 2,
          text: t('keywords.customer')
        });
      }

      if (author === ReportAuthorType.Admin) {
        return t('keywords.text_report', {
          count: 2,
          text: t('keywords.admin')
        });
      }

      return '';
    },
    layout: BarChartLayout.Horizontal,
    getBarColor: (position, baseColor) => ({
      color: TOTAL_REPORTS_CHART_SETTINGS['colors'][position],
      filter: undefined
    })
  };

export const REPORTS_CHART_SETTINGS: CustomStackedBarChartSettings<ReportsByStatusChartData> =
  {
    colors: ['#005C9F', '#F4AA23'],
    transformChartData: ({ status, value }) => ({
      id: status,
      value
    }),
    getTitleLabel: ({ id, t }) => {
      if (id === ReportAuthorType.User) {
        return t('keywords.text_report', {
          count: 2,
          text: t('keywords.customer')
        });
      }

      if (id === ReportAuthorType.Admin) {
        return t('keywords.text_report', {
          count: 2,
          text: t('keywords.admin')
        });
      }

      return '';
    },
    getLabel: ({ id: status, t, settings }) =>
      getTranslationByOptionKey({
        t,
        value: status as ReportStatus,
        optionKey: OptionKey.ReportStatus,
        settings
      }),
    layout: BarChartLayout.Horizontal,
    getBarColor: (position, baseColor) => ({
      color: baseColor,
      filter: `brightness(${100 + position * 20}%)`
    })
  };
