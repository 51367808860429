import { ENDPOINTS } from 'api/endpoints';
import axios from 'axios';
import { ActiveOrDeletedByAdminStatus } from 'types/customer';

export const fetchUpdateStoryStatus = ({
  storyId,
  status
}: {
  storyId: string;
  status: ActiveOrDeletedByAdminStatus;
}) =>
  axios.patch(`${ENDPOINTS.CUSTOMER_STORIES}/${storyId}/status`, {
    status
  });
