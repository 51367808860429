import { createSlice } from '@reduxjs/toolkit';

import { updateFilter } from './actions';
import { AppState } from './app.types';

const getInitialAppState = (): AppState => ({
  filters: {
    country: null
  }
});

export const appSlice = createSlice({
  name: 'app',
  initialState: getInitialAppState(),
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(updateFilter, (state, action) => ({
      ...state,
      filters: {
        ...state.filters,
        ...action.payload
      }
    }));
  }
});

export const { reducer: appReducer } = appSlice;
