import { MenuItem } from '@chakra-ui/react';
import { InputElement } from 'enum';
import { Fragment } from 'react';
import { GetUnionType } from 'types';

import { ListContainerProps } from '../ButtonDropdown.types';

type Props = GetUnionType<ListContainerProps, { type: InputElement.Select }>;

export const SelectList = ({
  options,
  selectedValues,
  onChange
}: Props): JSX.Element => (
  <Fragment>
    {options.map(({ label, value }) => (
      <MenuItem
        key={value}
        value={value}
        padding={3}
        onClick={() => onChange([value])}
        aria-selected={selectedValues[0] === value}
      >
        {label}
      </MenuItem>
    ))}
  </Fragment>
);
