import { chakra } from '@chakra-ui/react';
import { ControlProps, GroupBase } from 'react-select';

import { getAttributeProperty, useStyles } from '../LocationDropdown.utils';

export const Control = <
  Option,
  IsMulti extends boolean,
  Group extends GroupBase<Option>
>(
  props: ControlProps<Option, IsMulti, Group>
): JSX.Element => {
  const { children, isDisabled, isFocused, innerRef, innerProps, selectProps } =
    props;

  const styles = useStyles();

  return (
    <chakra.div
      ref={innerRef}
      __css={styles.control}
      {...innerProps}
      {...getAttributeProperty({
        isDisabled,
        isFocused,
        isInvalid: selectProps.isInvalid
      })}
    >
      {children}
    </chakra.div>
  );
};
