import { GLOBAL_COUNTRY_FILTER } from 'utils';

const loadState = () => {
  try {
    const globalCountryFilter = localStorage.getItem(GLOBAL_COUNTRY_FILTER);

    const country: string[] | null =
      globalCountryFilter && JSON.parse(globalCountryFilter);

    return {
      app: {
        filters: {
          country
        }
      }
    };
  } catch (err) {
    return;
  }
};

const saveCountryFilter = (country: string[] | null) => {
  if (!country) localStorage.removeItem(GLOBAL_COUNTRY_FILTER);

  localStorage.setItem(GLOBAL_COUNTRY_FILTER, JSON.stringify(country));
};

export const localStorageService = {
  loadState,
  saveCountryFilter
};
