import { Box, FormLabel, HStack, VStack } from '@chakra-ui/react';
import { Avatar, Dropdown, InfoCard, TextArea } from 'components';
import { ModalContainer } from 'containers';
import { ModalType } from 'enum';
import { OptionKey, ParameterType, ReportStatus } from 'enum/api';
import {
  useAppSelector,
  useModalActions,
  useNavigateToCustomer,
  useUpdateReport,
  useCustomerInfo
} from 'hooks';
import { keyBy } from 'lodash';
import startCase from 'lodash/startCase';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { selectSettings } from 'store';
import { ParamType } from 'types';
import { generateOptionsFromSettings } from 'utils';

import { getReportAdminAccountInformation } from './Reports.utils';

export const AdminReportDetails = (): JSX.Element => {
  const [t] = useTranslation();
  const navigate = useNavigate();

  const settings = useAppSelector(selectSettings);
  const reasonsById = keyBy(settings[ParameterType.ReportReason], 'optionId');

  const { isOpen, onClose, meta } = useModalActions(
    ModalType.AdminReportDetails
  );

  const { mutate, isLoading } = useUpdateReport();

  const navigateToCustomer = useNavigateToCustomer();

  const [selectedStatus, setSelectedStatus] = useState<ReportStatus[]>([]);

  const { data: conversatorData } = useCustomerInfo(
    meta?.details?.meta?.conversatorId || ''
  );

  useEffect(() => {
    setSelectedStatus(meta ? [meta.details.status] : []);
  }, [meta]);

  const actions: ParamType<typeof ModalContainer, 'footerButtons'> = [
    {
      text: t('actions.cancel'),
      variant: 'ghostGray'
    },
    {
      text: startCase(t('actions.save_changes')),
      isLoading,
      isPreventClose: true,
      onClick: () => {
        if (meta) {
          mutate(
            {
              reportId: meta.details.id,
              status: selectedStatus[0]
            },
            {
              onSuccess: onClose
            }
          );
        }
      }
    }
  ];

  const options = generateOptionsFromSettings(
    OptionKey.ReportStatus,
    t,
    settings
  );

  return (
    <ModalContainer
      header={t('attribute.title.report_details')}
      footerButtons={actions}
      isOpen={isOpen}
      onClose={onClose}
      size="3xl"
    >
      {!!meta && (
        <VStack spacing={6} alignItems="stretch">
          <Box>
            <FormLabel>{t('keywords.reported')}</FormLabel>
            <Box padding={4} bgColor="gray.100" borderRadius="1.25rem">
              <Avatar
                title={meta.details.reported.displayedNickname}
                description={meta.details.reported.email}
                onClick={() => {
                  if (meta.details.reported.hasAdminAccess) {
                    navigateToCustomer(meta.details.reported.id);
                  } else {
                    const { profileUrl } = meta.details.reported;

                    if (profileUrl) navigate(profileUrl);
                  }
                }}
              />
            </Box>
          </Box>
          {conversatorData && (
            <Box>
              <FormLabel>{t('keywords.conversator')}</FormLabel>
              <Box padding={4} bgColor="gray.100" borderRadius="1.25rem">
                <Avatar
                  title={conversatorData.displayedNickname}
                  description={conversatorData.email}
                />
              </Box>
            </Box>
          )}
          <Box>
            <FormLabel>{t('keywords.status')}</FormLabel>
            <Dropdown
              isControllable
              options={options}
              values={selectedStatus}
              onChange={(values) => setSelectedStatus(values as ReportStatus[])}
            />
          </Box>
          <HStack spacing={3} justifyContent="space-between">
            {getReportAdminAccountInformation({
              reportDate: meta.details.createdAt,
              reportReason: meta.details.reason,
              reasons: reasonsById,
              t
            }).map((item, index) => (
              <InfoCard key={`info_card_${index}`} {...item} />
            ))}
          </HStack>

          <Box>
            <FormLabel>{t('keywords.comment', { count: 10 })}</FormLabel>
            <TextArea
              isReadOnly
              value={meta.details.comment || ''}
              shouldSizeByContent
              resize="unset"
            />
          </Box>
          {meta?.details?.meta?.message && (
            <Box>
              <FormLabel>{t('keywords.message')}</FormLabel>
              <TextArea
                isReadOnly
                value={meta.details.meta.message}
                shouldSizeByContent
                resize="unset"
              />
            </Box>
          )}
        </VStack>
      )}
    </ModalContainer>
  );
};
