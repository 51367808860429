import { Checkbox } from '@chakra-ui/react';
import { yupResolver } from '@hookform/resolvers/yup';
import { FormField } from 'enum';
import { useUpdatePaymentMethod } from 'hooks';
import { FC } from 'react';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';

const enabledSuccessOnFailValidationSchema = yup.object({
  [FormField.EnabledSuccessOnFail]: yup.array(yup.string().required()).ensure()
});

export const EnabledSuccessOnFailCheckbox: FC<{
  isChecked: boolean;
  methodId: string;
}> = ({ isChecked, methodId }) => {
  const form = useForm({
    mode: 'onChange',
    resolver: yupResolver(enabledSuccessOnFailValidationSchema)
  });

  const { handleSubmit } = form;

  const { mutate: updatePaymentMethod } = useUpdatePaymentMethod();

  const onSubmit = handleSubmit(() => {
    updatePaymentMethod({
      methodId,
      enabledSuccessOnFail: !isChecked
    });
  });

  return <Checkbox isChecked={isChecked} onChange={onSubmit} />;
};
