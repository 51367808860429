import { useQuery } from '@tanstack/react-query';
import { fetchCustomerReportList } from 'api';
import { QueryKey } from 'enum/api';
import { useAppSelector } from 'hooks';
import { selectAppFilters } from 'store/app';
import { QueryFilters } from 'types';

export const useCustomerReportList = (queryFilters: QueryFilters) => {
  const globalFilters = useAppSelector(selectAppFilters);

  return useQuery(
    [QueryKey.ReportCustomerList, globalFilters, queryFilters],
    () => fetchCustomerReportList({ globalFilters, queryFilters }),
    {
      retry: false,
      refetchOnWindowFocus: false,
      keepPreviousData: true
    }
  );
};
