import { createSlice } from '@reduxjs/toolkit';

import { closeModal, openModal } from './actions';
import { IModalState } from './modal.types';

const initialState: IModalState = {
  openModal: []
};

export const modalSlice = createSlice({
  name: 'modal',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(openModal, (state, action) => {
      if (!state.openModal.find(({ id }) => id === action.payload.id)) {
        //@ts-ignore
        state.openModal.push(action.payload);
      }
    });
    builder.addCase(closeModal, (state, action) => {
      const index = state.openModal.findIndex(
        ({ id }) => id === action.payload
      );

      if (index !== -1) {
        state.openModal.splice(index, 1);
      }
    });
  }
});

export const { reducer: modalReducer } = modalSlice;
