import { Colors } from '@chakra-ui/theme';

export const COLORS: Colors = {
  black: '#262626',
  white: '#FFFFFF',
  disabled: '#ACADB9',
  success: '#4AAD66',
  error: '#F1634F',
  warning: '#FFBC42',
  subtext: '#ACADB9',
  status: {
    blue: {
      100: '#E4EEFE',
      500: '#4A84F6'
    },
    green: {
      100: '#E9FBFA',
      500: '#1BB0A7'
    },
    gray: {
      100: '#F5F5F8',
      500: '#71738B'
    },
    red: {
      100: '#FED7D7',
      500: '#E53E3E'
    }
  },
  primary: {
    50: '#4558FF',
    100: '#F5F8FF',
    200: '#4558FF',
    300: '#4558FF',
    400: '#4558FF',
    500: '#4558FF',
    600: '#6B7AFF', // hover
    700: '#2F42F0', // selected
    800: '#2F42F0',
    900: '#2F42F0'
  },
  secondary: {
    50: '#FF9649',
    100: '#FF9649',
    200: '#FF9649',
    300: '#FF9649',
    400: '#FF9649',
    500: '#FF9649',
    600: '#FFA767',
    700: '#FF7E20',
    800: '#FF7E20',
    900: '#FF7E20'
  },
  blackAlpha: {
    50: 'rgba(38, 38, 38 0.04)',
    100: 'rgba(38, 38, 38, 0.06)',
    200: 'rgba(38, 38, 38, 0.08)',
    300: 'rgba(38, 38, 38, 0.16)',
    400: 'rgba(38, 38, 38, 0.24)',
    500: 'rgba(38, 38, 38, 0.36)',
    600: 'rgba(38, 38, 38, 0.48)',
    700: 'rgba(38, 38, 38, 0.64)',
    800: 'rgba(38, 38, 38, 0.80)',
    900: 'rgba(38, 38, 38, 0.92)'
  },

  gray: {
    100: '#F5F5F8',
    200: '#EAEBED',
    250: '#E7E7E7',
    300: '#D5D6DC',
    500: '#ACADB9',
    600: '#71738B',
    800: '#53545E',
    850: '#7B7C92',
    900: '#393A3E'
  },

  red: {
    50: '#FFF5F5',
    100: '#FED7D7',
    200: '#FEB2B2',
    300: '#FC8181',
    400: '#F56565',
    500: '#E53E3E',
    600: '#C53030',
    700: '#9B2C2C',
    800: '#822727',
    900: '#63171B'
  },

  blue: {
    50: '#ebf8ff',
    100: '#BCD2EF',
    200: '#90cdf4',
    300: '#6574FF',
    400: '#5299e1',
    500: '#4558FF',
    600: '#2b6cb0',
    700: '#2F42F0',
    800: '#2a4365',
    900: '#1A365D'
  },

  green: {
    400: '#6BAA75'
  },
  pink: {
    300: '#DE6C83'
  }
};
