import { Button, VStack } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

export const RightSideButtons = ({
  onSubmit,
  onCancel,
  isDisabledSubmit,
  isLoading = false,
  label
}: {
  onSubmit: () => void;
  onCancel: () => void;
  isDisabledSubmit: boolean;
  isLoading?: boolean;
  label?: string;
}): JSX.Element => {
  const [t] = useTranslation();

  return (
    <VStack spacing={4}>
      <Button
        width="100%"
        onClick={onSubmit}
        isDisabled={isDisabledSubmit}
        isLoading={isLoading}
      >
        {label || t('actions.save_changes')}
      </Button>
      <Button width="100%" variant="ghostWhite" onClick={onCancel}>
        {t('actions.cancel')}
      </Button>
    </VStack>
  );
};
