import { ImageStatus } from 'enum/api';

export const getTagStatusColorByStatus = (status: ImageStatus) => {
  switch (status) {
    case ImageStatus.Active:
      return 'blue';
    default:
      return 'gray';
  }
};
