import { ENDPOINTS, WithPagination } from 'api';
import { serializeQueryFilters } from 'api/api.utils';
import axios from 'axios';
import { SupportSourceType } from 'enum/api';
import { QueryFilters } from 'types';

import { ContactUsTicketConversationListResponse } from './ContactUs.types';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const fakedData: ContactUsTicketConversationListResponse[] = [
  {
    id: 'id_1',
    sentAt: new Date().toISOString(),
    text: 'Some text',
    comment: null,
    source: SupportSourceType.Telegram,
    sender: {
      id: 'customer_1',
      nickname: 'Nickname1',
      email: 'email',
      avatar: null
    }
  },
  {
    id: 'id_2',
    sentAt: new Date().toISOString(),
    text: 'Some text',
    comment: null,
    source: SupportSourceType.Telegram,
    sender: {
      id: 'admin_1',
      nickname: 'admin',
      email: 'emailadmin',
      avatar: null
    }
  }
];

export const fetchTicketConversations = async ({
  ticketId,
  queryFilters
}: {
  ticketId: string;
  queryFilters: QueryFilters;
}): Promise<WithPagination<ContactUsTicketConversationListResponse[]>> => {
  const { data } = await axios.post<
    WithPagination<ContactUsTicketConversationListResponse[]>
  >(
    `${ENDPOINTS.CONTACT_US_TICKET}/${ticketId}/messages/list`,
    serializeQueryFilters(queryFilters)
  );

  return data;
};
