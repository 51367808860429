import { GridItem } from '@chakra-ui/react';
import { FormElement } from 'containers';
import { InputElement } from 'enum';
import { Fragment } from 'react';
import { useTranslation } from 'react-i18next';

import { GeneralSettingsFormField } from '../FormContent.types';

export const MinMaxAgeCustomers = (): JSX.Element => {
  const [t] = useTranslation();

  return (
    <Fragment>
      <GridItem>
        {t('attribute.description.min_and_max_age_customers')}
      </GridItem>
      <GridItem>
        <FormElement
          type={InputElement.Range}
          name={GeneralSettingsFormField.MinMaxAgeCustomers}
          translationKey="ages"
          getPlaceholder={() => ''}
          minValue={18}
          maxValue={99}
        />
      </GridItem>
    </Fragment>
  );
};
