import { GridItem, HStack, Spinner } from '@chakra-ui/react';
import { AddButton, Table } from 'components';
import { ModalType } from 'enum';
import {
  useAppDispatch,
  useCustomerIdFromUrl,
  useTableQueryController,
  useTicketList
} from 'hooks';
import { Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import { openModal } from 'store/modal';

import { ticketHistoryColumns } from '../AdminInteraction.utils';

export const TicketHistory = (): JSX.Element => {
  const [t] = useTranslation();
  const dispatch = useAppDispatch();

  const customerId = useCustomerIdFromUrl();

  const {
    queryFilters,
    action: { onChangeSort, onChangePagination }
  } = useTableQueryController({
    fixedValues: {
      filter: {
        userIds: [customerId]
      }
    }
  });

  const { data } = useTicketList(queryFilters);

  const handleCreateTicket = () => {
    dispatch(
      openModal({
        id: ModalType.CreateTicket,
        meta: {
          customerId
        }
      })
    );
  };

  const handleCreateEditQuickReply = () => {
    dispatch(
      openModal({
        id: ModalType.QuickReplyCustomer,
        meta: {
          mode: 'edit'
        }
      })
    );
  };

  if (!data) return <Spinner />;

  return (
    <Fragment>
      <GridItem area="actions" justifySelf="flex-end">
        <HStack spacing={4}>
          <AddButton onClick={handleCreateEditQuickReply}>
            {t('actions.create_quick_reply')}
          </AddButton>
          <AddButton onClick={handleCreateTicket}>
            {t('actions.create_ticket')}
          </AddButton>
        </HStack>
      </GridItem>
      <GridItem area="table" overflow="hidden">
        <Table
          data={data.data}
          columns={ticketHistoryColumns}
          onChangeSort={onChangeSort}
          onChangePagination={onChangePagination}
          totalItems={data.totalItems}
          pagination={queryFilters.pagination}
        />
      </GridItem>
    </Fragment>
  );
};
