import {
  Grid,
  GridItem,
  Heading,
  Skeleton,
  useDisclosure
} from '@chakra-ui/react';
import { AdminUsersLoginsResponse } from 'api';
import {
  ChartLegend,
  FilterButtons,
  LineChart,
  ModalContainer,
  useTableFilters
} from 'containers';
import { GenderTruncated, ParameterType } from 'enum';
import { useAppSelector, useChartData, usePurchasedSubscriptions } from 'hooks';
import { FullScreenButton } from 'pages/AdminPanel/components';
import { Fragment, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { selectSettings } from 'store';
import { ChartFilters } from 'types';
import { STATISTIC_TOTAL } from 'utils';

import {
  CHART_SETTINGS,
  chartFiltersSchema
} from './PurchasedSubscriptions.utils';

export const PurchasedSubscriptions = (): JSX.Element => {
  const [t] = useTranslation();
  const settings = useAppSelector(selectSettings);

  const [filterData, setFilterData] = useState<ChartFilters>({});
  const { isOpen: isModalOpen, onToggle, onClose } = useDisclosure();

  const filterButtonsProps = useTableFilters({
    filters: chartFiltersSchema,
    onChange: setFilterData
  });

  const { data, isSuccess } = usePurchasedSubscriptions({
    filters: filterData
  });

  const processedData = useMemo(() => {
    if (!data?.length) return [];

    const settingsOptions = settings[ParameterType.Gender];
    const mainGendersData: AdminUsersLoginsResponse[] = [];
    const otherGendersData: AdminUsersLoginsResponse[] = [];
    let totalData;
    let processedData;

    data.map((item) =>
      [GenderTruncated.Female, GenderTruncated.Male].includes(
        settingsOptions.find(({ optionId }) => optionId === item.id)
          ?.systemId as GenderTruncated
      )
        ? mainGendersData.push(item)
        : item.id === STATISTIC_TOTAL
        ? (totalData = item)
        : otherGendersData.push(item)
    );

    if (otherGendersData.length) {
      processedData = [
        ...mainGendersData,
        otherGendersData.reduce(
          (acc, item) => ({
            ...acc,
            data: acc.data.map(({ date, value }, idx) => ({
              date,
              value: value + item.data[idx].value
            }))
          }),
          {
            id: GenderTruncated.Other,
            data: otherGendersData[0].data.map(({ date }) => ({
              date,
              value: 0
            }))
          }
        )
      ];
    } else {
      processedData = mainGendersData;
    }

    if (totalData) processedData.push(totalData);

    return processedData;
  }, [data, settings]);

  const chartDataMap = useChartData({
    apiData: processedData,
    chartSettings: CHART_SETTINGS
  });

  const { chartData = [] } = chartDataMap || {};

  const title = t('attribute.title.purchased_subscriptions');

  const content = (
    <Grid
      templateAreas={`
      "title filters full"
      "legend legend legend"
      "chart chart chart"
    `}
      rowGap={4}
      columnGap={4}
      templateColumns="1fr auto auto"
    >
      <GridItem area="title">
        <Heading size="l">{title}</Heading>
      </GridItem>
      <GridItem area="filters">
        <FilterButtons {...filterButtonsProps} />
      </GridItem>
      <GridItem area="full">
        <FullScreenButton isFullMode={isModalOpen} onClick={onToggle} />
      </GridItem>
      <GridItem area="legend">
        <Skeleton isLoaded={isSuccess}>
          <ChartLegend data={chartData} />
        </Skeleton>
      </GridItem>
      <GridItem area="chart" height={isModalOpen ? '60vh' : '30rem'}>
        <Skeleton isLoaded={isSuccess} boxSize="100%">
          {!!chartDataMap && (
            <LineChart dataMap={chartDataMap} filters={filterData} />
          )}
        </Skeleton>
      </GridItem>
    </Grid>
  );

  return (
    <Fragment>
      {content}
      <ModalContainer
        isOpen={isModalOpen}
        onClose={onClose}
        size="max"
        modalBodyProps={{
          textAlign: 'left'
        }}
        shouldHideCloseButton
      >
        {content}
      </ModalContainer>
    </Fragment>
  );
};
