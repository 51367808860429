import { yupResolver } from '@hookform/resolvers/yup';
import { FormConstructor, ModalContainer } from 'containers';
import { ErrorCode, ModalType } from 'enum';
import { useChakraToast, useModalActions, useUpdatePopup } from 'hooks';
import { snakeCase } from 'lodash';
import { useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { ParamType } from 'types';
import { serializeAxiosError } from 'utils';

import { EditPopupFormData } from './EditPopup.types';
import {
  editPopupFormSchema,
  editPopupValidationSchema,
  getDefaultFormValues,
  serializeRequestData
} from './EditPopup.utils';

export const EditPopup = (): JSX.Element => {
  const [t] = useTranslation();
  const toast = useChakraToast();

  const { isOpen, onClose, meta } = useModalActions(ModalType.EditPopup);

  const form = useForm<EditPopupFormData>({
    mode: 'onSubmit',
    resolver: yupResolver(editPopupValidationSchema),
    defaultValues: getDefaultFormValues(meta?.popup)
  });

  const { handleSubmit, reset } = form;

  useEffect(() => {
    if (meta?.popup) {
      reset({
        ...getDefaultFormValues(meta.popup)
      });
    }
  }, [reset, meta]);

  const { mutate, isLoading } = useUpdatePopup();

  const onSubmit = handleSubmit((data) => {
    mutate(
      {
        popupId: meta?.popup.id || '',
        ...serializeRequestData(data)
      },
      {
        onSuccess: onClose,
        onError: (error) => {
          const { errorCode } = serializeAxiosError(error);

          if (errorCode === ErrorCode.ArticleDuplicate) {
            toast({
              title: t(`errors.codes.${snakeCase(errorCode)}`),
              status: 'error'
            });
          } else {
            toast({
              status: 'error',
              title: t('errors.invalid_form'),
              description: error.message
            });
          }
        }
      }
    );
  });

  const actions: ParamType<typeof ModalContainer, 'footerButtons'> = [
    {
      text: t('actions.cancel'),
      variant: 'ghostGray',
      disabled: isLoading
    },
    {
      text: t('actions.save'),
      colorScheme: 'secondary',
      isLoading,
      isPreventClose: true,
      isDisabled: false,
      onClick: () => {
        onSubmit();
      }
    }
  ];

  return (
    <ModalContainer
      header={t('attribute.title.edit_popup')}
      footerButtons={actions}
      isOpen={isOpen}
      onClose={onClose}
      size="6xl"
    >
      <FormProvider {...form}>
        <FormConstructor
          formSchema={editPopupFormSchema}
          meta={
            meta?.popup?.frequencyScheduler && {
              additional: {
                frequencyScheduler: {
                  weekDays: meta.popup.frequencyScheduler.weekDays,
                  frequency: meta.popup.frequencyScheduler.frequency
                }
              }
            }
          }
        />
      </FormProvider>
    </ModalContainer>
  );
};
