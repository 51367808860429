import { Center, Spinner } from '@chakra-ui/react';
import { PageName } from 'enum';
import { useAuthorization, useIdleUser } from 'hooks';
import { Fragment, ReactNode } from 'react';
import { Navigate } from 'react-router-dom';
import { useMount } from 'react-use';
import { checkIfTokenExists } from 'utils';

export const RequireAuth = ({
  children
}: {
  children: ReactNode;
}): JSX.Element | null => {
  const { isAuthorized, isFetching, reLogin, logout } = useAuthorization();

  useIdleUser({
    logout
  });

  const isTokenExist = checkIfTokenExists();

  useMount(() => {
    if (isTokenExist && !isAuthorized) {
      reLogin();
    }
  });

  if (isFetching)
    return (
      <Center height="100%">
        <Spinner
          thickness="0.25rem"
          speed="1s"
          emptyColor="gray.200"
          color="primary.500"
          size="xl"
        />
      </Center>
    );

  if (isTokenExist && !isAuthorized) return null;

  if (isAuthorized) return <Fragment>{children}</Fragment>;

  return <Navigate to={PageName.Login} replace />;
};
