import { ENDPOINTS } from 'api/endpoints';
import axios, { AxiosResponse } from 'axios';
import identity from 'lodash/identity';
import pickBy from 'lodash/pickBy';
import { EditAdminFormData } from 'pages';

import {
  Admin,
  AdminResponse,
  UpdateAdminRequestData
} from './AdminManagement.types';

export const fetchUpdateAdmin = async (
  adminData: Partial<EditAdminFormData> & {
    id: string;
  }
): Promise<Admin> => {
  const { id, password, userName, userType, status, countries, domains } =
    adminData;

  const body: UpdateAdminRequestData = {
    password,
    nickname: userName,
    role: userType,
    status,
    countries,
    domains
  };

  const { data } = await axios.patch<
    AdminResponse,
    AxiosResponse<AdminResponse>,
    UpdateAdminRequestData
  >(`${ENDPOINTS.ADMINS}/${id}`, pickBy(body, identity));

  return data;
};
