import { Box, HStack, VStack } from '@chakra-ui/react';
import { ModalContainer } from 'containers';
import { ModalType } from 'enum';
import { OptionKey, SupportSourceType, SystemLanguage } from 'enum/api';
import {
  useChakraToast,
  useCreateQuickReply,
  useDeleteQuickReply,
  useEditQuickReply,
  useModalActions,
  useQuickReplies,
  useTicketReply
} from 'hooks';
import { groupBy } from 'lodash';
import { FormEvent, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ParamType } from 'types';

import {
  AddSelectDeleteField,
  Select,
  TextField,
  TextRadioGroup
} from './components';

type TextVariant = {
  id: string | null;
  value: string;
};

const MAX_VARIANTS_COUNT = 4;

export const QuickReplyCustomer = (): JSX.Element => {
  const [t] = useTranslation();
  const toast = useChakraToast();

  const { data: quickReplays } = useQuickReplies();

  const { mutateAsync: editQuickReply } = useEditQuickReply();

  const { mutateAsync: creteQuickReply } = useCreateQuickReply();

  const { mutateAsync: deleteQuickReply } = useDeleteQuickReply();

  const { mutate, isLoading } = useTicketReply();

  const [groups, setGroups] = useState<string[]>([]);
  const [titles, setTitles] = useState<string[]>([]);
  const [variants, setVariants] = useState<TextVariant[]>([]);
  const [sourceType, setSourceType] = useState<SupportSourceType>();
  const [language, setLanguage] = useState<SystemLanguage>();
  const [currentVariantIdx, setCurrentVariantIdx] = useState<number>();
  const [group, setGroup] = useState<string>();
  const [title, setTitle] = useState<string>();

  const textFieldRef = useRef<HTMLTextAreaElement>(null);

  const { isOpen, onClose, meta } = useModalActions(
    ModalType.QuickReplyCustomer,
    {
      onClose() {
        setGroups([]);
        setTitles([]);
        setVariants([]);
        setSourceType(undefined);
        setLanguage(undefined);
        setCurrentVariantIdx(undefined);
        setGroup(undefined);
        setTitle(undefined);
      }
    }
  );

  const needToSave = useMemo(() => {
    if (!meta?.mode || !quickReplays || currentVariantIdx === undefined) {
      return false;
    }

    return variants.reduce((result, variant) => {
      if (result) {
        return result;
      }

      if (variant.id === null && variant.value) {
        return true;
      }

      if (variant.id !== null) {
        const storedText = quickReplays.find(
          ({ id }) => id === variant.id
        )?.text;

        return storedText !== variant.value;
      }

      return false;
    }, false);
  }, [currentVariantIdx, meta?.mode, quickReplays, variants]);

  useEffect(() => {
    if (language && quickReplays) {
      const newGroups = Object.keys(
        groupBy(
          quickReplays.filter((item) => item.languageCode === language),
          ({ group }) => group
        )
      ) as string[];

      setGroups(newGroups);
      if (group && !newGroups.includes(group)) {
        setGroup(undefined);
      }
    }
  }, [quickReplays, language]);

  useEffect(() => {
    if (language && group && quickReplays) {
      const newTitles = Object.keys(
        groupBy(
          quickReplays.filter(
            (item) => item.languageCode === language && item.group === group
          ),
          ({ title }) => title
        )
      ) as string[];
      setTitles(newTitles);

      if (title && !newTitles.includes(title)) {
        setTitle(undefined);
      }
    }
  }, [quickReplays, language, group]);

  useEffect(() => {
    if (language && group && title && quickReplays) {
      const replays = quickReplays
        .filter(
          (item) =>
            item.languageCode === language &&
            item.group === group &&
            item.title === title
        )
        .map(({ id, text }) => ({ id, value: text }));

      const slotsCount =
        meta?.mode === 'use' ? replays.length : MAX_VARIANTS_COUNT;

      const slots = Array(slotsCount)
        .fill(null)
        .map((_, idx) => ({
          id: replays[idx]?.id || null,
          idx,
          value: replays[idx]?.value || ''
        }));

      setVariants(slots);
      setCurrentVariantIdx(undefined);
    }
  }, [group, language, meta?.mode, quickReplays, title]);

  useEffect(() => {
    if (currentVariantIdx !== undefined) {
      setTimeout(() => textFieldRef.current?.focus());
    }
  }, [currentVariantIdx]);

  const onDeleteGroup = async (group: string) => {
    if (!quickReplays) {
      return;
    }

    try {
      await Promise.all(
        quickReplays
          .filter(
            (item) => item.languageCode === language && item.group === group
          )
          .map(({ id }) => deleteQuickReply({ id }))
      );
      setGroup(undefined);
      setTitle(undefined);
      setCurrentVariantIdx(undefined);
      setVariants([]);
    } catch {
      toast({
        status: 'error',
        description: t('errors.error_occurred_while_deleting_data')
      });
    }
  };

  const onDeleteTitle = async (title: string) => {
    if (!quickReplays) {
      return;
    }

    try {
      await Promise.all(
        quickReplays
          .filter(
            (item) =>
              item.languageCode === language &&
              item.group === group &&
              item.title === title
          )
          .map(({ id }) => deleteQuickReply({ id }))
      );
      setTitle(undefined);
      setCurrentVariantIdx(undefined);
      setVariants([]);
    } catch {
      toast({
        status: 'error',
        description: t('errors.error_occurred_while_deleting_data')
      });
    }
  };

  const onDeleteText = async (idx: number) => {
    if (variants[idx] === undefined) {
      return;
    }

    try {
      const id = variants[idx].id;
      if (id) {
        await deleteQuickReply({
          id
        });
      } else {
        setVariants([...variants].splice(idx, 1));
        if (idx === variants.length - 1) {
          setCurrentVariantIdx(variants.length - 2);
        }
      }
    } catch {
      toast({
        status: 'error',
        description: t('errors.error_occurred_while_deleting_data')
      });
    }
  };

  const onSubmit = async (e?: FormEvent) => {
    if (!meta) {
      return;
    }

    if (meta.mode === 'edit') {
      try {
        await Promise.all(
          variants
            .filter(({ value }) => !!value)
            .map((variant) => {
              if (variant.id) {
                return editQuickReply({
                  id: variant.id,
                  text: variant.value
                });
              } else if (language && group && title) {
                return creteQuickReply({
                  languageCode: language,
                  group,
                  title,
                  text: variant.value
                });
              }
            })
        );

        toast({
          status: 'success',
          description: t('messages.successfully_saved')
        });
      } catch (error) {
        toast({
          status: 'error',
          description: t('errors.error_occurred_while_saving_data')
        });
      }

      return;
    }

    if (
      sourceType &&
      meta.ticketId &&
      currentVariantIdx !== undefined &&
      variants[currentVariantIdx]
    ) {
      mutate(
        {
          ticketId: meta.ticketId,
          source: sourceType,
          text: variants[currentVariantIdx].value
        },
        {
          onSuccess: onClose,
          onError(error) {
            toast({
              status: 'error',
              description: error.message
            });
          }
        }
      );
    }
  };

  const handleChangeText = (text: string) => {
    if (currentVariantIdx === undefined) {
      return;
    }

    setVariants(
      variants.map((variant, idx) => {
        if (currentVariantIdx === idx) {
          return {
            ...variant,
            value: text
          };
        }

        return variant;
      })
    );
  };

  const text =
    currentVariantIdx === undefined
      ? ''
      : variants[currentVariantIdx].value ?? '';

  const actions: ParamType<typeof ModalContainer, 'footerButtons'> = meta
    ? [
        { text: t('actions.cancel'), variant: 'ghostGray' },
        {
          text: t(meta.mode === 'edit' ? 'actions.save' : 'actions.reply'),
          isPreventClose: true,
          isLoading,
          isDisabled: meta.mode === 'edit' ? !needToSave : !text.length,
          onClick: () => {
            onSubmit();
          }
        }
      ]
    : [];

  return (
    <ModalContainer
      header={t('attribute.title.quick_reply_customer')}
      footerButtons={actions}
      isOpen={isOpen}
      onClose={onClose}
      size="6xl"
    >
      {meta && (
        <VStack
          spacing={8}
          as="form"
          onSubmit={(e) => e.preventDefault()}
          width="full"
        >
          {meta.mode === 'use' && (
            <Box width="30%">
              <Select
                labelKey="source"
                optionKey={OptionKey.SupportSourceType}
                value={sourceType}
                onChange={setSourceType}
              />
            </Box>
          )}
          <HStack alignItems="stretch" spacing={8} width="full">
            <VStack flex="1 1 1px" height="full" justifyContent="flex-start">
              <Select
                labelKey="language"
                optionKey={OptionKey.SystemLanguage}
                value={language}
                onChange={setLanguage}
                getLabel={(value) => value.toUpperCase()}
                isDisabled={meta.mode === 'use' && !sourceType}
              />
              <AddSelectDeleteField
                key={language ? `${sourceType}$$${language}` : undefined}
                value={group}
                onChange={setGroup}
                labelKey="group"
                onBlur={() => null}
                onDelete={onDeleteGroup}
                values={groups}
                mode={meta.mode}
                isDisabled={!language}
              />
              <AddSelectDeleteField
                key={group ? `${sourceType}$$${language}$$${group}` : undefined}
                value={title}
                onChange={setTitle}
                labelKey="title"
                onBlur={() => null}
                onDelete={onDeleteTitle}
                values={titles}
                mode={meta.mode}
                isDisabled={!language || !group}
              />
            </VStack>
            <Box borderLeft="1px" borderColor="gray.500" />
            <Box flex="1 1 1px">
              <TextRadioGroup
                onDelete={onDeleteText}
                onSelect={setCurrentVariantIdx}
                mode={meta.mode}
                isDisabled={!title}
                options={variants}
                selected={currentVariantIdx}
              />
            </Box>
          </HStack>
          <TextField
            value={text}
            onChange={handleChangeText}
            labelKey="text_message"
            isDisabled={currentVariantIdx === undefined}
            ref={textFieldRef}
          />
        </VStack>
      )}
    </ModalContainer>
  );
};
