import { FormField, InputElement } from 'enum';
import { BadWordsType } from 'enum/api';
import identity from 'lodash/identity';
import pickBy from 'lodash/pickBy';
import { DefaultValues } from 'react-hook-form';
import { ICustomerBadWords } from 'types/customer';
import * as yup from 'yup';

import { CustomerBadWordsFormData } from './CustomerBadWords.types';

export const customerBadWordsFormSchema = {
  fields: {
    [FormField.BadWordsMessageWarning]: {
      type: InputElement.MultiSelect,
      translationKey: 'messages_bad_words_warning',
      getPlaceholder: () => 'Select words',
      shouldHideOptions: true,
      isSearchable: true,
      canAdd: true
    },
    [FormField.BadWordsProfileWarning]: {
      type: InputElement.MultiSelect,
      translationKey: 'profile_bad_words_warning',
      getPlaceholder: () => 'Select words',
      shouldHideOptions: true,
      isSearchable: true,
      canAdd: true
    },
    [FormField.BadWordsMessageBlocking]: {
      type: InputElement.MultiSelect,
      translationKey: 'messages_bad_words_blocking',
      getPlaceholder: () => 'Select words',
      shouldHideOptions: true,
      isSearchable: true,
      canAdd: true
    }
  },
  gridLayout: {
    templateAreas: `
    "${FormField.BadWordsMessageWarning}"
    "${FormField.BadWordsProfileWarning}"
    "${FormField.BadWordsMessageBlocking}"
  `,
    templateColumns: '1fr',
    rowGap: 6
  }
} as const;

export const customerBadWordsValidationSchema: yup.SchemaOf<CustomerBadWordsFormData> =
  yup.object().shape({
    [FormField.BadWordsMessageWarning]: yup
      .array(yup.string().required())
      .required(),
    [FormField.BadWordsProfileWarning]: yup
      .array(yup.string().required())
      .required(),
    [FormField.BadWordsMessageBlocking]: yup
      .array(yup.string().required())
      .required()
  });

export const getDefaultFormValues = (
  badWords?: ICustomerBadWords
): DefaultValues<CustomerBadWordsFormData> => {
  if (badWords) {
    return {
      [FormField.BadWordsMessageWarning]: badWords[BadWordsType.MessageWarn],
      [FormField.BadWordsProfileWarning]: badWords[BadWordsType.ProfileWarn],
      [FormField.BadWordsMessageBlocking]: badWords[BadWordsType.MessageBlock]
    };
  }

  return {
    [FormField.BadWordsMessageWarning]: [],
    [FormField.BadWordsProfileWarning]: [],
    [FormField.BadWordsMessageBlocking]: []
  };
};

export const serializeBadWordsRequestData = (
  data: Partial<CustomerBadWordsFormData>
) =>
  pickBy(
    {
      [BadWordsType.MessageWarn]: data[FormField.BadWordsMessageWarning],
      [BadWordsType.MessageBlock]: data[FormField.BadWordsMessageBlocking],
      [BadWordsType.ProfileWarn]: data[FormField.BadWordsProfileWarning]
    },
    identity
  );
