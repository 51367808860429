import { ENDPOINTS, UpdateModerationItemStatusRequestData } from 'api';
import axios, { AxiosResponse } from 'axios';

export const fetchUpdateAboutMeStatus = ({
  aboutMeId,
  ...body
}: UpdateModerationItemStatusRequestData & {
  aboutMeId: string;
}) =>
  axios.patch<
    unknown,
    AxiosResponse<unknown>,
    UpdateModerationItemStatusRequestData
  >(`${ENDPOINTS.MODERATION_ABOUT_ME}/${aboutMeId}/status`, body);
