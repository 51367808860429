import { CellContext } from '@tanstack/react-table';
import { Admin } from 'api';
import { NavigationLink } from 'components';
import { getBaseAdminLinkNavigation } from 'utils';

type Props = CellContext<Admin, string>;

export const AdminNicknameCell = ({
  row: {
    original: { nickname, id }
  }
}: Props): JSX.Element => (
  <NavigationLink to={getBaseAdminLinkNavigation(id)}>
    {nickname}
  </NavigationLink>
);
