import { ENDPOINTS, WithPagination } from 'api';
import { serializeBodyFilters } from 'api/api.utils';
import axios from 'axios';
import { GlobalFilters, QueryFilters } from 'types';

import { IStaticPagesTableResponse } from '../../pages/StaticPages/types';

export const fetchStaticPagesList = async ({
  queryFilters,
  globalFilters
}: {
  queryFilters: QueryFilters;
  globalFilters: GlobalFilters;
}): Promise<WithPagination<IStaticPagesTableResponse[]>> => {
  const { data } = await axios.post<
    WithPagination<IStaticPagesTableResponse[]>
  >(
    `${ENDPOINTS.STATIC_PAGES}/list`,
    serializeBodyFilters({ queryFilters, globalFilters })
  );

  return data;
};
