import { BarCustomLayerProps, BarDatum } from '@nivo/bar';

import { useBarChartTheme } from '../useBarChartTheme';

import { BarChartLabel } from './BarChartLabel';

export const BarChartLabelLayer = ({
  bars
}: BarCustomLayerProps<BarDatum>): JSX.Element => {
  const theme = useBarChartTheme();

  const maxBarWidth = bars.reduce(
    (acc, { width }) => (width > acc ? width : acc),
    0
  );

  return (
    <g>
      {bars.map((bar) => (
        <BarChartLabel
          {...bar}
          key={bar.data.indexValue.toString()}
          theme={theme}
          maxBarWidth={maxBarWidth}
        />
      ))}
    </g>
  );
};
