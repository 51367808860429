import { useMutation, useQueryClient } from '@tanstack/react-query';
import { fetchUpdateQuickReply, UserSupportQuickReply } from 'api';
import { QueryKey } from 'enum/api';

export const useEditQuickReply = () => {
  const queryClient = useQueryClient();

  return useMutation(fetchUpdateQuickReply, {
    async onMutate({ id, ...updates }) {
      await queryClient.cancelQueries({
        queryKey: [QueryKey.QuickReplies]
      });

      queryClient.setQueryData<UserSupportQuickReply[]>(
        [QueryKey.QuickReplies],
        (data) => {
          if (!data) {
            return data;
          }

          return data.map((item) => {
            if (item.id === id) {
              return {
                ...item,
                ...updates
              };
            }

            return item;
          });
        }
      );
    },
    onSettled() {
      queryClient.invalidateQueries([QueryKey.QuickReplies]);
    }
  });
};
