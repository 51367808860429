import { Icon, IconProps } from '@chakra-ui/react';

export const ArrowDownIcon = (props: IconProps): JSX.Element => (
  <Icon viewBox="0 0 24 24" fill="none" {...props}>
    <path
      d="M19 9.59998L11.9289 16.671L4.85786 9.59998"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="square"
    />
  </Icon>
);
