import { FormControl, FormLabel } from '@chakra-ui/react';
import DateTimeRangePicker from '@wojtekmaj/react-datetimerange-picker';
import { FormError } from 'components';
import { InputElement } from 'enum';
import { useMemo } from 'react';
import { useController } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { FormComponentProps } from '../FormConstructor.types';

type Props = FormComponentProps<{ type: InputElement.DateTimeRangePicker }>;

export const FormDateTimeRangePicker = ({
  type,
  translationKey,
  name,
  isDisabled,
  ...rest
}: Props): JSX.Element => {
  const [t] = useTranslation();

  const {
    field: { onChange, onBlur, value },
    fieldState: { error }
  } = useController({
    name
  });

  const pickerValue = useMemo(() => {
    if (!value) {
      return [];
    }

    return value;
  }, [value]);

  const handleChange = (
    pickerValue: Date | null | [Date | null, Date | null]
  ) => {
    const [from, to] = Array.isArray(pickerValue)
      ? pickerValue
      : [pickerValue, null];

    if (from || to) {
      onChange([from && from.toISOString(), to && to.toISOString()]);
    } else {
      onChange(undefined);
    }
  };

  return (
    <FormControl isInvalid={!!error}>
      <FormLabel>{t(`keywords.${translationKey}`)}</FormLabel>
      <DateTimeRangePicker
        {...rest}
        disabled={isDisabled}
        onChange={handleChange}
        onBlur={onBlur}
        value={pickerValue}
        format="dd/MM/yyy HH:mm:ss"
        disableClock
      />
      <FormError errorKey={error?.message} />
    </FormControl>
  );
};
