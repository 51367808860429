import { ChevronDownIcon, ChevronUpIcon } from '@chakra-ui/icons';
import {
  HStack,
  Icon,
  Menu,
  MenuButton,
  MenuItem,
  MenuList
} from '@chakra-ui/react';
import find from 'lodash/find';
import { Fragment, ReactNode } from 'react';

type CustomDropdownProps = {
  options: {
    label: ReactNode;
    value: string;
  }[];
  selectedValue: string;
  onChange: (value: string) => void;
};

export const TagDropdown = ({
  options,
  onChange,
  selectedValue
}: CustomDropdownProps): JSX.Element => {
  const selectedLabel = find(options, ['value', selectedValue])?.label;

  return (
    <Menu variant="unstyled">
      {({ isOpen }) => (
        <Fragment>
          <MenuButton px={0}>
            <HStack spacing={2}>
              {selectedLabel}
              <Icon
                as={isOpen ? ChevronUpIcon : ChevronDownIcon}
                sx={{
                  boxSize: 6,
                  color: 'subtext',
                  backgroundColor: 'white',
                  borderRadius: 'full'
                }}
              />
            </HStack>
          </MenuButton>
          <MenuList py={0}>
            {options.map(({ label, value }) => (
              <MenuItem
                key={value}
                value={value}
                padding={3}
                aria-selected={selectedValue === value}
                onClick={() => onChange(value)}
              >
                {label}
              </MenuItem>
            ))}
          </MenuList>
        </Fragment>
      )}
    </Menu>
  );
};
