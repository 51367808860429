import { usePermissions } from 'hooks';
import { Fragment, ReactNode } from 'react';
import { ResourceActionPermission } from 'types';

import { NoAccess } from './NoAccess';

type Props = {
  children: ReactNode | ((hasAccess: boolean) => ReactNode);
  noAccessRender?: ReactNode;
  restrictions: ResourceActionPermission | ResourceActionPermission[];
};
export const Access = ({
  restrictions,
  noAccessRender = <NoAccess />,
  children
}: Props): JSX.Element | null => {
  const { hasAccess } = usePermissions(restrictions);

  if (typeof children === 'function') {
    return <Fragment>{children(hasAccess)}</Fragment>;
  }

  return <Fragment>{hasAccess ? children : noAccessRender}</Fragment>;
};
