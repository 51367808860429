import { useQuery } from '@tanstack/react-query';
import { fetchVerificationLink } from 'api';
import { QueryKey } from 'enum/api';

export const useVerificationLink = (id: string) =>
  useQuery([QueryKey.VerificationLink, id], () => fetchVerificationLink(id), {
    retry: false,
    refetchOnWindowFocus: false,
    enabled: !!id
  });
