import { useQuery } from '@tanstack/react-query';
import { fetchSentMessages } from 'api';
import { QueryKey } from 'enum/api';
import { ChartFilters } from 'types';

export const useSentMessages = ({ filters }: { filters: ChartFilters }) =>
  useQuery(
    [QueryKey.StatisticSentMessages, filters],
    () => fetchSentMessages({ filters }),
    {
      retry: false,
      refetchOnWindowFocus: false,
      keepPreviousData: true
    }
  );
