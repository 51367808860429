import { yupResolver } from '@hookform/resolvers/yup';
import { FormConstructor, ModalContainer } from 'containers';
import { FormField, ModalType } from 'enum';
import { useCreateAdminComment, useModalActions } from 'hooks';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { ParamType } from 'types';

import { CreateCommentFormData } from './CreateComment.types';
import {
  createCommentValidationSchema,
  createCustomerCommentFormSchema
} from './CreateComment.utils';

export const CreateComment = (): JSX.Element => {
  const [t] = useTranslation();

  const form = useForm<CreateCommentFormData>({
    mode: 'onSubmit',
    resolver: yupResolver(createCommentValidationSchema),
    defaultValues: {
      [FormField.Comment]: '',
      [FormField.Files]: []
    }
  });

  const {
    handleSubmit,
    reset,
    formState: { dirtyFields }
  } = form;

  const { isOpen, onClose, meta } = useModalActions(ModalType.CreateComment, {
    onClose: reset
  });

  const { mutate, isLoading } = useCreateAdminComment();

  const onSubmit = handleSubmit((data) => {
    if (meta) {
      mutate(
        {
          customerId: meta.customerId,
          text: data.comment as string,
          image: data.files[0]?.file
        },
        {
          onSuccess: onClose
        }
      );
    }
  });

  const actions: ParamType<typeof ModalContainer, 'footerButtons'> = [
    {
      text: t('actions.discard'),
      variant: 'ghostGray'
    },
    {
      text: t('actions.save_changes'),
      colorScheme: 'primary',
      isLoading,
      isPreventClose: true,
      isDisabled: !dirtyFields[FormField.Comment],
      onClick: () => {
        onSubmit();
      }
    }
  ];

  return (
    <ModalContainer
      header={t('attribute.title.create_comment')}
      footerButtons={actions}
      isOpen={isOpen}
      onClose={onClose}
      size="xl"
    >
      <FormProvider {...form}>
        <FormConstructor formSchema={createCustomerCommentFormSchema} />
      </FormProvider>
    </ModalContainer>
  );
};
