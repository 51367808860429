import { ENDPOINTS } from 'api';
import { serializeBodyFilters } from 'api/api.utils';
import axios from 'axios';
import { GlobalFilters } from 'types';

import { ReportsByStatusApiResponse } from './AdminPanel.types';
import { serializeReportsByStatusResponse } from './AdminPanel.utils';

export const fetchReportsByStatus = async ({
  globalFilters
}: {
  globalFilters: GlobalFilters;
}): Promise<ReturnType<typeof serializeReportsByStatusResponse>> => {
  const { data } = await axios.post<ReportsByStatusApiResponse>(
    ENDPOINTS.REPORTS_STATISTIC_BY_STATUS,
    {
      ...serializeBodyFilters({ globalFilters })
    }
  );

  return serializeReportsByStatusResponse(data);
};
