import { FormField, InputElement } from 'enum';
import { getAllEnumValues } from 'enum-for';
import { ParameterType, SystemLanguage } from 'enum/api';
import { GetOptionsArg } from 'types/form';
import * as yup from 'yup';

import { CreateParameterValueFormData } from './CreateParameterValue.types';

export const createParameterValueFormSchema = {
  fields: {
    [FormField.ParameterType]: {
      type: InputElement.Select,
      translationKey: 'type',
      getPlaceholder: () => '',
      getOptions: () =>
        getAllEnumValues(ParameterType).map((value) => ({
          label: value,
          value
        }))
    },
    [FormField.ParameterTitle]: {
      type: InputElement.Select,
      translationKey: 'title',
      getPlaceholder: () => '',
      getOptions: ({ getFormValues, settings }: GetOptionsArg) =>
        (
          settings[getFormValues(FormField.ParameterType) as ParameterType] ||
          []
        ).map(({ title }) => ({
          label: title,
          value: title
        }))
    },
    [FormField.Language]: {
      type: InputElement.Select,
      translationKey: 'language',
      getPlaceholder: () => '',
      getOptions: () =>
        getAllEnumValues(SystemLanguage).map((value) => ({
          label: value,
          value
        }))
    },
    [FormField.Value]: {
      type: InputElement.Input,
      translationKey: 'value',
      getPlaceholder: () => ''
    },
    [FormField.Meta]: {
      type: InputElement.TextArea,
      translationKey: 'meta',
      getPlaceholder: () => ''
    }
  },
  gridLayout: {
    templateAreas: `
    "${FormField.ParameterType}"
    "${FormField.ParameterTitle}"
    "${FormField.Language}"
    "${FormField.Value}"
    "${FormField.Meta}"
    `,
    gridTemplateColumns: '1fr',
    rowGap: 6
  },
  dependedFields: {
    [FormField.ParameterType]: [FormField.ParameterTitle] as FormField[]
  }
} as const;

export const createParameterValueValidationSchema: yup.SchemaOf<CreateParameterValueFormData> =
  yup.object({
    [FormField.ParameterType]: yup.string().required(),
    [FormField.ParameterTitle]: yup.string().required(),
    [FormField.Language]: yup.string().required(),
    [FormField.Value]: yup.string().required(),
    [FormField.Meta]: yup
      .string()
      .test('jsonValidation', 'invalid_json', (value) => {
        if (!value) {
          return true;
        }
        try {
          JSON.parse(value);
          return true;
        } catch (error) {
          return false;
        }
      })
      .optional()
  });
