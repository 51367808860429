import {
  Button,
  Flex,
  FormControl,
  FormHelperText,
  FormLabel
} from '@chakra-ui/react';
import { Dropdown, FormError } from 'components';
import { DropdownType, InputElement } from 'enum';
import { PermissionAction } from 'enum/api';
import { useAppSelector, usePermissions } from 'hooks';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { selectSettings } from 'store/settings';

import { FormComponentProps } from '../FormConstructor.types';

type Props = FormComponentProps<{
  type: InputElement.MultiSelect | InputElement.Select;
}> & {
  isMulti: boolean;
  couldSelectAll?: boolean;
};

export const FormSelect = (props: Props): JSX.Element => {
  const [t] = useTranslation();
  const settings = useAppSelector(selectSettings);

  const { control, formState, getFieldState, getValues } = useFormContext();
  const { checkPermissions } = usePermissions();

  const {
    type,
    translationKey,
    getPlaceholder,
    name,
    hintTranslationKey,
    isMulti,
    resource,
    shouldHideOptions,
    getOptions,
    isDisabled,
    couldSelectAll,
    ...rest
  } = props;

  const { error } = getFieldState(name, formState);
  const hasUpdateAccess = resource
    ? checkPermissions({
        resource,
        actions: PermissionAction.Update
      })
    : true;

  const options = getOptions
    ? getOptions({
        t,
        settings,
        getFormValues: getValues
      })
    : undefined;

  return (
    <FormControl isInvalid={!!error}>
      <Controller
        control={control}
        name={name}
        render={({ field: { onChange, value } }) => {
          const values = value ? (isMulti ? value : [value]) : [];
          const allValues = options?.map(({ value }) => value);

          return (
            <Flex flexDir="column">
              <Flex justifyContent="space-between">
                <FormLabel>{t(`keywords.${translationKey}`)}</FormLabel>
                {couldSelectAll && (
                  <Button
                    variant="link"
                    isDisabled={!allValues?.length}
                    onClick={() => onChange(allValues)}
                  >
                    {t(`actions.add_all`)}
                  </Button>
                )}
              </Flex>
              <Dropdown
                type={DropdownType.Select}
                isDisabled={isDisabled || !hasUpdateAccess}
                {...rest}
                isMulti={isMulti}
                shouldHideOptions={shouldHideOptions}
                isControllable
                options={options}
                values={values}
                onChange={(values) => onChange(isMulti ? values : values[0])}
                isInvalid={!!error}
              />
            </Flex>
          );
        }}
      />
      {!!hintTranslationKey && !error && (
        <FormHelperText>
          {t(`attribute.hint.${hintTranslationKey}`)}
        </FormHelperText>
      )}
      <FormError errorKey={error?.message} />
    </FormControl>
  );
};
