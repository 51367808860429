import { FormField, InputElement } from 'enum';
import { ParameterType } from 'enum/api';
import { GetOptionsArg } from 'types/form';
import { generateOptionsFromSettings } from 'utils';

export const amountOfProfileViewsBeforeUploadPhotoFormFields = [
  {
    type: InputElement.Select,
    name: FormField.CustomerType,
    translationKey: 'customer_type',
    getPlaceholder: () => 'Customer type',
    getOptions: ({ t, settings }: GetOptionsArg) =>
      generateOptionsFromSettings(ParameterType.CustomerType, t, settings)
  },
  {
    type: InputElement.NumberInput,
    name: FormField.CustomersAmount,
    translationKey: 'customers',
    getPlaceholder: () => '',
    min: 0
  }
] as const;

export const getInitialFieldsState = () => ({
  [FormField.CustomerType]: undefined,
  [FormField.CustomersAmount]: undefined
});
