import { PaymentSubscriptionOptionsByUserResponse, Settings } from 'api';
import { FormField, InputElement } from 'enum';
import { ParameterType } from 'enum/api';
import { TFunction } from 'react-i18next';
import { Option } from 'types';
import { GetIsDisabledFnArg, GetOptionsArg } from 'types/form';
import { generateOptionsFromSettings, getTranslationByOptionKey } from 'utils';
import * as yup from 'yup';

import { CreateNewTransactionFormData } from './CreateNewTransaction.types';

export const generateProgramTypeOptions = ({
  options,
  t,
  settings
}: {
  options: PaymentSubscriptionOptionsByUserResponse;
  t: TFunction;
  settings: Settings;
}): Option[] =>
  Object.keys(options).map((programType) => ({
    value: programType,
    label: getTranslationByOptionKey({
      t,
      settings,
      optionKey: ParameterType.ProgramType,
      value: programType
    })
  }));

export const generateSubscriptionTypeNameOptions = ({
  options,
  programType,
  t
}: {
  options: PaymentSubscriptionOptionsByUserResponse;
  t: TFunction;
  programType?: string;
}): Option[] => {
  if (!programType || !options[programType]) return [];

  return options[programType].map((typeName) => ({
    label: typeName,
    value: typeName
  }));
};

export const getCreateNewTransactionFormSchema = (
  options: PaymentSubscriptionOptionsByUserResponse
) =>
  ({
    fields: {
      [FormField.Id]: {
        type: InputElement.Input,
        translationKey: 'user',
        getPlaceholder: () => '',
        getIsHidden: () => true
      },
      [FormField.SubscriptionProgramType]: {
        type: InputElement.Select,
        translationKey: 'program_type',
        getPlaceholder: () => 'Select',
        getOptions: ({ t, settings }: GetOptionsArg) =>
          generateProgramTypeOptions({ options, t, settings }),
        getIsDisabled: ({ formValues }: GetIsDisabledFnArg) =>
          !formValues[FormField.Id]
      },
      [FormField.SubscriptionTypeName]: {
        type: InputElement.Select,
        translationKey: 'type_name',
        getPlaceholder: () => 'Type name',
        getOptions: ({ t, getFormValues }: GetOptionsArg) =>
          generateSubscriptionTypeNameOptions({
            options,
            t,
            programType: getFormValues(FormField.SubscriptionProgramType)
          }),
        getIsDisabled: ({ formValues }: GetIsDisabledFnArg) =>
          !formValues[FormField.SubscriptionProgramType]
      },
      [FormField.SumPaid]: {
        type: InputElement.NumberInput,
        translationKey: 'price',
        getPlaceholder: () => '',
        getIsDisabled: ({ formValues }: GetIsDisabledFnArg) =>
          !formValues[FormField.SubscriptionTypeName],
        min: 0,
        precision: 2
      },
      [FormField.Currency]: {
        type: InputElement.Select,
        translationKey: 'currency',
        getPlaceholder: () => 'Select',
        getOptions: ({ t, settings }: GetOptionsArg) =>
          generateOptionsFromSettings(ParameterType.Currency, t, settings),
        getIsDisabled: ({ formValues }: GetIsDisabledFnArg) =>
          !formValues[FormField.SubscriptionTypeName]
      },
      [FormField.Comment]: {
        type: InputElement.TextArea,
        translationKey: 'transaction_comment',
        getPlaceholder: () => 'Comment',
        minHeight: 32
      }
    },
    gridLayout: {
      templateAreas: `
        "${FormField.Id} ${FormField.Id}"
        "${FormField.SubscriptionProgramType} ${FormField.SubscriptionProgramType}"
        "${FormField.SubscriptionTypeName} ${FormField.SubscriptionTypeName}"
        "${FormField.SumPaid} ${FormField.Currency}"
        "${FormField.Comment} ${FormField.Comment}"
      `,
      templateColumns: '1fr 1fr',
      rowGap: 6,
      columnGap: 3
    }
  } as const);

export const createNewTransactionValidationSchema: yup.SchemaOf<CreateNewTransactionFormData> =
  yup.object({
    [FormField.Id]: yup.string().required(),
    [FormField.SubscriptionProgramType]: yup.string().required(),
    [FormField.SubscriptionTypeName]: yup.string().required(),
    [FormField.SumPaid]: yup.number().min(0, 'positive_value').required(),
    [FormField.Currency]: yup.string().required(),
    [FormField.Comment]: yup.string().defined()
  });
