import { Button, Grid, GridItem, HStack } from '@chakra-ui/react';
import { DownloadIcon, Search, Table } from 'components';
import { ContentContainer } from 'containers';
import {
  useAppSelector,
  useChakraToast,
  useLogActivityDownloadCSVFile,
  useLogActivityList,
  useTableQueryController
} from 'hooks';
import { useTranslation } from 'react-i18next';
import { selectAuthorizedUser } from 'store';

import { logActivityColumns } from './LogActivity.utils';

export const LogActivity = (): JSX.Element => {
  const [t] = useTranslation();
  const toast = useChakraToast();

  const currentAdmin = useAppSelector(selectAuthorizedUser);

  const {
    queryFilters,
    action: { onChangeSort, onChangePagination }
  } = useTableQueryController();

  const { data } = useLogActivityList(queryFilters);

  const { mutate, isLoading } = useLogActivityDownloadCSVFile();

  const handleDownloadExcelFile = () => {
    mutate(undefined, {
      onSuccess(data) {
        window.open(data);
      },
      onError() {
        toast({
          status: 'error',
          title: t('errors.error_while_create_csv')
        });
      }
    });
  };

  return (
    <ContentContainer height="100%">
      <Grid
        templateAreas={`"search actions"
                        "table table"
       `}
        templateColumns="26.25rem 1fr"
        templateRows="min-content 1fr"
        rowGap={6}
        height="100%"
      >
        <GridItem area="search">
          <Search options={[]} onChange={() => {}} />
        </GridItem>
        <GridItem area="actions" justifySelf="flex-end">
          <HStack spacing={5}>
            <Button
              variant="link"
              leftIcon={<DownloadIcon boxSize="0.6875rem" />}
              iconSpacing="0.6875rem"
              onClick={handleDownloadExcelFile}
              isLoading={isLoading}
            >
              {t('actions.download_csv_file')}
            </Button>
          </HStack>
        </GridItem>
        {data && (
          <GridItem area="table" overflow="hidden">
            <Table
              data={data.data}
              columns={logActivityColumns}
              onChangeSort={onChangeSort}
              onChangePagination={onChangePagination}
              pagination={queryFilters.pagination}
              totalItems={data.totalItems}
              meta={{
                user: {
                  id: currentAdmin.id,
                  nickname: currentAdmin.userName,
                  email: '',
                  avatar: null
                }
              }}
            />
          </GridItem>
        )}
      </Grid>
    </ContentContainer>
  );
};
