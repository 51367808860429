import { HStack, Heading, Button, Flex } from '@chakra-ui/react';
import { Access, FormConstructor } from 'containers';
import { ModalType, PermissionAction, PermissionResource } from 'enum';
import { useChakraToast, useCrmDownloadCSV, useAppDispatch } from 'hooks';
import { FC } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { openModal } from 'store';

import { CRMFormData } from './CRM.types';
import { CRMFormSchema, serializeRequestData } from './CRM.utils';

export const CRM: FC = () => {
  const [t] = useTranslation();
  const dispatch = useAppDispatch();
  const toast = useChakraToast();

  const form = useForm<CRMFormData>();

  const {
    formState: { isDirty }
  } = form;

  const { mutate: onDownloadCSV, isLoading: isDownloadCSVLoading } =
    useCrmDownloadCSV();

  return (
    <Flex
      flexDirection="column"
      overflowY="auto"
      height="full"
      position="relative"
      paddingBottom={16}
    >
      <FormProvider {...form}>
        <Flex width="60rem" alignItems="stretch">
          <FormConstructor formSchema={CRMFormSchema} />
        </Flex>
      </FormProvider>
      <Heading as="h2" size="xl" marginBottom={6} marginTop={10}>
        {t('attribute.title.actions')}
      </Heading>
      <HStack spacing={4}>
        <Button
          isDisabled={!isDirty}
          isLoading={isDownloadCSVLoading}
          variant="outline"
          onClick={() =>
            onDownloadCSV(serializeRequestData(form.getValues()), {
              onSuccess(data) {
                window.open(data);
              },
              onError: () => {
                toast({
                  status: 'error',
                  title: t('errors.error_while_create_csv')
                });
              }
            })
          }
        >
          {t('actions.download_csv_file')}
        </Button>
        <Button
          isDisabled={!isDirty}
          variant="outline"
          onClick={() =>
            dispatch(
              openModal({
                id: ModalType.SendEmail,
                meta: serializeRequestData(form.getValues())
              })
            )
          }
        >
          {t('actions.send_email')}
        </Button>
        <Button
          isDisabled={!isDirty}
          variant="outline"
          onClick={() =>
            dispatch(
              openModal({
                id: ModalType.SendSMS,
                meta: serializeRequestData(form.getValues())
              })
            )
          }
        >
          {t('actions.send_sms')}
        </Button>
        <Access
          restrictions={{
            resource: PermissionResource.CRM,
            actions: PermissionAction.Delete
          }}
          noAccessRender={null}
        >
          <Button
            isDisabled={!isDirty}
            variant="outline"
            onClick={() =>
              dispatch(
                openModal({
                  id: ModalType.DeleteCrmUsers,
                  meta: serializeRequestData(form.getValues())
                })
              )
            }
          >
            {t('actions.delete_users')}
          </Button>
        </Access>
        <Access
          restrictions={{
            resource: PermissionResource.CRM,
            actions: PermissionAction.Update
          }}
          noAccessRender={null}
        >
          <Button
            isDisabled={!isDirty}
            variant="outline"
            onClick={() =>
              dispatch(
                openModal({
                  id: ModalType.BlockCrmUsers,
                  meta: serializeRequestData(form.getValues())
                })
              )
            }
          >
            {t('actions.block_users')}
          </Button>
        </Access>
      </HStack>
    </Flex>
  );
};
