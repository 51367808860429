import { ENDPOINTS, UserSupportQuickReply } from 'api';
import axios from 'axios';

export const fetchGetQuickReplies = async () => {
  const { data } = await axios.get<UserSupportQuickReply[]>(
    ENDPOINTS.CONTACT_US_QUICK_REPLY_LIST
  );

  return data;
};
