import { Access } from 'containers';
import { PermissionAction } from 'enum/api';
import { usePermissions } from 'hooks';
import kebabCase from 'lodash/kebabCase';
import {
  PAYMENT_PAGE,
  PAYMENT_PAGE_LIST
} from 'pages/PaymentManagement/PaymentManagement.utils';
import { Navigate, RouteObject } from 'react-router-dom';

const NavigateFirstAvailablePage = (): JSX.Element => {
  const { checkPermissions } = usePermissions();

  const firstAvailablePage =
    PAYMENT_PAGE_LIST.find((page) =>
      checkPermissions({
        resource: PAYMENT_PAGE[page].resource,
        actions: PermissionAction.Read
      })
    ) || PAYMENT_PAGE_LIST[0];

  return <Navigate to={kebabCase(firstAvailablePage)} replace />;
};

export const PAYMENT_ROUTES: RouteObject[] = PAYMENT_PAGE_LIST.map<RouteObject>(
  (page) => {
    const { content: Content, resource } = PAYMENT_PAGE[page];

    return {
      path: kebabCase(page),
      element: (
        <Access
          restrictions={{
            resource,
            actions: PermissionAction.Read
          }}
        >
          <Content />
        </Access>
      )
    };
  }
).concat({
  index: true,
  element: <NavigateFirstAvailablePage />
});
