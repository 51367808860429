import { Button, VStack } from '@chakra-ui/react';
import { ModalType } from 'enum';
import { useAppDispatch } from 'hooks';
import { useTranslation } from 'react-i18next';
import { openModal } from 'store';

import { IStaticPagesTableResponse } from '../types';

type Props = {
  staticPage: IStaticPagesTableResponse;
};

export const ActionCell = ({ staticPage }: Props): JSX.Element => {
  const [t] = useTranslation();
  const dispatch = useAppDispatch();

  const onDeleteStaticPage = () => {
    dispatch(
      openModal({
        id: ModalType.DeleteStaticPage,
        meta: {
          staticPageId: staticPage.id
        }
      })
    );
  };

  const onEditStaticPage = () => {
    dispatch(
      openModal({
        id: ModalType.EditStaticPage,
        meta: {
          staticPage
        }
      })
    );
  };

  return (
    <VStack>
      <Button variant="link" onClick={onEditStaticPage}>
        {t('actions.edit')}
      </Button>
      <Button variant="link" colorScheme="red" onClick={onDeleteStaticPage}>
        {t('actions.delete')}
      </Button>
    </VStack>
  );
};
