import { ICustomerComment } from 'types/customer';

import { CustomerCommentsResponse } from '../Customers.types';

export const serializeCustomerCommentsData = (
  response: CustomerCommentsResponse[]
): ICustomerComment[] =>
  response.map((data) => {
    const {
      id,
      commentedBy,
      commentedTo,
      photo,
      updatedAt,
      commentedAt,
      text: comment
    } = data;

    const { nickname = '', id: commentedId = '' } =
      commentedBy || commentedTo || {};

    return {
      id,
      nickname,
      photoUrl: photo.imageUrl,
      commentedAt: updatedAt || commentedAt,
      comment,
      commentedId
    };
  });
