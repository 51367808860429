import { Grid, GridItem, HStack } from '@chakra-ui/react';
import { AddButton, Search, Table } from 'components';
import { ContentContainer, TableFilters } from 'containers';
import { ModalType } from 'enum';
import { SupportTicketStatus } from 'enum/api';
import {
  useAdminList,
  useAppDispatch,
  useTableQueryController,
  useTicketList
} from 'hooks';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { openModal } from 'store';
import { NO_ASSIGNEE } from 'utils';

import {
  contactUsColumns,
  generateAdminListOptions,
  contactUsTableFilters,
  getSearchOptions
} from './ContactUs.utils';

export const ContactUs = (): JSX.Element => {
  const [t] = useTranslation();
  const dispatch = useAppDispatch();

  const {
    queryFilters,
    action: {
      onChangeSort,
      onChangePagination,
      onChangeFilters,
      onChangeSearch
    }
  } = useTableQueryController();
  const { queryFilters: allListFilters } = useTableQueryController({
    defaultValues: {
      pagination: {
        page: 1,
        limit: 150
      }
    }
  });

  const { data } = useTicketList(queryFilters);

  const { data: { data: adminList = [] } = {} } = useAdminList(allListFilters);

  const assigneeOptions = useMemo(
    () => [
      {
        label: t('keywords.no_assignee'),
        value: NO_ASSIGNEE
      },
      ...generateAdminListOptions(adminList)
    ],
    [adminList, t]
  );
  const searchOptions = useMemo(() => getSearchOptions(t), [t]);

  const handleCreateTicket = () => {
    dispatch(
      openModal({
        id: ModalType.CreateTicket,
        meta: {}
      })
    );
  };

  const handleCreateEditQuickReply = () => {
    dispatch(
      openModal({
        id: ModalType.QuickReplyCustomer,
        meta: {
          mode: 'edit'
        }
      })
    );
  };

  return (
    <ContentContainer height="100%">
      <Grid
        templateAreas={`"search actions"
                        "filters filters"
                        "table table"
        `}
        templateColumns="26.25rem 1fr"
        templateRows="min-content  min-content 1fr"
        rowGap={6}
        height="100%"
      >
        <GridItem area="search">
          <Search options={searchOptions} onChange={onChangeSearch} />
        </GridItem>
        <GridItem area="actions" justifySelf="flex-end">
          <HStack spacing={4}>
            <AddButton onClick={handleCreateEditQuickReply}>
              {t('actions.create_quick_reply')}
            </AddButton>
            <AddButton onClick={handleCreateTicket}>
              {t('actions.create_ticket')}
            </AddButton>
          </HStack>
        </GridItem>
        <GridItem area="filters">
          <TableFilters
            filters={contactUsTableFilters}
            onChange={onChangeFilters}
          />
        </GridItem>
        {data && (
          <GridItem area="table" overflow="hidden">
            <Table
              data={data.data}
              columns={contactUsColumns}
              onChangeSort={onChangeSort}
              onChangePagination={onChangePagination}
              pagination={queryFilters.pagination}
              totalItems={data.totalItems}
              meta={{ assigneeOptions }}
              getIsSelected={(row) =>
                row.original.status === SupportTicketStatus.None
              }
              hasHorizontalScroll
            />
          </GridItem>
        )}
      </Grid>
    </ContentContainer>
  );
};
