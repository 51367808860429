import { useMutation, useQueryClient } from '@tanstack/react-query';
import { fetchUpdateAdmin } from 'api/AdminManagement/fetchUpdateAdmin';
import { AxiosError } from 'axios';
import { QueryKey } from 'enum/api';
import { AxiosErrorData } from 'types';

export const useUpdateAdmin = () => {
  const queryClient = useQueryClient();

  return useMutation(fetchUpdateAdmin, {
    onSuccess() {
      queryClient.invalidateQueries([QueryKey.Admins]);
    },
    onError(error: AxiosError<AxiosErrorData>) {}
  });
};
