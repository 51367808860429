import { FormField, InputElement, Range, SystemLanguage } from 'enum';
import { OptionKey, ParameterType } from 'enum/api';
import { DefaultValues } from 'react-hook-form';
import { GoogleService } from 'services/google';
import { ICustomer } from 'types/customer';
import { GetIsHiddenFnArg, GetOptionsArg } from 'types/form';
import {
  generateCityOptionsByCountry,
  generateOptionsFromSettings,
  generateStatesOptionsByCountry,
  getIsArrayChanged
} from 'utils';
import * as yup from 'yup';

import { PersonalDetailsFormData } from './PersonalDetails.types';

export const getCustomerPersonalDetailsFormSchema = ({
  canEditPassword
}: {
  canEditPassword: boolean;
}) =>
  ({
    fields: {
      [FormField.UserName]: {
        type: InputElement.Input,
        getPlaceholder: () => 'Username',
        translationKey: 'nickname'
      },
      [FormField.PhoneNumber]: {
        type: InputElement.Input,
        translationKey: 'phone_number',
        getPlaceholder: () => 'Phone number'
      },
      [FormField.CustomerType]: {
        type: InputElement.Select,
        translationKey: 'customer_type',
        getPlaceholder: () => 'Select',
        getOptions: ({ t, settings }: GetOptionsArg) =>
          generateOptionsFromSettings(ParameterType.CustomerType, t, settings)
      },
      [FormField.Country]: {
        type: InputElement.Select,
        translationKey: 'country',
        getIsHidden: () => true,
        getPlaceholder: () => 'Country',
        getOptions: ({ t, settings }: GetOptionsArg) =>
          generateOptionsFromSettings(OptionKey.Country, t, settings)
      },
      [FormField.Gender]: {
        type: InputElement.Select,
        translationKey: 'gender',
        getPlaceholder: () => 'Select',
        getOptions: ({ t, settings }: GetOptionsArg) =>
          generateOptionsFromSettings(ParameterType.Gender, t, settings)
      },
      [FormField.State]: {
        type: InputElement.Select,
        translationKey: 'state',
        getPlaceholder: () => 'State',
        isSearchable: true,
        getIsHidden: () => true,
        getOptions: ({ t, getFormValues }: GetOptionsArg) =>
          generateStatesOptionsByCountry({
            t,
            countryCode: getFormValues(FormField.Country)
          })
      },
      [FormField.City]: {
        type: InputElement.Select,
        translationKey: 'city',
        getPlaceholder: () => 'City',
        isSearchable: true,
        getIsHidden: () => true,
        getOptions: ({ t, getFormValues }: GetOptionsArg) =>
          generateCityOptionsByCountry({
            t,
            countryCode: getFormValues(FormField.Country),
            originCity: getFormValues(FormField.City)
          })
      },
      [FormField.Latitude]: {
        type: InputElement.Select,
        translationKey: 'country',
        getIsHidden: () => true,
        getPlaceholder: () => 'Country',
        getOptions: ({ t, settings }: GetOptionsArg) =>
          generateOptionsFromSettings(OptionKey.Country, t, settings)
      },
      [FormField.Longitude]: {
        type: InputElement.Select,
        translationKey: 'country',
        getIsHidden: () => true,
        getPlaceholder: () => 'Country',
        getOptions: ({ t, settings }: GetOptionsArg) =>
          generateOptionsFromSettings(OptionKey.Country, t, settings)
      },
      [FormField.Location]: {
        type: InputElement.LocationSelect,
        translationKey: 'location',
        getPlaceholder: () => 'Location'
      },
      [FormField.RegisteredCountry]: {
        type: InputElement.Select,
        translationKey: 'registered_country',
        getPlaceholder: () => 'Registered country',
        getOptions: ({ t, settings }: GetOptionsArg) =>
          generateOptionsFromSettings(OptionKey.Country, t, settings),
        isSearchable: true
      },
      [FormField.Email]: {
        type: InputElement.Input,
        translationKey: 'email',
        getPlaceholder: () => 'Email'
      },
      [FormField.LookingForGender]: {
        type: InputElement.Select,
        translationKey: 'looking_for_gender',
        getPlaceholder: () => 'Select',
        getOptions: ({ t, settings }: GetOptionsArg) =>
          generateOptionsFromSettings(ParameterType.LookForGender, t, settings)
      },
      [FormField.Password]: {
        type: InputElement.Password,
        translationKey: 'password',
        getPlaceholder: () => 'Password',
        canResetPassword: true,
        isReadOnly: !canEditPassword
      },
      [FormField.BirthDate]: {
        type: InputElement.Input,
        translationKey: 'birth_date',
        getPlaceholder: () => '',
        inputType: 'date'
      },
      [FormField.Ages]: {
        type: InputElement.Range,
        translationKey: 'ages_looking_for',
        getPlaceholder: () => '',
        minValue: 18,
        maxValue: 100
      },
      [FormField.Label]: {
        type: InputElement.MultiSelect,
        translationKey: 'label',
        getPlaceholder: () => 'Select',
        getOptions: ({ t, settings }: GetOptionsArg) =>
          generateOptionsFromSettings(ParameterType.Label, t, settings)
      },
      [FormField.Comment]: {
        type: InputElement.TextArea,
        translationKey: 'comment',
        getPlaceholder: () => 'Write your comment',
        maxLength: 2500,
        getIsHidden: (arg: GetIsHiddenFnArg) => {
          const { formValues, defaultValues } =
            arg as GetIsHiddenFnArg<PersonalDetailsFormData>;

          return !getIsArrayChanged(
            formValues[FormField.Label],
            defaultValues[FormField.Label] || []
          );
        }
      }
    },
    gridLayout: {
      templateAreas: `
    "${FormField.UserName} ${FormField.PhoneNumber}"
    "${FormField.CustomerType} ${FormField.Location}"
    "${FormField.Email} ${FormField.RegisteredCountry}"
    "${FormField.Password} ${FormField.Gender}"
    "${FormField.BirthDate} ${FormField.LookingForGender}"
    "${FormField.Ages} ."
    "${FormField.Label} ${FormField.Label}"
    "${FormField.Comment} ${FormField.Comment}"
  `,
      gridTemplateColumns: '1fr 1fr',
      rowGap: 6,
      columnGap: 10
    }
  } as const);

export const personalDetailsValidationSchema: yup.SchemaOf<
  Omit<
    PersonalDetailsFormData,
    'city' | 'state' | 'country' | 'latitude' | 'longitude'
  >
> = yup.object().shape(
  {
    [FormField.UserName]: yup.string().required(),
    [FormField.PhoneNumber]: yup.string().ensure(),
    [FormField.CustomerType]: yup.string().required(),
    [FormField.Gender]: yup.string().required(),
    [FormField.Location]: yup
      .object()
      .shape({
        city: yup.string().defined(),
        state: yup.string(),
        country: yup.string().defined()
      })
      .required(),
    [FormField.RegisteredCountry]: yup.string().ensure(),
    [FormField.Email]: yup.string().required().email(),
    [FormField.LookingForGender]: yup.string().required(),
    [FormField.Password]: yup.string().ensure(),
    [FormField.BirthDate]: yup.string().required(),
    [FormField.Ages]: yup
      .object()
      .shape({
        start: yup.string().defined(),
        end: yup.string().defined()
      })
      .defined(),
    [FormField.Label]: yup.array(yup.string().required()).ensure(),
    [FormField.Comment]: yup.string().when(FormField.Comment, {
      is: (value?: string) => typeof value === 'undefined',
      then: yup.string().optional(),
      otherwise: yup.string().required()
    })
  },
  [[FormField.Comment, FormField.Comment]]
);

export const getDefaultFormValues = (
  customer: ICustomer,
  language: SystemLanguage
): DefaultValues<PersonalDetailsFormData> => ({
  [FormField.UserName]: customer.displayedNickname,
  [FormField.PhoneNumber]: customer.bio.phoneNumber || '',
  [FormField.CustomerType]: customer.role.type,
  [FormField.Location]: {
    [FormField.City]: customer.bio.city,
    [FormField.Country]: GoogleService.getCountryNamesByLocaleAndCode(
      language,
      customer.bio.country
    )
  },
  [FormField.RegisteredCountry]: customer.bio.registeredCountry,
  [FormField.Gender]: customer.role.gender,
  [FormField.Email]: customer.email,
  [FormField.LookingForGender]: customer.config.lookFor,
  [FormField.Password]: customer.password.slice(0, 10),
  [FormField.BirthDate]: new Date(customer.bio.birthDate)
    .toISOString()
    .split('T')[0],
  [FormField.Ages]: {
    [Range.Start]: customer.config.lookForAgeMin
      ? String(customer.config.lookForAgeMin)
      : '0',
    [Range.End]: customer.config.lookForAgeMax
      ? String(customer.config.lookForAgeMax)
      : '0'
  },
  [FormField.Label]: customer.system.labels,
  [FormField.Comment]: ''
});
