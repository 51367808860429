import { Pagination } from 'components';
import { Dispatch, SetStateAction, useCallback, useMemo } from 'react';
import { QueryFilters } from 'types';

type Props = {
  totalItems?: number;
  queryFilters: QueryFilters;
  setQueryFilters: Dispatch<SetStateAction<QueryFilters>>;
};

export const useCustomPagination = ({
  totalItems = 1,
  queryFilters,
  setQueryFilters
}: Props): {
  paginationProps: Parameters<typeof Pagination>[0];
} => {
  const pageCount = useMemo(
    () => Math.ceil(totalItems / queryFilters.pagination.limit),
    [queryFilters.pagination.limit, totalItems]
  );

  const scrollToTop = useCallback(() => {
    document.getElementsByClassName('scrollable-container')[0].scrollTop = 0;
  }, []);

  const paginationProps = useMemo(
    () => ({
      pagination: {
        pageIndex: queryFilters.pagination.page - 1,
        pageSize: queryFilters.pagination.limit
      },
      nextPage: () => {
        setQueryFilters((prev) => ({
          ...prev,
          pagination: {
            ...prev.pagination,
            page: prev.pagination.page++
          }
        }));

        scrollToTop();
      },
      prevPage: () => {
        setQueryFilters((prev) => ({
          ...prev,
          pagination: {
            ...prev.pagination,
            page: prev.pagination.page--
          }
        }));

        scrollToTop();
      },
      canNextPage:
        pageCount === -1
          ? true
          : pageCount === 0
          ? false
          : queryFilters.pagination.page < pageCount,
      canPrevPage: queryFilters.pagination.page > 1,
      goToPage: (idx: number) => {
        setQueryFilters((prev) => ({
          ...prev,
          pagination: {
            ...prev.pagination,
            page: idx + 1
          }
        }));

        scrollToTop();
      },
      totalPages: pageCount,
      numberOfItems: totalItems,
      onChangePageSize: (value: number) =>
        setQueryFilters((prev) => ({
          ...prev,
          pagination: {
            ...prev.pagination,
            limit: value
          }
        }))
    }),
    [
      queryFilters.pagination.page,
      queryFilters.pagination.limit,
      pageCount,
      totalItems,
      setQueryFilters,
      scrollToTop
    ]
  );

  return {
    paginationProps
  };
};
