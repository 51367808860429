import { Spinner } from '@chakra-ui/react';
import { Table } from 'components';
import {
  useCustomerIdFromUrl,
  useCustomerNotesByUser,
  useTableQueryController
} from 'hooks';

import { notesColumns } from '../CustomerInteraction.utils';

export const NotesByUser = (): JSX.Element => {
  const {
    queryFilters,
    action: { onChangeSort, onChangePagination }
  } = useTableQueryController();

  const customerId = useCustomerIdFromUrl();

  const { data } = useCustomerNotesByUser(customerId, queryFilters);

  if (!data) return <Spinner />;

  return (
    <Table
      data={data.data}
      columns={notesColumns}
      onChangeSort={onChangeSort}
      onChangePagination={onChangePagination}
      totalItems={data.totalItems}
      pagination={queryFilters.pagination}
    />
  );
};
