import { Button, Grid } from '@chakra-ui/react';
import { ModalType } from 'enum';
import { useAppDispatch } from 'hooks';
import { useTranslation } from 'react-i18next';
import { openModal } from 'store';

type Props = {
  ticketId: string;
};

export const ActionsCell = ({ ticketId }: Props): JSX.Element => {
  const [t] = useTranslation();
  const dispatch = useAppDispatch();

  const getReplyHandler = (isQuick?: boolean) => () => {
    dispatch(
      openModal(
        isQuick
          ? {
              id: ModalType.QuickReplyCustomer,
              meta: {
                ticketId,
                mode: 'use'
              }
            }
          : {
              id: ModalType.ReplyCustomer,
              meta: {
                ticketId
              }
            }
      )
    );
  };

  return (
    <Grid templateColumns="repeat(2, min-content)" columnGap={2}>
      <Button variant="ghostGray" onClick={getReplyHandler()}>
        {t('actions.reply')}
      </Button>
      <Button variant="ghostGray" onClick={getReplyHandler(true)}>
        {t('actions.quick_reply')}
      </Button>
    </Grid>
  );
};
