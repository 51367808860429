import { Grid, GridItem } from '@chakra-ui/react';
import { ScrollableContainer } from 'containers';
import { useOutletContext } from 'react-router-dom';

import { AccountInfo } from './AccountInfo';
import { Details } from './Details';
import { LastAdminComments } from './LastAdminComments';
import { Messages } from './Messages';

const DASHBOARD_SECTIONS = [
  {
    Component: Details,
    area: 'details'
  },
  {
    Component: AccountInfo,
    area: 'account-info'
  },
  {
    Component: Messages,
    area: 'messages'
  },
  {
    Component: LastAdminComments,
    area: 'last-admin-comment'
  }
];

export const CustomerDashboard = (): JSX.Element => {
  const { customer } = useOutletContext();

  return (
    <ScrollableContainer>
      <Grid
        templateAreas={`
          "details account-info"
          "details messages"
          "details last-admin-comment"
          `}
        gridTemplateRows="auto auto auto"
        gridTemplateColumns="16rem auto"
        gap="8"
      >
        {DASHBOARD_SECTIONS.map(({ area, Component }) => (
          <GridItem area={area} key={area}>
            <Component customer={customer} />
          </GridItem>
        ))}
      </Grid>
    </ScrollableContainer>
  );
};
