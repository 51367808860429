import { ParameterType } from 'enum/api';
import { useAppSelector } from 'hooks';
import { useTranslation } from 'react-i18next';
import { selectSettings } from 'store';
import { getTableManagementOptionTranslation } from 'utils';

export const useParameterTypeTranslation = () => {
  const [, { language }] = useTranslation();

  const settings = useAppSelector(selectSettings);

  const getTranslation = (
    key: ParameterType,
    value?: string | null
  ): string => {
    if (!value) return '';

    const option = settings[key].find(({ optionId }) => optionId === value);

    if (!option) return '';

    const { translations, defaultValue } = option;

    return getTableManagementOptionTranslation({
      translations,
      defaultValue,
      language
    });
  };

  const getAllTranslations = (key: ParameterType): Record<string, string> =>
    settings[key].reduce((acc, { optionId, defaultValue, translations }) => {
      acc[optionId] = getTableManagementOptionTranslation({
        translations,
        defaultValue,
        language
      });

      return acc;
    }, {} as Record<string, string>);

  return {
    getTranslation,
    getAllTranslations
  };
};
