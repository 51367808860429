import { ChevronDownIcon, ChevronUpIcon } from '@chakra-ui/icons';
import { IconButton } from '@chakra-ui/react';

export const RightIndicator = ({
  isOpen
}: {
  isOpen: boolean;
}): JSX.Element => (
  <IconButton
    as="span"
    variant="unstyled"
    aria-label="Select icon indicator"
    sx={{
      display: 'inline-flex',
      alignItems: 'center',
      h: 'auto',
      minW: '9',
      fontSize: 'rg',
      color: isOpen ? 'primary.500' : 'gray.500'
    }}
    icon={isOpen ? <ChevronUpIcon /> : <ChevronDownIcon />}
  />
);
