import { FormField } from 'enum';
import { ParameterType } from 'enum/api';
import { useParameterTypeTranslation } from 'hooks';
import { RequiredNotNullable } from 'types';

import {
  GeneralSettingsFormData,
  GeneralSettingsFormField
} from '../../FormContent.types';

export const getLabelsByParameterTypeByIds = (
  type: ParameterType,
  ids: string[] | null | undefined,
  getAllTranslations: ReturnType<
    typeof useParameterTypeTranslation
  >['getAllTranslations']
): string[] => {
  if (!ids) return [];

  const translations = getAllTranslations(type);

  return ids.map((id) => translations[id]);
};

export const getInitialFieldsState = () => ({
  [FormField.CustomerTypes]: [],
  [FormField.OtherCustomerTypes]: [],
  [FormField.Genders]: [],
  [FormField.OtherGenders]: [],
  [FormField.ProgramTypes]: [],
  [FormField.OtherProgramTypes]: [],
  [FormField.Countries]: [],
  [FormField.EverybodyCanMessageToEverybody]: true
});

export const getIsConfigItemsExists = (
  data: GeneralSettingsFormData[GeneralSettingsFormField.RequestChatAccessFromAnotherCountry][number]
): data is RequiredNotNullable<
  GeneralSettingsFormData[GeneralSettingsFormField.RequestChatAccessFromAnotherCountry][number]
> => !data.isEverybodyCanMessageToEverybody;
