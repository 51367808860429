import { FormField, InputElement } from 'enum';

export const rateForAutomaticallyBlockFormFields = [
  {
    type: InputElement.NumberInput,
    name: FormField.MessageAmount,
    translationKey: 'num_of_messages',
    getPlaceholder: () => '',
    min: 0
  },
  {
    type: InputElement.NumberInput,
    name: FormField.Period,
    translationKey: 'period_of_minutes',
    getPlaceholder: () => '',
    min: 0
  },
  {
    type: InputElement.MultiSelect,
    name: FormField.BadWords,
    translationKey: 'word_other',
    getPlaceholder: () => 'Select',
    shouldHideOptions: true,
    isSearchable: true,
    canAdd: true
  }
] as const;
