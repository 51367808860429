import { HStack, Text, VStack } from '@chakra-ui/react';
import { ReactNode } from 'react';

type Props = {
  title: string;
  children: ReactNode;
  actionButton?: ReactNode;
};

export const InfoItem = ({
  title,
  children,
  actionButton = null
}: Props): JSX.Element => (
  <VStack spacing={1} width="100%" alignItems="stretch">
    <HStack spacing={3} justifyContent="space-between">
      <Text textStyle="ss">{title}</Text>
      {actionButton}
    </HStack>
    {children}
  </VStack>
);
