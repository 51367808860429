import { ENDPOINTS, GeneralSettingsResponse } from 'api';
import axios from 'axios';

export const fetchGeneralSettings = async ({
  country
}: {
  country?: string;
}): Promise<GeneralSettingsResponse> => {
  const { data } = await axios.get<GeneralSettingsResponse>(
    `${ENDPOINTS.GENERAL_SETTINGS}/by-country/${country}`
  );

  return data;
};
