import { chakra } from '@chakra-ui/react';
import { GroupBase, InputProps } from 'react-select';

import {
  cleanCommonProps,
  getAttributeProperty,
  useStyles
} from '../LocationDropdown.utils';

export const Input = <
  Option,
  IsMulti extends boolean,
  Group extends GroupBase<Option>
>(
  props: InputProps<Option, IsMulti, Group>
): JSX.Element => {
  const { value } = props;

  const { innerRef, isDisabled, isHidden, ...inputProps } =
    cleanCommonProps(props);

  const styles = useStyles();

  return (
    <chakra.div
      __css={styles.inputContainer}
      {...getAttributeProperty(props)}
      data-value={value || ''}
    >
      <chakra.input
        ref={innerRef}
        __css={styles.input}
        {...inputProps}
        {...getAttributeProperty(props)}
      />
    </chakra.div>
  );
};
