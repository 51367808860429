import { ContentContainer, SubHeader } from 'containers';
import { Fragment } from 'react';
import { Outlet } from 'react-router-dom';

import { PaymentManagementTabs } from './components';

export const PaymentManagement = (): JSX.Element => (
  <Fragment>
    <SubHeader>
      <PaymentManagementTabs />
    </SubHeader>
    <ContentContainer height="100%">
      <Outlet />
    </ContentContainer>
  </Fragment>
);
