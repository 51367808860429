import { HStack } from '@chakra-ui/react';
import {
  ContentContainer,
  ScrollableContainer,
  TableFilters
} from 'containers';
import { useState } from 'react';
import { Outlet } from 'react-router-dom';

import { ContentFilters } from './Content.types';
import { chartFilters, CONTENT_SECTION_LIST } from './Content.utils';
import { DropdownTitle } from './components';

export const Content = (): JSX.Element => {
  const [contentFilters, setContentFilters] = useState<ContentFilters>();

  return (
    <ContentContainer height="100%" display="flex" flexDirection="column">
      <HStack marginBottom={6} justifyContent="space-between">
        <DropdownTitle sections={CONTENT_SECTION_LIST} />
        <TableFilters filters={chartFilters} onChange={setContentFilters} />
      </HStack>
      <ScrollableContainer>
        <Outlet
          context={{
            contentFilters
          }}
        />
      </ScrollableContainer>
    </ContentContainer>
  );
};
