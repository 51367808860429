import { Checkbox, CheckboxGroup, MenuItem } from '@chakra-ui/react';
import { InputElement } from 'enum';
import { GetUnionType } from 'types';

import { ListContainerProps } from '../ButtonDropdown.types';

type Props = GetUnionType<ListContainerProps, { type?: InputElement.Checkbox }>;

export const CheckboxList = ({
  options,
  selectedValues,
  onChange
}: Props): JSX.Element => (
  <CheckboxGroup value={selectedValues} onChange={onChange}>
    {options.map(({ label, value }) => (
      <MenuItem as={Checkbox} key={value} value={value} padding={3}>
        {label}
      </MenuItem>
    ))}
  </CheckboxGroup>
);
