import { useCallback, useRef, useState } from 'react';

const getVideoThumbnail = (videoUrl: string): Promise<string> => {
  const video = document.createElement('video');

  video.preload = 'auto';
  video.muted = true;
  video.currentTime = 0.1;
  video.src = videoUrl;
  video.setAttribute('crossorigin', 'anonymous');

  return new Promise((resolve) => {
    video.addEventListener('loadeddata', () => {
      const canvas = document.createElement('canvas');

      canvas.width = 150;
      canvas.height = 150;

      const ctx = canvas.getContext('2d');

      const isPortraitVideo = video.videoWidth < video.videoHeight;
      const videoWidth = isPortraitVideo
        ? canvas.width
        : Math.round((video.videoWidth / video.videoHeight) * canvas.height);

      const videoHeight = isPortraitVideo
        ? Math.round((video.videoHeight / video.videoWidth) * canvas.width)
        : canvas.height;

      const videoOffsetX = isPortraitVideo
        ? 0
        : -Math.round((videoWidth - canvas.width) / 2);

      const videoOffsetY = isPortraitVideo
        ? -Math.round((videoHeight - canvas.height) / 2)
        : 0;

      if (ctx) {
        ctx.drawImage(
          video,
          videoOffsetX,
          videoOffsetY,
          videoWidth,
          videoHeight
        );
      }

      const imageDataUrl = canvas.toDataURL();

      video.src = '';

      resolve(imageDataUrl);
    });
  });
};

export const useVideoThumbnails = () => {
  const [cache, setCache] = useState<Record<string, string>>({});
  const cached = useRef<Record<string, true>>({});

  const thumbnail = useCallback(
    (videoUrl: string) => {
      if (!cached.current[videoUrl]) {
        getVideoThumbnail(videoUrl)
          .then((dataUrl) =>
            setCache((current) => ({ ...current, [videoUrl]: dataUrl }))
          )
          .catch(() => null);

        cached.current[videoUrl] = true;
      }

      return cache[videoUrl];
    },
    [cache]
  );

  return { thumbnail };
};
