import { ENDPOINTS } from 'api';
import axios from 'axios';

export const fetchCreateAdminComment = async ({
  customerId,
  text,
  image
}: {
  customerId: string;
  text: string;
  image?: File;
}): Promise<void> => {
  const formData = new FormData();

  formData.append('text', text);

  if (image) {
    formData.append('image', image);
  }

  await axios.post(
    `${ENDPOINTS.ADMIN_COMMENTS}/by-user/${customerId}`,
    formData,
    {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    }
  );
};
