import {
  FormControl,
  FormLabel,
  Stack,
  RadioGroup,
  Radio,
  Input
} from '@chakra-ui/react';
import { FormError } from 'components';
import { InputElement, SchedulerFrequency } from 'enum';
import { useEffect, useMemo, useState } from 'react';
import { useController } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import Select from 'react-select';

import { FormComponentProps } from '../FormConstructor.types';

type Props = FormComponentProps<{ type: InputElement.FrequencyScheduler }>;

type DayOption = {
  value: number;
  label: string;
};

type FrequencyOption = {
  value: SchedulerFrequency;
  label: string;
};

const DAYS_OPTIONS: DayOption[] = [
  { value: 0, label: 'Sun' },
  { value: 1, label: 'Mon' },
  { value: 2, label: 'Tue' },
  { value: 3, label: 'Wed' },
  { value: 4, label: 'Thur' },
  { value: 5, label: 'Fri' },
  { value: 6, label: 'Sat' }
];

const FREQUENCY_OPTIONS: FrequencyOption[] = [
  { value: SchedulerFrequency.CurrentWeek, label: 'This week only' },
  { value: SchedulerFrequency.CurrentMonth, label: 'This month only' },
  { value: SchedulerFrequency.CurrentYear, label: 'This year only' },
  { value: SchedulerFrequency.Always, label: 'Always' }
];

export const FormFrequencyScheduler = ({
  translationKey,
  name,
  getPlaceholder,
  meta
}: Props): JSX.Element => {
  const [t] = useTranslation();
  const [weekDays, setWeekDays] = useState<DayOption[]>(
    meta?.additional?.frequencyScheduler?.weekDays?.length
      ? DAYS_OPTIONS.filter(({ value }) =>
          (meta?.additional?.frequencyScheduler?.weekDays || []).includes(value)
        )
      : DAYS_OPTIONS
  );
  const [frequency, setFrequency] = useState<SchedulerFrequency>(
    meta?.additional?.frequencyScheduler?.frequency || SchedulerFrequency.Always
  );

  const {
    field: { onChange },
    fieldState: { error }
  } = useController({
    name
  });

  useEffect(() => {
    onChange({
      weekDays: weekDays?.length ? weekDays.map(({ value }) => value) : null,
      frequency: frequency || null
    });
  }, [weekDays, frequency, onChange]);

  const weekDaysFrequencyStr = useMemo(() => {
    if (!weekDays?.length) return null;

    const processedWeekDays = weekDays
      .sort((a, b) => a.value - b.value)
      .map(({ label }) => label);

    return `${processedWeekDays.join(', ')}: ${
      FREQUENCY_OPTIONS.find(({ value }) => value === frequency)?.label
    }`;
  }, [weekDays, frequency]);

  return (
    <FormControl isInvalid={!!error} paddingLeft="1rem">
      <FormLabel>{t(`keywords.${translationKey}`)}</FormLabel>
      <Stack spacing={2} direction="column">
        {weekDaysFrequencyStr && (
          <Input type="text" value={weekDaysFrequencyStr} isDisabled={true} />
        )}
        <Select
          isMulti
          isSearchable={false}
          value={weekDays}
          //@ts-ignore
          onChange={setWeekDays}
          options={DAYS_OPTIONS}
          placeholder={getPlaceholder(t)}
        />
        <RadioGroup
          //@ts-ignore
          onChange={setFrequency}
          value={frequency}
          alignSelf="flex-start"
        >
          <Stack spacing={4} direction="column" alignItems="flex-start">
            {FREQUENCY_OPTIONS.map((option) => (
              <Radio key={option.value} value={option.value}>
                {option.label}
              </Radio>
            ))}
          </Stack>
        </RadioGroup>
      </Stack>
      <FormError errorKey={error?.message} />
    </FormControl>
  );
};
