import { Button, Grid, GridItem, Spinner, VStack } from '@chakra-ui/react';
import { Search, Table } from 'components';
import { ContentContainer, ScrollableContainer } from 'containers';
import { ModalType } from 'enum';
import {
  useAppDispatch,
  useCustomerConversations,
  useTableQueryController
} from 'hooks';
import size from 'lodash/size';
import { CUSTOMER_PAGE } from 'pages/Customers/CustomerInfo/CustomerInfo.utils';
import { DropdownTitle } from 'pages/Customers/components';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useOutletContext } from 'react-router-dom';
import { useMount } from 'react-use';
import { getCustomerPageNameByUrl } from 'routes/routes.utils';
import { openModal } from 'store';
import { ICustomerConversation } from 'types/customer';

import {
  conversationsColumns,
  getSearchOptions
} from '../CustomerInteraction.utils';
import { Conversation } from '../components';

export const Conversations = (): JSX.Element | null => {
  const [t] = useTranslation();
  const dispatch = useAppDispatch();
  const { pathname } = useLocation();

  const { customer } = useOutletContext();

  const [canViewConversations, setCanViewConversations] = useState(false);
  const [expandedConversations, setExpandedConversations] = useState<
    Record<string, boolean>
  >({});

  const {
    queryFilters,
    action: { onChangeSort, onChangeSearch }
  } = useTableQueryController({
    defaultValues: {
      pagination: {
        page: 1,
        limit: 100
      },
      sort: {
        created: 'desc'
      }
    }
  });

  useMount(() => {
    dispatch(
      openModal({
        id: ModalType.ViewConversations,
        meta: {
          customerId: customer.id,
          onSuccess: () => setCanViewConversations(true)
        }
      })
    );
  });

  const { data } = useCustomerConversations({
    customerId: customer.id,
    queryFilters: {
      ...queryFilters,
      sort: {
        lastMessageAt: queryFilters.sort.created
      }
    },
    enabled: canViewConversations
  });

  const conversations = useMemo(() => {
    if (!data) return [];

    return data.pages.reduce<ICustomerConversation[]>((acc, { data }) => {
      acc.push(...data);

      return acc;
    }, []);
  }, [data]);

  const searchOptions = useMemo(() => getSearchOptions(t), [t]);

  if (!canViewConversations) return null;

  if (!conversations) return <Spinner />;

  const expandedLength = size(expandedConversations);
  const isAllExpanded = expandedLength === conversations.length;

  const expandAllOrLess = () => {
    const expandedObj = isAllExpanded
      ? {}
      : conversations.reduce((acc, cur) => {
          acc[cur.id] = true;

          return acc;
        }, {} as Record<string, boolean>);

    setExpandedConversations(expandedObj);
  };

  const actionButtonLabel = isAllExpanded
    ? t('actions.see_less_for_all', {
        count: expandedLength
      })
    : t('actions.see_more_for_all', {
        count: conversations.length - expandedLength
      });

  const sections = CUSTOMER_PAGE[getCustomerPageNameByUrl(pathname)].sections;

  return (
    <Grid
      templateAreas={`
        "heading"
        "tables"
      `}
      templateColumns="1fr"
      templateRows="auto 1fr"
      rowGap={4}
      height="100%"
    >
      <GridItem area="heading">
        <ContentContainer paddingBottom={0}>
          <Grid
            templateAreas={`
             "title actions"
             "search ."
             "t-head t-head"
            `}
            templateColumns="26.25rem 1fr"
            templateRows="min-content min-content min-content 1fr"
            rowGap={6}
            height="100%"
          >
            <GridItem area="title">
              <DropdownTitle sections={sections} />
            </GridItem>
            <GridItem area="actions" justifySelf="flex-end">
              <Button variant="link" onClick={expandAllOrLess}>
                {actionButtonLabel}
              </Button>
            </GridItem>
            <GridItem area="search">
              <Search options={searchOptions} onChange={onChangeSearch} />
            </GridItem>
            <GridItem area="t-head" overflow="hidden">
              <Table
                data={[]}
                columns={conversationsColumns}
                totalItems={0}
                onChangeSort={onChangeSort}
                shouldHidePagination
                shouldHideErrorMessage
                tableOptions={{
                  enableSortingRemoval: false
                }}
                defaultSortBy={{
                  created: 'desc'
                }}
              />
            </GridItem>
          </Grid>
        </ContentContainer>
      </GridItem>
      <ScrollableContainer>
        <GridItem area="tables" height="100%">
          <VStack spacing={4} alignItems="stretch" height="100%">
            {conversations.map((conversation) => (
              <Conversation
                key={conversation.id}
                conversation={conversation}
                expandedConversations={expandedConversations}
                setExpandedConversations={setExpandedConversations}
                customerId={customer.id}
              />
            ))}
          </VStack>
        </GridItem>
      </ScrollableContainer>
    </Grid>
  );
};
