import { createSlice } from '@reduxjs/toolkit';
import { Settings } from 'api';

import { setSettings } from './actions';

const getInitialState = (): Settings | null => null;

export const settingsSlice = createSlice({
  name: 'options',
  initialState: getInitialState(),
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(setSettings, (_, action) => action.payload);
  }
});

export const { reducer: settingsReducer } = settingsSlice;
