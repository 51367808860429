import { Button, Wrap } from '@chakra-ui/react';
import snakeCase from 'lodash/snakeCase';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  CUSTOMER_PAGE_LINK_BY_PAGE,
  getCustomerPageNameByUrl
} from 'routes/routes.utils';

import { CUSTOMER_PAGE, CUSTOMER_PAGE_LIST } from './CustomerInfo.utils';

export const CustomerTabs = (): JSX.Element => {
  const [t] = useTranslation();
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const selectedTab = getCustomerPageNameByUrl(pathname);

  return (
    <Wrap shouldWrapChildren spacing={3}>
      {CUSTOMER_PAGE_LIST.map((tab) => (
        <Button
          key={tab}
          variant="ghost"
          colorScheme="gray"
          aria-selected={tab === selectedTab}
          isDisabled={!CUSTOMER_PAGE[tab]}
          onClick={() => {
            navigate(CUSTOMER_PAGE_LINK_BY_PAGE[tab]);
          }}
        >
          {t(`keywords.${snakeCase(tab)}`)}
        </Button>
      ))}
    </Wrap>
  );
};
