import { useMutation, useQueryClient } from '@tanstack/react-query';
import { fetchCreateLinkedAccount } from 'api';
import { AxiosError } from 'axios';
import { QueryKey } from 'enum/api';
import { AxiosErrorData } from 'types';

export const useCreateLinkedAccount = () => {
  const queryClient = useQueryClient();

  return useMutation(fetchCreateLinkedAccount, {
    onSuccess(_, { customerId }) {
      queryClient.invalidateQueries([
        QueryKey.CustomerLinkedAccounts,
        customerId
      ]);
    },
    onError(error: AxiosError<AxiosErrorData>) {}
  });
};
