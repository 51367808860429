import {
  Box,
  Text,
  Textarea as ChakraTextarea,
  TextareaProps,
  useControllableState,
  useMergeRefs
} from '@chakra-ui/react';
import { forwardRef, useEffect, useRef } from 'react';

type Props = Omit<TextareaProps, 'value' | 'onChange'> & {
  value?: string;
  onChange?: (value: string) => void;
  shouldSizeByContent?: boolean;
};

export const TextArea = forwardRef<HTMLTextAreaElement, Props>(
  (props: Props, extRef): JSX.Element => {
    const { value, onChange, shouldSizeByContent = false, ...rest } = props;

    const ref = useRef<HTMLTextAreaElement>(null);
    const mergedRef = useMergeRefs(ref, extRef);

    const [text, setText] = useControllableState({
      value,
      onChange
    });

    useEffect(() => {
      if (ref?.current && shouldSizeByContent) {
        const element = ref?.current;
        element.style.height = element.scrollHeight + 'px';

        const handleInput = () => {
          element.style.height = 'auto';
          element.style.height = element.scrollHeight + 'px';
        };

        element.addEventListener('input', handleInput);

        return element.removeEventListener('input', handleInput);
      }
    }, [shouldSizeByContent]);

    const shouldShowCounter = !!props.maxLength;

    return (
      <Box position="relative">
        <ChakraTextarea
          {...rest}
          ref={mergedRef}
          value={text}
          onChange={(e) => setText(e.target.value)}
          paddingBottom={shouldShowCounter ? 8 : undefined}
          overflowY={shouldSizeByContent ? 'hidden' : 'auto'}
        />
        {shouldShowCounter && (
          <Text
            sx={{
              position: 'absolute',
              bottom: 3,
              right: 3,
              color: 'subtext'
            }}
          >{`${text?.length || 0} / ${props.maxLength}`}</Text>
        )}
      </Box>
    );
  }
);
