import { Icon, IconProps } from '@chakra-ui/react';

export const CalendarIcon = (props: IconProps): JSX.Element => (
  <Icon viewBox="0 0 20 22" stroke="gray.500" fill="none" {...props}>
    <rect x="1" y="2.5" width="18" height="18" rx="5" strokeWidth="1.5" />
    <path d="M1 7.5H19" strokeWidth="1.5" strokeLinejoin="round" />
    <path
      d="M14.5 1L14.5 4"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M5.5 1L5.5 4"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M4.5 11.5H5.5"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M9.5 11.5H10.5"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M14.5 11.5H15.5"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M4.5 15.5H5.5"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M9.5 15.5H10.5"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M14.5 15.5H15.5"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </Icon>
);
