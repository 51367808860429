import { CreateDomainConfigRequestData, DomainConfigListResponse } from 'api';
import {
  FormField,
  InputElement,
  LanguageRelatedDisplayName,
  LanguageRelatedFileName,
  SystemLanguage,
  WelcomePageAlias
} from 'enum';
import { getAllEnumValues } from 'enum-for';
import { OptionKey } from 'enum/api';
import identity from 'lodash/identity';
import pickBy from 'lodash/pickBy';
import { DefaultValues } from 'react-hook-form';
import { RequiredNotNullable } from 'types';
import { GetIsHiddenFnArg, GetOptionsArg } from 'types/form';
import { generateOptionsFromSettings } from 'utils';
import * as yup from 'yup';

import { EditDomainConfigFormData } from './EditDomainConfig.types';

export const getEditDomainConfigFormSchema = ({
  isDefaultConfig = false
}: {
  isDefaultConfig: boolean;
}) =>
  ({
    fields: {
      [FormField.Title]: {
        type: InputElement.Input,
        translationKey: 'title',
        getPlaceholder: () => 'Title',
        maxLength: 150,
        getIsDisabled: () => isDefaultConfig
      },
      [FormField.Domain]: {
        type: InputElement.Input,
        translationKey: 'domain',
        hintTranslationKey: 'domain_input',
        getPlaceholder: () => 'Domain',
        maxLength: 100,
        getIsDisabled: () => isDefaultConfig
      },
      [FormField.SubDir]: {
        type: InputElement.Input,
        translationKey: 'sub_dir',
        getPlaceholder: () => '/turkey',
        maxLength: 50,
        getIsDisabled: () => isDefaultConfig
      },
      [FormField.WelcomePageAlias]: {
        type: InputElement.Select,
        translationKey: 'welcome_page_alias',
        getPlaceholder: () => 'Welcome Page Alias',
        getOptions: ({ t, settings }: GetOptionsArg) =>
          generateOptionsFromSettings(OptionKey.WelcomePageAlias, t, settings),
        getIsDisabled: () => isDefaultConfig
      },
      [FormField.GoogleTagMKey]: {
        type: InputElement.Input,
        translationKey: 'google_t_m_key',
        getPlaceholder: () => 'M-...',
        maxLength: 100,
        getIsDisabled: () => isDefaultConfig
      },
      [FormField.GoogleTagGKey]: {
        type: InputElement.Input,
        translationKey: 'google_t_g_key',
        getPlaceholder: () => 'G-...',
        maxLength: 100,
        getIsDisabled: () => isDefaultConfig
      },
      [FormField.GoogleTagAwKey]: {
        type: InputElement.Input,
        translationKey: 'google_t_aw_key',
        getPlaceholder: () => 'AW-...',
        maxLength: 100,
        getIsDisabled: () => isDefaultConfig
      },
      [FormField.Language]: {
        type: InputElement.Select,
        translationKey: 'default_language',
        getPlaceholder: () => 'Language',
        getOptions: ({ t, settings }: GetOptionsArg) =>
          generateOptionsFromSettings(OptionKey.SystemLanguage, t, settings),
        getIsDisabled: () => isDefaultConfig
      },
      [FormField.AdditionalFields]: {
        type: InputElement.TextArea,
        translationKey: 'additional_fields',
        getPlaceholder: () => `JSON object like:
        {
          "shouldHideOtherGenders": true,
          "shouldShowReferralCode": true  
        }  
      `,
        minHeight: 44,
        getIsDisabled: () => isDefaultConfig
      },
      [FormField.Assets]: {
        type: InputElement.TextArea,
        translationKey: 'assets',
        getPlaceholder: () => `JSON object like:
        {
          "welcome": {
            "top": "africa.webp",
            "world": {
                "1": "africa.webp",
                "2": "africa.webp",
            ...
            }  
          }  
        }    
      `,
        minHeight: 44,
        getIsDisabled: () => isDefaultConfig
      },
      [FormField.AvailableTranslations]: {
        type: InputElement.MultiSelect,
        translationKey: 'available_translations',
        getPlaceholder: () => 'Available Translations',
        getOptions: ({ t, settings }: GetOptionsArg) =>
          generateOptionsFromSettings(OptionKey.SystemLanguage, t, settings),
        getIsDisabled: () => isDefaultConfig
      },
      [FormField.TranslationEnDisplayName]: {
        type: InputElement.Input,
        translationKey: 'translation_display_name',
        getPlaceholder: () => 'English',
        maxLength: 20,
        getIsHidden: (arg: GetIsHiddenFnArg) => {
          const { formValues } =
            arg as GetIsHiddenFnArg<EditDomainConfigFormData>;

          return !formValues[FormField.AvailableTranslations]?.includes(
            SystemLanguage.EN
          );
        },
        getIsDisabled: () => isDefaultConfig
      },
      [FormField.TranslationEnFileName]: {
        type: InputElement.Input,
        translationKey: 'translation_file_name',
        getPlaceholder: () => 'en.json',
        maxLength: 20,
        getIsHidden: (arg: GetIsHiddenFnArg) => {
          const { formValues } =
            arg as GetIsHiddenFnArg<EditDomainConfigFormData>;

          return !formValues[FormField.AvailableTranslations]?.includes(
            SystemLanguage.EN
          );
        },
        getIsDisabled: () => isDefaultConfig
      },
      [FormField.TranslationRuDisplayName]: {
        type: InputElement.Input,
        translationKey: 'translation_display_name',
        getPlaceholder: () => 'Русский',
        maxLength: 20,
        getIsHidden: (arg: GetIsHiddenFnArg) => {
          const { formValues } =
            arg as GetIsHiddenFnArg<EditDomainConfigFormData>;

          return !formValues[FormField.AvailableTranslations]?.includes(
            SystemLanguage.RU
          );
        },
        getIsDisabled: () => isDefaultConfig
      },
      [FormField.TranslationRuFileName]: {
        type: InputElement.Input,
        translationKey: 'translation_file_name',
        getPlaceholder: () => 'ru.json',
        maxLength: 20,
        getIsHidden: (arg: GetIsHiddenFnArg) => {
          const { formValues } =
            arg as GetIsHiddenFnArg<EditDomainConfigFormData>;

          return !formValues[FormField.AvailableTranslations]?.includes(
            SystemLanguage.RU
          );
        },
        getIsDisabled: () => isDefaultConfig
      },
      [FormField.TranslationTrDisplayName]: {
        type: InputElement.Input,
        translationKey: 'translation_display_name',
        getPlaceholder: () => 'Türkçe',
        maxLength: 20,
        getIsHidden: (arg: GetIsHiddenFnArg) => {
          const { formValues } =
            arg as GetIsHiddenFnArg<EditDomainConfigFormData>;

          return !formValues[FormField.AvailableTranslations]?.includes(
            SystemLanguage.TR
          );
        },
        getIsDisabled: () => isDefaultConfig
      },
      [FormField.TranslationTrFileName]: {
        type: InputElement.Input,
        translationKey: 'translation_file_name',
        getPlaceholder: () => 'tr.json',
        maxLength: 20,
        getIsHidden: (arg: GetIsHiddenFnArg) => {
          const { formValues } =
            arg as GetIsHiddenFnArg<EditDomainConfigFormData>;

          return !formValues[FormField.AvailableTranslations]?.includes(
            SystemLanguage.TR
          );
        },
        getIsDisabled: () => isDefaultConfig
      },
      [FormField.TranslationHeDisplayName]: {
        type: InputElement.Input,
        translationKey: 'translation_display_name',
        getPlaceholder: () => 'עברית',
        maxLength: 20,
        getIsHidden: (arg: GetIsHiddenFnArg) => {
          const { formValues } =
            arg as GetIsHiddenFnArg<EditDomainConfigFormData>;

          return !formValues[FormField.AvailableTranslations]?.includes(
            SystemLanguage.HE
          );
        },
        getIsDisabled: () => isDefaultConfig
      },
      [FormField.TranslationHeFileName]: {
        type: InputElement.Input,
        translationKey: 'translation_file_name',
        getPlaceholder: () => 'he.json',
        maxLength: 20,
        getIsHidden: (arg: GetIsHiddenFnArg) => {
          const { formValues } =
            arg as GetIsHiddenFnArg<EditDomainConfigFormData>;

          return !formValues[FormField.AvailableTranslations]?.includes(
            SystemLanguage.HE
          );
        },
        getIsDisabled: () => isDefaultConfig
      },
      [FormField.TranslationDeDisplayName]: {
        type: InputElement.Input,
        translationKey: 'translation_display_name',
        getPlaceholder: () => 'Deutsch',
        maxLength: 20,
        getIsHidden: (arg: GetIsHiddenFnArg) => {
          const { formValues } =
            arg as GetIsHiddenFnArg<EditDomainConfigFormData>;

          return !formValues[FormField.AvailableTranslations]?.includes(
            SystemLanguage.DE
          );
        }
      },
      [FormField.TranslationDeFileName]: {
        type: InputElement.Input,
        translationKey: 'translation_file_name',
        getPlaceholder: () => 'de.json',
        maxLength: 20,
        getIsHidden: (arg: GetIsHiddenFnArg) => {
          const { formValues } =
            arg as GetIsHiddenFnArg<EditDomainConfigFormData>;

          return !formValues[FormField.AvailableTranslations]?.includes(
            SystemLanguage.DE
          );
        }
      },
      [FormField.TranslationFrDisplayName]: {
        type: InputElement.Input,
        translationKey: 'translation_display_name',
        getPlaceholder: () => 'French',
        maxLength: 20,
        getIsHidden: (arg: GetIsHiddenFnArg) => {
          const { formValues } =
            arg as GetIsHiddenFnArg<EditDomainConfigFormData>;

          return !formValues[FormField.AvailableTranslations]?.includes(
            SystemLanguage.FR
          );
        }
      },
      [FormField.TranslationFrFileName]: {
        type: InputElement.Input,
        translationKey: 'translation_file_name',
        getPlaceholder: () => 'fr.json',
        maxLength: 20,
        getIsHidden: (arg: GetIsHiddenFnArg) => {
          const { formValues } =
            arg as GetIsHiddenFnArg<EditDomainConfigFormData>;

          return !formValues[FormField.AvailableTranslations]?.includes(
            SystemLanguage.FR
          );
        }
      },
      [FormField.TranslationEsDisplayName]: {
        type: InputElement.Input,
        translationKey: 'translation_display_name',
        getPlaceholder: () => 'Spanish',
        maxLength: 20,
        getIsHidden: (arg: GetIsHiddenFnArg) => {
          const { formValues } =
            arg as GetIsHiddenFnArg<EditDomainConfigFormData>;

          return !formValues[FormField.AvailableTranslations]?.includes(
            SystemLanguage.ES
          );
        }
      },
      [FormField.TranslationEsFileName]: {
        type: InputElement.Input,
        translationKey: 'translation_file_name',
        getPlaceholder: () => 'es.json',
        maxLength: 20,
        getIsHidden: (arg: GetIsHiddenFnArg) => {
          const { formValues } =
            arg as GetIsHiddenFnArg<EditDomainConfigFormData>;

          return !formValues[FormField.AvailableTranslations]?.includes(
            SystemLanguage.ES
          );
        }
      },
      [FormField.TranslationItDisplayName]: {
        type: InputElement.Input,
        translationKey: 'translation_display_name',
        getPlaceholder: () => 'Italian',
        maxLength: 20,
        getIsHidden: (arg: GetIsHiddenFnArg) => {
          const { formValues } =
            arg as GetIsHiddenFnArg<EditDomainConfigFormData>;

          return !formValues[FormField.AvailableTranslations]?.includes(
            SystemLanguage.IT
          );
        }
      },
      [FormField.TranslationItFileName]: {
        type: InputElement.Input,
        translationKey: 'translation_file_name',
        getPlaceholder: () => 'it.json',
        maxLength: 20,
        getIsHidden: (arg: GetIsHiddenFnArg) => {
          const { formValues } =
            arg as GetIsHiddenFnArg<EditDomainConfigFormData>;

          return !formValues[FormField.AvailableTranslations]?.includes(
            SystemLanguage.IT
          );
        }
      },
      [FormField.TranslationElDisplayName]: {
        type: InputElement.Input,
        translationKey: 'translation_display_name',
        getPlaceholder: () => 'Greek',
        maxLength: 20,
        getIsHidden: (arg: GetIsHiddenFnArg) => {
          const { formValues } =
            arg as GetIsHiddenFnArg<EditDomainConfigFormData>;

          return !formValues[FormField.AvailableTranslations]?.includes(
            SystemLanguage.EL
          );
        }
      },
      [FormField.TranslationElFileName]: {
        type: InputElement.Input,
        translationKey: 'translation_file_name',
        getPlaceholder: () => 'el.json',
        maxLength: 20,
        getIsHidden: (arg: GetIsHiddenFnArg) => {
          const { formValues } =
            arg as GetIsHiddenFnArg<EditDomainConfigFormData>;

          return !formValues[FormField.AvailableTranslations]?.includes(
            SystemLanguage.EL
          );
        }
      },
      [FormField.TranslationUkDisplayName]: {
        type: InputElement.Input,
        translationKey: 'translation_display_name',
        getPlaceholder: () => 'Ukraine',
        maxLength: 20,
        getIsHidden: (arg: GetIsHiddenFnArg) => {
          const { formValues } =
            arg as GetIsHiddenFnArg<EditDomainConfigFormData>;

          return !formValues[FormField.AvailableTranslations]?.includes(
            SystemLanguage.UK
          );
        }
      },
      [FormField.TranslationUkFileName]: {
        type: InputElement.Input,
        translationKey: 'translation_file_name',
        getPlaceholder: () => 'uk.json',
        maxLength: 20,
        getIsHidden: (arg: GetIsHiddenFnArg) => {
          const { formValues } =
            arg as GetIsHiddenFnArg<EditDomainConfigFormData>;

          return !formValues[FormField.AvailableTranslations]?.includes(
            SystemLanguage.UK
          );
        }
      },
      [FormField.TranslationKaDisplayName]: {
        type: InputElement.Input,
        translationKey: 'translation_display_name',
        getPlaceholder: () => 'Georgian',
        maxLength: 20,
        getIsHidden: (arg: GetIsHiddenFnArg) => {
          const { formValues } =
            arg as GetIsHiddenFnArg<EditDomainConfigFormData>;

          return !formValues[FormField.AvailableTranslations]?.includes(
            SystemLanguage.KA
          );
        }
      },
      [FormField.TranslationKaFileName]: {
        type: InputElement.Input,
        translationKey: 'translation_file_name',
        getPlaceholder: () => 'ka.json',
        maxLength: 20,
        getIsHidden: (arg: GetIsHiddenFnArg) => {
          const { formValues } =
            arg as GetIsHiddenFnArg<EditDomainConfigFormData>;

          return !formValues[FormField.AvailableTranslations]?.includes(
            SystemLanguage.KA
          );
        }
      },
      [FormField.TranslationEnSekkDisplayName]: {
        type: InputElement.Input,
        translationKey: 'translation_display_name',
        getPlaceholder: () => 'English',
        maxLength: 20,
        getIsHidden: (arg: GetIsHiddenFnArg) => {
          const { formValues } =
            arg as GetIsHiddenFnArg<EditDomainConfigFormData>;

          return !formValues[FormField.AvailableTranslations]?.includes(
            SystemLanguage.ENSEKK
          );
        },
        getIsDisabled: () => isDefaultConfig
      },
      [FormField.TranslationEnSekkFileName]: {
        type: InputElement.Input,
        translationKey: 'translation_file_name',
        getPlaceholder: () => 'en_sekking.json',
        maxLength: 20,
        getIsHidden: (arg: GetIsHiddenFnArg) => {
          const { formValues } =
            arg as GetIsHiddenFnArg<EditDomainConfigFormData>;

          return !formValues[FormField.AvailableTranslations]?.includes(
            SystemLanguage.ENSEKK
          );
        },
        getIsDisabled: () => isDefaultConfig
      }
    },
    gridLayout: {
      templateAreas: `
      "heading heading"
      "${FormField.Title} ."
      "${FormField.Domain} ${FormField.SubDir}"
      ". ."
       "settings settings"
      "${FormField.WelcomePageAlias} ${FormField.GoogleTagMKey}"
      "${FormField.GoogleTagGKey} ${FormField.GoogleTagAwKey}"
      "${FormField.Language} ${FormField.AvailableTranslations}"
      "${FormField.Assets} ${FormField.AdditionalFields}"
      "${FormField.TranslationEnDisplayName} ${FormField.TranslationEnFileName}"
      "${FormField.TranslationRuDisplayName} ${FormField.TranslationRuFileName}"
      "${FormField.TranslationTrDisplayName} ${FormField.TranslationTrFileName}"
      "${FormField.TranslationHeDisplayName} ${FormField.TranslationHeFileName}"
      "${FormField.TranslationDeDisplayName} ${FormField.TranslationDeFileName}"
      "${FormField.TranslationFrDisplayName} ${FormField.TranslationFrFileName}"
      "${FormField.TranslationEsDisplayName} ${FormField.TranslationEsFileName}"
      "${FormField.TranslationItDisplayName} ${FormField.TranslationItFileName}"
      "${FormField.TranslationElDisplayName} ${FormField.TranslationElFileName}"
      "${FormField.TranslationUkDisplayName} ${FormField.TranslationUkFileName}"
      "${FormField.TranslationKaDisplayName} ${FormField.TranslationKaFileName}"
      "${FormField.TranslationEnSekkDisplayName} ${FormField.TranslationEnSekkFileName}"
    `,
      gridTemplateColumns: '1fr 1fr',
      rowGap: 6,
      columnGap: 10
    }
  } as const);

export const editDomainConfigValidationSchema: yup.SchemaOf<EditDomainConfigFormData> =
  yup.object({
    [FormField.Title]: yup.string().required().max(150),
    [FormField.Domain]: yup.string().required().max(100),
    [FormField.SubDir]: yup.string().optional().max(50),
    [FormField.WelcomePageAlias]: yup
      .mixed()
      .oneOf(getAllEnumValues(WelcomePageAlias)),
    [FormField.GoogleTagMKey]: yup.string().required().max(100),
    [FormField.GoogleTagGKey]: yup.string().required().max(100),
    [FormField.GoogleTagAwKey]: yup.string().required().max(100),
    [FormField.Language]: yup.mixed().oneOf(getAllEnumValues(SystemLanguage)),
    [FormField.AdditionalFields]: yup.string().optional(),
    [FormField.Assets]: yup.string().optional(),
    [FormField.AvailableTranslations]: yup
      .array(yup.mixed().oneOf(getAllEnumValues(SystemLanguage)))
      .required()
      .min(1),
    [FormField.TranslationEnDisplayName]: yup
      .string()
      .when(FormField.AvailableTranslations, {
        is: (value?: string) => value?.includes(SystemLanguage.EN),
        then: yup.string().required().max(20),
        otherwise: yup.string().optional()
      }),
    [FormField.TranslationEnFileName]: yup
      .string()
      .when(FormField.AvailableTranslations, {
        is: (value?: string) => value?.includes(SystemLanguage.EN),
        then: yup.string().required().max(20),
        otherwise: yup.string().optional()
      }),
    [FormField.TranslationRuDisplayName]: yup
      .string()
      .when(FormField.AvailableTranslations, {
        is: (value?: string) => value?.includes(SystemLanguage.RU),
        then: yup.string().required().max(20),
        otherwise: yup.string().optional()
      }),
    [FormField.TranslationRuFileName]: yup
      .string()
      .when(FormField.AvailableTranslations, {
        is: (value?: string) => value?.includes(SystemLanguage.RU),
        then: yup.string().required().max(20),
        otherwise: yup.string().optional()
      }),
    [FormField.TranslationTrDisplayName]: yup
      .string()
      .when(FormField.AvailableTranslations, {
        is: (value?: string) => value?.includes(SystemLanguage.TR),
        then: yup.string().required().max(20),
        otherwise: yup.string().optional()
      }),
    [FormField.TranslationTrFileName]: yup
      .string()
      .when(FormField.AvailableTranslations, {
        is: (value?: string) => value?.includes(SystemLanguage.TR),
        then: yup.string().required().max(20),
        otherwise: yup.string().optional()
      }),
    [FormField.TranslationHeDisplayName]: yup
      .string()
      .when(FormField.AvailableTranslations, {
        is: (value?: string) => value?.includes(SystemLanguage.HE),
        then: yup.string().required().max(20),
        otherwise: yup.string().optional()
      }),
    [FormField.TranslationHeFileName]: yup
      .string()
      .when(FormField.AvailableTranslations, {
        is: (value?: string) => value?.includes(SystemLanguage.HE),
        then: yup.string().required().max(20),
        otherwise: yup.string().optional()
      }),
    [FormField.TranslationDeDisplayName]: yup
      .string()
      .when(FormField.AvailableTranslations, {
        is: (value?: string) => value?.includes(SystemLanguage.DE),
        then: yup.string().required().max(20),
        otherwise: yup.string().optional()
      }),
    [FormField.TranslationDeFileName]: yup
      .string()
      .when(FormField.AvailableTranslations, {
        is: (value?: string) => value?.includes(SystemLanguage.DE),
        then: yup.string().required().max(20),
        otherwise: yup.string().optional()
      }),
    [FormField.TranslationFrDisplayName]: yup
      .string()
      .when(FormField.AvailableTranslations, {
        is: (value?: string) => value?.includes(SystemLanguage.FR),
        then: yup.string().required().max(20),
        otherwise: yup.string().optional()
      }),
    [FormField.TranslationFrFileName]: yup
      .string()
      .when(FormField.AvailableTranslations, {
        is: (value?: string) => value?.includes(SystemLanguage.FR),
        then: yup.string().required().max(20),
        otherwise: yup.string().optional()
      }),
    [FormField.TranslationEsDisplayName]: yup
      .string()
      .when(FormField.AvailableTranslations, {
        is: (value?: string) => value?.includes(SystemLanguage.ES),
        then: yup.string().required().max(20),
        otherwise: yup.string().optional()
      }),
    [FormField.TranslationEsFileName]: yup
      .string()
      .when(FormField.AvailableTranslations, {
        is: (value?: string) => value?.includes(SystemLanguage.ES),
        then: yup.string().required().max(20),
        otherwise: yup.string().optional()
      }),
    [FormField.TranslationItDisplayName]: yup
      .string()
      .when(FormField.AvailableTranslations, {
        is: (value?: string) => value?.includes(SystemLanguage.IT),
        then: yup.string().required().max(20),
        otherwise: yup.string().optional()
      }),
    [FormField.TranslationItFileName]: yup
      .string()
      .when(FormField.AvailableTranslations, {
        is: (value?: string) => value?.includes(SystemLanguage.IT),
        then: yup.string().required().max(20),
        otherwise: yup.string().optional()
      }),
    [FormField.TranslationElDisplayName]: yup
      .string()
      .when(FormField.AvailableTranslations, {
        is: (value?: string) => value?.includes(SystemLanguage.EL),
        then: yup.string().required().max(20),
        otherwise: yup.string().optional()
      }),
    [FormField.TranslationElFileName]: yup
      .string()
      .when(FormField.AvailableTranslations, {
        is: (value?: string) => value?.includes(SystemLanguage.EL),
        then: yup.string().required().max(20),
        otherwise: yup.string().optional()
      }),
    [FormField.TranslationUkDisplayName]: yup
      .string()
      .when(FormField.AvailableTranslations, {
        is: (value?: string) => value?.includes(SystemLanguage.UK),
        then: yup.string().required().max(20),
        otherwise: yup.string().optional()
      }),
    [FormField.TranslationUkFileName]: yup
      .string()
      .when(FormField.AvailableTranslations, {
        is: (value?: string) => value?.includes(SystemLanguage.UK),
        then: yup.string().required().max(20),
        otherwise: yup.string().optional()
      }),
    [FormField.TranslationKaDisplayName]: yup
      .string()
      .when(FormField.AvailableTranslations, {
        is: (value?: string) => value?.includes(SystemLanguage.KA),
        then: yup.string().required().max(20),
        otherwise: yup.string().optional()
      }),
    [FormField.TranslationKaFileName]: yup
      .string()
      .when(FormField.AvailableTranslations, {
        is: (value?: string) => value?.includes(SystemLanguage.KA),
        then: yup.string().required().max(20),
        otherwise: yup.string().optional()
      }),
    [FormField.TranslationEnSekkDisplayName]: yup
      .string()
      .when(FormField.AvailableTranslations, {
        is: (value?: string) => value?.includes(SystemLanguage.ENSEKK),
        then: yup.string().required().max(20),
        otherwise: yup.string().optional()
      }),
    [FormField.TranslationEnSekkFileName]: yup
      .string()
      .when(FormField.AvailableTranslations, {
        is: (value?: string) => value?.includes(SystemLanguage.ENSEKK),
        then: yup.string().required().max(20),
        otherwise: yup.string().optional()
      })
  });

export const getDefaultFormValues = (
  data?: DomainConfigListResponse
): DefaultValues<EditDomainConfigFormData> => ({
  [FormField.Title]: data?.name,
  [FormField.Domain]: data?.domain,
  [FormField.SubDir]: data?.subDir || '',
  [FormField.WelcomePageAlias]: data?.welcomePageAlias,
  [FormField.GoogleTagMKey]: data?.googleTagMKey,
  [FormField.GoogleTagGKey]: data?.googleTagGKey,
  [FormField.GoogleTagAwKey]: data?.googleTagAwKey,
  [FormField.Language]: data?.defaultTranslation,
  [FormField.AdditionalFields]: data?.additional || '',
  [FormField.Assets]: data?.assets || '',
  [FormField.AvailableTranslations]: data?.availableTranslations,
  [FormField.TranslationEnDisplayName]: data?.translations.find(
    (item) => item.languageCode === SystemLanguage.EN
  )?.displayName,
  [FormField.TranslationEnFileName]: data?.translations.find(
    (item) => item.languageCode === SystemLanguage.EN
  )?.fileName,
  [FormField.TranslationRuDisplayName]: data?.translations.find(
    (item) => item.languageCode === SystemLanguage.RU
  )?.displayName,
  [FormField.TranslationRuFileName]: data?.translations.find(
    (item) => item.languageCode === SystemLanguage.RU
  )?.fileName,
  [FormField.TranslationTrDisplayName]: data?.translations.find(
    (item) => item.languageCode === SystemLanguage.TR
  )?.displayName,
  [FormField.TranslationTrFileName]: data?.translations.find(
    (item) => item.languageCode === SystemLanguage.TR
  )?.fileName,
  [FormField.TranslationHeDisplayName]: data?.translations.find(
    (item) => item.languageCode === SystemLanguage.HE
  )?.displayName,
  [FormField.TranslationHeFileName]: data?.translations.find(
    (item) => item.languageCode === SystemLanguage.HE
  )?.fileName,
  [FormField.TranslationDeDisplayName]: data?.translations.find(
    (item) => item.languageCode === SystemLanguage.DE
  )?.displayName,
  [FormField.TranslationDeFileName]: data?.translations.find(
    (item) => item.languageCode === SystemLanguage.DE
  )?.fileName,
  [FormField.TranslationFrDisplayName]: data?.translations.find(
    (item) => item.languageCode === SystemLanguage.FR
  )?.displayName,
  [FormField.TranslationFrFileName]: data?.translations.find(
    (item) => item.languageCode === SystemLanguage.FR
  )?.fileName,
  [FormField.TranslationEsDisplayName]: data?.translations.find(
    (item) => item.languageCode === SystemLanguage.ES
  )?.displayName,
  [FormField.TranslationEsFileName]: data?.translations.find(
    (item) => item.languageCode === SystemLanguage.ES
  )?.fileName,
  [FormField.TranslationItDisplayName]: data?.translations.find(
    (item) => item.languageCode === SystemLanguage.IT
  )?.displayName,
  [FormField.TranslationItFileName]: data?.translations.find(
    (item) => item.languageCode === SystemLanguage.IT
  )?.fileName,
  [FormField.TranslationElDisplayName]: data?.translations.find(
    (item) => item.languageCode === SystemLanguage.EL
  )?.displayName,
  [FormField.TranslationElFileName]: data?.translations.find(
    (item) => item.languageCode === SystemLanguage.EL
  )?.fileName,
  [FormField.TranslationUkDisplayName]: data?.translations.find(
    (item) => item.languageCode === SystemLanguage.UK
  )?.displayName,
  [FormField.TranslationUkFileName]: data?.translations.find(
    (item) => item.languageCode === SystemLanguage.UK
  )?.fileName,
  [FormField.TranslationKaDisplayName]: data?.translations.find(
    (item) => item.languageCode === SystemLanguage.KA
  )?.displayName,
  [FormField.TranslationKaFileName]: data?.translations.find(
    (item) => item.languageCode === SystemLanguage.KA
  )?.fileName,
  [FormField.TranslationEnSekkDisplayName]: data?.translations.find(
    (item) => item.languageCode === SystemLanguage.ENSEKK
  )?.displayName,
  [FormField.TranslationEnSekkFileName]: data?.translations.find(
    (item) => item.languageCode === SystemLanguage.ENSEKK
  )?.fileName
});

export const serializeRequestData = (
  formData: EditDomainConfigFormData
): CreateDomainConfigRequestData => {
  const data = formData as RequiredNotNullable<EditDomainConfigFormData>;

  return {
    ...pickBy(
      {
        name: data[FormField.Title],
        domain: data[FormField.Domain],
        subDir: data[FormField.SubDir],
        welcomePageAlias: data[FormField.WelcomePageAlias],
        googleTagMKey: data[FormField.GoogleTagMKey],
        googleTagGKey: data[FormField.GoogleTagGKey],
        googleTagAwKey: data[FormField.GoogleTagAwKey],
        defaultTranslation: data[FormField.Language],
        additional: data[FormField.AdditionalFields],
        availableTranslations: data[FormField.AvailableTranslations],
        translations: data[FormField.AvailableTranslations].map(
          (code: SystemLanguage) => ({
            languageCode: code,
            displayName: data[FormField[LanguageRelatedDisplayName[code]]],
            fileName: data[FormField[LanguageRelatedFileName[code]]]
          })
        )
      },
      identity
    ),
    assets: data[FormField.Assets] || '{}'
  } as CreateDomainConfigRequestData;
};
