import { defineStyle, defineStyleConfig } from '@chakra-ui/react';

const baseStyle = defineStyle({
  _hover: {
    color: 'primary.600',
    textDecoration: 'none'
  },
  _active: {
    color: 'primary.700'
  },
  _disabled: {
    color: 'disabled',
    pointerEvents: 'none'
  }
});

export const Link = defineStyleConfig({
  baseStyle
});
