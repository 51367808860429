import { configureStore } from '@reduxjs/toolkit';
import { localStorageService } from 'services';

import { appReducer } from './app';
import { modalReducer } from './modal';
import { settingsReducer } from './settings';
import { userReducer } from './user';

export const store = configureStore({
  reducer: {
    user: userReducer,
    modal: modalReducer,
    settings: settingsReducer,
    app: appReducer
  },
  preloadedState: localStorageService.loadState(),
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false
    })
});
