import { GridItem, Heading } from '@chakra-ui/react';
import { yupResolver } from '@hookform/resolvers/yup';
import { RightSideButtons } from 'components';
import {
  ContentContainer,
  FormConstructor,
  RightSide,
  ScrollableContainer
} from 'containers';
import {
  useChakraToast,
  useCreateDomainConfig,
  useFormValidation
} from 'hooks';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { CreateDomainConfigFormData } from './CreateDomainConfig.types';
import {
  createDomainConfigFormSchema,
  createDomainConfigValidationSchema,
  getDefaultFormValues,
  serializeRequestData
} from './CreateDomainConfig.utils';

export const CreateDomainConfig = (): JSX.Element => {
  const [t] = useTranslation();
  const navigate = useNavigate();
  const toast = useChakraToast();

  const form = useForm<CreateDomainConfigFormData>({
    mode: 'onSubmit',
    shouldUnregister: true,
    resolver: yupResolver(createDomainConfigValidationSchema),
    defaultValues: getDefaultFormValues()
  });

  const { handleSubmit } = form;

  const isFormFilled = useFormValidation<CreateDomainConfigFormData>(
    form,
    createDomainConfigValidationSchema
  );

  const { mutate, isLoading } = useCreateDomainConfig();

  const onSubmit = handleSubmit((data) => {
    mutate(serializeRequestData(data), {
      onSuccess: () => {
        toast({
          status: 'success',
          title: t('messages.successfully_saved')
        });
        navigate(-1);
      }
    });
  });

  const handleCancel = () => {
    navigate(-1);
  };

  return (
    <ContentContainer height="100%">
      <ScrollableContainer>
        <FormProvider {...form}>
          <FormConstructor formSchema={createDomainConfigFormSchema}>
            <GridItem area="heading">
              <Heading as="h3" size="l">
                {t('attribute.title.list_of_domain_configs')}
              </Heading>
            </GridItem>
            <GridItem area="settings">
              <Heading as="h3" size="l">
                {t('attribute.title.settings')}
              </Heading>
            </GridItem>
          </FormConstructor>
        </FormProvider>
        <RightSide>
          <RightSideButtons
            onSubmit={onSubmit}
            isDisabledSubmit={false}
            onCancel={handleCancel}
            isLoading={isLoading}
            label={t('actions.save_config')}
          />
        </RightSide>
      </ScrollableContainer>
    </ContentContainer>
  );
};
