import { Flex, Heading } from '@chakra-ui/react';
import { AddButton, Table } from 'components';
import { ContentContainer } from 'containers';
import { ModalType } from 'enum';
import { useAppDispatch } from 'hooks';
import { useTranslation } from 'react-i18next';
import { openModal } from 'store/modal';
import { ICustomer } from 'types/customer';

import { lastAdminCommentsColumns } from './CustomerDashboard.utils';

type Props = {
  customer: ICustomer;
};

export const LastAdminComments = ({ customer }: Props): JSX.Element => {
  const [t] = useTranslation();
  const dispatch = useAppDispatch();

  const handleAddComment = () =>
    dispatch(
      openModal({
        id: ModalType.CreateComment,
        meta: {
          customerId: customer.id
        }
      })
    );

  return (
    <ContentContainer>
      <Flex direction="row" justifyContent="space-between" marginBottom={6}>
        <Heading as="h3" size="l">
          Last Admin Comments
        </Heading>
        <AddButton onClick={handleAddComment}>
          {t('actions.add_comment')}
        </AddButton>
      </Flex>
      <Table
        columns={lastAdminCommentsColumns}
        data={customer.lastAdminComments}
        totalItems={1}
        shouldHidePagination
      />
    </ContentContainer>
  );
};
