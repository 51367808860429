import { ICustomerBlackList } from 'types/customer';

import { CustomerBlackListResponse } from '../Customers.types';

export const serializeCustomerBlackListData = (
  response: CustomerBlackListResponse[]
): ICustomerBlackList[] =>
  response.map((data) => {
    const { id, addedAt, partner, addedBy } = data;
    const {
      nickname = '',
      email = '',
      id: userId = ''
    } = partner || addedBy || {};

    return {
      id,
      nickname,
      email,
      addedAt,
      userId
    };
  });
