import { HStack, Icon, Text, VStack } from '@chakra-ui/react';
import { yupResolver } from '@hookform/resolvers/yup';
import { CheckIcon, RightSideButtons } from 'components';
import { ContentContainer, FormConstructor, RightSide } from 'containers';
import { useChakraToast, useGeneralSettings, useUpdateCustomer } from 'hooks';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate, useOutletContext } from 'react-router-dom';
import { getUpdatedFields } from 'utils';

import { AboutMeFormData } from './AboutMe.types';
import {
  aboutMeValidationSchema,
  getDefaultFormValues,
  getCustomerAboutMeFormSchema
} from './AboutMe.utils';

export const AboutMe = (): JSX.Element => {
  const [t] = useTranslation();
  const navigate = useNavigate();
  const toast = useChakraToast();

  const { customer } = useOutletContext();
  const { data: generalSettings } = useGeneralSettings(customer.bio?.country);

  const form = useForm<AboutMeFormData>({
    mode: 'onSubmit',
    resolver: yupResolver(aboutMeValidationSchema),
    defaultValues: getDefaultFormValues(customer)
  });

  const {
    formState: { isDirty, dirtyFields },
    handleSubmit,
    getValues,
    reset
  } = form;

  const { mutate: updateCustomer, isLoading } = useUpdateCustomer();

  const onSubmit = handleSubmit((data) => {
    const updateData = getUpdatedFields(data, dirtyFields);

    updateCustomer(
      {
        id: customer.id,
        ...updateData
      },
      {
        onSuccess: (_, { id, ...rest }) => {
          reset({
            ...getValues(),
            ...rest
          });

          toast({
            title: t('messages.successfully_saved'),
            status: 'success'
          });
        }
      }
    );
  });

  const handleCancel = () => {
    navigate(-1);
  };

  const acceptedItems: string[] = [];

  if (customer.config.allowedAds) {
    acceptedItems.push(t('attribute.description.allowed_ad_marker'));
  }

  if (customer.config.acceptedPolicy) {
    acceptedItems.push(t('attribute.description.accepted_policy_marker'));
  }

  return (
    <ContentContainer>
      {acceptedItems.length > 0 && (
        <VStack spacing={4} marginBottom={6} alignItems="flex-start">
          {acceptedItems.map((text) => (
            <HStack spacing={4} key={text}>
              <Icon as={CheckIcon} />
              <Text>{text}</Text>
            </HStack>
          ))}
        </VStack>
      )}
      <FormProvider {...form}>
        <FormConstructor
          formSchema={getCustomerAboutMeFormSchema({
            heightUnit: generalSettings?.lengthUnit
          })}
        />
      </FormProvider>
      <RightSide>
        <RightSideButtons
          onSubmit={onSubmit}
          isDisabledSubmit={!isDirty}
          onCancel={handleCancel}
          isLoading={isLoading}
        />
      </RightSide>
    </ContentContainer>
  );
};
