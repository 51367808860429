import { ENDPOINTS } from 'api/endpoints';
import axios from 'axios';
import { getAllEnumValues } from 'enum-for';
import { PermissionAction, PermissionResource } from 'enum/api';
import merge from 'lodash/merge';

import { Permission, PermissionsResponse } from './Permissions.types';
import { serializePermissions } from './Permissions.utils';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const fakePermissions: Permission = merge(
  getAllEnumValues(PermissionResource).reduce((acc, key) => {
    acc[key] = {
      [PermissionAction.Create]: true,
      [PermissionAction.Update]: true,
      [PermissionAction.Delete]: true,
      [PermissionAction.Read]: true
    };
    return acc;
  }, {} as Permission)
);

export const fetchPermissions = async (): Promise<Permission> => {
  const { data } = await axios.get<PermissionsResponse[]>(
    ENDPOINTS.PERMISSIONS
  );

  return serializePermissions(data);
};
