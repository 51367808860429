import { ENDPOINTS, ParameterOptionUpdateParams } from 'api';
import axios from 'axios';

export const fetchUpdateParameterOption = ({
  id,
  meta,
  ...body
}: ParameterOptionUpdateParams) =>
  axios.patch(`${ENDPOINTS.PARAMETERS_OPTIONS}/${id}`, {
    ...body,
    ...(meta && { meta: JSON.stringify(meta) })
  });
