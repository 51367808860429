import { ENDPOINTS } from 'api/endpoints';
import axios from 'axios';

export const fetchUpdatePaymentMethod = ({
  methodId,
  showOnWebsite,
  enabledSuccessOnFail,
  countries
}: {
  methodId: string;
  showOnWebsite?: boolean;
  enabledSuccessOnFail?: boolean;
  countries?: string[];
}) =>
  axios.patch(`${ENDPOINTS.PAYMENT_METHOD}/${methodId}`, {
    showOnWebsite,
    enabledSuccessOnFail,
    countries
  });
