import { Grid, GridItem } from '@chakra-ui/react';
import { ReactNode, useEffect, useMemo, useRef } from 'react';
import { useFormContext } from 'react-hook-form';
import { FormSchemaType } from 'types/form';

import { WithFormMetaData } from './FormConstructor.types';
import { FormElement } from './FormElement';

type Props = WithFormMetaData & {
  formSchema: FormSchemaType;
  children?: ReactNode;
  insert?: {
    afterField: string;
    node: ReactNode;
  }[];
};

export const FormConstructor = ({
  formSchema,
  children,
  insert,
  meta
}: Props): JSX.Element => {
  const { fields, gridLayout, dependedFields } = formSchema;

  const { watch, getValues, resetField } = useFormContext();

  const watchFields = dependedFields ? Object.keys(dependedFields) : [];
  const watchValues = watchFields.length ? watch(watchFields) : [];

  const formList = useMemo(() => Object.keys(fields), [fields, ...watchValues]);

  const oldFormValues = useRef<Record<string, unknown> | null>(null);

  useEffect(() => {
    if (!dependedFields) {
      return;
    }

    const formValues = getValues();
    if (!oldFormValues.current) {
      oldFormValues.current = formValues;
      return;
    }

    Object.keys(dependedFields).forEach((field) => {
      if (watchFields.includes(field)) {
        if (oldFormValues.current![field] !== formValues[field]) {
          dependedFields![field]!.forEach((dependedField) =>
            resetField(dependedField)
          );
        }
      }
    });

    oldFormValues.current = getValues();
  }, [watchValues]);

  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
      }}
    >
      <Grid {...gridLayout}>
        {formList.map((key, idx) => (
          <GridItem key={key} area={key}>
            <FormElement
              {...(fields as Required<typeof fields>)[key]}
              name={key}
              meta={meta}
            />
            {insert
              ?.filter(({ afterField }) => afterField === key)
              ?.map(({ node }) => node)}
          </GridItem>
        ))}
        {children}
      </Grid>
    </form>
  );
};
