import { Grid, GridItem } from '@chakra-ui/react';
import { AddButton, Table } from 'components';
import { ContentContainer } from 'containers';
import { useDomainConfigList, useTableQueryController } from 'hooks';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { domainsConfigsColumns } from './DomainConfig.utils';

export const DomainConfigList = (): JSX.Element => {
  const [t] = useTranslation();
  const navigate = useNavigate();

  const {
    queryFilters,
    action: { onChangeSort, onChangePagination }
  } = useTableQueryController();

  const { data } = useDomainConfigList(queryFilters);

  const createDomainConfig = () => {
    navigate('create');
  };

  return (
    <ContentContainer height="100%">
      <Grid
        templateAreas={`". actions"
                        "table table"
       `}
        templateColumns="1fr auto"
        templateRows="min-content 1fr"
        rowGap={6}
        height="100%"
      >
        <GridItem area="actions" justifySelf="flex-end">
          <AddButton onClick={createDomainConfig}>
            {t('actions.create_domain_config')}
          </AddButton>
        </GridItem>
        {data && (
          <GridItem area="table" overflow="hidden">
            <Table
              data={data.data}
              columns={domainsConfigsColumns}
              onChangeSort={onChangeSort}
              onChangePagination={onChangePagination}
              pagination={queryFilters.pagination}
              totalItems={data.totalItems}
            />
          </GridItem>
        )}
      </Grid>
    </ContentContainer>
  );
};
