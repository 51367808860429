import { ComponentStyleConfig } from '@chakra-ui/theme';

export const Avatar: ComponentStyleConfig = {
  sizes: {
    md: {
      container: {
        width: 10,
        height: 10
      }
    }
  }
};
