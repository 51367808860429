import { ImageStatus } from 'enum/api';
import { TFunction } from 'react-i18next';

export const getUpdateCustomerStoryStatusTitle = (
  t: TFunction,
  status?: ImageStatus
): string => {
  switch (status) {
    case ImageStatus.Active:
      return t('attribute.title.restore_story');
    case ImageStatus.DeletedByAdmin:
      return t('attribute.title.delete_story');
  }

  return '';
};

export const getUpdateCustomerMessageStatusTitle = (
  t: TFunction,
  status?: ImageStatus
): string => {
  switch (status) {
    case ImageStatus.Active:
      return t('attribute.title.restore_image');
    case ImageStatus.DeletedByAdmin:
      return t('attribute.title.delete_image');
  }

  return '';
};

export const getUpdateCustomerGalleryImageStatusTitle = (
  t: TFunction,
  status?: ImageStatus
): string => {
  switch (status) {
    case ImageStatus.Active:
      return t('attribute.title.restore_image');
    case ImageStatus.DeletedByAdmin:
      return t('attribute.title.delete_image');
    case ImageStatus.DeclinedByAdmin:
      return t('attribute.title.decline_image');
  }

  return '';
};
