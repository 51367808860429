import { IconButton } from '@chakra-ui/react';
import { EditIcon } from 'components';
import { ModalType } from 'enum';
import { useAppDispatch } from 'hooks';
import { openModal } from 'store';

type Props = {
  transactionId: string;
  comment: string | null;
};

export const EditTransactionCell = ({
  transactionId,
  comment
}: Props): JSX.Element => {
  const dispatch = useAppDispatch();

  const handleEdit = () => {
    dispatch(
      openModal({
        id: ModalType.UpdateTransaction,
        meta: {
          transactionId,
          comment: comment || ''
        }
      })
    );
  };

  return (
    <IconButton
      size="md"
      icon={<EditIcon />}
      variant="icon"
      onClick={handleEdit}
      aria-label="edit transaction"
    />
  );
};
