import { GridItem } from '@chakra-ui/react';
import { FormElement } from 'containers';
import { InputElement } from 'enum';
import { Fragment } from 'react';
import { useTranslation } from 'react-i18next';

import { GeneralSettingsFormField } from '../FormContent.types';

export const ReportEmail = (): JSX.Element => {
  const [t] = useTranslation();

  return (
    <Fragment>
      <GridItem>{t('attribute.description.report_email_for_reports')}</GridItem>
      <GridItem>
        <FormElement
          type={InputElement.Input}
          name={GeneralSettingsFormField.ReportEmail}
          translationKey="email"
          getPlaceholder={() => 'Email'}
          maxLength={50}
        />
      </GridItem>
    </Fragment>
  );
};
