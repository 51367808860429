import { FormField, InputElement } from 'enum';

export const warningBadWordsCustomerMessageByCustomerFormFields = [
  {
    type: InputElement.Select,
    name: `${FormField.User}.${FormField.Email}`,
    translationKey: 'customer',
    shouldHideOptions: true,
    getPlaceholder: () => 'Select customer'
  },
  {
    type: InputElement.MultiSelect,
    name: FormField.BadWords,
    translationKey: 'word_other',
    getPlaceholder: () => 'Select',
    shouldHideOptions: true,
    isSearchable: true,
    canAdd: true
  }
] as const;

export const getInitialFieldsState = () => ({
  [FormField.User]: {
    [FormField.Id]: null,
    [FormField.Email]: null
  },
  [FormField.BadWords]: []
});
