import { Flex } from '@chakra-ui/react';
import { ModerationGalleryPhotoResponse } from 'api';
import { LoadMoreIntersection } from 'components';
import { ScrollableContainer } from 'containers';

import { UserCard } from './UserCard';

type Props = {
  data: ModerationGalleryPhotoResponse[];
  isLoading: boolean;
  loadMore: () => void;
};

export const GalleryPhotoContainer = ({
  data,
  isLoading,
  loadMore
}: Props): JSX.Element => (
  <ScrollableContainer>
    <Flex flexDirection="column" gap={5} overflowX="hidden">
      {data.map((moderateData) => (
        <UserCard key={moderateData.user.id} data={moderateData} />
      ))}
    </Flex>
    <LoadMoreIntersection
      isLoading={isLoading}
      loadMore={loadMore}
      triggerOffset={{ top: '-10vh' }}
      marginTop={2}
    />
  </ScrollableContainer>
);
