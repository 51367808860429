import { ModalContainer } from 'containers';
import { ModalType } from 'enum';
import { useModalActions, useCrmBlockUsers } from 'hooks';
import { useTranslation } from 'react-i18next';
import { ParamType } from 'types';

export const BlockCrmUsers = (): JSX.Element => {
  const [t] = useTranslation();

  const { mutate, isLoading } = useCrmBlockUsers();

  const { isOpen, onClose, meta } = useModalActions(ModalType.BlockCrmUsers);

  const onSubmit = () => {
    if (meta) {
      mutate(meta, {
        onSuccess: onClose
      });
    }
  };

  const actions: ParamType<typeof ModalContainer, 'footerButtons'> = [
    {
      text: t('actions.no'),
      variant: 'ghostGray'
    },
    {
      text: t('actions.yes'),
      isLoading,
      isPreventClose: true,
      onClick: onSubmit
    }
  ];

  return (
    <ModalContainer
      header={t('attribute.title.crm_block_users_confirm', { count: 2 })}
      footerButtons={actions}
      isOpen={isOpen}
      onClose={onClose}
      size="xl"
      modalBodyProps={{
        overflow: 'unset',
        overflowX: 'unset'
      }}
    />
  );
};
