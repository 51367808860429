import { FormField, InputElement } from 'enum';
import { ParameterType } from 'enum/api';
import { GetIsHiddenFnArg, GetOptionsArg } from 'types/form';
import { generateOptionsFromSettings, getIsArrayChanged } from 'utils';
import * as yup from 'yup';

import { EditLabelsFormData } from './EditLabels.types';

export const editLabelsFormSchema = {
  fields: {
    [FormField.Label]: {
      type: InputElement.MultiSelect,
      translationKey: 'labels',
      getPlaceholder: () => 'Select',
      getOptions: ({ t, settings }: GetOptionsArg) =>
        generateOptionsFromSettings(ParameterType.Label, t, settings)
    },
    [FormField.Comment]: {
      type: InputElement.TextArea,
      translationKey: 'comment',
      getPlaceholder: () => '',
      maxLength: 2500,
      getIsHidden: (arg: GetIsHiddenFnArg) => {
        const { formValues, defaultValues } =
          arg as GetIsHiddenFnArg<EditLabelsFormData>;

        return !getIsArrayChanged(
          formValues[FormField.Label],
          defaultValues[FormField.Label] || []
        );
      }
    }
  },
  gridLayout: {
    templateAreas: `
    "${FormField.Label}"
    "${FormField.Comment}"
  `,
    gridTemplateColumns: '1fr',
    rowGap: 6
  }
} as const;

export const editLabelsValidationSchema: yup.SchemaOf<EditLabelsFormData> = yup
  .object()
  .shape(
    {
      [FormField.Label]: yup.array(yup.string().required()).ensure(),
      [FormField.Comment]: yup.string().when(FormField.Comment, {
        is: (value?: string) => typeof value === 'undefined',
        then: yup.string().optional(),
        otherwise: yup.string().required()
      })
    },
    [[FormField.Comment, FormField.Comment]]
  );
