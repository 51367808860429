import { Icon, IconProps } from '@chakra-ui/react';

export const CopyIcon = (props: IconProps): JSX.Element => (
  <Icon viewBox="0 0 24 24" fill="none" {...props}>
    <rect
      x="4"
      y="2"
      width="12"
      height="14"
      rx="2"
      stroke="currentColor"
      strokeWidth="1.5"
    />
    <rect
      x="9"
      y="7"
      width="12"
      height="14"
      rx="2"
      stroke="currentColor"
      strokeWidth="1.5"
    />
  </Icon>
);
