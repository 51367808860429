import { useMutation, useQueryClient } from '@tanstack/react-query';
import { fetchDeactivatePaymentSubscription } from 'api';
import { QueryKey } from 'enum/api';

export const useDeactivatePaymentSubscription = () => {
  const queryClient = useQueryClient();

  return useMutation(fetchDeactivatePaymentSubscription, {
    onSuccess() {
      queryClient.invalidateQueries([QueryKey.PaymentSubscription]);
    }
  });
};
