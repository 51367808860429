import { ModalContainer } from 'containers';
import { ModalType } from 'enum';
import { useDeleteLinkedAccount, useModalActions } from 'hooks';
import { useTranslation } from 'react-i18next';
import { ParamType } from 'types';

export const UnlinkLinkedAccount = (): JSX.Element => {
  const [t] = useTranslation();

  const { mutate, isLoading, reset } = useDeleteLinkedAccount();

  const { isOpen, onClose, meta } = useModalActions(
    ModalType.UnlinkLinkedAccount,
    {
      onClose: reset
    }
  );

  const actions: ParamType<typeof ModalContainer, 'footerButtons'> = [
    {
      text: t('actions.no'),
      variant: 'ghostGray'
    },
    {
      text: t('actions.yes'),
      colorScheme: 'secondary',
      isLoading,
      isPreventClose: true,
      onClick: (onClose) => {
        if (meta) {
          mutate(
            {
              linkId: meta.linkId
            },
            {
              onSuccess: onClose
            }
          );
        }
      }
    }
  ];

  const headerLabel = t('attribute.title.unlink_account');

  return (
    <ModalContainer
      header={headerLabel}
      footerButtons={actions}
      isOpen={isOpen}
      onClose={onClose}
      size="xl"
    >
      {t('attribute.description.basic_action_question', {
        action: headerLabel.toLowerCase()
      })}
    </ModalContainer>
  );
};
