import { Button, Text } from '@chakra-ui/react';
import { ReportCustomerListResponse } from 'api';
import { NavigationLink } from 'components';
import { ModalType } from 'enum';
import { useAppDispatch, useUpdateReport } from 'hooks';
import { useTranslation } from 'react-i18next';
import { openModal } from 'store/modal';
import { getBaseCustomerLinkNavigation } from 'utils';

type Props = {
  user:
    | ReportCustomerListResponse['reporter']
    | ReportCustomerListResponse['reported'];
  reportId: string;
};

export const OptionalUserCell = ({ user, reportId }: Props): JSX.Element => {
  const [t] = useTranslation();
  const dispatch = useAppDispatch();

  const { mutate } = useUpdateReport();

  if (!user) {
    const handleChooseUser = () => {
      dispatch(
        openModal({
          id: ModalType.CustomerFullList,
          meta: {
            onChooseCustomer: ({ id: userId }) => {
              mutate({
                reportId,
                reportedId: userId
              });
            }
          }
        })
      );
    };

    return (
      <Button variant="link" onClick={handleChooseUser}>
        {t('keywords.unknown_user')}
      </Button>
    );
  }

  const link =
    'hasAdminAccess' in user && !user.hasAdminAccess
      ? user.profileUrl || ''
      : getBaseCustomerLinkNavigation(user.id);

  return (
    <NavigationLink to={link}>
      <Text>{user.displayedNickname}</Text>
      <Text color="subtext">{user.email}</Text>
    </NavigationLink>
  );
};
