import debounce from 'lodash/debounce';
import { useState } from 'react';
import { useLatest, useMount } from 'react-use';
import { getHTMLFontSize } from 'utils';

export const useHtmlFontSize = (): {
  htmlFontSizeValue: number;
  htmlFontSize: string;
} => {
  const [htmlFontSize, setHtmlFontSize] = useState(getHTMLFontSize);

  const latestFontSize = useLatest(htmlFontSize);

  useMount(() => {
    const debouncedResizeHandler = debounce((event: UIEvent) => {
      const newFontSize = getHTMLFontSize();

      if (newFontSize !== latestFontSize.current) {
        setHtmlFontSize(newFontSize);
      }
    }, 200);

    window.addEventListener('resize', debouncedResizeHandler);

    return () => window.removeEventListener('resize', debouncedResizeHandler);
  });

  return {
    htmlFontSizeValue: parseFloat(htmlFontSize),
    htmlFontSize
  };
};
