import { CustomerUserReportsResponse } from 'api';
import { BarChartData, BarChartSettings, TableFilterSchema } from 'containers';
import { format } from 'date-fns';
import { BarChartLayout, InputElement, Period } from 'enum';
import { OptionKey, ParameterType } from 'enum/api';
import { getTranslationByOptionKey } from 'utils';

export const CHART_SETTINGS: BarChartSettings<CustomerUserReportsResponse> = {
  colors: ['#F4AA23'],
  indexBy: 'reason',
  transformChartData: (data) =>
    Object.keys(data).map<BarChartData>((reason) => ({
      reason,
      value: data[reason]
    })),
  formatYAxis: ({ value, t, settings }) =>
    getTranslationByOptionKey({
      t,
      value,
      optionKey: ParameterType.ReportReason,
      settings
    }),
  layout: BarChartLayout.Horizontal
};

export const chartFilters: TableFilterSchema[] = [
  {
    type: InputElement.Select,
    getLabel: (t) => t('keywords.period'),
    defaultValues: [Period.LastMonth],
    optionKey: OptionKey.Period,
    updateOutput: (_, value) => ({
      key: 'period',
      value: value[0]
    })
  },
  {
    type: InputElement.Range,
    getLabel: (t) => t('keywords.custom_period'),
    optionKey: 'customRange',
    updateOutput: (key, value) => ({
      key,
      value: value
        .filter((date): date is string => !!date)
        .map((date) => new Date(date).toISOString())
    }),
    inputProps: {
      type: 'date',
      max: format(new Date(), 'dd.MM.yyyy')
    }
  }
];
