import { Box } from '@chakra-ui/react';
import { Table } from 'components';
import { ModalContainer } from 'containers';
import { ModalType } from 'enum';
import {
  useAppDispatch,
  useModalActions,
  useTableQueryController,
  useTicketConversations
} from 'hooks';
import { useTranslation } from 'react-i18next';
import { openModal } from 'store';
import { ParamType } from 'types';

import { ticketConversationsColumns } from './TicketConversations.utils';

export const TicketConversations = (): JSX.Element => {
  const [t] = useTranslation();
  const dispatch = useAppDispatch();

  const {
    queryFilters,
    action: { onChangeSort, onChangePagination }
  } = useTableQueryController();

  const { isOpen, onClose, meta } = useModalActions(
    ModalType.TicketConversation
  );

  const { data } = useTicketConversations({
    ticketId: meta?.ticketId || '',
    queryFilters,
    enabled: !!meta?.ticketId
  });

  const actions: ParamType<typeof ModalContainer, 'footerButtons'> = [
    {
      text: t('actions.ok'),
      colorScheme: 'gray'
    },
    {
      text: t('actions.reply'),
      isPreventClose: true,
      onClick: () => {
        if (meta) {
          dispatch(
            openModal({
              id: ModalType.ReplyCustomer,
              meta: {
                ticketId: meta.ticketId
              }
            })
          );
        }
      }
    }
  ];

  return (
    <ModalContainer
      header={t('attribute.title.whole_conversation')}
      footerButtons={actions}
      isOpen={isOpen}
      onClose={onClose}
      modalBodyProps={{
        display: 'flex'
      }}
      size="5xl"
    >
      <Box width="100%">
        {data && (
          <Table
            data={data.data}
            columns={ticketConversationsColumns}
            onChangeSort={onChangeSort}
            onChangePagination={onChangePagination}
            pagination={queryFilters.pagination}
            totalItems={data.totalItems}
          />
        )}
      </Box>
    </ModalContainer>
  );
};
