import {
  Button,
  FormControl,
  FormHelperText,
  FormLabel,
  HStack,
  Input,
  InputGroup,
  InputProps,
  InputRightElement,
  useDisclosure
} from '@chakra-ui/react';
import { FormError } from 'components';
import { ViewIcon } from 'components/Icons/ViewIcon';
import { ViewOffIcon } from 'components/Icons/ViewOffIcon';
import { WithFormMetaData } from 'containers/FormConstructor/FormConstructor.types';
import { forwardRef } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

type Props = InputProps &
  WithFormMetaData & {
    label: string;
    errorMessage?: string;
    errorKey?: string;
    hint?: string;
    shouldPreventVisibility?: boolean;
    canResetPassword?: boolean;
    isPrefilled?: boolean;
  };

export const Password = forwardRef<HTMLInputElement, Props>(
  (props, ref): JSX.Element => {
    const [t] = useTranslation();
    const { isOpen, onToggle } = useDisclosure();
    const { getValues } = useFormContext();

    const {
      label,
      errorKey,
      errorMessage,
      isInvalid,
      hint,
      shouldPreventVisibility = false,
      canResetPassword,
      meta,
      isPrefilled,
      name,
      autoComplete,
      ...inputProps
    } = props;

    const hasValue = !!(name && getValues(name));

    return (
      <FormControl isInvalid={isInvalid}>
        {canResetPassword ? (
          <HStack alignItems="baseline" justifyContent="space-between">
            <FormLabel>{label}</FormLabel>
            <Button variant="link" onClick={meta?.onResetPassword}>
              {t('actions.reset_password')}
            </Button>
          </HStack>
        ) : (
          <FormLabel>{label}</FormLabel>
        )}
        <InputGroup position="relative">
          <Input
            size="md"
            type={
              isOpen || (autoComplete === 'off' && !hasValue)
                ? 'text'
                : 'password'
            }
            ref={ref}
            maxLength={25}
            position="relative"
            zIndex={1}
            name={name}
            {...inputProps}
            placeholder={isPrefilled ? '' : inputProps.placeholder}
            autoComplete={autoComplete}
            sx={{
              '&:focus + input': {
                display: 'none'
              }
            }}
          />
          {!isOpen && isPrefilled && !hasValue && (
            <Input
              size="md"
              type="password"
              maxLength={25}
              value="**********"
              position="absolute"
              pointerEvents="none"
              zIndex={2}
              border="none"
            />
          )}
          {!shouldPreventVisibility && (
            <InputRightElement cursor="pointer" onClick={() => onToggle()}>
              {isOpen ? (
                <ViewOffIcon w={5} h={3.5} />
              ) : (
                <ViewIcon w={4.5} h={3} />
              )}
            </InputRightElement>
          )}
        </InputGroup>
        {!!hint && !isInvalid && <FormHelperText>{hint}</FormHelperText>}
        <FormError errorKey={errorKey} errorMessage={errorMessage} />
      </FormControl>
    );
  }
);
