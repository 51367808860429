import { ENDPOINTS } from 'api';
import { serializeBodyFilters } from 'api/api.utils';
import axios from 'axios';
import { GlobalFilters, QueryFilters } from 'types';

export const fetchPaymentTransactionDownloadCSV = async ({
  queryFilters,
  globalFilters
}: {
  queryFilters: QueryFilters;
  globalFilters: GlobalFilters;
}): Promise<string> => {
  const { data } = await axios.post<string>(
    `${ENDPOINTS.PAYMENT_TRANSACTION}/export-csv`,
    serializeBodyFilters({ queryFilters, globalFilters })
  );

  return data;
};
