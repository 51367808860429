import { ENDPOINTS } from 'api';
import { serializeBodyFilters } from 'api/api.utils';
import axios from 'axios';
import { ChartFilters, GlobalFilters } from 'types';

import { PurchaseSubscriptionResponse } from './AdminPanel.types';

export const fetchPurchasedSubscriptions = async ({
  filters,
  globalFilters
}: {
  filters: ChartFilters;
  globalFilters: GlobalFilters;
}): Promise<PurchaseSubscriptionResponse[]> => {
  const { data } = await axios.post<PurchaseSubscriptionResponse[]>(
    ENDPOINTS.PURCHASE_SUBSCRIPTIONS,
    {
      filter: filters,
      ...serializeBodyFilters({ globalFilters })
    }
  );

  return data;
};
