import {
  Box,
  Button,
  Heading,
  HStack,
  useDisclosure,
  VStack
} from '@chakra-ui/react';
import { ModalContainer } from 'containers';
import { useChakraToast } from 'hooks';

export const Modal = (): JSX.Element => {
  const toast = useChakraToast();
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <VStack alignItems="start">
      <Box>
        <Heading as="h3" size="md" mt={3} mb={2}>
          Modal
        </Heading>
        <Button onClick={onOpen}>Open modal</Button>
        <ModalContainer
          isOpen={isOpen}
          onClose={onClose}
          header="Modal Title"
          footerButtons={[
            {
              text: 'Cancel',
              variant: 'ghostGray',
              onClick: () => {}
            },
            {
              text: 'Save',
              onClick: () => {}
            }
          ]}
        >
          <span>Some content</span>
        </ModalContainer>
      </Box>
      <Box>
        <Heading as="h3" size="md" mt={3} mb={2}>
          Toast
        </Heading>
        <HStack spacing={3}>
          {(['info', 'warning', 'success', 'error'] as const).map((status) => (
            <Button
              key={status}
              onClick={() =>
                toast({
                  title: 'Title',
                  description: 'Some text',
                  status,
                  isClosable: true,
                  duration: null
                })
              }
            >
              {status}
            </Button>
          ))}
        </HStack>
      </Box>
    </VStack>
  );
};
