import { ApiQuerySelection, WithPagination } from 'api/api.types';
import { ENDPOINTS } from 'api/endpoints';
import axios from 'axios';
import { ParameterOptionActivityStatus, ParameterType } from 'enum';

import { ParameterOption, ParameterOptionFromApi } from './Settings.types';
import { parseMeta } from './settings.utils';

export const fetchParameterOptions = async (
  params: ApiQuerySelection<
    'type' | 'title',
    {
      types?: ParameterType[];
      statuses?: ParameterOptionActivityStatus[];
      title?: string;
      defaultValue?: string;
    }
  >
): Promise<WithPagination<ParameterOption[]>> => {
  const { data } = await axios.post<WithPagination<ParameterOptionFromApi[]>>(
    ENDPOINTS.PARAMETERS_OPTIONS_LIST,
    params
  );

  return {
    ...data,
    data: data.data.map((option) => ({
      ...option,
      meta: parseMeta(option.meta)
    }))
  };
};
