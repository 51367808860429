import { Icon, IconProps } from '@chakra-ui/react';

export const LightningIcon = (props: IconProps): JSX.Element => (
  <Icon viewBox="0 0 24 24" fill="none" {...props}>
    <path
      d="M18.4059 11.2196L17.8042 10.7719L18.4059 11.2196ZM10.7451 21.5156L11.3468 21.9633L10.7451 21.5156ZM5.59411 12.7804L4.9924 12.3327L5.59411 12.7804ZM13.2549 2.48445L13.8566 2.93216L13.2549 2.48445ZM12.8654 2.60383V10.2211H14.3654V2.60383H12.8654ZM6.19582 13.2281L13.8566 2.93216L12.6532 2.03674L4.9924 12.3327L6.19582 13.2281ZM10.1846 12.8289H5.99525V14.3289H10.1846V12.8289ZM11.1346 21.3962V13.7789H9.63463V21.3962H11.1346ZM17.8042 10.7719L10.1434 21.0678L11.3468 21.9633L19.0076 11.6673L17.8042 10.7719ZM13.8154 11.1711H18.0047V9.67112H13.8154V11.1711ZM19.0076 11.6673C19.6212 10.8427 19.0326 9.67112 18.0047 9.67112V11.1711C17.7992 11.1711 17.6815 10.9368 17.8042 10.7719L19.0076 11.6673ZM9.63463 21.3962C9.63463 22.311 10.8007 22.6972 11.3468 21.9633L10.1434 21.0678C10.4595 20.6429 11.1346 20.8665 11.1346 21.3962H9.63463ZM10.1846 14.3289C9.88087 14.3289 9.63463 14.0826 9.63463 13.7789H11.1346C11.1346 13.2542 10.7093 12.8289 10.1846 12.8289V14.3289ZM4.9924 12.3327C4.37882 13.1573 4.96739 14.3289 5.99525 14.3289V12.8289C6.20082 12.8289 6.31854 13.0632 6.19582 13.2281L4.9924 12.3327ZM12.8654 10.2211C12.8654 10.7458 13.2907 11.1711 13.8154 11.1711V9.67112C14.1191 9.67112 14.3654 9.91736 14.3654 10.2211H12.8654ZM14.3654 2.60383C14.3654 1.68903 13.1993 1.30282 12.6532 2.03674L13.8566 2.93216C13.5405 3.35706 12.8654 3.13346 12.8654 2.60383H14.3654Z"
      fill="currentColor"
    />
  </Icon>
);
