import { Box, Center, CenterProps, Spinner } from '@chakra-ui/react';
import { useLoadMoreData } from 'hooks';

type Props = {
  isLoading?: boolean;
  loadMore: () => void;
  triggerOffset?:
    | { top: string }
    | { left: string }
    | { bottom: string }
    | { right: string };
} & CenterProps;

export const LoadMoreIntersection = ({
  isLoading = false,
  loadMore,
  triggerOffset,
  ...centerProps
}: Props): JSX.Element => {
  const { intersectionRef } = useLoadMoreData({
    loadMore
  });

  return (
    <Box width="100%" position="relative">
      <Box ref={intersectionRef} position="absolute" {...triggerOffset} />
      {isLoading && (
        <Center overflow="hidden" {...centerProps}>
          <Spinner size="md" />
        </Center>
      )}
    </Box>
  );
};
