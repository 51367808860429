import { ENDPOINTS, ReportUpdateRequestData } from 'api';
import axios from 'axios';

export const fetchUpdateReport = ({
  reportId,
  ...body
}: {
  reportId: string;
} & ReportUpdateRequestData) =>
  axios.patch(`${ENDPOINTS.REPORT}/${reportId}`, body);
