import { ENDPOINTS, PaymentTransactionListResponse, WithPagination } from 'api';
import { serializeBodyFilters } from 'api/api.utils';
import axios from 'axios';
import { GlobalFilters, QueryFilters } from 'types';

export const fetchPaymentTransactionList = async ({
  queryFilters,
  globalFilters
}: {
  queryFilters: QueryFilters;
  globalFilters: GlobalFilters;
}): Promise<WithPagination<PaymentTransactionListResponse[]>> => {
  const { data } = await axios.post<
    WithPagination<PaymentTransactionListResponse[]>
  >(
    `${ENDPOINTS.PAYMENT_TRANSACTION}/list`,
    serializeBodyFilters({ queryFilters, globalFilters })
  );

  return data;
};
