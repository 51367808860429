import { useQuery } from '@tanstack/react-query';
import { fetchAllCustomerList } from 'api';
import { QueryKey } from 'enum/api';
import { QueryFilters } from 'types';

export const useAllCustomerList = ({
  queryFilters,
  countryFilter,
  enabled
}: {
  queryFilters: QueryFilters;
  countryFilter?: string[] | null;
  enabled?: boolean;
}) => {
  const filters = countryFilter
    ? { country: countryFilter }
    : { country: null };

  return useQuery(
    [QueryKey.AllCustomers, filters, queryFilters],
    () => fetchAllCustomerList({ globalFilters: filters, queryFilters }),
    {
      retry: false,
      refetchOnWindowFocus: false,
      keepPreviousData: true,
      enabled
    }
  );
};
