import { Text, VStack } from '@chakra-ui/react';
import { BaseChartDataTemplate } from 'api';

import { StackedBarSeriesProps } from '../../CustomStackedBarChart.types';
import { useStackedChartSeries } from '../../useStackedChartSeries';

import { VerticalStackedBar } from './VerticalStackedBar';

export const VerticalStackedBarSeries = <T extends BaseChartDataTemplate>({
  series,
  chartSettings
}: StackedBarSeriesProps<T>): JSX.Element => {
  const { seriesTitle, getStackedBarProps } = useStackedChartSeries({
    seriesId: series.id,
    dataCount: series.data.length,
    chartSettings
  });

  const { data } = series;

  return (
    <VStack spacing={6} height="100%" alignItems="flex-start">
      <VStack
        spacing={0}
        height="100%"
        alignItems="flex-start"
        flexDirection="column-reverse"
      >
        {data.map((barData, index) => (
          <VerticalStackedBar
            key={barData.id}
            {...getStackedBarProps(barData, index)}
          />
        ))}
      </VStack>
      {!!seriesTitle && <Text textStyle="md">{seriesTitle}</Text>}
    </VStack>
  );
};
