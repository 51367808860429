import { SystemStyleFunction } from '@chakra-ui/react';
import { ComponentStyleConfig } from '@chakra-ui/theme';

const variantSimple: SystemStyleFunction = (props) => {
  const { colorScheme: c } = props;

  return {
    table: {
      '&[data-hasxscroll="true"]': {
        th: {
          '*': {
            overflowWrap: 'unset'
          }
        },
        td: {
          overflowWrap: 'unset'
        }
      }
    },
    thead: {
      zIndex: 1,
      position: 'sticky',
      top: 0,
      background: 'white'
    },
    th: {
      color: 'gray.500',
      borderColor: `${c}.300`,
      py: 3.5,
      whiteSpace: 'pre-wrap',
      '*': {
        overflowWrap: 'anywhere'
      }
    },
    td: {
      borderBottom: '1px',
      borderColor: `${c}.300`,
      whiteSpace: 'pre-wrap',
      overflowWrap: 'anywhere'
    },
    tr: {
      _disabled: {
        bgColor: 'gray.100',
        color: 'gray.600',
        "&[data-events='false']": {
          pointerEvents: 'none'
        }
      },
      _selected: {
        bgColor: 'primary.100'
      }
    }
  };
};

const variants = {
  simple: variantSimple
};

export const Table: ComponentStyleConfig = {
  baseStyle: {
    th: {
      textTransform: 'none',
      letterSpacing: 'normal',
      cursor: 'default',
      "&[data-cansort='true']": {
        cursor: 'pointer'
      }
    }
  },
  sizes: {
    md: {
      th: {
        fontSize: 'md'
      },
      td: {
        fontSize: 'md'
      },
      tr: {
        minHeight: 12
      }
    }
  },
  variants,
  defaultProps: {}
};
