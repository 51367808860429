import { FormField, InputElement } from 'enum';
import { getAllEnumValues } from 'enum-for';
import {
  AdminRole,
  AdminStatus,
  OptionKey,
  PermissionResource
} from 'enum/api';
import { GetOptionsArg } from 'types/form';
import { generateOptionsFromSettings } from 'utils';
import * as yup from 'yup';

import { EditAdminFormData } from './EditAdmin.types';

export const editAdminFormSchema = {
  fields: {
    [FormField.Id]: {
      type: InputElement.Input,
      getPlaceholder: () => 'Id',
      translationKey: 'id',
      getIsDisabled: () => true,
      resource: PermissionResource.Admins
    },
    [FormField.UserName]: {
      type: InputElement.Input,
      translationKey: 'user_name',
      getPlaceholder: () => 'Username',
      resource: PermissionResource.Admins
    },
    [FormField.Password]: {
      type: InputElement.Password,
      translationKey: 'password',
      getPlaceholder: () => 'Password',
      resource: PermissionResource.AdminPassword
    },
    [FormField.UserType]: {
      type: InputElement.Select,
      translationKey: 'admin_role',
      getPlaceholder: () => 'User Type',
      getOptions: ({ t, settings }: GetOptionsArg) =>
        generateOptionsFromSettings(OptionKey.AdminRole, t, settings),
      resource: PermissionResource.AdminType
    },
    [FormField.Status]: {
      type: InputElement.Select,
      translationKey: 'status',
      getPlaceholder: () => 'Status',
      getOptions: ({ t, settings }: GetOptionsArg) =>
        generateOptionsFromSettings(OptionKey.AdminStatus, t, settings),
      resource: PermissionResource.Admins
    },
    [FormField.Countries]: {
      type: InputElement.MultiSelect,
      translationKey: 'countries',
      getPlaceholder: () => 'Country',
      getOptions: ({ t, settings }: GetOptionsArg) =>
        generateOptionsFromSettings(OptionKey.Country, t, settings),
      isSearchable: true,
      resource: PermissionResource.Admins
    },
    [FormField.Domains]: {
      type: InputElement.MultiSelect,
      translationKey: 'domains',
      getPlaceholder: () => 'Domains',
      getOptions: ({ t, settings }: GetOptionsArg) =>
        generateOptionsFromSettings(OptionKey.DomainName, t, settings)
    }
  },
  gridLayout: {
    templateAreas: `
    "${FormField.Id} ${FormField.UserType}"
    "${FormField.UserName} ${FormField.Status}"
    "${FormField.Password} ${FormField.Countries}"
    ". ${FormField.Domains}"
    `,
    gridTemplateColumns: '1fr 1fr',
    rowGap: 6,
    columnGap: 10
  }
} as const;

export const editAdminValidationSchema: yup.SchemaOf<EditAdminFormData> = yup
  .object()
  .shape(
    {
      [FormField.Id]: yup.string().required(),
      [FormField.UserName]: yup.string().required(),
      [FormField.Password]: yup
        .string()
        .defined()
        .when([FormField.Password], {
          is: (val: string) => !!val,
          then: (schema) =>
            schema.min(10, 'invalid_password').max(25, 'invalid_password'),
          otherwise: (schema) => schema
        }),
      [FormField.UserType]: yup
        .mixed()
        .required()
        .oneOf(getAllEnumValues(AdminRole)),
      [FormField.Status]: yup
        .mixed()
        .required()
        .oneOf(getAllEnumValues(AdminStatus)),
      [FormField.Countries]: yup
        .array(yup.string().required())
        .min(1, 'required'),
      [FormField.Domains]: yup.array(yup.string().required()).required().min(1)
    },
    [[FormField.Password, FormField.Password]]
  );
