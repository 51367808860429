import { Menu, MenuItem, MenuList } from '@chakra-ui/react';
import { Fragment, MouseEventHandler, ReactNode } from 'react';
import { Option } from 'types';

type CustomDropdownProps = {
  options: Option[];
  selectedValue?: string;
  onChange: (value: string) => void;
  children: (({ isOpen }: { isOpen: boolean }) => ReactNode) | ReactNode;
};

export const CustomDropdown = ({
  options,
  onChange,
  children,
  selectedValue
}: CustomDropdownProps): JSX.Element => {
  const onSelectItem: MouseEventHandler<HTMLDivElement> = (event) => {
    const { value } = event.target as HTMLButtonElement;

    if (value) onChange(value);
  };

  return (
    <Menu>
      {(menuProps) => (
        <Fragment>
          {typeof children === 'function' ? children(menuProps) : children}
          <MenuList py={0} onClick={onSelectItem}>
            {options.map(({ label, value }) => (
              <MenuItem
                key={value}
                value={value}
                padding={3}
                aria-selected={selectedValue === value}
              >
                {label}
              </MenuItem>
            ))}
          </MenuList>
        </Fragment>
      )}
    </Menu>
  );
};
