import { ICustomerNotes } from 'types/customer';

import { CustomerNotesResponse } from '../Customers.types';

export const serializeCustomerNotesData = (
  response: CustomerNotesResponse[]
): ICustomerNotes[] =>
  response.map((data) => {
    const { id, notedAt, text, notedBy, notedTo } = data;

    const { nickname, email, id: userId } = notedBy || notedTo;
    return {
      id,
      nickname,
      email,
      createdAt: notedAt,
      note: text,
      userId
    };
  });
