import { Spinner, Text, VStack } from '@chakra-ui/react';
import { Pagination } from 'components';
import { ModalType } from 'enum';
import { ImageStatus } from 'enum/api';
import {
  useAppDispatch,
  useAllGalleryImages,
  useCustomPagination
} from 'hooks';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useOutletContext } from 'react-router-dom';
import { ModalItem, openModal } from 'store/modal';
import { QueryFilters } from 'types';
import { DEFAULT_PAGINATION_LIMIT } from 'utils/constants';

import { AssetsContainer } from '../../Customers/CustomerInfo/CustomerPages/CustomerContent/components';

export const GalleryImagesContent = (): JSX.Element => {
  const [t] = useTranslation();
  const dispatch = useAppDispatch();
  const { contentFilters } = useOutletContext();

  const [queryFilters, setQueryFilters] = useState<QueryFilters>({
    search: null,
    searchBy: null,
    sort: {},
    filter: {},
    pagination: {
      page: 1,
      limit: DEFAULT_PAGINATION_LIMIT
    }
  });

  const { data, isLoading } = useAllGalleryImages(queryFilters, contentFilters);

  const { paginationProps } = useCustomPagination({
    queryFilters,
    setQueryFilters,
    totalItems: data?.totalItems
  });

  if (isLoading || !data) return <Spinner />;

  if (data && data.data.length === 0)
    return <Text>{t('messages.no_result_found')}</Text>;

  const onHandleAsset =
    (
      status: ModalItem<ModalType.UpdateCustomerGalleryImageStatus>['meta']['status']
    ) =>
    (id: string) => {
      dispatch(
        openModal({
          id: ModalType.UpdateCustomerGalleryImageStatus,
          meta: {
            imageId: id,
            status
          }
        })
      );
    };

  return (
    <VStack alignItems="stretch" spacing={7}>
      <AssetsContainer
        data={data.data}
        onDeleteAsset={onHandleAsset(ImageStatus.DeletedByAdmin)}
        onDeclineAsset={onHandleAsset(ImageStatus.DeclinedByAdmin)}
        onRestoreAsset={onHandleAsset(ImageStatus.Active)}
      />
      <Pagination {...paginationProps} />
    </VStack>
  );
};
