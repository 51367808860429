import { FormField, InputElement } from 'enum';
import { OptionKey } from 'enum/api';
import { GetOptionsArg } from 'types/form';
import { generateOptionsFromSettings } from 'utils';
import * as yup from 'yup';

import { ReplyCustomerFormData } from './ReplyCustomer.types';

export const replyCustomerFormSchema = {
  fields: {
    [FormField.SupportSourceType]: {
      type: InputElement.Select,
      translationKey: 'support_source_type',
      getPlaceholder: () => 'Select',
      getOptions: ({ t, settings }: GetOptionsArg) =>
        generateOptionsFromSettings(OptionKey.SupportSourceType, t, settings)
    },
    [FormField.Comment]: {
      type: InputElement.TextArea,
      translationKey: 'text_message',
      getPlaceholder: () => '',
      minHeight: 44,
      maxLength: 2000
    },
    [FormField.IsReplyFromCustomer]: {
      type: InputElement.Toggle,
      translationKey: 'reply_from_customer',
      getPlaceholder: () => 'Reply from customer'
    }
  },
  gridLayout: {
    templateAreas: `
      "${FormField.SupportSourceType}"
      "${FormField.Comment}"
      "${FormField.IsReplyFromCustomer}"
    `,
    rowGap: 6
  }
} as const;

export const replyCustomerValidationSchema: yup.SchemaOf<ReplyCustomerFormData> =
  yup.object({
    [FormField.SupportSourceType]: yup.string().required(),
    [FormField.Comment]: yup.string().required(),
    [FormField.IsReplyFromCustomer]: yup.boolean().defined()
  });
