import { ModalType } from 'enum';
import { useAppDispatch, useAppSelector } from 'hooks';
import { useCallback, useMemo, useRef } from 'react';
import {
  closeModal,
  makeSelectIsOpenModalByType,
  ModalItem
} from 'store/modal';

type ReturnUseModalActions<T extends ModalType> = {
  onClose: () => void;
} & {
  isOpen: boolean;
  meta?: ModalItem<T>['meta'];
};

export const useModalActions = <T extends ModalType = ModalType>(
  type: T,
  options: {
    onClose?: () => void;
  } = {}
): ReturnUseModalActions<T> => {
  const optionsRef = useRef(options);

  const selectIsOpenModalByType = useMemo(makeSelectIsOpenModalByType, []);

  const dispatch = useAppDispatch();

  const openModal = useAppSelector((state) =>
    selectIsOpenModalByType(state, type)
  );

  const onClose = useCallback(() => {
    dispatch(closeModal(type));

    optionsRef.current.onClose?.();
  }, [dispatch, type]);

  return {
    isOpen: !!openModal,
    onClose,
    meta: openModal?.meta
  };
};
