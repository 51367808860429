import { createColumnHelper } from '@tanstack/react-table';
import { ParameterOption } from 'api';
import { TruncateText } from 'components';
import { TableFilterSchema } from 'containers';
import { InputElement } from 'enum';
import { OptionKey } from 'enum/api';
import {
  StatusCell,
  RestrictionTypeCell,
  JsonCell,
  TextEditCell,
  EditableCountryList
} from 'pages/TableManagement/components';
import { TFunction } from 'react-i18next';

const columnHelper = createColumnHelper<ParameterOption>();

export const parameterColumns = [
  columnHelper.accessor('type', {
    cell: ({ getValue }) => (
      <TruncateText maxWidth="md">{getValue()}</TruncateText>
    ),
    header: ({ table }) => table.options.meta?.t('keywords.type'),

    enableSorting: true
  }),
  columnHelper.accessor('title', {
    cell: ({ getValue }) => (
      <TruncateText maxWidth="md">{getValue()}</TruncateText>
    ),
    header: ({ table }) => table.options.meta?.t('keywords.title'),
    enableSorting: true
  }),
  columnHelper.accessor('defaultValue', {
    cell: ({ getValue, row, table }) => (
      <TextEditCell
        maxWidth="md"
        editModalHeader={table.options.meta?.t('actions.edit_default_value')}
        updateEntity={async (data) =>
          await table.options.meta?.updateEntity?.(row.original.id, {
            defaultValue: data ?? undefined
          })
        }
      >
        {getValue()}
      </TextEditCell>
    ),
    header: ({ table }) => table.options.meta?.t('keywords.default_value'),
    enableSorting: false
  }),
  columnHelper.accessor('status', {
    cell: ({ row, table }) => (
      <StatusCell
        status={row.original.status}
        updateEntity={async (data) =>
          await table.options.meta?.updateEntity?.(row.original.id, {
            status: data
          })
        }
      />
    ),
    header: ({ table }) => table.options.meta?.t('keywords.status'),
    enableSorting: false
  }),
  columnHelper.accessor('countryRestrictionType', {
    cell: ({ row, table }) => (
      <RestrictionTypeCell
        updateEntity={async (data) =>
          await table.options.meta?.updateEntity?.(row.original.id, {
            countryRestrictionType: data
          })
        }
        status={row.original.countryRestrictionType}
        isRestrictedCountries={!!row.original.restrictedCountries?.length}
      />
    ),
    header: ({ table }) => table.options.meta?.t('keywords.restriction_type'),
    enableSorting: false
  }),
  columnHelper.accessor('restrictedCountries', {
    cell: ({ getValue, table, row }) => (
      <EditableCountryList
        countryCodes={getValue()}
        editModalHeader={table.options.meta?.t('actions.edit_countries')}
        updateEntity={async (data) =>
          await table.options.meta?.updateEntity?.(row.original.id, {
            restrictedCountries: data
          })
        }
      />
    ),
    header: ({ table }) =>
      table.options.meta?.t('keywords.restricted_countries'),
    enableSorting: false
  }),

  columnHelper.accessor('meta', {
    cell: ({ getValue, row, table }) => (
      <JsonCell
        value={getValue()}
        editModalHeader={table.options.meta?.t('actions.edit_meta')}
        updateEntity={async (data) =>
          await table.options.meta?.updateEntity?.(row.original.id, {
            meta: data
          })
        }
      />
    ),
    header: ({ table }) => table.options.meta?.t('keywords.meta'),
    enableSorting: false
  })
];

export const tableFilters: TableFilterSchema[] = [
  {
    type: InputElement.Checkbox,
    getLabel: (t) => t('keywords.type'),
    optionKey: OptionKey.ParameterOptionType,
    updateOutput: (_, value) => ({
      key: 'types',
      value
    })
  },
  {
    type: InputElement.Checkbox,
    getLabel: (t) => t('keywords.status'),
    optionKey: OptionKey.ParameterOptionActivityStatus,
    updateOutput: (_, value) => ({
      key: 'statuses',
      value
    })
  }
];

export const getSearchOptions = (t: TFunction) => [
  {
    label: t('keywords.title'),
    value: 'title'
  },
  {
    label: t('keywords.default_value'),
    value: 'defaultValue'
  }
];
