import { Grid, GridItem, IconButton, Text, VStack } from '@chakra-ui/react';
import { AddButton, EditIcon } from 'components';
import {
  FormField,
  ModalType,
  ParameterType,
  PermissionAction,
  PermissionResource
} from 'enum';
import {
  useAppDispatch,
  useParameterTypeTranslation,
  usePermissions
} from 'hooks';
import { getName } from 'i18n-iso-countries';
import { RemoveButton } from 'pages/GeneralSettings/components';
import { Fragment } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { openModal } from 'store';

import {
  GeneralSettingsFormData,
  GeneralSettingsFormField
} from '../../FormContent.types';
import { useSettingsFormFields } from '../../useSettingsFormFields';

import {
  getLabelsByParameterTypeByIds,
  getIsConfigItemsExists,
  getInitialFieldsState
} from './RequestChatAccess.utils';

export const RequestChatAccess = (): JSX.Element => {
  const [t, { language }] = useTranslation();
  const dispatch = useAppDispatch();

  const { getAllTranslations } = useParameterTypeTranslation();

  const formItemName =
    GeneralSettingsFormField.RequestChatAccessFromAnotherCountry;

  const { fields, append, update, replace, handleRemove } =
    useSettingsFormFields({
      name: formItemName,
      getInitialFieldsState
    });

  const { hasAccess: hasCreateAccess } = usePermissions({
    resource: PermissionResource.RequestChatAccess,
    actions: PermissionAction.Create
  });
  const { hasAccess: hasReadAccess } = usePermissions({
    resource: PermissionResource.RequestChatAccess,
    actions: PermissionAction.Read
  });
  const { hasAccess: hasUpdateAccess } = usePermissions({
    resource: PermissionResource.RequestChatAccess,
    actions: PermissionAction.Update
  });
  const { hasAccess: hasDeleteAccess } = usePermissions({
    resource: PermissionResource.RequestChatAccess,
    actions: PermissionAction.Delete
  });

  const editConfigItem = (
    field: GeneralSettingsFormData[typeof formItemName][number],
    index: number
  ) => {
    dispatch(
      openModal({
        id: ModalType.RequiredCustomersChatAccessRequest,
        meta: {
          onUpdateData(data) {
            if (data.isEverybodyCanMessageToEverybody) {
              replace([data]);
            } else {
              update(index, data);
            }
          },
          initialData: field
        }
      })
    );
  };

  const addNewConfigItem = () => {
    dispatch(
      openModal({
        id: ModalType.RequiredCustomersChatAccessRequest,
        meta: {
          onUpdateData(data) {
            if (data.isEverybodyCanMessageToEverybody) {
              replace([data]);
            } else {
              append(data);
            }
          },
          initialData: {
            ...getInitialFieldsState(),
            [FormField.EverybodyCanMessageToEverybody]: false
          }
        }
      })
    );
  };

  const getDescription = (
    field: GeneralSettingsFormData[typeof formItemName][number]
  ) =>
    !getIsConfigItemsExists(field) ? (
      <Trans
        i18nKey="attribute.description.everybody_can_message_to_everybody"
        components={{
          b: <b />
        }}
      />
    ) : (
      <Trans
        i18nKey="attribute.description.customer_required_send_chat_access_req_other_customer"
        values={{
          customerTypes: getLabelsByParameterTypeByIds(
            ParameterType.CustomerType,
            field.customerTypes,
            getAllTranslations
          ).join(', '),
          customerTypesFromAnotherCountry: getLabelsByParameterTypeByIds(
            ParameterType.CustomerType,
            field.otherCustomerTypes,
            getAllTranslations
          ).join(', '),
          genders: getLabelsByParameterTypeByIds(
            ParameterType.Gender,
            field.genders,
            getAllTranslations
          ).join(', '),
          gendersFromAnotherCountry: getLabelsByParameterTypeByIds(
            ParameterType.Gender,
            field.otherGenders,
            getAllTranslations
          ).join(', '),
          programTypes: getLabelsByParameterTypeByIds(
            ParameterType.ProgramType,
            field.programTypes,
            getAllTranslations
          ).join(', '),
          programTypesFromAnotherCountry: getLabelsByParameterTypeByIds(
            ParameterType.ProgramType,
            field.otherProgramTypes,
            getAllTranslations
          ).join(', '),
          countries: field.countries
            .map((code) => getName(code, language))
            .join(', ')
        }}
        components={{
          b: <b />
        }}
      />
    );

  const canAddNewConfigItem = fields.every(
    ({ isEverybodyCanMessageToEverybody }) => !isEverybodyCanMessageToEverybody
  );

  return (
    <Fragment>
      <GridItem>
        {t(
          'attribute.description.require_request_chat_access_from_another_country'
        )}
      </GridItem>
      <GridItem>
        {hasReadAccess && (
          <Fragment>
            <VStack alignItems="stretch" spacing={10}>
              {fields.map((field, index) => (
                <Grid
                  key={field.id}
                  templateColumns="1fr auto auto"
                  columnGap={3}
                >
                  <GridItem>
                    <Text>{getDescription(field)}</Text>
                  </GridItem>
                  <GridItem>
                    {hasUpdateAccess && (
                      <IconButton
                        icon={<EditIcon fontSize="2xl" />}
                        aria-label="edit config items"
                        variant="ghostGray"
                        color="gray.500"
                        onClick={() => editConfigItem(field, index)}
                      />
                    )}
                  </GridItem>
                  <GridItem>
                    {hasDeleteAccess && (
                      <RemoveButton
                        onClick={() => handleRemove(index)}
                        marginTop={0}
                      />
                    )}
                  </GridItem>
                </Grid>
              ))}
            </VStack>
            {canAddNewConfigItem && hasCreateAccess && (
              <AddButton
                alignSelf="flex-start"
                onClick={addNewConfigItem}
                marginTop={4}
              >
                {t('actions.add_item')}
              </AddButton>
            )}
          </Fragment>
        )}
      </GridItem>
    </Fragment>
  );
};
