import { FormField, InputElement } from 'enum';
import { OptionKey, ParameterType } from 'enum/api';
import { GetIsDisabledFnArg, GetOptionsArg } from 'types/form';
import { generateOptionsFromSettings } from 'utils';
import * as yup from 'yup';

import { RestrictionViewCustomersFormData } from './RestrictionViewCustomers.types';

export const restrictionViewCustomersFormSchema = {
  fields: {
    [FormField.CustomerType]: {
      type: InputElement.Select,
      translationKey: 'customer_type_of_my_country',
      getPlaceholder: () => 'Customer type',
      getOptions: ({ t, settings }: GetOptionsArg) =>
        generateOptionsFromSettings(ParameterType.CustomerType, t, settings),
      getIsDisabled: ({ formValues }: GetIsDisabledFnArg) =>
        formValues[FormField.EverybodyCanSeeEverybody]
    },
    [FormField.AppliedForFreeProgramType]: {
      type: InputElement.Toggle,
      translationKey: 'apply_for_free_program_type',
      getIsDisabled: ({ formValues }: GetIsDisabledFnArg) =>
        formValues[FormField.EverybodyCanSeeEverybody]
    },
    [FormField.OtherCustomerType]: {
      type: InputElement.Select,
      translationKey: 'customer_type_of_another_country',
      getPlaceholder: () => 'Customer type',
      getOptions: ({ t, settings }: GetOptionsArg) =>
        generateOptionsFromSettings(ParameterType.CustomerType, t, settings),
      getIsDisabled: ({ formValues }: GetIsDisabledFnArg) =>
        formValues[FormField.EverybodyCanSeeEverybody]
    },
    [FormField.Countries]: {
      type: InputElement.MultiSelect,
      translationKey: 'countries',
      getPlaceholder: () => 'Country',
      isSearchable: true,
      getOptions: ({ t, settings }: GetOptionsArg) =>
        generateOptionsFromSettings(OptionKey.Country, t, settings),
      getIsDisabled: ({ formValues }: GetIsDisabledFnArg) =>
        formValues[FormField.EverybodyCanSeeEverybody]
    },
    [FormField.EverybodyCanSeeEverybody]: {
      type: InputElement.Toggle,
      translationKey: 'everybody_can_see_everybody'
    }
  },
  gridLayout: {
    templateAreas: `
    "${FormField.CustomerType} cant-see ${FormField.OtherCustomerType}"
    "${FormField.AppliedForFreeProgramType} . ."
    "${FormField.Countries} ${FormField.Countries} ${FormField.Countries}"
    "${FormField.EverybodyCanSeeEverybody} ${FormField.EverybodyCanSeeEverybody} ${FormField.EverybodyCanSeeEverybody}"
  `,
    templateColumns: '1fr auto 1fr',
    columnGap: 3,
    rowGap: 6,
    textAlign: 'left'
  }
} as const;

export const restrictionViewCustomersValidationSchema: yup.SchemaOf<RestrictionViewCustomersFormData> =
  yup.object({
    [FormField.CustomerType]: yup
      .mixed()
      .when(FormField.EverybodyCanSeeEverybody, {
        is: true,
        then: (schema) => schema.optional().nullable(),
        otherwise: (schema) => schema.required()
      }),
    [FormField.OtherCustomerType]: yup
      .mixed()
      .when(FormField.EverybodyCanSeeEverybody, {
        is: true,
        then: (schema) => schema.optional().nullable(),
        otherwise: (schema) => schema.required()
      }),
    [FormField.Countries]: yup
      .array(yup.string().required())
      .when(FormField.EverybodyCanSeeEverybody, {
        is: true,
        then: (schema) => schema.max(0),
        otherwise: (schema) => schema.min(1)
      }),
    [FormField.EverybodyCanSeeEverybody]: yup.boolean().required(),
    [FormField.AppliedForFreeProgramType]: yup.boolean().optional()
  });
