import { differenceInYears, format } from 'date-fns';
import { Period } from 'enum';
import { i18n } from 'i18n';
import { getName } from 'i18n-iso-countries';
import identity from 'lodash/identity';
import { TFunction } from 'react-i18next';
import { DateString } from 'types';

export const getDateFormattedString = ({
  date,
  t = i18n.t,
  timeFormat = '24h',
  toUTCFormat
}: {
  t?: TFunction;
  date: Date | DateString;
  timeFormat?: '24h' | '12h';
  toUTCFormat?: boolean;
}) => {
  const serializedDate = typeof date === 'string' ? new Date(date) : date;

  return t('keywords.registration_at', {
    date: format(serializedDate, 'dd.MM.yyyy'),
    time:
      timeFormat === '12h'
        ? format(serializedDate, 'h aaa')
        : toUTCFormat
        ? serializedDate.toISOString().substring(11, 16)
        : format(serializedDate, 'HH:mm')
  });
};

export const sortByDate = <T extends object>(
  data: T[],
  fn: (data: T) => Date | DateString,
  isAsc = false
): T[] =>
  data.sort((a, b) => {
    const multiply = isAsc ? -1 : 1;

    const dateA = fn(a);
    const dateB = fn(b);

    if (typeof dateA === 'string' && typeof dateB === 'string') {
      return multiply * (new Date(dateA).getTime() - new Date(dateB).getTime());
    }

    if (dateA instanceof Date && dateB instanceof Date) {
      return multiply * (dateA.getTime() - dateB.getTime());
    }

    throw new Error('Incorrect types provided');
  });

export const getAgeByBirthday = (date: Date | DateString) => {
  const birthDate = typeof date === 'string' ? new Date(date) : date;

  return differenceInYears(new Date(), birthDate);
};

export const getAddress = ({
  country,
  state,
  city,
  language,
  separator = ', '
}: {
  country: string;
  state: string | null;
  city: string;
  language: string;
  separator?: string;
}): string =>
  [getName(country, language), state, city].filter(identity).join(separator);

export const formatChartAxisDateByPeriod = (
  value: DateString | number,
  period: Period
) => {
  switch (period) {
    case Period.Today:
    case Period.Yesterday:
      return format(new Date(value), 'HH:mm');
    case Period.Week:
    case Period.LastMonth:
    case Period.CurrentMonth:
      return format(new Date(value), 'dd');
    case Period.Season:
    case Period.Year:
      return format(new Date(value), 'MMMM');
    case Period.AllTime:
    default:
      return format(new Date(value), 'yyyy');
  }
};

export const convertRemToPixels = (
  rem: number | string,
  fontSize?: number
): {
  pxValue: number;
  px: string;
} => {
  const htmlFontSize =
    fontSize || parseFloat(getComputedStyle(document.documentElement).fontSize);

  const remValue = typeof rem === 'string' ? parseFloat(rem) : rem;

  const pxValue = remValue * htmlFontSize;

  return {
    pxValue,
    px: `${pxValue}px`
  };
};

export const convertCmToFtIn = (centimeters: number | string) => {
  const cmNum = Number(centimeters) || 0;

  const inches = Math.round(cmNum / 2.54);

  return {
    ft: Math.trunc(inches / 12),
    inch: inches % 12
  };
};
