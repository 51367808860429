const element =
  typeof document !== 'undefined' ? document.createElement('div') : null;

export const getPlaceDetails = async (
  request: google.maps.places.PlaceDetailsRequest
): Promise<google.maps.places.PlaceResult | null> => {
  if (!element) {
    return null;
  }

  return new Promise((resolve, reject) => {
    new google.maps.places.PlacesService(element).getDetails(
      { ...request, language: 'en' },
      (results, status) => {
        if (status === google.maps.places.PlacesServiceStatus.OK) {
          resolve(results);
        } else {
          reject(status);
        }
      }
    );
  });
};
