import { chakra } from '@chakra-ui/react';
import { ContainerProps, GroupBase } from 'react-select';

import { getAttributeProperty, useStyles } from '../LocationDropdown.utils';

export const SelectContainer = <
  Option,
  IsMulti extends boolean,
  Group extends GroupBase<Option>
>(
  props: ContainerProps<Option, IsMulti, Group>
): JSX.Element => {
  const { children, innerProps } = props;

  const styles = useStyles();

  return (
    <chakra.div
      __css={styles.rootContainer}
      {...innerProps}
      {...getAttributeProperty(props)}
    >
      {children}
    </chakra.div>
  );
};
