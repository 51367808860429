import { Box, VStack } from '@chakra-ui/react';
import { AllCustomerListResponse } from 'api';
import { Search, Table } from 'components';
import { ModalContainer } from 'containers';
import { ModalType } from 'enum';
import {
  useAllCustomerList,
  useCustomerList,
  useModalActions,
  useTableQueryController,
  useTableRowSelection
} from 'hooks';
import { useTranslation } from 'react-i18next';
import { ParamType } from 'types';

import { customerColumns, getSearchOptions } from './CustomerFullList.utils';

export const CustomerFullList = (): JSX.Element => {
  const [t] = useTranslation();

  const {
    queryFilters,
    action: { onChangeSort, onChangePagination, onResetFilters, onChangeSearch }
  } = useTableQueryController();

  const {
    tableInstance,
    rowSelection,
    setRowSelection,
    getSelectedRowDataById
  } = useTableRowSelection<AllCustomerListResponse>();

  const { isOpen, onClose, meta } = useModalActions(
    ModalType.CustomerFullList,
    {
      onClose: () => {
        setRowSelection({});
        onResetFilters();
      }
    }
  );

  const { data: filteredData } = useCustomerList({
    queryFilters,
    enabled: isOpen && meta?.shouldShowFilters
  });

  const { data: allData } = useAllCustomerList({
    queryFilters,
    countryFilter: meta?.countryFilter,
    enabled: isOpen && !meta?.shouldShowFilters
  });

  const data = filteredData || allData;

  const actions: ParamType<typeof ModalContainer, 'footerButtons'> = [
    {
      text: t('actions.cancel'),
      variant: 'ghostGray'
    },
    {
      text: t('actions.choose'),
      isPreventClose: true,
      onClick: (onClose) => {
        const [rowId] = Object.keys(rowSelection);

        if (rowId && meta) {
          const { id, nickname, email } = getSelectedRowDataById(rowId);

          meta.onChooseCustomer({ id, nickname, email });
          onClose();
        }
      }
    }
  ];

  return (
    <ModalContainer
      header={meta?.title ?? t('attribute.title.customers')}
      footerButtons={actions}
      isOpen={isOpen}
      onClose={onClose}
      modalBodyProps={{
        display: 'flex'
      }}
      size="3xl"
    >
      <VStack width="100%" alignItems="flex-start">
        {meta?.shouldShowFilters && (
          <Box maxWidth="24rem">
            <Search options={getSearchOptions(t)} onChange={onChangeSearch} />
          </Box>
        )}
        {data && (
          <Box minHeight="15rem">
            <Table
              instance={tableInstance}
              data={data.data}
              columns={customerColumns}
              onChangeSort={onChangeSort}
              onChangePagination={onChangePagination}
              pagination={queryFilters.pagination}
              totalItems={data.totalItems}
              tableOptions={{
                enableRowSelection: true,
                enableMultiRowSelection: false,
                getRowId: (row) => row.id
              }}
              rowSelection={rowSelection}
              onRowSelectionChange={setRowSelection}
            />
          </Box>
        )}
      </VStack>
    </ModalContainer>
  );
};
