import {
  Button,
  Checkbox,
  Grid,
  GridItem,
  HStack,
  Text
} from '@chakra-ui/react';
import {
  AddButton,
  DownloadIcon,
  Search,
  Table,
  TruncateText
} from 'components';
import { ContentContainer, TableFilters } from 'containers';
import { CustomerCSVFields, ModalType } from 'enum';
import { AccountStatus } from 'enum/api';
import {
  useAppDispatch,
  useCustomerList,
  useTableQueryController
} from 'hooks';
import { useMemo, useState, Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import { openModal } from 'store/modal';

import {
  columns,
  getCSVOptions,
  getSearchOptions,
  tableFilters
} from './Customers.utils';

export const Customers = (): JSX.Element => {
  const [t] = useTranslation();
  const dispatch = useAppDispatch();
  const [shouldSearchStartsWith, setShouldSearchStartsWith] = useState(false);

  const {
    queryFilters,
    action: {
      onChangeFilters,
      onChangeSort,
      onChangePagination,
      onChangeSearch
    }
  } = useTableQueryController();

  const { data } = useCustomerList({ queryFilters, shouldSearchStartsWith });

  const customersData = useMemo(
    () =>
      data?.data.map(({ email, utmParams, ...restData }) => ({
        ...restData,
        ...(!utmParams
          ? {
              email
            }
          : {
              email: (
                <Fragment>
                  <Text marginBottom="5px">{email}</Text>
                  <TruncateText
                    maxWidth="150px"
                    textOverflow="ellipsis"
                    fontSize="x-small"
                    visibleLines={1}
                  >
                    {utmParams}
                  </TruncateText>
                </Fragment>
              )
            })
      })),
    [data]
  );

  const searchOptions = useMemo(() => getSearchOptions(t), [t]);

  const handleCreateAccount = () => {
    dispatch(
      openModal({
        id: ModalType.CreateCustomerAccount
      })
    );
  };

  const handleDownloadCSV = () => {
    const options = getCSVOptions(t);

    dispatch(
      openModal({
        id: ModalType.DownloadCustomerCSV,
        meta: {
          options,
          defaultValues: [
            CustomerCSVFields.Nickname,
            CustomerCSVFields.Email,
            CustomerCSVFields.CustomerRole,
            CustomerCSVFields.CreatedAt
          ]
        }
      })
    );
  };

  return (
    <ContentContainer height="100%">
      <Grid
        templateAreas={`"search actions"
                       "filters filters"
                       "table table"
       `}
        templateColumns="29rem 1fr"
        templateRows="min-content min-content 1fr"
        rowGap={6}
        height="100%"
      >
        <GridItem area="search">
          <HStack>
            <Search options={searchOptions} onChange={onChangeSearch} />
            <Checkbox
              isChecked={shouldSearchStartsWith}
              onChange={() =>
                setShouldSearchStartsWith(!shouldSearchStartsWith)
              }
              paddingLeft="20px"
            >
              {t('keywords.starts_with')}
            </Checkbox>
          </HStack>
        </GridItem>
        <GridItem area="actions" justifySelf="flex-end">
          <HStack spacing={5}>
            <AddButton onClick={handleCreateAccount}>
              {t('actions.create_customer_account')}
            </AddButton>
            <Button
              variant="link"
              leftIcon={<DownloadIcon boxSize="0.6875rem" />}
              iconSpacing="0.6875rem"
              onClick={handleDownloadCSV}
            >
              {t('actions.download_csv_file')}
            </Button>
          </HStack>
        </GridItem>
        <GridItem area="filters">
          <TableFilters filters={tableFilters} onChange={onChangeFilters} />
        </GridItem>
        {data && customersData && (
          <GridItem area="table" overflow="hidden">
            <Table
              //@ts-ignore
              data={customersData}
              columns={columns}
              onChangeSort={onChangeSort}
              onChangePagination={onChangePagination}
              pagination={queryFilters.pagination}
              totalItems={data.totalItems}
              getIsDisabled={(row) =>
                row.original.accountStatus === AccountStatus.Deactivated
              }
              getIsAttentioned={(row) => !!row.original.suspicious}
              canInteractWithDisabled
            />
          </GridItem>
        )}
      </Grid>
    </ContentContainer>
  );
};
