import { Dropdown } from 'components';
import { DropdownType, SystemLanguage } from 'enum';
import { getAllEnumValues } from 'enum-for';

type Props = {
  updateEntity: (data: SystemLanguage) => Promise<void>;
  value: SystemLanguage;
};

export const LanguageCell = ({ value, updateEntity }: Props): JSX.Element => {
  const options = getAllEnumValues(SystemLanguage).map((value) => ({
    label: value,
    value
  }));

  return (
    <Dropdown
      type={DropdownType.Select}
      options={options}
      isControllable
      values={[value]}
      onChange={([value]) => updateEntity(value as SystemLanguage)}
      menuButtonProps={{
        maxWidth: '5rem'
      }}
    />
  );
};
