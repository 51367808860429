import { yupResolver } from '@hookform/resolvers/yup';
import { ModalContainer } from 'containers';
import { FormConstructor } from 'containers';
import { ErrorCode, ModalType } from 'enum';
import {
  useFormValidation,
  useUpdateArticle,
  useModalActions,
  useChakraToast
} from 'hooks';
import { snakeCase } from 'lodash';
import { useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { ParamType } from 'types';
import { serializeAxiosError } from 'utils';

import { EditArticleFormData } from './EditArticle.types';
import {
  editArticleFormSchema,
  editArticleValidationSchema,
  getDefaultFormValues,
  serializeRequestData
} from './EditArticle.utils';

export const EditArticle = (): JSX.Element => {
  const [t] = useTranslation();
  const toast = useChakraToast();

  const { isOpen, onClose, meta } = useModalActions(ModalType.EditArticle);

  const form = useForm<EditArticleFormData>({
    mode: 'onSubmit',
    resolver: yupResolver(editArticleValidationSchema),
    defaultValues: getDefaultFormValues(meta?.article)
  });

  const { handleSubmit, reset } = form;

  useEffect(() => {
    if (meta?.article) {
      reset({
        ...getDefaultFormValues(meta.article)
      });
    }
  }, [reset, meta]);

  const { mutate, isLoading } = useUpdateArticle();

  const isFormFilled = useFormValidation<EditArticleFormData>(
    form,
    editArticleValidationSchema
  );

  const onSubmit = handleSubmit((data) => {
    mutate(
      {
        articleId: meta?.article.id || '',
        ...serializeRequestData(data)
      },
      {
        onSuccess: onClose,
        onError: (error) => {
          const { errorCode } = serializeAxiosError(error);

          if (errorCode === ErrorCode.ArticleDuplicate) {
            toast({
              title: t(`errors.codes.${snakeCase(errorCode)}`),
              status: 'error'
            });
          } else {
            toast({
              status: 'error',
              title: t('errors.invalid_form'),
              description: error.message
            });
          }
        }
      }
    );
  });

  const actions: ParamType<typeof ModalContainer, 'footerButtons'> = [
    {
      text: t('actions.cancel'),
      variant: 'ghostGray',
      disabled: isLoading
    },
    {
      text: t('actions.save'),
      colorScheme: 'secondary',
      isLoading,
      isPreventClose: true,
      isDisabled: !isFormFilled,
      onClick: () => {
        onSubmit();
      }
    }
  ];

  return (
    <ModalContainer
      header={t('attribute.title.edit_article')}
      footerButtons={actions}
      isOpen={isOpen}
      onClose={onClose}
      size="5xl"
    >
      <FormProvider {...form}>
        <FormConstructor formSchema={editArticleFormSchema} />
      </FormProvider>
    </ModalContainer>
  );
};
