import { createAction } from '@reduxjs/toolkit';

import { AppAction, AppState } from './app.types';

export const updateFilter = createAction(
  AppAction.UpdateFilter,
  (filters: Partial<AppState['filters']>) => ({
    payload: filters
  })
);
