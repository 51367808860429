import { ENDPOINTS, UpdateModerationItemStatusRequestData } from 'api';
import axios, { AxiosResponse } from 'axios';

export const fetchUpdateCommentStatus = ({
  commentId,
  ...body
}: UpdateModerationItemStatusRequestData & {
  commentId: string;
}) =>
  axios.patch<
    unknown,
    AxiosResponse<unknown>,
    UpdateModerationItemStatusRequestData
  >(`${ENDPOINTS.MODERATION_COMMENT}/${commentId}/status`, body);
