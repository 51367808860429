import { ENDPOINTS, ReportCustomerListResponse, WithPagination } from 'api';
import { serializeBodyFilters } from 'api/api.utils';
import axios from 'axios';
import { ReportStatus, ReportType } from 'enum/api';
import { GlobalFilters, QueryFilters } from 'types';

const photoUrl =
  'https://images.pexels.com/photos/220453/pexels-photo-220453.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const fakedData: ReportCustomerListResponse[] = Array.from({
  length: 25
}).map<ReportCustomerListResponse>((_, i) => ({
  id: `report_${i}`,
  createdAt: new Date().toISOString(),
  type: ReportType.Gallery,
  reason: '1234',
  meta: null,
  status: ReportStatus.Open,
  hasRead: !!(i % 4),
  comment: 'Some comment',
  imageUrl: photoUrl,
  reporter: {
    id: 'id1',
    nickname: 'nickname1',
    displayedNickname: 'nickname1WithEmoji',
    email: 'email1',
    avatar: null
  },
  reported:
    i % 5
      ? {
          id: 'id2',
          nickname: 'nickname2',
          displayedNickname: 'nickname1WithEmoji',
          email: 'email2',
          avatar: null,
          hasAdminAccess: true
        }
      : null
}));

export const fetchCustomerReportList = async ({
  queryFilters,
  globalFilters
}: {
  queryFilters: QueryFilters;
  globalFilters: GlobalFilters;
}): Promise<WithPagination<ReportCustomerListResponse[]>> => {
  const { data } = await axios.post<
    WithPagination<ReportCustomerListResponse[]>
  >(
    ENDPOINTS.REPORT_CUSTOMER_LIST,
    serializeBodyFilters({ queryFilters, globalFilters })
  );

  return data;
};
