import { UsersWithEachProgramTypeChartData } from 'api';
import { CustomStackedBarChartSettings, TableFilterSchema } from 'containers';
import { BarChartLayout, GenderTruncated, InputElement } from 'enum';
import { OptionKey, ParameterType } from 'enum/api';
import { getTranslationByOptionKey } from 'utils';

export const CHART_SETTINGS: CustomStackedBarChartSettings<UsersWithEachProgramTypeChartData> =
  {
    colors: ['blue.400', 'pink.300', 'green.500'],
    transformChartData: ({ type, value }) => ({
      id: type,
      value
    }),
    getTitleLabel: ({ id, t, settings }) =>
      t('keywords.gender_users', {
        gender:
          id === GenderTruncated.Other
            ? t('keywords.other')
            : getTranslationByOptionKey({
                t,
                value: id,
                optionKey: ParameterType.Gender,
                settings
              })
      }),
    getLabel: ({ id: type, t, settings }) =>
      getTranslationByOptionKey({
        t,
        value: type,
        optionKey: ParameterType.ProgramType,
        settings
      }),
    layout: BarChartLayout.Vertical,
    getBarColor: (position, baseColor) => ({
      color: baseColor,
      filter: `brightness(${100 - position * 20}%)`
    })
  };

export const chartFilters: TableFilterSchema[] = [
  {
    type: InputElement.Checkbox,
    getLabel: (t) => t('keywords.subscription_type'),
    optionKey: OptionKey.SubscriptionType,
    updateOutput: (_, value) => ({
      key: 'subscriptionTypes',
      value
    })
  }
];
