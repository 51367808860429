import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { SortingState } from '@tanstack/react-table';
import { QueryFilters } from 'types';

const getInitialQueryFilterState = (): QueryFilters => ({
  search: null,
  searchBy: null,
  sort: {},
  filter: {},
  pagination: {
    limit: 50,
    page: 1
  }
});

export const tableSlice = createSlice({
  name: 'table',
  initialState: getInitialQueryFilterState(),
  reducers: {
    setSearch(
      state,
      action: PayloadAction<Pick<QueryFilters, 'search' | 'searchBy'>>
    ) {
      const { search, searchBy } = action.payload;

      state.search = search;
      state.searchBy = searchBy;
    },
    setSort(state, action: PayloadAction<SortingState>) {
      const sort = action.payload.reduce((acc, cur) => {
        acc[cur.id] = cur.desc ? 'desc' : 'asc';

        return acc;
      }, {} as QueryFilters['sort']);

      state.sort = sort;
      state.pagination.page = 1;
    },
    setFilters(
      state,
      action: PayloadAction<{
        filters: QueryFilters['filter'];
        fixedFilter?: QueryFilters['filter'];
      }>
    ) {
      const { filters, fixedFilter } = action.payload;

      state.filter = fixedFilter
        ? {
            ...filters,
            ...fixedFilter
          }
        : filters;

      state.pagination.page = 1;
    },
    setPagination(state, action: PayloadAction<QueryFilters['pagination']>) {
      state.pagination = action.payload;
    },
    reset(_, action: PayloadAction<QueryFilters>) {
      return action.payload;
    }
  }
});
