import { extendTheme, withDefaultColorScheme } from '@chakra-ui/react';
import { CalendarDefaultTheme } from '@uselessdev/datepicker';
import { dropdownTheme } from 'components';

import * as components from './components';
import { COLORS, SIZES, SPACE, textStyles, TYPOGRAPHY } from './foundations';

export const theme = extendTheme(
  CalendarDefaultTheme,
  {
    initialColorMode: 'light',
    useSystemColorMode: false,
    colors: COLORS,
    sizes: SIZES,
    space: SPACE,
    ...TYPOGRAPHY,
    textStyles,
    components: { ...components, Dropdown: dropdownTheme }
  },
  withDefaultColorScheme({
    colorScheme: 'primary'
  })
);
