import { Box, Flex, Spinner } from '@chakra-ui/react';
import { AddButton, Table } from 'components';
import { ModalType } from 'enum';
import {
  useAppDispatch,
  useCustomerIdFromUrl,
  useCustomerReportsByUser,
  useTableQueryController
} from 'hooks';
import { useTranslation } from 'react-i18next';
import { openModal } from 'store/modal';

import { reportsColumns } from '../Reports.utils';

export const CustomerReport = (): JSX.Element => {
  const [t] = useTranslation();
  const dispatch = useAppDispatch();

  const customerId = useCustomerIdFromUrl();

  const {
    queryFilters,
    action: { onChangeSort, onChangePagination }
  } = useTableQueryController();

  const { data } = useCustomerReportsByUser({ customerId, queryFilters });

  const handleCreateTicket = () => {
    dispatch(
      openModal({
        id: ModalType.CreateTicket,
        meta: {}
      })
    );
  };

  if (!data) return <Spinner />;

  return (
    <Box>
      <Flex justifyContent="flex-end">
        <AddButton onClick={handleCreateTicket}>
          {t('actions.create_ticket')}
        </AddButton>
      </Flex>
      <Table
        data={data.data}
        columns={reportsColumns}
        onChangeSort={onChangeSort}
        onChangePagination={onChangePagination}
        pagination={queryFilters.pagination}
        totalItems={data.totalItems}
      />
    </Box>
  );
};
