import {
  Flex,
  Grid,
  GridItem,
  Heading,
  HStack,
  Skeleton,
  Text
} from '@chakra-ui/react';
import { ContentContainer, GaugeChart, TableFilters } from 'containers';
import { useActiveUsers } from 'hooks';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ChartFilters } from 'types';

import { chartFilters, CHART_SETTINGS } from './ActiveUsers.utils';
import { useActiveUsersChartData } from './useActiveUsersChartData';

export const ActiveUsers = (): JSX.Element => {
  const [t] = useTranslation();

  const [filters, setFilters] = useState<ChartFilters>({});

  const { data: users, isSuccess } = useActiveUsers({
    filters
  });

  const chartProps = useActiveUsersChartData({ apiData: users });

  return (
    <ContentContainer>
      <Grid
        templateAreas={`
          "title "
          "filters"
          "chart"
        `}
        templateColumns="1fr"
        rowGap={4}
        columnGap={4}
        height="100%"
      >
        <GridItem area="title">
          <Flex flexWrap="wrap" alignItems="flex-end">
            <Heading size="l" marginRight={2}>
              {t('attribute.title.users_online')}
            </Heading>
            <Text color="subtext">
              {t('attribute.description.real_time_monitoring')}
            </Text>
          </Flex>
        </GridItem>
        <GridItem area="filters">
          <TableFilters filters={chartFilters} onChange={setFilters} />
        </GridItem>
        <GridItem area="chart" height="27rem">
          <Skeleton isLoaded={isSuccess} boxSize="100%">
            <GaugeChart {...chartProps} settings={CHART_SETTINGS}>
              <HStack>
                <Heading size="xl">{chartProps.value}</Heading>
                <Text color="subtext">
                  {t('keywords.user', {
                    count: chartProps.value
                  }).toLowerCase()}
                </Text>
              </HStack>
            </GaugeChart>
          </Skeleton>
        </GridItem>
      </Grid>
    </ContentContainer>
  );
};
