import { chakra } from '@chakra-ui/react';
import { GroupBase, NoticeProps } from 'react-select';

import { useStyles } from '../LocationDropdown.utils';

export const NoOptionsMessage = <
  Option,
  IsMulti extends boolean,
  Group extends GroupBase<Option>
>(
  props: NoticeProps<Option, IsMulti, Group>
): JSX.Element => {
  const { children, innerProps } = props;

  const styles = useStyles();

  return (
    <chakra.div
      __css={{
        ...styles.noOptionsMessage,
        ...styles.option
      }}
      {...innerProps}
    >
      {children}
    </chakra.div>
  );
};
