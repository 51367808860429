import { Icon, IconProps } from '@chakra-ui/react';

export const ChevronLeftIcon = (props: IconProps): JSX.Element => (
  <Icon viewBox="0 0 24 24" fill="none" {...props}>
    <path
      d="M14.5 5L7.42893 12.0711L14.5 19.1421"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </Icon>
);
