import { Button } from '@chakra-ui/react';

import { ELLIPSES } from './utils';

export const PaginationButton = ({
  children,
  isSelected,
  onClick
}: {
  children: number | string;
  isSelected?: boolean;
  onClick: (page: number) => void;
}): JSX.Element => (
  <Button
    size="xs"
    variant="ghost"
    colorScheme="gray"
    sx={{
      color: 'black',
      fontSize: 'md',
      lineHeight: '1.5',
      fontWeight: 'normal'
    }}
    aria-selected={isSelected}
    aria-readonly={children === ELLIPSES}
    onClick={() => {
      if (typeof children === 'number') onClick(children);
    }}
  >
    {children}
  </Button>
);
