import { HStack, Img } from '@chakra-ui/react';
import { CellContext } from '@tanstack/react-table';
import { NavigationLink } from 'components';
import { StatusMark } from 'components/StatusMark/StatusMark';
import { OnlineStatus } from 'enum';
import { AccountStatus } from 'enum/api';
import { ICustomerTable } from 'types/customer';
import { getBaseCustomerLinkNavigation } from 'utils';

type Props = CellContext<ICustomerTable, string>;

export const NicknameCell = ({
  getValue,
  row: {
    original: { accountStatus, programTypeImg, online, id }
  }
}: Props): JSX.Element => {
  const markStatus =
    accountStatus === AccountStatus.Active
      ? online
        ? OnlineStatus.Online
        : OnlineStatus.Offline
      : null;

  return (
    <HStack>
      <StatusMark status={markStatus} />
      <Img boxSize={6} src={programTypeImg} />
      <NavigationLink to={getBaseCustomerLinkNavigation(id)}>
        {getValue()}
      </NavigationLink>
    </HStack>
  );
};
