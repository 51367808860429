import { ChevronDownIcon, ChevronUpIcon } from '@chakra-ui/icons';
import { Heading, Icon, MenuButton } from '@chakra-ui/react';
import { Dropdown } from 'components';
import { BlogSection, DropdownType } from 'enum';
import find from 'lodash/find';
import kebabCase from 'lodash/kebabCase';
import snakeCase from 'lodash/snakeCase';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import { getBlogSectionByUrl } from 'routes/routes.utils';

export const BlogDropdownTitle = ({
  sections
}: {
  sections: BlogSection[] | null;
}): JSX.Element => {
  const [t] = useTranslation();
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const options = (sections || []).map((section) => ({
    label: t(`keywords.${snakeCase(section)}`),
    value: section
  }));

  const selectedValue = getBlogSectionByUrl(pathname) || undefined;

  const selectedLabel = find(options, ['value', selectedValue])?.label;

  return (
    <Dropdown
      type={DropdownType.Custom}
      options={options}
      onChange={(value) => navigate(kebabCase(value))}
      selectedValue={selectedValue}
    >
      {({ isOpen }) => (
        <MenuButton as={Heading} size="l" display="inline-flex">
          {selectedLabel}
          <Icon
            as={isOpen ? ChevronUpIcon : ChevronDownIcon}
            boxSize={6}
            marginLeft={2}
          />
        </MenuButton>
      )}
    </Dropdown>
  );
};
