import { SubHeader } from 'containers';
import { Fragment } from 'react';
import { Outlet } from 'react-router-dom';

import { ModerationTabs } from './components';

export const Moderation = (): JSX.Element => (
  <Fragment>
    <SubHeader>
      <ModerationTabs />
    </SubHeader>
    <Outlet />
  </Fragment>
);
