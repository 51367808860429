import { useQuery } from '@tanstack/react-query';
import { fetchLogActivity } from 'api';
import { QueryKey } from 'enum/api';
import { useAppSelector } from 'hooks';
import { selectAppFilters } from 'store/app';
import { QueryFilters } from 'types';

export const useLogActivityList = (queryFilters: QueryFilters) => {
  const globalFilters = useAppSelector(selectAppFilters);

  return useQuery(
    [QueryKey.LogActivity, globalFilters, queryFilters],
    () => fetchLogActivity({ globalFilters, queryFilters }),
    {
      retry: false,
      refetchOnWindowFocus: false,
      keepPreviousData: true
    }
  );
};
