import { ENDPOINTS, WithPagination } from 'api';
import { serializeBodyFilters } from 'api/api.utils';
import axios from 'axios';
import { QueryFilters } from 'types';

import { AdminLoginSessionResponse } from './AdminManagement.types';

export const fetchLoginSession = async ({
  adminId,
  queryFilters
}: {
  adminId: string;
  queryFilters: QueryFilters;
}): Promise<WithPagination<AdminLoginSessionResponse[]>> => {
  const { data } = await axios.post<
    WithPagination<AdminLoginSessionResponse[]>
  >(
    `${ENDPOINTS.ADMINS}/${adminId}/login-sessions/list`,
    serializeBodyFilters({ queryFilters })
  );

  return data;
};
