import { ENDPOINTS } from 'api';
import axios from 'axios';

import { UpdateArticleRequestData } from './BlogManagement.types';

export const fetchUpdateArticle = ({
  articleId,
  ...body
}: { articleId: string } & UpdateArticleRequestData) => {
  const formData = new FormData();

  Object.keys(body).forEach((key) => {
    if (key === 'image' || key === 'previewImage') {
      body[key]?.forEach((value) => {
        formData.append(key, value);
      });
    } else {
      if (typeof body[key] !== 'undefined')
        formData.append(key, JSON.stringify(body[key]));
    }
  });

  return axios.patch(`${ENDPOINTS.ARTICLE}/${articleId}`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  });
};
