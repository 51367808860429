import { FormControl, FormLabel } from '@chakra-ui/react';
import { CustomSettings, SettingsResponse } from 'api';
import { Dropdown, FormError } from 'components';
import { DropdownType, ParameterType } from 'enum';
import { useAppSelector } from 'hooks';
import { useTranslation } from 'react-i18next';
import { selectSettings } from 'store/settings';
import { TranslationType } from 'types';
import { generateOptionsFromSettings } from 'utils';

type Props<T extends string> = {
  labelKey: keyof TranslationType['keywords'];
  value?: T;
  onChange: (value: T) => void;
  error?: string;
  optionKey: ParameterType | keyof SettingsResponse | keyof CustomSettings;
  getLabel?: (value: string) => string;
  isDisabled?: boolean;
};

export const Select = <T extends string>({
  labelKey,
  onChange,
  value,
  error,
  optionKey,
  getLabel,
  isDisabled
}: Props<T>): JSX.Element => {
  const [t] = useTranslation();
  const settings = useAppSelector(selectSettings);
  const options = generateOptionsFromSettings(optionKey, t, settings);

  return (
    <FormControl isInvalid={!!error}>
      <FormLabel>{t(`keywords.${labelKey}`)}</FormLabel>
      <Dropdown
        type={DropdownType.Select}
        isControllable
        options={
          getLabel
            ? options.map((option) => ({
                ...option,
                label: getLabel(option.value)
              }))
            : options
        }
        values={value ? [value] : []}
        onChange={(values) => onChange(values[0] as T)}
        isInvalid={!!error}
        isDisabled={isDisabled}
      />
      <FormError errorKey={error} />
    </FormControl>
  );
};
