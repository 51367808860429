import kebabCase from 'lodash/kebabCase';
import {
  TABLE_MANAGEMENT_PAGE,
  TABLE_MANAGEMENT_PAGE_LIST
} from 'pages/TableManagement/TableManagement.utils';
import { Navigate, RouteObject } from 'react-router-dom';

export const TABLE_MANAGEMENT_ROUTES: RouteObject[] =
  TABLE_MANAGEMENT_PAGE_LIST.map<RouteObject>((page) => {
    const { content: Content } = TABLE_MANAGEMENT_PAGE[page];

    return {
      path: kebabCase(page),
      element: <Content />
    };
  }).concat({
    index: true,
    element: <Navigate to={kebabCase(TABLE_MANAGEMENT_PAGE_LIST[0])} replace />
  });
