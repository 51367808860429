import { defaultMargin, Margin, Theme as BarChartTheme } from '@nivo/core';
import { Settings } from 'api';
import { BarChartLayout } from 'enum';
import { TFunction } from 'react-i18next';
import { getTextWidth } from 'utils';

import {
  BarChartAxisProps,
  BarChartData,
  BarChartSettings
} from './BarChart.types';

export const getBarChartAxisProps = <T extends unknown>({
  chartSettings,
  t,
  settings
}: {
  chartSettings: BarChartSettings<T>;
  t: TFunction;
  settings: Settings;
}): Required<BarChartAxisProps> => {
  const { formatXAxis, formatYAxis } = chartSettings;

  return {
    axisTop: null,
    axisRight: null,
    axisBottom: {
      tickSize: 0,
      tickPadding: 5,
      format: formatXAxis
        ? (value) => formatXAxis({ value, t, settings })
        : undefined
    },
    axisLeft: {
      tickSize: 0,
      tickPadding: 20,
      format: formatYAxis
        ? (value) => formatYAxis({ value, t, settings })
        : undefined
    }
  };
};

export const getBarChartMargins = <T extends unknown>({
  data: chartData,
  axisProps,
  theme,
  chartSettings,
  t,
  settings
}: {
  data: BarChartData[];
  axisProps: BarChartAxisProps;
  theme: BarChartTheme;
  chartSettings: BarChartSettings<T>;
  t: TFunction;
  settings: Settings;
}): Margin => {
  if (chartData.length === 0) return defaultMargin;

  const { axisBottom, axisLeft } = axisProps;
  const { indexBy, formatYAxis, formatXAxis, layout } = chartSettings;
  const { axis } = theme;

  const bottomTextPadding =
    (axisBottom?.tickSize || 0) + (axisBottom?.tickPadding || 0);
  const leftTextPadding =
    (axisLeft?.tickSize || 0) + (axisLeft?.tickPadding || 0);

  const { fontSize, fontWeight } = axis?.ticks?.text || {};

  const { maxYAxisValue, maxXAxisValue } = chartData.reduce<{
    maxYAxisValue: string;
    maxXAxisValue: string;
  }>(
    (acc, data) => {
      const formatFn =
        layout === BarChartLayout.Horizontal ? formatYAxis : formatXAxis;
      const translation = formatFn
        ? formatFn({ value: data[indexBy] as string, t, settings })
        : data[indexBy].toString();

      const [xAxisValue, yAxisValue] =
        layout === BarChartLayout.Horizontal
          ? [data['value'].toString(), translation]
          : [translation, data['value'].toString()];

      if (xAxisValue.length > acc.maxXAxisValue.length) {
        acc.maxXAxisValue = xAxisValue;
      }

      if (yAxisValue.length > acc.maxYAxisValue.length) {
        acc.maxYAxisValue = yAxisValue;
      }

      return acc;
    },
    {
      maxXAxisValue: '',
      maxYAxisValue: ''
    }
  );

  const font = {
    fontSize: fontSize as string,
    fontWeight: fontWeight as string
  };

  const maxYAxisWidth = getTextWidth(maxYAxisValue, font).width;
  const { width: maxXWidth, height: xAxisHeight } = getTextWidth(
    maxXAxisValue,
    font
  );

  return {
    top: 5,
    bottom: bottomTextPadding + xAxisHeight,
    right: maxXWidth / 2,
    left: leftTextPadding + maxYAxisWidth
  };
};

export const getMaxDataValue = ({ data }: { data: BarChartData[] }): number =>
  data.reduce((acc, cur) => {
    const { value } = cur;

    return acc > Number(value) ? acc : Number(value);
  }, 0);
