import { FormField, InputElement } from 'enum';
import { OptionKey } from 'enum/api';
import { GetOptionsArg } from 'types/form';
import { generateOptionsFromSettings } from 'utils';
import * as yup from 'yup';

import { EditCountriesFormData } from './EditCountries.types';

export const editCountriesFormSchema = {
  fields: {
    [FormField.Countries]: {
      type: InputElement.MultiSelect,
      translationKey: 'country',
      isSearchable: true,
      getPlaceholder: () => '',
      getOptions: ({ t, settings }: GetOptionsArg) =>
        generateOptionsFromSettings(OptionKey.Country, t, settings)
    }
  },
  gridLayout: {
    templateAreas: `
    "${FormField.Countries}"
  `,
    gridTemplateColumns: '1fr'
  }
} as const;

export const editCountriesValidationSchema: yup.SchemaOf<EditCountriesFormData> =
  yup.object({
    [FormField.Countries]: yup.array(yup.string().required()).ensure()
  });
