import { WithPagination } from 'api';
import { serializeQueryFilters } from 'api/api.utils';
import { ENDPOINTS } from 'api/endpoints';
import axios from 'axios';
import { AccountLinkReason, AccountStatus } from 'enum/api';
import { QueryFilters } from 'types';
import { ICustomerLinkedAccounts } from 'types/customer';

import { CustomerLinkedAccountsResponse } from '../Customers.types';

import { serializeLinkedAccountsData } from './LinkedAccount.utils';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const fakeData: ICustomerLinkedAccounts[] = [
  {
    id: 'customer_some1',
    linkedAccountId: 'customer_some1',
    email: 'customer@email',
    nickname: 'customer nick 1',
    lastAccessAt: new Date().toLocaleString(),
    linkedAt: new Date().toLocaleString(),
    reason: AccountLinkReason.SameEmail,
    adminNickname: 'admin1',
    adminId: 'admin1',
    comment: 'Just to check',
    accountStatus: AccountStatus.Active
  },
  {
    id: 'customer_some2',
    linkedAccountId: 'customer_some1',
    email: 'customer2@email',
    nickname: 'customer nick 2',
    lastAccessAt: new Date().toLocaleString(),
    linkedAt: new Date().toLocaleString(),
    reason: AccountLinkReason.SameEmail,
    adminNickname: 'admin1',
    adminId: 'admin1',
    comment: 'Just to check',
    accountStatus: AccountStatus.Active
  }
];

export const fetchCustomerLinkedAccounts = async ({
  customerId,
  queryFilters
}: {
  customerId: string;
  queryFilters: QueryFilters;
}): Promise<WithPagination<ICustomerLinkedAccounts[]>> => {
  const { data } = await axios.post<
    WithPagination<CustomerLinkedAccountsResponse[]>
  >(
    `${ENDPOINTS.LINKED_ACCOUNTS}/by-user/${customerId}/list`,
    serializeQueryFilters(queryFilters)
  );

  return {
    ...data,
    data: serializeLinkedAccountsData(data.data)
  };
};
