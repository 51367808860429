import { chakra } from '@chakra-ui/react';
import { ArrowDownIcon } from 'components/Icons';
import { MouseEventHandler, TouchEventHandler } from 'react';
import { DropdownIndicatorProps, GroupBase } from 'react-select';

import { getAttributeProperty, useStyles } from '../LocationDropdown.utils';

export const DropdownIndicator = <
  Option,
  IsMulti extends boolean,
  Group extends GroupBase<Option>
>(
  props: DropdownIndicatorProps<Option, IsMulti, Group>
): JSX.Element => {
  const {
    isDisabled,
    isFocused,
    innerProps,
    selectProps: { menuIsOpen: isOpen, dropdownIcon: Icon, dropdownIconClick }
  } = props;

  const styles = useStyles();

  const content = Icon ? (
    <Icon isMenuOpen={isOpen} />
  ) : (
    <ArrowDownIcon transform="auto" scaleY={isOpen ? -1 : 1} />
  );

  const onMouseDown: MouseEventHandler<HTMLDivElement> = (event) => {
    if (event && event.type === 'mousedown' && event.button !== 0) {
      return;
    }

    if (!dropdownIconClick) {
      innerProps.onMouseDown?.(event);

      return;
    }

    dropdownIconClick(event);

    event.preventDefault();
  };

  const onTouchEnd: TouchEventHandler<HTMLDivElement> = (event) => {
    if (!dropdownIconClick) {
      innerProps.onTouchEnd?.(event);

      return;
    }

    dropdownIconClick(event);

    event.preventDefault();
  };

  return (
    <chakra.div
      __css={styles.dropdownIndicator}
      {...innerProps}
      onMouseDown={onMouseDown}
      onTouchEnd={onTouchEnd}
      {...getAttributeProperty({
        isDisabled,
        isFocused
      })}
    >
      {content}
    </chakra.div>
  );
};
