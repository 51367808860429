import { Center, Stack } from '@chakra-ui/react';
import { BaseChartDataTemplate } from 'api';
import { BarChartLayout } from 'enum';

import {
  CustomStackedBarChartData,
  CustomStackedBarChartSettings
} from './CustomStackedBarChart.types';
import {
  HorizontalStackedBarSeries,
  VerticalStackedBarSeries
} from './components';

type Props<T extends BaseChartDataTemplate> = {
  data: CustomStackedBarChartData[];
  chartSettings: CustomStackedBarChartSettings<T>;
};

export const CustomStackedBarChart = <T extends BaseChartDataTemplate>({
  data,
  chartSettings
}: Props<T>): JSX.Element => {
  const { layout } = chartSettings;

  const isHorizontal = layout === BarChartLayout.Horizontal;

  const ChartSeries = isHorizontal
    ? HorizontalStackedBarSeries
    : VerticalStackedBarSeries;

  return (
    <Center height="100%">
      <Stack
        spacing={10}
        {...(isHorizontal ? { width: '100%' } : { height: '100%' })}
        direction={isHorizontal ? 'column' : 'row'}
      >
        {data.map((series) => (
          <ChartSeries
            key={series.id}
            series={series}
            chartSettings={chartSettings}
          />
        ))}
      </Stack>
    </Center>
  );
};
