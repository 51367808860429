import { FormControl, FormLabel } from '@chakra-ui/react';
import { FormError, TextArea } from 'components';
import { forwardRef } from 'react';
import { useTranslation } from 'react-i18next';
import { TranslationType } from 'types';

type Props = {
  labelKey: keyof TranslationType['keywords'];
  value?: string;
  onChange: (value: string) => void;
  onBlur?: () => void;
  error?: string;
  placeholder?: string;
  isDisabled?: boolean;
};

export const TextField = forwardRef<HTMLTextAreaElement, Props>(
  ({ labelKey, onChange, error, placeholder, value, isDisabled }, ref) => {
    const [t] = useTranslation();

    return (
      <FormControl isInvalid={!!error}>
        <FormLabel>{t(`keywords.${labelKey}`)}</FormLabel>
        <TextArea
          value={value}
          onChange={onChange}
          placeholder={placeholder}
          maxLength={2000}
          minHeight={44}
          isDisabled={isDisabled}
          ref={ref}
        />
        <FormError errorKey={error} />
      </FormControl>
    );
  }
);
