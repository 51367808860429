import {
  Menu,
  MenuButtonProps,
  MenuProps,
  useControllableState
} from '@chakra-ui/react';
import { UserEvent } from 'enum';
import {
  Fragment,
  KeyboardEventHandler,
  useEffect,
  useMemo,
  useState
} from 'react';
import { Option } from 'types';
import { generateSimpleOptionsByValues, getMapOptions } from 'utils/options';

import { Control } from './MenuButton';
import { Options } from './MenuOptions';

type Props = {
  isSearchable?: boolean;
  isMulti?: boolean;
  canAdd?: boolean;
  shouldHideOptions?: boolean;
  options?: Option[];
  variant?: MenuProps['variant'];
  placeholder?: string;
  menuButtonProps?: MenuButtonProps;
  shouldFitContent?: boolean;
  onChange?: (values: string[]) => void;
  defaultValue?: string[];
  isInvalid?: boolean;
  isDisabled?: boolean;
  matchWidth?: boolean;
  menuProps?: Omit<MenuProps, 'children'>;
} & (
  | {
      isControllable?: false;
      values?: undefined;
    }
  | {
      isControllable: true;
      values: string[];
    }
);

export const SelectDropdown = ({
  isSearchable = false,
  isMulti = false,
  isInvalid = false,
  canAdd = false,
  shouldHideOptions = false,
  isDisabled = false,
  variant = 'select',
  placeholder,
  menuButtonProps = {},
  shouldFitContent = false,
  defaultValue = [],
  values: controlledValues,
  isControllable,
  onChange,
  options = generateSimpleOptionsByValues(controlledValues),
  matchWidth,
  menuProps
}: Props): JSX.Element => {
  const defaultSearch =
    !isMulti && defaultValue.length > 0
      ? options.find((option) => option.value === defaultValue[0])?.label || ''
      : '';

  const [search, setSearch] = useState<string>(defaultSearch);
  const [selectedValues, setSelectedValues] = useControllableState<string[]>({
    value: isControllable ? controlledValues : undefined,
    defaultValue,
    onChange
  });

  const [customOptions, setCustomOptions] = useState<Option[]>([]);

  const optionsMap = useMemo(
    () => getMapOptions(options.concat(customOptions)),
    [options, customOptions]
  );

  // reset search when reset selectedValues
  useEffect(() => {
    if (isControllable && controlledValues.length === 0) {
      setSearch('');
    }
  }, [isControllable, controlledValues, setSearch]);

  const handleAddItem = (value: string) => {
    setSelectedValues((prev) => {
      if (isMulti) {
        if (prev.includes(value)) return prev;

        return [...prev, value];
      }
      return [value];
    });

    const search = isMulti ? '' : optionsMap.optionMap[value].label;
    setSearch(search);
  };

  const handleRemoveItem = (id: string) => {
    setSelectedValues((values) => values.filter((value) => value !== id));
  };

  const createNewOption = (id: string, label: string) => {
    setCustomOptions((prev) => {
      if (prev.find((option) => option.label === label)) return prev;

      return [
        ...prev,
        {
          label,
          value: id
        }
      ];
    });
  };

  const onKeyDown: KeyboardEventHandler<HTMLInputElement> = (e) => {
    if (canAdd && e.code === UserEvent.Enter) {
      const { value } = e.target as HTMLInputElement;

      if (!value) return;

      createNewOption(value, value);
      handleAddItem(value);
    }
  };

  const selectedOptions = selectedValues.reduce<Option[]>((acc, value) => {
    const option = optionsMap.optionMap[value];
    if (option) acc.push(option);

    return acc;
  }, []);

  const onCloseMenu = () => {
    if (isMulti) {
      setSearch('');
      return;
    }

    if (isSearchable) {
      const [option] = selectedOptions;
      setSearch(option?.label || '');
      return;
    }
  };

  return (
    <Menu
      autoSelect={false}
      isOpen={shouldHideOptions || isDisabled ? false : undefined}
      variant={variant}
      isLazy
      onClose={onCloseMenu}
      closeOnSelect={!isMulti}
      matchWidth={matchWidth}
      {...menuProps}
    >
      {({ isOpen }) => (
        <Fragment>
          <Control
            isOpen={isOpen}
            isDisabled={isDisabled}
            isInvalid={isInvalid}
            isReadOnly={!isSearchable || (shouldHideOptions ? false : !isOpen)}
            isMulti={isMulti}
            placeholder={placeholder}
            selectedOptions={selectedOptions}
            handleRemoveItem={handleRemoveItem}
            search={search}
            setSearch={setSearch}
            shouldHideOptions={shouldHideOptions}
            onInputKeyDown={onKeyDown}
            menuButtonProps={menuButtonProps}
            shouldFitContent={shouldFitContent}
          />
          {!shouldHideOptions && (
            <Options
              optionsMap={optionsMap}
              search={search}
              handleAddItem={handleAddItem}
              isSearchable={isSearchable}
              canAdd={canAdd}
              createNewOption={createNewOption}
              selectedValues={selectedValues}
            />
          )}
        </Fragment>
      )}
    </Menu>
  );
};
