import { CustomerCSVFields, FormField } from 'enum';
import { getAllEnumValues } from 'enum-for';
import * as yup from 'yup';

import { DownloadCSVFormData } from './DownloadCSV.types';

export const downloadCSVValidationSchema: yup.SchemaOf<DownloadCSVFormData> =
  yup.object({
    [FormField.Custom]: yup
      .array(yup.mixed().oneOf(getAllEnumValues(CustomerCSVFields)))
      .ensure()
      .min(1)
  });
