import { yupResolver } from '@hookform/resolvers/yup';
import { FormConstructor, ModalContainer } from 'containers';
import { FormField, ModalType } from 'enum';
import {
  useCreateLinkedAccount,
  useFormValidation,
  useModalActions
} from 'hooks';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { ParamType } from 'types';

import { AddLinkedAccountFormData } from './AddLinkedAccount.types';
import {
  addLinkedAccountBaseValidationSchema,
  addLinkedAccountFormSchema,
  addLinkedAccountValidationSchema
} from './AddLinkedAccount.utils';

export const AddLinkedAccount = (): JSX.Element => {
  const [t] = useTranslation();

  const form = useForm<AddLinkedAccountFormData>({
    mode: 'onSubmit',
    resolver: yupResolver(addLinkedAccountValidationSchema),
    defaultValues: {
      [FormField.Comment]: '',
      [FormField.Email]: ''
    }
  });

  const { handleSubmit, reset } = form;

  const isFormFilled = useFormValidation<AddLinkedAccountFormData>(
    form,
    addLinkedAccountBaseValidationSchema
  );

  const { isOpen, onClose, meta } = useModalActions(
    ModalType.AddLinkedAccount,
    {
      onClose: reset
    }
  );

  const { mutate, isLoading } = useCreateLinkedAccount();

  const onSubmit = handleSubmit((data) => {
    if (meta) {
      mutate(
        {
          customerId: meta.customerId,
          ...data,
          comment: data.comment as string
        },
        {
          onSuccess: onClose
        }
      );
    }
  });

  const actions: ParamType<typeof ModalContainer, 'footerButtons'> = [
    {
      text: t('actions.cancel'),
      variant: 'ghostGray'
    },
    {
      text: t('actions.add_linked_account'),
      colorScheme: 'primary',
      isLoading,
      isPreventClose: true,
      isDisabled: !isFormFilled,
      onClick: () => {
        onSubmit();
      }
    }
  ];

  return (
    <ModalContainer
      header={t('attribute.title.add_linked_account')}
      footerButtons={actions}
      isOpen={isOpen}
      onClose={onClose}
      size="xl"
    >
      <FormProvider {...form}>
        <FormConstructor formSchema={addLinkedAccountFormSchema} />
      </FormProvider>
    </ModalContainer>
  );
};
