import { TextProps } from '@chakra-ui/react';
import { TruncateText } from 'components';
import { ModalType } from 'enum';
import { useAppDispatch } from 'hooks';
import { FC } from 'react';
import { openModal } from 'store';

export const TextEditCell: FC<
  {
    children?: string | null;
    editModalHeader?: string;
    updateEntity: (data: string | null) => Promise<void>;
  } & Omit<TextProps, 'onClick'>
> = ({ children, editModalHeader, updateEntity, ...rest }) => {
  const dispatch = useAppDispatch();

  const onEditOption = () => {
    dispatch(
      openModal({
        id: ModalType.EditText,
        meta: {
          onSubmit: updateEntity,
          initialValue: children,
          header: editModalHeader
        }
      })
    );
  };

  return (
    <TruncateText
      maxWidth="200px"
      overflow="hidden"
      textOverflow="ellipsis"
      onClick={onEditOption}
      {...rest}
    >
      {children}
    </TruncateText>
  );
};
