import { Button } from '@chakra-ui/react';
import { ModalType } from 'enum';
import { useAppDispatch } from 'hooks';
import { useTranslation } from 'react-i18next';
import { openModal } from 'store/modal';

export const DeleteComment = ({
  commentId
}: {
  commentId: string;
}): JSX.Element => {
  const [t] = useTranslation();
  const dispatch = useAppDispatch();

  return (
    <Button
      variant="link"
      colorScheme="secondary"
      onClick={() =>
        dispatch(
          openModal({
            id: ModalType.DeleteCustomerComment,
            meta: {
              commentId
            }
          })
        )
      }
    >
      {t('actions.delete')}
    </Button>
  );
};
