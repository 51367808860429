import { ENDPOINTS, WithPagination } from 'api';
import { serializeBodyFilters } from 'api/api.utils';
import axios from 'axios';
import { SupportSourceType, SupportTicketStatus } from 'enum/api';
import { GlobalFilters, QueryFilters } from 'types';

import { ContactUsTicketListResponse } from './ContactUs.types';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const fakedData: ContactUsTicketListResponse[] = [
  {
    id: 'id_1',
    reason: 'reason1',
    status: SupportTicketStatus.Opened,
    user: {
      id: 'customer1',
      nickname: 'Customer',
      email: 'customer@email',
      avatar: null
    },
    assignee: null,
    lastMessageAt: new Date().toISOString(),
    lastMessageText: 'Some text',
    lastMessageComment: null,
    lastMessageSource: SupportSourceType.Email,
    sourcePhoneNumber: null,
    sourceNickname: null,
    sourceEmail: null,
    sourceUserLink: null,
    isManuallyAdded: true
  },
  {
    id: 'id_2',
    reason: 'reason1',
    status: SupportTicketStatus.Opened,
    user: null,
    assignee: null,
    lastMessageAt: new Date().toISOString(),
    lastMessageText: 'Some text',
    lastMessageComment: null,
    lastMessageSource: SupportSourceType.Email,
    sourcePhoneNumber: null,
    sourceNickname: null,
    sourceEmail: null,
    sourceUserLink: null,
    isManuallyAdded: true
  }
];

export const fetchTicketList = async ({
  queryFilters,
  globalFilters
}: {
  queryFilters: QueryFilters;
  globalFilters: GlobalFilters;
}): Promise<WithPagination<ContactUsTicketListResponse[]>> => {
  const { data } = await axios.post<
    WithPagination<ContactUsTicketListResponse[]>
  >(
    ENDPOINTS.CONTACT_US_TICKET_LIST,
    serializeBodyFilters({ queryFilters, globalFilters })
  );

  return data;
};
