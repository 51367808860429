import { useMutation } from '@tanstack/react-query';
import { fetchCreateParameterValue } from 'api';
import { QueryKey } from 'enum/api';
import { queryClient } from 'services';

export const useCreateParameterValue = () =>
  useMutation(fetchCreateParameterValue, {
    onSettled: () => {
      queryClient.invalidateQueries([QueryKey.ParameterValues]);
    }
  });
