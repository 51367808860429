import { FormField, InputElement } from 'enum';
import { getAllEnumValues } from 'enum-for';
import {
  AdminRole,
  AdminStatus,
  OptionKey,
  PermissionResource
} from 'enum/api';
import { GetOptionsArg } from 'types/form';
import { generateOptionsFromSettings } from 'utils';
import { LATIN_CHARACTERS_REGEXP } from 'utils/validation';
import * as yup from 'yup';

import { CreateAdminFormData } from './CreateNewAdmin.types';

export const createNewAdminValidationSchema: yup.SchemaOf<CreateAdminFormData> =
  yup.object({
    [FormField.UserName]: yup
      .string()
      .required()
      .max(50)
      .matches(LATIN_CHARACTERS_REGEXP),
    [FormField.Email]: yup.string().required().email(),
    [FormField.Password]: yup
      .string()
      .required()
      .min(10, 'invalid_password')
      .max(25, 'invalid_password')
      .matches(/[a-z]/, 'invalid_password')
      .matches(/[A-Z]/, 'invalid_password')
      .matches(/[0-9]/, 'invalid_password')
      .matches(/[!@#$%^&*()_+{}\[\]:;<>,.?~\\-]/, 'invalid_password')
      .matches(/^\S*$/, 'invalid_password'),
    [FormField.UserType]: yup
      .mixed()
      .required()
      .oneOf(getAllEnumValues(AdminRole)),
    [FormField.Status]: yup
      .mixed()
      .required()
      .oneOf(getAllEnumValues(AdminStatus)),
    [FormField.Countries]: yup.array().required(),
    [FormField.Domains]: yup.array(yup.string().required()).required().min(1)
  });

export const createNewAdminFormSchema = {
  fields: {
    [FormField.UserName]: {
      type: InputElement.Input,
      translationKey: 'user_name',
      getPlaceholder: () => 'Username'
    },
    [FormField.Password]: {
      type: InputElement.Password,
      translationKey: 'password',
      getPlaceholder: () => 'Password',
      hintTranslationKey: 'password_input',
      resource: PermissionResource.AdminPassword
    },
    [FormField.Email]: {
      type: InputElement.Input,
      translationKey: 'email',
      getPlaceholder: () => 'Email'
    },
    [FormField.UserType]: {
      type: InputElement.Select,
      translationKey: 'admin_role',
      getPlaceholder: () => 'User Type',
      getOptions: ({ t, settings }: GetOptionsArg) =>
        generateOptionsFromSettings(OptionKey.AdminRole, t, settings)
    },
    [FormField.Status]: {
      type: InputElement.Select,
      translationKey: 'status',
      getPlaceholder: () => 'Status',
      getOptions: ({ t, settings }: GetOptionsArg) =>
        generateOptionsFromSettings(OptionKey.AdminStatus, t, settings)
    },
    [FormField.Countries]: {
      type: InputElement.MultiSelect,
      translationKey: 'countries',
      getPlaceholder: () => 'Country',
      isSearchable: true,
      getOptions: ({ t, settings }: GetOptionsArg) =>
        generateOptionsFromSettings(OptionKey.Country, t, settings)
    },
    [FormField.Domains]: {
      type: InputElement.MultiSelect,
      translationKey: 'domains',
      getPlaceholder: () => 'Domains',
      getOptions: ({ t, settings }: GetOptionsArg) =>
        generateOptionsFromSettings(OptionKey.DomainName, t, settings)
    }
  },
  gridLayout: {
    templateAreas: `
    "${FormField.UserName}"
    "${FormField.Password}"
    "${FormField.Email}"
    "${FormField.UserType}"
    "${FormField.Status}"
    "${FormField.Countries}"
    "${FormField.Domains}"
    `,
    rowGap: 6
  }
} as const;
