import { yupResolver } from '@hookform/resolvers/yup';
import { FormConstructor, ModalContainer } from 'containers';
import { ModalType } from 'enum';
import { useModalActions, useChakraToast, useCrmSendSMS } from 'hooks';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { ParamType } from 'types';

import { SendSMSFormData } from './SendSMS.types';
import { sendSMSFormSchema, sendSMSValidationSchema } from './SendSMS.utils';

export const SendSMS = (): JSX.Element => {
  const [t] = useTranslation();
  const toast = useChakraToast();

  const form = useForm<SendSMSFormData>({
    mode: 'onSubmit',
    resolver: yupResolver(sendSMSValidationSchema)
  });

  const {
    reset,
    handleSubmit,
    formState: { isDirty }
  } = form;

  const { mutate, isLoading, reset: resetSendSMS } = useCrmSendSMS();

  const clearComponentStates = () => {
    resetSendSMS();
    reset();
  };

  const { isOpen, onClose, meta } = useModalActions(ModalType.SendSMS, {
    onClose: clearComponentStates
  });

  const onSubmit = handleSubmit((data) => {
    if (meta) {
      mutate(
        {
          ...meta,
          smsText: data.smsText || ''
        },
        {
          onSuccess: onClose,
          onError: (error) => {
            toast({
              status: 'error',
              title: t('errors.invalid_form'),
              description: error.message
            });
          }
        }
      );
    }
  });

  const actions: ParamType<typeof ModalContainer, 'footerButtons'> = [
    {
      text: t('actions.cancel'),
      variant: 'ghostGray'
    },
    {
      text: t('actions.send'),
      isLoading,
      isDisabled: !isDirty,
      isPreventClose: true,
      onClick: () => onSubmit()
    }
  ];

  return (
    <ModalContainer
      header={t('actions.send_sms', { count: 2 })}
      footerButtons={actions}
      isOpen={isOpen}
      onClose={onClose}
      size="xl"
      modalBodyProps={{
        overflow: 'unset',
        overflowX: 'unset'
      }}
    >
      <FormProvider {...form}>
        <FormConstructor formSchema={sendSMSFormSchema} />
      </FormProvider>
    </ModalContainer>
  );
};
