import { useQuery } from '@tanstack/react-query';
import { fetchConversationMessages } from 'api';
import { minutesToMilliseconds } from 'date-fns';
import { QueryKey } from 'enum/api';

const STALE_TIME_MINUTES = 5;
export const useCustomerConversationMessages = ({
  conversationId,
  enabled
}: {
  conversationId: string;
  enabled: boolean;
}) =>
  useQuery(
    [QueryKey.CustomerConversationMessages, conversationId],
    () => fetchConversationMessages({ conversationId }),
    {
      retry: false,
      refetchOnWindowFocus: false,
      keepPreviousData: true,
      staleTime: minutesToMilliseconds(STALE_TIME_MINUTES),
      enabled
    }
  );
