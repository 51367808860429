import { useAppSelector } from 'hooks';
import { selectPermissions } from 'store/user';
import { ResourceActionPermission } from 'types';
import { checkIfHasPermissions } from 'utils';

export const usePermissions = (
  restrictions?: ResourceActionPermission | ResourceActionPermission[]
): {
  hasAccess: boolean;
  checkPermissions: (restrictions: ResourceActionPermission) => boolean;
} => {
  const userPermissions = useAppSelector(selectPermissions);

  const checkPermissions = (restriction: ResourceActionPermission) =>
    checkIfHasPermissions(userPermissions, restriction);

  if (restrictions) {
    const restrictionsArray = Array.isArray(restrictions)
      ? restrictions
      : [restrictions];

    return {
      hasAccess: restrictionsArray.some((restriction) => {
        const { resource, actions, isStrict = false } = restriction;
        return checkIfHasPermissions(userPermissions, {
          resource,
          actions,
          isStrict
        });
      }),
      checkPermissions
    };
  }

  return {
    hasAccess: false,
    checkPermissions
  };
};
