import { Grid, GridItem, VStack } from '@chakra-ui/react';
import { AddButton } from 'components';
import { FormElement } from 'containers';
import { FormField } from 'enum';
import { RemoveButton } from 'pages/GeneralSettings/components';
import { Fragment } from 'react';
import { useTranslation } from 'react-i18next';

import { GeneralSettingsFormField } from '../../FormContent.types';
import { useSettingsFormFields } from '../../useSettingsFormFields';

import {
  amountOfProfileViewsBeforeUploadPhotoFormFields,
  getInitialFieldsState
} from './AmountOfProfileViewsBeforeUploadPhoto.utils';

export const AmountOfProfileViewsBeforeUploadPhoto = (): JSX.Element => {
  const [t] = useTranslation();

  const formItemName =
    GeneralSettingsFormField.AmountOfProfileViewsBeforeUploadPhoto;

  const { fields, handleRemove, appendField } = useSettingsFormFields({
    name: formItemName,
    getInitialFieldsState
  });

  return (
    <Fragment>
      <GridItem>
        {t(
          'attribute.description.how_many_profile_views_customer_see_before_uploading_photo'
        )}
      </GridItem>
      <GridItem>
        <VStack alignItems="stretch" spacing={6}>
          {fields.map((field, index) => (
            <Grid
              key={field.id}
              templateAreas={`
                "${FormField.CustomerType} ${FormField.CustomersAmount} remove"
              `}
              columnGap={3}
            >
              {amountOfProfileViewsBeforeUploadPhotoFormFields.map(
                (formField) => (
                  <GridItem key={formField.name} area={formField.name}>
                    <FormElement
                      {...formField}
                      name={`${formItemName}.${index}.${formField.name}`}
                      autoComplete="off"
                    />
                  </GridItem>
                )
              )}
              <GridItem area="remove">
                <RemoveButton onClick={() => handleRemove(index)} />
              </GridItem>
            </Grid>
          ))}
        </VStack>
        <AddButton alignSelf="flex-start" onClick={appendField} marginTop={4}>
          {t('actions.add_customer_type')}
        </AddButton>
      </GridItem>
    </Fragment>
  );
};
