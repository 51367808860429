import { GridItem } from '@chakra-ui/react';
import { FormElement } from 'containers';
import { InputElement } from 'enum';
import { Fragment } from 'react';
import { useTranslation } from 'react-i18next';

import { GeneralSettingsFormField } from '../FormContent.types';

export const Domains = (): JSX.Element => {
  const [t] = useTranslation();

  return (
    <Fragment>
      <GridItem>{t('attribute.description.domains')}</GridItem>
      <GridItem>
        <FormElement
          type={InputElement.MultiSelect}
          name={GeneralSettingsFormField.Domains}
          translationKey="domains"
          getPlaceholder={() => 'Domains'}
          shouldHideOptions
          canAdd
          isSearchable
        />
      </GridItem>
    </Fragment>
  );
};
