import { useQuery } from '@tanstack/react-query';
import { fetchLoginSession } from 'api';
import { QueryKey } from 'enum/api';
import { QueryFilters } from 'types';

export const useAdminLoginSessions = ({
  adminId,
  queryFilters
}: {
  adminId: string;
  queryFilters: QueryFilters;
}) =>
  useQuery(
    [QueryKey.AdminLoginSession, adminId, queryFilters],
    () => fetchLoginSession({ adminId, queryFilters }),
    {
      retry: false,
      refetchOnWindowFocus: false,
      keepPreviousData: true,
      enabled: !!adminId
    }
  );
