import { ENDPOINTS } from 'api/endpoints';
import axios from 'axios';

import { LoginParams, LoginResponse } from './authorization.types';

export const fetchLogin = async ({
  email,
  password
}: LoginParams): Promise<LoginResponse> => {
  const { data } = await axios.post<LoginResponse>(ENDPOINTS.LOGIN, {
    email,
    password
  });

  return data;
};
