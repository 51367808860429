import { FormField, InputElement } from 'enum';
import * as yup from 'yup';

import { SendSMSFormData } from './SendSMS.types';

export const sendSMSFormSchema = {
  fields: {
    [FormField.SmsText]: {
      type: InputElement.TextArea,
      translationKey: 'sms_text',
      getPlaceholder: () => '',
      minHeight: 44,
      maxLength: 160
    }
  },
  gridLayout: {
    templateAreas: `
    "${FormField.SmsText}"
  `,
    gridTemplateColumns: '1fr'
  }
} as const;

export const sendSMSValidationSchema: yup.SchemaOf<SendSMSFormData> =
  yup.object({
    [FormField.SmsText]: yup.string().required()
  });
