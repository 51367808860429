import { RowData, RowSelectionState, Table } from '@tanstack/react-table';
import { mapValues } from 'lodash';
import { useEffect, useRef, useState } from 'react';

export const useTableRowSelection = <TData extends RowData>() => {
  const tableInstance = useRef<Table<TData>>(null);

  const selectedRowsDataRef = useRef<Record<string, TData>>({});

  const [rowSelection, setRowSelection] = useState<RowSelectionState>({});

  useEffect(() => {
    const rowsById =
      tableInstance.current?.getSelectedRowModel().rowsById || {};

    selectedRowsDataRef.current = {
      ...selectedRowsDataRef.current,
      ...mapValues(rowsById, ({ original }) => original)
    };
  }, [rowSelection]);

  const getSelectedRowDataById = <T extends string>(id: T): TData =>
    selectedRowsDataRef.current[id];

  const getAllSelectedRowData = (): Record<string, TData> =>
    Object.keys(rowSelection).reduce((acc, rowId) => {
      acc[rowId] = selectedRowsDataRef.current[rowId];

      return acc;
    }, {} as Record<string, TData>);

  return {
    tableInstance,
    rowSelection,
    setRowSelection,
    getSelectedRowDataById,
    getAllSelectedRowData
  };
};
