import {
  Button,
  Input,
  InputProps,
  MenuButton,
  MenuButtonProps,
  Wrap
} from '@chakra-ui/react';
import {
  ChangeEventHandler,
  Dispatch,
  Fragment,
  KeyboardEventHandler,
  MouseEventHandler,
  SetStateAction,
  useState
} from 'react';
import { useTranslation } from 'react-i18next';
import { Option } from 'types';

import { FlexibleInput } from './FlexibleInput';
import { MultiValue } from './MultiValue';
import { RightIndicator } from './RightIndicator';

type Props = {
  isOpen: boolean;
  isReadOnly: boolean;
  isMulti: boolean;
  isInvalid: boolean;
  isDisabled: boolean;
  placeholder?: string;
  selectedOptions: Option[];
  search: string;
  setSearch: Dispatch<SetStateAction<string>>;
  handleRemoveItem: (id: string) => void;
  onInputKeyDown: KeyboardEventHandler<HTMLInputElement>;
  shouldHideOptions: boolean;
  menuButtonProps?: MenuButtonProps;
  shouldFitContent: boolean;
};

const MAX_ITEMS_TO_SHOW = 10;

export const Control = ({
  isOpen,
  isReadOnly,
  isMulti,
  isDisabled,
  placeholder = 'Select',
  selectedOptions,
  search,
  setSearch,
  handleRemoveItem,
  shouldHideOptions,
  onInputKeyDown,
  menuButtonProps,
  shouldFitContent,
  isInvalid
}: Props): JSX.Element => {
  const [t] = useTranslation();

  const [isFullListVisible, setIsFullListVisible] = useState(false);

  const hasSelectedOptions = selectedOptions.length > 0;

  const handleInput: ChangeEventHandler<HTMLInputElement> = (e) => {
    if (!isReadOnly) {
      setSearch(e.target.value);
    }
    requestAnimationFrame(() => {
      e.target.focus();
    });
  };

  const handleShowLess: MouseEventHandler<HTMLButtonElement> = (e) => {
    e.stopPropagation();
    setIsFullListVisible(false);
  };
  const handleShowMore: MouseEventHandler<HTMLButtonElement> = (e) => {
    e.stopPropagation();
    setIsFullListVisible(true);
  };

  const shouldShowLessButton =
    isFullListVisible && selectedOptions.length > MAX_ITEMS_TO_SHOW;
  const shouldShowMoreButton =
    !isFullListVisible && selectedOptions.length > MAX_ITEMS_TO_SHOW;

  const inputProps: InputProps = {
    isReadOnly,
    variant: 'unstyled',
    type: 'text',
    height: 'auto',
    minWidth: 0.5,
    value:
      !isMulti && isReadOnly && hasSelectedOptions
        ? selectedOptions[0].label
        : search,
    onChange: handleInput,
    onKeyDown: onInputKeyDown,
    placeholder
  };

  return (
    <MenuButton
      onClick={(e) => {
        if (isOpen && !isReadOnly) e.preventDefault();
        if (!isReadOnly) {
          (e.target as HTMLButtonElement).querySelector('input')?.focus();
        }
      }}
      type="button"
      aria-invalid={isInvalid}
      aria-disabled={isDisabled}
      {...menuButtonProps}
    >
      {isMulti ? (
        <Wrap spacing={1} align="center" shouldWrapChildren>
          {(isFullListVisible
            ? selectedOptions
            : selectedOptions.slice(0, MAX_ITEMS_TO_SHOW)
          ).map(({ label, value, color }) => (
            <MultiValue
              key={value}
              onRemove={() => handleRemoveItem(value)}
              color={color}
            >
              {label}
            </MultiValue>
          ))}
          {shouldShowLessButton && (
            <Button variant="link" onClick={handleShowLess}>
              {t('actions.show_less')}
            </Button>
          )}
          {shouldShowMoreButton && (
            <Button variant="link" onClick={handleShowMore}>
              {t('actions.show_more')}
            </Button>
          )}
          <FlexibleInput
            key="flexInput"
            {...inputProps}
            htmlSize={hasSelectedOptions ? 3 : undefined}
            placeholder={!hasSelectedOptions ? placeholder : ''}
          />
        </Wrap>
      ) : (
        <Fragment>
          {shouldFitContent ? (
            <FlexibleInput {...inputProps} htmlSize={3} />
          ) : (
            <Input {...inputProps} />
          )}
        </Fragment>
      )}
      {!shouldHideOptions && <RightIndicator isOpen={isOpen} />}
    </MenuButton>
  );
};
