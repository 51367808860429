import { useQuery } from '@tanstack/react-query';
import { fetchCustomerAdminComments } from 'api';
import { QueryKey } from 'enum/api';
import { QueryFilters } from 'types';

export const useCustomerAdminComments = (
  customerId: string,
  queryFilters: QueryFilters
) =>
  useQuery(
    [QueryKey.CustomerAdminComments, customerId, queryFilters],
    () =>
      fetchCustomerAdminComments({
        customerId,
        queryFilters
      }),
    {
      retry: false,
      refetchOnWindowFocus: false,
      keepPreviousData: true
    }
  );
