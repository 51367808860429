import { GridItem } from '@chakra-ui/react';
import { FormElement } from 'containers';
import { InputElement } from 'enum';
import { Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import { MAX_NUMBER_VALUE_WITH_10_CHARACTERS } from 'utils';

import { GeneralSettingsFormField } from '../FormContent.types';

export const AmountProfileViewsBeforeFillProfile = (): JSX.Element => {
  const [t] = useTranslation();

  return (
    <Fragment>
      <GridItem>
        {t(
          'attribute.description.how_many_profile_views_customer_see_before_fill_profile'
        )}
      </GridItem>
      <GridItem width="50%">
        <FormElement
          type={InputElement.NumberInput}
          name={GeneralSettingsFormField.AmountOfProfileViewsFillProfile}
          translationKey="profiles_amount"
          getPlaceholder={() => ''}
          min={0}
          max={MAX_NUMBER_VALUE_WITH_10_CHARACTERS}
        />
      </GridItem>
    </Fragment>
  );
};
