import {
  Settings,
  TableManagementOption,
  TableManagementOptionTranslations
} from 'api';
import { State } from 'country-state-city';
import { GenderTruncated } from 'enum';
import { OptionKey, ParameterType } from 'enum/api';
import { i18n } from 'i18n';
import { getName } from 'i18n-iso-countries';
import { snakeCase } from 'lodash';
import { TFunction } from 'react-i18next';
import { GroupOption, Option, Options, OptionsMap } from 'types';
import { convertCmToFtIn, NO, NO_RESULTS_FOUND, YES } from 'utils';

import {
  getUniqCityArrayByCountryCode,
  isValidCountry
} from './countryStateCity';

export const getMapOptions = (options: Option[]) => {
  const optionsMap = options.reduce<OptionsMap>(
    (acc, option) => {
      acc.order.push(option.value);
      acc.optionMap[option.value] = option;

      return acc;
    },
    {
      order: [],
      optionMap: {
        [NO_RESULTS_FOUND]: {
          label: 'No result found',
          value: NO_RESULTS_FOUND
        }
      },
      getFormattedOrder: (fn?: (order: string[]) => string[]): string[] =>
        fn ? fn(optionsMap.order) : optionsMap.order
    }
  );

  return optionsMap;
};

export const isGroupOptions = (options: Options): options is GroupOption[] => {
  if (options.length === 0) return false;

  return typeof (options[0] as GroupOption).groupLabel !== 'undefined';
};

const checkIfTableManagementOption = (
  options: Settings[keyof Settings]
): options is TableManagementOption[] =>
  typeof options[0] === 'object' && !!options[0].optionId;

export const getTableManagementOptionTranslation = ({
  translations,
  defaultValue,
  language
}: {
  translations: TableManagementOptionTranslations;
  language: string;
  defaultValue: string;
}) => {
  const { value } = translations[language] || translations['en'] || {};

  if (!value) {
    return defaultValue;
  }

  return value;
};

export const generateOptionsFromSettings = <K extends keyof Settings>(
  key: K,
  t: TFunction,
  settings: Settings
): Settings[K][number] extends string
  ? Option<Settings[K][number]>[]
  : Option[] => {
  let settingsOptions;

  if (key === ParameterType.GenderTruncated) {
    settingsOptions = settings[ParameterType.Gender];

    if (!settingsOptions) {
      console.error(`No options for Gender`);
      return [];
    }

    const result = [];

    settingsOptions.map(
      ({ optionId, defaultValue, translations, meta, systemId }) => {
        if (
          [GenderTruncated.Female, GenderTruncated.Male].includes(
            systemId as GenderTruncated
          )
        ) {
          result.push({
            value: optionId,
            label: getTableManagementOptionTranslation({
              translations,
              defaultValue,
              language: i18n.language
            }),
            ...(meta || {})
          });
        }
      }
    );

    result.push({
      value: GenderTruncated.Other,
      label: t('keywords.other')
    });

    return result;
  } else {
    settingsOptions = settings[key];
  }

  if (!settingsOptions) {
    console.error(`No options for "${key}"`);
    return [];
  }

  if (checkIfTableManagementOption(settingsOptions)) {
    return settingsOptions.map(
      ({ optionId, defaultValue, translations, meta }) => ({
        value: optionId,
        label: getTableManagementOptionTranslation({
          translations,
          defaultValue,
          language: i18n.language
        }),
        ...(meta || {})
      })
    );
  }

  switch (key) {
    case OptionKey.HeightInch:
      return settingsOptions.map((option) => {
        const { ft, inch } = convertCmToFtIn(option);

        return {
          value: typeof option === 'number' ? String(option) : option,
          label: t(`keywords.ft_inch`, {
            ft,
            inch
          })
        };
      });

    case OptionKey.Height:
      return settingsOptions.map((option) => ({
        value: typeof option === 'number' ? String(option) : option,
        label: t(`keywords.cm`, {
          cm: option
        })
      }));
    case OptionKey.Country:
      return settingsOptions.map((option) => ({
        value: typeof option === 'number' ? String(option) : option,
        label: getName(option, i18n.language, { select: 'official' })
      }));
    default:
      return settingsOptions.map((value) => {
        if (typeof value === 'number') {
          return {
            value: String(value),
            label: String(value)
          };
        }

        return {
          value,
          // @ts-ignore: ts doesn't work properly with union types
          label: t(`enum.${snakeCase(key)}.${snakeCase(value)}`, {
            defaultValue: value
          }) as string
        };
      });
  }
};

export const generateOptionsFromSettingsByKeys = <K extends keyof Settings>(
  keys: K[],
  t: TFunction,
  settings: Settings
): {
  [P in K]: ReturnType<typeof generateOptionsFromSettings<P>>;
} =>
  keys.reduce((acc, key) => {
    // @ts-ignore: ts doesn't work properly with union types
    acc[key] = generateOptionsFromSettings(key, t, settings);

    return acc;
  }, {} as ReturnType<typeof generateOptionsFromSettingsByKeys>);

export const generateSimpleOptionsByValues = (values?: string[]) =>
  values
    ? values.map((value) => ({
        label: value,
        value
      }))
    : [];

export const generateCityOptionsByCountry = ({
  t,
  countryCode,
  originCity
}: {
  t: TFunction;
  countryCode: string;
  originCity: string;
}): Option[] => {
  if (!isValidCountry(countryCode)) return [];
  const defaultCities = getUniqCityArrayByCountryCode(countryCode);
  const expandedCities = defaultCities.includes(originCity)
    ? defaultCities
    : [...defaultCities, originCity];

  return expandedCities.map((cityName) => ({
    value: cityName,
    label: cityName
  }));
};

export const generateCityOptionsByCountries = ({
  t,
  countryCodes = []
}: {
  t: TFunction;
  countryCodes: string[];
}): Option[] =>
  [
    ...new Set(
      countryCodes
        .map((countryCode) => {
          if (!isValidCountry(countryCode)) return [];

          return getUniqCityArrayByCountryCode(countryCode);
        })
        .flat()
    )
  ].map((cityName) => ({
    value: cityName,
    label: cityName
  }));

export const generateStatesOptionsByCountry = ({
  t,
  countryCode
}: {
  t: TFunction;
  countryCode: string;
}): Option[] =>
  State.getStatesOfCountry(countryCode).map((state) => ({
    value: state.isoCode,
    label: state.name
  }));

export const generateStatesOptionsByCountries = ({
  t,
  countryCodes
}: {
  t: TFunction;
  countryCodes: string[];
}): Option[] => {
  if (!countryCodes?.length) return [];

  return countryCodes
    .map((code) => generateStatesOptionsByCountry({ t, countryCode: code }))
    .flat(1);
};

export const generateYesNoOptions = (t: TFunction): Option[] =>
  ([YES, NO] as const).map((value) => ({
    value,
    label: t(`keywords.${value}`)
  }));

export const getTranslationByOptionKey = <K extends keyof Settings>({
  t,
  settings,
  optionKey,
  value
}: {
  t: TFunction;
  settings: Settings;
  optionKey: K;
  value: Settings[K] extends Array<infer U>
    ? U extends TableManagementOption
      ? string
      : U
    : never;
}): string => {
  const options = settings[optionKey];

  if (checkIfTableManagementOption(options)) {
    const option = options.find(({ optionId }) => optionId === value);

    if (!option) return value as string;

    const { translations, defaultValue } = option;

    return getTableManagementOptionTranslation({
      translations,
      language: i18n.language,
      defaultValue
    });
  }

  // @ts-ignore
  return t(`enum.${snakeCase(optionKey)}.${snakeCase(value)}`, {
    defaultValue: value
  }) as string;
};
