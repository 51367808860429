import { ENDPOINTS } from 'api/endpoints';
import axios from 'axios';
import { ILink } from 'types/customer';

export const fetchPasswordRecoveryLink = async (id: string): Promise<ILink> => {
  const { data } = await axios.get<ILink>(
    `${ENDPOINTS.CUSTOMER}/${id}/${ENDPOINTS.PASSWORD_RECOVERY_LINK}`
  );

  return data;
};
