import { DropdownType } from 'enum';

import { ButtonDropdown } from './ButtonDropdown';
import { CustomDropdown } from './CustomDropdown';
import { RangeDropdown } from './RangeDropdown';
import { SelectDropdown } from './SelectDropdown';
import { TagDropdown } from './TagDropdown';

type DropdownProps =
  | ({
      type?: DropdownType.Select;
    } & Parameters<typeof SelectDropdown>[0])
  | ({
      type: DropdownType.Range;
    } & Parameters<typeof RangeDropdown>[0])
  | ({
      type: DropdownType.Button;
    } & Parameters<typeof ButtonDropdown>[0])
  | ({
      type: DropdownType.Custom;
    } & Parameters<typeof CustomDropdown>[0])
  | ({
      type: DropdownType.Tag;
    } & Parameters<typeof TagDropdown>[0]);

export const Dropdown = (props: DropdownProps): JSX.Element | null => {
  switch (props.type) {
    case DropdownType.Range:
      return <RangeDropdown {...props} />;
    case DropdownType.Button:
      return <ButtonDropdown {...props} />;
    case DropdownType.Custom:
      return <CustomDropdown {...props} />;
    case DropdownType.Tag:
      return <TagDropdown {...props} />;
    case DropdownType.Select:
    default:
      return <SelectDropdown {...props} />;
  }
};
