import { FormField, Range, SystemLanguage, LengthUnits } from 'enum';
import { getAllEnumValues } from 'enum-for';
import { isNil } from 'lodash';
import isFinite from 'lodash/isFinite';
import { MAX_NUMBER_VALUE_WITH_10_CHARACTERS } from 'utils';
import * as yup from 'yup';

import {
  GeneralSettingsFormData,
  GeneralSettingsFormField
} from './FormContent';

export const generalSettingsValidationSchema: yup.SchemaOf<GeneralSettingsFormData> =
  yup.object().shape({
    [GeneralSettingsFormField.Country]: yup.string().required(),
    [GeneralSettingsFormField.SeeAllUsersWithFreePlan]: yup
      .boolean()
      .required(),
    [GeneralSettingsFormField.PercentageForDisplayAnotherGender]: yup
      .number()
      .max(100)
      .optional(),
    [GeneralSettingsFormField.AmountOfProfileViewsBeforePay]: yup
      .number()
      .optional()
      .max(MAX_NUMBER_VALUE_WITH_10_CHARACTERS),
    [GeneralSettingsFormField.AmountOfProfileViewsFillProfile]: yup
      .number()
      .optional()
      .max(MAX_NUMBER_VALUE_WITH_10_CHARACTERS),
    [GeneralSettingsFormField.CountOfFollowersToInstagramVerify]: yup
      .number()
      .optional()
      .max(MAX_NUMBER_VALUE_WITH_10_CHARACTERS),
    [GeneralSettingsFormField.MinMaxAgeCustomers]: yup
      .object()
      .shape(
        {
          [Range.Start]: yup.number().required().min(18).max(99),
          [Range.End]: yup
            .number()
            .required()
            .when(Range.Start, (start, schema) => schema.min(start + 1).max(99))
        },
        [[Range.Start, Range.End]]
      )
      .required(),
    [GeneralSettingsFormField.PasswordForViewCustomerMessages]: yup
      .object()
      .shape(
        {
          [FormField.Password]: yup
            .string()
            .defined()
            .when([FormField.Password], {
              is: (val: string) => !!val,
              then: (schema) =>
                schema
                  .min(10, 'invalid_password')
                  .max(25, 'invalid_password')
                  .matches(/[a-z]/, 'invalid_password')
                  .matches(/[A-Z]/, 'invalid_password')
                  .matches(/[0-9]/, 'invalid_password')
                  .matches(
                    /[!@#$%^&*()_+{}\[\]:;<>,.?~\\-]/,
                    'invalid_password'
                  )
                  .matches(/^\S*$/, 'invalid_password'),
              otherwise: (schema) => schema
            }),
          [FormField.PasswordConfirmation]: yup
            .string()
            .defined()
            .when(FormField.Password, {
              is: (value: string) => !!value,
              then: (schema) =>
                schema.oneOf(
                  [yup.ref(FormField.Password)],
                  'password_are_not_same'
                )
            })
        },
        [[FormField.Password, FormField.Password]]
      ),
    [GeneralSettingsFormField.CorporateEmail]: yup.string().email().defined(),
    [GeneralSettingsFormField.ReportEmail]: yup.string().email().defined(),
    [GeneralSettingsFormField.ContactUsEmail]: yup.string().email().defined(),
    [GeneralSettingsFormField.ContactUsTelegramLink]: yup
      .string()
      .url()
      .defined(),
    [GeneralSettingsFormField.ContactUsWhatsappLink]: yup
      .string()
      .url()
      .defined(),
    [GeneralSettingsFormField.NotificationEmail]: yup
      .string()
      .email()
      .defined(),
    [GeneralSettingsFormField.WelcomeMessageText]: yup.string().defined(),
    [GeneralSettingsFormField.WarningBadWordsInCustomerMessage]: yup
      .array(yup.string().required())
      .required(),
    [GeneralSettingsFormField.WarningBadWordsInCustomerDescription]: yup
      .array(yup.string().required())
      .required(),
    [GeneralSettingsFormField.BlockingBadWordsInCustomerMessage]: yup
      .array(yup.string().required())
      .required(),
    [GeneralSettingsFormField.AutoLabelsAfterRegistration]: yup
      .array(
        yup.lazy(
          (
            value: GeneralSettingsFormData[GeneralSettingsFormField.AutoLabelsAfterRegistration][number]
          ) => {
            const { gender, label } = value;

            if (gender || label.length > 0) {
              return yup.object({
                [FormField.Gender]: yup.string().required(),
                [FormField.Label]: yup.array(yup.string().required()).min(1)
              });
            }

            return yup.object({
              [FormField.Gender]: yup.mixed().nullable(),
              [FormField.Label]: yup.array(yup.string().required()).max(0)
            });
          }
        )
      )
      .required(),
    [GeneralSettingsFormField.WarningBadWordsInCustomerMessageByCustomer]: yup
      .array(
        yup.lazy(
          (
            value: GeneralSettingsFormData[GeneralSettingsFormField.BlockingBadWordsInCustomerMessageByCustomer][number]
          ) => {
            const { user, badWords } = value;

            if (user.id || badWords.length > 0) {
              return yup.object({
                [FormField.User]: yup
                  .object({
                    [FormField.Id]: yup.string().required(),
                    [FormField.Email]: yup.string().required()
                  })
                  .required(),
                [FormField.BadWords]: yup.array(yup.string().required()).min(1)
              });
            }

            return yup.object({
              [FormField.User]: yup
                .object({
                  [FormField.Id]: yup.mixed().nullable(),
                  [FormField.Email]: yup.mixed().nullable()
                })
                .required(),
              [FormField.BadWords]: yup.array().max(0)
            });
          }
        )
      )
      .required(),
    [GeneralSettingsFormField.WarningBadWordsInCustomerDescriptionByCustomer]:
      yup
        .array(
          yup.lazy(
            (
              value: GeneralSettingsFormData[GeneralSettingsFormField.BlockingBadWordsInCustomerMessageByCustomer][number]
            ) => {
              const { user, badWords } = value;

              if (user.id || badWords.length > 0) {
                return yup.object({
                  [FormField.User]: yup
                    .object({
                      [FormField.Id]: yup.string().required(),
                      [FormField.Email]: yup.string().required()
                    })
                    .required(),
                  [FormField.BadWords]: yup
                    .array(yup.string().required())
                    .min(1)
                });
              }

              return yup.object({
                [FormField.User]: yup
                  .object({
                    [FormField.Id]: yup.mixed().nullable(),
                    [FormField.Email]: yup.mixed().nullable()
                  })
                  .required(),
                [FormField.BadWords]: yup.array().max(0)
              });
            }
          )
        )
        .required(),
    [GeneralSettingsFormField.BlockingBadWordsInCustomerMessageByCustomer]: yup
      .array(
        yup.lazy(
          (
            value: GeneralSettingsFormData[GeneralSettingsFormField.BlockingBadWordsInCustomerMessageByCustomer][number]
          ) => {
            const { user, badWords } = value;

            if (user.id || badWords.length > 0) {
              return yup.object({
                [FormField.User]: yup
                  .object({
                    [FormField.Id]: yup.string().required(),
                    [FormField.Email]: yup.string().required()
                  })
                  .required(),
                [FormField.BadWords]: yup.array(yup.string().required()).min(1)
              });
            }

            return yup.object({
              [FormField.User]: yup
                .object({
                  [FormField.Id]: yup.mixed().nullable(),
                  [FormField.Email]: yup.mixed().nullable()
                })
                .required(),
              [FormField.BadWords]: yup.array().max(0)
            });
          }
        )
      )
      .required(),
    [GeneralSettingsFormField.FreeMessages]: yup
      .array(
        yup.object().shape(
          {
            [FormField.CustomerType]: yup
              .string()
              .when(FormField.MessageAmount, (number, schema) =>
                isFinite(number) ? schema.required() : schema.optional()
              ),
            [FormField.MessageAmount]: yup
              .number()
              .when(FormField.CustomerType, (customerType, schema) =>
                customerType ? schema.required() : schema.optional()
              ),
            [FormField.ConversationAmount]: yup.number().optional()
          },
          [[FormField.CustomerType, FormField.MessageAmount]]
        )
      )
      .required(),
    [GeneralSettingsFormField.AmountOfProfileViewsBeforeUploadPhoto]: yup
      .array(
        yup.object().shape(
          {
            [FormField.CustomerType]: yup
              .string()
              .when(FormField.CustomersAmount, (number, schema) =>
                isFinite(number) ? schema.required() : schema.optional()
              ),
            [FormField.CustomersAmount]: yup
              .number()
              .when(FormField.CustomerType, (customerType, schema) =>
                customerType ? schema.required() : schema.optional()
              )
          },
          [[FormField.CustomerType, FormField.CustomersAmount]]
        )
      )
      .required(),
    [GeneralSettingsFormField.RequiredPremiumProfiles]: yup
      .array(
        yup.object().shape(
          {
            [FormField.CustomerType]: yup
              .string()
              .when(FormField.Gender, (gender, schema) =>
                gender ? schema.required() : schema.optional()
              ),
            [FormField.Gender]: yup
              .string()
              .when(FormField.CustomerType, (customerType, schema) =>
                customerType ? schema.required() : schema.optional()
              )
          },
          [[FormField.CustomerType, FormField.Gender]]
        )
      )
      .required(),
    [GeneralSettingsFormField.FreeLikesCount]: yup.lazy(
      (
        value: GeneralSettingsFormData[GeneralSettingsFormField.FreeLikesCount]
      ) => {
        const { freeLikesCount, premiumLikesPerDayCount } = value;

        if (isNil(freeLikesCount) && isNil(premiumLikesPerDayCount)) {
          return yup.object({
            [FormField.FreeLikesAmount]: yup.number().moreThan(0).optional(),
            [FormField.PremiumLikesAmountPerDay]: yup
              .number()
              .moreThan(0)
              .optional()
          });
        }

        return yup.object({
          [FormField.FreeLikesAmount]: yup.number().moreThan(0).required(),
          [FormField.PremiumLikesAmountPerDay]: yup
            .number()
            .moreThan(0)
            .required()
        });
      }
    ),
    [GeneralSettingsFormField.LengthUnit]: yup
      .mixed()
      .oneOf([...getAllEnumValues(LengthUnits), null]),
    [GeneralSettingsFormField.RateForAutoBlock]: yup.lazy(
      (
        value: GeneralSettingsFormData[GeneralSettingsFormField.RateForAutoBlock]
      ) => {
        const { messageAmount, period, badWords } = value;

        if (isNil(messageAmount) && isNil(period) && badWords.length === 0) {
          return yup.object({
            [FormField.MessageAmount]: yup.number().optional(),
            [FormField.Period]: yup.number().moreThan(0).optional(),
            [FormField.BadWords]: yup.array(yup.string().required()).max(0)
          });
        }

        return yup.object({
          [FormField.MessageAmount]: yup.number().moreThan(0).required(),
          [FormField.Period]: yup.number().moreThan(0).required(),
          [FormField.BadWords]: yup.array(yup.string().required()).min(1)
        });
      }
    ),
    [GeneralSettingsFormField.RateForBlockingFloods]: yup.lazy(
      (
        value: GeneralSettingsFormData[GeneralSettingsFormField.RateForBlockingFloods]
      ) => {
        const { messageAmount, period } = value;

        if (isNil(messageAmount) && isNil(period)) {
          return yup.object({
            [FormField.MessageAmount]: yup.number().optional(),
            [FormField.Period]: yup.number().moreThan(0).optional()
          });
        }

        return yup.object({
          [FormField.MessageAmount]: yup.number().moreThan(0).required(),
          [FormField.Period]: yup.number().moreThan(0).required()
        });
      }
    ),
    [GeneralSettingsFormField.RestrictCustomerFromAnotherCountry]: yup
      .array(
        yup.object({
          [FormField.CustomerType]: yup.mixed().optional(),
          [FormField.OtherCustomerType]: yup.mixed().optional(),
          [FormField.Countries]: yup.array(yup.string().required()),
          [FormField.EverybodyCanSeeEverybody]: yup.boolean().required(),
          [FormField.AppliedForFreeProgramType]: yup.boolean().optional()
        })
      )
      .required(),
    [GeneralSettingsFormField.RequestChatAccessFromAnotherCountry]: yup
      .array(
        yup.object({
          [FormField.CustomerTypes]: yup.array(yup.string().required()),
          [FormField.OtherCustomerTypes]: yup.array(yup.string().required()),
          [FormField.Genders]: yup.array(yup.string().required()),
          [FormField.OtherGenders]: yup.array(yup.string().required()),
          [FormField.ProgramTypes]: yup.array(yup.string().required()),
          [FormField.OtherProgramTypes]: yup.array(yup.string().required()),
          [FormField.Countries]: yup.array(yup.string().required()),
          [FormField.EverybodyCanMessageToEverybody]: yup.boolean().required()
        })
      )
      .required(),
    [GeneralSettingsFormField.DefaultLanguage]: yup
      .mixed()
      .oneOf([...getAllEnumValues(SystemLanguage), null]),
    [GeneralSettingsFormField.Domains]: yup
      .array(
        yup
          .string()
          .trim()
          .matches(
            /^[a-zA-Z0-9][a-zA-Z0-9-]{0,61}[a-zA-Z0-9]\.(?:[a-zA-Z]{2,}|[a-zA-Z0-9-]{2,}\.[a-zA-Z]{2,})$/,
            'Should be in correct domain format'
          )
          .required()
      )
      .defined(),
    [GeneralSettingsFormField.SisterCountries]: yup
      .array(yup.string().required())
      .defined(),
    [GeneralSettingsFormField.StorageFolderName]: yup
      .string()
      .optional()
      .nullable(),
    [GeneralSettingsFormField.StorageMaxEmailsImagesMale]: yup
      .number()
      .optional()
      .nullable(),
    [GeneralSettingsFormField.StorageMaxEmailsImagesFemale]: yup
      .number()
      .optional()
      .nullable(),
    [GeneralSettingsFormField.StorageMaxNotificationsImagesMale]: yup
      .number()
      .optional()
      .nullable(),
    [GeneralSettingsFormField.StorageMaxNotificationsImagesFemale]: yup
      .number()
      .optional()
      .nullable(),
    [GeneralSettingsFormField.UsersShouldRequestProfileDeletion]: yup
      .boolean()
      .required(),
    [GeneralSettingsFormField.EnabledMultiCurrencies]: yup.boolean().required(),
    [GeneralSettingsFormField.StartedConversationsPerDayLimit]: yup
      .number()
      .optional()
      .min(1)
      .max(10000)
  });
