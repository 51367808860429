import { Button, Grid, GridProps } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

import { ModerationActionType } from '../Moderation.utils';

type Props = {
  handleClick: (type: ModerationActionType) => void;
  gridProps?: GridProps;
};

export const ModerationActions = ({
  handleClick,
  gridProps
}: Props): JSX.Element => {
  const [t] = useTranslation();

  return (
    <Grid templateColumns="repeat(4, 1fr)" columnGap={2} {...gridProps}>
      <Button
        variant="ghostGray"
        onClick={() => handleClick(ModerationActionType.Decline)}
      >
        {t('actions.decline')}
      </Button>
      <Button
        variant="ghostGray"
        onClick={() => handleClick(ModerationActionType.AutoDecline)}
      >
        {t('actions.automatic_decline')}
      </Button>
      <Button onClick={() => handleClick(ModerationActionType.Approve)}>
        {t('actions.approve')}
      </Button>
      <Button
        variant="ghostGray"
        onClick={() => handleClick(ModerationActionType.Edit)}
        color="green"
      >
        {t('actions.edit')}
      </Button>
    </Grid>
  );
};
