import { CellContext } from '@tanstack/react-table';
import { AdminLoginSessionResponse } from 'api';
import { NavigationLink } from 'components';
import { getBaseAdminLinkNavigation } from 'utils';

type Props = CellContext<AdminLoginSessionResponse, unknown>;

export const AdminNicknameCell = ({ table }: Props): JSX.Element | null => {
  const { nickname, id } = table.options.meta?.user || {};

  if (!nickname || !id) {
    return null;
  }

  return (
    <NavigationLink to={getBaseAdminLinkNavigation(id)}>
      {nickname}
    </NavigationLink>
  );
};
