import { CustomerPage, CustomerSection, PageName } from 'enum';
import {
  CUSTOMER_PAGE_LINK_BY_PAGE,
  CUSTOMER_PAGE_SECTION_LINK_BY_SECTION,
  SIDE_NAV_LINK_BY_PAGE,
  SIDE_NAV_PAGE_BY_LINK
} from 'routes/routes.utils';

import { UserLocationDetails } from '../containers';

export const getPageNameByUrl = (pathname: string) =>
  SIDE_NAV_PAGE_BY_LINK[pathname.split('/')[1] || '/'];

export const getBaseCustomerLinkNavigation = (customerId: string) =>
  `/${SIDE_NAV_LINK_BY_PAGE[PageName.Customers]}/${customerId}`;

export const getBaseAdminLinkNavigation = (adminId: string) =>
  `/${SIDE_NAV_LINK_BY_PAGE[PageName.AdminManagement]}/${adminId}`;

export const getCustomerLinkNavigation = ({
  customerId,
  section,
  page
}: {
  customerId: string;
  section?: CustomerSection;
  page: CustomerPage;
}) => {
  let link = `${getBaseCustomerLinkNavigation(customerId)}/${
    CUSTOMER_PAGE_LINK_BY_PAGE[page]
  }`;

  if (section) {
    link += `/${CUSTOMER_PAGE_SECTION_LINK_BY_SECTION[section]}`;
  }

  return link;
};

export const getLocationDetails = (
  data: google.maps.places.PlaceResult
): UserLocationDetails | null => {
  const {
    address_components: addressComponents,
    geometry,
    place_id: placeId
  } = data;

  if (!addressComponents || !geometry?.location) return null;

  const address = addressComponents.reduce((acc, current) => {
    const { types, short_name: shortName, long_name: longName } = current;

    switch (types[0]) {
      case 'locality':
        acc['city'] = longName;
        break;
      case 'administrative_area_level_1':
        acc['state'] = shortName;
        break;
      case 'country':
        acc['country'] = shortName;
        break;
    }

    return acc;
  }, {} as UserLocationDetails);

  return {
    ...address,
    latitude:
      typeof geometry.location.lat === 'function'
        ? geometry.location.lat()
        : (geometry.location.lat as unknown as number),
    longitude:
      typeof geometry.location.lng === 'function'
        ? geometry.location.lng()
        : (geometry.location.lng as unknown as number),
    placeId
  };
};
