import { ENDPOINTS } from 'api/endpoints';
import axios from 'axios';
import { CustomerCSVFields } from 'enum';

export const fetchCustomerDownloadCSV = async (
  fields: CustomerCSVFields[]
): Promise<string> => {
  const { data } = await axios.post<string>(ENDPOINTS.CUSTOMER_CSV, {
    fields
  });

  return data;
};
