import { Box, Grid } from '@chakra-ui/react';
import { ContentContainer } from 'containers';
import { ReportSection } from 'enum';
import { ReportDropdownTitle } from 'pages/Reports/components';
import { Outlet } from 'react-router-dom';

export const AdminReports = ({
  sections
}: {
  sections: ReportSection[] | null;
}): JSX.Element => (
  <ContentContainer height="100%" display="flex" flexDirection="column">
    <Box marginBottom={6} display="flex">
      <ReportDropdownTitle sections={sections} />
    </Box>
    <Grid
      templateAreas={`
             "search actions"
             "filters filters"
             "table table"
            `}
      templateColumns="26.25rem 1fr"
      templateRows="min-content  min-content 1fr"
      rowGap={6}
      overflow="hidden"
      flexGrow={1}
    >
      <Outlet />
    </Grid>
  </ContentContainer>
);
