import { Button } from '@chakra-ui/react';
import { useUpdateReport } from 'hooks';
import { useTranslation } from 'react-i18next';

type Props = {
  reportId: string;
  hasRead: boolean;
};

export const MarkButton = ({ reportId, hasRead }: Props): JSX.Element => {
  const [t] = useTranslation();

  const { mutate, isLoading } = useUpdateReport();

  const onToggleRead = () => {
    mutate({
      reportId,
      hasRead: !hasRead
    });
  };

  return (
    <Button
      variant="link"
      colorScheme="secondary"
      onClick={onToggleRead}
      isLoading={isLoading}
    >
      {hasRead ? t('actions.mark_unread') : t('actions.mark_read')}
    </Button>
  );
};
