import {
  CustomerFullFormData,
  CustomerResponse,
  ENDPOINTS,
  UpdateCustomerRequest
} from 'api';
import axios, { AxiosResponse } from 'axios';

import { serializeCustomerRequestData } from '../Customers.utils';

export const fetchUpdateCustomer = async (
  customerDetails: CustomerFullFormData & { id: string }
): Promise<void> => {
  const { id, adminComment, ...rest } = customerDetails;

  await axios.patch<
    CustomerResponse,
    AxiosResponse<CustomerResponse>,
    Partial<UpdateCustomerRequest>
  >(`${ENDPOINTS.CUSTOMER}/${customerDetails.id}`, {
    ...(adminComment && { adminComment }),
    ...serializeCustomerRequestData(rest)
  });
};
