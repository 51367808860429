import { ICustomerLinkedAccounts } from 'types/customer';

import { CustomerLinkedAccountsResponse } from '../Customers.types';

export const serializeLinkedAccountsData = (
  response: CustomerLinkedAccountsResponse[]
): ICustomerLinkedAccounts[] =>
  response.map((data) => ({
    id: data.id,
    linkedAccountId: data.linkedAccount.id,
    email: data.linkedAccount.email,
    nickname: data.linkedAccount.nickname,
    lastAccessAt: data.linkedAccount.lastAccessAt,
    linkedAt: data.linkedAt,
    reason: data.reason,
    adminNickname: data.admin?.nickname || null,
    adminId: data.admin?.id || null,
    comment: data.comment,
    accountStatus: data.linkedAccount.accountStatus
  }));
