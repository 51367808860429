import { useQuery } from '@tanstack/react-query';
import { fetchCustomerInfo } from 'api';
import { QueryKey } from 'enum/api';

export const useCustomerInfo = (id: string) =>
  useQuery([QueryKey.Customers, id], () => fetchCustomerInfo(id), {
    retry: false,
    refetchOnWindowFocus: false,
    enabled: !!id
  });
