import { useMutation, useQueryClient } from '@tanstack/react-query';
import { fetchUpdateCustomerBadWords } from 'api/Customers/CustomerBadWords/fetchUpdateCustomerBadWords';
import { AxiosError } from 'axios';
import { QueryKey } from 'enum/api';
import { AxiosErrorData } from 'types';

export const useUpdateCustomerBadWords = () => {
  const queryClient = useQueryClient();

  return useMutation(fetchUpdateCustomerBadWords, {
    onSuccess() {
      queryClient.invalidateQueries([QueryKey.CustomerBadWords]);
    },
    onError(error: AxiosError<AxiosErrorData>) {}
  });
};
