import { ModerationPage } from 'enum';

import { ModerationTotalResponse, ModerationTotal } from './Moderation.types';

export const serializeModerationTotalResponse = (
  data: ModerationTotalResponse
): ModerationTotal => ({
  [ModerationPage.GalleryPhoto]: data.photos,
  [ModerationPage.Comment]: data.comments,
  [ModerationPage.Motto]: data.mottos,
  [ModerationPage.SomethingAboutMe]: data.aboutMe
});
