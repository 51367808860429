'use client';

import { createStylesContext } from '@chakra-ui/react';
import { CreateContextReturn } from '@chakra-ui/react-utils';
import { CommonPropsAndClassName, GroupBase } from 'react-select';

import { DropdownStyleConfig } from './LocationDropdown.types';

export const [StylesProvider, useStyles] = createStylesContext(
  'Component'
) as unknown as CreateContextReturn<DropdownStyleConfig>;

export const getAttributeProperty = ({
  isDisabled,
  isFocused,
  isReadOnly,
  isHidden,
  isSelected,
  hasMultiValue,
  isInvalid
}: {
  isDisabled?: boolean;
  isFocused?: boolean;
  isReadOnly?: boolean;
  isHidden?: boolean;
  isSelected?: boolean;
  hasMultiValue?: boolean;
  isInvalid?: boolean;
}) => ({
  'data-disabled': isDisabled ? '' : undefined,
  'data-focus': isFocused ? '' : undefined,
  'data-focus-visible': isFocused ? '' : undefined,
  'data-hidden': isHidden ? '' : undefined,
  'aria-readonly': isReadOnly,
  'aria-selected': isSelected,
  'data-has-multi': hasMultiValue ? '' : undefined,
  'aria-invalid': isInvalid
});

export const cleanCommonProps = <
  Option,
  IsMulti extends boolean,
  Group extends GroupBase<Option>,
  AdditionalProps
>(
  props: Partial<CommonPropsAndClassName<Option, IsMulti, Group>> &
    AdditionalProps
): Omit<
  AdditionalProps,
  keyof CommonPropsAndClassName<Option, IsMulti, Group>
> => {
  const {
    className,
    clearValue,
    cx,
    getStyles,
    getClassNames,
    getValue,
    hasValue,
    isMulti,
    isRtl,
    options,
    selectOption,
    selectProps,
    setValue,
    theme,
    ...innerProps
  } = props;

  return innerProps;
};
