import { GridItem, Spinner } from '@chakra-ui/react';
import { AddButton, Table } from 'components';
import { ModalType } from 'enum';
import {
  useAppDispatch,
  useCustomerAdminComments,
  useCustomerIdFromUrl,
  useTableQueryController
} from 'hooks';
import { Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import { openModal } from 'store/modal';

import { adminCommentsColumns } from '../AdminInteraction.utils';

export const AdminComments = (): JSX.Element => {
  const [t] = useTranslation();
  const dispatch = useAppDispatch();

  const {
    queryFilters,
    action: { onChangeSort, onChangePagination }
  } = useTableQueryController();

  const customerId = useCustomerIdFromUrl();

  const { data } = useCustomerAdminComments(customerId, queryFilters);

  const handleAddComment = () =>
    dispatch(
      openModal({
        id: ModalType.CreateComment,
        meta: {
          customerId
        }
      })
    );

  if (!data) return <Spinner />;

  return (
    <Fragment>
      <GridItem area="actions" justifySelf="flex-end">
        <AddButton onClick={handleAddComment}>
          {t('actions.add_comment')}
        </AddButton>
      </GridItem>
      <GridItem area="table" overflow="hidden">
        <Table
          data={data.data}
          columns={adminCommentsColumns}
          onChangeSort={onChangeSort}
          onChangePagination={onChangePagination}
          totalItems={data.totalItems}
          pagination={queryFilters.pagination}
        />
      </GridItem>
    </Fragment>
  );
};
