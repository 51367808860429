import { ENDPOINTS } from 'api';
import axios from 'axios';

import { CreateArticleRequestData } from './BlogManagement.types';

export const fetchCreateArticle = (data: CreateArticleRequestData) => {
  const formData = new FormData();

  Object.keys(data).forEach((key) => {
    if (key === 'image' || key === 'previewImage') {
      data[key]?.forEach((value) => {
        formData.append(key, value);
      });
    } else {
      if (typeof data[key] !== 'undefined')
        formData.append(key, JSON.stringify(data[key]));
    }
  });

  return axios.post(ENDPOINTS.ARTICLE, formData, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  });
};
