import { HStack, Icon, IconButton, StackProps } from '@chakra-ui/react';

type Props = StackProps & {
  actions: {
    id: string;
    Icon: typeof Icon;
    isDisabled: boolean;
    onClick: () => void;
  }[];
};

export const ArrowIcons = ({ actions, ...rest }: Props): JSX.Element => (
  <HStack {...rest}>
    {actions.map(({ id, isDisabled, onClick, Icon }) => (
      <IconButton
        key={id}
        icon={<Icon />}
        color="gray.500"
        variant="ghostGray"
        size="md"
        fontSize="xl"
        aria-label="navigate content"
        isDisabled={isDisabled}
        onClick={onClick}
      />
    ))}
  </HStack>
);
