import { CrmFiltersRequestData, ENDPOINTS } from 'api';
import { serializeBodyFilters } from 'api/api.utils';
import axios from 'axios';
import { GlobalFilters } from 'types';

export const fetchCrmSendEmail = (
  body: CrmFiltersRequestData & {
    emailTemplateId: string;
    payload: string;
  },
  globalFilters: GlobalFilters
): Promise<void> =>
  axios.post(ENDPOINTS.CRM_SEND_EMAIL, {
    ...Object.fromEntries(
      Object.entries(body).filter(([, value]) => value !== undefined)
    ),
    ...serializeBodyFilters({ globalFilters })
  });
