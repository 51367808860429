import { AnyAction } from '@reduxjs/toolkit';
import { Settings } from 'api';
import { ListContainerProps } from 'components';
import { format, isValid } from 'date-fns';
import { InputElement } from 'enum';
import { Dispatch } from 'react';
import { TFunction } from 'react-i18next';
import {
  GroupDefaultValues,
  GroupOption,
  GroupSelectedValue,
  Option,
  SupportedInputTypeAttribute
} from 'types';
import { generateOptionsFromSettingsByKeys, isEmpty } from 'utils';

import {
  TableFilterReducerState,
  TableFilterSchema
} from './TableFilters.types';
import { resetSelection, tableFilterReducer, updateSelection } from './reducer';

const formatRangeValue = ({
  value,
  inputType
}: {
  inputType?: SupportedInputTypeAttribute;
  value: string | number;
}) => {
  if (inputType === 'date' && isValid(new Date(value))) {
    return format(new Date(value), 'dd.MM.yyyy');
  }

  return value;
};

export const getSelectedValuesLabel = ({
  type,
  selectedValues,
  options,
  inputType,
  t
}: {
  selectedValues: (string | number | undefined)[];
  t: TFunction;
} & (
  | {
      type: InputElement.Checkbox | InputElement.Select;
      options: Option[];
      inputType?: undefined;
    }
  | {
      type: InputElement.Range;
      inputType?: SupportedInputTypeAttribute;
      options?: undefined;
    }
)) => {
  switch (type) {
    case InputElement.Range: {
      const from = selectedValues[0];
      const to = selectedValues[1];

      if (!isEmpty(from) && !isEmpty(to)) {
        return t('keywords.from_to_range', {
          from: formatRangeValue({
            inputType,
            value: from
          }),
          to: formatRangeValue({
            inputType,
            value: to
          })
        });
      }

      if (!isEmpty(from)) {
        return t('keywords.from_range', {
          from: formatRangeValue({
            inputType,
            value: from
          })
        });
      }

      if (!isEmpty(to)) {
        return t('keywords.to_range', {
          to: formatRangeValue({
            inputType,
            value: to
          })
        });
      }

      return t('keywords.all');
    }
    default:
      return selectedValues.length > 0
        ? selectedValues
            .map(
              (selectedValue) =>
                options.find((option) => option.value === selectedValue)?.label
            )
            .join(', ')
        : t('keywords.all');
  }
};

export const serializeSingleFilter = ({
  filter,
  optionsByKey,
  selectedValues = [],
  settings,
  t,
  dispatch
}: {
  filter: TableFilterSchema;
  optionsByKey: ReturnType<typeof generateOptionsFromSettingsByKeys>;
  selectedValues?: (string | number | undefined)[];
  settings: Settings;
  t: TFunction;
  dispatch: Dispatch<AnyAction>;
}): {
  id: string;
  label: string;
  optionList: ListContainerProps;
  onReset: () => void;
} => {
  const { type, optionKey, getOptions, getLabel, defaultValues } = filter;

  if (type === InputElement.Range) {
    const { inputProps } = filter;
    const { type: inputType } = inputProps;

    return {
      id: optionKey,
      label: `${getLabel(t)}: ${getSelectedValuesLabel({
        selectedValues,
        type,
        inputType,
        t
      })}`,
      optionList: {
        type,
        selectedValues,
        inputProps,
        defaultValues,
        onChange: (values) => {
          const newValues = [...values];

          if (inputType === 'date') {
            if (isEmpty(newValues[0]) && !isEmpty(newValues[1])) {
              newValues[0] = newValues[1];
            }

            if (isEmpty(newValues[1]) && !isEmpty(newValues[0])) {
              newValues[1] = newValues[0];
            }
          }

          dispatch(updateSelection(optionKey, newValues));
        }
      },
      onReset: () =>
        dispatch(
          resetSelection([
            {
              filerKey: optionKey,
              defaultValues
            }
          ])
        )
    };
  }

  const options = getOptions
    ? getOptions({ t, settings })
    : optionsByKey[optionKey];

  return {
    id: optionKey,
    label: `${getLabel(t)}: ${getSelectedValuesLabel({
      selectedValues,
      options,
      type,
      t
    })}`,
    optionList: {
      type,
      options,
      selectedValues: selectedValues as string[],
      defaultValues,
      onChange: (values: string[]) => {
        dispatch(updateSelection(optionKey, values));
      }
    },
    onReset: () =>
      dispatch(
        resetSelection([
          {
            filerKey: optionKey,
            defaultValues
          }
        ])
      )
  };
};

export const getAmountOfSelectedGroupFilters = (
  selectedValues: GroupSelectedValue
): number =>
  Object.values(selectedValues).reduce((acc, values) => {
    acc += values.length > 0 ? 1 : 0;

    return acc;
  }, 0);

export const serializeGroupFilters = ({
  filters,
  optionsByKey,
  data,
  settings,
  t,
  dispatch
}: {
  filters: TableFilterSchema[];
  optionsByKey: ReturnType<typeof generateOptionsFromSettingsByKeys>;
  data: TableFilterReducerState;
  settings: Settings;
  t: TFunction;
  dispatch: Dispatch<AnyAction>;
}): {
  selectedFiltersCount: number;
  onReset: () => void;
  optionList: ListContainerProps;
} => {
  const groupOptions = filters.map<GroupOption>(
    ({ optionKey, getOptions, getLabel }) => ({
      groupLabel: getLabel(t),
      groupKey: optionKey,
      options: getOptions
        ? getOptions({ t, settings })
        : optionsByKey[optionKey as keyof Settings]
    })
  );

  const { selectedValues, defaultValues } = filters.reduce(
    (acc, { optionKey, defaultValues }) => {
      acc.selectedValues[optionKey] = (data[optionKey] || []) as string[];

      if (defaultValues) {
        acc.defaultValues[optionKey] = defaultValues;
      }

      return acc;
    },
    {
      selectedValues: {},
      defaultValues: {}
    } as {
      selectedValues: GroupSelectedValue;
      defaultValues: GroupDefaultValues;
    }
  );

  return {
    selectedFiltersCount: getAmountOfSelectedGroupFilters(selectedValues),
    optionList: {
      type: InputElement.CheckboxGroup,
      options: groupOptions,
      selectedValues,
      defaultValues,
      onChange: (values, key) => {
        dispatch(updateSelection(key, values));
      }
    },
    onReset: () => {
      const resetData = filters.map(({ optionKey, defaultValues }) => ({
        filerKey: optionKey,
        defaultValues
      }));

      dispatch(resetSelection(resetData));
    }
  };
};

export const serializeOutputFilters = ({
  filters,
  data
}: {
  filters: TableFilterSchema[];
  data: TableFilterReducerState;
}): Record<string, unknown> =>
  Object.keys(data).reduce((acc, key) => {
    const filter = filters.find(({ optionKey }) => optionKey === key);

    if (!filter) return acc;

    const { updateOutput } = filter;
    const value = data[key] as NonNullable<typeof data[typeof key]>;

    const { key: newKey, value: newValue } = updateOutput
      ? updateOutput(key, value)
      : { key, value };

    acc[newKey] = newValue;

    return acc;
  }, {} as Record<string, unknown>);

export const getTableFilterInitialState = ({
  filters
}: {
  filters: TableFilterSchema[];
}): TableFilterReducerState => {
  const defaultValues = filters.reduce((acc, filter) => {
    if (filter.defaultValues) {
      const { defaultValues, optionKey } = filter;

      acc[optionKey] = defaultValues;
    }

    return acc;
  }, {} as TableFilterReducerState);

  return {
    ...tableFilterReducer.getInitialState(),
    ...defaultValues
  };
};
