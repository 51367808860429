import { ENDPOINTS, PopupResponse } from 'api';
import axios from 'axios';

export const fetchPopup = async (popupId: string): Promise<PopupResponse> => {
  const { data } = await axios.get<PopupResponse>(
    `${ENDPOINTS.POPUP}/${popupId}`
  );

  return data;
};
