import { yupResolver } from '@hookform/resolvers/yup';
import { FormConstructor, ModalContainer } from 'containers';
import { FormField, ModalType } from 'enum';
import { useModalActions, useUpdateAdminComment } from 'hooks';
import { useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { ParamType } from 'types';

import { UpdateCommentFormData } from './UpdateComment.types';
import {
  updateCommentValidationSchema,
  updateCustomerCommentFormSchema
} from './UpdateComment.utils';

export const UpdateComment = (): JSX.Element => {
  const [t] = useTranslation();

  const form = useForm<UpdateCommentFormData>({
    mode: 'onSubmit',
    resolver: yupResolver(updateCommentValidationSchema)
  });

  const {
    handleSubmit,
    reset,
    formState: { dirtyFields }
  } = form;

  const { isOpen, onClose, meta } = useModalActions(
    ModalType.EditAdminComment,
    {
      onClose: reset
    }
  );

  useEffect(() => {
    if (meta) {
      const { imageUrl, text } = meta;

      const files = imageUrl
        ? [imageUrl].map((url) => ({
            file: new File([url], url),
            url
          }))
        : [];

      reset({
        [FormField.Comment]: text,
        [FormField.Files]: files
      });
    }
  }, [meta, reset]);

  const { mutate, isLoading } = useUpdateAdminComment();

  const onSubmit = handleSubmit((data) => {
    if (meta) {
      mutate(
        {
          commentId: meta.commentId,
          comment: data.comment as string
        },
        {
          onSuccess: onClose
        }
      );
    }
  });

  const actions: ParamType<typeof ModalContainer, 'footerButtons'> = [
    {
      text: t('actions.discard'),
      variant: 'ghostGray'
    },
    {
      text: t('actions.save_changes'),
      colorScheme: 'primary',
      isLoading,
      isPreventClose: true,
      isDisabled: !dirtyFields[FormField.Comment],
      onClick: () => {
        onSubmit();
      }
    }
  ];

  return (
    <ModalContainer
      header={t('attribute.title.edit_admin_comment')}
      footerButtons={actions}
      isOpen={isOpen}
      onClose={onClose}
      size="xl"
    >
      <FormProvider {...form}>
        <FormConstructor formSchema={updateCustomerCommentFormSchema} />
      </FormProvider>
    </ModalContainer>
  );
};
