import { Box, Button, Spinner, VStack } from '@chakra-ui/react';
import { ReportAdminListResponse } from 'api';
import { AddButton, CheckIcon, Table } from 'components';
import { ModalType } from 'enum';
import { ReportType } from 'enum/api';
import {
  useAdminReportList,
  useAppDispatch,
  useCustomerIdFromUrl,
  useTableQueryController
} from 'hooks';
import { useMarkReport } from 'pages/Reports/useMarkReport';
import { useTranslation } from 'react-i18next';
import { openModal } from 'store';

import { adminReportsColumns } from '../Reports.utils';

export const AdminReports = (): JSX.Element => {
  const [t] = useTranslation();
  const dispatch = useAppDispatch();

  const customerId = useCustomerIdFromUrl();

  const {
    queryFilters,
    action: { onChangeSort, onChangePagination }
  } = useTableQueryController({
    fixedValues: {
      filter: {
        type: ReportType.Profile,
        userIds: [customerId]
      }
    }
  });

  const { data } = useAdminReportList(queryFilters);

  const { isDisabled, isLoading, label, onHandleClick, tableProps } =
    useMarkReport<ReportAdminListResponse>();

  const handleCreateReport = () => {
    dispatch(
      openModal({
        id: ModalType.CreateReport,
        meta: {
          customerId
        }
      })
    );
  };

  if (!data) return <Spinner />;

  return (
    <Box>
      <VStack alignItems="flex-end">
        <AddButton onClick={handleCreateReport}>
          {t('actions.create_report')}
        </AddButton>

        <Button
          onClick={onHandleClick}
          variant="link"
          leftIcon={<CheckIcon />}
          isDisabled={isDisabled}
          isLoading={isLoading}
        >
          {label}
        </Button>
      </VStack>
      {!!data && (
        <Table
          data={data.data}
          columns={adminReportsColumns}
          onChangeSort={onChangeSort}
          onChangePagination={onChangePagination}
          totalItems={data.totalItems}
          pagination={queryFilters.pagination}
          hasHorizontalScroll
          {...tableProps}
        />
      )}
    </Box>
  );
};
