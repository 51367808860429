import { Grid, GridItem } from '@chakra-ui/react';
import { FormElement } from 'containers';
import { FormField } from 'enum';
import { Fragment } from 'react';
import { useTranslation } from 'react-i18next';

import { GeneralSettingsFormField } from '../../FormContent.types';

import { rateForAutomaticallyBlockFormFields } from './RateForAutoBlock.utils';

export const RateForAutoBlock = (): JSX.Element => {
  const [t] = useTranslation();

  return (
    <Fragment>
      <GridItem>
        {t('attribute.description.rate_for_automatically_block')}
      </GridItem>
      <GridItem>
        <Grid
          templateAreas={`
            "${FormField.MessageAmount} ${FormField.Period}"
            "${FormField.BadWords} ${FormField.BadWords}"
          `}
          templateColumns="1fr 1fr"
          columnGap={3}
          rowGap={6}
        >
          {rateForAutomaticallyBlockFormFields.map((formField) => (
            <GridItem key={formField.name} area={formField.name}>
              <FormElement
                {...formField}
                name={`${GeneralSettingsFormField.RateForAutoBlock}.${formField.name}`}
              />
            </GridItem>
          ))}
        </Grid>
      </GridItem>
    </Fragment>
  );
};
