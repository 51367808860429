import { useAppSelector } from 'hooks';
import { useEffect } from 'react';
import { localStorageService } from 'services';
import { selectAppFilters } from 'store';

export const useWatchCountryFilter = (): null => {
  const { country } = useAppSelector(selectAppFilters);

  useEffect(() => {
    localStorageService.saveCountryFilter(country);
  }, [country]);

  return null;
};
