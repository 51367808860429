import { ENDPOINTS, WithPagination } from 'api';
import { serializeBodyFilters } from 'api/api.utils';
import axios from 'axios';
import { ModerationPostStatus } from 'enum';
import { GlobalFilters } from 'types';
import { DEFAULT_PAGINATION_LIMIT } from 'utils';

import { ModerationGalleryPhotoResponse } from '../Moderation.types';

export const fetchModerationGalleryPhoto = async ({
  status,
  globalFilters,
  pageParam = 1
}: {
  status: ModerationPostStatus;
  globalFilters: GlobalFilters;
  pageParam?: number;
}): Promise<WithPagination<ModerationGalleryPhotoResponse[]>> => {
  const { data } = await axios.post<
    WithPagination<ModerationGalleryPhotoResponse[]>
  >(`${ENDPOINTS.MODERATION_PHOTO}/${status}/list`, {
    ...serializeBodyFilters({ globalFilters }),
    pagination: {
      page: pageParam,
      limit: DEFAULT_PAGINATION_LIMIT
    }
  });

  return data;
};
