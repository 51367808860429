import { Grid, GridItem } from '@chakra-ui/react';
import { FormElement } from 'containers';
import { Fragment } from 'react';
import { useTranslation } from 'react-i18next';

import { GeneralSettingsFormField } from '../../FormContent.types';

import { filesStorageParamsFormFields } from './FilesStorageParams.utils';

export const FilesStorageParams = (): JSX.Element => {
  const [t] = useTranslation();

  return (
    <Fragment>
      <GridItem>{t('attribute.description.files_storage_params')}</GridItem>
      <GridItem>
        <Grid
          templateAreas={`
            "${GeneralSettingsFormField.StorageFolderName}"
            "${GeneralSettingsFormField.StorageMaxEmailsImagesMale}"
            "${GeneralSettingsFormField.StorageMaxEmailsImagesFemale}"
            "${GeneralSettingsFormField.StorageMaxNotificationsImagesMale}"
            "${GeneralSettingsFormField.StorageMaxNotificationsImagesFemale}"
          `}
          templateColumns="1fr"
          rowGap={6}
        >
          {filesStorageParamsFormFields.map((formField) => (
            <GridItem key={formField.name} area={formField.name}>
              <FormElement {...formField} />
            </GridItem>
          ))}
        </Grid>
      </GridItem>
    </Fragment>
  );
};
