import { Grid, GridItem } from '@chakra-ui/react';
import { useRef } from 'react';
import { Outlet } from 'react-router-dom';

import { Header, SideNav } from './components';

export const AppLayout = (): JSX.Element => {
  const rightSideRef = useRef<HTMLDivElement>(null);
  const subHeaderRef = useRef<HTMLDivElement>(null);

  return (
    <Grid
      width="full"
      height="full"
      templateAreas={`
      "side-nav header header"
      "side-nav sub-header sub-header"
      "side-nav content right-side"
      `}
      gridTemplateRows="auto auto 1fr"
      gridTemplateColumns="16rem 1fr auto"
      columnGap="8"
    >
      <GridItem as="aside" area="side-nav">
        <SideNav />
      </GridItem>
      <GridItem as="header" area="header" marginRight={8} marginBottom={6}>
        <Header />
      </GridItem>
      <GridItem
        area="sub-header"
        marginBottom={6}
        marginRight={8}
        _empty={{
          display: 'none'
        }}
        ref={subHeaderRef}
      />
      <GridItem as="main" area="content" overflow="hidden" marginBottom={6}>
        <Outlet
          context={{
            rightSideRef,
            subHeaderRef
          }}
        />
      </GridItem>
      <GridItem
        as="aside"
        area="right-side"
        marginRight={8}
        minWidth={64}
        _empty={{
          display: 'none'
        }}
        ref={rightSideRef}
      />
    </Grid>
  );
};
