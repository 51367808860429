import { CrmFiltersRequestData } from 'api';
import { FormField, InputElement } from 'enum';
import { AllowedMarketing, OptionKey, ParameterType } from 'enum/api';
import { GetOptionsArg } from 'types/form';
import {
  generateCityOptionsByCountries,
  generateOptionsFromSettings,
  generateStatesOptionsByCountries
} from 'utils';

import { CRMFormData } from './CRM.types';

export const CRMFormSchema = {
  fields: {
    [FormField.RegistrationDateTime]: {
      type: InputElement.DateTimeRangePicker,
      translationKey: 'registration_date_and_time'
    },
    [FormField.FinishRegistrationDateTime]: {
      type: InputElement.DateTimeRangePicker,
      translationKey: 'finish_registration_date_and_time'
    },
    [FormField.CustomerType]: {
      type: InputElement.MultiSelect,
      translationKey: 'customer_type',
      getPlaceholder: () => 'Customer type',
      getOptions: ({ t, settings }: GetOptionsArg) =>
        generateOptionsFromSettings(ParameterType.CustomerType, t, settings)
    },
    [FormField.Gender]: {
      type: InputElement.MultiSelect,
      translationKey: 'gender',
      getPlaceholder: () => 'Select',
      getOptions: ({ t, settings }: GetOptionsArg) =>
        generateOptionsFromSettings(ParameterType.Gender, t, settings)
    },
    [FormField.Country]: {
      type: InputElement.MultiSelect,
      translationKey: 'country',
      getPlaceholder: () => 'Select country',
      isSearchable: true,
      getOptions: ({ t, settings }: GetOptionsArg) =>
        generateOptionsFromSettings(OptionKey.Country, t, settings)
    },
    [FormField.State]: {
      type: InputElement.MultiSelect,
      translationKey: 'state',
      getPlaceholder: () => 'Select state',
      isSearchable: true,
      getOptions: ({ t, getFormValues }: GetOptionsArg) =>
        generateStatesOptionsByCountries({
          t,
          countryCodes: getFormValues(FormField.Country)
        })
    },
    [FormField.City]: {
      type: InputElement.MultiSelect,
      translationKey: 'city',
      getPlaceholder: () => 'Select city',
      isSearchable: true,
      couldSelectAll: true,
      getOptions: ({ t, getFormValues }: GetOptionsArg) =>
        generateCityOptionsByCountries({
          t,
          countryCodes: getFormValues(FormField.Country)
        })
    },
    [FormField.ProgramType]: {
      type: InputElement.MultiSelect,
      translationKey: 'program_type',
      getPlaceholder: () => 'Select',
      getOptions: ({ t, settings }: GetOptionsArg) =>
        generateOptionsFromSettings(ParameterType.ProgramType, t, settings)
    },
    [FormField.PaymentPackageType]: {
      type: InputElement.MultiSelect,
      translationKey: 'payment_package_type',
      getPlaceholder: () => 'Select',
      getOptions: ({ t, settings }: GetOptionsArg) =>
        generateOptionsFromSettings(OptionKey.SubscriptionType, t, settings)
    },
    [FormField.BirthDate]: {
      type: InputElement.DateRangePicker,
      translationKey: 'birth_date'
    },
    [FormField.Label]: {
      type: InputElement.MultiSelect,
      translationKey: 'labels',
      getPlaceholder: () => 'Select',
      getOptions: ({ t, settings }: GetOptionsArg) =>
        generateOptionsFromSettings(ParameterType.Label, t, settings)
    },
    [FormField.MessagesSent]: {
      type: InputElement.NumberInput,
      translationKey: 'number_of_messages_sent',
      getPlaceholder: () => '',
      min: 0,
      precision: 0
    },
    [FormField.MessagesReceived]: {
      type: InputElement.NumberInput,
      translationKey: 'number_of_messages_received',
      getPlaceholder: () => '',
      min: 0,
      precision: 0
    },
    [FormField.LikesReceived]: {
      type: InputElement.NumberInput,
      translationKey: 'amount_of_likes_received',
      getPlaceholder: () => '',
      min: 0
    },
    [FormField.LikesGave]: {
      type: InputElement.NumberInput,
      translationKey: 'amount_of_likes_gave',
      getPlaceholder: () => '',
      min: 0
    },
    [FormField.FavouritesReceived]: {
      type: InputElement.NumberInput,
      translationKey: 'amount_of_favourites_received',
      getPlaceholder: () => '',
      min: 0
    },
    [FormField.FavouritesGave]: {
      type: InputElement.NumberInput,
      translationKey: 'amount_of_favourites_gave',
      getPlaceholder: () => '',
      min: 0
    },
    [FormField.AllowedMarketing]: {
      type: InputElement.Select,
      translationKey: 'allowed_marketing',
      getPlaceholder: () => 'Allowed Marketing',
      getOptions: ({ t, settings }: GetOptionsArg) =>
        generateOptionsFromSettings(OptionKey.AllowedMarketing, t, settings)
    }
  },
  gridLayout: {
    templateAreas: `
      "${FormField.RegistrationDateTime} ${FormField.RegistrationDateTime}"
      "${FormField.FinishRegistrationDateTime} ${FormField.FinishRegistrationDateTime}"
      "${FormField.CustomerType} ${FormField.CustomerType}"
      "${FormField.Gender} ${FormField.Gender}"
      "${FormField.Country} ${FormField.Country}"
      "${FormField.City} ${FormField.City}"
      "${FormField.State} ${FormField.State}"
      "${FormField.ProgramType} ${FormField.ProgramType}"
      "${FormField.PaymentPackageType} ${FormField.PaymentPackageType}"
      "${FormField.BirthDate} ${FormField.AllowedMarketing}"
      "${FormField.Label} ${FormField.Label}"
      "${FormField.MessagesSent} ${FormField.MessagesReceived}"
      "${FormField.LikesReceived} ${FormField.LikesGave}"
      "${FormField.FavouritesReceived} ${FormField.FavouritesGave}"
    `,
    gridTemplateColumns: '1fr 1fr',
    rowGap: 6,
    columnGap: 10,
    alignItems: 'center'
  },
  dependedFields: {
    [FormField.Country]: [FormField.City, FormField.State] as FormField[]
  }
} as const;

export const serializeRequestData = ({
  allowedMarketing,
  ...formData
}: CRMFormData): CrmFiltersRequestData =>
  ({
    ...formData,
    ...(allowedMarketing && {
      allowedAds: allowedMarketing === AllowedMarketing.Allowed
    })
  } as unknown as CrmFiltersRequestData);
