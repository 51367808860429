import {
  CategoryIcon,
  ChartBarIcon,
  ChartLineIcon,
  ChatCircleIcon,
  CreditCardIcon,
  CrmIcon,
  DangerIcon,
  EditRectangleIcon,
  FanDeckIcon,
  LightningIcon,
  SettingsIcon,
  UserIcon,
  UsersIcon
} from 'components';
import { PageName } from 'enum';
import {
  useAppSelector,
  useModerationItemsTotal,
  useReportItemsTotal,
  useTicketsActiveTotal
} from 'hooks';
import sum from 'lodash/sum';
import { useTranslation } from 'react-i18next';
import { SIDE_NAV_LINK_BY_PAGE } from 'routes/routes.utils';
import { selectPermissions } from 'store/user';

import { SideNavItem } from './SideNav.types';
import { getIsDisabledNavigation } from './utils';

export const useSideNav = (): SideNavItem[] => {
  const [t] = useTranslation();

  const userPermissions = useAppSelector(selectPermissions);

  const isDisabledModeration = getIsDisabledNavigation(
    PageName.Moderation,
    userPermissions
  );
  const isDisabledContactUs = getIsDisabledNavigation(
    PageName.ContactUs,
    userPermissions
  );
  const isDisabledReports = getIsDisabledNavigation(
    PageName.Reports,
    userPermissions
  );

  const { data: moderationTotalItems } = useModerationItemsTotal({
    enabled: !isDisabledModeration
  });
  const { data: contactUsTotalItems } = useTicketsActiveTotal({
    enabled: !isDisabledContactUs
  });
  const { data: reportsTotalItems } = useReportItemsTotal({
    enabled: !isDisabledReports
  });

  const moderationCount = sum(Object.values(moderationTotalItems));

  return [
    {
      id: PageName.AdminPanel,
      label: t(`attribute.title.${PageName.AdminPanel}`),
      icon: ChartBarIcon,
      isDisabled: getIsDisabledNavigation(PageName.AdminPanel, userPermissions),
      to: SIDE_NAV_LINK_BY_PAGE[PageName.AdminPanel]
    },
    {
      id: PageName.AdminManagement,
      label: t(`attribute.title.${PageName.AdminManagement}`),
      icon: UserIcon,
      isDisabled: getIsDisabledNavigation(
        PageName.AdminManagement,
        userPermissions
      ),
      to: SIDE_NAV_LINK_BY_PAGE[PageName.AdminManagement]
    },
    {
      id: PageName.Customers,
      label: t(`attribute.title.${PageName.Customers}`),
      icon: UsersIcon,
      isDisabled: getIsDisabledNavigation(PageName.Customers, userPermissions),
      to: SIDE_NAV_LINK_BY_PAGE[PageName.Customers]
    },
    {
      id: PageName.Settings,
      label: t(`attribute.title.${PageName.Settings}`),
      icon: SettingsIcon,
      isDisabled: getIsDisabledNavigation(PageName.Settings, userPermissions),
      to: SIDE_NAV_LINK_BY_PAGE[PageName.Settings]
    },
    {
      id: PageName.Moderation,
      label: t(`attribute.title.${PageName.Moderation}`),
      icon: LightningIcon,
      isDisabled: isDisabledModeration,
      to: SIDE_NAV_LINK_BY_PAGE[PageName.Moderation],
      badgeValue: moderationCount
    },
    {
      id: PageName.LogActivity,
      label: t(`attribute.title.${PageName.LogActivity}`),
      icon: ChartLineIcon,
      isDisabled: getIsDisabledNavigation(
        PageName.LogActivity,
        userPermissions
      ),
      to: SIDE_NAV_LINK_BY_PAGE[PageName.LogActivity]
    },
    {
      id: PageName.PopupsManagement,
      label: t(`attribute.title.${PageName.PopupsManagement}`),
      icon: FanDeckIcon,
      isDisabled: getIsDisabledNavigation(
        PageName.PopupsManagement,
        userPermissions
      ),
      to: SIDE_NAV_LINK_BY_PAGE[PageName.PopupsManagement]
    },
    {
      id: PageName.ContactUs,
      label: t(`attribute.title.${PageName.ContactUs}`),
      icon: ChatCircleIcon,
      isDisabled: isDisabledContactUs,
      to: SIDE_NAV_LINK_BY_PAGE[PageName.ContactUs],
      badgeValue: contactUsTotalItems
    },
    {
      id: PageName.Reports,
      label: t(`attribute.title.${PageName.Reports}`),
      icon: DangerIcon,
      isDisabled: isDisabledReports,
      to: SIDE_NAV_LINK_BY_PAGE[PageName.Reports],
      badgeValue: reportsTotalItems
    },
    {
      id: PageName.PaymentsManagement,
      label: t(`attribute.title.${PageName.PaymentsManagement}`),
      icon: CreditCardIcon,
      isDisabled: getIsDisabledNavigation(
        PageName.PaymentsManagement,
        userPermissions
      ),
      to: SIDE_NAV_LINK_BY_PAGE[PageName.PaymentsManagement]
    },
    {
      id: PageName.BlogManagement,
      label: t(`attribute.title.${PageName.BlogManagement}`),
      icon: EditRectangleIcon,
      isDisabled: getIsDisabledNavigation(
        PageName.BlogManagement,
        userPermissions
      ),
      to: SIDE_NAV_LINK_BY_PAGE[PageName.BlogManagement]
    },
    {
      id: PageName.Content,
      label: t(`attribute.title.${PageName.Content}`),
      icon: UsersIcon,
      isDisabled: getIsDisabledNavigation(PageName.Content, userPermissions),
      to: SIDE_NAV_LINK_BY_PAGE[PageName.Content]
    },
    {
      id: PageName.CRM,
      label: t(`attribute.title.${PageName.CRM}`),
      icon: CrmIcon,
      isDisabled: getIsDisabledNavigation(PageName.CRM, userPermissions),
      to: SIDE_NAV_LINK_BY_PAGE[PageName.CRM]
    },
    {
      id: PageName.TablesManagement,
      label: t(`attribute.title.${PageName.TablesManagement}`),
      icon: CategoryIcon,
      isDisabled: getIsDisabledNavigation(
        PageName.TablesManagement,
        userPermissions
      ),
      to: SIDE_NAV_LINK_BY_PAGE[PageName.TablesManagement]
    },
    {
      id: PageName.StaticPages,
      label: t(`attribute.title.${PageName.StaticPages}`),
      icon: EditRectangleIcon,
      isDisabled: getIsDisabledNavigation(
        PageName.StaticPages,
        userPermissions
      ),
      to: SIDE_NAV_LINK_BY_PAGE[PageName.StaticPages]
    },
    {
      id: PageName.DomainsConfig,
      label: t(`attribute.title.${PageName.DomainsConfig}`),
      icon: EditRectangleIcon,
      isDisabled: getIsDisabledNavigation(
        PageName.DomainsConfig,
        userPermissions
      ),
      to: SIDE_NAV_LINK_BY_PAGE[PageName.DomainsConfig]
    }
  ];
};
