import { Image } from '@chakra-ui/react';
import { createColumnHelper } from '@tanstack/react-table';
import { PaymentMethodListResponse } from 'api';
import { CountryList } from 'components';

import {
  EditCountriesCell,
  EnabledSuccessOnFailCheckbox,
  ShowOnWebsiteCheckbox
} from '../components';

const columnHelper = createColumnHelper<PaymentMethodListResponse>();

export const paymentMethodColumns = [
  columnHelper.accessor('title', {
    header: ({ table }) =>
      table.options.meta?.t('keywords.payment_method_name'),
    enableSorting: false
  }),
  columnHelper.accessor('displayName', {
    header: ({ table }) => table.options.meta?.t('keywords.display_name'),
    enableSorting: false
  }),
  columnHelper.accessor('id', {
    header: ({ table }) => table.options.meta?.t('keywords.payment_method_id'),
    enableSorting: false
  }),
  columnHelper.accessor('imageUrl', {
    cell: ({ getValue }) => (
      <Image
        boxSize={10}
        minWidth={10}
        src={getValue()}
        borderRadius="md"
        objectFit="cover"
      />
    ),
    header: ({ table }) =>
      table.options.meta?.t('keywords.payment_method_image'),
    enableSorting: false
  }),
  columnHelper.accessor('countries', {
    cell: ({ getValue }) => <CountryList countryCodes={getValue()} />,
    header: ({ table }) =>
      table.options.meta?.t('keywords.assigned_country', { count: 2 }),
    enableSorting: false
  }),
  columnHelper.accessor('showOnWebsite', {
    cell: ({ getValue, row }) => (
      <ShowOnWebsiteCheckbox
        isChecked={getValue()}
        methodId={row.original.id}
      />
    ),
    header: ({ table }) =>
      table.options.meta?.t('keywords.show_on_website', { count: 2 }),
    enableSorting: false
  }),
  columnHelper.accessor('enabledSuccessOnFail', {
    cell: ({ getValue, row }) => (
      <EnabledSuccessOnFailCheckbox
        isChecked={getValue()}
        methodId={row.original.id}
      />
    ),
    header: ({ table }) =>
      table.options.meta?.t('keywords.enabled_success_on_fail', { count: 2 }),
    enableSorting: false
  }),
  columnHelper.accessor('paymentSystem', {
    header: ({ table }) => table.options.meta?.t('keywords.payment_system'),
    enableSorting: false
  }),
  columnHelper.display({
    id: 'actions',
    cell: ({ row }) => (
      <EditCountriesCell
        methodId={row.original.id}
        countries={row.original.countries}
      />
    ),
    header: () => null,
    enableSorting: false
  })
];
