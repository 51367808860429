import { ButtonProps, chakra } from '@chakra-ui/react';
import { ReactNode } from 'react';

type Props = ButtonProps & {
  children: ReactNode;
};

export const MenuOptionItem = ({ children, ...rest }: Props): JSX.Element => (
  <chakra.button
    sx={{
      cursor: 'pointer',
      px: 3,
      py: 3,
      display: 'flex',
      alignItems: 'center',
      textAlign: 'start',
      flex: '0 0 auto',
      width: '100%',
      _hover: {
        backgroundColor: 'primary.100'
      },
      _focus: {
        backgroundColor: 'primary.100'
      },
      _disabled: {
        backgroundColor: 'disabled',
        cursor: 'default',
        pointerEvents: 'none'
      },
      _selected: {
        backgroundColor: 'primary.100'
      },
      _readOnly: {
        pointerEvents: 'none'
      }
    }}
    {...rest}
  >
    {children}
  </chakra.button>
);
