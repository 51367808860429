import { CloseIcon } from '@chakra-ui/icons';
import { Badge, Box, Button, Wrap, WrapItem } from '@chakra-ui/react';
import { Dropdown } from 'components';
import { DropdownType } from 'enum';
import { useTranslation } from 'react-i18next';

import { useTableFilters } from '../useTableFilters';

type Props = ReturnType<typeof useTableFilters> & {
  shouldShowClearAll?: boolean;
};

export const FilterButtons = ({
  singleFilters,
  groupFilter,
  handleClearSelection,
  shouldShowClearAll = false
}: Props): JSX.Element => {
  const [t] = useTranslation();

  return (
    <Wrap spacing={3}>
      {singleFilters.map(({ id, label, optionList, onReset }) => (
        <WrapItem key={id}>
          <Dropdown
            type={DropdownType.Button}
            list={optionList}
            label={label}
            onReset={onReset}
          />
        </WrapItem>
      ))}
      {groupFilter && (
        <WrapItem>
          <Dropdown
            type={DropdownType.Button}
            label={
              <Box as="span">
                <Box marginRight={1} as="span">
                  {t('keywords.more_filters')}
                </Box>
                {groupFilter.selectedFiltersCount > 0 ? (
                  <Badge>{groupFilter.selectedFiltersCount}</Badge>
                ) : (
                  t('keywords.all')
                )}
              </Box>
            }
            onReset={groupFilter.onReset}
            list={groupFilter.optionList}
            menuButtonProps={{
              background: 'white',
              border: '1px',
              borderColor: 'gray.200'
            }}
          />
        </WrapItem>
      )}
      {shouldShowClearAll && (
        <WrapItem>
          <Button
            variant="ghostWhite"
            leftIcon={<CloseIcon boxSize="2" />}
            iconSpacing="2"
            onClick={handleClearSelection}
          >
            {t('actions.clear_all')}
          </Button>
        </WrapItem>
      )}
    </Wrap>
  );
};
