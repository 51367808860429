import { ParameterType } from 'enum/api';
import { useParameterTypeTranslation } from 'hooks';
import { Fragment } from 'react';

type Props = {
  optionKey: ParameterType;
  value: string;
};

export const ParameterTypeOptionTranslateCell = ({
  optionKey,
  value
}: Props): JSX.Element => {
  const { getTranslation } = useParameterTypeTranslation();

  return <Fragment>{getTranslation(optionKey, value)}</Fragment>;
};
