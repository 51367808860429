import { Text } from '@chakra-ui/react';
import { yupResolver } from '@hookform/resolvers/yup';
import { ModalContainer, FormConstructor } from 'containers';
import { ModalType } from 'enum';
import { useCancelCustomerSubscription, useModalActions } from 'hooks';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { ParamType } from 'types';
import { getFormFieldsListBySchema } from 'utils';
import { isNotEmpty } from 'utils/validation';

import { CancelCustomerSubscriptionFormData } from './CancelCustomerSubscription.types';
import {
  cancelCustomerSubscriptionValidationSchema,
  cancelCustomerSubscriptionFormSchema
} from './CancelCustomerSubscription.utils';

export const CancelCustomerSubscription = (): JSX.Element => {
  const [t] = useTranslation();

  const { mutate, isLoading, reset } = useCancelCustomerSubscription();

  const { isOpen, onClose, meta } = useModalActions(
    ModalType.CancelCustomerSubscription,
    {
      onClose: reset
    }
  );

  const form = useForm<CancelCustomerSubscriptionFormData>({
    mode: 'onSubmit',
    resolver: yupResolver(cancelCustomerSubscriptionValidationSchema)
  });

  const {
    handleSubmit,
    formState: { dirtyFields }
  } = form;

  const isSubmitDisabled = !isNotEmpty(
    dirtyFields,
    getFormFieldsListBySchema(cancelCustomerSubscriptionFormSchema)
  );

  const onSubmit = (onSuccess: () => void) =>
    handleSubmit((data) => {
      const { comment } = data;

      mutate(
        { customerId: meta?.customerId || '', comment: comment as string },
        {
          onSuccess
        }
      );
    });

  const actions: ParamType<typeof ModalContainer, 'footerButtons'> = [
    {
      text: t('actions.no'),
      variant: 'ghostGray'
    },
    {
      text: t('actions.yes'),
      colorScheme: 'secondary',
      isLoading,
      isPreventClose: true,
      isDisabled: isSubmitDisabled,
      onClick: (onClose) => {
        onSubmit(onClose)();
      }
    }
  ];

  return (
    <ModalContainer
      header={t('attribute.title.cancel_customer_subscription')}
      footerButtons={actions}
      isOpen={isOpen}
      onClose={onClose}
      size="xl"
    >
      <Text marginBottom="20px">
        {t('attribute.description.basic_action_question', {
          action: t(
            'attribute.title.cancel_customer_subscription'
          ).toLowerCase()
        })}
      </Text>
      <FormProvider {...form}>
        <FormConstructor formSchema={cancelCustomerSubscriptionFormSchema} />
      </FormProvider>
    </ModalContainer>
  );
};
