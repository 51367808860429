import { RequiredNotNullable } from 'types';

export const isRequiredFields = <T extends Record<string, unknown> = {}>(
  fields: T
): fields is RequiredNotNullable<T> =>
  Object.keys(fields).every((key) => !!fields[key]);

export const isNotEmpty = <T extends Record<string, unknown> = {}>(
  fields: T,
  keys: (keyof T)[]
): fields is T => keys.every((key) => key in fields);

export const LATIN_CHARACTERS_REGEXP =
  /^([A-Za-z\u00C0-\u00D6\u00D8-\u00f6\u00f8-\u00ff\s]*)$/gi;

export const DIGITS_ONLY_REGEXP = /^\d+$/;
