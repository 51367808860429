import {
  Checkbox,
  CheckboxGroup,
  FormControl,
  FormHelperText,
  FormLabel,
  Stack
} from '@chakra-ui/react';
import { FormError } from 'components';
import { InputElement } from 'enum';
import { useAppSelector } from 'hooks';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { selectSettings } from 'store/settings';

import { FormComponentProps } from '../FormConstructor.types';

type Props = FormComponentProps<{ type: InputElement.CheckboxGroup }>;

export const FormCheckboxGroup = (props: Props): JSX.Element => {
  const [t] = useTranslation();
  const settings = useAppSelector(selectSettings);

  const { control, formState, getFieldState, getValues } = useFormContext();

  const { translationKey, name, hintTranslationKey, getOptions, isDisabled } =
    props;

  const { error } = getFieldState(name, formState);

  const options = getOptions({
    t,
    settings,
    getFormValues: getValues
  });

  return (
    <FormControl isInvalid={!!error}>
      {!!translationKey && (
        <FormLabel>{t(`keywords.${translationKey}`)}</FormLabel>
      )}
      <Controller
        control={control}
        name={name}
        render={({ field: { onChange, value } }) => (
          <CheckboxGroup
            value={value}
            onChange={onChange}
            isDisabled={isDisabled}
          >
            <Stack spacing={4} direction="column" alignItems="flex-start">
              {options.map((option) => (
                <Checkbox key={option.value} value={option.value}>
                  {option.label}
                </Checkbox>
              ))}
            </Stack>
          </CheckboxGroup>
        )}
      />
      {!!hintTranslationKey && !error && (
        <FormHelperText>
          {t(`attribute.hint.${hintTranslationKey}`)}
        </FormHelperText>
      )}
      <FormError errorKey={error?.message} />
    </FormControl>
  );
};
