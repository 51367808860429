import { Button, VStack } from '@chakra-ui/react';
import { DomainConfigListResponse } from 'api';
import { ModalType } from 'enum';
import { useAppDispatch } from 'hooks';
import { useTranslation } from 'react-i18next';
import { openModal } from 'store';

type Props = {
  domainConfig: DomainConfigListResponse;
  isDefaultConfig: boolean;
};

export const ActionCell = ({
  domainConfig,
  isDefaultConfig
}: Props): JSX.Element => {
  const [t] = useTranslation();
  const dispatch = useAppDispatch();

  const onDeleteDomainConfig = () => {
    dispatch(
      openModal({
        id: ModalType.DeleteDomainConfig,
        meta: {
          domainConfigId: domainConfig.id
        }
      })
    );
  };

  const onEditPopup = (isDefaultConfig: boolean) => {
    dispatch(
      openModal({
        id: ModalType.EditDomainConfig,
        meta: {
          domainConfig,
          isDefaultConfig
        }
      })
    );
  };

  return (
    <VStack>
      <Button
        variant="link"
        colorScheme="primary"
        onClick={() => onEditPopup(isDefaultConfig)}
      >
        {isDefaultConfig ? t('actions.view') : t('actions.edit')}
      </Button>
      {!isDefaultConfig && (
        <Button variant="link" colorScheme="red" onClick={onDeleteDomainConfig}>
          {t('actions.delete')}
        </Button>
      )}
    </VStack>
  );
};
