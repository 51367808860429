import { ENDPOINTS, LogActivityResponse, WithPagination } from 'api';
import { serializeBodyFilters } from 'api/api.utils';
import axios from 'axios';
import { AdminActivityAction, AdminActivityScope } from 'enum/api';
import { GlobalFilters, QueryFilters } from 'types';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const fakedData: LogActivityResponse[] = [
  {
    actedAt: new Date().toISOString(),
    description: AdminActivityAction.LoginAdmin,
    scope: AdminActivityScope.CustomersManagement,
    admin: {
      nickname: 'admin_1',
      countries: ['BY', 'US']
    }
  }
];

export const fetchLogActivity = async ({
  queryFilters,
  globalFilters
}: {
  queryFilters: QueryFilters;
  globalFilters: GlobalFilters;
}): Promise<WithPagination<LogActivityResponse[]>> => {
  const { data } = await axios.post<WithPagination<LogActivityResponse[]>>(
    ENDPOINTS.LOG_ACTIVITY_LIST,
    serializeBodyFilters({ queryFilters, globalFilters })
  );

  return data;
};
