import { useQuery } from '@tanstack/react-query';
import { fetchPurchasedSubscriptions } from 'api/AdminPanel/fetchPurchasedSubscriptions';
import { QueryKey } from 'enum/api';
import { useAppSelector } from 'hooks';
import { selectAppFilters } from 'store/app';
import { ChartFilters } from 'types';

export const usePurchasedSubscriptions = ({
  filters
}: {
  filters: ChartFilters;
}) => {
  const globalFilters = useAppSelector(selectAppFilters);

  return useQuery(
    [QueryKey.StatisticPurchasedSubscriptions, globalFilters, filters],
    () => fetchPurchasedSubscriptions({ globalFilters, filters }),
    {
      retry: false,
      refetchOnWindowFocus: false,
      keepPreviousData: true
    }
  );
};
