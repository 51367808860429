import { Box, Text, useControllableState } from '@chakra-ui/react';
import MDEditor, { MDEditorProps } from '@uiw/react-md-editor';
import { useEffect, useRef } from 'react';

type Props = Omit<MDEditorProps, 'value' | 'onChange'> & {
  value?: string;
  onChange?: (value: string) => void;
  shouldSizeByContent?: boolean;
  maxLength?: number;
};

export const MdEditor = (props: Props): JSX.Element => {
  const { value, onChange, shouldSizeByContent = false, ...rest } = props;

  const ref = useRef<HTMLTextAreaElement>(null);

  const [text, setText] = useControllableState({
    value,
    onChange
  });

  useEffect(() => {
    if (ref.current && shouldSizeByContent) {
      const element = ref.current;
      element.style.height = element.scrollHeight + 'px';

      const handleInput = () => {
        element.style.height = 'auto';
        element.style.height = element.scrollHeight + 'px';
      };

      element.addEventListener('input', handleInput);

      return element.removeEventListener('input', handleInput);
    }
  }, [shouldSizeByContent]);

  const shouldShowCounter = !!props.maxLength;
  const linkReg = /(<[Aa]\s(.*)<\/[Aa]>)/g;

  return (
    <Box position="relative" paddingX="1px">
      <MDEditor
        {...rest}
        ref={ref}
        value={text}
        onChange={(value) => setText(value!)}
      />
      {shouldShowCounter && (
        <Text
          sx={{
            position: 'absolute',
            bottom: 3,
            right: 3,
            color: 'subtext'
          }}
        >{`${text?.length || 0} / ${props.maxLength}`}</Text>
      )}
    </Box>
  );
};
