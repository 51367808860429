import { ReportPage, ReportSection } from 'enum';
import { getAllEnumValues } from 'enum-for';
import { ReportStatus, ReportType } from 'enum/api';
import { TFunction } from 'react-i18next';

import {
  AdminReportContent,
  AdminReports,
  CustomerReportContent,
  CustomerReports
} from './Pages';

export const REPORT_PAGE_LIST = getAllEnumValues(ReportPage);

export const REPORT_PAGE: Record<
  ReportPage,
  {
    sections: ReportSection[];
    content: ({ sections }: { sections: ReportSection[] }) => JSX.Element;
  }
> = {
  [ReportPage.Customer]: {
    sections: getAllEnumValues(ReportSection),
    content: CustomerReports
  },
  [ReportPage.Admin]: {
    sections: getAllEnumValues(ReportSection),
    content: AdminReports
  }
};

export const REPORT_SECTION_CONTENT: Record<
  ReportPage,
  Record<ReportSection, () => JSX.Element>
> = {
  [ReportPage.Customer]: {
    [ReportSection.ProfileReports]: () => (
      <CustomerReportContent reportType={ReportType.Profile} />
    ),
    [ReportSection.GalleryReports]: () => (
      <CustomerReportContent reportType={ReportType.Gallery} />
    ),
    [ReportSection.StoryReports]: () => (
      <CustomerReportContent reportType={ReportType.Story} />
    )
  },
  [ReportPage.Admin]: {
    [ReportSection.ProfileReports]: () => (
      <AdminReportContent reportType={ReportType.Profile} />
    ),
    [ReportSection.GalleryReports]: () => (
      <AdminReportContent reportType={ReportType.Gallery} />
    ),
    [ReportSection.StoryReports]: () => (
      <AdminReportContent reportType={ReportType.Story} />
    )
  }
};

export const getReportPageLabel = (page: ReportPage, t: TFunction): string => {
  if (page === ReportPage.Customer) {
    return t(`keywords.customer_report`, {
      count: 10
    });
  }

  return t(`keywords.admin_report`, {
    count: 10
  });
};

export const getTagColorSchemeByStatus = (status: ReportStatus) => {
  switch (status) {
    case ReportStatus.Open:
      return 'status.blue';
    case ReportStatus.InProgress:
      return 'status.green';
    case ReportStatus.Close:
    default:
      return 'status.gray';
  }
};
