import { Table } from 'components';
import { ModalContainer } from 'containers';
import { ModalType } from 'enum';
import { useCustomerNicknameHistory, useModalActions } from 'hooks';
import { useTranslation } from 'react-i18next';

import { nicknameColumns } from './NicknameHistory.utils';

export const NicknameHistory = (): JSX.Element => {
  const [t] = useTranslation();

  const { isOpen, onClose, meta } = useModalActions(ModalType.NicknameHistory);

  const { data } = useCustomerNicknameHistory(meta?.customerId || '');

  return (
    <ModalContainer
      header={t('attribute.title.nickname_history')}
      footerButtons={[
        {
          text: t('actions.ok')
        }
      ]}
      isOpen={isOpen}
      onClose={onClose}
      scrollBehavior="outside"
      size="xl"
    >
      {!!data && (
        <Table
          data={data}
          columns={nicknameColumns}
          totalItems={1}
          shouldHidePagination
          tableOptions={{
            manualSorting: false
          }}
        />
      )}
    </ModalContainer>
  );
};
