import { FormField, InputElement } from 'enum';

export const likesCountFormFields = [
  {
    type: InputElement.NumberInput,
    name: FormField.FreeLikesAmount,
    translationKey: 'free_user_likes_limit',
    getPlaceholder: () => '',
    min: 0
  },
  {
    type: InputElement.NumberInput,
    name: FormField.PremiumLikesAmountPerDay,
    translationKey: 'premium_user_likes_per_day_limit',
    getPlaceholder: () => '',
    min: 0
  }
] as const;
