import { useMutation } from '@tanstack/react-query';
import { fetchDeleteParameterValue } from 'api';
import { QueryKey } from 'enum/api';
import { queryClient } from 'services';

export const useDeleteParameterValue = () =>
  useMutation(fetchDeleteParameterValue, {
    onSettled: () => {
      queryClient.invalidateQueries([QueryKey.ParameterValues]);
    }
  });
