import { Box } from '@chakra-ui/react';
import { UserProfile } from 'components';
import { ModalContainer } from 'containers';
import { ModalType } from 'enum';
import { useModalActions } from 'hooks';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { ParamType } from 'types';
import { getBaseCustomerLinkNavigation } from 'utils';

export const ModerationUserProfile = (): JSX.Element => {
  const [t] = useTranslation();
  const navigate = useNavigate();

  const { isOpen, onClose, meta } = useModalActions(ModalType.UserProfile);

  const actions: ParamType<typeof ModalContainer, 'footerButtons'> = [
    {
      text: t('actions.visit_user_profile'),
      variant: 'ghostGray',
      onClick: () => {
        if (meta) {
          navigate(getBaseCustomerLinkNavigation(meta.user.id));
        }
      },
      onMouseDown: (event) => {
        if (meta) {
          if (event.button === 1) {
            window.open(
              getBaseCustomerLinkNavigation(meta.user.id),
              '_blank',
              'noopener,noreferrer'
            );
          }
        }
      }
    },
    {
      text: t('actions.ok'),
      colorScheme: 'primary'
    }
  ];

  return (
    <ModalContainer
      header={t('attribute.title.user_profile')}
      footerButtons={actions}
      isOpen={isOpen}
      onClose={onClose}
      size="xl"
    >
      {!!meta && (
        <Box textAlign="initial">
          <UserProfile user={meta.user} compareData={meta.compareData} />
        </Box>
      )}
    </ModalContainer>
  );
};
