import { ENDPOINTS } from 'api';
import axios from 'axios';
import { ImageStatus } from 'enum/api';
import { DateString } from 'types';
import { ICustomerMessageImages } from 'types/customer';

type CustomerMessageImagesApiResponse = {
  id: string;
  uploaded: DateString;
  statused: DateString;
  imageUrl: string;
  status: ImageStatus;
};

const photoUrl =
  'https://images.pexels.com/photos/220453/pexels-photo-220453.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const fakedData: ICustomerMessageImages[] = [
  {
    id: 'id_1',
    uploadedAt: new Date().toLocaleString(),
    statusedAt: new Date().toLocaleString(),
    imageUrl: photoUrl,
    status: ImageStatus.Active
  }
];

export const fetchCustomerMessageImages = async (
  customerId: string
): Promise<ICustomerMessageImages[]> => {
  const { data } = await axios.get<CustomerMessageImagesApiResponse[]>(
    `${ENDPOINTS.MESSAGE_IMAGES}/by-user/${customerId}`
  );

  return data.map(({ id, imageUrl, status, statused, uploaded }) => ({
    id,
    imageUrl,
    status,
    statusedAt: statused,
    uploadedAt: uploaded
  }));
};
