import { GridItem } from '@chakra-ui/react';
import { FormElement } from 'containers';
import { InputElement } from 'enum';
import { PermissionResource } from 'enum/api';
import { Fragment } from 'react';
import { useTranslation } from 'react-i18next';

import { GeneralSettingsFormField } from '../FormContent.types';

export const CorporateEmail = (): JSX.Element => {
  const [t] = useTranslation();

  return (
    <Fragment>
      <GridItem>
        {t('attribute.description.corporate_email_for_reports')}
      </GridItem>
      <GridItem>
        <FormElement
          type={InputElement.Input}
          name={GeneralSettingsFormField.CorporateEmail}
          translationKey="email"
          getPlaceholder={() => 'Email'}
          maxLength={50}
          resource={PermissionResource.SettingsCorporateEmail}
        />
      </GridItem>
    </Fragment>
  );
};
