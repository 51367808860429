import { CountryList } from 'components';
import { ModalType } from 'enum';
import { useAppDispatch } from 'hooks';
import { openModal } from 'store';

export const EditableCountryList = ({
  countryCodes,
  editModalHeader,
  updateEntity
}: {
  countryCodes: string[];
  onClick?: () => void;
  editModalHeader?: string;
  updateEntity: (data: string[]) => Promise<void>;
}): JSX.Element => {
  const dispatch = useAppDispatch();

  const onEditOption = () => {
    dispatch(
      openModal({
        id: ModalType.EditCountries,
        meta: {
          onSubmit: updateEntity,
          initialValue: countryCodes,
          header: editModalHeader
        }
      })
    );
  };

  return <CountryList onClick={onEditOption} countryCodes={countryCodes} />;
};
