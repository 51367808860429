import { ReportsChartData } from 'api';
import { LineChartSettings, TableFilterSchema } from 'containers';
import { format } from 'date-fns';
import { InputElement, Period, ReportAuthorType } from 'enum';
import { OptionKey } from 'enum/api';
import { formatChartAxisDateByPeriod, STATISTIC_TOTAL } from 'utils';

export const CHART_SETTINGS: LineChartSettings<ReportsChartData> = {
  colors: ['#CA9BDB', '#F4AA23', '#009FFD'],
  transformChartData: (data) => ({
    x: data.date,
    y: data.value
  }),
  formatXAxis: (value, period) => {
    if (typeof value !== 'string') return value.toString();

    return formatChartAxisDateByPeriod(value, period);
  },
  getLabel: ({ id, t }) => {
    const getTextTranslation = () => {
      if (id === ReportAuthorType.User) {
        return t('keywords.customer');
      }

      if (id === ReportAuthorType.Admin) {
        return t('keywords.admin');
      }

      return t('keywords.total');
    };

    return t(`keywords.text_report`, {
      count: 2,
      text: getTextTranslation()
    });
  },
  applyAreaIds: [STATISTIC_TOTAL]
};

export const chartFiltersSchema: TableFilterSchema[] = [
  {
    type: InputElement.Select,
    getLabel: (t) => t('keywords.period'),
    defaultValues: [Period.LastMonth],
    optionKey: OptionKey.Period,
    updateOutput: (_, value) => ({
      key: 'period',
      value: value[0]
    })
  },
  {
    type: InputElement.Range,
    getLabel: (t) => t('keywords.custom_period'),
    optionKey: 'customRange',
    updateOutput: (key, value) => ({
      key,
      value: value
        .filter((date): date is string => !!date)
        .map((date) => new Date(date).toISOString())
    }),
    inputProps: {
      type: 'date',
      max: format(new Date(), 'dd.MM.yyyy')
    }
  },
  {
    type: InputElement.Checkbox,
    getLabel: (t) => t('keywords.type'),
    optionKey: 'authorTypes',
    getOptions: ({ t }) => [
      {
        label: t(`keywords.text_report`, {
          count: 2,
          text: t('keywords.customer')
        }),
        value: ReportAuthorType.User
      },
      {
        label: t(`keywords.text_report`, {
          count: 2,
          text: t('keywords.admin')
        }),
        value: ReportAuthorType.Admin
      }
    ]
  }
];
