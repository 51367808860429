import { Grid, useToast } from '@chakra-ui/react';
import { GridItem } from '@chakra-ui/react';
import { ParameterOption } from 'api';
import { Search, Table } from 'components';
import { ContentContainer } from 'containers';
import { TableFilters } from 'containers';
import { useParameterOptions, useTableQueryController } from 'hooks';
import { useUpdateParameterOption } from 'hooks/requests/Parameters/useUpdateParameterOption';
import { FC } from 'react';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import {
  tableFilters,
  parameterColumns,
  getSearchOptions
} from './ParameterOptions.utils';

export const ParameterOptions: FC = () => {
  const [t] = useTranslation();
  const toast = useToast();

  const {
    queryFilters,
    action: {
      onChangeSort,
      onChangePagination,
      onChangeFilters,
      onChangeSearch
    }
  } = useTableQueryController({
    defaultValues: {
      sort: {
        type: 'asc'
      }
    }
  });

  const { data } = useParameterOptions(queryFilters);
  const { mutateAsync: updateOption } = useUpdateParameterOption(queryFilters);

  const searchOptions = useMemo(() => getSearchOptions(t), [t]);

  const updateEntity = async (id: string, data: Partial<ParameterOption>) => {
    try {
      await updateOption({
        id,
        ...data
      });
    } catch (error) {
      toast({
        status: 'error',
        description:
          error instanceof Error
            ? error.message || t('errors.error_occurred_while_saving_data')
            : t('errors.error_occurred_while_saving_data')
      });
    }
  };

  return (
    <ContentContainer height="100%" display="flex" flexDirection="column">
      <Grid
        templateAreas={`
             "search actions"
             "filters filters"
             "table table"
            `}
        templateColumns="26.25rem 1fr"
        templateRows="min-content  min-content 1fr"
        rowGap={6}
        overflow="hidden"
        flexGrow={1}
      >
        <GridItem area="search">
          <Search options={searchOptions} onChange={onChangeSearch} />
        </GridItem>
        <GridItem area="filters">
          <TableFilters filters={tableFilters} onChange={onChangeFilters} />
        </GridItem>
        {!!data && (
          <GridItem area="table" overflow="hidden">
            <Table
              data={data.data}
              columns={parameterColumns}
              onChangeSort={onChangeSort}
              onChangePagination={onChangePagination}
              totalItems={data.totalItems}
              pagination={queryFilters.pagination}
              hasHorizontalScroll
              meta={{
                updateEntity
              }}
            />
          </GridItem>
        )}
      </Grid>
    </ContentContainer>
  );
};
