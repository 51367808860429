import { FormField, InputElement } from 'enum';
import { ParameterType } from 'enum/api';
import { GetOptionsArg } from 'types/form';
import { generateOptionsFromSettings } from 'utils';

export const automaticLabelsFormFields = [
  {
    type: InputElement.Select,
    name: FormField.Gender,
    translationKey: 'gender',
    getPlaceholder: () => 'Select',
    getOptions: ({ t, settings }: GetOptionsArg) =>
      generateOptionsFromSettings(ParameterType.Gender, t, settings)
  },
  {
    type: InputElement.MultiSelect,
    name: FormField.Label,
    translationKey: 'labels',
    getPlaceholder: () => 'Select',
    getOptions: ({ t, settings }: GetOptionsArg) =>
      generateOptionsFromSettings(ParameterType.Label, t, settings)
  }
] as const;

export const getInitialFieldsState = () => ({
  [FormField.Gender]: null,
  [FormField.Label]: []
});
