import {
  Box,
  Checkbox,
  Heading,
  HStack,
  Switch,
  VStack
} from '@chakra-ui/react';

export const Checkboxes = (): JSX.Element => (
  <VStack
    w="400px"
    ml="20px"
    spacing={3}
    align="flex-start"
    alignItems="stretch"
  >
    <Box>
      <Heading as="h3" size="md" mt={3} mb={2}>
        Base Checkbox
      </Heading>
      <Checkbox>Some checkbox label</Checkbox>
      <Heading as="h3" size="md" mt={3} mb={2}>
        Disabled Checkbox
      </Heading>
      <HStack spacing={3}>
        <Checkbox disabled>Some checkbox label</Checkbox>
        <Checkbox disabled defaultChecked>
          Some checkbox label
        </Checkbox>
      </HStack>
    </Box>
    <Box>
      <Heading as="h3" size="md" mt={3} mb={2}>
        Base Checkbox
      </Heading>
      <Switch spacing={3} />
      <Heading as="h3" size="md" mt={3} mb={2}>
        Disabled Checkbox
      </Heading>
      <HStack spacing={3}>
        <Switch spacing={3}>Toggle me</Switch>
        <Switch spacing={3} disabled>
          Toggle me
        </Switch>
      </HStack>
    </Box>
  </VStack>
);
