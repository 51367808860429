import {
  differenceInMinutes as differenceInMinutesFnsFn,
  differenceInHours as differenceInHoursFnsFn,
  differenceInDays as differenceInDaysFnsFn,
  isAfter as isAfterFnsFn,
  isValid as isValidFnsFn,
  subDays as subDaysFnsFn
} from 'date-fns';

export const differenceInMinutes = differenceInMinutesFnsFn;

export const differenceInHours = differenceInHoursFnsFn;

export const differenceInDays = differenceInDaysFnsFn;

export const isAfter = isAfterFnsFn;

export const isValidDate = isValidFnsFn;

export const subDays = subDaysFnsFn;
