import { getAllEnumValues } from 'enum-for';
import { PermissionAction, PermissionResource } from 'enum/api';

import { Permission, PermissionsResponse } from './Permissions.types';

const getDefaultResourcePermissions = (): Permission[keyof Permission] => ({
  [PermissionAction.Create]: false,
  [PermissionAction.Update]: false,
  [PermissionAction.Delete]: false,
  [PermissionAction.Read]: false
});

const getInitialPermissions = () =>
  getAllEnumValues(PermissionResource).reduce((acc, key) => {
    acc[key] = getDefaultResourcePermissions();
    return acc;
  }, {} as Permission);

export const serializePermissions = (
  data: PermissionsResponse[]
): Permission => {
  const permissions = data.reduce((acc, cur) => {
    const { resource, action } = cur;

    // This condition prevent broke FE app when new permissions are added
    if (!acc[resource]) {
      acc[resource] = getDefaultResourcePermissions();
    }

    acc[resource][action] = true;

    return acc;
  }, getInitialPermissions());

  permissions[PermissionResource.Payments] = {
    ...getDefaultResourcePermissions(),
    [PermissionAction.Read]:
      permissions[PermissionResource.PaymentMethods][PermissionAction.Read] ||
      permissions[PermissionResource.PaymentSubscriptions][
        PermissionAction.Read
      ] ||
      permissions[PermissionResource.PaymentTransactions][PermissionAction.Read]
  };

  return permissions;
};
