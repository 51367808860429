import { ComponentWithAs, IconProps, TagProps } from '@chakra-ui/react';
import { Fragment } from 'react';

import { Label } from './Label';

type Props = {
  list: {
    label: string;
    color?: string;
  }[];
  leftIcon?: ComponentWithAs<'svg', IconProps>;
  rightIcon?: ComponentWithAs<'svg', IconProps>;
  itemProps: TagProps;
};

export const LabelList = ({
  list,
  leftIcon,
  rightIcon,
  itemProps
}: Props): JSX.Element => (
  <Fragment>
    {list.map(({ label, color }) => (
      <Label
        key={label}
        leftIcon={leftIcon}
        rightIcon={rightIcon}
        {...itemProps}
        color={color}
      >
        {label}
      </Label>
    ))}
  </Fragment>
);
