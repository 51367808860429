import { Button, Wrap } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  getTableManagementPageNameByUrl,
  TABLE_MANAGEMENT_PAGE_LINK_BY_PAGE
} from 'routes/routes.utils';

import {
  TABLE_MANAGEMENT_PAGE,
  TABLE_MANAGEMENT_PAGE_LIST,
  getTableManagementPageLabel
} from '../TableManagement.utils';

export const TableManagementTabs = (): JSX.Element => {
  const [t] = useTranslation();
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const selectedTab = getTableManagementPageNameByUrl(pathname);

  return (
    <Wrap shouldWrapChildren spacing={3}>
      {TABLE_MANAGEMENT_PAGE_LIST.map((tab) => (
        <Button
          key={tab}
          variant="ghost"
          colorScheme="gray"
          aria-selected={tab === selectedTab}
          isDisabled={!TABLE_MANAGEMENT_PAGE[tab]}
          onClick={() => {
            navigate(TABLE_MANAGEMENT_PAGE_LINK_BY_PAGE[tab]);
          }}
        >
          {getTableManagementPageLabel(tab, t)}
        </Button>
      ))}
    </Wrap>
  );
};
