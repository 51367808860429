import { GaugeChartSettings, TableFilterSchema } from 'containers';
import { InputElement } from 'enum';
import { ParameterType } from 'enum/api';

export const CHART_SETTINGS: GaugeChartSettings = {
  arcWidth: '1rem',
  tickWidth: '0.3125rem',
  tickColor: 'gray.300',
  tickColorWithText: 'gray.600',
  tickOffset: '0.25rem',
  tickTextColor: 'gray.600',
  tickTextSize: '0.75rem',
  tickTextWeight: 'normal',
  needleOffset: '1rem',
  needleColor: 'gray.600',
  radius: '7rem'
};

export const chartFilters: TableFilterSchema[] = [
  {
    type: InputElement.Checkbox,
    getLabel: (t) => t('keywords.program_type'),
    optionKey: ParameterType.ProgramType,
    updateOutput: (_, value) => ({
      key: 'programTypes',
      value
    })
  },
  {
    type: InputElement.Checkbox,
    getLabel: (t) => t('keywords.gender'),
    optionKey: ParameterType.GenderTruncated,
    updateOutput: (_, value) => ({
      key: 'genders',
      value
    })
  }
];
