import { Td, Tr } from '@chakra-ui/react';
import { flexRender, Row, RowData } from '@tanstack/react-table';

import { TableProps } from '../Table.types';

type Props<TData> = {
  row: Row<TData>;
  getIsDisabled: NonNullable<TableProps<TData>['getIsDisabled']>;
  getIsSelected: NonNullable<TableProps<TData>['getIsSelected']>;
  getIsAttentioned: NonNullable<TableProps<TData>['getIsAttentioned']>;
  canInteractWithDisabled: TableProps<TData>['canInteractWithDisabled'];
  getHighlightColor: TableProps<TData>['getHighlightColor'];
};

export const TableBodyRow = <TData extends RowData>({
  row,
  getIsDisabled,
  getIsSelected,
  getIsAttentioned,
  canInteractWithDisabled,
  getHighlightColor
}: Props<TData>): JSX.Element => (
  <Tr
    aria-disabled={getIsDisabled(row)}
    aria-selected={getIsSelected(row)}
    data-events={!!canInteractWithDisabled}
    background={getHighlightColor?.(row)}
    style={{ ...(getIsAttentioned(row) && { background: '#ffb6c1' }) }}
  >
    {row.getVisibleCells().map((cell) => (
      <Td key={cell.id} {...cell.column.columnDef.meta}>
        {flexRender(cell.column.columnDef.cell, cell.getContext())}
      </Td>
    ))}
  </Tr>
);
