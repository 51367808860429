import { Text, VStack } from '@chakra-ui/react';
import { yupResolver } from '@hookform/resolvers/yup';
import { FormConstructor, ModalContainer } from 'containers';
import {
  DeclineItemType,
  FormField,
  ModalType,
  ModerationPostStatus
} from 'enum';
import {
  useModalActions,
  useUpdateAboutMeStatus,
  useUpdateCommentStatus,
  useUpdateGalleryPhoto,
  useUpdateMottoStatus
} from 'hooks';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { ParamType } from 'types';

import { DeclineItemFormData } from './DeclineItem.types';
import {
  declineItemFormSchema,
  declineItemValidationSchema
} from './DeclineItem.utils';

export const DeclineItem = (): JSX.Element => {
  const [t] = useTranslation();

  const form = useForm<DeclineItemFormData>({
    mode: 'onChange',
    resolver: yupResolver(declineItemValidationSchema)
  });

  const {
    handleSubmit,
    reset,
    formState: { isValid }
  } = form;

  const { isOpen, onClose, meta } = useModalActions(ModalType.DeclineItem, {
    onClose: reset
  });

  const { mutate: updateGalleryPhoto, isLoading: isLoadingPhotoStatus } =
    useUpdateGalleryPhoto();
  const { mutate: updateCommentStatus, isLoading: isLoadingCommentStatus } =
    useUpdateCommentStatus();
  const { mutate: updateMottoStatus, isLoading: isLoadingMottoStatus } =
    useUpdateMottoStatus();
  const { mutate: updateAboutMeStatus, isLoading: isLoadingAboutMeStatus } =
    useUpdateAboutMeStatus();

  const isLoading =
    isLoadingPhotoStatus ||
    isLoadingCommentStatus ||
    isLoadingMottoStatus ||
    isLoadingAboutMeStatus;

  const onSubmit = handleSubmit((data) => {
    const requestData = {
      status: ModerationPostStatus.Declined,
      declineReason: data[FormField.Reason] || undefined
    };
    const options = {
      onSuccess: onClose
    };

    switch (meta?.type) {
      case DeclineItemType.GalleryPhoto:
        updateGalleryPhoto(
          {
            photoId: meta.photoId,
            ...requestData
          },
          options
        );
        break;
      case DeclineItemType.Comment:
        updateCommentStatus(
          {
            commentId: meta.commentId,
            ...requestData
          },
          options
        );
        break;
      case DeclineItemType.Motto:
        updateMottoStatus(
          {
            mottoId: meta.mottoId,
            ...requestData
          },
          options
        );
        break;
      case DeclineItemType.AboutMe:
        updateAboutMeStatus(
          {
            aboutMeId: meta.aboutMeId,
            ...requestData
          },
          options
        );
        break;
    }
  });

  const actions: ParamType<typeof ModalContainer, 'footerButtons'> = [
    {
      text: t('actions.cancel'),
      variant: 'ghostGray'
    },
    {
      text: t('actions.decline'),
      colorScheme: 'primary',
      isLoading,
      isPreventClose: true,
      isDisabled: !isValid,
      onClick: () => {
        onSubmit();
      }
    }
  ];

  let headerLabel = '';

  switch (meta?.type) {
    case DeclineItemType.GalleryPhoto:
      headerLabel = t('attribute.title.decline_photo');
      break;
    case DeclineItemType.Comment:
      headerLabel = t('attribute.title.decline_comment');
      break;
    case DeclineItemType.Motto:
      headerLabel = t('attribute.title.decline_motto');
      break;
    case DeclineItemType.AboutMe:
      headerLabel = t('attribute.title.decline_changes');
      break;

    default:
      break;
  }

  return (
    <ModalContainer
      header={headerLabel}
      footerButtons={actions}
      isOpen={isOpen}
      onClose={onClose}
      modalBodyProps={{
        overflow: 'unset',
        overflowX: 'unset'
      }}
    >
      <VStack alignItems="stretch">
        <Text>{t('attribute.description.enter_reason_declined_item')}</Text>
        <FormProvider {...form}>
          <FormConstructor formSchema={declineItemFormSchema} />
        </FormProvider>
      </VStack>
    </ModalContainer>
  );
};
