import { ResponsiveLine } from '@nivo/line';
import { BaseChartDataTemplate } from 'api';
import { LineChartDataMap } from 'containers';
import { ChartFilters } from 'types';

import { LineChartTooltip } from './components';
import { useLineChartProps } from './useLineChartProps';

type Props<T extends BaseChartDataTemplate> = {
  dataMap: LineChartDataMap<T>;
  filters: ChartFilters;
};

export const LineChart = <T extends BaseChartDataTemplate>({
  dataMap,
  filters
}: Props<T>): JSX.Element => {
  const { chartProps } = useLineChartProps({
    dataMap,
    filters
  });

  return (
    <ResponsiveLine
      {...chartProps}
      tooltip={({ point }) => (
        <LineChartTooltip point={point} dataMap={dataMap} />
      )}
    />
  );
};
