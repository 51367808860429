import { MenuItem } from '@chakra-ui/react';
import { EllipsisButton } from 'components';
import { Access } from 'containers';
import { ModalType } from 'enum';
import { PermissionAction, PermissionResource } from 'enum/api';
import { useAppDispatch } from 'hooks';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { openModal } from 'store/modal';

export const EditButton = ({ userId }: { userId: string }): JSX.Element => {
  const [t] = useTranslation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const onDeleteUser = () => {
    dispatch(
      openModal({
        id: ModalType.DeleteAdminUser,
        meta: {
          userId
        }
      })
    );
  };

  return (
    <EllipsisButton>
      <Access
        restrictions={{
          resource: PermissionResource.Admins,
          actions: PermissionAction.Update
        }}
      >
        {(hasAccess) => (
          <MenuItem
            onClick={() => {
              navigate(userId);
            }}
            isDisabled={!hasAccess}
          >
            {t('actions.edit')}
          </MenuItem>
        )}
      </Access>
      <Access
        restrictions={{
          resource: PermissionResource.Admins,
          actions: PermissionAction.Delete
        }}
      >
        {(hasAccess) => (
          <MenuItem onClick={onDeleteUser} isDisabled={!hasAccess}>
            {t('actions.delete')}
          </MenuItem>
        )}
      </Access>
    </EllipsisButton>
  );
};
