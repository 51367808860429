import { useToast, UseToastOptions } from '@chakra-ui/toast';

export const useChakraToast = (
  defaultOptions?: UseToastOptions
): ReturnType<typeof useToast> =>
  useToast({
    position: 'top',
    variant: 'left-accent',
    isClosable: true,
    ...defaultOptions
  });
