import { Box, Grid, VStack } from '@chakra-ui/react';

import { FormContentProps } from './FormContent.types';
import { FORM_SETTINGS_ITEMS } from './FormContent.utils';

export const FormContent = (props: FormContentProps): JSX.Element => (
  <VStack alignItems="stretch">
    {FORM_SETTINGS_ITEMS.map((Content, index) => (
      <Box
        key={index}
        paddingY={10}
        borderBottom="1px"
        borderColor="gray.300"
        _last={{
          border: 'none'
        }}
      >
        <Grid templateColumns="1fr 1.5fr" columnGap={6}>
          <Content {...props} />
        </Grid>
      </Box>
    ))}
  </VStack>
);
