import { IncomeMoneyBalanceChartDataItem } from 'api';
import { LineChartSettings, TableFilterSchema } from 'containers';
import { format } from 'date-fns';
import { InputElement, Period } from 'enum';
import { IncomePeriodType, OptionKey, ParameterType } from 'enum/api';
import { formatChartAxisDateByPeriod, getTranslationByOptionKey } from 'utils';

export const CHART_SETTINGS: LineChartSettings<IncomeMoneyBalanceChartDataItem> =
  {
    colors: ['#5743D1', '#B3A7FF'],
    transformChartData: (data) => ({
      x: data.date,
      y: data.value
    }),
    formatXAxis: (value, period) => {
      if (typeof value !== 'string') return value.toString();

      return formatChartAxisDateByPeriod(value, period);
    },
    getLabel: ({ id, subId, t, settings }) => {
      switch (id) {
        case IncomePeriodType.PrevIncome:
          return `${t(
            'keywords.same_period_year_ago'
          )} ${getTranslationByOptionKey({
            t,
            value: subId || '',
            optionKey: ParameterType.Currency,
            settings
          })}`;
        case IncomePeriodType.Income:
          return `${t('keywords.income')} ${getTranslationByOptionKey({
            t,
            value: subId || '',
            optionKey: ParameterType.Currency,
            settings
          })}`;
        default:
          return '';
      }
    }
  };

export const chartFilters: TableFilterSchema[] = [
  {
    type: InputElement.Select,
    getLabel: (t) => t('keywords.period'),
    defaultValues: [Period.LastMonth],
    optionKey: OptionKey.Period,
    updateOutput: (_, value) => ({
      key: 'period',
      value: value[0]
    })
  },
  {
    type: InputElement.Range,
    getLabel: (t) => t('keywords.custom_period'),
    optionKey: 'customRange',
    updateOutput: (key, value) => ({
      key,
      value: value
        .filter((date): date is string => !!date)
        .map((date) => new Date(date).toISOString())
    }),
    inputProps: {
      type: 'date',
      max: format(new Date(), 'dd.MM.yyyy')
    }
  },
  {
    type: InputElement.Checkbox,
    getLabel: (t) => t('keywords.currency'),
    optionKey: ParameterType.Currency,
    updateOutput: (_, value) => ({
      key: 'currencies',
      value
    })
  }
];
