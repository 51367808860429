import { ENDPOINTS } from 'api/endpoints';
import axios from 'axios';

import { Admin, AdminResponse } from './AdminManagement.types';

export const fetchAdminInfo = async (id: string): Promise<Admin> => {
  const { data } = await axios.get<AdminResponse>(`${ENDPOINTS.ADMINS}/${id}`);

  return data;
};
