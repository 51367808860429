import { createColumnHelper } from '@tanstack/react-table';
import { PopupListResponse } from 'api';
import { CountryList, TruncateText } from 'components';
import { getDateFormattedString } from 'utils';

import { ActionCell, PagesCell } from './components';

const columnHelper = createColumnHelper<PopupListResponse>();

export const popupsManagementColumns = [
  columnHelper.accessor('title', {
    header: ({ table }) => table.options.meta?.t('keywords.popup_title'),
    enableSorting: true
  }),
  columnHelper.accessor('message', {
    cell: ({ getValue }) => <TruncateText>{getValue()}</TruncateText>,
    header: ({ table }) => table.options.meta?.t('keywords.popup_message'),
    enableSorting: true,
    meta: {
      maxWidth: 'md'
    }
  }),
  columnHelper.accessor('createdAt', {
    cell: ({ getValue, table }) =>
      getDateFormattedString({
        t: table.options.meta?.t,
        date: getValue()
      }),
    header: ({ table }) =>
      table.options.meta?.t('keywords.creation_date_and_time'),
    enableSorting: true
  }),
  columnHelper.accessor('pages', {
    cell: ({ getValue }) => <PagesCell pages={getValue()} />,
    header: ({ table }) => table.options.meta?.t('keywords.pages'),
    enableSorting: false
  }),
  columnHelper.accessor('countries', {
    cell: ({ getValue }) => <CountryList countryCodes={getValue()} />,
    header: ({ table }) => table.options.meta?.t('keywords.country'),
    enableSorting: false
  }),
  columnHelper.display({
    id: 'actions',
    cell: ({ row }) => (
      <ActionCell
        status={row.original.status}
        popupId={row.original.id}
        metrics={row.original.popupMetric}
      />
    ),
    header: () => null,
    enableSorting: false
  })
];
