import { CustomerAdminCommentsResponse, ENDPOINTS, WithPagination } from 'api';
import { serializeQueryFilters } from 'api/api.utils';
import axios from 'axios';
import { QueryFilters } from 'types';
import { ICustomerAdminComments } from 'types/customer';

export const fetchCustomerAdminComments = async ({
  customerId,
  queryFilters
}: {
  customerId: string;
  queryFilters: QueryFilters;
}): Promise<WithPagination<ICustomerAdminComments[]>> => {
  const { data } = await axios.post<
    WithPagination<CustomerAdminCommentsResponse[]>
  >(
    `${ENDPOINTS.ADMIN_COMMENTS}/by-user/${customerId}/list`,
    serializeQueryFilters(queryFilters)
  );

  return data;
};
