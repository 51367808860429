import { SystemStyleFunction } from '@chakra-ui/react';
import { ComponentStyleConfig } from '@chakra-ui/theme';

const variantSubtle: SystemStyleFunction = (props) => {
  const { colorScheme: c } = props;

  let color;

  switch (c) {
    case 'black':
      color = 'black';
      break;
    case 'gray':
      color = `${c}.600`;
      break;
    default:
      color = `${c}.500`;
  }

  return {
    container: {
      background: 'gray.100',
      color
    }
  };
};

const variantSolid: SystemStyleFunction = (props) => {
  const { colorScheme: c } = props;

  return {
    container: {
      background: `${c}.100`,
      color: `${c}.500`
    }
  };
};

const variants = {
  subtle: variantSubtle,
  solid: variantSolid
};

export const Tag: ComponentStyleConfig = {
  baseStyle: {
    container: {
      lineHeight: 'base'
    },
    label: {
      lineHeight: 'base'
    }
  },
  variants,
  sizes: {
    rg: {
      container: {
        minHeight: '8',
        minWidth: '8',
        fontSize: 'md',
        paddingX: '3'
      }
    },
    md: {
      container: {
        px: '3'
      }
    },
    sm: {
      container: {
        minH: '1.375rem',
        minW: '1.375rem',
        fontSize: 'sm',
        px: '3'
      }
    }
  },
  defaultProps: {
    size: 'rg'
  }
};
