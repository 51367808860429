import { Grid, GridItem, Heading, Skeleton, VStack } from '@chakra-ui/react';
import { CustomStackedBarChart } from 'containers';
import { useCustomStackedBarChartData, useReportsByStatus } from 'hooks';
import { useTranslation } from 'react-i18next';

import { REPORTS_CHART_SETTINGS } from './ReportsByStatus.utils';

export const ReportsByStatus = (): JSX.Element => {
  const [t] = useTranslation();

  const { data, isSuccess } = useReportsByStatus();

  const reportsByStatusChartData = useCustomStackedBarChartData({
    apiData: data?.reportsByStatus,
    chartSettings: REPORTS_CHART_SETTINGS
  });

  return (
    <Grid
      templateAreas={`
          "title title"
          "chart chart"
        `}
      templateColumns="1fr auto"
      rowGap={4}
    >
      <GridItem area="title">
        <Heading size="l">{t('attribute.title.reports')}</Heading>
      </GridItem>
      <GridItem area="chart" height="30rem">
        <Skeleton isLoaded={isSuccess} boxSize="100%">
          <VStack spacing={10} alignItems="stretch">
            <CustomStackedBarChart
              data={reportsByStatusChartData}
              chartSettings={REPORTS_CHART_SETTINGS}
            />
          </VStack>
        </Skeleton>
      </GridItem>
    </Grid>
  );
};
