import { useQuery } from '@tanstack/react-query';
import { fetchCustomerMessageImages } from 'api';
import { QueryKey } from 'enum/api';

export const useCustomerMessageImages = (customerId: string) =>
  useQuery(
    [QueryKey.CustomerMessageImages, customerId],
    () => fetchCustomerMessageImages(customerId),
    {
      retry: false,
      refetchOnWindowFocus: false
    }
  );
