import { useMutation } from '@tanstack/react-query';
import { fetchLogin } from 'api';
import { AxiosError } from 'axios';
import { QueryKey } from 'enum/api';
import { useAppDispatch } from 'hooks';
import Cookies from 'js-cookie';
import { resetUser } from 'store/user';
import { AppError, AxiosErrorData } from 'types';
import { serializeAxiosError, TOKEN } from 'utils';

export const useLogin = ({
  onSuccess,
  onError
}: {
  onSuccess?: () => void;
  onError?: (error: AppError) => void;
} = {}) => {
  const dispatch = useAppDispatch();

  return useMutation([QueryKey.Login], fetchLogin, {
    onSuccess(data) {
      Cookies.set(TOKEN, data.token, {
        expires: new Date(data.expiresAt)
      });
      onSuccess?.();
    },
    onError(error: AxiosError<AxiosErrorData>) {
      dispatch(resetUser());
      onError?.(serializeAxiosError(error));
    }
  });
};
