import { ICustomerFavourites } from 'types/customer';

import { CustomerFavouritesResponse } from '../Customers.types';

export const serializeCustomerFavouritesData = (
  response: CustomerFavouritesResponse[]
): ICustomerFavourites[] =>
  response.map((data) => {
    const { id, addedAt, partner, addedBy } = data;
    const {
      nickname = '',
      email = '',
      id: userId = ''
    } = partner || addedBy || {};

    return {
      id,
      nickname,
      email,
      addedAt,
      userId
    };
  });
