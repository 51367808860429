import { ENDPOINTS, IncomeMoneyBalanceResponse } from 'api';
import { serializeBodyFilters } from 'api/api.utils';
import axios from 'axios';
import { ChartFilters, GlobalFilters } from 'types';

import { serializeIncomeMoneyBalanceResponse } from './AdminPanel.utils';

export const fetchIncomeMoneyBalance = async ({
  filters,
  globalFilters
}: {
  filters: ChartFilters;
  globalFilters: GlobalFilters;
}): Promise<IncomeMoneyBalanceResponse> => {
  const { data } = await axios.post<IncomeMoneyBalanceResponse>(
    ENDPOINTS.INCOME_BALANCE,
    {
      filter: filters,
      ...serializeBodyFilters({ globalFilters })
    }
  );

  return serializeIncomeMoneyBalanceResponse(data);
};
