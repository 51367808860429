import { yupResolver } from '@hookform/resolvers/yup';
import { FormConstructor, ModalContainer } from 'containers';
import { FormField, ModalType } from 'enum';
import { useModalActions, useUpdateCustomer } from 'hooks';
import { useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { ParamType } from 'types';

import { EditLabelsFormData } from './EditLabels.types';
import {
  editLabelsFormSchema,
  editLabelsValidationSchema
} from './EditLabels.utils';

export const EditLabels = (): JSX.Element => {
  const [t] = useTranslation();

  const form = useForm<EditLabelsFormData>({
    mode: 'onSubmit',
    shouldUnregister: true,
    resolver: yupResolver(editLabelsValidationSchema),
    defaultValues: {
      [FormField.Label]: [],
      [FormField.Comment]: ''
    }
  });

  const {
    reset,
    handleSubmit,
    formState: { isDirty }
  } = form;

  const {
    mutate: updateCustomer,
    isLoading,
    reset: queryReset
  } = useUpdateCustomer();

  const clearComponentStates = () => {
    queryReset();
    reset();
  };

  const { isOpen, onClose, meta } = useModalActions(ModalType.EditLabels, {
    onClose: clearComponentStates
  });

  useEffect(() => {
    reset({
      [FormField.Label]: meta?.labels || [],
      [FormField.Comment]: ''
    });
  }, [meta, reset]);

  const onSubmit = handleSubmit((data) => {
    if (meta) {
      updateCustomer(
        {
          id: meta.customerId,
          ...data
        },
        {
          onSuccess: onClose
        }
      );
    }
  });

  const actions: ParamType<typeof ModalContainer, 'footerButtons'> = [
    {
      text: t('actions.cancel'),
      variant: 'ghostGray'
    },
    {
      text: t('actions.save_changes'),
      isLoading,
      isDisabled: !isDirty,
      isPreventClose: true,
      onClick: () => onSubmit()
    }
  ];

  return (
    <ModalContainer
      header={t('actions.edit_labels')}
      footerButtons={actions}
      isOpen={isOpen}
      onClose={onClose}
      size="xl"
      modalBodyProps={{
        overflow: 'unset',
        overflowX: 'unset'
      }}
    >
      <FormProvider {...form}>
        <FormConstructor formSchema={editLabelsFormSchema} />
      </FormProvider>
    </ModalContainer>
  );
};
