import { isAfter, isBefore } from 'date-fns';
import { SupportedInputTypeAttribute } from 'types';
import { isEmpty } from 'utils';

export const serializeUpdatedValues = ({
  inputType,
  prevValues,
  newValues
}: {
  inputType: SupportedInputTypeAttribute;
  prevValues: (string | number | undefined)[];
  newValues: (string | number | undefined)[];
}): (string | number | undefined)[] => {
  const [prevStart, prevEnd] = prevValues;

  switch (inputType) {
    case 'date': {
      const [start, end] = newValues;

      if (isEmpty(start) || isEmpty(end)) {
        return newValues;
      }

      if (prevStart !== start) {
        return isAfter(new Date(start), new Date(end))
          ? [start, start]
          : newValues;
      }

      if (prevEnd !== end) {
        return isBefore(new Date(end), new Date(start))
          ? [end, end]
          : newValues;
      }

      return newValues;
    }
    case 'number': {
      const [start, end] = newValues;

      if (isEmpty(start) || isEmpty(end)) return newValues;

      if (Number(start) <= Number(end)) return newValues;

      if (prevStart !== start) return [start, start];

      if (prevEnd !== end) return [end, end];

      return newValues;
    }
    case 'text':
    default:
      return newValues;
  }
};
