import { CalendarIcon } from '@chakra-ui/icons';
import { TableManagementOption } from 'api';
import { DangerIcon, InfoCardProps } from 'components';
import { format } from 'date-fns';
import { AdminReportReason } from 'enum/api';
import snakeCase from 'lodash/snakeCase';
import { TFunction } from 'react-i18next';
import { DateString } from 'types';

export const getReportCustomerAccountInformation = ({
  reportReason,
  reportDate,
  t
}: {
  reportReason: string;
  reportDate: DateString;
  t: TFunction;
}): InfoCardProps[] => [
  {
    Icon: CalendarIcon,
    header: format(new Date(reportDate), 'dd.MM.yyyy'),
    description: t('keywords.report_date_and_time')
  },
  {
    Icon: DangerIcon,
    header: reportReason,
    description: t('keywords.reason')
  }
];

export const getReportAdminAccountInformation = ({
  reportReason,
  reasons,
  reportDate,
  t
}: {
  reportReason: AdminReportReason | string;
  reasons: Record<string, TableManagementOption>;
  reportDate: DateString;
  t: TFunction;
}): InfoCardProps[] => {
  const reason = reasons[reportReason]
    ? reasons[reportReason].defaultValue
    : (t(
        `enum.admin_report_reason.${snakeCase(reportReason)}` as Parameters<
          typeof t
        >[0]
      ) as string);

  return [
    {
      Icon: DangerIcon,
      header: t('keywords.automatic'),
      description: t('keywords.sent_by')
    },
    {
      Icon: CalendarIcon,
      header: format(new Date(reportDate), 'HH:mm, dd.MM.yyyy'),
      description: t('keywords.report_date_and_time')
    },
    {
      Icon: DangerIcon,
      header: reason,
      description: t('keywords.reason')
    }
  ];
};
