import { ENDPOINTS } from 'api';
import { serializeBodyFilters } from 'api/api.utils';
import axios from 'axios';
import { GlobalFilters } from 'types';

import { ReportItemsTotalResponse } from './Reports.types';

export const fetchReportItemsTotal = async ({
  globalFilters
}: {
  globalFilters: GlobalFilters;
}): Promise<number> => {
  const { data } = await axios.post<ReportItemsTotalResponse>(
    ENDPOINTS.REPORT_TOTAL,
    serializeBodyFilters({ globalFilters })
  );

  return data.totalItems;
};
