import { useQuery } from '@tanstack/react-query';
import { fetchSettings } from 'api';
import { QueryKey } from 'enum/api';
import { useAppDispatch } from 'hooks';
import { setSettings } from 'store';
import { checkIfTokenExists } from 'utils';

export const useAuthUserSettings = () => {
  const dispatch = useAppDispatch();
  const enabled = checkIfTokenExists();

  return useQuery([QueryKey.Settings], fetchSettings, {
    retry: false,
    refetchOnWindowFocus: false,
    keepPreviousData: true,
    enabled,
    onSuccess(data) {
      dispatch(setSettings(data));
    }
  });
};
