import { ICustomerLikes } from 'types/customer';

import { CustomerLikesResponse } from '../Customers.types';

export const serializeCustomerLikesData = (
  response: CustomerLikesResponse[]
): ICustomerLikes[] =>
  response.map((data) => {
    const { id, likedAt, likedBy, partner } = data;
    const {
      nickname = '',
      email = '',
      id: userId = ''
    } = partner || likedBy || {};

    return {
      id,
      nickname,
      email,
      likedAt,
      userId
    };
  });
