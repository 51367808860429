import { Box, HStack, Text, VStack } from '@chakra-ui/react';

import { CustomStackedBarProps } from '../../CustomStackedBarChart.types';

export const VerticalStackedBar = ({
  container,
  base
}: CustomStackedBarProps): JSX.Element => {
  const { height, width, borders, minHeight } = container;
  const { value, color, filter, description } = base;

  return (
    <HStack
      spacing={3}
      height={height}
      minHeight={minHeight}
      alignItems="flex-end"
    >
      <Box width={width} height="100%" position="relative">
        <Box
          position="absolute"
          boxSize="100%"
          background={color}
          filter={filter}
          {...borders}
        />
      </Box>
      <VStack spacing={1} alignItems="flex-start">
        <Text color={color} textStyle="mds" filter={filter}>
          {value}
        </Text>
        <Text color="subtext" textStyle="xsrg">
          {description}
        </Text>
      </VStack>
    </HStack>
  );
};
