import { yupResolver } from '@hookform/resolvers/yup';
import { AxiosError } from 'axios';
import { FormConstructor, ModalContainer } from 'containers';
import { FormField, ModalType } from 'enum';
import { ErrorCode, ParameterType, SystemLanguage } from 'enum/api';
import {
  useChakraToast,
  useCreateParameterValue,
  useFormValidation,
  useModalActions
} from 'hooks';
import snakeCase from 'lodash/snakeCase';
import { useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { ParamType } from 'types';

import { CreateParameterValueFormData } from './CreateParameterValue.types';
import {
  createParameterValueFormSchema,
  createParameterValueValidationSchema
} from './CreateParameterValue.util';

export const CreateParameterValue = (): JSX.Element => {
  const [t] = useTranslation();
  const toast = useChakraToast();

  const form = useForm<CreateParameterValueFormData>({
    mode: 'onBlur',
    resolver: yupResolver(createParameterValueValidationSchema)
  });

  const { isOpen, onClose } = useModalActions(ModalType.CreateParameterValue, {
    onClose: form.reset
  });

  const { mutate, isLoading } = useCreateParameterValue();

  const { handleSubmit, watch, resetField } = form;

  const isFormFilled = useFormValidation<CreateParameterValueFormData>(
    form,
    createParameterValueValidationSchema
  );

  const type = watch(FormField.ParameterType);

  useEffect(() => {
    resetField(FormField.ParameterTitle);
  }, [resetField, type]);

  const onSubmit = handleSubmit(
    ({ language, meta, parameterTitle, parameterType, value }) => {
      if (parameterType && parameterTitle && language && value) {
        mutate(
          {
            parameterType: parameterType as ParameterType,
            parameterTitle,
            languageCode: language as SystemLanguage,
            value,
            meta: meta && JSON.parse(meta)
          },
          {
            onSuccess: onClose,
            onError: () => {
              toast({
                title: t('errors.error_create_new_value'),
                status: 'error'
              });
            }
          }
        );
      }
    }
  );

  const actions: ParamType<typeof ModalContainer, 'footerButtons'> = [
    {
      text: t('actions.create_parameter_value'),
      colorScheme: 'primary',
      isPreventClose: true,
      isLoading,
      isDisabled: !isFormFilled,
      onClick: () => {
        onSubmit();
      }
    }
  ];

  return (
    <ModalContainer
      header={t('attribute.title.create_parameter_value')}
      footerButtons={actions}
      isOpen={isOpen}
      onClose={onClose}
      size="xl"
      modalBodyProps={{
        paddingBottom: '30px'
      }}
    >
      <FormProvider {...form}>
        <FormConstructor formSchema={createParameterValueFormSchema} />
      </FormProvider>
    </ModalContainer>
  );
};
