import { TableFiltersProps } from './TableFilters.types';
import { FilterButtons } from './components';
import { useTableFilters } from './useTableFilters';

export const TableFilters = ({
  filters,
  onChange,
  shouldShowClearAll = false
}: TableFiltersProps): JSX.Element => {
  const filterButtonsProps = useTableFilters({
    filters,
    onChange
  });

  return (
    <FilterButtons
      {...filterButtonsProps}
      shouldShowClearAll={shouldShowClearAll}
    />
  );
};
