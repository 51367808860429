import { AdminRegisteredUsersChartData } from 'api';
import { LineChartSettings, TableFilterSchema } from 'containers';
import { format } from 'date-fns';
import { GenderTruncated, InputElement, Period } from 'enum';
import { OptionKey, ParameterType } from 'enum/api';
import {
  formatChartAxisDateByPeriod,
  getTranslationByOptionKey,
  STATISTIC_TOTAL
} from 'utils';

export const CHART_SETTINGS: LineChartSettings<AdminRegisteredUsersChartData> =
  {
    colors: [
      'var(--chakra-colors-blue-100)',
      'var(--chakra-colors-pink-300)',
      'var(--chakra-colors-green-400)'
    ],
    transformChartData: (data) => ({
      x: data.date,
      y: data.value
    }),
    formatXAxis: (value, period) => {
      if (typeof value !== 'string') return value.toString();

      return formatChartAxisDateByPeriod(value, period);
    },
    getLabel: ({ id, t, settings }) =>
      id === STATISTIC_TOTAL
        ? t('keywords.total')
        : id === GenderTruncated.Other
        ? t('keywords.other')
        : getTranslationByOptionKey({
            t,
            value: id,
            optionKey: ParameterType.Gender,
            settings
          })
  };

export const chartFiltersSchema: TableFilterSchema[] = [
  {
    type: InputElement.Checkbox,
    getLabel: (t) => t('keywords.gender'),
    optionKey: ParameterType.Gender,
    updateOutput: (_, value) => ({
      key: 'genders',
      value
    })
  },
  {
    type: InputElement.Select,
    getLabel: (t) => t('keywords.period'),
    defaultValues: [Period.LastMonth],
    optionKey: OptionKey.Period,
    updateOutput: (_, value) => ({
      key: 'period',
      value: value[0]
    })
  },
  {
    type: InputElement.Range,
    getLabel: (t) => t('keywords.custom_period'),
    optionKey: 'customRange',
    updateOutput: (key, value) => ({
      key,
      value: value
        .filter((date): date is string => !!date)
        .map((date) => new Date(date).toISOString())
    }),
    inputProps: {
      type: 'date',
      max: format(new Date(), 'dd.MM.yyyy')
    }
  },
  {
    type: InputElement.Checkbox,
    getLabel: (t) => t('keywords.program_type'),
    optionKey: ParameterType.ProgramType,
    updateOutput: (_, value) => ({
      key: 'programTypes',
      value
    })
  }
];
