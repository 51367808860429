import { inputAnatomy as parts } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers } from '@chakra-ui/react';

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(parts.keys);

const variantOutline = definePartsStyle((props) => {
  const { colorScheme } = props;

  switch (colorScheme) {
    case 'success':
      return {
        field: {
          bgColor: 'white',
          borderColor: 'green.500',
          _hover: {
            borderColor: 'green.500'
          },
          _focusVisible: {
            boxShadow: 'none',
            borderColor: 'green.500'
          }
        }
      };
    case 'default':
    default:
      return {
        field: {
          bgColor: 'white',
          _disabled: {
            bgColor: 'gray.100',
            opacity: 1,
            borderColor: 'inherit',
            color: 'gray.600'
          },
          _hover: {
            borderColor: 'primary.500'
          },
          _invalid: {
            boxShadow: 'none'
          },
          _focusVisible: {
            boxShadow: 'none',
            outline: 'none'
          }
        }
      };
  }
});

const variants = {
  outline: variantOutline
};

export const Input = defineMultiStyleConfig({
  baseStyle: {
    field: {
      _readOnly: {
        cursor: 'pointer'
      },
      _disabled: {
        pointerEvents: 'none'
      }
    }
  },
  variants,
  sizes: {
    md: definePartsStyle({
      field: {
        px: 3
      }
    })
  },
  defaultProps: {
    size: 'md',
    colorScheme: 'default'
  }
});
