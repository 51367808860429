import { ENDPOINTS, WithPagination } from 'api';
import { serializeBodyFilters } from 'api/api.utils';
import axios from 'axios';
import { QueryFilters } from 'types';
import { ICustomerPayments } from 'types/customer';

import { CustomerPaymentsResponse } from './Payments.types';

export const fetchCustomerPaymentList = async ({
  customerId,
  queryFilters
}: {
  customerId: string;
  queryFilters: QueryFilters;
}): Promise<WithPagination<ICustomerPayments[]>> => {
  const { data } = await axios.post<WithPagination<CustomerPaymentsResponse[]>>(
    `${ENDPOINTS.PAYMENT_TRANSACTION}/by-user/${customerId}/list`,
    serializeBodyFilters({ queryFilters })
  );

  return data;
};
