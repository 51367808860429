import { ENDPOINTS } from 'api/endpoints';
import axios from 'axios';
import { ImageStatus } from 'enum/api';

export const fetchUpdateGalleryImageStatus = ({
  imageId,
  status
}: {
  imageId: string;
  status:
    | ImageStatus.Active
    | ImageStatus.DeletedByAdmin
    | ImageStatus.DeclinedByAdmin;
}) =>
  axios.patch(`${ENDPOINTS.GALLERY_IMAGES}/${imageId}/status`, {
    status
  });
