import { ENDPOINTS } from 'api';
import axios from 'axios';

export const fetchAccessViewConversations = async ({
  password
}: {
  password: string;
}) => {
  const { data } = await axios.post<{
    hasAccess: boolean;
  }>(ENDPOINTS.CONVERSATION_ACCESS, {
    viewConversationsPassword: password
  });

  return data;
};
