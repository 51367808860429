import { createColumnHelper } from '@tanstack/react-table';
import { ModerationAboutMeResponse } from 'api';
import { TruncateText } from 'components';
import { DeclineReasonCell, UserCell } from 'pages/Moderation/components';
import { getDateFormattedString } from 'utils';

import { AboutMeActionButtons } from './components';

const moderationAboutMeColumnHelper =
  createColumnHelper<ModerationAboutMeResponse>();

export const moderationAboutMeColumns = [
  moderationAboutMeColumnHelper.accessor('newText', {
    cell: ({ getValue }) => (
      <TruncateText maxWidth="md">{getValue()}</TruncateText>
    ),
    header: ({ table }) => table.options.meta?.t('keywords.something_about_me'),
    enableSorting: false,
    meta: {
      maxWidth: 60
    }
  }),
  moderationAboutMeColumnHelper.accessor('prevText', {
    cell: ({ getValue }) => (
      <TruncateText maxWidth="md">{getValue()}</TruncateText>
    ),
    header: ({ table }) =>
      table.options.meta?.t('keywords.previous', {
        text: table.options.meta?.t('keywords.version')
      }),
    enableSorting: false,
    meta: {
      maxWidth: 60
    }
  }),
  moderationAboutMeColumnHelper.accessor((row) => row.user, {
    id: 'user',
    cell: ({ getValue, row: { original }, table }) => (
      <UserCell
        user={getValue()}
        compareData={{
          prev: {
            label:
              table.options.meta?.t('keywords.previous', {
                text: table.options.meta?.t('keywords.something_about_me')
              }) || '',
            value: original.prevText
          },
          current: {
            label: table.options.meta?.t('keywords.something_about_me') || '',
            value: original.newText
          }
        }}
      />
    ),
    header: ({ table }) => table.options.meta?.t('keywords.user'),
    enableSorting: false
  }),
  moderationAboutMeColumnHelper.accessor('postedAt', {
    cell: ({ getValue, table }) =>
      getDateFormattedString({ t: table.options.meta?.t, date: getValue() }),
    header: ({ table }) => table.options.meta?.t('keywords.creation_date'),
    enableSorting: false
  }),
  moderationAboutMeColumnHelper.display({
    id: 'actions',
    header: ({ table }) =>
      table.options.meta?.t('keywords.action', {
        count: 10
      }),
    cell: ({ row }) => <AboutMeActionButtons aboutMeId={row.original.id} />,
    enableSorting: false
  }),
  moderationAboutMeColumnHelper.accessor('declineReason', {
    cell: ({ getValue, row: { original } }) => (
      <DeclineReasonCell status={original.status} declineReason={getValue()} />
    ),
    header: ({ table }) => table.options.meta?.t('keywords.reason'),
    enableSorting: false
  })
];
