import { Permission } from 'api';
import { AxiosError } from 'axios';
import { getAllEnumValues } from 'enum-for';
import { ErrorCode } from 'enum/api';
import Cookies from 'js-cookie';
import { AuthorizedUserState, UserState } from 'store/user';
import { AppError, AxiosErrorData, ResourceActionPermission } from 'types';
import { FormSchemaType } from 'types/form';
import { TOKEN } from 'utils';

export const getFormFieldsListBySchema = <T extends FormSchemaType>(
  schema: T
): (keyof T['fields'])[] => Object.keys(schema.fields);

export const checkIfTokenExists = () => !!Cookies.get(TOKEN);

export const checkIfUserExist = (
  user: UserState
): user is AuthorizedUserState =>
  Object.values(user).every((field) => field !== null);

const isValidErrorCode = (code: string): code is ErrorCode =>
  getAllEnumValues(ErrorCode).includes(code as ErrorCode);

export const serializeAxiosError = (
  error: AxiosError<AxiosErrorData>
): AppError => {
  const { response = null } = error;

  if (response && isValidErrorCode(response.data.errorCode)) {
    return {
      errorCode: response.data.errorCode,
      data: response.data.data,
      origin: error
    };
  }

  return {
    errorCode: ErrorCode.Unknown,
    data: undefined,
    origin: error
  };
};

export const checkIfHasPermissions = (
  userPermissions: Permission,
  { resource, actions, isStrict }: ResourceActionPermission
) => {
  const permissions = userPermissions[resource];

  if (!permissions) return false;

  if (typeof actions === 'string') {
    return permissions[actions];
  }

  if (Array.isArray(actions)) {
    return isStrict
      ? actions.every((key) => permissions[key])
      : actions.some((key) => permissions[key]);
  }

  return false;
};

export const getTextWidth = (
  text: string | number,
  {
    fontSize = '1rem',
    fontWeight = '500'
  }: Partial<Pick<CSSStyleDeclaration, 'fontSize' | 'fontWeight'>>
) => {
  const span = document.createElement('span');
  document.body.appendChild(span);

  const value = typeof text === 'number' ? text.toString() : text;

  span.style.fontSize = fontSize;
  span.style.fontWeight = fontWeight;
  span.style.height = 'auto';
  span.style.width = 'auto';
  span.style.position = 'absolute';
  span.style.opacity = '0';
  // add one more character to have some reserve
  span.innerHTML = value + '0';

  const width = Math.ceil(span.clientWidth);
  const height = Math.ceil(span.clientHeight);
  document.body.removeChild(span);

  return {
    width,
    height
  };
};

export const getHTMLFontSize = () =>
  getComputedStyle(document.documentElement).fontSize;

export const isEmpty = (value: unknown): value is null | undefined | '' =>
  value === null ||
  typeof value === 'undefined' ||
  value === '' ||
  (typeof value === 'number' && isNaN(value));
