import { Tag } from '@chakra-ui/react';
import { Dropdown } from 'components';
import { DropdownType } from 'enum';
import { OptionKey, ReportStatus } from 'enum/api';
import { useAppSelector, useUpdateReport } from 'hooks';
import { useTranslation } from 'react-i18next';
import { selectSettings } from 'store/settings';
import { generateOptionsFromSettings } from 'utils';

import { getTagColorSchemeByStatus } from '../Reports.utils';

type Props = {
  status: ReportStatus;
  reportId: string;
};

export const StatusCell = ({
  reportId,
  status: selectedStatus
}: Props): JSX.Element => {
  const [t] = useTranslation();

  const settings = useAppSelector(selectSettings);

  const options = generateOptionsFromSettings(
    OptionKey.ReportStatus,
    t,
    settings
  ).map(({ label, value }) => ({
    label: (
      <Tag
        variant="solid"
        colorScheme={getTagColorSchemeByStatus(value)}
        size="md"
      >
        {label}
      </Tag>
    ),
    value
  }));

  const { mutate } = useUpdateReport();

  const handleChangeStatus = (status: string) => {
    mutate({
      reportId,
      status: status as ReportStatus
    });
  };

  return (
    <Dropdown
      type={DropdownType.Tag}
      options={options}
      selectedValue={selectedStatus}
      onChange={handleChangeStatus}
    />
  );
};
