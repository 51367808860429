import { Flex, FormControl, Switch, Text } from '@chakra-ui/react';
import { FormError } from 'components';
import { InputElement } from 'enum';
import { useController } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { FormComponentProps } from '../FormConstructor.types';

type Props = FormComponentProps<{ type: InputElement.Toggle }>;

export const FormToggle = (props: Props): JSX.Element => {
  const [t] = useTranslation();

  const { name, translationKey, reverseLayout } = props;

  const {
    field: { onChange, onBlur, value },
    fieldState: { error }
  } = useController({
    name
  });

  return (
    <FormControl isInvalid={!!error}>
      <Text minHeight="1em" marginBottom={1}>
        {' '}
      </Text>
      <Flex
        flexDirection={reverseLayout ? 'row-reverse' : 'row'}
        justifyContent={reverseLayout ? 'flex-end' : 'flex-start'}
        alignItems="center"
        gap={4}
      >
        <Switch
          spacing={3}
          isChecked={value}
          onChange={onChange}
          onBlur={onBlur}
        />
        {!!translationKey && t(`keywords.${translationKey}`)}
      </Flex>
      <FormError errorKey={error?.message} />
    </FormControl>
  );
};
