import { useQuery } from '@tanstack/react-query';
import { fetchAdminInfo } from 'api';
import { QueryKey } from 'enum/api';

export const useAdminInfo = ({
  id,
  shouldPreventRequest = false
}: {
  id: string;
  shouldPreventRequest?: boolean;
}) =>
  useQuery([QueryKey.Admins, id], () => fetchAdminInfo(id), {
    enabled: !shouldPreventRequest
  });
