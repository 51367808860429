import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import { initCountriesLocales } from './countries';
import enTranslations from './locales/en.json';
import { initYupLocaleErrors } from './yup';

const resources = {
  en: {
    translation: enTranslations
  }
};

initCountriesLocales();
initYupLocaleErrors();

i18n.use(initReactI18next).init({
  resources,
  lng: 'en',
  fallbackLng: 'en',
  load: 'languageOnly',
  interpolation: {
    escapeValue: false // react already safes from xss
  }
});

export { i18n };
