import { CreateSubscriptionRequestData, ENDPOINTS } from 'api';
import axios from 'axios';

export const fetchCreatePaymentSubscription = (
  body: CreateSubscriptionRequestData
) =>
  axios.post(
    ENDPOINTS.PAYMENT_SUBSCRIPTION,
    Object.fromEntries(
      Object.entries(body).filter(
        ([, value]) => typeof value === 'boolean' || !!value
      )
    )
  );
