import { yupResolver } from '@hookform/resolvers/yup';
import { RightSideButtons } from 'components';
import { ContentContainer, FormConstructor, RightSide } from 'containers';
import {
  useAllCustomerBadWordsList,
  useChakraToast,
  useUpdateCustomerBadWords
} from 'hooks';
import { useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate, useOutletContext } from 'react-router-dom';
import { getDirtyValues } from 'utils';

import { CustomerBadWordsFormData } from './CustomerBadWords.types';
import {
  customerBadWordsFormSchema,
  customerBadWordsValidationSchema,
  getDefaultFormValues,
  serializeBadWordsRequestData
} from './CustomerBadWords.utils';

export const CustomerBadWords = (): JSX.Element => {
  const [t] = useTranslation();
  const navigate = useNavigate();
  const toast = useChakraToast();

  const { customer } = useOutletContext();

  const form = useForm<CustomerBadWordsFormData>({
    mode: 'onSubmit',
    resolver: yupResolver(customerBadWordsValidationSchema),
    defaultValues: getDefaultFormValues()
  });

  const {
    formState: { isDirty, dirtyFields },
    handleSubmit,
    reset
  } = form;

  const { data: badWords } = useAllCustomerBadWordsList(customer.id);

  useEffect(() => {
    reset(getDefaultFormValues(badWords));
  }, [reset, badWords]);

  const { mutate, isLoading } = useUpdateCustomerBadWords();

  const onSubmit = handleSubmit((data) => {
    const values = getDirtyValues(data, dirtyFields);

    mutate(
      {
        customerId: customer.id,
        body: serializeBadWordsRequestData(values)
      },
      {
        onSuccess: () => {
          toast({
            title: t('messages.successfully_saved'),
            status: 'success'
          });
        }
      }
    );
  });

  const handleCancel = () => {
    navigate(-1);
  };

  return (
    <ContentContainer>
      <FormProvider {...form}>
        <FormConstructor formSchema={customerBadWordsFormSchema} />
      </FormProvider>
      <RightSide>
        <RightSideButtons
          onSubmit={onSubmit}
          isDisabledSubmit={!isDirty}
          onCancel={handleCancel}
          isLoading={isLoading}
        />
      </RightSide>
    </ContentContainer>
  );
};
