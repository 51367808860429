import { Button, Heading, HStack } from '@chakra-ui/react';
import { yupResolver } from '@hookform/resolvers/yup';
import { RightSideButtons } from 'components';
import { ContentContainer, FormConstructor, RightSide } from 'containers';
import { ModalType } from 'enum';
import { useAppDispatch, useChakraToast, useUpdateCustomer } from 'hooks';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate, useOutletContext } from 'react-router-dom';
import { openModal } from 'store';
import { getUpdatedFields } from 'utils';

import { AccountSettingsFormData } from './AccountSettings.types';
import {
  accountSettingsValidationSchema,
  customerAccountSettingsFormSchema,
  getDefaultFormValues
} from './AccountSettings.utils';

export const AccountSettings = (): JSX.Element => {
  const [t] = useTranslation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const toast = useChakraToast();

  const { customer } = useOutletContext();

  const form = useForm<AccountSettingsFormData>({
    mode: 'onSubmit',
    resolver: yupResolver(accountSettingsValidationSchema),
    defaultValues: getDefaultFormValues(customer)
  });

  const {
    formState: { isDirty, dirtyFields },
    handleSubmit,
    reset,
    getValues
  } = form;

  const { mutate: updateCustomer, isLoading } = useUpdateCustomer();

  const onSubmit = handleSubmit((data) => {
    const updateData = getUpdatedFields(data, dirtyFields);

    updateCustomer(
      {
        id: customer.id,
        ...updateData
      },
      {
        onSuccess: (_, { id, ...rest }) => {
          reset({
            ...getValues(),
            ...rest
          });

          toast({
            title: t('messages.successfully_saved'),
            status: 'success'
          });
        }
      }
    );
  });

  const handleCancel = () => {
    navigate(-1);
  };

  const handleStopRenew = () =>
    dispatch(
      openModal({
        id: ModalType.StopRenewSubscription,
        meta: {
          customerId: customer.id
        }
      })
    );

  const handleCancelSubscription = () =>
    dispatch(
      openModal({
        id: ModalType.CancelCustomerSubscription,
        meta: {
          customerId: customer.id
        }
      })
    );

  return (
    <ContentContainer>
      <FormProvider {...form}>
        <FormConstructor formSchema={customerAccountSettingsFormSchema} />
      </FormProvider>
      <Heading as="h2" size="xl" marginBottom={6} marginTop={16}>
        {t('attribute.title.actions')}
      </Heading>
      <HStack spacing={4}>
        <Button variant="link" onClick={handleStopRenew}>
          {t('actions.stop_renew')}
        </Button>
        <Button
          variant="link"
          colorScheme="secondary"
          onClick={handleCancelSubscription}
        >
          {t('actions.cancel_subscription')}
        </Button>
      </HStack>
      <RightSide>
        <RightSideButtons
          onSubmit={onSubmit}
          isDisabledSubmit={!isDirty}
          onCancel={handleCancel}
          isLoading={isLoading}
        />
      </RightSide>
    </ContentContainer>
  );
};
