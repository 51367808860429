import { CSSProperties } from 'react';
import { ListChildComponentProps } from 'react-window';
import { OptionsMap } from 'types';

import { SelectItem } from './SelectItem';

type Props = ListChildComponentProps<string[]> & {
  style: CSSProperties;
  index: number;
  optionMap: OptionsMap['optionMap'];
  search: string;
  isSearchable: boolean;
  selectedValues: string[];
};

export const VirtualItemRow = ({
  style,
  data,
  index,
  optionMap,
  search,
  isSearchable,
  selectedValues
}: Props): JSX.Element => {
  const key = data[index];

  return (
    <SelectItem
      option={optionMap[key]}
      search={search}
      isSearchable={isSearchable}
      isSelected={selectedValues.includes(optionMap[key].value)}
      style={style}
    />
  );
};
