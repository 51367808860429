import {
  Box,
  Button,
  CloseButton,
  Heading,
  HStack,
  VStack
} from '@chakra-ui/react';
import { AddButton } from 'components';

export const Buttons = (): JSX.Element => (
  <HStack spacing={3} align="flex-start">
    <VStack
      w="400px"
      ml="20px"
      spacing={3}
      align="flex-start"
      alignItems="stretch"
    >
      <Box>
        <Heading as="h3" size="md" mt={3} mb={2}>
          Primary button
        </Heading>
        <HStack>
          <Button w="auto">Login</Button>
          <Button w="auto" disabled>
            disabled
          </Button>
        </HStack>
      </Box>
      <Box>
        <Heading as="h3" size="md" mt={3} mb={2}>
          Secondary button
        </Heading>
        <HStack>
          <Button colorScheme="secondary">Delete user</Button>
          <Button colorScheme="secondary" disabled>
            Delete user
          </Button>
        </HStack>
      </Box>
      <Box>
        <Heading as="h3" size="md" mt={3} mb={2}>
          Dark Button
        </Heading>
        <Button variant="ghost" colorScheme="gray">
          Dashboard
        </Button>
        <Button variant="ghost" colorScheme="gray" disabled>
          Dashboard
        </Button>
      </Box>
      <Box>
        <Heading as="h3" size="md" mt={3} mb={2}>
          Text
        </Heading>
        <HStack>
          <Button w="auto" variant="link">
            See more
          </Button>
          <Button w="auto" variant="link" disabled>
            See more
          </Button>
        </HStack>
      </Box>
      <Box>
        <Heading as="h3" size="md" mt={3} mb={2}>
          Text + Icon
        </Heading>
        <HStack>
          <AddButton>See more</AddButton>
          <AddButton disabled>See more</AddButton>
        </HStack>
      </Box>
      <Box>
        <Heading as="h3" size="md" mt={3} mb={2}>
          Pagination button
        </Heading>
        <HStack>
          <Button
            size="xs"
            variant="ghost"
            colorScheme="gray"
            color="black"
            fontSize="md"
            px={0}
          >
            9
          </Button>
        </HStack>
      </Box>
    </VStack>
    <VStack
      w="400px"
      ml="20px"
      spacing={3}
      align="flex-start"
      alignItems="stretch"
    >
      <Heading as="h3" size="lg" mt={3} mb={2}>
        Different variants
      </Heading>
      <Box bgColor="gray.100">
        <Heading as="h3" size="md" mt={3} mb={2}>
          ghostWhite variant
        </Heading>
        <HStack>
          <Button w="auto" variant="ghostWhite">
            Login
          </Button>
          <Button w="auto" variant="ghostWhite" disabled>
            Login
          </Button>
        </HStack>
      </Box>
      <Box bgColor="white">
        <Heading as="h3" size="md" mt={3} mb={2}>
          ghostGray variant
        </Heading>
        <HStack>
          <Button w="auto" variant="ghostGray">
            Login
          </Button>
          <Button w="auto" variant="ghostGray" disabled>
            Login
          </Button>
        </HStack>
      </Box>
      <Box bgColor="gray.100">
        <Heading as="h3" size="md" mt={3} mb={2}>
          Close icon (Round + White)
        </Heading>
        <CloseButton variant="round" colorScheme="white" size="sm" />
      </Box>
      <Box>
        <Heading as="h3" size="md" mt={3} mb={2}>
          Close icon (Round + Black)
        </Heading>
        <CloseButton variant="round" colorScheme="black" size="sm" />
      </Box>
    </VStack>
  </HStack>
);
