import kebabCase from 'lodash/kebabCase';
import {
  REPORT_PAGE,
  REPORT_PAGE_LIST,
  REPORT_SECTION_CONTENT
} from 'pages/Reports/Reports.utils';
import { Navigate, RouteObject } from 'react-router-dom';

export const REPORT_ROUTES: RouteObject[] = REPORT_PAGE_LIST.map<RouteObject>(
  (page) => {
    const { sections, content: Content } = REPORT_PAGE[page];

    const childrenRoutes = sections
      ? [
          ...sections.map((section) => {
            const Section = REPORT_SECTION_CONTENT[page][section];

            return {
              path: kebabCase(section),
              element: <Section />
            };
          }),
          {
            index: true,
            element: <Navigate to={kebabCase(sections[0])} replace />
          }
        ]
      : undefined;

    return {
      path: kebabCase(page),
      element: <Content sections={sections} />,
      children: childrenRoutes
    };
  }
).concat({
  index: true,
  element: <Navigate to={kebabCase(REPORT_PAGE_LIST[0])} replace />
});
