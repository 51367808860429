import { Text, VStack } from '@chakra-ui/react';
import { FormConstructor, ModalContainer } from 'containers';
import { FormField, ModalType } from 'enum';
import {
  useAccessViewConversations,
  useChakraToast,
  useModalActions
} from 'hooks';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { ParamType } from 'types';

import { ViewConversationsFormData } from './ViewConversations.types';
import { viewConversationsFormSchema } from './ViewConversations.utils';

export const ViewConversations = (): JSX.Element => {
  const [t] = useTranslation();
  const toast = useChakraToast();
  const navigate = useNavigate();

  const form = useForm<ViewConversationsFormData>({
    mode: 'onSubmit',
    defaultValues: {
      [FormField.Password]: ''
    }
  });

  const {
    handleSubmit,
    formState: { isDirty }
  } = form;

  const { isOpen, onClose, meta } = useModalActions(
    ModalType.ViewConversations,
    {
      onClose: () => {
        const {
          reset,
          formState: { isSubmitSuccessful }
        } = form;

        reset();

        if (!isSubmitSuccessful) {
          navigate(-1);
        }
      }
    }
  );

  const { mutate, isLoading } = useAccessViewConversations();

  const onSubmit = handleSubmit((data) => {
    if (meta) {
      mutate(
        {
          password: data[FormField.Password]
        },
        {
          onSuccess: ({ hasAccess }) => {
            if (hasAccess) {
              meta.onSuccess();
              onClose();
            } else {
              toast({
                title: t('errors.incorrect_password'),
                status: 'warning'
              });
            }
          }
        }
      );
    }
  });

  const actions: ParamType<typeof ModalContainer, 'footerButtons'> = [
    {
      text: t('actions.cancel'),
      variant: 'ghostGray'
    },
    {
      text: t('actions.view_conversations'),
      colorScheme: 'primary',
      isLoading,
      isPreventClose: true,
      isDisabled: !isDirty,
      onClick: () => {
        onSubmit();
      }
    }
  ];

  return (
    <ModalContainer
      header={t('attribute.title.view_conversations')}
      footerButtons={actions}
      isOpen={isOpen}
      onClose={onClose}
      size="xl"
    >
      <VStack spacing={6} alignItems="stretch">
        <Text>
          {t(
            'attribute.description.get_access_customer_conversations_enter_password'
          )}
        </Text>
        <FormProvider {...form}>
          <FormConstructor formSchema={viewConversationsFormSchema} />
        </FormProvider>
      </VStack>
    </ModalContainer>
  );
};
