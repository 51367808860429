import { Grid, GridItem, IconButton, Text, VStack } from '@chakra-ui/react';
import { AddButton, EditIcon } from 'components';
import {
  FormField,
  ModalType,
  PermissionAction,
  PermissionResource
} from 'enum';
import {
  useAppDispatch,
  useParameterTypeTranslation,
  usePermissions
} from 'hooks';
import { getName } from 'i18n-iso-countries';
import { RemoveButton } from 'pages/GeneralSettings/components';
import { Fragment } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { openModal } from 'store';

import {
  GeneralSettingsFormData,
  GeneralSettingsFormField
} from '../../FormContent.types';
import { useSettingsFormFields } from '../../useSettingsFormFields';

import {
  getCustomerTypeLabelByType,
  getInitialFieldsState,
  isCustomerTypesExist
} from './CustomerRestriction.utils';

export const CustomerRestriction = (): JSX.Element => {
  const [t, { language }] = useTranslation();
  const dispatch = useAppDispatch();

  const { getAllTranslations } = useParameterTypeTranslation();

  const formItemName =
    GeneralSettingsFormField.RestrictCustomerFromAnotherCountry;

  const { fields, append, update, replace, handleRemove } =
    useSettingsFormFields({
      name: formItemName,
      getInitialFieldsState
    });

  const { hasAccess: hasCreateAccess } = usePermissions({
    resource: PermissionResource.SettingsCountryRestrictions,
    actions: PermissionAction.Create
  });
  const { hasAccess: hasReadAccess } = usePermissions({
    resource: PermissionResource.SettingsCountryRestrictions,
    actions: PermissionAction.Read
  });
  const { hasAccess: hasUpdateAccess } = usePermissions({
    resource: PermissionResource.SettingsCountryRestrictions,
    actions: PermissionAction.Update
  });
  const { hasAccess: hasDeleteAccess } = usePermissions({
    resource: PermissionResource.SettingsCountryRestrictions,
    actions: PermissionAction.Delete
  });

  const editRestriction = (
    field: GeneralSettingsFormData[typeof formItemName][number],
    index: number
  ) => {
    dispatch(
      openModal({
        id: ModalType.RestrictionViewCustomers,
        meta: {
          onUpdateData(data) {
            if (data.isEverybodyCanSeeEverybody) {
              replace([data]);
            } else {
              update(index, data);
            }
          },
          initialData: field
        }
      })
    );
  };

  const addNewRestriction = () => {
    dispatch(
      openModal({
        id: ModalType.RestrictionViewCustomers,
        meta: {
          onUpdateData(data) {
            if (data.isEverybodyCanSeeEverybody) {
              replace([data]);
            } else {
              append(data);
            }
          },
          initialData: {
            ...getInitialFieldsState(),
            [FormField.EverybodyCanSeeEverybody]: false
          }
        }
      })
    );
  };

  const getDescription = (
    field: GeneralSettingsFormData[typeof formItemName][number]
  ) =>
    !isCustomerTypesExist(field) ? (
      <Trans
        i18nKey="attribute.description.everybody_can_see_everybody"
        components={{
          b: <b />
        }}
      />
    ) : (
      <Trans
        i18nKey="attribute.description.customer_cant_see_other_customer"
        values={{
          customerType: getCustomerTypeLabelByType(
            field.customerType,
            t,
            getAllTranslations
          ),
          customerTypeFromAnotherCountry: getCustomerTypeLabelByType(
            field.otherCustomerType,
            t,
            getAllTranslations
          ),
          country: field.countries
            .map((code) => getName(code, language))
            .join(', ')
        }}
        components={{
          b: <b />
        }}
      />
    );

  const canAddRestriction = fields.every(
    ({ isEverybodyCanSeeEverybody }) => !isEverybodyCanSeeEverybody
  );

  return (
    <Fragment>
      <GridItem>
        {t(
          'attribute.description.restrictions_view_customer_from_another_country'
        )}
      </GridItem>
      <GridItem>
        {hasReadAccess && (
          <Fragment>
            <VStack alignItems="stretch" spacing={10}>
              {fields.map((field, index) => (
                <Grid
                  key={field.id}
                  templateColumns="1fr auto auto"
                  columnGap={3}
                >
                  <GridItem>
                    <Text>{getDescription(field)}</Text>
                  </GridItem>
                  <GridItem>
                    {hasUpdateAccess && (
                      <IconButton
                        icon={<EditIcon fontSize="2xl" />}
                        aria-label="edit restrictions"
                        variant="ghostGray"
                        color="gray.500"
                        onClick={() => editRestriction(field, index)}
                      />
                    )}
                  </GridItem>
                  <GridItem>
                    {hasDeleteAccess && (
                      <RemoveButton
                        onClick={() => handleRemove(index)}
                        marginTop={0}
                      />
                    )}
                  </GridItem>
                </Grid>
              ))}
            </VStack>
            {canAddRestriction && hasCreateAccess && (
              <AddButton
                alignSelf="flex-start"
                onClick={addNewRestriction}
                marginTop={4}
              >
                {t('actions.add_restriction')}
              </AddButton>
            )}
          </Fragment>
        )}
      </GridItem>
    </Fragment>
  );
};
