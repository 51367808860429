import { useMutation } from '@tanstack/react-query';
import { fetchAccessViewConversations } from 'api/Customers/Conversations/fetchAccessViewConversations';
import { AxiosError } from 'axios';
import { AxiosErrorData } from 'types';

export const useAccessViewConversations = () =>
  useMutation(fetchAccessViewConversations, {
    onSuccess() {},
    onError(error: AxiosError<AxiosErrorData>) {}
  });
