import { Box, FormLabel, VStack } from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { RangeInput } from './RangeInput';
import { RangeInputProps } from './RangeList.types';
import { serializeUpdatedValues } from './RangeList.utils';

type Props = {
  options?: undefined;
  selectedValues: (string | number | undefined)[];
  onChange: (value: (string | number | undefined)[]) => void;
  inputProps: RangeInputProps;
};

export const RangeList = ({
  selectedValues,
  onChange,
  inputProps
}: Props): JSX.Element => {
  const [t] = useTranslation();

  const [values, setValues] = useState(selectedValues);

  useEffect(() => {
    setValues(selectedValues);
  }, [selectedValues]);

  const onBlur = () => {
    const newValues = serializeUpdatedValues({
      prevValues: selectedValues,
      newValues: values,
      inputType: inputProps.type
    });

    onChange(newValues);
    setValues(newValues);
  };

  return (
    <VStack spacing={4} padding={3} alignItems="flex-start">
      <Box>
        <FormLabel>{t('keywords.from')}</FormLabel>
        <RangeInput
          {...inputProps}
          value={values[0]}
          onBlur={onBlur}
          onChange={(value: string | number | undefined) => {
            setValues((prev) => [value, prev[1]]);
          }}
        />
      </Box>
      <Box>
        <FormLabel>{t('keywords.to')}</FormLabel>
        <RangeInput
          {...inputProps}
          value={values[1]}
          onBlur={onBlur}
          onChange={(value: string | number | undefined) => {
            setValues((prev) => [prev[0], value]);
          }}
        />
      </Box>
    </VStack>
  );
};
