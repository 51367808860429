import { Button } from '@chakra-ui/react';
import { ModalType } from 'enum';
import { useAppDispatch } from 'hooks';
import { useTranslation } from 'react-i18next';
import { openModal } from 'store';

type Props = {
  countries: string[];
} & (
  | {
      methodId: string;
      subscriptionId?: undefined;
    }
  | {
      subscriptionId: string;
      methodId?: undefined;
    }
);

export const EditCountriesCell = (props: Props): JSX.Element => {
  const [t] = useTranslation();
  const dispatch = useAppDispatch();

  const onEditCountries = () => {
    dispatch(
      openModal({
        id: ModalType.EditPaymentMethodCountries,
        meta: props
      })
    );
  };

  return (
    <Button variant="link" onClick={onEditCountries}>
      {t('actions.edit_country', {
        count: 2
      })}
    </Button>
  );
};
