import range from 'lodash/range';
import { Option } from 'types';

export const MAX_BUTTONS = 5;
export const ELLIPSES = '...';

// https://stackoverflow.com/a/70263913
export const getPaginationList =
  (count: number) =>
  (page: number, total: number): (string | number)[] => {
    const { max, min, floor } = Math;
    const start = max(1, min(page - floor((count - 3) / 2), total - count + 2));
    const end = min(total, max(page + floor((count - 2) / 2), count - 1));
    return [
      ...(start > 2 ? [1, ELLIPSES] : start > 1 ? [1] : []),
      ...Array.from({ length: end + 1 - start }, (_, i) => i + start),
      ...(end < total - 1 ? [ELLIPSES, total] : end < total ? [total] : [])
    ];
  };

export const getShowRowsOptions = (): Option[] =>
  range(25, 100, 25).map((value) => ({
    value: String(value),
    label: String(value)
  }));
