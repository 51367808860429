import { getCSSVar } from '@chakra-ui/react';
import { convertRemToPixels } from 'utils';

import { GaugeChartElementProps } from '../GaugeChart.types';

export const GaugeNeedle = ({
  gauge,
  theme,
  settings,
  fontSize,
  currentValue
}: GaugeChartElementProps): JSX.Element => {
  const { valueToAngle } = gauge;
  const { radius, needleOffset, needleColor } = settings;

  const needleLength =
    convertRemToPixels(radius, fontSize).pxValue -
    convertRemToPixels(needleOffset, fontSize).pxValue;

  const needleColorVar = getCSSVar(theme, 'colors', needleColor);

  return (
    <g>
      <line
        stroke={needleColorVar}
        x1={0}
        y1={0}
        x2={1}
        y2={needleLength}
        style={{
          transition: 'transform 0.5s'
        }}
        strokeLinecap="round"
        transform={`rotate(${valueToAngle(currentValue)})`}
      />
      <circle stroke={needleColorVar} fill="white" cx={0} cy={0} r={2} />
    </g>
  );
};
