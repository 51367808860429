import { LineSvgProps } from '@nivo/line';
import { BaseChartDataTemplate } from 'api';
import { scaleLinear } from 'd3';
import { Period } from 'enum';
import { useId } from 'react';
import { ChartFilters } from 'types';

import { LineChartDataMap } from './LineChart.types';
import {
  getLineChartArea,
  getLineChartAxisProps,
  getLineChartMargins,
  getLineChartMarkers,
  Y_AXIS_TICKS_AMOUNT
} from './LineChart.utils';
import { useLineChartTheme } from './useLineChartTheme';

export const useLineChartProps = <T extends BaseChartDataTemplate>({
  dataMap,
  filters
}: {
  dataMap: LineChartDataMap<T>;
  filters: ChartFilters;
}): {
  chartProps: LineSvgProps;
} => {
  const theme = useLineChartTheme();
  const chartId = useId();

  const { chartSettings, chartData, maxYValue, minYValue } = dataMap;
  const { formatXAxis, formatYAxis } = chartSettings;

  const { period = Period.AllTime } = filters;

  const { defs, fill, enableArea } = getLineChartArea({
    dataMap,
    chartId
  });

  const yDomain = scaleLinear()
    .domain([minYValue, maxYValue || 1])
    .nice(Y_AXIS_TICKS_AMOUNT)
    .ticks(Y_AXIS_TICKS_AMOUNT);

  const minYAxis = yDomain[0];
  const maxYAxis = maxYValue === 0 && minYAxis === 0 ? 1 : undefined;

  const axisProps = getLineChartAxisProps({
    chartSettings,
    period,
    minYAxis
  });

  const margins = getLineChartMargins({
    dataMap,
    period,
    axisTheme: theme['axis'],
    axisProps,
    yDomain
  });

  const chartProps: LineSvgProps = {
    ...axisProps,
    useMesh: true,
    enableCrosshair: false,
    areaBaselineValue: minYAxis,
    enableArea,
    enableGridY: true,
    enableGridX: false,
    gridYValues: Y_AXIS_TICKS_AMOUNT,
    areaOpacity: 1,
    data: chartData,
    margin: margins,
    colors: { datum: 'color' },
    xScale: { type: 'point' },
    yScale: {
      type: 'linear',
      min: minYAxis,
      max: maxYAxis,
      nice: Y_AXIS_TICKS_AMOUNT
    },
    xFormat: formatXAxis ? (value) => formatXAxis(value, period) : undefined,
    yFormat: formatYAxis,
    lineWidth: 3,
    pointSize: 6,
    theme,
    defs,
    fill,
    markers: getLineChartMarkers({ dataMap, minYAxis })
  };

  return {
    chartProps
  };
};
