import {
  DatumValue,
  defaultMargin,
  linearGradientDef,
  Margin,
  SvgDefsAndFill,
  Theme as LineChartTheme
} from '@nivo/core';
import { Datum, LineSvgProps } from '@nivo/line';
import { BaseChartDataTemplate } from 'api';
import { Period } from 'enum';
import { getTextWidth } from 'utils';

import {
  LineChartAxisProps,
  LineChartDataMap,
  LineChartSettings
} from './LineChart.types';

export const Y_AXIS_TICKS_AMOUNT = 8;

const getXAxisStartAndEndValues = <T extends BaseChartDataTemplate>({
  dataMap,
  period
}: {
  dataMap: LineChartDataMap<T>;
  period: Period;
}) => {
  const {
    chartData,
    chartSettings: { formatXAxis }
  } = dataMap;

  const firstValue = chartData[0].data[0].x;
  const lastValue = chartData[0].data[chartData[0].data.length - 1].x;

  const [startXAxisValue, endXAxisValue] = [firstValue, lastValue].map(
    (value) => (formatXAxis ? formatXAxis(value, period) : value)
  );

  return {
    startXAxisValue,
    endXAxisValue
  };
};

const getMaxYWidth = ({
  yDomain,
  font
}: {
  yDomain: number[];
  font: {
    fontSize: string;
    fontWeight: string;
  };
}) => {
  const maxYText = yDomain.reduce((acc, value) => {
    const valueString = value.toString();

    if (valueString.length > acc.length) {
      return valueString;
    }

    return acc;
  }, '');

  return getTextWidth(maxYText, font).width;
};

export const getLineChartMargins = <T extends BaseChartDataTemplate>({
  dataMap,
  period,
  axisTheme,
  axisProps,
  yDomain
}: {
  dataMap: LineChartDataMap<T>;
  period: Period;
  axisTheme: LineChartTheme['axis'];
  axisProps: LineChartAxisProps;
  yDomain: number[];
}): Margin => {
  const { chartData } = dataMap;

  if (chartData.length === 0) return defaultMargin;

  const { axisBottom, axisLeft } = axisProps;

  const bottomTextPadding =
    (axisBottom?.tickSize || 0) + (axisBottom?.tickPadding || 0);
  const leftTextPadding =
    (axisLeft?.tickSize || 0) + (axisLeft?.tickPadding || 0);

  const { fontSize, fontWeight } = axisTheme?.ticks?.text || {};

  const font = {
    fontSize: fontSize as string,
    fontWeight: fontWeight as string
  };

  const { startXAxisValue, endXAxisValue } = getXAxisStartAndEndValues({
    dataMap,
    period
  });

  const startXAxis = getTextWidth(startXAxisValue, font);
  const endXAxis = getTextWidth(endXAxisValue, font);

  const maxYAxisWidth = getMaxYWidth({
    yDomain,
    font
  });

  return {
    top: 5,
    bottom: bottomTextPadding + startXAxis.height,
    right: endXAxis.width / 2,
    left: leftTextPadding + Math.max(maxYAxisWidth, startXAxis.width / 2)
  };
};

export const getLineChartArea = <T extends BaseChartDataTemplate>({
  dataMap,
  chartId
}: {
  dataMap: LineChartDataMap<T>;
  chartId: string;
}): SvgDefsAndFill<Datum> & {
  enableArea: boolean;
} => {
  const {
    chartData,
    chartSettings: { applyAreaIds }
  } = dataMap;

  if (!applyAreaIds || applyAreaIds.length === 0)
    return {
      enableArea: false
    };

  const defineGradients = applyAreaIds.map((id) => {
    const { color = 'black' } =
      chartData.find(({ serieId }) => serieId === id) || {};

    return linearGradientDef(`${chartId}-${id}`, [
      { offset: 0, color, opacity: 0.5 },
      { offset: 100, color, opacity: 0.18 }
    ]);
  });

  const defineMatches = applyAreaIds.map((id) => ({
    id: `${chartId}-${id}`,
    match: { id }
  }));

  return {
    enableArea: true,
    defs: [
      ...defineGradients,
      linearGradientDef('none', [
        { offset: 0, color: 'transparent' },
        { offset: 100, color: 'transparent' }
      ])
    ],
    fill: [...defineMatches, { match: '*', id: 'none' }]
  };
};

export const getLineChartMarkers = <T extends BaseChartDataTemplate>({
  dataMap,
  minYAxis
}: {
  dataMap: LineChartDataMap<T>;
  minYAxis: number;
}): LineSvgProps['markers'] => {
  const yMarkers: LineSvgProps['markers'] = [];

  if (dataMap.chartData[0]) {
    dataMap.chartData[0].data.forEach(({ x }) => {
      yMarkers.push({
        axis: 'x',
        value: x
      });
    });
  }

  yMarkers.push({
    axis: 'y',
    value: minYAxis
  });

  return yMarkers;
};

export const getLineChartAxisProps = <T extends BaseChartDataTemplate>({
  chartSettings,
  period,
  minYAxis
}: {
  chartSettings: LineChartSettings<T>;
  period: Period;
  minYAxis: number;
}): Required<LineChartAxisProps> => {
  const {
    formatXAxis,
    formatYAxis,
    shouldShowMinYAxisTick = false
  } = chartSettings;

  return {
    axisTop: null,
    axisRight: null,
    axisBottom: {
      tickSize: 0,
      tickPadding: 5,
      format: formatXAxis
        ? (value: DatumValue) => formatXAxis(value, period)
        : undefined
    },
    axisLeft: {
      tickValues: Y_AXIS_TICKS_AMOUNT,
      tickSize: 0,
      tickPadding: 5,
      format: (value: DatumValue) => {
        if (!shouldShowMinYAxisTick && value === minYAxis) {
          return '';
        }

        if (formatYAxis) {
          return formatYAxis(value);
        }

        return value;
      }
    }
  };
};
