import { BaseChartDataTemplate } from 'api';
import { BarChartLayout } from 'enum';
import { useAppSelector } from 'hooks';
import { useTranslation } from 'react-i18next';
import { selectSettings } from 'store';

import {
  CustomStackedBarChartData,
  CustomStackedBarProps,
  StackedBarProps
} from './CustomStackedBarChart.types';

export const useStackedChartSeries = <T extends BaseChartDataTemplate>({
  seriesId,
  dataCount,
  chartSettings
}: StackedBarProps<T>): {
  seriesTitle: string;
  getStackedBarProps: (
    data: CustomStackedBarChartData['data'][number],
    index: number
  ) => CustomStackedBarProps;
} => {
  const [t] = useTranslation();

  const settings = useAppSelector(selectSettings);

  const { getTitleLabel, getLabel, layout, getBarColor } = chartSettings;

  const title = getTitleLabel({ id: seriesId, t, settings });

  const getVerticalStackedBarProps: ReturnType<
    typeof useStackedChartSeries
  >['getStackedBarProps'] = (data, index) => {
    const { id, percentage, color: baseColor, value } = data;

    return {
      container: {
        height: `${percentage}%`,
        width: 24,
        minHeight: 12,
        minWidth: 24,
        borders: {
          borderTopRadius: index === dataCount - 1 ? 'xl' : 0,
          borderBottomRadius: index === 0 ? 'xl' : 0
        }
      },
      base: {
        ...getBarColor(index, baseColor),
        value,
        description: getLabel({ id, t, settings })
      }
    };
  };

  const getHorizontalStackedBarProps: ReturnType<
    typeof useStackedChartSeries
  >['getStackedBarProps'] = (data, index) => {
    const { id, percentage, color: baseColor, value } = data;

    return {
      container: {
        height: 8,
        width: `${percentage}%`,
        minHeight: 8,
        minWidth: 28,
        borders: {
          borderRightRadius: index === dataCount - 1 ? 'xl' : 0,
          borderLeftRadius: index === 0 ? 'xl' : 0
        }
      },
      base: {
        ...getBarColor(index, baseColor),
        value,
        description: getLabel({ id, t, settings })
      }
    };
  };

  return {
    seriesTitle: title,
    getStackedBarProps:
      layout === BarChartLayout.Horizontal
        ? getHorizontalStackedBarProps
        : getVerticalStackedBarProps
  };
};
