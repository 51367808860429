import { ModalContainer } from 'containers';
import { ModalType } from 'enum';
import { useModalActions, useResetCustomerPassword } from 'hooks';
import { useTranslation } from 'react-i18next';
import { ParamType } from 'types';

export const ResetCustomerPassword = (): JSX.Element => {
  const [t] = useTranslation();

  const {
    mutate: resetPassword,
    isLoading,
    reset
  } = useResetCustomerPassword();

  const clearComponentStates = () => {
    reset();
  };

  const { isOpen, onClose, meta } = useModalActions(
    ModalType.ResetCustomerPassword,
    {
      onClose: clearComponentStates
    }
  );

  const actions: ParamType<typeof ModalContainer, 'footerButtons'> = [
    {
      text: t('actions.cancel'),
      variant: 'ghostGray'
    },
    {
      text: t('actions.reset_password'),
      colorScheme: 'secondary',
      isLoading,
      isPreventClose: true,
      onClick: (onClose) => {
        if (meta) {
          resetPassword(meta.customerId, {
            onSuccess: onClose
          });
        }
      }
    }
  ];

  return (
    <ModalContainer
      header={t('actions.reset_password')}
      footerButtons={actions}
      isOpen={isOpen}
      onClose={onClose}
    >
      {t('attribute.description.reset_customer_password')}
    </ModalContainer>
  );
};
