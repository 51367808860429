import { ENDPOINTS, ReportAdminListResponse, WithPagination } from 'api';
import { serializeBodyFilters } from 'api/api.utils';
import axios from 'axios';
import { AdminReportReason, ReportStatus, ReportType } from 'enum/api';
import { GlobalFilters, QueryFilters } from 'types';

const photoUrl =
  'https://images.pexels.com/photos/220453/pexels-photo-220453.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const fakedData: ReportAdminListResponse[] = [
  {
    id: 'report_1',
    createdAt: new Date().toISOString(),
    type: ReportType.Gallery,
    reason: AdminReportReason.BadWords,
    meta: null,
    status: ReportStatus.Open,
    hasRead: false,
    comment: 'Some comment',
    imageUrl: photoUrl,
    reporter: null,
    reported: {
      id: 'id2',
      nickname: 'nickname2',
      email: 'email2',
      hasAdminAccess: true,
      avatar: null
    }
  },
  {
    id: 'report_2',
    createdAt: new Date().toISOString(),
    type: ReportType.Gallery,
    reason: AdminReportReason.BadWords,
    meta: null,
    status: ReportStatus.InProgress,
    hasRead: true,
    comment: 'Some comment',
    imageUrl: photoUrl,
    reporter: null,
    reported: {
      id: 'id2',
      nickname: 'nickname2',
      email: 'email2',
      avatar: null,
      hasAdminAccess: true
    }
  }
];

export const fetchAdminReportList = async ({
  queryFilters,
  globalFilters
}: {
  queryFilters: QueryFilters;
  globalFilters: GlobalFilters;
}): Promise<WithPagination<ReportAdminListResponse[]>> => {
  const { data } = await axios.post<WithPagination<ReportAdminListResponse[]>>(
    ENDPOINTS.REPORT_ADMIN_LIST,
    serializeBodyFilters({ queryFilters, globalFilters })
  );

  return data;
};
