import { CellContext } from '@tanstack/react-table';
import { NavigationLink } from 'components';
import { ICustomerPayments } from 'types/customer';
import { getBaseAdminLinkNavigation } from 'utils';

type Props = CellContext<ICustomerPayments, string | undefined>;

export const AdminNicknameCell = ({
  row: {
    original: { admin }
  }
}: Props): JSX.Element | null => {
  if (!admin) return null;

  return (
    <NavigationLink to={getBaseAdminLinkNavigation(admin.id)}>
      {admin.nickname}
    </NavigationLink>
  );
};
