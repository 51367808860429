import { chakra } from '@chakra-ui/react';
import { GroupBase, IndicatorSeparatorProps } from 'react-select';

import { useStyles } from '../LocationDropdown.utils';

export const IndicatorSeparator = <
  Option,
  IsMulti extends boolean,
  Group extends GroupBase<Option>
>(
  props: IndicatorSeparatorProps<Option, IsMulti, Group>
): JSX.Element => {
  const { innerProps } = props;

  const styles = useStyles();

  return <chakra.span __css={styles.indicatorSeparator} {...innerProps} />;
};
