import { GridItem } from '@chakra-ui/react';
import { FormElement } from 'containers';
import { InputElement } from 'enum';
import { Fragment } from 'react';
import { useTranslation } from 'react-i18next';

import { GeneralSettingsFormField } from '../FormContent.types';

export const StartConversationsDailyLimit = (): JSX.Element => {
  const [t] = useTranslation();

  return (
    <Fragment>
      <GridItem>
        {t('attribute.description.start_conversations_limit')}
      </GridItem>
      <GridItem width="50%">
        <FormElement
          type={InputElement.NumberInput}
          name={GeneralSettingsFormField.StartedConversationsPerDayLimit}
          translationKey="start_conversations_daily_limit"
          getPlaceholder={() => ''}
          min={0}
          max={10000}
        />
      </GridItem>
    </Fragment>
  );
};
