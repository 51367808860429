import { RefObject, useEffect, useRef } from 'react';
import { useIntersection, useLatest } from 'react-use';

export const useLoadMoreData = ({
  loadMore,
  intersectionOptions
}: {
  loadMore: () => void;
  intersectionOptions?: IntersectionObserverInit;
}): {
  intersectionRef: RefObject<HTMLDivElement>;
} => {
  const intersectionRef = useRef<HTMLDivElement>(null);
  const loadMoreLatest = useLatest(loadMore);

  const intersection = useIntersection(intersectionRef, {
    threshold: 1,
    ...intersectionOptions
  });

  const { isIntersecting } = intersection || {};

  useEffect(() => {
    const { current: loadMore } = loadMoreLatest;

    if (isIntersecting) {
      loadMore();
    }
  }, [loadMoreLatest, isIntersecting]);

  return {
    intersectionRef
  };
};
