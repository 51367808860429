import { useMutation } from '@tanstack/react-query';
import {
  fetchParameterValues,
  fetchUpdateParameterValue,
  ParameterValue,
  WithPagination
} from 'api';
import { QueryKey } from 'enum/api';
import { queryClient } from 'services';

export const useUpdateParameterValue = (
  params: Parameters<typeof fetchParameterValues>[0]
) => {
  const key = [
    QueryKey.ParameterValues,
    params.pagination.page,
    params.pagination.limit,
    params.filter?.value,
    params.filter?.parameterTitle,
    params.sort?.updatedAt,
    params.sort?.value,
    ...(params.filter?.parameterTypes || []),
    ...(params.filter?.languageCodes || [])
  ];

  return useMutation(fetchUpdateParameterValue, {
    onMutate: async ({ id, ...updates }) => {
      const prevData =
        queryClient.getQueryData<WithPagination<ParameterValue[]>>(key);

      let skipInvalidation = false;

      queryClient.setQueryData<WithPagination<ParameterValue[]>>(
        key,
        (data) => {
          if (!data) {
            return data;
          }

          return {
            ...data,
            data: data.data.map((option) => {
              if (option.id === id) {
                skipInvalidation = true;
                return {
                  ...option,
                  ...updates
                };
              }

              return option;
            })
          };
        }
      );

      return { prevData, skipInvalidation };
    },
    onError: (_, __, context) => {
      if (!context?.skipInvalidation) {
        queryClient.invalidateQueries([QueryKey.ParameterValues]);
        return;
      }

      queryClient.setQueryData(key, context.prevData);
    }
  });
};
