import { AllCustomerListResponse, ENDPOINTS, WithPagination } from 'api';
import { serializeBodyFilters } from 'api/api.utils';
import axios from 'axios';
import { GlobalFilters, QueryFilters } from 'types';

export const fetchAllCustomerList = async ({
  globalFilters,
  queryFilters
}: {
  queryFilters: QueryFilters;
  globalFilters: GlobalFilters;
}): Promise<WithPagination<AllCustomerListResponse[]>> => {
  const { data } = await axios.post<WithPagination<AllCustomerListResponse[]>>(
    ENDPOINTS.CUSTOMERS_LIST_ALL,
    serializeBodyFilters({ globalFilters, queryFilters })
  );

  return data;
};
