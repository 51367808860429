import { HStack, Text } from '@chakra-ui/react';
import { Dropdown } from 'components';
import { DropdownType } from 'enum';
import { useCountryFilter } from 'hooks';
import { useTranslation } from 'react-i18next';

export const CountryFilter = (): JSX.Element => {
  const [t] = useTranslation();

  const { options, selectedValues, isDisabled, handleChange } =
    useCountryFilter();

  return (
    <HStack>
      <Text textStyle="xss">{t('keywords.country')}</Text>
      <Dropdown
        type={DropdownType.Select}
        isMulti
        isSearchable
        isControllable
        options={options}
        values={selectedValues}
        onChange={handleChange}
        placeholder={t('keywords.country')}
        isDisabled={isDisabled}
      />
    </HStack>
  );
};
