import { useQuery } from '@tanstack/react-query';
import { fetchParameterValues } from 'api';
import { QueryKey } from 'enum/api';
import { checkIfTokenExists } from 'utils';

export const useParameterValues = (
  params: Parameters<typeof fetchParameterValues>[0]
) => {
  const isEnabled = checkIfTokenExists();

  return useQuery(
    [
      QueryKey.ParameterValues,
      params.pagination.page,
      params.pagination.limit,
      params.filter?.value,
      params.filter?.parameterTitle,
      params.sort?.updatedAt,
      params.sort?.value,
      ...(params.filter?.parameterTypes || []),
      ...(params.filter?.languageCodes || [])
    ],
    () => fetchParameterValues(params),
    {
      retry: false,
      refetchOnWindowFocus: false,
      keepPreviousData: true,
      enabled: isEnabled
    }
  );
};
