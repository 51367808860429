import { useQuery } from '@tanstack/react-query';
import { fetchSubscriptionOptionsByUser } from 'api';
import { QueryKey } from 'enum/api';

export const useSubscriptionOptionsByUser = ({
  customerId
}: {
  customerId: string;
}) =>
  useQuery(
    [QueryKey.PaymentSubscriptionTypeByUser, customerId],
    () => fetchSubscriptionOptionsByUser({ customerId }),
    {
      retry: false,
      refetchOnWindowFocus: false,
      initialData: {},
      enabled: !!customerId
    }
  );
