import { Text } from '@chakra-ui/react';
import { NavigationLink } from 'components';
import { useCustomerInfo } from 'hooks';
import { useTranslation } from 'react-i18next';
import { getBaseCustomerLinkNavigation } from 'utils';

export const UserData = ({ userId }: { userId: string }): JSX.Element => {
  const [t] = useTranslation();
  const { data } = useCustomerInfo(userId || '');

  if (!data) return <Text>{t('keywords.unknown_user')}</Text>;

  return (
    <NavigationLink shouldUnstyled to={getBaseCustomerLinkNavigation(data.id)}>
      <Text>{data.displayedNickname}</Text>
    </NavigationLink>
  );
};
