import { useQuery } from '@tanstack/react-query';
import { fetchCustomerPaymentList } from 'api';
import { QueryKey } from 'enum/api';
import { QueryFilters } from 'types';

export const useCustomerPayments = ({
  customerId,
  queryFilters
}: {
  customerId: string;
  queryFilters: QueryFilters;
}) =>
  useQuery(
    [QueryKey.CustomerPayments, customerId, queryFilters],
    () =>
      fetchCustomerPaymentList({
        customerId,
        queryFilters
      }),
    {
      retry: false,
      refetchOnWindowFocus: false,
      keepPreviousData: true
    }
  );
