import { FormField, InputElement } from 'enum';
import * as yup from 'yup';

import { CreateCommentFormData } from './CreateComment.types';

export const createCustomerCommentFormSchema = {
  fields: {
    [FormField.Comment]: {
      type: InputElement.TextArea,
      translationKey: 'comment',
      getPlaceholder: () => '',
      minHeight: 44,
      maxLength: 2500
    },
    [FormField.Files]: {
      type: InputElement.UploadFile,
      fileLimit: 1
    }
  },
  gridLayout: {
    templateAreas: `
    "${FormField.Comment}"
    "${FormField.Files}"
    `,
    gridTemplateColumns: '1fr',
    rowGap: 8
  }
} as const;

export const createCommentValidationSchema: yup.SchemaOf<CreateCommentFormData> =
  yup.object({
    [FormField.Comment]: yup.string().required(),
    [FormField.Files]: yup
      .array(
        yup
          .object()
          .shape({
            file: yup.mixed().required(),
            url: yup.string().required()
          })
          .required()
      )
      .defined()
  });
