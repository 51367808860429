import { Button, Text } from '@chakra-ui/react';
import { NavigationLink } from 'components';
import { CustomerPage, CustomerSection, ModalType } from 'enum';
import { useAppDispatch, useUpdateTicket } from 'hooks';
import { kebabCase } from 'lodash';
import { useTranslation } from 'react-i18next';
import { CUSTOMER_PAGE_LINK_BY_PAGE } from 'routes/routes.utils';
import { openModal } from 'store/modal';
import { getBaseCustomerLinkNavigation } from 'utils';

type Props = {
  user: {
    id: string;
    nickname: string;
    displayedNickname: string;
    email: string;
  } | null;
  ticketId: string;
};

export const UserCell = ({ user, ticketId }: Props): JSX.Element => {
  const [t] = useTranslation();
  const dispatch = useAppDispatch();

  const { mutate } = useUpdateTicket();

  const handleChooseUser = () => {
    dispatch(
      openModal({
        id: ModalType.CustomerFullList,
        meta: {
          onChooseCustomer: ({ id: userId }) => {
            mutate({
              ticketId,
              userId
            });
          }
        }
      })
    );
  };

  if (!user)
    return (
      <Button variant="link" onClick={handleChooseUser}>
        {t('keywords.unknown_user')}
      </Button>
    );

  return (
    <NavigationLink
      shouldUnstyled
      to={`${getBaseCustomerLinkNavigation(user.id)}/${
        CUSTOMER_PAGE_LINK_BY_PAGE[CustomerPage.AdminInteraction]
      }/${kebabCase(CustomerSection.TicketHistory)}`}
    >
      <Text>{user.displayedNickname}</Text>
      <Text color="subtext">{user.email}</Text>
    </NavigationLink>
  );
};
