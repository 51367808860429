import {
  FormControl,
  FormHelperText,
  FormLabel,
  NumberInput,
  NumberInputField
} from '@chakra-ui/react';
import { FormError } from 'components';
import { InputElement } from 'enum';
import { PermissionAction } from 'enum/api';
import { usePermissions } from 'hooks';
import isNaN from 'lodash/isNaN';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { FormComponentProps } from '../FormConstructor.types';

type Props = FormComponentProps<{ type: InputElement.NumberInput }>;

export const FormNumberInput = (props: Props): JSX.Element => {
  const [t] = useTranslation();

  const { control, formState, getFieldState } = useFormContext();
  const { checkPermissions } = usePermissions();

  const {
    type,
    translationKey,
    name,
    hintTranslationKey,
    getPlaceholder,
    isDisabled,
    resource,
    ...rest
  } = props;
  const { error } = getFieldState(name, formState);
  const hasUpdateAccess = resource
    ? checkPermissions({
        resource,
        actions: PermissionAction.Update
      })
    : true;

  return (
    <FormControl isInvalid={!!error}>
      <FormLabel>{t(`keywords.${translationKey}`)}</FormLabel>
      <Controller
        control={control}
        name={name}
        render={({ field: { onChange, value } }) => (
          <NumberInput
            isDisabled={isDisabled || !hasUpdateAccess}
            {...rest}
            onChange={(valueAsString, valueAsNumber) => {
              const newValue = rest.precision
                ? valueAsString
                : isNaN(valueAsNumber)
                ? undefined
                : valueAsNumber;

              onChange(newValue);
            }}
            value={value ?? ''}
          >
            <NumberInputField placeholder={getPlaceholder(t)} />
          </NumberInput>
        )}
      />
      {!!hintTranslationKey && !error && (
        <FormHelperText>
          {t(`attribute.hint.${hintTranslationKey}`)}
        </FormHelperText>
      )}
      <FormError errorKey={error?.message} />
    </FormControl>
  );
};
