import { ModerationGalleryPhotoResponse } from 'api';
import {
  CalendarIcon,
  DangerIcon,
  ImageIcon,
  InfoCardProps,
  MailIcon,
  MessageCircleIcon
} from 'components';
import { format } from 'date-fns';
import { TFunction } from 'react-i18next';

export const getCustomerProfileInformation = (
  customer: ModerationGalleryPhotoResponse['user'],
  t: TFunction
): InfoCardProps[] => [
  {
    Icon: CalendarIcon,
    header: format(new Date(customer.createdAt), 'HH:mm, dd.MM.yyyy'),
    description: t('keywords.registration_date_and_time')
  },
  {
    Icon: ImageIcon,
    header: customer.galleryPhotosNum || t('keywords.no_images'),
    description: t('keywords.photo', {
      count: customer.galleryPhotosNum
    })
  },
  {
    Icon: DangerIcon,
    header: customer.reportsByNum,
    description: t('attribute.description.reports_per_customer')
  },
  {
    Icon: MessageCircleIcon,
    header: customer.commentsAdminsNum,
    description: t('keywords.admin_comments')
  },
  {
    Icon: MailIcon,
    header: customer.messagesByNum,
    description: t('attribute.description.number_sent_messages')
  }
];
