import {
  Box,
  HStack,
  NumberInput,
  NumberInputField,
  Wrap
} from '@chakra-ui/react';
import { PaginationState } from '@tanstack/react-table';
import { Dropdown } from 'components/Dropdown';
import { Direction, DropdownType, UserEvent } from 'enum';
import { KeyboardEventHandler } from 'react';

import { ArrowIcon } from './ArrowIcon';
import { PaginationButtons } from './PaginationButtons';
import { getShowRowsOptions } from './utils';

type Props = {
  pagination: PaginationState;
  nextPage: () => void;
  prevPage: () => void;
  canNextPage: boolean;
  canPrevPage: boolean;
  goToPage: (index: number) => void;
  totalPages: number;
  numberOfItems: number;
  defaultNumberOfPages?: number;
  onChangePageSize: (value: number) => void;
};

const showRowsOptions = getShowRowsOptions();

export const Pagination = ({
  pagination,
  nextPage,
  prevPage,
  canNextPage,
  canPrevPage,
  goToPage,
  totalPages,
  numberOfItems,
  onChangePageSize
}: Props): JSX.Element => {
  const { pageIndex, pageSize } = pagination;
  const page = pageIndex + 1;
  const range = `${pageIndex * pageSize + 1}-${Math.min(
    (pageIndex + 1) * pageSize,
    numberOfItems
  )}`;

  const onKeyDown: KeyboardEventHandler<HTMLInputElement> = (e) => {
    if (e.code === UserEvent.Enter) {
      (e.target as HTMLInputElement).blur();

      const getValueInRange = (): number => {
        let {
          value: inputValue,
          ariaValueMin,
          ariaValueMax
        } = e.target as HTMLInputElement;

        const [value, min, max] = [inputValue, ariaValueMin, ariaValueMax].map(
          Number
        );

        if (value < min) return min;
        if (value > max) return max;

        return value;
      };

      goToPage(getValueInRange() - 1);
    }
  };

  return (
    <Wrap shouldWrapChildren justify="space-between" overflow="unset">
      <HStack spacing={3}>
        <Box whiteSpace="pre">Show rows</Box>
        <Box maxWidth={20}>
          <Dropdown
            type={DropdownType.Select}
            options={showRowsOptions}
            isControllable
            values={[String(pageSize)]}
            onChange={([value]) => onChangePageSize(Number(value))}
          />
        </Box>
      </HStack>
      <HStack spacing={10}>
        <Box whiteSpace="pre">
          {range} of {numberOfItems} items
        </Box>
        <HStack>
          <ArrowIcon
            direction={Direction.Left}
            isDisabled={!canPrevPage}
            onClick={prevPage}
          />
          <PaginationButtons
            page={page}
            totalSize={totalPages}
            onClick={(value) => goToPage(value - 1)}
          />
          <ArrowIcon
            direction={Direction.Right}
            isDisabled={!canNextPage}
            onClick={nextPage}
          />
        </HStack>
        <HStack spacing={3}>
          <Box whiteSpace="pre">Go to page</Box>
          <NumberInput
            defaultValue={1}
            min={1}
            max={totalPages}
            onKeyDown={onKeyDown}
            maxWidth="15"
            clampValueOnBlur
          >
            <NumberInputField pe={3} />
          </NumberInput>
        </HStack>
      </HStack>
    </Wrap>
  );
};
