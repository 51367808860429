import { createColumnHelper } from '@tanstack/react-table';
import { CustomerEmailHistoryResponse } from 'api';
import { getDateFormattedString } from 'utils';

const columnHelper = createColumnHelper<CustomerEmailHistoryResponse>();

export const emailColumns = [
  columnHelper.accessor('email', {
    cell: (info) => info.getValue(),
    header: ({ table }) => table.options.meta?.t('keywords.email'),
    enableSorting: true
  }),
  columnHelper.accessor('setAt', {
    cell: ({ getValue, table }) =>
      getDateFormattedString({
        t: table.options.meta?.t,
        date: getValue()
      }),
    header: ({ table }) =>
      table.options.meta?.t('keywords.email_date_and_time'),
    enableSorting: true
  })
];
