import { useMutation } from '@tanstack/react-query';
import { fetchPaymentTransactionDownloadCSV } from 'api';
import { AxiosError } from 'axios';
import { useAppSelector } from 'hooks';
import { selectAppFilters } from 'store/app';
import { AxiosErrorData, QueryFilters } from 'types';

export const usePaymentTransactionDownloadCSV = (
  queryFilters: QueryFilters
) => {
  const globalFilters = useAppSelector(selectAppFilters);

  return useMutation(
    () => fetchPaymentTransactionDownloadCSV({ globalFilters, queryFilters }),
    {
      onError(error: AxiosError<AxiosErrorData>) {}
    }
  );
};
