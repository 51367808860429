import { ENDPOINTS } from 'api';
import axios from 'axios';

import { CustomerEmailHistoryResponse } from '../Customers.types';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const fakedData: CustomerEmailHistoryResponse[] = [
  {
    email: 'email1@mail',
    setAt: new Date().toLocaleString()
  },
  {
    email: 'email2@mail',
    setAt: new Date().toLocaleString()
  }
];

export const fetchCustomerEmailHistory = async (
  id: string
): Promise<CustomerEmailHistoryResponse[]> => {
  const { data } = await axios.get<CustomerEmailHistoryResponse[]>(
    `${ENDPOINTS.EMAIL_HISTORY}/${id}`
  );

  return data;
};
