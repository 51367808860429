import { ENDPOINTS, WithPagination } from 'api';
import { serializeQueryFilters } from 'api/api.utils';
import axios from 'axios';
import { QueryFilters } from 'types';
import { ICustomerFavourites } from 'types/customer';

import { CustomerFavouritesResponse } from '../Customers.types';

import { serializeCustomerFavouritesData } from './CustomerFavourites.utils';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const fakeData: ICustomerFavourites[] = [
  {
    id: 'id_1',
    nickname: 'nick 1',
    email: 'email@email.com',
    addedAt: new Date().toLocaleString(),
    userId: 'user_1'
  }
];

export const fetchCustomerFavouritesToUser = async ({
  customerId,
  queryFilters
}: {
  customerId: string;
  queryFilters: QueryFilters;
}): Promise<WithPagination<ICustomerFavourites[]>> => {
  const { data } = await axios.post<
    WithPagination<CustomerFavouritesResponse[]>
  >(
    `${ENDPOINTS.FAVOURITES}/to-user/${customerId}/list`,
    serializeQueryFilters(queryFilters)
  );

  return {
    ...data,
    data: serializeCustomerFavouritesData(data.data)
  };
};
