import {
  Flex,
  Grid,
  GridItem,
  Heading,
  Skeleton,
  Text,
  useDisclosure
} from '@chakra-ui/react';
import {
  ChartLegend,
  FilterButtons,
  LineChart,
  ModalContainer,
  useTableFilters
} from 'containers';
import { useChartData, useReportsStatistic } from 'hooks';
import { FullScreenButton } from 'pages/AdminPanel/components';
import { Fragment, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ChartFilters } from 'types';

import { chartFiltersSchema, CHART_SETTINGS } from './ReportsStatistic.utils';

export const ReportsStatistic = (): JSX.Element => {
  const [t] = useTranslation();

  const [filterData, setFilterData] = useState<ChartFilters>({});
  const { isOpen: isModalOpen, onToggle, onClose } = useDisclosure();

  const filterButtonsProps = useTableFilters({
    filters: chartFiltersSchema,
    onChange: setFilterData
  });

  const { data, isSuccess } = useReportsStatistic({
    filters: filterData
  });

  const chartDataMap = useChartData({
    apiData: data,
    chartSettings: CHART_SETTINGS
  });

  const { chartData = [] } = chartDataMap || {};

  const content = (
    <Grid
      templateAreas={`
          "title filters full"
          "legend legend legend"
          "chart chart chart"
        `}
      rowGap={4}
      columnGap={4}
      templateColumns="1fr auto auto"
    >
      <GridItem area="title">
        <Flex flexWrap="wrap" alignItems="flex-end">
          <Heading size="l" marginRight={2}>
            {t('attribute.title.reports')}
          </Heading>
          <Text color="subtext">{t('keywords.amount')}</Text>
        </Flex>
      </GridItem>
      <GridItem area="filters">
        <FilterButtons {...filterButtonsProps} />
      </GridItem>
      <GridItem area="full">
        <FullScreenButton isFullMode={isModalOpen} onClick={onToggle} />
      </GridItem>
      <GridItem area="legend">
        <Skeleton isLoaded={isSuccess}>
          <ChartLegend data={chartData} />
        </Skeleton>
      </GridItem>
      <GridItem area="chart" height="30rem">
        <Skeleton isLoaded={isSuccess} boxSize="100%">
          {!!chartDataMap && (
            <LineChart dataMap={chartDataMap} filters={filterData} />
          )}
        </Skeleton>
      </GridItem>
    </Grid>
  );

  return (
    <Fragment>
      {content}
      <ModalContainer
        isOpen={isModalOpen}
        onClose={onClose}
        size="max"
        modalBodyProps={{
          textAlign: 'left'
        }}
        shouldHideCloseButton
      >
        {content}
      </ModalContainer>
    </Fragment>
  );
};
