import { Text } from '@chakra-ui/react';
import { Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import { DateString } from 'types';
import { getDateFormattedString } from 'utils';

type Props = {
  author: string | null;
  date: DateString;
};

export const CommentAuthor = ({ author, date }: Props): JSX.Element => {
  const [t] = useTranslation();

  return (
    <Fragment>
      <Text>{author || t('keywords.auto_comment')}</Text>
      <Text color="subtext" whiteSpace="nowrap">
        {getDateFormattedString({ date: new Date(date), t })}
      </Text>
    </Fragment>
  );
};
