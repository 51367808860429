import { Table } from 'components';
import { ContentContainer } from 'containers';
import {
  useAdminInfo,
  useAdminLoginSessions,
  useTableQueryController
} from 'hooks';
import { useParams } from 'react-router-dom';

import { loginSessionColumns } from './LoginSession.utils';

export const LoginSession = (): JSX.Element => {
  const params = useParams<{ adminId: string }>();

  const {
    queryFilters,
    action: { onChangeSort, onChangePagination }
  } = useTableQueryController();

  const adminId = params.adminId || '';

  const { data } = useAdminLoginSessions({
    adminId,
    queryFilters
  });

  const { data: adminInfo } = useAdminInfo({
    id: adminId
  });

  return (
    <ContentContainer height="100%">
      {data && adminInfo ? (
        <Table
          data={data.data}
          columns={loginSessionColumns}
          onChangeSort={onChangeSort}
          onChangePagination={onChangePagination}
          totalItems={data.totalItems}
          pagination={queryFilters.pagination}
          meta={{
            user: {
              id: adminInfo.id,
              nickname: adminInfo.nickname,
              email: adminInfo.email,
              avatar: null
            }
          }}
        />
      ) : null}
    </ContentContainer>
  );
};
